export enum BannerTypesEnum {
  BannerTopSlim = 'BANNER_TOP_SLIM',
  BannerTopRoll = 'BANNER_TOP_ROLL',
  BannerReview = 'BANNER_REVIEW',
  BannerSlim = 'BANNER_SLIM',
  BannerPopup = 'BANNER_POPUP',
  BannerDesigners = 'BANNER_DESIGNERS',
  BannerWeeklyBest = 'BANNER_WEEKLY_BEST',
  BannerCoverflow = 'BANNER_COVERFLOW',
  BannerApparelCoverflow = 'BANNER_APPAREL_COVERFLOW',
  BannerAccessoriesCoverflow = 'BANNER_ACCESSORIES_COVERFLOW',
  BannerApparelRoll = 'BANNER_APPAREL_ROLL',
  BannerAccessoriesRoll = 'BANNER_ACCESSORIES_ROLL',
  BannerHomeBottom = 'BANNER_HOME_BOTTOM',
  BannerHomeMiddle = 'BANNER_HOME_MIDDLE',
  BannerProductTop = 'BANNER_PRODUCT_TOP',
  BannerHomeMultiLine = 'BANNER_HOME_MULTI_LINE',
}
