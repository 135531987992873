/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * Fitpet Mall
 * 핏펫몰 API 스펙

API 스펙 작성시 다음 가이드를 숙지하시기 바랍니다.

- [Open API 가이드 참고](https://swagger.io/docs/specification/about/)
- [Kotlin-Spring Open API Generator 설정 참고](https://openapi-generator.tech/docs/generators/kotlin-spring)
- REST API 디자인 가이드 참고
  - doc > guide > rest-api-design.md
- OpenAPI Spec 컨벤션 참고
  - doc > guid > openapi-spec-convention.md
- 설명(description) 작성시 마크다운([CommonMark](https://commonmark.org/help/)) 구문 참고

로컬에서 스웨거 에디터를 사용하는 방법은 다음과 같다.

```shell
docker pull swaggerapi/swagger-editor
docker run -d -p 8123:8080 swaggerapi/swagger-editor
open http://localhost:8123
```

Api 스펙을 하나의 파일로 합치려면 다음과 같이 실행한다.

```shell
# 설치
npm install -g swagger-cli

# 실행
swagger-cli bundle api-spec.yaml --outfile build/openapi.yaml --type yaml
```
 * OpenAPI spec version: 1.0.0
 */

/**
 * 메시지 알림코드

다음은 지원하는 알림코드와 메시지 형태를 보여준다. `%{...}`의 네임드 플레이스홀더는 각각의 이름을 키(key)로 하여
`notficationData.templateProperty`에 맵 형태의 데이터로 지정해야 한다.

- `FITPET_HEALTH_QNA_NEW_COMMENT_ON_ARTICLE`: 게시물에 새로운 코멘트 달림
  - ```
    핏펫 커뮤니티
    %{petName} 보호자님이 %{articleName}에 새로운 댓글을 남겼어요.
    ```

- `FITPET_HEALTH_QNA_ARTICLE_LIKED`: 게시물에 좋아요가 눌림
  - ```
    핏펫 커뮤니티
    %{petName} 보호자님이 회원님의 게시글을 좋아해요.
    ```

- `FITPET_HEALTH_QNA_COMMENT_LIKED`: 코멘트에 좋아요가 눌림
  - ```
    핏펫 커뮤니티
    %{petName} 보호자님이 회원님의 댓글을 좋아해요.
    ```

- `FITPET_HEALTH_QNA_ARTICLE_HOT`: 게시물에 일정 개수 이상 좋아요가 눌림
  - ```
    핏펫 커뮤니티
    회원님의 게시글이 좋아요를 %{count}개 이상 받았어요.
    ```

- `FITPET_HEALTH_QNA_COMMENT_HOT`: 코멘트에 일정 개수 이상 좋아요가 눌림
  - ```
    핏펫 커뮤니티
    회원님의 댓글이 좋아요를 %{count}개 이상 받았어요.
    ```

- `FITPET_HEALTH_QNA_COMMENT_REPLIED`: 코멘트에 답글 달림
  - ```
    핏펫 커뮤니티
    %{petName} 보호자님이 새로운 답글을 남겼어요.
    ```

- `FITPET_HEALTH_NOTE_BIRTH_1Y`: 1살이 된 당일 13:00 발송
  - ```
    🌹 어른이 된 %{petName}, 축하합니다!
    더 건강한 %{petType}을 위해 건강관리 시작해요!
    ```

- `FITPET_HEALTH_NOTE_BIRTH_2Y_MORE`: 2살 생일부터 당일 13:00 발송
  - ```
    🎂 생일 선물 잊지 않으셨죠?
    %{petName}의 생일을 축하합니다!
    ```

- `FITPET_HEALTH_NOTE_WALKING_COMPLETE`: 챌린지 산책 완료 체킹 후 30분뒤 발송
  - ```
    🦮 산책 다녀오셨네요!
    더 필요한 산책 용품은 없었나요?
    ```

- `FITPET_HEALTH_NOTE_DIAGNOSIS_14D_BEFORE`: 관리항목의 14일 전 17:00 발송
  - ```
    🔔 잊지 않게 꼭 챙겨 주세요!
    %{petName}의 %{managementItem}주기가 2주 남았어요!
    ```

- `FITPET_HEALTH_NOTE_DIAGNOSIS_7D_BEFORE`: 관리항목의 7일 전 17:00 발송
  - ```
    🔔 잊지 않게 꼭 챙겨 주세요!
    %{petName}의 %{managementItem}주기가 1주 남았어요!
    ```

- `FITPET_HEALTH_NOTE_DIAGNOSIS_3D_BEFORE`: 관리항목의 3일 전 17:00 발송
  - ```
    🔔 잊지 않게 꼭 챙겨 주세요!
    %{petName}의 %{managementItem}주기가 3일 남았어요!
    ```

- `FITPET_HEALTH_NOTE_DIAGNOSIS_1W_AFTER`: 관리항목의 7일 후 17:00 발송
  - ```
    👀 잊지 않게 꼭 챙겨 주세요!
    %{petName}의 %{managementItem}주기가 1주 지났어요!
    ```

- `FITPET_HEALTH_NOTE_REVIEW_COMPLEMENT`: 리뷰 보완 요청 즉시
  - ```
    🧐 리뷰 검수중에 문제가 생겼어요
    리뷰 내용을 확인하고 보완해 주세요!
    ```

- `FITPET_HEALTH_NOTE_REVIEW_SUBMIT`: 리뷰 검수 완료 즉시
  - ```
    🗒️ 리뷰 검수 완료!
    검수된 리뷰를 건강수첩에 기록해 두었어요!
    ```

- `FITPET_HEALTH_NOTE_RESERVATION_CONFIRM`: 병원 예약 확정 즉시 (date : YYYY-MM-DD HH:MM)
  - ```
    🏥 %{hospitalName} 예약 확정!
    %{date}에 방문해 주세요!
    ```

- `FITPET_HEALTH_NOTE_RESERVATION_VISIT`: 병원 방문 완료 즉시
  - ```
    🏥 %{hospitalName} 방문 완료!
    병원 방문 내역을 건강수첩에 기록해 두었어요!
    ```

- `FITPET_HEALTH_NOTE_AHEAD_BASIC_COMPLETE`: 검사 완료 후 30분 뒤 발송
  - ```
    건강수첩
    🧪 셀프 소변 검사 하셨네요!
    어헤드 검사 내역을 건강수첩에 기록해 두었어요!
    ```

- `FITPET_HEALTH_NOTE_MONTHLY_HOSPITAL_EXPENSE`: 매월 1일 13:00 발송
  - ```
    💸 %{month}월에 병원비,
    %{amount}원 지출하셨네요!
    ```

- `FITPET_HEALTH_NOTE_NOT_USED_DIAGNOSIS`: 매월 15일 13:00 발송
  - ```
    📋 주기적으로 관리해 주세요!
    %{itemName}, 관리해 볼까요?
    ```

- `FITPET_HEALTH_NOTE_DIAGNOSIS_RECEIPT_COMPLEMENT`: 영수증 보완 요청 즉시
  - ```
    🧐 영수증 검수중에 문제가 생겼어요
    영수증 내용을 확인하고 보완해 주세요!
    ```

- `FITPET_HEALTH_NOTE_DIAGNOSIS_RECEIPT_COMPLETE`: 영수증 검수 완료 즉시
  - ```
    🗒️ 영수증 검수 완료!
    검수된 영수증을 건강수첩에 기록해 두었어요!
    ```
 */
export type PushNotificationCodeEnum = typeof PushNotificationCodeEnum[keyof typeof PushNotificationCodeEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PushNotificationCodeEnum = {
  FITPET_HEALTH_QNA_NEW_COMMENT_ON_ARTICLE: 'FITPET_HEALTH_QNA_NEW_COMMENT_ON_ARTICLE',
  FITPET_HEALTH_QNA_ARTICLE_LIKED: 'FITPET_HEALTH_QNA_ARTICLE_LIKED',
  FITPET_HEALTH_QNA_COMMENT_LIKED: 'FITPET_HEALTH_QNA_COMMENT_LIKED',
  FITPET_HEALTH_QNA_ARTICLE_HOT: 'FITPET_HEALTH_QNA_ARTICLE_HOT',
  FITPET_HEALTH_QNA_COMMENT_HOT: 'FITPET_HEALTH_QNA_COMMENT_HOT',
  FITPET_HEALTH_QNA_COMMENT_REPLIED: 'FITPET_HEALTH_QNA_COMMENT_REPLIED',
  FITPET_HEALTH_NOTE_BIRTH_1Y: 'FITPET_HEALTH_NOTE_BIRTH_1Y',
  FITPET_HEALTH_NOTE_BIRTH_2Y_MORE: 'FITPET_HEALTH_NOTE_BIRTH_2Y_MORE',
  FITPET_HEALTH_NOTE_WALKING_COMPLETE: 'FITPET_HEALTH_NOTE_WALKING_COMPLETE',
  FITPET_HEALTH_NOTE_DIAGNOSIS_14D_BEFORE: 'FITPET_HEALTH_NOTE_DIAGNOSIS_14D_BEFORE',
  FITPET_HEALTH_NOTE_DIAGNOSIS_7D_BEFORE: 'FITPET_HEALTH_NOTE_DIAGNOSIS_7D_BEFORE',
  FITPET_HEALTH_NOTE_DIAGNOSIS_3D_BEFORE: 'FITPET_HEALTH_NOTE_DIAGNOSIS_3D_BEFORE',
  FITPET_HEALTH_NOTE_DIAGNOSIS_1W_AFTER: 'FITPET_HEALTH_NOTE_DIAGNOSIS_1W_AFTER',
  FITPET_HEALTH_NOTE_REVIEW_COMPLEMENT: 'FITPET_HEALTH_NOTE_REVIEW_COMPLEMENT',
  FITPET_HEALTH_NOTE_REVIEW_SUBMIT: 'FITPET_HEALTH_NOTE_REVIEW_SUBMIT',
  FITPET_HEALTH_NOTE_RESERVATION_CONFIRM: 'FITPET_HEALTH_NOTE_RESERVATION_CONFIRM',
  FITPET_HEALTH_NOTE_RESERVATION_VISIT: 'FITPET_HEALTH_NOTE_RESERVATION_VISIT',
  FITPET_HEALTH_NOTE_AHEAD_BASIC_COMPLETE: 'FITPET_HEALTH_NOTE_AHEAD_BASIC_COMPLETE',
  FITPET_HEALTH_NOTE_MONTHLY_HOSPITAL_EXPENSE: 'FITPET_HEALTH_NOTE_MONTHLY_HOSPITAL_EXPENSE',
  FITPET_HEALTH_NOTE_NOT_USED_DIAGNOSIS: 'FITPET_HEALTH_NOTE_NOT_USED_DIAGNOSIS',
  FITPET_HEALTH_NOTE_DIAGNOSIS_RECEIPT_COMPLEMENT: 'FITPET_HEALTH_NOTE_DIAGNOSIS_RECEIPT_COMPLEMENT',
  FITPET_HEALTH_NOTE_DIAGNOSIS_RECEIPT_COMPLETE: 'FITPET_HEALTH_NOTE_DIAGNOSIS_RECEIPT_COMPLETE',
} as const
