import { useRef } from 'react'
import Router from 'next/router'
import { HEALTH_HOST } from '@/constants/legacy/constEnv'
import ROUTES from '@/constants/legacy/constRoutes'
import { IModelFakeWhoami } from '@/constants/legacy/constType'
import { MembershipStatusEnum } from '@/constants/membership-status.enum'
import { QueryStringKeyEnum } from '@/constants/query-string-key.enum'
import { useTracker } from '@/containers/contexts/EventTrackingProvider'
import { useCustomRouter, useUserInfo } from '@/containers/hooks'
import useCanBuyDeal100 from '@/containers/hooks/useCanBuyDeal100'
import { UserContainer } from '@/containers/users/UserContainer'
import { EmailSigninMutation, TokenSigninMutation } from '@/graphql/generated/schemas'
import { Bridge } from '@/utils/bridge/bridge'
import { convertUserProperties } from '@/utils/event-tracker/convertParams/convertUserParams'
import { FitpetMallEventEnum } from '@/utils/event-tracker/events'
import appBridgeProvider, { appBridgeLogin, isInAppFlag } from '@/utils/utilBridge'
import { localLoginReturnUrl } from '@/utils/utilLocalStorage'
import { UtilLogin } from '@/utils/utilLogin'

type UseAfterLogin = {
  setIsSocialLoginBeforeSignup: (isBeforeSignup: boolean) => void
  onEmailLoginSuccess: (data: EmailSigninMutation | TokenSigninMutation) => Promise<void>
  onSocialLoginSuccess: (data: IModelFakeWhoami | any) => Promise<void>
  hasRedirectUrl: () => boolean
  getRedirectUrl: () => string | undefined
  clearRedirectUrl: () => void
  isExcludeUrlsForAfterSingUp: (url: string) => boolean
  getRedirectUrlAfterSignUp: () => string
  replaceRedirectUrl: () => Promise<boolean>
  loginOperator: (asPath?: string, afterReload?: boolean, onforegroundCallback?: () => void) => void
}

const useAfterLogin = (): UseAfterLogin => {
  const isSocialLoginBeforeSignupRef = useRef<boolean>(false)
  const tracker = useTracker()
  const router = useCustomRouter()
  const { useSaveUserInfo } = useUserInfo
  const bridgeLogin = appBridgeLogin()
  const { saveTokenNWhoami } = useSaveUserInfo()
  const { replace } = useCustomRouter()
  const { queryUserCanBuyDeal100 } = useCanBuyDeal100()
  const { getUserInfo, initTokenSignIn } = UserContainer.useContainer()

  const setIsSocialLoginBeforeSignup = (isBeforeSignup: boolean) => {
    isSocialLoginBeforeSignupRef.current = isBeforeSignup
  }

  const hasRedirectUrl = (): boolean => {
    return !!localLoginReturnUrl.load()
  }

  const getRedirectUrl = () => {
    const localReturnUrl = localLoginReturnUrl.load()
    if (!localReturnUrl) {
      return undefined
    }

    let _returnUrl = localReturnUrl || ROUTES.INDEX
    if (_returnUrl === '/mall') _returnUrl = ROUTES.INDEX
    return _returnUrl.replace('/mall/', '/')
  }

  const clearRedirectUrl = () => {
    localLoginReturnUrl.remove()
  }

  const isExcludeUrlsForAfterSingUp = (url: string): boolean => {
    return [ROUTES.MYPAGE.BASE].includes(url)
  }

  const getRedirectUrlAfterSignUp = () => {
    let redirectUrl = localLoginReturnUrl.load()
    if (!redirectUrl || isExcludeUrlsForAfterSingUp(redirectUrl)) {
      return ROUTES.INDEX
    }
    if (redirectUrl === '/mall') redirectUrl = ROUTES.INDEX
    return redirectUrl.replace('/mall/', '/')
  }

  const replaceRedirectUrl = () => {
    const redirectUrl = getRedirectUrl()
    localLoginReturnUrl.remove()
    return replace(redirectUrl)
  }

  const onEmailLoginSuccess = async (data: EmailSigninMutation | TokenSigninMutation) => {
    if (!data) return

    tracker.setUserProperties({
      login_type: 'email',
      ...convertUserProperties(data, FitpetMallEventEnum.CompleteLogin),
    })
    tracker.triggerCustomEvent(FitpetMallEventEnum.CompleteLogin, {
      loginType: 'email',
    })

    await saveTokenNWhoami(data)

    _redirectAfterLogin(data)
  }

  const onSocialLoginSuccess = async (data: IModelFakeWhoami | any) => {
    if (!data) {
      return
    }

    const loginType = data.device.data.socialService?.socialType?.toLowerCase()
    tracker.setUserProperties({
      login_type: loginType,
      ...convertUserProperties(data, FitpetMallEventEnum.CompleteLogin),
    })
    tracker.triggerCustomEvent(FitpetMallEventEnum.CompleteLogin, {
      loginType,
    })

    const fixWhoami = {
      user: data.user.data,
      device: data.device.data,
    } as IModelFakeWhoami
    await saveTokenNWhoami(fixWhoami)

    if (isSocialLoginBeforeSignupRef.current) {
      replace(ROUTES.INDEX)
      return
    }
    _redirectAfterLogin(data)
  }

  const getRedirectMembershipUrl = async ({
    membershipStatus,
    returnUrl,
  }: {
    membershipStatus?: string
    returnUrl: string
  }) => {
    if (membershipStatus && membershipStatus === MembershipStatusEnum.Owner) {
      return ROUTES.MEMBERSHIP.INTRO
    }
    return returnUrl
  }

  const _redirectAfterLogin = async (whoami: IModelFakeWhoami | EmailSigninMutation | TokenSigninMutation | any) => {
    let _returnUrl = localLoginReturnUrl.load() || ROUTES.INDEX

    // 용도 확인 필요 ↓
    if (_returnUrl === '/mall') {
      _returnUrl = ROUTES.INDEX
    }
    _returnUrl = _returnUrl.replace('/mall/', '/')
    // 용도 확인 필요 ↑

    const userId = whoami?.user?.id || whoami?.user?.data?.id
    const userInfo = await getUserInfo(userId)

    if (_returnUrl.startsWith(ROUTES.MEMBERSHIP.JOIN.OWNER.BASE)) {
      _returnUrl = await getRedirectMembershipUrl({
        membershipStatus: userInfo?.membershipStatus,
        returnUrl: _returnUrl,
      })
      replace(_returnUrl)
      return
    }

    if (_returnUrl.startsWith(HEALTH_HOST)) {
      _returnUrl = UtilLogin.getRedirectUrlWithToken(_returnUrl)
      replace(_returnUrl)
      return
    }

    /**
     * 병원 개발시 로그인 처리를 하기 위해
     * 로그인 후 병원 관련 페이지로 이동하는 경우
     * 병원 관련 페이지로 이동하기 전에 토큰을 붙여서 이동하도록 처리
     * production 환경은 쿠키로 토큰 정보 공유
     */
    const decodedReturnUrl = decodeURIComponent(_returnUrl)
    if (UtilLogin.isHospitalRelatedUrls(decodedReturnUrl)) {
      if (process.env.FITPETMALL_ENV !== 'production') {
        _returnUrl = UtilLogin.getRedirectUrlWithToken(_returnUrl)
      }
      window.location.replace(_returnUrl)
      return
    }

    replace(decodedReturnUrl)
  }

  const _appLogin = (afterReload: boolean = true, onforegroundCallback?: () => void) => {
    appBridgeProvider((bridge: Bridge) => bridge.login())
    window.onforeground = async () => {
      if (window.getToken().apiToken.token.length > 0) {
        await bridgeLogin(window.getToken())

        await initTokenSignIn()
        // Android APP 로그인 시 canBuyDeal100 flag가 userInfo 내에 없어서 쿼리 직접 호출
        // 최신 버전 Android 앱에서는 canBuyDeal100 플래그를 같이 주나, 이전 버전 사용자들은 canBuyDeal100 플래그가 userInfo 내에 없음
        // TODO Android APP 모든 버전 사용자가 로그인 시 canBuyDeal100 플래그가 userInfo 내에서 확인되면 제거
        const userId = window.getToken().userInfo.id
        queryUserCanBuyDeal100({ variables: { id: userId } })

        // reload 되는 경우는 사실 없어야 합니다. refetch를 하거나 컴포넌트 단위별로 재실행을 해주어야 합니다.
        // eslint-disable-next-line no-unused-expressions
        onforegroundCallback && (await onforegroundCallback())
        // eslint-disable-next-line no-unused-expressions
        afterReload && window.location.reload()
      }
      window.onforeground = null
    }
  }

  const _webLogin = (asPath?: string) => {
    router.push({
      pathname: ROUTES.ACCOUNTS.LOGIN,
      query: {
        [QueryStringKeyEnum.ReturnUrl]: asPath || Router.asPath,
      },
    })
  }

  // 이함수를 쓸 때는 반드시 onforeground 이벤트를 초기화 시켜 주어야 합니다.
  const loginOperator = (asPath?: string, afterReload: boolean = true, onforegroundCallback?: () => void) => {
    if (isInAppFlag) {
      _appLogin(afterReload, onforegroundCallback)
    } else {
      _webLogin(asPath)
    }
  }

  return {
    onEmailLoginSuccess,
    onSocialLoginSuccess,
    setIsSocialLoginBeforeSignup,
    hasRedirectUrl,
    getRedirectUrl,
    clearRedirectUrl,
    isExcludeUrlsForAfterSingUp,
    getRedirectUrlAfterSignUp,
    replaceRedirectUrl,
    loginOperator,
  }
}

export default useAfterLogin
