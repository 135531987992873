import { FC, PropsWithChildren, useEffect, useState } from 'react'
import styled from 'styled-components'
import { ImageAsset, TextEllipsis } from '@/components/common'
import { NewCol } from '@/components/common/layouts/NewCol'
import { NewRow } from '@/components/common/layouts/NewRow'
import { ModalBaseProps } from '@/components/common/modals/ModalBase'
import ModalSliderPortal from '@/components/common/modals/ModalSliderPortal'
import { GlobalColorEnum } from '@/constants/global-color.enum'
import { LAYOUT_UNITS, Z_INDEXES } from '@/constants/legacy/constLayout'
import { enableScroll } from '@/utils/utilCommon'

type ModalSliderProps<T> = {
  title?: string
  centerTitle?: boolean
  titleNoBorder?: boolean
  noHeader?: boolean
} & ModalBaseProps<T>

const ModalSlider: FC<PropsWithChildren<ModalSliderProps<any>>> = ({
  title,
  centerTitle = false,
  titleNoBorder = false,
  children,
  onCancel,
  useModalProps,
  noHeader = false,
}) => {
  const [open, toggleOpen] = useState<boolean>(false)
  const { visible: isAnimation, hideModal: fadeOutAnimation } = useModalProps

  const openModal = () => isAnimation && toggleOpen(true)
  const disableScroll = () => enableScroll(!open)

  useEffect(() => {
    openModal()
    disableScroll()

    return () => {
      if (!open) {
        return
      }
      setTimeout(() => toggleOpen(false), 300)
    }
  }, [isAnimation, open])

  useEffect(() => {
    return () => {
      enableScroll(true)
    }
  }, [])

  const _onClose = () => {
    onCancel?.()
    fadeOutAnimation()
  }

  if (!open) {
    return null
  }

  return (
    <ModalSliderPortal>
      <StyledContainer data-cy="modal-slider">
        <Popup isAnimation={isAnimation}>
          {!noHeader && (
            <TitleRow align="center" border={titleNoBorder ? 0 : 1} wrap={false}>
              <NewCol flex={1} style={{ paddingLeft: centerTitle ? '22px' : '0px' }}>
                <TextEllipsis size={18} weight="bold" lineNumber={1} align={centerTitle ? 'center' : 'left'}>
                  {title}
                </TextEllipsis>
              </NewCol>
              {onCancel && typeof onCancel === 'function' && (
                <CloseButton onClick={_onClose}>
                  <ImageAsset name="close_new" size={24} svg />
                </CloseButton>
              )}
            </TitleRow>
          )}
          <ChildrenContainer>{children}</ChildrenContainer>
        </Popup>
        <Background isAnimation={isAnimation} onClick={_onClose} />
      </StyledContainer>
    </ModalSliderPortal>
  )
}

const StyledContainer = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  max-width: ${LAYOUT_UNITS.BODY_MAX_WIDTH};
  z-index: ${Z_INDEXES.BOTTOM_SLIDE_POPUP};
`

const Background = styled.div<{ isAnimation: boolean }>`
  width: 100%;
  max-width: ${LAYOUT_UNITS.BODY_MAX_WIDTH};
  height: 100vh;
  position: fixed;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background: ${({ isAnimation }) => (isAnimation ? 'rgba(0, 0, 0, 0.5)' : 'rgba(0, 0, 0, 0)')};
  transition: background-color 0.3s ease;
`

const Popup = styled.div<{ isAnimation: boolean }>`
  z-index: ${Z_INDEXES.BOTTOM_SLIDE_POPUP};
  position: absolute;
  bottom: 0;
  display: block;
  width: 100%;
  max-width: ${LAYOUT_UNITS.BODY_MAX_WIDTH};
  max-height: 90vh;
  border-radius: 20px 20px 0 0;
  background-color: ${(props) => props.theme.color.grayWhite};
  animation: ${({ isAnimation }) => (isAnimation ? `0.3s forwards slideIn` : `0.2s ease forwards slideOut`)};

  @keyframes slideIn {
    0% {
      transform: translateY(100%);
    }
    100% {
      transform: translateY(0%);
    }
  }

  @keyframes slideOut {
    0% {
      transform: translateY(0%);
    }
    100% {
      transform: translateY(100%);
    }
  }
`

const TitleRow = styled(NewRow)<{ border: number }>`
  position: relative;
  height: 54px;
  border-bottom: ${({ border }) => (border ? `1px solid ${GlobalColorEnum.Gray200}` : 'none')};
  background-color: ${(props) => props.theme.color.grayWhite};
  width: 100%;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  z-index: ${Z_INDEXES.BOTTOM_SLIDE_POPUP + 1};
  max-width: ${LAYOUT_UNITS.BODY_MAX_WIDTH};
  margin: auto 0;
  padding: 16px 20px;
`

const ChildrenContainer = styled.div`
  /* padding-top: 50px; */
`

const CloseButton = styled.div`
  /* position: absolute;
  right: 0;
  top: 0;
  padding: 20px;
  padding-top: 10px; */
  /* padding-top: -20px; */
  margin-bottom: 8px;
  cursor: pointer;
`

export default ModalSlider
