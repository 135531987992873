import { ParsedUrlQueryInput } from 'querystring'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import ROUTES from '@/constants/legacy/constRoutes'
import { QueryStringKeyEnum } from '@/constants/query-string-key.enum'
import { useCustomRouter } from '@/containers/hooks/index'
import { doSetRecentSearchKeywordList } from '@/stores/reduxData'
import { localRecentSearchKeywordList } from '@/utils/utilLocalStorage'

export type UseSearchResultType = {
  initSearchBodyMount: () => void
  appendFilterQuery: (pathname: string, queries: ParsedUrlQueryInput) => void
}

const useSearchResult = (): UseSearchResultType => {
  const dispatch = useDispatch()
  const { pathname, query, replace } = useCustomRouter()
  const { [QueryStringKeyEnum.SearchQuery]: searchText } = query

  useEffect(() => {
    if (pathname === ROUTES.SEARCH_RESULT && searchText) {
      initSearchBodyMount()
    }
  }, [pathname, searchText])

  const setRecentSearchKeyword = (keywordList: string[]) => {
    const sliceKeywordList = keywordList.filter((item: string) => item !== '').slice(0, 10)
    localRecentSearchKeywordList.save(sliceKeywordList)
    dispatch(doSetRecentSearchKeywordList(sliceKeywordList))
  }

  const initSearchBodyMount = () => {
    const localKeywordList = localRecentSearchKeywordList.load()
    const newKeywordList = searchText === '' ? localKeywordList : [...new Set([searchText, ...localKeywordList])]
    setRecentSearchKeyword(newKeywordList)
  }

  const appendFilterQuery = (pathname: string, queries: ParsedUrlQueryInput) => {
    replace({
      pathname: pathname,
      query: { ...query, ...queries },
    })
  }

  return {
    initSearchBodyMount,
    appendFilterQuery,
  }
}

export default useSearchResult
