import { useEffect, useState } from 'react'
import ROUTES from '@/constants/legacy/constRoutes'
import { SocialAuthType, SocialServiceUserProfileType } from '@/constants/legacy/constType'
import { SocialServicesEnum } from '@/constants/social-services.enum'
import { localSocialAuthType } from '@/utils/utilLocalStorage'

type UseNaverReturnProps = {
  naverContext: any
  getUserProfile: (naverLogin: any) => SocialServiceUserProfileType | undefined
  logout: (naverLogin: any) => void
  openAuth: (authType?: SocialAuthType) => void
  getNavigationBackCount: () => number
}

export const loadSdk = () => {
  return new Promise((resolve) => {
    const js: HTMLScriptElement = document.createElement('script')

    js.id = 'naver-sdk'
    js.src = 'https://static.nid.naver.com/js/naveridlogin_js_sdk_2.0.2-nopolyfill.js'
    js.onload = resolve

    document.body.append(js)
  })
}

/**
 * @desc useNaver 사용 시 사용하는 곳에서 {<script async type="text/javascript" src="https://static.nid.naver.com/js/naveridlogin_js_sdk_2.0.2-nopolyfill.js" />} 이 스크립트를 꼭 Head에서 같이 불러줘야 동작합니다.
 * @desc LayoutMain에서 해당 스크립트를 제외시켜서 사용하는 곳에서 호출 해줘야함 - 2022-12-23 Jerry
 * @desc loadSdk 함수 사용해 동적으로 넣어서 처리함 추후 점진적으로 script 문 제거 후 확인 - 2023-10-17 Grant
 */
const useNaver = (): UseNaverReturnProps => {
  const [naverContext, setNaverContext] = useState(undefined)

  useEffect(() => {
    const loadSdkAndInitializeNaverLogin = async () => {
      // SSR의 경우, render 전에 build가 되어 window가 undefined된 상태가 될 수 있음
      if (typeof window === 'undefined') return
      await loadSdk()
      setTimeout(() => {
        // naver login script 로드 후 실행되도록 순서 보장을 위해 setTimeout 사용
        const { naver } = window

        if (!naver || !!naverContext || !document.getElementById('naverIdLogin')) return

        const naverLogin = new naver.LoginWithNaverId({
          clientId: process.env.NAVER_CLIENT_ID,
          callbackUrl: `${window.location.origin}${ROUTES.PREFIX}${ROUTES.ACCOUNTS.CALLBACK_NAVER}`,
          isPopup: false, // popup 사용시, Naver 로그인 성공 후 redirect url로 변경되기 때문에 사용을 권장하지 않음
          loginButton: { color: 'green', type: 1, height: 30 },
        })
        naverLogin.init()

        setNaverContext(naverLogin)
      }, 300)
    }

    loadSdkAndInitializeNaverLogin()
  }, [])

  const getUserProfile = (_naverLogin: any) => {
    if (_naverLogin.loginStatus.status && _naverLogin.user) {
      return {
        uniqueId: _naverLogin.user.id,
        accessToken: _naverLogin.accessToken.accessToken,
        accessTokenTtl: _naverLogin.accessToken.ttl,
        socialType: SocialServicesEnum.Naver,
        email: _naverLogin.user.email,
        name: _naverLogin.user.name,
        refreshToken: '',
      }
    }
  }

  const openAuth = (authType: SocialAuthType) => {
    localSocialAuthType.save(authType || 'LOGIN')
    if (document && document.getElementById('naverIdLogin')?.firstChild && window) {
      const loginButton: any = document.getElementById('naverIdLogin')?.firstChild

      loginButton?.addEventListener('click', (event: MouseEvent) => {
        event.stopPropagation()
      })

      loginButton?.click()
    }
  }

  /**
   * Naver 로그아웃 - Naver 로그인한 사용자 정보를 삭제함(login status = false로 변경됨)
   * */
  const logout = (_naverLogin: any) => {
    _naverLogin.logout()
  }

  const getNavigationBackCount = () => {
    return -2
  }

  return { naverContext, getUserProfile, logout, openAuth, getNavigationBackCount }
}

export default useNaver
