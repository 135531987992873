import { useEffect, useState } from 'react'
import { useGetRecentlyProducts } from '@/apis/rest/generated/hooks'
import { AdPetType } from '@/graphql/generated/schemas'
import { Base64ConverterUtil } from '@/utils/base64-converter.util'
import { deepcopy } from '@/utils/utilData'
import { localRecentlyProductByPetType, localRecentlyProductItems } from '@/utils/utilLocalStorage'
import type { ProductsListItemResponseDto, RecentlyProductRequestDto } from '@/apis/rest/generated/types'

type UseRecentlyProductType = {
  length?: number | undefined
  currentProductId?: string
  isFloatingButton?: boolean
}

type UseRecentlyProductReturnType = {
  isRecentlyProductsLoading: boolean
  recentlyProducts: ProductsListItemResponseDto[] | undefined
  // recentlyProductsTotalCount: number
  getRecentlyProducts: () => void
  addRecentlyProduct: (data: RecentlyProductType) => void
  addRecentlyProductByPetType: (productId: string, petType: AdPetType) => void
}

type RecentlyProductItemsType = {
  productId: string
  promotionId: string
  viewDate: number
  image: string
  salePrice?: number
  salePriceAfterCoupon?: number
}

interface RecentlyProductType {
  productId: string
  productPromotionId?: string
  image: string
  salePrice?: number
  salePriceAfterCoupon?: number
}

const LIMIT_RECENTLY_PRODUCT = 100

export const useRecentlyProductList = ({
  length = LIMIT_RECENTLY_PRODUCT,
  currentProductId,
}: UseRecentlyProductType): UseRecentlyProductReturnType => {
  const [recentlyProducts, setRecentlyProducts] = useState<ProductsListItemResponseDto[] | undefined>(undefined)

  const { isLoading, mutateAsync } = useGetRecentlyProducts({
    mutation: {
      onSuccess: (response) => {
        setRecentlyProducts(response.data || [])
      },
    },
  })

  const getRecentlyProducts = () => {
    const productList: RecentlyProductType[] = localRecentlyProductItems.load()
    const payload: RecentlyProductRequestDto[] = productList.map((product) => {
      return {
        productId: Base64ConverterUtil.convertBase64ToNumberId(product.productId),
        promotionId: product.productPromotionId
          ? Base64ConverterUtil.convertBase64ToNumberId(product.productPromotionId)
          : undefined,
        salePrice: product.salePrice,
        salePriceAfterCoupon: product.salePriceAfterCoupon,
      }
    })

    const productIdNumber = currentProductId ? Base64ConverterUtil.convertBase64ToNumberId(currentProductId) : undefined

    // 상품 상세 페이지 일 때 현재 보고있는 상품은 제외
    const payloadWithFilteredCurrentProduct = productIdNumber
      ? payload.filter((product) => product.productId !== productIdNumber)
      : payload

    const payloadWithLimitLength =
      length !== undefined && length > 0 ? payloadWithFilteredCurrentProduct.slice(0, length) : []

    if (payloadWithLimitLength.length === 0) {
      setRecentlyProducts([])
      return
    }

    mutateAsync({
      data: {
        products: payloadWithLimitLength,
      },
    })
  }

  /**
   * 상품 상세페이지에 접근하여 productDetail 쿼리 호출 후 최근 본 상품에 저장
   */
  const addRecentlyProduct = ({
    productId,
    productPromotionId,
    image,
    salePrice,
    salePriceAfterCoupon,
  }: RecentlyProductType) => {
    const viewedProduct: RecentlyProductItemsType = {
      productId,
      promotionId: productPromotionId || '',
      viewDate: Date.now(),
      image,
      salePrice: salePrice,
      salePriceAfterCoupon: salePriceAfterCoupon,
    }

    // localStorage 의 최근 본 상품을 로드
    const productList: RecentlyProductType[] = localRecentlyProductItems.load()

    // 최근 본 상품 리스트에 추가할 상품이 이미 존재하면 제거
    const ListWithViewedProductFiltered = productList.filter((product) => {
      if (viewedProduct.promotionId) {
        return product.productId !== viewedProduct.productId && product.productPromotionId !== viewedProduct.promotionId
      }
      return product.productId !== viewedProduct.productId
    })

    // 최근 본 상품 리스트에 추가
    const ListOfViewedProductAdded = [viewedProduct, ...ListWithViewedProductFiltered]

    // 최근 본 상품 리스트를 100개로 제한
    const ListLimitedLength = ListOfViewedProductAdded.slice(0, LIMIT_RECENTLY_PRODUCT)

    localRecentlyProductItems.save(ListLimitedLength)
  }

  /**
   * 최근 본 상품의 연관 상품 추천 섹션 관련 데이터 저장
   * 펫 타입에 따라 상품을 나누어 저장하여 홈섹션 쿼리 variable에 사용
   */
  const addRecentlyProductByPetType = (productId: string, petType: AdPetType) => {
    const recentlyProductByPetType = deepcopy(localRecentlyProductByPetType.load())

    if (petType === AdPetType.All) {
      recentlyProductByPetType[AdPetType.Dog] = productId
      recentlyProductByPetType[AdPetType.Cat] = productId
    } else {
      recentlyProductByPetType[petType] = productId
    }
    localRecentlyProductByPetType.save(recentlyProductByPetType)
  }

  useEffect(() => {
    getRecentlyProducts()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    isRecentlyProductsLoading: isLoading,
    recentlyProducts,
    getRecentlyProducts,
    addRecentlyProduct,
    addRecentlyProductByPetType,
    // recentlyProductsTotalCount: data?.page?.totalCount || 0,
  }
}
