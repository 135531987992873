import { createContext, useContext, useEffect, useLayoutEffect, useMemo, useRef } from 'react'
import { useRouter } from 'next/router'
import { usePetType } from '@/containers/hooks'
import { UserContainer } from '@/containers/users/UserContainer'
import { AdPetType } from '@/graphql/generated/schemas'
import { Tracker } from '@/utils/event-tracker'
import { decodeId } from '@/utils/utilApi'
import { getPlatform } from '@/utils/utilCommon'
import { hasData } from '@/utils/utilData'
import { localPetType } from '@/utils/utilLocalStorage'

type EventTrackingState = {
  tracker: Tracker
}

const eventTracker = new Tracker({
  petTypeMall: (process.browser && localPetType.load()) || AdPetType.Dog,
})

const EventTrackingContext = createContext<EventTrackingState>({ tracker: eventTracker })

export const EventTrackingProvider = ({ children }: { children: React.ReactNode }) => {
  const { pathname } = useRouter()
  const { getPetType } = usePetType()
  const petType = getPetType()
  const tracker = useMemo(() => {
    if (petType) {
      eventTracker.setPetTypeMall(petType)
    }
    return eventTracker
  }, [petType])

  const { me } = UserContainer.useContainer()

  /**
   * ref를 사용해 최초 한번만 Tracker 초기화초기화
   */
  const initializedTracker = useRef(false)
  if (!initializedTracker.current && process.browser) {
    eventTracker.initialize()
    initializedTracker.current = true
  }

  const value = useMemo(() => ({ tracker }), [tracker])
  const isFirstRender = useRef(true)

  useEffect(() => {
    // 클라이언트 처음 시작 데이터 초기화
    tracker.setPlatform(getPlatform())
  }, [tracker])

  useEffect(() => {
    if (me && hasData(me)) {
      tracker.setUserId(decodeId(me?.id).id)
    }
  }, [tracker, me])

  useEffect(() => {
    if (petType) {
      tracker?.setUserProperties({ pet_type_mall: petType })
      tracker?.setPetTypeMall(petType)
    }
  }, [petType, tracker])

  useEffect(() => {
    // root url이 아닐 경우 routeChangeComplete이 바로 호출됨
    // root일 경우 isFirstRender를 미리 false 처리 -> 이 다음 페이지 이동 시 routeChangeComplete가 호출됨
    if (pathname === '/') {
      isFirstRender.current = false
    }
    // handle first page view
    tracker.triggerPageviewEvent(pathname)
  }, [])

  return <EventTrackingContext.Provider value={value}>{children}</EventTrackingContext.Provider>
}

export const useTracker = () => {
  const { tracker } = useContext(EventTrackingContext)
  return tracker
}
