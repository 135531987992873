import { FC } from 'react'
import { ImageAsset } from '@/components/common'
import { SocialServiceSocialType } from '@/graphql/generated/schemas'
import { getSocialCircleIcon } from '@/utils/utilLogin'

type SocialIconProps = {
  socialType?: SocialServiceSocialType
}

const SocialIcon: FC<SocialIconProps> = ({ socialType }) => {
  return <ImageAsset name={getSocialCircleIcon(socialType)} size={24} svg />
}

export default SocialIcon
