import { FC, Dispatch, SetStateAction, useEffect } from 'react'
import styled, { useTheme } from 'styled-components'
import FlatIcon from '@/components/common/icons/FlatIcon'
import { GlobalColorEnum } from '@/constants/global-color.enum'
import { IconNameEnum } from '@/constants/icon-name.enum'
import ROUTES from '@/constants/legacy/constRoutes'
import { useCustomRouter } from '@/containers/hooks'
import { isInApp } from '@/utils/utilCommon'
import { localRecentlyProductItems } from '@/utils/utilLocalStorage'

type ButtonScrollTopProps = {
  isAppToggle?: Dispatch<SetStateAction<boolean>>
  showTopButton: boolean
}

const ButtonScrollTop: FC<ButtonScrollTopProps> = ({ isAppToggle, showTopButton }) => {
  const { ableToShowScrollTopButton, pathname } = useCustomRouter()
  const localRecentlyItems = localRecentlyProductItems.load()
  const { iconSize, color } = useTheme()

  useEffect(() => {
    if (isAppToggle) {
      isAppToggle(isInApp())
    }
  }, [isInApp])

  if (!ableToShowScrollTopButton()) {
    return null
  }

  return (
    <StyledButton
      className={localRecentlyItems.length > 0 && pathname === ROUTES.INDEX ? '' : 'expand'}
      show={showTopButton}
      onClick={() => window.scrollTo({ left: 0, top: 0, behavior: 'smooth' })}
    >
      <FlatIcon type={IconNameEnum.IcTop} size={iconSize.size16} color={color.gray700} />
    </StyledButton>
  )
}

const StyledButton = styled.div<{ show: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4rem;
  height: 4rem;
  border: 1px solid ${GlobalColorEnum.Gray100};
  border-radius: 50%;
  background-color: ${(props) => props.theme.color.grayWhite};
  pointer-events: auto;
  box-shadow: 0.2rem 0.4rem 0.4rem rgba(0, 0, 0, 0.04);
  cursor: pointer;
  opacity: 1;

  @keyframes scaleInButton {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }

  @keyframes hideButton {
    100% {
      opacity: 0;
    }
  }

  // 사라질땐 opacity가 0이 되면서 사라지고 다시 나타날땐 opacity가 1인 상태로 scale이 커져야 하기 떄문에 원복용으로 생성한 애니메이션
  @keyframes restoreAfterHide {
    100% {
      opacity: 1;
      transform: scale(0);
    }
  }

  &.expand {
    animation: ${({ show }) =>
      show
        ? 'scaleInButton 0.3s cubic-bezier(0.65, 0, 0.35, 1) forwards'
        : 'hideButton 0.3s cubic-bezier(0.65, 0, 0.35, 1) forwards, 0s scaleOutButton 0.4s backwards'};
  }
`

export default ButtonScrollTop
