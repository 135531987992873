import moment from 'moment'
import { CouponDiscountTypeEnum } from '@/constants/coupon-discount-type.enum'
import { CouponTypeEnum } from '@/constants/coupon-type.enum'
import { SktPaperCouponTitle } from '@/constants/coupon.const'
import { IModelCouponGroupDefault } from '@/containers/models/base/defaultModelInterfaces'
import { ModelCouponGroupDefault } from '@/containers/models/base/defaultModels'
import { CouponGroupIssueType } from '@/graphql/generated/schemas'
import { round } from '@/utils/utilCommon'
import { addDay, date2string, DATE_FORMAT_MMDDHHmm_KOREAN, DATE_FORMAT_YYYY_MM_DD } from '@/utils/utilDate'
import { currencyText, percentText } from '@/utils/utilNumber'

export interface IModelCouponGroup extends IModelCouponGroupDefault {
  getBenefitText: () => string
  getExpiredDateText: () => string
  getExpiredDate: () => string
  getExpiredTextInMonth: () => string
  getConditionText: () => string
  getConjunctUseText: () => string | undefined

  doesPassCondition: (totalProductAmount: number, isInAndroidOrIos: boolean) => boolean
}

export default class ModelCouponGroup extends ModelCouponGroupDefault {
  doesPassCondition = (totalProductAmount: number, isInAndroidOrIos: boolean) => {
    if (this.hasMinOrderAmount && this.minOrderAmount > totalProductAmount) {
      return false
    }
    if (this.coverageOsType == 'ALL') {
      return true
    }
    if (isInAndroidOrIos) {
      return this.coverageOsType === 'APP'
    }
    return this.coverageOsType === 'WEB'
  }

  getConditionText = () => {
    let condText = ''

    if (this.hasMinOrderAmount) {
      condText += `${currencyText(this.minOrderAmount)} 이상 구매 시 사용 가능, `
    }

    if (this.maxDiscountAmount > 0) {
      condText += `최대 ${currencyText(this.maxDiscountAmount)}까지 사용 가능, `
    }

    if (this.coverageOsType === 'ALL') {
      condText += 'PC+모바일'
    } else if (this.coverageOsType === 'APP') {
      condText += '모바일'
    } else {
      // WEB
      condText += 'PC'
    }

    return condText
  }

  getConjunctUseText = () => {
    if (this.canConjunctUse) {
      return undefined
    }

    return '중복 사용 불가능'
  }

  getBenefitText = () => {
    let prefixText = ''
    if (this.couponType === CouponTypeEnum.Order) {
      prefixText = '주문'
    } else if (this.couponType === CouponTypeEnum.Product) {
      prefixText = '상품'
    } else if (this.couponType === CouponTypeEnum.Shipping) {
      prefixText = '배송비'
    }

    let benefitText = ''
    if (this.discountType === CouponDiscountTypeEnum.Amount) {
      benefitText += `${currencyText(this.discountAmount)}`
    } else {
      benefitText += `${percentText(this.discountRate)}`
    }

    if (this.issueType === CouponGroupIssueType.PaperSkt) {
      return SktPaperCouponTitle
    }

    return `${prefixText} 할인 ${benefitText}`
  }

  getExpiredTextInMonth = () => {
    const now = moment()
    const _expiredAt = moment(this.getExpiredDate()).utc()
    const duration = moment.duration(_expiredAt.diff(now))

    // @ts-ignore
    const daysRemaining = round(duration.asDays(), 0)
    if (daysRemaining > 29) {
      return undefined
    }

    if (daysRemaining === 0) {
      return '오늘 만료'
    }
    return `${daysRemaining + 1}일 남음`
  }

  getExpiredDate = () => {
    if (this.hasUsingPeriod) {
      return addDay(this.usingPeriod, undefined, DATE_FORMAT_YYYY_MM_DD)
    }
    // @ts-ignore
    return this.usingEndedAt
  }

  getExpiredDateText = () => {
    let expiredDate = this.getExpiredDate()
    return `${date2string(expiredDate!, DATE_FORMAT_MMDDHHmm_KOREAN)} 까지`
  }
}
