import { FieldKeysEnum } from '@/constants/field-keys.enum'
import { IModelReviewDefault } from '@/containers/models/base/defaultModelInterfaces'
import { ModelReviewDefault } from '@/containers/models/base/defaultModels'
import { ModelOrderItem } from '@/containers/models/index'
import { IModelOrderItem } from '@/containers/models/modelOrderItem'
import { IModelUser } from '@/containers/models/modelUser'
import { getFullUrl } from '@/utils/utilData'
import { BOARD_DATE_DISPLAY_FORMAT, date2string } from '@/utils/utilDate'

export interface IModelReview extends IModelReviewDefault {
  likedUsers: IModelUser[]
  orderItem: IModelOrderItem

  initFormValues: () => any
  getFormDefaultImages: () => any

  getLikedCount: () => number
  getProductName: () => string
  getProductNumber: () => string
  getWrittenDateFormat: () => string
  getRewardedMileage: () => number
}

export default class ModelReview extends ModelReviewDefault {
  orderItem: IModelOrderItem = {} as IModelOrderItem

  constructor(data: IModelReview) {
    super(data)
    this.orderItem = new ModelOrderItem(
      data.orderItem ? data.orderItem : ({} as IModelOrderItem)
    ) as unknown as IModelOrderItem
  }

  getRewardedMileage = () => {
    return this.orderItem.getRewardedOrderConfirmMileage() + this.mileage
  }

  initFormValues = () => {
    return {
      [FieldKeysEnum.Id]: this.id,
      [FieldKeysEnum.Score]: this.score,
      [FieldKeysEnum.ReviewBody]: this.reviewBody,
    }
  }

  getFormDefaultImages = () => {
    return this.reviewReviewImages.map((r) => ({
      uid: r.id,
      name: r.id,
      url: getFullUrl(r.image),
    }))
  }

  getLikedCount = () => {
    // @ts-ignore
    return this.likedUsers.totalCount || 0
  }

  getProductName = () => {
    return this.product.name
  }

  getProductNumber = () => {
    return this.product.productNumber
  }

  getWrittenDateFormat = () => {
    return `${date2string(this.createdAt!, BOARD_DATE_DISPLAY_FORMAT)} 작성`
  }
}
