import styled, { useTheme } from 'styled-components'
import { Typo, TypoVariant } from '@/components/common/typography'

interface RankingLabelProps {
  value: number
}

export const RankingLabel = ({ value }: RankingLabelProps) => {
  const { color } = useTheme()
  return (
    <RankingLabelWrapper>
      <Typo variant={TypoVariant.Body3Bold} color={color.grayWhite}>
        {value}
      </Typo>
    </RankingLabelWrapper>
  )
}

const RankingLabelWrapper = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  padding: 0 0.6rem;
  border-radius: 0.8rem 0 0.8rem 0;
  opacity: 0.4;
  background: ${({
    theme: {
      color: { grayBlack },
    },
  }) => grayBlack};
`
