import { gql } from '@apollo/client'
import USER_FRAGMENTS from '@/containers/gqls/accounts/user/fragments'

const USER_QUERY_GQLS = {
  USER_WITH_PASSWORD: gql`
    query userWithPassword($password: String!) {
      userWithPassword(password: $password) {
        ...userProfile
      }
    }
    ${USER_FRAGMENTS.userProfile}
  `,
  USER: gql`
    query user($id: ID!) {
      user(id: $id) {
        ...userProfile
      }
    }
    ${USER_FRAGMENTS.userProfile}
  `,
  USER_MY_SHOPPING_INFO: gql`
    query user($id: ID!) {
      user(id: $id) {
        id
        orderCount
        reviewCount
        likedCount
        writableReviewCount
        ongoingOrderCount
        couponCount
        cartItemCount
        mileagePoint
      }
    }
  `,
  USER_CAN_BUY_DEAL100: gql`
    query user($id: ID!) {
      user(id: $id) {
        canBuyDeal100
      }
    }
  `,
  USER_ORDER_BENEFITS: gql`
    query userOrderBenefits($id: ID!) {
      user(id: $id) {
        id
        mileage {
          point
        }
        isUseAllMileage
        couponCount
        coupons {
          totalCount
          edges {
            node {
              id
            }
          }
        }
      }
    }
  `,
  USER_MILEAGE_INFO: gql`
    query userMileageInfo($id: ID!) {
      user(id: $id) {
        id
        mileagePoint
        unusedMileage
      }
    }
  `,
  USER_BANK_ACCOUNT_INFO: gql`
    query userBankAccountInfo($id: ID!) {
      user(id: $id) {
        id
        accountNumber
        accountHolderName
        bankCode
        bankName
      }
    }
  `,
  LEAVE_REASONS: gql`
    query leaveReasons {
      leaveReasons {
        leaveReasons
      }
    }
  `,
  REVIEW_PROMOTION_INFO: gql`
    query reviewPromotionInfo {
      reviewPromotionInfo
    }
  `,
  USER_RESTOCKS: gql`
    query userRestocks($id: ID!) {
      user(id: $id) {
        id
        userRestocks {
          edges {
            node {
              id
              requestedAt
              informedAt
              productOption {
                id
              }
            }
          }
        }
      }
    }
  `,
}

export default USER_QUERY_GQLS
