import { PlatformDetailEnum } from '@/constants/platform-detail.enum'
import { InAppBridge } from '@/utils/bridge'
import { FitpetMallEventEnum } from '@/utils/event-tracker/events'
import { BaseEventHandler } from '@/utils/event-tracker/handlers/baseEventHandler'
import { isInApp } from '@/utils/utilCommon'

export class AppsflyerHandler extends BaseEventHandler {
  private appBridge: InAppBridge = new InAppBridge()
  initialize = () => {
    this.appBridge.init()
    this.logger.info('Appsflyer client is initialized.')
  }
  // Todo : 실제로 appsflyer에 보낼 필요가 없는 이벤트들이 많아 마케팅 팀의 도움을 받아 사용하지 않는 이벤트들은 삭제해야합니다.
  private appsFlyerEvents: Array<FitpetMallEventEnum> = [
    FitpetMallEventEnum.ViewHome,
    FitpetMallEventEnum.ViewCategory,
    FitpetMallEventEnum.ViewBrandshop,
    FitpetMallEventEnum.ViewProductdetail,
    FitpetMallEventEnum.ViewCart,
    FitpetMallEventEnum.ClickProductdetailShareButton,
    FitpetMallEventEnum.CompleteLogin,
    FitpetMallEventEnum.CompleteSignup,
    FitpetMallEventEnum.CompletePurchase,
    FitpetMallEventEnum.FirstCompletePurchase,
    FitpetMallEventEnum.Garbage,
    FitpetMallEventEnum.ClickProductdetailRestockButton,
    FitpetMallEventEnum.ClickProductdetailRestockcancelButton,
    FitpetMallEventEnum.ClickProductdetailOptionrestockButton,
    FitpetMallEventEnum.ClickProductdetailOptionrestockcancelButton,
    FitpetMallEventEnum.ClickProductdetailGotorestockButton,
    FitpetMallEventEnum.ClickProductdetailStartpurchaseButton,
    FitpetMallEventEnum.ClickProductdetailGotocartButton,
    FitpetMallEventEnum.ViewRestockproduct,
    FitpetMallEventEnum.ClickRestockproductProduct,
    FitpetMallEventEnum.ViewSearch,
    FitpetMallEventEnum.ClickSearchRecentKeyword,
    FitpetMallEventEnum.ClickSearchAutoKeyword,
    FitpetMallEventEnum.ClickSearchBrand,
    FitpetMallEventEnum.ClickSearchPopularKeyword,
    FitpetMallEventEnum.ViewSearchResult,
    FitpetMallEventEnum.ClickMypageBanner,
    FitpetMallEventEnum.ViewFriendevent,
    FitpetMallEventEnum.ClickFriendeventCodecopy,
    FitpetMallEventEnum.ClickFriendeventLinkshare,
    FitpetMallEventEnum.ClickFriendeventKakaoinvite,
    FitpetMallEventEnum.ViewInvitedFriendevent,
    FitpetMallEventEnum.ClickFriendeventSignupButton,
    FitpetMallEventEnum.ClickOrdercompleteBanner,
    FitpetMallEventEnum.ClickSearchRecommendKeyword,
    FitpetMallEventEnum.ClickMypageMarketingAgreement,
  ]

  sendEvent = (event: FitpetMallEventEnum, platform: PlatformDetailEnum, properties: Record<string, any>) => {
    if (isInApp()) {
      const data = { eventName: event, eventParam: properties }
      this.appBridge?.appsFlyerEvent(data)
    }
  }

  /**
   * @deprecated: 구 버전의 triggerCustomEvent에 사용되는 이벤트 트래커 지정 방식입니다.
   */
  sendCustomEvent = (event: FitpetMallEventEnum, properties: Record<string, any>, platform: PlatformDetailEnum) => {
    if (!this.appsFlyerEvents.includes(event)) {
      return
    }
    this.sendEvent(event, platform, properties)
  }
}
