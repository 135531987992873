const RegexUtils = {
  /**
   * 정규표현식에서 사용되는 특수문자 빈문자열로 치환
   *
   * @param {string} string
   * @returns {string}
   */
  escapeRegExp(string: string): string {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '')
  },
  // 사용자 입력값에서 하이픈 제거 및 숫자만 추출
  extractNumber(value: string): string {
    return value.replace(/[^0-9]/g, '')
  },
}

export { RegexUtils }
