import React, {
  ElementType,
  PropsWithChildren,
  ComponentPropsWithoutRef,
  forwardRef,
  ComponentPropsWithRef,
} from 'react'
import { ValueOf } from 'type-fest'
import { GlobalTheme } from '@/styles/globalTheme'

const TypoVariant = {
  Heading1: 'Heading1 Bold',
  Heading2: 'Heading2 Bold',
  Heading3: 'Heading3 Bold',
  Heading4: 'Heading4 Bold',
  Heading5: 'Heading5 Bold',
  Heading6Bold: 'Heading6 Bold',
  Heading6SemiBold: 'Heading6 SemiBold',
  Heading6Regular: 'Heading6 Regular',
  Body1Bold: 'Body1 Bold',
  Body1SemiBold: 'Body1 SemiBold',
  Body1Medium: 'Body1 Medium',
  Body1Regular: 'Body1 regular',
  Body2Bold: 'Body2 Bold',
  Body2SemiBold: 'Body2 SemiBold',
  Body2Medium: 'Body2 Medium',
  Body2Regular: 'Body2 Regular',
  Body3Bold: 'Body3 Bold',
  Body3SemiBold: 'Body3 SemiBold',
  Body3Medium: 'Body3 Medium',
  Body3Regular: 'Body3 Regular',
  Body4Bold: 'Body4 Bold',
  Body4SemiBold: 'Body4 SemiBold',
  Body4Medium: 'Body4 Medium',
  Body4Regular: 'Body4 Regular',
  Body5Bold: 'Body5 Bold',
  Body5SemiBold: 'Body5 SemiBold',
  Body5Medium: 'Body5 Medium',
  Body5Regular: 'Body5 Regular',
  Caption1Bold: 'Caption1 Bold',
  Caption1SemiBold: 'Caption1 SemiBold',
  Caption1Medium: 'Caption1 Medium',
  Caption1Regular: 'Caption1 Regular',
  Caption2Bold: 'Caption2 Bold',
  Caption2SemiBold: 'Caption2 SemiBold',
  Caption2Medium: 'Caption2 Medium',
  Caption2Regular: 'Caption2 Regular',
} as const

type TypoVariant = ValueOf<typeof TypoVariant>

type TypoVariantKeys = keyof typeof TypoVariant

type TypoProps<T extends ElementType> = {
  variant: TypoVariant
  color?: ValueOf<typeof GlobalTheme.color>
  as?: T
} & ComponentPropsWithoutRef<T>

type TypoComponent = <K extends ElementType = 'div'>(
  props: TypoProps<K> & {
    ref?: ComponentPropsWithRef<K>['ref']
  }
) => React.ReactElement

// @ts-ignore
// eslint-disable-next-line react/display-name
const Typo: TypoComponent = forwardRef(
  <T extends ElementType = 'div'>(
    { children, as, variant, color, ...props }: PropsWithChildren<TypoProps<T>>,
    ref: ComponentPropsWithRef<T>['ref']
  ) => {
    const appliedColor = color || GlobalTheme.color.gray900
    const Element = as || 'div'
    return (
      <Element ref={ref} {...props} className={variant} style={{ ...props.style, color: appliedColor }}>
        {children}
      </Element>
    )
  }
)

const isTypoVariant = (variant: string): variant is TypoVariant => {
  return Object.keys(TypoVariant).includes(variant)
}

export { Typo, TypoVariant, isTypoVariant }

export type { TypoVariantKeys }
