import { FC, useState } from 'react'
import { useRouter } from 'next/router'
import styled from 'styled-components'
import { DragScrollWrapper } from '@/components/common/etc'
import { BrandCircleItem } from '@/components/domains/search/index'
import { SkeletonPopularBrand } from '@/components/domains/search/skeleton'
import ROUTES from '@/constants/legacy/constRoutes'
import { PageSizeEnum } from '@/constants/page-size.enum'
import { QueryStringKeyEnum } from '@/constants/query-string-key.enum'
import { useTracker } from '@/containers/contexts/EventTrackingProvider'
import { useCustomRouter } from '@/containers/hooks'
import { usePopularBrandQuery } from '@/graphql/generated/hooks'
import { AdPetType, BrandType } from '@/graphql/generated/schemas'
import { FitpetMallEventEnum } from '@/utils/event-tracker/events'
import { decodeId } from '@/utils/utilApi'

const defaultPageSize = PageSizeEnum.PageSize20

const PopularBrands: FC = () => {
  const router = useCustomRouter()

  const [popularBrands, setPopularBrands] = useState<Array<BrandType>>([])
  const {
    query: { [QueryStringKeyEnum.SearchPetType]: searchPetType },
  } = useRouter()

  const { triggerCustomEvent } = useTracker()

  const { loading: isLoading } = usePopularBrandQuery({
    fetchPolicy: 'no-cache',
    skip: !searchPetType,
    variables: {
      filter: {
        is_approved: true,
        status: '{"include": ["APPROVED"]}',
      },
      petType: searchPetType as AdPetType,
      first: defaultPageSize,
    },
    onCompleted(data) {
      if (!data) {
        return
      }
      setPopularBrands(data.brands?.edges.map((e) => ({ ...(e?.node as BrandType) })) || [])
    },
  })

  const clickBrandImageHandler = (brandName: string, brandId: string, index: number) => () => {
    triggerCustomEvent(FitpetMallEventEnum.ClickSearchBrand, {
      brandId: decodeId(brandId).id,
      brandName,
      searchKeyword: brandName,
      listIndex: index,
      petTypeSearch: searchPetType,
    })

    router.push({
      pathname: ROUTES.BRAND_DETAIL,
      query: { brandId, location: 'search' },
    })
  }

  return (
    <>
      <StyledPopularBrandTitle>
        <StyledTitle>인기 브랜드</StyledTitle>
      </StyledPopularBrandTitle>
      <StyledBrandListContainer>
        <DragScrollWrapper>
          {!isLoading ? (
            <StyledBrandList>
              {popularBrands.map((brand, index) => (
                <BrandCircleItem
                  key={index}
                  node={brand}
                  onClick={clickBrandImageHandler(brand.name, brand.id, index)}
                />
              ))}
            </StyledBrandList>
          ) : (
            <SkeletonPopularBrand />
          )}
        </DragScrollWrapper>
      </StyledBrandListContainer>
    </>
  )
}

const StyledPopularBrandTitle = styled.div`
  padding-left: 1.6rem;
`

const StyledTitle = styled.span`
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.02em;
  color: ${(props) => props.theme.color.gray900};
`

const StyledBrandListContainer = styled.div`
  margin-block-start: 12px;
`

const StyledBrandList = styled.div`
  min-width: max-content;
  padding: 0 16px;
  padding-inline-end: 50px;
`

export default PopularBrands
