import { gql } from '@apollo/client'
import DEFAULT_FRAGMENTS from '@/containers/gqls/base/defaultFragments'
import BRAND_FRAGMENTS from '@/containers/gqls/brands/fragments'
import CATEGORY_FRAGMENTS from '@/containers/gqls/products/categories/fragments'
import PRODUCT_FRAGMENTS from '@/containers/gqls/products/fragments'
import REVIEW_FRAGMENTS from '@/containers/gqls/reviews/fragments'

export const PRODUCT_QUERY_GQLS = {
  SEARCH_PRODUCT_NAME_LIST: gql`
    query searchProductNameList($pageInfo: PageInfoInputType, $filter: GenericScalar) {
      searchedProducts(pageInfo: $pageInfo, filter: $filter) {
        pageInfo {
          ...pageInfo
        }
        totalCount
        edges {
          node {
            id
            name
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.pageInfo}
  `,
  SEARCH_PRODUCTS: gql`
    query searchProducts($text: String!, $petType: String!, $pageInfo: PageInfoInputType, $filter: GenericScalar) {
      searchProductsWithDetails(text: $text, petType: $petType, pageInfo: $pageInfo, filter: $filter) {
        pageInfo {
          ...pageInfo
        }
        totalCount
        soldOutCount
        maxPrice
        edges {
          node {
            ...searchProductsDefaultItem
          }
        }
        categories {
          ...searchFilterCategory
        }
        brands {
          ...searchFilterBrand
        }
      }
    }
    ${DEFAULT_FRAGMENTS.pageInfo}
    ${PRODUCT_FRAGMENTS.searchProductsDefaultItem}
    ${CATEGORY_FRAGMENTS.searchFilterCategory}
    ${BRAND_FRAGMENTS.searchFilterBrand}
  `,
  SEARCHED_KEYWORDS: gql`
    query searchedKeywords($keyword: String, $petType: String) {
      searchedKeywords(keyword: $keyword, petType: $petType)
    }
  `,
  POPULAR_BRANDS: gql`
    query brands($filter: GenericScalar, $first: Int = 20, $petType: String) {
      brands(filter: $filter, first: $first, petType: $petType) {
        totalCount
        edges {
          node {
            id
            name
            listImage
            backgroundImage
          }
        }
      }
    }
  `,
  POPULAR_KEYWORDS: gql`
    query popularKeywords($petType: String) {
      popularKeywords(petType: $petType) {
        totalCount
        edges {
          node {
            keyword
            rank
            changeRank
            isNewRank
          }
        }
      }
    }
  `,
  PRODUCT_LIST: gql`
    query productList($pageInfo: PageInfoInputType, $filter: GenericScalar, $orderBy: String) {
      products(pageInfo: $pageInfo, filter: $filter, orderBy: $orderBy) {
        pageInfo {
          ...pageInfo
        }
        totalCount
        edges {
          node {
            ...productListDefaultItem
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.pageInfo}
    ${PRODUCT_FRAGMENTS.productListDefaultItem}
  `,
  PRODUCT_INFO_FOR_INQUIRY: gql`
    query productInfoForInquiry($id: ID!, $productPromotion: ID) {
      product(id: $id, productPromotion: $productPromotion) {
        id
        name
        mainImage {
          id
          thumbnail(productImageType: "NORMAL")
        }
        productProductOptions(isDelete: false, includeNotUse: false) {
          totalCount
          edges {
            node {
              ...productOption
            }
          }
        }
      }
    }
    ${PRODUCT_FRAGMENTS.productOption}
  `,
  PRODUCT_PRODUCT_OPTIONS: gql`
    query productProductOptions($id: ID!, $productPromotion: ID) {
      product(id: $id, productPromotion: $productPromotion) {
        id
        isRealSoldOut
        isSoldOut
        isSingleOption
        name
        pricePerSku
        maxProductOptionSkuCount
        productPromotion {
          id
          productPromotionType
        }
        status {
          value
        }
        categories {
          edges {
            node {
              id
              name
            }
          }
        }
        shippingPreset {
          freeShippingBaseAmount
        }
        mainImage {
          image
        }
        productProductImages {
          edges {
            node {
              image
              thumbnail
              displayType
            }
          }
        }
        productProductOptions(isDelete: false, includeNotUse: false) {
          totalCount
          edges {
            node {
              ...productOption
              productOptionSkus {
                edges {
                  node {
                    sku {
                      id
                      code
                      stockQuantity
                    }
                    count
                  }
                }
              }
            }
          }
        }
        supplyType
      }
    }
    ${PRODUCT_FRAGMENTS.productOption}
  `,
  PRODUCT_BENEFITS: gql`
    query productBenefits($id: ID!, $productPromotion: ID) {
      product(id: $id, productPromotion: $productPromotion) {
        id
        productPromotionCustomerPrice
        maxDiscountCoupon {
          id
          couponGroup {
            name
            discountType
            discountAmount
            discountRate
            maxDiscountAmount
            couponType
            issueType
          }
        }
        reviewMileage
        reviewMileageRate
        orderConfirmMileage
        orderConfirmMileageRate
        mileage
        mileageRate
        membershipMileage
        imageReviewMileage
        imageReviewMileageRate
        membershipOrderConfirmMileage
        membershipOrderConfirmMileageRate
        membershipOrderConfirmDiffMileage
        membershipOrderConfirmDiffMileageRate
      }
    }
  `,
  PRODUCT_DETAIL: gql`
    query productDetail($id: ID!, $productPromotion: ID, $orderBy: String) {
      product(id: $id, productPromotion: $productPromotion) {
        id
        name
        price
        productNumber
        customerPrice
        discountRate
        description
        averageReviewScore
        finalDiscountRate
        reviewCount
        productPromotionCustomerPrice
        isRealSoldOut
        isSoldOut
        isTimesale
        shippingGuide
        shippingNotice
        searchKeywords
        supplyType
        isSingleOption
        isLiked
        likedCount
        mileage
        petType
        pricePerSku
        maxProductOptionSkuCount
        supplyType
        ingredientTypes
        feedCategoryType
        status {
          value
        }
        categories {
          edges {
            node {
              id
              name
              depth
              description
              text
            }
          }
        }
        brand {
          ...brandMinimum
        }
        seller {
          id
          companyName
          isFitpet
        }
        shippingPreset {
          id
          shippingFee
          guide
          notice
          freeShippingBaseAmount
          shippingDeadline
          shippingCompany {
            name
          }
        }
        productPromotion {
          id
          startedAt
          endedAt
          isDelete
          minOrderAmount
          boughtCount
          maxOrderCount
          discountPrice
          discountRate
          productPromotionType
          displayProductInfo {
            displayCollection {
              name
              ad {
                name
              }
            }
          }
        }
        productReviews(first: 3) {
          edges {
            node {
              ...reviewList
              reviewReviewImages {
                edges {
                  node {
                    ...reviewImage
                  }
                }
              }
            }
          }
        }
        productProductImages {
          edges {
            node {
              id
              displayType
              image
            }
          }
        }
        productProductOptions(isDelete: false, includeNotUse: false, orderBy: $orderBy) {
          totalCount
          edges {
            node {
              ...productOption
              productOptionSkus {
                edges {
                  node {
                    sku {
                      id
                      code
                      stockQuantity
                    }
                    count
                  }
                }
              }
            }
          }
        }
      }
    }
    ${BRAND_FRAGMENTS.brandMinimum}
    ${PRODUCT_FRAGMENTS.productOption}
    ${REVIEW_FRAGMENTS.reviewList}
    ${REVIEW_FRAGMENTS.reviewImage}
  `,
  PRODUCT_LIST_IN_CART: gql`
    query productListInCart($filter: GenericScalar) {
      products(filter: $filter) {
        totalCount
        edges {
          node {
            ...productListDefaultItem
            productProductOptions(isDelete: false, includeNotUse: false) {
              totalCount
              edges {
                node {
                  ...productOption
                }
              }
            }
          }
        }
      }
    }
    ${PRODUCT_FRAGMENTS.productOption}
    ${PRODUCT_FRAGMENTS.productListDefaultItem}
  `,
  CART_PRODUCT_LIST_COUNT: gql`
    query cartProductListCount($pageInfo: PageInfoInputType, $filter: GenericScalar) {
      cartItems(pageInfo: $pageInfo, filter: $filter) {
        totalCount
      }
    }
  `,
  CART_PRODUCT_LIST: gql`
    query cartProductList($pageInfo: PageInfoInputType, $filter: GenericScalar) {
      cartItems(pageInfo: $pageInfo, filter: $filter) {
        totalCount
        edges {
          node {
            id
            quantity
            productOption {
              ...productOption
              product {
                ...productListDefaultItem
                seller {
                  id
                  shopName
                }
                brand {
                  id
                  name
                }
                status {
                  value
                }
                shippingPreset {
                  id
                }
              }
            }
            productPromotion {
              ...productPromotion
            }
          }
        }
      }
    }
    ${PRODUCT_FRAGMENTS.productOption}
    ${PRODUCT_FRAGMENTS.productListDefaultItem}
    ${PRODUCT_FRAGMENTS.productPromotion}
  `,
  PRODUCT_QNA_LIST: gql`
    query productQnas($pageInfo: PageInfoInputType, $filter: GenericScalar) {
      productQnas(pageInfo: $pageInfo, filter: $filter) {
        pageInfo {
          ...pageInfo
        }
        totalCount
        edges {
          node {
            user {
              id
            }
            answeredUser {
              username
            }
            productOption {
              ...productOption
            }
            answeredUserType
            ...productQna
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.pageInfo}
    ${DEFAULT_FRAGMENTS.productQna}
    ${PRODUCT_FRAGMENTS.productOption}
  `,
  PRODUCT_RETURN_EXCHANGE_GUIDE: gql`
    query productReturnExchangeGuide($id: ID!) {
      product(id: $id) {
        seller {
          returnExchangeGuide
        }
      }
    }
  `,
  RECOMMENDED_PRODUCTS: gql`
    query recommendedProducts($ids: [ID]!) {
      recommendedProducts(ids: $ids) {
        edges {
          node {
            id
            name
            price
            productNumber
            customerPrice
            discountRate
            description
            averageReviewScore
            finalDiscountRate
            reviewCount
            productPromotionCustomerPrice
            isRealSoldOut
            isSoldOut
            isTimesale
            shippingGuide
            shippingNotice
            isSingleOption
            mileage
            pricePerSku
            maxProductOptionSkuCount
            mainImage {
              thumbnail
            }
          }
        }
      }
    }
  `,
  RECOMMENDED_CART_PRODUCTS: gql`
    query recommendedCartProducts($ids: [ID]!) {
      recommendedCartProducts(ids: $ids) {
        edges {
          node {
            id
            name
            price
            productNumber
            customerPrice
            discountRate
            description
            averageReviewScore
            finalDiscountRate
            reviewCount
            productPromotionCustomerPrice
            isRealSoldOut
            isSoldOut
            isTimesale
            shippingGuide
            shippingNotice
            isSingleOption
            mileage
            pricePerSku
            maxProductOptionSkuCount
            mainImage {
              thumbnail
            }
          }
        }
      }
    }
  `,
  RECOMMENDED_PRODUCT_DETAIL_PRODUCTS: gql`
    query recommendedProductDetailProducts($id: ID!) {
      recommendedProductDetailProducts(id: $id) {
        edges {
          node {
            id
            name
            price
            productNumber
            customerPrice
            discountRate
            description
            averageReviewScore
            finalDiscountRate
            reviewCount
            productPromotionCustomerPrice
            isRealSoldOut
            isSoldOut
            isTimesale
            shippingGuide
            shippingNotice
            isSingleOption
            mileage
            pricePerSku
            maxProductOptionSkuCount
            mainImage {
              thumbnail
            }
          }
        }
      }
    }
  `,
  RECOMMENDED_SIMILAR_PRODUCTS: gql`
    query recommendedSimilarProducts($id: ID!) {
      recommendedSimilarProducts(id: $id) {
        edges {
          node {
            id
            name
            price
            productNumber
            customerPrice
            discountRate
            description
            averageReviewScore
            finalDiscountRate
            reviewCount
            productPromotionCustomerPrice
            isRealSoldOut
            isSoldOut
            isTimesale
            shippingGuide
            shippingNotice
            isSingleOption
            mileage
            pricePerSku
            maxProductOptionSkuCount
            mainImage {
              thumbnail
            }
          }
        }
      }
    }
  `,
  LIKED_PRODUCTS: gql`
    query likedProductsWithPromotion($pageInfo: PageInfoInputType) {
      likedProductsWithPromotion(pageInfo: $pageInfo) {
        pageInfo {
          ...pageInfo
        }
        totalCount
        edges {
          node {
            ...likedProductsItem
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.pageInfo}
    ${PRODUCT_FRAGMENTS.likedProductsItem}
  `,
  LIKED_PRODUCT_IDS: gql`
    query likedProductsWithPromotion($pageInfo: PageInfoInputType) {
      likedProductsWithPromotion(pageInfo: $pageInfo) {
        pageInfo {
          ...pageInfo
        }
        totalCount
        edges {
          node {
            product {
              id
            }
            productPromotion {
              id
              productPromotionType
            }
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.pageInfo}
  `,
  RECENTLY_PRODUCTS: gql`
    query productsWithoutPetType($filter: GenericScalar, $pageInfo: PageInfoInputType) {
      productsWithoutPetType(pageInfo: $pageInfo, filter: $filter) {
        pageInfo {
          ...pageInfo
        }
        totalCount
        edges {
          node {
            productPromotion {
              id
            }
            ...productListDefaultItem
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.pageInfo}
    ${PRODUCT_FRAGMENTS.productListDefaultItem}
  `,
  RESTOCK_PRODUCT_OPTIONS: gql`
    query restockProductOption($pageInfo: PageInfoInputType, $filter: GenericScalar) {
      restockProductOptionUser(pageInfo: $pageInfo, filter: $filter) {
        edges {
          node {
            id
            requestedAt
            informedAt
            productOption {
              id
              name
              productId
            }
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
    }
    ${DEFAULT_FRAGMENTS.pageInfo}
  `,
  CART_PRODUCT_OPTIONS: gql`
    query cartProductOptions($productOptions: String) {
      productOptionsWithPromotion(productOptions: $productOptions) {
        edges {
          node {
            ...productOption
            product {
              id
              name
              isRealSoldOut
              isSoldOut
              status {
                value
              }
              brand {
                id
                name
              }
              supplyType
              categories {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
              productPromotion {
                id
                productPromotionType
              }
            }
          }
        }
      }
    }
    ${PRODUCT_FRAGMENTS.productOption}
  `,
  RECOMMENDED_RELATED_PRODUCTS: gql`
    query recommendedRelatedProducts($productId: ID!) {
      recommendedRelatedProducts(id: $productId) {
        edges {
          node {
            ...productListDefaultItem
          }
        }
        totalCount
      }
    }
    ${PRODUCT_FRAGMENTS.productListDefaultItem}
  `,
  RECOMMEND_BUNDLED_PRODUCTS: gql`
    query recommendBundledProducts($pageInfo: PageInfoInputType, $id: ID!) {
      recommendBundledProducts(pageInfo: $pageInfo, id: $id) {
        edges {
          node {
            ...productListDefaultItem
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
    }
    ${DEFAULT_FRAGMENTS.pageInfo}
    ${PRODUCT_FRAGMENTS.productListDefaultItem}
  `,
  BUNDLED_PRODUCTS: gql`
    query bundledProducts($pageInfo: PageInfoInputType, $filter: GenericScalar, $orderBy: String) {
      products(pageInfo: $pageInfo, filter: $filter, orderBy: $orderBy) {
        pageInfo {
          ...pageInfo
        }
        totalCount
        edges {
          node {
            ...productListDefaultItem
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.pageInfo}
    ${PRODUCT_FRAGMENTS.productListDefaultItem}
  `,
}
