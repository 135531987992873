import { useUserCouponsInfoQuery } from '@/graphql/generated/hooks'

export const useCoupons = () => {
  const {
    data: couponsData,
    refetch,
    loading: isCouponLoading,
  } = useUserCouponsInfoQuery({
    variables: {
      filter: {
        couponStatus: ['AVAILABLE'],
      },
    },
  })
  const couponGroups =
    couponsData?.coupons?.edges?.map(({ node }) => {
      return {
        id: node?.couponGroup?.id,
        couponType: node?.couponGroup?.couponType,
        name: node?.couponGroup?.name,
        discountAmount: node?.couponGroup?.discountAmount,
        discountRate: node?.couponGroup?.discountRate,
      }
    }) || []

  return { couponGroups, refetch, isCouponLoading }
}
