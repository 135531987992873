import { ParsedUrlQuery } from 'querystring'
import { useCallback, useEffect, useState } from 'react'
import { useInfiniteQuery } from '@tanstack/react-query'
import { useInView } from 'react-intersection-observer'
import { match } from 'ts-pattern'
import { createContainer } from 'unstated-next'
import { getGetSearchProductsV3QueryKey, getSearchProductsV3 } from '@/apis/rest/generated/hooks'
import { SearchProductResponseDtoV2 } from '@/apis/rest/generated/types'
import { useBackAction } from '@/components/common/back-action/use-back-action'
import { RelatedSearchedKeywords } from '@/components/domains/layouts/header/searchHeader.type'
import ROUTES from '@/constants/legacy/constRoutes'
import { PAGE_LIMIT } from '@/constants/pages.const'
import { useCustomRouter, useSearchResult } from '@/containers/hooks'
import { useGetQueryParamsSearchResult } from '@/containers/hooks/search/use-get-query-params-search-result'
import { useRelatedSearchedKeywordsQuery } from '@/graphql/generated/hooks'
import { encodeId } from '@/utils/utilApi'
import { localSearchFilterList } from '@/utils/utilLocalStorage'

export enum ConvertType {
  Brand = 'brand',
  Category = 'category',
  Product = 'product',
}

type SelectFilterListType = {
  category: string[]
  brand: string[]
  minPrice: number
  maxPrice: number
}

const searchResultContext = () => {
  const [searchData, setSearchData] = useState<SearchProductResponseDtoV2>(localSearchFilterList.load())
  const { ref, inView } = useInView()
  const [parentCategory, setParentCategory] = useState<string>('')
  const [selectFilterList, setSelectFilterList] = useState<SelectFilterListType>({
    category: [],
    brand: [],
    minPrice: 0,
    maxPrice: searchData?.maxPrice || 0,
  })
  const [relatedKeywords, setRelatedKeywords] = useState<RelatedSearchedKeywords>([])

  const [headerHeight, setHeaderHeight] = useState<number>(0)

  const { initSearchBodyMount } = useSearchResult()

  const { replace, query, pathname } = useCustomRouter()
  const { searchKeyword, petType, queryParams, filterCategory, filterBrand, filterPrice } =
    useGetQueryParamsSearchResult()

  const backAction = useBackAction('recommend-list-for-search-result')

  const convertFromBase64 = (str: string) => {
    return Number(atob(str).split(':')[1])
  }

  const convertToBase64 = (value: number | undefined, type: ConvertType) => {
    return match(type)
      .with(ConvertType.Brand, () => {
        return encodeId('BrandType', value)
      })
      .with(ConvertType.Category, () => {
        return encodeId('CategoryType', value)
      })
      .with(ConvertType.Product, () => {
        return encodeId('ProductType', value)
      })
      .exhaustive()
  }

  /*
  isRealSoldOut true로 보낼경우 isRealSoldOut가 false인 상품만 조회
  graphQL false로 보낼경우 isRealSoldOut가 false인 상품만 조회
   */

  // 검색 조건 변경시 반영안되고 최신데이터 이슈로 캐시 제거
  const {
    data: searchProducts,
    isSuccess,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    refetch,
    isLoading,
    remove,
  } = useInfiniteQuery(
    getGetSearchProductsV3QueryKey({
      ...queryParams,
    }),
    ({ pageParam = PAGE_LIMIT.DEFAULT_PAGE }) =>
      getSearchProductsV3({
        ...queryParams,
        size: PAGE_LIMIT.LIST,
        page: pageParam,
        sortSoldOutToLast: true,
      }),
    {
      getNextPageParam: (lastPage, allPages) => {
        return lastPage.products?.length === PAGE_LIMIT.LIST ? allPages.length + PAGE_LIMIT.DEFAULT_PAGE : undefined
      },
      onSuccess: (res) => {
        if (res.pages.length <= 1) {
          backAction.clear()
        }

        if (filterCategory || filterBrand || filterPrice) {
          return
        }

        setSearchData(res?.pages[0])
        localSearchFilterList.save(res.pages[0])
      },
    }
  )

  const { loading: relatedSearchKeywordLoading, data: relatedSearchKeyword } = useRelatedSearchedKeywordsQuery({
    variables: {
      keyword: searchKeyword as string,
      petType,
    },
    fetchPolicy: 'no-cache',
    onCompleted(data) {
      if (!data.relatedSearchedKeywords) return
      setRelatedKeywords(data.relatedSearchedKeywords)
    },
  })

  const getCategoryNameById = (categoryIds: string | null): string[] | null => {
    try {
      if (!categoryIds) return null
      const _categoryIds = categoryIds.split('_')
      const categoryFilterList = searchData?.categories

      return (categoryFilterList || []).flatMap((parentCategory) =>
        parentCategory.detailCategories
          .filter((category) => _categoryIds.includes(convertToBase64(category.id, ConvertType.Category)))
          .map((category) => category.name)
      )
    } catch (e) {
      return null
    }
  }

  const getBrandNameById = (brandIds: string | null): string[] | null => {
    try {
      if (!brandIds) return null

      const _brandIds = brandIds.split('_')
      const brandFilterList = searchData?.brands

      return (brandFilterList || [])
        .filter((brand) => _brandIds.includes(convertToBase64(brand?.id, ConvertType.Brand)))
        .map((brand) => brand.name)
    } catch (e) {
      return null
    }
  }

  const appendFilterQuery = (pathname: string, queries: ParsedUrlQuery) => {
    remove()
    replace({
      pathname: pathname,
      query: { ...query, ...queries },
    })
  }

  const headerRefCallback = useCallback(
    (node: HTMLDivElement) => {
      if (!relatedSearchKeyword || !node) return
      setTimeout(() => setHeaderHeight(node.offsetHeight))
    },
    [relatedSearchKeyword]
  )

  const totalCount = searchProducts?.pages[0].totalCount
  const soldOutCount = searchProducts?.pages[0].soldOutCount

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage()
    }
  }, [inView, fetchNextPage, hasNextPage])

  useEffect(() => {
    setParentCategory('')
    setSelectFilterList((prevState) => ({
      ...prevState,
      category: [],
      brand: [],
    }))
  }, [query])

  // 최근검색어 저장 로직
  useEffect(() => {
    if (pathname === ROUTES.SEARCH_RESULT && searchKeyword) {
      initSearchBodyMount()
    }
  }, [pathname, searchKeyword])

  return {
    ref,
    isSuccess,
    searchProducts,
    isFetchingNextPage,
    totalCount,
    soldOutCount,
    PAGE_LIMIT,
    refetch,
    isLoading,
    searchData,
    appendFilterQuery,
    getBrandNameById,
    convertToBase64,
    convertFromBase64,
    getCategoryNameById,
    isEmpty: totalCount === 0 && !isLoading,
    selectFilterList,
    parentCategory,
    setParentCategory,
    setSelectFilterList,
    headerRefCallback,
    headerHeight,
    relatedSearchKeywordLoading,
    relatedKeywords,
    petType,
    searchKeyword,
  }
}

export const SearchResultContainer = createContainer(searchResultContext)
