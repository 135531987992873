/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * Fitpet Mall
 * 핏펫몰 API 스펙

API 스펙 작성시 다음 가이드를 숙지하시기 바랍니다.

- [Open API 가이드 참고](https://swagger.io/docs/specification/about/)
- [Kotlin-Spring Open API Generator 설정 참고](https://openapi-generator.tech/docs/generators/kotlin-spring)
- REST API 디자인 가이드 참고
  - doc > guide > rest-api-design.md
- OpenAPI Spec 컨벤션 참고
  - doc > guid > openapi-spec-convention.md
- 설명(description) 작성시 마크다운([CommonMark](https://commonmark.org/help/)) 구문 참고

로컬에서 스웨거 에디터를 사용하는 방법은 다음과 같다.

```shell
docker pull swaggerapi/swagger-editor
docker run -d -p 8123:8080 swaggerapi/swagger-editor
open http://localhost:8123
```

Api 스펙을 하나의 파일로 합치려면 다음과 같이 실행한다.

```shell
# 설치
npm install -g swagger-cli

# 실행
swagger-cli bundle api-spec.yaml --outfile build/openapi.yaml --type yaml
```
 * OpenAPI spec version: 1.0.0
 */

/**
 * 은행코드

- `090`: 카카오뱅크
- `011`: NH농협은행
- `004`: KB국민은행
- `088`: 신한은행
- `020`: 우리은행
- `003`: IBK기업은행
- `081`: 하나은행
- `031`: 대구은행
- `039`: 경남은행
- `034`: 광주은행
- `032`: 부산은행
- `002`: KDB산업은행
- `045`: 새마을금고
- `007`: SH수협
- `027`: 한국씨티은행
- `071`: 우체국
- `037`: 전북은행
- `035`: 제주은행
- `089`: 케이뱅크
- `023`: SC제일은행
- `008`: 한국수출입은행
 */
export type FitpetPartnersBankCodeEnum = typeof FitpetPartnersBankCodeEnum[keyof typeof FitpetPartnersBankCodeEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FitpetPartnersBankCodeEnum = {
  NUMBER_090: '090',
  NUMBER_011: '011',
  NUMBER_004: '004',
  NUMBER_088: '088',
  NUMBER_020: '020',
  NUMBER_003: '003',
  NUMBER_081: '081',
  NUMBER_031: '031',
  NUMBER_039: '039',
  NUMBER_034: '034',
  NUMBER_032: '032',
  NUMBER_002: '002',
  NUMBER_045: '045',
  NUMBER_007: '007',
  NUMBER_027: '027',
  NUMBER_071: '071',
  NUMBER_037: '037',
  NUMBER_035: '035',
  NUMBER_089: '089',
  NUMBER_023: '023',
  NUMBER_008: '008',
} as const
