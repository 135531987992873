import { IModelShippingDefault } from '@/containers/models/base/defaultModelInterfaces'
import { ModelShippingDefault } from '@/containers/models/base/defaultModels'
import { IModelCoupon } from '@/containers/models/modelCoupon'
import { IModelOrderItem } from '@/containers/models/modelOrderItem'
import { IModelShippingCompany } from '@/containers/models/modelShippingCompany'
import { currencyText } from '@/utils/utilNumber'

export interface IModelShipping extends IModelShippingDefault {
  shippingOrderItems: IModelOrderItem[]
  shippingCoupons: IModelCoupon[]
  shippingCompany: IModelShippingCompany

  getSellerName: () => string
  getShippingOrderItemCount: () => number
  getShippingFee: (couponDcPrice?: number) => number
  getShippingFeeText: (couponDcPrice?: number) => string
  getShippingOrderItemTotalAmount: () => number
}

export default class ModelShipping extends ModelShippingDefault {
  getSellerName = () => {
    return this.shippingPreset.seller.shopName
  }

  getShippingOrderItemCount = () => {
    // @ts-ignore
    return this.shippingOrderItems.totalCount || 0
  }

  getShippingFee = (couponDcPrice?: number) => {
    let _amount = 0

    if (this.discountPrice > 0) {
      _amount = 0
    } else {
      _amount = this.fee
    }
    // if ( this.amount > 0 && this.extraFee > 0 ) {
    //   _amount = this.fee //지역 추가 배송지는 빼고 보여줌
    // } else {
    //   _amount = this.amount
    // }

    if (!couponDcPrice) {
      return _amount
    }

    if (couponDcPrice > _amount) {
      // 쿠폰가가 배송비보다 클 경우
      return 0
    }

    _amount -= couponDcPrice
    return _amount
  }

  getShippingFeeText = (couponDcPrice?: number) => {
    const _amount = this.getShippingFee(couponDcPrice)

    const amountText = currencyText(_amount)
    return _amount > 0 ? `+${amountText}` : amountText
  }

  getShippingOrderItemTotalAmount = () => {
    let totalAmount = 0

    this.shippingOrderItems.forEach(({ amount }) => {
      totalAmount += amount
    })

    return totalAmount
  }
}
