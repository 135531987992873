import { gql } from '@apollo/client'
import DEFAULT_FRAGMENTS from '@/containers/gqls/base/defaultFragments'
import ORDER_FRAGMENTS from '@/containers/gqls/orders/fragments'
import PRODUCT_FRAGMENTS from '@/containers/gqls/products/fragments'
import COUPON_FRAGMENTS from '@/containers/gqls/promotions/coupons/fragments'

export const ORDER_MUTATION_GQLS = {
  CONFIRM_ORDER_ITEM: gql`
    mutation confirmOrderItem($id: ID!) {
      confirmOrderItem(id: $id) {
        orderItem {
          ...orderItemMinimum
        }
      }
    }
    ${ORDER_FRAGMENTS.orderItemMinimum}
  `,
  CREATE_ORDER: gql`
    mutation createOrder($input: OrderInput!) {
      createOrder(input: $input) {
        order {
          ...order
          shippings {
            edges {
              node {
                couponInfo
                shippingOrderItems {
                  edges {
                    node {
                      couponInfo
                      quantity
                      productOption {
                        id
                        name
                        productPromotionCustomerPrice
                        product {
                          id
                          name
                          categories {
                            edges {
                              node {
                                id
                                name
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.order}
  `,
  ESTIMATE_ORDER_FOR_CART: gql`
    mutation estimateOrderForCart($input: EstimateOrderInput!) {
      estimateOrder(input: $input) {
        order {
          ...order
          user {
            orderCount
            canBuyDeal100
          }
          shippings {
            totalCount
            edges {
              node {
                ...shipping
                shippingPreset {
                  ...shippingPreset
                  seller {
                    id
                    shopName
                  }
                }
                shippingOrderItems {
                  totalCount
                  edges {
                    node {
                      id
                      orderStatus
                      orderStatusText
                      createdAt
                      productName
                      quantity
                      originalPrice
                      productDiscountPrice
                      couponDiscountPrice
                      amount
                      originalAmount
                      productDiscountAmount
                      refundAmount
                      customerPrice
                      productPromotionCustomerPrice
                      productPromotionCustomerAmount
                      productPromotionDiscountPrice
                      orderConfirmMileageRate
                      reviewMileageRate
                      imageReviewMileageRate
                      mileage
                      customerAmount
                      productPromotion {
                        ...productPromotion
                      }
                      productOption {
                        ...productOption
                        productOptionSkus {
                          edges {
                            node {
                              sku {
                                id
                                code
                                stockQuantity
                              }
                              count
                            }
                          }
                        }
                        product {
                          id
                          name
                          productNumber
                          isRealSoldOut
                          isSingleOption
                          stockQuantity
                          isSoldOut
                          status {
                            requestType
                            value
                          }
                          categories {
                            edges {
                              node {
                                id
                                name
                              }
                            }
                          }
                          mainImage {
                            id
                            thumbnail(productImageType: "NORMAL")
                          }
                          productProductOptions(isDelete: false, includeNotUse: false) {
                            totalCount
                            edges {
                              node {
                                id
                                isDefault
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    ${ORDER_FRAGMENTS.order}
    ${DEFAULT_FRAGMENTS.shipping}
    ${DEFAULT_FRAGMENTS.shippingPreset}
    ${PRODUCT_FRAGMENTS.productOption}
    ${PRODUCT_FRAGMENTS.productPromotion}
  `,
  ESTIMATE_ORDER_CALCULATION_ONLY: gql`
    mutation estimateOrderCalculationOnly($input: EstimateOrderInput!) {
      estimateOrder(input: $input) {
        order {
          ...order
          shippings {
            totalCount
            edges {
              node {
                ...shipping
                shippingOrderItems {
                  totalCount
                  edges {
                    node {
                      id
                      orderStatus
                      orderStatusText
                      createdAt
                      quantity
                      originalPrice
                      productDiscountPrice
                      couponDiscountPrice
                      amount
                      originalAmount
                      productDiscountAmount
                      refundAmount
                      customerPrice
                      productPromotionCustomerPrice
                      productPromotionDiscountPrice
                      customerAmount
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    ${ORDER_FRAGMENTS.order}
    ${DEFAULT_FRAGMENTS.shipping}
  `,
  ESTIMATE_ORDER: gql`
    mutation estimateOrder($input: EstimateOrderInput!) {
      estimateOrder(input: $input) {
        order {
          ...order
          user {
            orderCount
            canBuyDeal100
          }
          availableCoupons {
            totalCount
            edges {
              node {
                ...coupon
                couponGroup {
                  ...couponGroupInCouponList
                }
              }
            }
          }
          shippings {
            totalCount
            edges {
              node {
                ...shipping
                availableCoupons {
                  totalCount
                  edges {
                    node {
                      ...coupon
                      couponGroup {
                        ...couponGroupInCouponList
                      }
                    }
                  }
                }
                shippingPreset {
                  ...shippingPreset
                  seller {
                    id
                    shopName
                  }
                }
                shippingOrderItems {
                  totalCount
                  edges {
                    node {
                      id
                      orderStatus
                      orderStatusText
                      createdAt
                      productName
                      quantity
                      originalPrice
                      productDiscountPrice
                      couponDiscountPrice
                      amount
                      originalAmount
                      productDiscountAmount
                      refundAmount
                      customerPrice
                      productPromotionCustomerPrice
                      productPromotionDiscountPrice
                      customerAmount
                      reviewMileage
                      imageReviewMileage
                      orderConfirmMileage
                      orderConfirmMileageRate
                      membershipOrderConfirmDiffMileageRate
                      reviewMileageRate
                      imageReviewMileageRate
                      availableCoupons {
                        totalCount
                        edges {
                          node {
                            ...coupon
                            couponGroup {
                              ...couponGroupInCouponList
                            }
                          }
                        }
                      }
                      productPromotion {
                        ...productPromotion
                      }
                      productOption {
                        ...productOption
                        product {
                          id
                          name
                          productNumber
                          isRealSoldOut
                          stockQuantity
                          isSoldOut
                          status {
                            requestType
                            value
                          }
                          categories {
                            edges {
                              node {
                                id
                                name
                              }
                            }
                          }
                          mainImage {
                            id
                            thumbnail(productImageType: "NORMAL")
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    ${ORDER_FRAGMENTS.order}
    ${DEFAULT_FRAGMENTS.shipping}
    ${DEFAULT_FRAGMENTS.shippingPreset}
    ${PRODUCT_FRAGMENTS.productOption}
    ${PRODUCT_FRAGMENTS.productPromotion}
    ${DEFAULT_FRAGMENTS.coupon}
    ${COUPON_FRAGMENTS.couponGroupInCouponList}
  `,
  CANCEL_ORDER_ITEMS: gql`
    mutation cancelOrderItems($input: CancelOrderItemsInput!) {
      cancelOrderItems(input: $input) {
        orderItems {
          ...orderItemMinimum
        }
      }
    }
    ${ORDER_FRAGMENTS.orderItemMinimum}
  `,
  REQUEST_RETURN_ORDER_ITEMS: gql`
    mutation requestReturnOrderItems($input: RequestReturnOrderItemsInput!) {
      requestReturnOrderItems(input: $input) {
        orderItems {
          ...orderItemMinimum
        }
      }
    }
    ${ORDER_FRAGMENTS.orderItemMinimum}
  `,
  REQUEST_EXCHANGE_ORDER_ITEMS: gql`
    mutation requestExchangeOrderItems($input: RequestExchangeOrderItemsInput!) {
      requestExchangeOrderItems(input: $input) {
        orderItems {
          ...orderItemMinimum
        }
      }
    }
    ${ORDER_FRAGMENTS.orderItemMinimum}
  `,
}

export default ORDER_MUTATION_GQLS
