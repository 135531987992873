/**
 * HH는 24시간 표기
 * hh는 12시간 표기
 *
 */

export enum DateFormatEnum {
  'YY.MM.DD' = 'YY.MM.DD',
  'YYYY.MM.DD' = 'YYYY.MM.DD',
  'YY.MM.DD HH:mm' = 'YY.MM.DD HH:mm',
  'YYYY-MM-DD' = 'YYYY-MM-DD',
}
