import { ABTestGroupCodeEnum } from '@/apis/rest/generated/types'
import { BottomSheetModal } from '@/components/common/modals/BottomSheetModal'
import { CartConfirmation } from '@/components/domains/cart/cart-confirmation/CartConfirmation'
import { useCartBottomSheetRestReduxData } from '@/containers/hooks/cart/useCartBottomSheetRestReduxData'
import { ABTestGroupCase, useAbGroup } from '@/containers/hooks/useAbGroup'

export const CartConfirmationBottomSheet = ({ visible }: { visible: boolean }) => {
  const { getAbByKey } = useAbGroup()
  const { abCode } = getAbByKey({
    abKey: ABTestGroupCase.BOTTOM_SHEET_RECOMMENDATION_SLOT_IMPROVEMENT_ON_ADD_TO_CART,
  })

  const height = abCode === ABTestGroupCodeEnum.A ? 70 : 80

  const { resetCartBottomSheetReduxData } = useCartBottomSheetRestReduxData()
  return (
    <BottomSheetModal
      title=""
      titleNoBorder
      onCancel={() => {}}
      visible={visible}
      onClose={() => {
        resetCartBottomSheetReduxData()
      }}
      height={height}
    >
      <CartConfirmation />
    </BottomSheetModal>
  )
}
