import { IModelFakeWhoami } from '@/constants/legacy/constType'
import { EmailSigninMutation, TokenSigninMutation, UserGender } from '@/graphql/generated/schemas'
import { FitpetMallEventEnum } from '@/utils/event-tracker/events'
import {
  DATE_DISPLAY_FORMAT,
  DATE_FORMAT_YYYY_MM_DD,
  formatDate,
  YEAR_FORMAT,
  YEAR_MONTH_FORMAT,
} from '@/utils/utilDate'

type SetAtProps = FitpetMallEventEnum.CompleteSignup | FitpetMallEventEnum.CompleteLogin

const SET_AT = {
  // 회원가입 후 user attribute set
  [FitpetMallEventEnum.CompleteSignup]: [
    'birthyear',
    'gender',
    'signup_date',
    'signup_month',
    'marketing_allowed',
    'name',
    'email',
    'brithday',
    'phone',
    'is_member',
    'last_order_date',
  ],

  // 로그인 후 user attribute set
  [FitpetMallEventEnum.CompleteLogin]: [
    'last_login_date',
    'is_member',
    'last_order_date',
    'marketing_allowed',
    'marketing_night_allowed',
  ],
}

type UserPropertyType = {
  birthyear: string
  gender: string // 'm' | 'w' | 'u'
  signup_date: string
  signup_month: string
  marketing_allowed: boolean
  agree_pr_marketing: boolean
  marketing_night_allowed: boolean
  last_login_date: string
  name: string
  email: string
  birthday: string
  phone: string
  is_member: boolean
  last_order_date: string
}

const convertGender2String = (gender: UserGender) => {
  return UserGender.Female === gender ? 'w' : UserGender.Male ? 'm' : 'u'
}

export const convertUserProperties = (
  data: IModelFakeWhoami | EmailSigninMutation | TokenSigninMutation,
  setAt: SetAtProps
) => {
  // TODO typescript 오류 수정 필요
  // @ts-ignore
  const userData = data.user.data || data.user
  // @ts-ignore
  const deviceData = data.device.data || data.device

  const setList = SET_AT[setAt]
  const user = {} as UserPropertyType

  setList.forEach((x) => {
    if (x === 'birthyear') user.birthyear = formatDate(userData!.birthday, YEAR_FORMAT)
    if (x === 'gender') user.gender = convertGender2String(userData!.gender)
    if (x === 'signup_date') user.signup_date = formatDate(userData!.dateJoined, DATE_DISPLAY_FORMAT)
    if (x === 'signup_month') user.signup_month = formatDate(userData!.dateJoined, YEAR_MONTH_FORMAT)
    user.marketing_allowed = !!userData?.agreeMarketing
    user.agree_pr_marketing = !!userData?.agreePrMarketing
    if (x === 'marketing_night_allowed') user.marketing_night_allowed = !!userData!.agreeNightMarketing
    if (x === 'last_login_date') user.last_login_date = formatDate(deviceData!.lastLoggedAt, DATE_DISPLAY_FORMAT)
    if (x === 'name') user.name = userData!.name
    if (x === 'email') user.email = userData!.email
    if (x === 'birthday') user.birthday = formatDate(userData!.birthday, DATE_DISPLAY_FORMAT)
    if (x === 'phone') user.phone = userData!.mobileNumber
    if (x === 'is_member') user.is_member = true
    if (x === 'last_order_date') user.last_order_date = formatDate(userData!.lastOrderedAt, DATE_FORMAT_YYYY_MM_DD)
  })
  return user
}
