import { DealTypeEnum } from '@/constants/deal-type.enum'
import DataBase from '@/containers/gqls/data/dataBase'
import { IModelCartItemDefault, IModelOrderItemDefault } from '@/containers/models/base/defaultModelInterfaces'
import { ModelCartItemDefault } from '@/containers/models/base/defaultModels'
import { IModelProductOption } from '@/containers/models/modelProductOption'
import { IModelProductPromotion } from '@/containers/models/modelProductPromotion'
import { CartProductOptionType } from '@/containers/types/CartProductOption'

export interface IModelCartItem extends IModelCartItemDefault {
  productId: string
  product_id: string
  productName: string
  thumbnail: string
  productPromotionId: string | undefined

  productProductOptions: IModelProductOption[]

  productOption: IModelProductOption
  productPromotion: IModelProductPromotion
  productPromotionType: 'DEAL_100' | 'DEAL_990' | 'NORMAL'
}

export default class ModelCartItem extends ModelCartItemDefault {
  productId: string
  product_id: string
  productName: string
  thumbnail: string
  productPromotionId: string | undefined

  productOption: IModelProductOption
  productProductOptions: IModelProductOption[]

  selected: boolean
  productOptionCount: number
  productPromotionType: 'DEAL_100' | 'DEAL_990' | 'NORMAL'
  constructor(data: IModelCartItem) {
    super(data)
    if (!data.productPromotion && data.productOption.product.productPromotion) {
      this.productPromotion = data.productOption.product.productPromotion
      this.productPromotionId = this.productPromotion.id
    }

    this.productOption = DataBase.getModel(data.productOption)
    this.productProductOptions = DataBase.getListModel(data.productOption.product?.productProductOptions) || []

    this.productId = this.productOption.product.id
    this.product_id = this.productOption.product._id
    this.productName = this.productOption.product.name
    this.thumbnail = this.productOption.product.mainImage.thumbnail

    this.selected = true
    this.productOptionCount = data.productOption.product?.productProductOptions?.length || 0
    // TODO typescript 오류 수정 필요
    // @ts-ignore
    this.productPromotionType = data.productPromotion?.productPromotionType || DealTypeEnum.Normal
  }

  static getCartItemByProductOption = (
    cartItems: ModelCartItem[],
    productOptionId: string,
    dealType: DealTypeEnum = DealTypeEnum.Normal
  ): ModelCartItem | undefined => {
    cartItems.forEach((cartItem) => {
      const productPromotionType = cartItem.productPromotionType
      if (cartItem.productOption.id === productOptionId && productPromotionType === dealType) {
        return cartItem
      }
    })
    return undefined
  }

  getCartProductOption = (): CartProductOptionType => {
    const { productOption } = this
    const { product } = productOption
    const isSoldOut =
      product.isRealSoldOut || product.isSoldOut || productOption.stockQuantity === 0 || productOption.isSoldOut
    const disabled = product.status.value !== 'APPROVED' || !productOption.isUse || productOption.isDelete
    return {
      productId: this.productId,
      productName: product.name,
      productOptionId: productOption.id,
      productOption_id: productOption._id,
      productOptionName: productOption.optionName,
      stockQuantity: productOption.stockQuantity,
      isSoldOut,
      disabled,
      price: productOption.price,
      customerPrice: productOption.customerPrice,
      productPromotionCustomerPrice: productOption.productPromotionCustomerPrice,
      cartItemId: this.id,
      productPromotionId: this.productPromotion?.id,
      productPromotionType: this.productPromotion?.productPromotionType || DealTypeEnum.Normal,
      purchaseQuantity: this.quantity,
      categories: product.categories,
      productOptionSkus: productOption.productOptionSkus,
      mileage: productOption.mileage,
      checked: !(disabled || isSoldOut),
      status: product.status.value,
      isDelete: productOption.isDelete,
      isAbleIncreasePurchaseQuantity: true,
      shippingGroupId: product?.shippingPreset?.id,
      pricePerSku: product.pricePerSku,
      maxProductOptionSkuCount: product.maxProductOptionSkuCount,
    }
  }

  isEqualsByProductOptionAndPromotion = (cartItem: ModelCartItem): boolean => {
    return (
      this.productOption.id === cartItem.productOption.id && this.productPromotionType === cartItem.productPromotionType
    )
  }
  isInByProductOptionAndPromotion = (cartItems: ModelCartItem[]): boolean => {
    cartItems.forEach((cartItem) => {
      if (this.isEqualsByProductOptionAndPromotion(cartItem)) {
        return true
      }
    })
    return false
  }
  isInOrderItems = (orderItems: IModelOrderItemDefault[]): boolean => {
    let ret = false
    for (const orderItem of orderItems) {
      const orderItemPromotionType = orderItem.productPromotion?.productPromotionType || DealTypeEnum.Normal
      if (
        orderItem.productOption.id === this.productOption.id &&
        orderItemPromotionType === this.productPromotionType
      ) {
        ret = true
        break
      }
    }
    return ret
  }
  getThumbnail = () => {
    return this.productOption.product.mainImage.thumbnail
  }
  getProductId = () => {
    return this.productOption.product.id
  }
  getProduct_Id = () => {
    return this.productOption.product._id
  }
  getProductName = () => {
    return this.productOption.product.name
  }
  getCategories = () => {
    return this.productOption.product.categories
  }
}
