import { gql } from '@apollo/client'

const TRANSACTION_FRAGMENTS = {
  get createTransaction() {
    return gql`
      fragment createTransaction on TransactionType {
        id
        merchantUid
        pgProviderType
        name
        amount
        order {
          id
          orderOrderItems {
            edges {
              node {
                id
                quantity
                productOption {
                  id
                  name
                  productId
                }
              }
            }
          }
        }
      }
    `
  },
  get approveTransaction() {
    return gql`
      fragment approveTransaction on TransactionType {
        id
        merchantUid
        pgProviderType
        name
        amount
        order {
          id
          originalAmount
          productPromotionDiscountAmount
          shippingAmount
          orderOrderItems {
            edges {
              node {
                quantity
                amount
                customerPrice
                productPromotionCustomerAmount
                productOption {
                  id
                  product {
                    id
                    name
                  }
                  productOptionKinds {
                    key
                    value
                  }
                }
              }
            }
          }
        }
      }
    `
  },
}

export default TRANSACTION_FRAGMENTS
