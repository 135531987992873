import { Url } from 'url'
import { NextRouter, useRouter } from 'next/router'
import { FITPET } from '@/constants/legacy/constData'
import { ZENDESK_URLS } from '@/constants/legacy/constEnv'
import ROUTES, {
  ROUTE_DELIVERY_ADDRESSES_BASE,
  ROUTE_MYPAGE_BASE,
  ROUTE_ORDER_BASE,
  ROUTE_POLICY_BASE,
} from '@/constants/legacy/constRoutes'
import { appBridgeRouter, isInAppFlag } from '@/utils/utilBridge'

interface TransitionOptions {
  shallow?: boolean
  locale?: string | false
  scroll?: boolean
}

export type UseCustomRouterType = NextRouter & {
  isMainTab: () => boolean
  isNoneHeaderTitle: () => boolean
  isNoneHeaderTitleH1Tag: () => boolean
  isSearchPage: () => boolean
  shouldHideHeaderLeftSide: () => boolean
  shouldHiddenLayoutHeader: () => boolean
  shouldHiddenHeader: () => boolean
  ableToShowHeaderShareDeepLinkButton: () => boolean
  ableToShowHeaderShareButton: () => boolean
  ableToShowHeaderHomeButton: () => boolean
  ableToShowHeaderSearchButton: () => boolean
  ableToShowHeaderCartButton: () => boolean
  ableToShowTopSlimBanner: () => boolean
  ableToShowBottomTabNavigator: () => boolean
  ableToShowScrollTopButton: () => boolean
  ableToShowOrderCancelCloseButton: () => boolean
  ableToCloseButtonOldIdLogin: () => boolean
  ableToMembershipConfig: () => boolean
  ableToMypageSettingConfig: () => boolean
  isAuthenticatedRoute: () => boolean
  isBlueTheme: () => boolean
  getPageTitle: () => string | undefined
}

// url 조립하는 함수
const useCustomRouter = (): UseCustomRouterType => {
  const _router = useRouter()
  const appRouter = isInAppFlag ? appBridgeRouter() : _router
  const push = (url: Url | string, as?: Url | undefined, options?: TransitionOptions | undefined): Promise<boolean> => {
    const _pathname = typeof url === 'string' ? url : url.pathname || undefined

    /*
     * main -> main 이동 일때만 라우터 브릿지 쓰게 처리
     * 젠디스크, 약관 등은 라우터 브릿지 쓰게 처리
     */
    return (isInAppFlag && isMainTab() && !isMainTab(_pathname)) || isRouterBrigeUsePath(_pathname) || isMyPage()
      ? appRouter.push(url, as, options)
      : _router.push(url, as, options).then(() => {
          setTimeout(() => {
            window.scrollTo({ top: 0, behavior: 'auto' })
          }, 200)
        })
  }

  const back = (): void => {
    // eslint-disable-next-line no-unused-expressions
    isInAppFlag ? appRouter.back() : _router.back()
  }

  const replace = (url: Url, as?: Url | undefined, options?: TransitionOptions | undefined): Promise<boolean> => {
    return _router.replace(url, as, options)
  }

  // 라우터 브릿지를 써야하는 경로
  const isRouterBrigeUsePath = (url?: string) => {
    switch (url) {
      case ROUTES.POLICY.AGREEMENT:
      case ROUTES.POLICY.PRIVACY:
      case ROUTES.POLICY.LOCATION_SERVICE:
      case ZENDESK_URLS.NOTICE:
      case ZENDESK_URLS.INQUIRY:
      case ROUTES.FRIENDS_INVITE:
        return true
      default:
        return false
    }
  }

  const isMainTab = (pathname?: string) => {
    switch (pathname || _router.pathname) {
      case ROUTES.INDEX:
      case ROUTES.HOME.BEST_SELLER:
      case ROUTES.HOME.EVENTS.BASE:
      case ROUTES.HOME.EVENTS.DETAIL:
      case '/home/facelift': // TODO: Facelift 퍼블리싱용 페이지로 홈페이스 리프트 1-2차 배포 후 삭제필요
        return true
      default:
        return false
    }
  }

  const isMyPage = (pathname?: string) => {
    switch (pathname || _router.pathname) {
      case ROUTES.MYPAGE.BASE:
        return true
      default:
        return false
    }
  }

  const isSearchPage = (pathname?: string) => {
    switch (pathname || _router.pathname) {
      case ROUTES.SEARCH:
      case ROUTES.SEARCH_RESULT:
      case ROUTES.SEARCH_KEYWORD:
        return true
      default:
        return false
    }
  }

  const isNoneHeaderTitle = () => {
    switch (_router.pathname) {
      case ROUTES.INDEX:
      case ROUTES.PRODUCTS.DETAIL:
      case ROUTES.HOME.FIND_HOSPITALS:
        return true
      default:
        return false
    }
  }

  /**
   * header title 이 h1 태그가 아니어야 할 때 비교
   * - 타이틀이 없는 페이지거나 페이지내 다른 타이틀이 있는 경우
   */
  const isNoneHeaderTitleH1Tag = () => {
    return [ROUTES.CATEGORY.ROOT, ROUTES.CATEGORY.SUB].includes(_router.pathname)
  }

  const shouldHideHeaderLeftSide = () => {
    return (
      _router.pathname === ROUTES.MYPAGE.BASE ||
      _router.pathname === ROUTES.ORDER.FINISH ||
      _router.pathname === ROUTES.ORDER.REDIRECT ||
      _router.pathname === ROUTES.ACCOUNTS.CALLBACK_APPLE ||
      _router.pathname === ROUTES.ACCOUNTS.CALLBACK_NAVER ||
      _router.pathname === ROUTES.MYPAGE.ORDER_CANCEL.DONE ||
      _router.pathname === ROUTES.ACCOUNTS.NEED_CONVERT ||
      _router.pathname === ROUTES.MEMBERSHIP.SETTING.CANCEL.FINISH ||
      _router.pathname === ROUTES.PARTNERS.COMPLETED
    )
  }

  const hiddenHeaderPages = [ROUTES.MEMBERSHIP.JOIN.OWNER.FINISH, ROUTES.MEMBERSHIP.JOIN.FAMILY.FINISH]

  const shouldHiddenLayoutHeader = () => {
    let hideLayoutHeader = false
    try {
      hideLayoutHeader = JSON.parse(_router.query?.hideLayoutHeader as string)
    } catch (e) {}

    if (hiddenHeaderPages.includes(_router.pathname)) {
      hideLayoutHeader = true
    }
    return hideLayoutHeader
  }

  const shouldHiddenHeader = () => {
    let hideHeader = false
    try {
      hideHeader = JSON.parse(_router.query?.hideHeader as string)
    } catch (e) {}
    return hideHeader && _router.pathname.startsWith(`/${ROUTE_POLICY_BASE}/`)
  }

  const ableToShowOrderCancelCloseButton = () => {
    return (
      _router.pathname === ROUTES.MYPAGE.ORDER_CANCEL.BASE ||
      _router.pathname === ROUTES.MYPAGE.ORDER_CANCEL.REASON ||
      _router.pathname === ROUTES.MYPAGE.ORDER_CANCEL.REQUEST
    )
  }

  const ableToMembershipConfig = () => {
    return _router.pathname === ROUTES.MEMBERSHIP.INDEX
  }

  const ableToMypageSettingConfig = () => {
    return isInAppFlag && _router.pathname === ROUTES.MYPAGE.BASE
  }

  const ableToSimpleCloseButton = () => {
    return _router.pathname === ROUTES.ACCOUNTS.NEED_CONVERT
  }

  const ableToShowScrollTopButton = () => {
    if (isInAppFlag) {
      try {
        const hideLayoutHeader: boolean = JSON.parse(_router.query?.hideScrollTopButton as string)
        if (hideLayoutHeader) {
          return false
        }
      } catch (e) {}
    }

    switch (_router.pathname) {
      case ROUTES.INDEX:
      case ROUTES.CATEGORY.ROOT:
      case ROUTES.CATEGORY.SUB:
      case ROUTES.BEST_SELLER:
      case ROUTES.HOME.BEST_SELLER:
      case ROUTES.HOME.EVENTS.BASE:
      case ROUTES.HOME.EVENTS.DETAIL:
      case ROUTES.BRAND_DETAIL:
      case ROUTES.SECTION_DETAIL:
      case ROUTES.SEARCH:
      case ROUTES.SEARCH_RESULT:
      case ROUTES.EVENT_DETAIL:
      case ROUTES.EVENTS_100_DEAL:
      case ROUTES.EVENTS_100_DEAL_CAT:
      case ROUTES.EVENTS_100_DEAL_DOG:
      case ROUTES.MYPAGE.MILEAGES:
      case ROUTES.MYPAGE.COUPONS:
      case ROUTES.PRODUCTS.DETAIL:
      case '/products-new/[productId]':
      case ROUTES.PRODUCTS.REVIEWS:
      case ROUTES.PRODUCTS.INQUIRY:
      case ROUTES.SECTION_RECENTLY_BOUGHT:
      case ROUTES.SECTION_RECOMMEND_PET_ID:
        return true
      default:
        return false
    }
  }

  const ableToShowHeaderShareDeepLinkButton = () => {
    switch (_router.pathname) {
      case ROUTES.MEMBERSHIP.INTRO:
        return !!isInAppFlag
      default:
        return false
    }
  }

  const ableToShowHeaderShareButton = () => {
    switch (_router.pathname) {
      case ROUTES.EVENT_DETAIL:
      case ROUTES.EVENTS_100_DEAL:
      case ROUTES.EVENTS_100_DEAL_CAT:
      case ROUTES.EVENTS_100_DEAL_DOG:
      case ROUTES.EVENTS_PARTNERS:
        return true
      default:
        return false
    }
  }

  const ableToShowHeaderHomeButton = () => {
    switch (_router.pathname) {
      case ROUTES.PRODUCTS.DETAIL:
      case ROUTES.BRAND_DETAIL:
      case ROUTES.EVENT_DETAIL:
      case ROUTES.EVENTS_100_DEAL:
      case ROUTES.EVENTS_100_DEAL_CAT:
      case ROUTES.EVENTS_100_DEAL_DOG:
      case ROUTES.MYPAGE.REVIEWS.LIST:
      case ROUTES.MYPAGE.REVIEWS.WRITE:
      case ROUTES.MYPAGE.REVIEWS.EDIT:
      case ROUTES.FRIENDS_INVITE:
      case ROUTES.FRIENDS_GUEST:
      case ROUTES.MEMBERSHIP.INDEX:
        return true
      default:
        return false
    }
  }

  const ableToShowHeaderSearchButton = () => {
    if (isMainTab()) return false
    switch (_router.pathname) {
      case ROUTES.MYPAGE.BASE:
        return !isInAppFlag
      case ROUTES.BRAND_DETAIL:
      case ROUTES.SECTION_DETAIL:
      case ROUTES.PRODUCTS.DETAIL:
      case ROUTES.CATEGORY.ROOT:
      case ROUTES.CATEGORY.SUB:
      case ROUTES.BEST_SELLER:
      case ROUTES.MYPAGE.REVIEWS.LIST:
      case ROUTES.MYPAGE.REVIEWS.WRITE:
      case ROUTES.MYPAGE.REVIEWS.EDIT:
      case ROUTES.MYPAGE.FREQUENTLY_LIST:
      case ROUTES.RELATED_LIST:
      case ROUTES.SECTION_RECENTLY_BOUGHT:
      case ROUTES.SECTION_RECOMMEND_PET_ID:
      case ROUTES.MYPAGE.FAVORITE_LIST.BASE:
        return true
      default:
        return false
    }
  }

  const ableToShowHeaderCartButton = () => {
    if (isMainTab()) return true

    switch (_router.pathname) {
      case ROUTES.MYPAGE.BASE:
      case ROUTES.BRAND_DETAIL:
      case ROUTES.SECTION_DETAIL:
      case ROUTES.PRODUCTS.DETAIL:
      case ROUTES.BEST_SELLER:
      case ROUTES.CATEGORY.ROOT:
      case ROUTES.CATEGORY.SUB:
      case ROUTES.MYPAGE.FREQUENTLY_LIST:
      case ROUTES.MYPAGE.REVIEWS.LIST:
      case ROUTES.MYPAGE.REVIEWS.WRITE:
      case ROUTES.MYPAGE.REVIEWS.EDIT:
      case ROUTES.RELATED_LIST:
      case ROUTES.MYPAGE.ORDERS:
      case ROUTES.SECTION_RECENTLY_BOUGHT:
      case ROUTES.SECTION_RECOMMEND_PET_ID:
      case ROUTES.MYPAGE.FAVORITE_LIST.BASE:
        return true
      default:
        return false
    }
  }

  const ableToShowBottomTabNavigator = () => {
    switch (_router.pathname) {
      case ROUTES.INDEX:
      case ROUTES.MYPAGE.BASE:
      case ROUTES.HOME.EVENTS.BASE:
      case ROUTES.HOME.EVENTS.DETAIL:
      case ROUTES.HOME.BEST_SELLER:
      case ROUTES.HEALTH.INTRO:
        return true
      default:
        return false
    }
  }

  const ableToShowTopSlimBanner = () => {
    switch (_router.pathname) {
      case ROUTES.INDEX:
      case ROUTES.PRODUCTS.DETAIL:
        return true
      default:
        return false
    }
  }

  const isAuthenticatedRoute = () => {
    return (
      _router.pathname.startsWith(`/${ROUTE_ORDER_BASE}`) ||
      _router.pathname.startsWith(`/${ROUTE_DELIVERY_ADDRESSES_BASE}`) ||
      _router.pathname.startsWith(`/${ROUTE_MYPAGE_BASE}`)
    )
  }

  const BlueThemeList = [
    ROUTES.MEMBERSHIP.JOIN.OWNER.BASE,
    ROUTES.MEMBERSHIP.JOIN.OWNER.FINISH,
    ROUTES.MEMBERSHIP.JOIN.FAMILY.BASE,
    ROUTES.MEMBERSHIP.JOIN.FAMILY.FINISH,
  ]

  const isBlueTheme = () => {
    return BlueThemeList.includes(_router.pathname)
  }

  const getPageTitle = () => {
    switch (_router.pathname) {
      case ROUTES.CART:
        return '장바구니'
      case ROUTES.BEST_SELLER:
        return '베스트'
      case ROUTES.EVENTS_100_DEAL:
      case ROUTES.EVENTS_100_DEAL_CAT:
      case ROUTES.EVENTS_100_DEAL_DOG:
        return '첫 구매 혜택'
      case ROUTES.ACCOUNTS.LOGIN:
        return ''
      case ROUTES.ACCOUNTS.EMAIL_LOGIN:
        return '이메일로 로그인'
      case ROUTES.ACCOUNTS.FIND.ID:
        return '아이디 찾기'
      case ROUTES.ACCOUNTS.FIND.PASSWORD:
        return '비밀번호 찾기'
      case ROUTES.ACCOUNTS.RESET_PASSWORD:
        return '비밀번호 재설정'
      case ROUTES.ACCOUNTS.REGISTER:
      case ROUTES.ACCOUNTS.SIGN_UP:
        return '회원가입'
      case ROUTES.ACCOUNTS.ADD_INFO:
        return '추가 정보 입력'
      case ROUTES.PRODUCTS.EXCHANGE_REFUND_GUIDE:
        return '교환/환불/품절 안내'
      case ROUTES.PRODUCTS.INQUIRY:
      case ROUTES.PRODUCTS.INQUIRY_WRITE:
        return '상품 문의'
      case ROUTES.CATEGORY.ROOT:
      case ROUTES.CATEGORY.SUB:
        return '카테고리'
      case ROUTES.RELATED_LIST:
        return '지금 이 상품 어때요?'
      case ROUTES.ORDER.BASE:
        return '결제'
      case ROUTES.ORDER.DELIVERY_ADDRESSES:
      case ROUTES.MYPAGE.RETRIEVAL_ADDRESSES:
        return '배송지 목록'
      case ROUTES.MYPAGE.INFO.DELIVERY_ADDRESSES.BASE:
        return '배송지 관리'
      case ROUTES.ORDER.FINISH:
        return '결제 완료'
      case ROUTES.ORDER.TRANSACTION:
        return '결제'
      case ROUTES.DELIVERY_ADDRESSES.CREATE:
      case ROUTES.MYPAGE.INFO.DELIVERY_ADDRESSES.CREATE:
        return '배송지 추가'
      case ROUTES.DELIVERY_ADDRESSES.UPDATE:
      case ROUTES.MYPAGE.INFO.DELIVERY_ADDRESSES.UPDATE:
        return '배송지 수정'
      case ROUTES.MYPAGE.ORDERS:
        return '주문·배송 내역'
      case ROUTES.MYPAGE.CANCEL_RETURN_EXCHANGE_LIST:
        return '취소·반품·교환 내역'
      case ROUTES.MYPAGE.BASE:
        return '마이페이지'
      case ROUTES.MYPAGE.INFO.BASE:
        return '내 정보'
      case ROUTES.MYPAGE.INFO.SNS_LINK:
        return 'SNS 계정 연동'
      case ROUTES.MYPAGE.INFO.MARKETING_AGREEMENT:
        return '마케팅 정보 수신 동의'
      case ROUTES.MYPAGE.INFO.VERSION:
        return '버전 정보'
      case ROUTES.MYPAGE.INFO.CONFIRM_PASSWORD:
      case ROUTES.MYPAGE.INFO.UPDATE_INFO:
        return '내 정보 변경'
      case ROUTES.MYPAGE.INFO.WITHDRAW:
        return '탈퇴하기'
      case ROUTES.MYPAGE.MILEAGES:
        return '적립금'
      case ROUTES.MYPAGE.COUPONS_ADD:
        return '쿠폰 등록'
      case ROUTES.MYPAGE.REVIEWS.LIST:
        return '상품 리뷰'
      case ROUTES.MYPAGE.REVIEWS.WRITE:
        return '상품 리뷰 작성'
      case ROUTES.MYPAGE.REVIEWS.EDIT:
        return '상품 리뷰 수정'
      case ROUTES.MYPAGE.REFUND_ACCOUNT:
        return '환불 계좌 등록'
      case ROUTES.MYPAGE.REFUND_ACCOUNT_EDIT:
        return '환불 계좌 변경'
      case ROUTES.MYPAGE.ORDER_CANCEL.BASE:
      case ROUTES.MYPAGE.ORDER_CANCEL.REASON:
      case ROUTES.MYPAGE.ORDER_CANCEL.REQUEST:
        if (_router.asPath.includes('return')) {
          return '반품신청'
        }
        if (_router.asPath.includes('exchange')) {
          return '교환신청'
        }
        return '주문취소'
      case ROUTES.MYPAGE.ORDER_CANCEL.DONE: {
        if (_router.asPath.includes('return')) {
          return '반품신청 완료'
        }
        if (_router.asPath.includes('exchange')) {
          return '교환신청 완료'
        }
        return '취소신청 완료'
      }
      case ROUTES.MYPAGE.CANCEL_RETURN_EXCHANGE_PROCESSING_LIST:
        return '취소·반품·교환 신청 내역'
      case ROUTES.MYPAGE.CANCEL_RETURN_EXCHANGE_PROCESSED_LIST:
        return '취소·반품·교환 처리 내역'
      case ROUTES.POLICY.AGREEMENT:
        return '이용약관'
      case ROUTES.POLICY.PRIVACY:
        return '핏펫 개인(위치)정보 처리방침'
      case ROUTES.POLICY.LOCATION_SERVICE:
        return '위치기반서비스 이용약관'
      case ROUTES.POLICY.BEFORE_LOCATION_PRIVACY_20220125:
      case ROUTES.POLICY.BEFORE_LOCATION_PRIVACY_20230424:
        return '이전 위치기반서비스 이용약관'
      case ROUTES.POLICY.PROVIDE_3RD:
        return '개인정보 제3자 제공 동의'
      case ROUTES.POLICY.POLICY_MARKETING:
        return '개인정보 마케팅 활용 동의'
      case ROUTES.POLICY.OPTIONAL_PRIVACY_TO_COLLECT:
        return '개인정보 수집 및 이용 동의'
      case ROUTES.POLICY.PAYMENT_GUARANTEE:
        return FITPET.PURCHASE_SAFETY_SERVICE
      case ROUTES.POLICY.PRIVACY_PURPOSE:
        return '개인정보 수집 및 이용 동의'
      case ROUTES.POLICY.SELLERS:
        return '판매자(셀러)'
      case ROUTES.POLICY.HOSPITALS:
        return '핏펫 제휴 동물병원'
      case ROUTES.POLICY.BEFORE_PRIVACY_20220719:
      case ROUTES.POLICY.BEFORE_PRIVACY_20221004:
      case ROUTES.POLICY.BEFORE_PRIVACY_20230424:
      case ROUTES.POLICY.BEFORE_PRIVACY_20230628:
      case ROUTES.POLICY.BEFORE_PRIVACY_20230731:
      case ROUTES.POLICY.BEFORE_PRIVACY_20231031:
      case ROUTES.POLICY.BEFORE_PRIVACY_20231115:
        return '이전 핏펫 개인(위치)정보 처리방침'
      case ROUTES.POLICY.FITPET_PLUS_TERMS:
        return '핏펫플러스 이용약관'
      case ROUTES.POLICY.FITPET_PLUS_PRIVACY:
        return '개인정보 제공'
      case ROUTES.MEMBERSHIP.INDEX:
      case ROUTES.MEMBERSHIP.INTRO:
        return '핏펫플러스'
      case ROUTES.MEMBERSHIP.SETTING.BASE:
        return '설정'
      case ROUTES.MEMBERSHIP.SETTING.INFO:
        return '가입 정보'
      case ROUTES.MEMBERSHIP.SETTING.FAMILY:
        return '패밀리 관리'
      case ROUTES.MEMBERSHIP.SETTING.MILEAGE:
        return '적립금 내역'
      case ROUTES.MEMBERSHIP.SETTING.HISTORY:
        return '핏펫플러스 결제 내역'
      case ROUTES.MEMBERSHIP.SETTING.CANCEL.REASON:
      case ROUTES.MEMBERSHIP.SETTING.CANCEL.REQUEST:
        return '핏펫플러스 해지'
      case ROUTES.MEMBERSHIP.JOIN.OWNER.REGULAR_PAYMENT:
        return '멤버십 정기결제'
      case ROUTES.MEMBERSHIP.RECOMMEND:
        return '핏펫플러스 회원님을 위한 추천'
      case ROUTES.MEMBERSHIP.SETTING.CANCEL.FINISH:
        return '해지 완료'
      case ROUTES.PARTNERS.REGISTER:
        return '핏펫 파트너스 신청'
      case ROUTES.PARTNERS.HOME:
        return '핏펫 파트너스'
      case ROUTES.PARTNERS.REWARD:
        return '나의 리워드'
      case ROUTES.POLICY.PARTNERS:
        return '핏펫 파트너스 이용 약관'
      case ROUTES.PARTNERS.COMPLETED:
        return '핏펫 파트너스 신청 완료'
      case ROUTES.SECTION_RECENTLY_BOUGHT:
        return '최근 구매한 이 상품!'
      case ROUTES.MYPAGE.FAVORITE_LIST.BASE:
        return '관심상품'
    }

    const { title } = _router.query

    if (!title) {
      return undefined
    }

    return title as string
  }

  return {
    ..._router,
    push,
    back,
    replace,
    isMainTab,
    isNoneHeaderTitle,
    isNoneHeaderTitleH1Tag,
    isSearchPage,
    shouldHideHeaderLeftSide,
    shouldHiddenLayoutHeader,
    shouldHiddenHeader,
    ableToShowHeaderShareDeepLinkButton,
    ableToShowHeaderShareButton,
    ableToShowHeaderHomeButton,
    ableToShowHeaderSearchButton,
    ableToShowHeaderCartButton,
    ableToShowTopSlimBanner,
    ableToShowScrollTopButton,
    ableToShowBottomTabNavigator,
    ableToShowOrderCancelCloseButton,
    ableToCloseButtonOldIdLogin: ableToSimpleCloseButton,
    ableToMembershipConfig,
    ableToMypageSettingConfig,
    isAuthenticatedRoute,
    isBlueTheme,
    getPageTitle,
  }
}

export default useCustomRouter
