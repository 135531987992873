import { gql } from '@apollo/client'
import DEFAULT_FRAGMENTS from '@/containers/gqls/base/defaultFragments'
import COUPON_FRAGMENTS from '@/containers/gqls/promotions/coupons/fragments'

export const COUPON_QUERY_GQLS = {
  USER_COUPONS: gql`
    query userCoupons($pageInfo: PageInfoInputType, $filter: GenericScalar, $orderBy: String) {
      coupons(pageInfo: $pageInfo, filter: $filter, orderBy: $orderBy) {
        totalCount
        pageInfo {
          ...pageInfo
        }
        edges {
          node {
            ...coupon
            couponGroup {
              ...couponGroupInCouponList
              issueStartedAt
              issueEndedAt
              includedBrands {
                edges {
                  node {
                    name
                  }
                }
              }
              includedCategories {
                edges {
                  node {
                    name
                  }
                }
              }
              includedProducts {
                edges {
                  node {
                    name
                  }
                }
              }
              excludedBrands {
                edges {
                  node {
                    name
                  }
                }
              }
              excludedCategories {
                edges {
                  node {
                    name
                  }
                }
              }
              excludedProducts {
                edges {
                  node {
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.pageInfo}
    ${DEFAULT_FRAGMENTS.coupon}
    ${COUPON_FRAGMENTS.couponGroupInCouponList}
  `,
  COUPON_GROUP_INCLUDES: gql`
    query couponGroupIncludes($id: ID!) {
      couponGroup(id: $id) {
        ...couponGroupIncludeList
      }
    }
    ${COUPON_FRAGMENTS.couponGroupIncludeList}
  `,
}

export default COUPON_QUERY_GQLS
