import ChipTertiary from '@/components/common/buttons/Button/ChipTertiary'
import GrayFill from '@/components/common/buttons/Button/GrayFill'
import Primary from '@/components/common/buttons/Button/Primary'
import Secondary from '@/components/common/buttons/Button/Secondary'
import Tertiary from '@/components/common/buttons/Button/Tertitary'

const Button = {
  Primary,
  Secondary,
  Tertiary,
  GrayFill,
  ChipTertiary,
}

export default Button
