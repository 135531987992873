import isPropValid from '@emotion/is-prop-valid'
import type { ShouldForwardProp } from 'styled-components'

/**
 * styled-components v5의 기본동작을 구현하는 Utils 함수
 *
 * v6 이후부터는 아래와 같은 함수가 기본지원되지 않으므로, 동일한 재현을 위해서는 추가해주어야합니다
 *
 * {@link https://styled-components.com/docs/faqs#what-do-i-need-to-do-to-migrate-to-v6}
 */
export const shouldForwardProp: ShouldForwardProp<'web'> = (propName, target) => {
  if (typeof target === 'string') {
    return isPropValid(propName)
  }

  return true
}
