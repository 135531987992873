import { DealTypeEnum } from '@/constants/deal-type.enum'
import { POLICY } from '@/constants/legacy/constData'
import { ModelProduct } from '@/containers/models'
import { IModelProductOptionSkuDefault } from '@/containers/models/base/defaultModelInterfaces'
import { IModelCategory } from '@/containers/models/modelCategory'
import { IModelOrderItem } from '@/containers/models/modelOrderItem'
import { IModelProduct } from '@/containers/models/modelProduct'
import { IModelProductOption } from '@/containers/models/modelProductOption'
import { CartProductKeyType } from '@/containers/types/CartProduct'
import { AppCartProductOptionType } from '@/utils/bridge/bridge'
import { deepcopy } from '@/utils/utilData'

export type CartProductOptionType = {
  productId: string
  productName: string
  productOptionId: string
  productOption_id: string
  productOptionName: string
  stockQuantity: number

  isSoldOut: boolean
  disabled: boolean
  checked: boolean
  price: number
  customerPrice: number
  productPromotionCustomerPrice: number

  cartItemId?: string
  productPromotionId?: string
  productPromotionType: 'DEAL_100' | 'DEAL_990' | 'NORMAL' | 'PET_REGISTRATION'
  purchaseQuantity: number
  purchaseQuantityInCart?: number // 장바구니에 담겨있는 상품의 수량

  pricePerSku: number
  maxProductOptionSkuCount: number

  categories: IModelCategory[]
  productOptionSkus: IModelProductOptionSkuDefault[]

  isAbleIncreasePurchaseQuantity: boolean // 수량 증가 가능 여부
  isValidStockQuantity?: boolean // 구매 가능 여부
  isPurchaseQuantityAvailableAtOnce?: boolean // 한번에 구매할 수 있는 최대 수량 인지 여부, POLICY.MAX_PURCHASE_QUANTITY_AT_ONCE 이하인 경우 true
  isTryToIncreaseSoldOutQuantity?: boolean // 재고 수량을 초과한 상태에서 수량 증가를 시도했는지 여부
  isTryToIncreaseMaxPurchaseQuantityAtOnce?: boolean // 한번에 구매할 수 있는 최대 수량인 상태에서 수량 증가를 시도했는지 여부
  isSkuCodeInCart?: boolean // SkuCode가 동일한 상품이 카트에 담겨있는지 여부
  status: 'APPROVED' | 'BANNED' | 'DELETED' | 'INCOMPLETE' | 'REJECTED' | 'REQUEST' | 'SUSPEND'
  mileage?: number
  isDelete: boolean
  shippingGroupId?: string
}

export type CartProductOptionsAmountType = {
  totalProductOptionAmount: number
}

export type CartProductOptionKeyType = {
  productOptionId: string
  productPromotionType: 'DEAL_100' | 'DEAL_990' | 'NORMAL' | 'PET_REGISTRATION'
}

export type CartProductOptionTypeUtilType = {
  createCartProductOption: (orderItem: IModelOrderItem) => CartProductOptionType
  createCartProductOptionByProduct: (
    product: IModelProduct | ModelProduct,
    productOption: IModelProductOption
  ) => CartProductOptionType
  createCartProductOptionByProductOption: (
    productOption: IModelProductOption,
    quantity: number
  ) => CartProductOptionType
  createAppCartProductOption: (cartProductOption: CartProductOptionType) => AppCartProductOptionType
  getCartProductOptionKeyByAppCartProductOption: (
    appCartProductOption: AppCartProductOptionType
  ) => CartProductOptionKeyType
  getCartProductOptionKey: (cartProductOption: CartProductOptionType) => CartProductOptionKeyType
  equalsByProductOptionKey: (
    cartProductOption: CartProductOptionType,
    cartProductOptionKey: CartProductOptionKeyType
  ) => boolean
  equalsProductOption: (one: CartProductOptionType, other: CartProductOptionType) => boolean
  getCartProductOption: (
    cartProductOptions: CartProductOptionType[],
    cartProductOptionKey: CartProductOptionKeyType
  ) => CartProductOptionType | undefined
  getCartProductOptions: (
    cartProductOptions: CartProductOptionType[],
    cartProductOptionKeys: CartProductOptionKeyType[]
  ) => CartProductOptionType[]
  existsCartProductOption: (
    cartProductOptions: CartProductOptionType[],
    cartProductOptionKey: CartProductOptionKeyType
  ) => boolean
  getCartProductOptionsByCartProductKey: (
    cartProductOptions: CartProductOptionType[],
    cartProductKey: CartProductKeyType
  ) => CartProductOptionType[]
  getCartProductOptionsByCartProductKeys: (
    cartProductOptions: CartProductOptionType[],
    cartProductKeys: CartProductKeyType[]
  ) => CartProductOptionType[]
  addCartProductOption: (
    cartProductOptions: CartProductOptionType[],
    cartProductOption: CartProductOptionType
  ) => CartProductOptionType[]
  mergeCartProductOptions: (
    cartProductOptions1: CartProductOptionType[],
    cartProductOptions2: CartProductOptionType[]
  ) => CartProductOptionType[]
  removeCartProductOption: (
    cartProductOptions: CartProductOptionType[],
    cartProductOptionKey: CartProductOptionKeyType
  ) => CartProductOptionType[]
  removeCartProductOptions: (
    cartProductOptions: CartProductOptionType[],
    cartProductOptionKeys: CartProductOptionKeyType[]
  ) => CartProductOptionType[]
  getCartProductOptionsCount: (cartProductOptions: CartProductOptionType[]) => number
  updateCartProductOption: (
    cartProductOptions: CartProductOptionType[],
    oldCartProductOption: CartProductOptionType,
    newCartProductOption: CartProductOptionType
  ) => void
  canUpdatePurchaseQuantity: (cartProductOption: CartProductOptionType, newPurchaseQuantity: number) => boolean
  setCartProductOption: (source: CartProductOptionType, data: CartProductOptionType) => CartProductOptionType
  getErrorInfo: (
    cartProductOption: CartProductOptionType,
    usageFrom: 'productDetail' | 'cart'
  ) => { type: string; message: string; subMessage?: string } | undefined
  isDisabledStyle: (cartProductOption: CartProductOptionType) => boolean
  getCartProductOptionsAmount: (cartProductOptions: CartProductOptionType[]) => CartProductOptionsAmountType
  isPurchaseQuantityAvailableAtOnce: (purchaseQuantity: number) => boolean
  isEqualsForRendering: (one: CartProductOptionType, other: CartProductOptionType) => boolean
  getExpectedMileage: (cartProductOption: CartProductOptionType) => number
}

const createCartProductOption = (orderItem: IModelOrderItem): CartProductOptionType => {
  const { productPromotion, productOption, quantity: purchaseQuantity } = orderItem
  const {
    id: productOptionId,
    _id: productOption_id,
    name: productOptionName,
    stockQuantity,
    price,
    customerPrice,
    productPromotionCustomerPrice,
    product,
    mileage,
    isUse,
    isDelete,
  } = productOption

  const isSoldOut = product.isRealSoldOut || product.isSoldOut || stockQuantity === 0 || productOption.isSoldOut
  const disabled = product.status.value !== 'APPROVED' || !isUse || isDelete

  return {
    productId: product.id,
    productOptionId,
    productOption_id,
    productOptionName,
    productName: product.name,
    stockQuantity,
    isSoldOut,
    disabled,
    price,
    customerPrice,
    productPromotionCustomerPrice,
    productPromotionId: productPromotion?.id,
    productPromotionType: productPromotion?.productPromotionType || DealTypeEnum.Normal,
    purchaseQuantity,
    purchaseQuantityInCart: 0,
    categories: productOption.product.categories,
    productOptionSkus: productOption.productOptionSkus,
    checked: !(disabled || isSoldOut),
    mileage,
    isDelete,
    status: product.status.value,
    isAbleIncreasePurchaseQuantity: true,
    pricePerSku: productOption.pricePerSku,
    maxProductOptionSkuCount: productOption.maxProductOptionSkuCount,
  }
}

const createCartProductOptionByProduct = (
  product: IModelProduct | ModelProduct,
  productOption: IModelProductOption
): CartProductOptionType => {
  const { productPromotion } = product
  const isSingleProductOption = product.productProductOptions.length === 1
  const isSoldOut =
    product.isRealSoldOut || product.isSoldOut || productOption.stockQuantity === 0 || productOption.isSoldOut
  const disabled = product.status.value !== 'APPROVED' || !productOption.isUse || productOption.isDelete

  return {
    productId: product.id,
    productName: product.name,
    productOptionId: productOption.id,
    productOption_id: productOption._id,
    productOptionName: isSingleProductOption ? product.name : productOption.name,
    stockQuantity: productOption.stockQuantity,
    isSoldOut,
    disabled,
    price: productOption.price,
    customerPrice: productOption.customerPrice,
    productPromotionCustomerPrice: productOption.productPromotionCustomerPrice,
    productPromotionId: productPromotion?.id,
    productPromotionType: productPromotion?.productPromotionType || DealTypeEnum.Normal,
    purchaseQuantity: 1,
    purchaseQuantityInCart: 0,
    categories: product.categories,
    productOptionSkus: productOption.productOptionSkus,
    checked: !(disabled || isSoldOut),
    mileage: productOption.mileage,
    status: product.status.value,
    isDelete: productOption.isDelete,
    isAbleIncreasePurchaseQuantity: true,
    shippingGroupId: productOption?.product?.shippingPreset?.id,
    pricePerSku: productOption.pricePerSku,
    maxProductOptionSkuCount: productOption.maxProductOptionSkuCount,
  }
}

const createCartProductOptionByProductOption = (productOption: IModelProductOption, quantity: number = 1) => {
  const isSoldOut =
    productOption.product.isRealSoldOut ||
    productOption.product.isSoldOut ||
    productOption.stockQuantity === 0 ||
    productOption.isSoldOut
  const disabled = productOption.product.status.value !== 'APPROVED' || !productOption.isUse || productOption.isDelete
  return {
    productId: productOption.product.id,
    productName: productOption.product.name,
    productOptionId: productOption.id,
    productOption_id: productOption._id,
    productOptionName: productOption.name,
    stockQuantity: productOption.stockQuantity,
    isSoldOut: isSoldOut,
    disabled,
    price: productOption.price,
    customerPrice: productOption.customerPrice,
    productPromotionCustomerPrice: productOption.productPromotionCustomerPrice,
    productPromotionId: productOption.product.productPromotion?.id,
    productPromotionType: productOption.product.productPromotion?.productPromotionType || DealTypeEnum.Normal,
    purchaseQuantity: quantity,
    purchaseQuantityInCart: quantity,
    categories: productOption.product.categories,
    productOptionSkus: productOption.productOptionSkus,
    checked: !(disabled || isSoldOut),
    mileage: productOption.mileage,
    isDelete: productOption.isDelete,
    status: productOption.product.status.value,
    isAbleIncreasePurchaseQuantity: true,
    shippingGroupId: productOption?.product?.shippingPreset?.id,
    pricePerSku: productOption.pricePerSku,
    maxProductOptionSkuCount: productOption.maxProductOptionSkuCount,
  }
}

const createAppCartProductOption = (cartProductOption: CartProductOptionType) => {
  const { productOptionId, productOptionSkus, productPromotionType, purchaseQuantity, productPromotionId } =
    cartProductOption

  const newProductOptionSkus = productOptionSkus.map((sku) => {
    return {
      count: sku.count,
      sku: {
        code: sku.sku.code,
        id: sku.sku.id,
        stockQuantity: sku.sku.stockQuantity,
      },
    }
  })
  const appCartProductOption: AppCartProductOptionType = {
    productOptionId,
    productPromotionId,
    productOptionSkus: newProductOptionSkus,
    productPromotionType,
    purchaseQuantity,
  }
  return appCartProductOption
}

const getCartProductOptionKeyByAppCartProductOption = (appCartProductOption: AppCartProductOptionType) => {
  return {
    productOptionId: appCartProductOption.productOptionId,
    productPromotionType: appCartProductOption.productPromotionType,
  }
}

const getCartProductOptionKey = (cartProductOption: CartProductOptionType): CartProductOptionKeyType => {
  return {
    productOptionId: cartProductOption.productOptionId,
    productPromotionType: cartProductOption.productPromotionType,
  }
}

const equalsByProductOptionKey = (
  cartProductOption: CartProductOptionType,
  cartProductOptionKey: CartProductOptionKeyType
): boolean => {
  return (
    cartProductOption.productOptionId === cartProductOptionKey.productOptionId &&
    cartProductOption.productPromotionType === cartProductOptionKey.productPromotionType
  )
}

const equalsProductOption = (one: CartProductOptionType, other: CartProductOptionType): boolean => {
  return one.productOptionId === other.productOptionId && one.productPromotionType === other.productPromotionType
}

const getCartProductOption = (
  cartProductOptions: CartProductOptionType[],
  cartProductOptionKey: CartProductOptionKeyType
): CartProductOptionType | undefined => {
  return cartProductOptions.find((cartProductOption) => {
    return equalsByProductOptionKey(cartProductOption, cartProductOptionKey)
  })
}

const getCartProductOptions = (
  cartProductOptions: CartProductOptionType[],
  cartProductOptionKeys: CartProductOptionKeyType[]
): CartProductOptionType[] => {
  return (
    cartProductOptions.filter((cartProductOption) => {
      for (const cartProductOptionKey of cartProductOptionKeys) {
        if (equalsByProductOptionKey(cartProductOption, cartProductOptionKey)) {
          return true
        }
      }
      return false
    }) || []
  )
}

const existsCartProductOption = (
  cartProductOptions: CartProductOptionType[],
  cartProductOptionKey: CartProductOptionKeyType
): boolean => {
  return !!getCartProductOption(cartProductOptions, cartProductOptionKey)
}

const getCartProductOptionsByCartProductKey = (
  cartProductOptions: CartProductOptionType[],
  cartProductKey: CartProductKeyType
): CartProductOptionType[] => {
  return (
    cartProductOptions.filter((cartProductOption) => {
      return (
        cartProductOption.productId === cartProductKey.productId &&
        cartProductOption.productPromotionType === cartProductKey.productPromotionType
      )
    }) || []
  )
}

const getCartProductOptionsByCartProductKeys = (
  cartProductOptions: CartProductOptionType[],
  cartProductKeys: CartProductKeyType[]
): CartProductOptionType[] => {
  return (
    cartProductOptions.filter((cartProductOption) => {
      return (
        cartProductKeys.findIndex((cartProductKey) => {
          return (
            cartProductOption.productId === cartProductKey.productId &&
            cartProductOption.productPromotionType === cartProductKey.productPromotionType
          )
        }) > -1
      )
    }) || []
  )
}

const addCartProductOption = (
  cartProductOptions: CartProductOptionType[],
  cartProductOption: CartProductOptionType
): CartProductOptionType[] => {
  const _cartProductOptions: CartProductOptionType[] = deepcopy(cartProductOptions)
  _cartProductOptions.push(cartProductOption)
  return _cartProductOptions
}

const mergeCartProductOptions = (
  cartProductOptions1: CartProductOptionType[],
  cartProductOptions2: CartProductOptionType[]
): CartProductOptionType[] => {
  const mergedList: CartProductOptionType[] = deepcopy(cartProductOptions1)
  cartProductOptions2.forEach((cartProductOption2) => {
    let cartProductOption = getCartProductOption(mergedList, getCartProductOptionKey(cartProductOption2))
    if (cartProductOption) {
      cartProductOption.purchaseQuantity += cartProductOption2.purchaseQuantity
    } else {
      cartProductOption = deepcopy(cartProductOption2)
      if (cartProductOption) {
        mergedList.push(cartProductOption)
      }
    }
  })
  return mergedList
}

const removeCartProductOption = (
  cartProductOptions: CartProductOptionType[],
  cartProductOptionKey: CartProductOptionKeyType
): CartProductOptionType[] => {
  const _cartProductOptions: CartProductOptionType[] = deepcopy(cartProductOptions) || []
  return _cartProductOptions.filter((cartProductOption) => {
    return !equalsByProductOptionKey(cartProductOption, cartProductOptionKey)
  })
}

const removeCartProductOptions = (
  cartProductOptions: CartProductOptionType[],
  cartProductOptionKeys: CartProductOptionKeyType[]
): CartProductOptionType[] => {
  const _cartProductOptions: CartProductOptionType[] = deepcopy(cartProductOptions) || []
  return _cartProductOptions.filter((cartProductOption) => {
    for (const cartProductOptionKey of cartProductOptionKeys) {
      if (equalsByProductOptionKey(cartProductOption, cartProductOptionKey)) {
        return false
      }
    }
    return true
  })
}

const getCartProductOptionsCount = (cartProductOptions: CartProductOptionType[]): number => {
  return cartProductOptions.reduce(
    (totalQuantity, cartProductOption) => totalQuantity + cartProductOption.purchaseQuantity,
    0
  )
}

const updateCartProductOption = (
  cartProductOptions: CartProductOptionType[],
  oldCartProductOption: CartProductOptionType,
  newCartProductOption: CartProductOptionType
) => {
  const _cartProductOptions: CartProductOptionType[] = deepcopy(cartProductOptions) || []
  for (const cartProductOption of _cartProductOptions) {
    if (equalsProductOption(cartProductOption, oldCartProductOption)) {
      setCartProductOption(cartProductOption, newCartProductOption)
    }
  }
  return _cartProductOptions
}

const canUpdatePurchaseQuantity = (cartProductOption: CartProductOptionType, newPurchaseQuantity: number): boolean => {
  return (
    !cartProductOption.isSoldOut &&
    !cartProductOption.disabled &&
    newPurchaseQuantity >= 0 &&
    cartProductOption.purchaseQuantity !== newPurchaseQuantity
  )
}

const setCartProductOption = (source: CartProductOptionType, data: CartProductOptionType): CartProductOptionType => {
  source.productId = data.productId
  source.productOptionId = data.productOptionId
  source.productOption_id = data.productOption_id
  source.productOptionName = data.productOptionName
  source.stockQuantity = data.stockQuantity
  source.isSoldOut = data.isSoldOut
  source.disabled = data.disabled
  source.price = data.price
  source.customerPrice = data.customerPrice
  source.productPromotionCustomerPrice = data.productPromotionCustomerPrice
  if (data.cartItemId) source.cartItemId = data.cartItemId
  source.productPromotionId = data.productPromotionId
  source.productPromotionType = data.productPromotionType
  // source.purchaseQuantity = data.purchaseQuantity  Do not set. Maintain original productOption's purchaseQuantity.
  source.categories = data.categories || source.categories
  source.productOptionSkus = data.productOptionSkus || source.productOptionSkus
  source.isValidStockQuantity = undefined
  source.isTryToIncreaseSoldOutQuantity = undefined
  source.checked = data.checked
  source.mileage = data.mileage
  source.status = data.status

  return source
}

const getErrorInfo = (
  cartProductOption: CartProductOptionType,
  usageFrom: 'productDetail' | 'cart' = 'productDetail'
): { type: string; message: string; subMessage?: string } | undefined => {
  if (cartProductOption.isSoldOut) {
    return {
      type: 'isSoldOut',
      message: '입고 예정인 상품입니다.',
    }
  } else if (cartProductOption.disabled) {
    return {
      type: 'disabled',
      message: '구매 불가 상품입니다.',
    }
  } else if (!cartProductOption.isValidStockQuantity || cartProductOption.isTryToIncreaseSoldOutQuantity) {
    if (cartProductOption.isSkuCodeInCart && usageFrom === 'productDetail') {
      return {
        type: 'alreadyInCartInValidStockQuantity',
        message: '장바구니에 이미 담긴 상품으로 여분의 재고가 없습니다.',
        subMessage: String(cartProductOption.productOptionName),
      }
    }
    return {
      type: 'exceedStockQuantity',
      message: '재고 수량을 초과하였습니다.',
    }
  } else if (cartProductOption.isTryToIncreaseMaxPurchaseQuantityAtOnce) {
    if (cartProductOption.purchaseQuantityInCart! > 0 && usageFrom === 'productDetail') {
      return {
        type: 'alreadyInCartMaxAblePurchaseQuantity',
        message: `장바구니 포함 1회\n최대 구매 가능 수량은 ${POLICY.MAX_PURCHASE_QUANTITY_AT_ONCE}개입니다.`,
        subMessage: String(cartProductOption.productOptionName),
      }
    }
    return {
      type: 'notInCartMaxAblePurchaseQuantity',
      message: '1회 최대 구매 수량을 초과하였습니다.',
    }
  }
  return undefined
}

const isDisabledStyle = (cartProductOption: CartProductOptionType): boolean => {
  return (
    cartProductOption.isSoldOut ||
    cartProductOption.disabled ||
    !cartProductOption.isValidStockQuantity ||
    !cartProductOption.isPurchaseQuantityAvailableAtOnce
  )
}

const getCartProductOptionsAmount = (cartProductOptions: CartProductOptionType[]): CartProductOptionsAmountType => {
  let totalProductOptionAmount = 0
  cartProductOptions.forEach((cartProductOption) => {
    totalProductOptionAmount += cartProductOption.productPromotionCustomerPrice * cartProductOption.purchaseQuantity
  })
  return {
    totalProductOptionAmount,
  }
}

const isPurchaseQuantityAvailableAtOnce = (purchaseQuantity: number): boolean => {
  return purchaseQuantity <= POLICY.MAX_PURCHASE_QUANTITY_AT_ONCE
}

const isEqualsForRendering = (one: CartProductOptionType, other: CartProductOptionType): boolean => {
  return (
    one.productId === other.productId &&
    one.productOptionId === other.productOptionId &&
    one.productOption_id === other.productOption_id &&
    one.productOptionName === other.productOptionName &&
    one.stockQuantity === other.stockQuantity &&
    one.isSoldOut === other.isSoldOut &&
    one.disabled === other.disabled &&
    one.price === other.price &&
    one.customerPrice === other.customerPrice &&
    one.productPromotionCustomerPrice === other.productPromotionCustomerPrice &&
    one.cartItemId === other.cartItemId &&
    one.productPromotionId === other.productPromotionId &&
    one.productPromotionType === other.productPromotionType &&
    one.purchaseQuantity === other.purchaseQuantity &&
    one.purchaseQuantityInCart === other.purchaseQuantityInCart &&
    one.isValidStockQuantity === other.isValidStockQuantity &&
    one.isPurchaseQuantityAvailableAtOnce === other.isPurchaseQuantityAvailableAtOnce &&
    one.isTryToIncreaseSoldOutQuantity === other.isTryToIncreaseSoldOutQuantity &&
    one.isTryToIncreaseMaxPurchaseQuantityAtOnce === other.isTryToIncreaseMaxPurchaseQuantityAtOnce &&
    one.isSkuCodeInCart === other.isSkuCodeInCart
  )
}
const getExpectedMileage = (cartProductOption: CartProductOptionType) => {
  return cartProductOption.mileage! * cartProductOption.purchaseQuantity
}

export const CartProductOptionTypeUtil: CartProductOptionTypeUtilType = {
  createCartProductOption,
  createCartProductOptionByProduct,
  getCartProductOptionKey,
  equalsByProductOptionKey,
  equalsProductOption,
  getCartProductOption,
  getCartProductOptions,
  existsCartProductOption,
  getCartProductOptionsByCartProductKey,
  getCartProductOptionsByCartProductKeys,
  addCartProductOption,
  mergeCartProductOptions,
  removeCartProductOption,
  removeCartProductOptions,
  getCartProductOptionsCount,
  updateCartProductOption,
  setCartProductOption,
  canUpdatePurchaseQuantity,
  isDisabledStyle,
  getCartProductOptionsAmount,
  isPurchaseQuantityAvailableAtOnce,
  isEqualsForRendering,
  getErrorInfo,
  getExpectedMileage,
  createCartProductOptionByProductOption,
  createAppCartProductOption,
  getCartProductOptionKeyByAppCartProductOption,
}
