import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import styled, { useTheme } from 'styled-components'
import { Spinner } from '@/components/common/etc'
import { Typo, TypoVariant } from '@/components/common/typography'
import DownloadProductCouponCard from '@/components/domains/coupon/DownloadProductCouponCard'
import { useCouponEventTracker } from '@/containers/event-tracker/coupon.event-tracker'
import { useProductCouponDownloadBottomSheet } from '@/containers/hooks/bottom-sheet/useProductCouponDownloadBottomSheet'
import { useMutationDownloadCoupon } from '@/containers/hooks/coupon/useMutationDownloadCoupon'
import { useQueryDownloadCouponList } from '@/containers/hooks/coupon/useQueryDownloadCouponList'
import { RootState } from '@/stores/store'
import { getAmountText, getBenefitTextNew, getDatePeriodTextNew } from '@/utils/coupon.util'
import { decodeId, encodeId } from '@/utils/utilApi'

export const ProductCouponDownload = () => {
  const { closeBottomSheet } = useProductCouponDownloadBottomSheet()
  const { trackClickCouponDownloadButtonEvent, trackViewProductCouponEvent } = useCouponEventTracker()
  const { color } = useTheme()
  const { downloadCoupon } = useMutationDownloadCoupon()
  const { addLocation, productId, promotionId, productName, buttonText, isRealSoldOut } = useSelector(
    (state: RootState) => state.reduxDataReducers.productCouponDownloadBottomSheetData
  )
  const [loadingCouponId, setLoadingCouponId] = useState<number | null>(null)

  const { data, isLoading, refetch } = useQueryDownloadCouponList({
    productId: Number(productId),
    promotionId,
  })

  useEffect(() => {
    if (typeof addLocation === 'undefined' || typeof isRealSoldOut === 'undefined') return
    trackViewProductCouponEvent({
      addLocation,
      productId,
      productName,
      buttonText,
      isRealSoldOut,
    })
  }, [])

  const handleCouponDownload = async ({
    couponExpiredAt,
    couponDiscountAmount,
    couponDiscountRate,
    couponName,
    couponGroupId,
  }: {
    couponExpiredAt: string
    couponDiscountAmount: number
    couponDiscountRate: number
    couponName: string
    couponGroupId: number
  }) => {
    setLoadingCouponId(couponGroupId)
    const result = await downloadCoupon({ id: encodeId('CouponGroupType', couponGroupId) })
    const couponId = result?.downloadEventCoupons?.coupons ? result.downloadEventCoupons.coupons[0].id : ''

    await refetch()
    setLoadingCouponId(null)
    if (typeof addLocation === 'undefined') return
    trackClickCouponDownloadButtonEvent({
      addLocation,
      couponId: +decodeId(couponId).id,
      couponExpiredAt,
      couponDiscountAmount,
      couponDiscountRate,
      couponName,
      couponGroupId,
    })
  }

  const handleClickConfirm = () => {
    closeBottomSheet()
  }

  return (
    <ProductCouponDownloadWrapper className="product-coupon-download">
      <div className="product-coupon-download__content">
        <div className="product-coupon-download__paragraph">
          <Typo variant={TypoVariant.Body4Regular} color={color.gray500}>
            상품쿠폰 1장은 옵션 1개에 적용 가능
          </Typo>
        </div>
        {isLoading && (
          <StyledSpinnerContainer>
            <Spinner />
          </StyledSpinnerContainer>
        )}
        <ul className="product-coupon-download__list">
          {data?.map((coupon) => {
            if (!coupon.couponGroup) return
            const {
              id: couponGroupId,
              name,
              canConjunctUse,
              minOrderAmount = 0,
              maxDiscountAmount,
              issueType,
              isMembership,
              discountAmount,
              discountType,
              discountRate,
              usingPeriod,
            } = coupon.couponGroup
            const {
              downloadLimit = 0,
              appliedDiscountAmount,
              startedAt,
              expiredAt,
              hasSpecifiedCoverage,
              coverage,
              downloadedCount,
            } = coupon

            const isDownloadableCoupon = downloadLimit !== downloadedCount
            const couponCoverageContent = hasSpecifiedCoverage
              ? coverage?.targets.map(({ name }) => name).join(', ')
              : ''

            const datePeriodText = getDatePeriodTextNew({
              hasUsingPeriod: !!usingPeriod,
              startedAt: new Date(startedAt),
              expiredAt: new Date(expiredAt),
            })

            const amountText = getAmountText({
              hasMinOrderAmount: minOrderAmount > 0,
              minOrderAmount,
              maxDiscountAmount,
            })

            const couponTitle = getBenefitTextNew({
              appliedDiscountAmount,
              discountAmount,
              discountType,
              discountRate,
              issueType,
              name,
              isAvailable: true,
            })

            return (
              <li className="product-coupon-download__item" key={couponGroupId}>
                <DownloadProductCouponCard
                  title={couponTitle}
                  isMembership={isMembership}
                  isConjunctUse={canConjunctUse}
                  name={name}
                  amount={amountText}
                  datePeriod={datePeriodText}
                  couponCoverageContent={couponCoverageContent || ''}
                  isExcludeCouponCoverage={coupon.coverage?.hasExclusions || false}
                  isAvailableDownload={isDownloadableCoupon}
                  onClick={() =>
                    handleCouponDownload({
                      couponExpiredAt: expiredAt,
                      couponDiscountAmount: discountAmount,
                      couponDiscountRate: discountRate,
                      couponName: name,
                      couponGroupId: couponGroupId,
                    })
                  }
                  disabled={false}
                  downloadableQuantity={downloadLimit - downloadedCount}
                  isLoading={loadingCouponId === couponGroupId}
                />
              </li>
            )
          })}
        </ul>
      </div>
      <div className="product-coupon-download__footer">
        <button type="button" className="product-coupon-download__confirm" onClick={handleClickConfirm}>
          <Typo variant={TypoVariant.Body1Bold} color={color.grayWhite}>
            확인
          </Typo>
        </button>
      </div>
    </ProductCouponDownloadWrapper>
  )
}

const ProductCouponDownloadWrapper = styled.div`
  max-height: calc(90vh - 5.7rem); // 바텀시트 높이 - 바텀시트 헤더 높이
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  .product-coupon-download {
    &__content {
      overflow-y: auto;
      padding: 1rem 1.6rem 1.6rem;
    }
    &__paragraph {
      margin-bottom: 1.6rem;
    }
    &__list {
      margin: 0;
      display: flex;
      flex-direction: column;
      gap: 1.2rem;
    }
    &__footer {
      background-color: ${({
        theme: {
          color: { grayWhite },
        },
      }) => grayWhite};
      position: sticky;
      bottom: 0;
      padding: 1.6rem 1.6rem calc(env(safe-area-inset-bottom) + 1.6rem);
    }
    &__confirm {
      cursor: pointer;
      width: 100%;
      padding: 1.6rem;
      border: none;
      border-radius: 0.8rem;
      background-color: ${({
        theme: {
          color: { blue500 },
        },
      }) => blue500};
    }
  }
`

const StyledSpinnerContainer = styled.div`
  height: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
`
