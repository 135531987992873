import { FC } from 'react'
import styled, { useTheme } from 'styled-components'
import FlatIcon from '@/components/common/icons/FlatIcon'
import { GlobalColorEnum } from '@/constants/global-color.enum'
import { IconNameEnum } from '@/constants/icon-name.enum'
import { SCREEN_MEDIA_QUERY, Z_INDEXES } from '@/constants/legacy/constLayout'

type ButtonCircleProps = {
  visible: boolean
  onClick: () => void
}

const ButtonCircleLeft: FC<ButtonCircleProps> = ({ visible, onClick }) => {
  const { spacing, color } = useTheme()

  if (!visible) {
    return null
  }

  return (
    <StyledContainer onClick={onClick}>
      <FlatIcon type={IconNameEnum.IcArrowLeft} color={color.gray300} size={spacing.size24} />
    </StyledContainer>
  )
}

const ButtonCircleRight: FC<ButtonCircleProps> = ({ visible, onClick }) => {
  const { spacing, color } = useTheme()

  if (!visible) {
    return null
  }

  return (
    <StyledContainer onClick={onClick}>
      <FlatIcon type={IconNameEnum.IcArrowRight} color={color.gray300} size={spacing.size24} />
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
  z-index: ${Z_INDEXES.SCROLL_TOP_BUTTON};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;
  box-sizing: border-box;
  outline: none;
  overflow: hidden;
  border: 1px solid ${GlobalColorEnum.Gray100};
  width: 3.6rem;
  height: 3.6rem;
  border-radius: 5rem;
  background-color: ${(props) => props.theme.color.grayWhite};

  @media ${SCREEN_MEDIA_QUERY.xs} {
    display: none;
  }
`

type SubComponents = {
  Left: FC<ButtonCircleProps>
  Right: FC<ButtonCircleProps>
}

const ButtonCircle: FC & SubComponents = () => <></>
ButtonCircle.Left = ButtonCircleLeft
ButtonCircle.Right = ButtonCircleRight

export default ButtonCircle
