import { FieldKeysEnum } from '@/constants/field-keys.enum'
import { UserGenderEnum } from '@/constants/user-gender.enum'
import { IModelUserDefault } from '@/containers/models/base/defaultModelInterfaces'
import { ModelUserDefault } from '@/containers/models/base/defaultModels'
import { IModelBrand } from '@/containers/models/modelBrand'
import { IModelCoupon } from '@/containers/models/modelCoupon'
import { IModelMileage } from '@/containers/models/modelMileage'
import { IModelProduct } from '@/containers/models/modelProduct'
import { IModelSocialService } from '@/containers/models/modelSocialService'

export interface IModelUser extends IModelUserDefault {
  hasAccount: boolean
  wishedProducts: IModelProduct[]
  wishedBrands: IModelBrand[]
  mileage: IModelMileage
  coupons: IModelCoupon[]
  userCoupons: IModelCoupon[]
  userSocialServices: IModelSocialService[]
  gender4tracking: string

  payMethodType: string
  isUseAllMileage: boolean
  isTest: boolean

  initRefundAccountFormValues(): any

  hasDefaultTemplateAddress(): boolean
}

export default class ModelUser extends ModelUserDefault {
  hasAccount = false
  gender4tracking = 'u'

  constructor(data: IModelUser) {
    super(data)
    this.hasAccount = !!this.accountNumber
    this.gender4tracking = setGender4tracking(this.gender)
  }

  initRefundAccountFormValues = () => {
    return {
      [FieldKeysEnum.BankCode]: this.bankCode,
      [FieldKeysEnum.BankName]: this.bankName,
      [FieldKeysEnum.AccountHolderName]: this.accountHolderName,
      [FieldKeysEnum.AccountNumber]: this.accountNumber,
    }
  }
}

const setGender4tracking = (gender: string) => {
  if (gender === UserGenderEnum.Female) return 'w'
  if (gender === UserGenderEnum.Male) return 'm'
  return 'u'
}
