import { forwardRef } from 'react'
import styled from 'styled-components'

interface NewColProps extends React.ComponentPropsWithoutRef<'div'> {
  flex?: number | 'none' | 'auto' | string
  width?: number | string // number: px, string: %, rem, em, etc.
  style?: React.CSSProperties
  className?: string
  onClick?: () => void
}

const convertTypeToStrPx = (value: string | number | undefined) => {
  if (typeof value === 'number') {
    return `${value}px`
  }
  return value
}

export const NewCol = forwardRef<HTMLDivElement, NewColProps>(({ children, ...props }, ref) => {
  return (
    <StyledNewCol ref={ref} {...props}>
      {children}
    </StyledNewCol>
  )
})

NewCol.displayName = 'NewCol'

const StyledNewCol = styled.div<NewColProps>`
  flex: ${({ flex }) => flex};
  width: ${({ width }) => convertTypeToStrPx(width)};
`
