import * as Sentry from '@sentry/nextjs'
import { useGetTestGroupsV2 } from '@/apis/rest/generated/hooks'
import { ABTestGroupCodeEnum } from '@/apis/rest/generated/types'
import { LocalABFlagEnum } from '@/constants/local-abflag.enum'
import { useCustomRouter, utilABTest } from '@/containers/hooks/index'
import { createDeviceUuid } from '@/utils/utilCrypto'

export const ABTestGroupCase = {
  BOTTOM_SHEET_RECOMMENDATION_SLOT_IMPROVEMENT_ON_ADD_TO_CART:
    'BOTTOM_SHEET_RECOMMENDATION_SLOT_IMPROVEMENT_ON_ADD_TO_CART',
  EXPOSE_RECENT_BOUGHT_PRODUCT_ON_PRODUCT_SEARCH_VIEW: 'EXPOSE_RECENT_BOUGHT_PRODUCT_ON_PRODUCT_SEARCH_VIEW',
  FAVORITE_PRODUCT_PRICE_CHANGE_NUDGING: 'FAVORITE_PRODUCT_PRICE_CHANGE_NUDGING',
  MAX_REWARD_AND_TOTAL_SALES_EXPOSURE_IN_SEARCH_RESULTS: 'MAX_REWARD_AND_TOTAL_SALES_EXPOSURE_IN_SEARCH_RESULTS',
  PRODUCT_RECOMMENDATION_ACCESSIBILITY: 'PRODUCT_RECOMMENDATION_ACCESSIBILITY',
  RECOMMENDED_PRODUCTS_FOR_INSUFFICIENT_AMOUNT_IN_CART: 'RECOMMENDED_PRODUCTS_FOR_INSUFFICIENT_AMOUNT_IN_CART',
  REDUCE_HOME_MAIN_TOP_AREA: 'REDUCE_HOME_MAIN_TOP_AREA',
  SEARCH_KEYWORD: 'SEARCH_KEYWORD',
  SIMILAR_PRODUCT_EXPOSURE_AFTER_SEARCH_RESULT_EXIT: 'SIMILAR_PRODUCT_EXPOSURE_AFTER_SEARCH_RESULT_EXIT',
  SORT_REVIEW_COUNT_ON_PRODUCT_LIST_VIEW_BY_CATEGORY: 'SORT_REVIEW_COUNT_ON_PRODUCT_LIST_VIEW_BY_CATEGORY',
} as const

export type ABTestGroupCase = typeof ABTestGroupCase[keyof typeof ABTestGroupCase]

export const useAbGroup = () => {
  const { query } = useCustomRouter()
  const abQuerySting = (query.ab as string)?.toUpperCase() as ABTestGroupCodeEnum
  const getLocalStorageAB = utilABTest().getAB(LocalABFlagEnum.Global) as ABTestGroupCodeEnum

  const uuid = createDeviceUuid()

  const { data, isLoading } = useGetTestGroupsV2(
    {
      uuid,
    },
    {
      query: {
        enabled: !!uuid,
        staleTime: 1000 * 60 * 5,
        onError: (error) => {
          Sentry.captureException(`AB 테스트 서버 호출 에러 ${error}`)
        },
      },
    }
  )

  const getAbByKey = ({ abKey }: { abKey: ABTestGroupCase }) => {
    const target = data?.find((item) => item.case === abKey)
    const abCode: ABTestGroupCodeEnum = abQuerySting || getLocalStorageAB || (target?.code as ABTestGroupCodeEnum)
    if (!target && !isLoading && !!data) {
      Sentry.withScope((scope) => {
        scope.setTag('errorType', 'ABCode Error')
        scope.setExtra('ABTestResponseData', data)
        scope.setExtra('ABTestTarget', target)
        scope.setExtra('ABCode', abCode)

        Sentry.captureException(`AB 테스트 CASE find 에러 ${abKey}`)
      })
    }

    return {
      ab: target,
      uuid,
      abCode,
      isAbLoading: isLoading,
    }
  }

  return {
    getAbByKey,
  }
}
