import { gql } from '@apollo/client'
import DEFAULT_FRAGMENTS from '@/containers/gqls/base/defaultFragments'

const BANNER_QUERY_GQLS = {
  // banner 직접 쿼리 호출은 상품상세 배너만 진행
  BANNERS: gql`
    query banners($pageInfo: PageInfoInputType, $filter: GenericScalar) {
      banners(pageInfo: $pageInfo, filter: $filter) {
        edges {
          node {
            ...banner
            productTopBannerInfo {
              adId
              adName
              adSlug
              mobileImage {
                link
                image
              }
            }
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
    }
    ${DEFAULT_FRAGMENTS.banner}
    ${DEFAULT_FRAGMENTS.pageInfo}
  `,
}

export default BANNER_QUERY_GQLS
