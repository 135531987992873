import { Url } from 'url'
import { useUserInfo } from '@/containers/hooks'
import { IModelUser } from '@/containers/models/modelUser'
import { InAppBridge } from '@/utils/bridge'
import { isInApp } from '@/utils/utilCommon'

const getFullPath = (url: Url | string) => {
  const isLocal = window.location.host?.includes('4000')
  const host = isLocal ? `${window.location.origin}/mall` : process.env.HOST
  if (typeof url === 'string') {
    // https, http 로 시작하는 경우 풀패스로 인식해서 현재 패스를 사용하지 않는다.
    if (url.startsWith('http://') || url.startsWith('https://')) {
      return url
    }
    return `${host}${url}`
  }
  if (url?.query && Object.keys(url.query).length > 0) {
    const fullPath = `${host}${Object.keys(url.query).reduce(
      // TODO typescript 오류 수정 필요
      // @ts-ignore
      (p: string, c: string) => p.replace(`[${c}]`, url.query[c]),
      // TODO typescript 오류 수정 필요
      // @ts-ignore
      url.pathname
    )}?${Object.keys(url.query)
      // TODO typescript 오류 수정 필요
      // @ts-ignore
      .map((v) => `${v}=${url.query[v] ? url.query[v] : ''}`)
      .join('&')}`
    return fullPath
  }
  if (url?.pathname?.startsWith('http://') || url?.pathname?.startsWith('https://')) {
    return url.pathname
  }
  return `${host}${url.pathname}`
}

let appBridge: InAppBridge | null = null

const appBridgeProvider = (callback: Function) => {
  if (isInApp()) {
    if (!appBridge) {
      appBridge = new InAppBridge()
      appBridge.init()
    }
    return callback(appBridge)
  }
}
// 4.1 버전까지만 유지한다. 메인홈이 네이티브로 변경되면 삭제 해야함
export const appBridgeRouter = () => {
  if (!appBridge) {
    appBridge = new InAppBridge()
    appBridge.init()
  }
  return {
    push(url: Url | string, as?: Url, options?: object): Promise<any> {
      return appBridge!.routerPush(getFullPath(url), options)
    },
    back(): Promise<any> {
      return appBridge!.routerGo(-1)
    },
    replace(url: Url | string, as?: Url, options?: object): Promise<any> {
      return appBridge!.routerReplace(getFullPath(url), options)
    },
  }
  // }
}

export const appBridgeLogin = () => {
  const { useSaveUserInfo } = useUserInfo
  const { saveToken } = useSaveUserInfo()

  type AppUserInfoType = {
    apiToken: Object
    router: Object
    userInfo: IModelUser
    nativeScreenInfo: { statusBarHeight: number }
  }

  return async (res: AppUserInfoType) => {
    await saveToken(
      res.apiToken as {
        token: string
        refreshToken: string
        expiredAt: string
      }
    )
    return true
  }
}

export const appBridgeLogout = () => {
  const { useLogout } = useUserInfo
  const { logout } = useLogout()

  return () => {
    logout(false)
    return true
  }
}

// url 조립하는 함수
export const isInAppFlag = isInApp() as boolean

const getAppVersion = () => {
  if (window.hasOwnProperty('getAppVersion')) {
    return parseInt((window as any).getAppVersion())
  }
  return 0
}

export const isAppTopSlimBannerClose = isInAppFlag && getAppVersion() >= 4211
export const isShareAvailable = isInAppFlag && getAppVersion() >= 4103
export const isAppStorageDisabled = isInAppFlag && getAppVersion() < 4201
export const isLikedPromotion = isInAppFlag && getAppVersion() >= 4205
export const isInAppReview = isInAppFlag && getAppVersion() >= 4205
export const isAppBuyNow = isInAppFlag && getAppVersion() >= 4207
export const isAppNonLoginCart = isInAppFlag && getAppVersion() >= 4207
export const isAbleMembership = isInAppFlag && getAppVersion() >= 4217 // 멤버십 초대 원링크 진입시 딥링크 query param 가능한 버전, todo membership, 변경 가능성 있음
export const isAbleStatusBarFontChange = isInAppFlag && getAppVersion() > 4217

export const isHomeWebView = isInAppFlag && getAppVersion() >= 4258

export const isAbleAppChannelTalk = isInAppFlag && getAppVersion() >= 4273

export default appBridgeProvider
