import { FC } from 'react'
import styled from 'styled-components'
import { ImageAsset } from '@/components/common'
import { LinkButton } from '@/components/common/buttons'
import FlatIcon from '@/components/common/icons/FlatIcon'
import { CartItemCountCircle } from '@/components/domains/etc'
import HeaderPageTitle from '@/components/domains/layouts/header/HeaderPageTitle'
import { PetTypeFilter } from '@/components/domains/search'
import { IconNameEnum } from '@/constants/icon-name.enum'
import { IconSizeEnum } from '@/constants/icon-size.enum'
import ROUTES from '@/constants/legacy/constRoutes'

const ListFilterHeader: FC = () => {
  return (
    <>
      <StyledFilterHeaderContainer>
        <LinkButton goBack>
          <ImageAsset name="ic_left" size={24} svg={true} />
        </LinkButton>
        <HeaderPageTitle />
        <StyledCartIconContainer style={{ paddingRight: '0' }}>
          <LinkButton rel="nosublink" url={ROUTES.CART} style={{ display: 'flex', alignItems: 'center' }}>
            <CartItemCountCircle />
            <FlatIcon type={IconNameEnum.IcCart} size={IconSizeEnum.Size24} />
          </LinkButton>
        </StyledCartIconContainer>
      </StyledFilterHeaderContainer>
      <PetTypeFilter />
    </>
  )
}

const StyledCartIconContainer = styled.div`
  position: relative;
  max-width: 100%;
  min-height: 1px;
  padding-inline-end: 0;
`

const StyledFilterHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1.6rem;
  padding-left: 16px;
  padding-right: 16px;
  height: 5.8rem;
  flex-flow: nowrap;
`

export default ListFilterHeader
