import { BottomSheetModal } from '@/components/common/modals/BottomSheetModal'
import { ProductCouponDownload } from '@/components/domains/coupon/ProductCouponDownload'
import { useProductCouponDownloadBottomSheet } from '@/containers/hooks/bottom-sheet/useProductCouponDownloadBottomSheet'

export const ProductCouponDownloadBottomSheet = ({ visible }: { visible: boolean }) => {
  const { closeBottomSheet } = useProductCouponDownloadBottomSheet()
  return (
    <BottomSheetModal title="상품쿠폰" centerTitle titleNoBorder visible={visible} onClose={closeBottomSheet}>
      <ProductCouponDownload />
    </BottomSheetModal>
  )
}
