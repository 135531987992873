import { BannerTypesEnum } from '@/constants/banner-types.enum'
import { GlobalColorEnum } from '@/constants/global-color.enum'
import { IModelBannerDefault } from '@/containers/models/base/defaultModelInterfaces'
import { ModelBannerDefault } from '@/containers/models/base/defaultModels'
import { IModelBannerImage } from '@/containers/models/modelBannerImage'
import { IModelSlimBannerInfo } from '@/containers/models/modelSlimBannerInfo'
import { IModelTopRollBannerInfo } from '@/containers/models/modelTopRollBannerInfo'

export interface IModelBanner extends IModelBannerDefault {
  bannerImages: IModelBannerImage[]

  topSlimBannerInfo: IModelSlimBannerInfo
  topRollBannerInfo: IModelTopRollBannerInfo
  coverBackgroundColor: string

  isTopSlimBanner: () => boolean
  isTopRollBanner: () => boolean
}

export default class ModelBanner extends ModelBannerDefault {
  coverBackgroundColor = ''
  constructor(data: IModelBanner) {
    super(data)
    this.coverBackgroundColor = (() => {
      let style: any = data.style
      if (style === null) return style
      try {
        style = JSON.parse(style)
      } catch (e) {
        // JSON 형식이 아닙니다.
      }
      return style.coverBackgroundColor || style['background_color'] || GlobalColorEnum.GrayWhite
    })() // TODO: 현재 style을 string으로 받고 있어서 타입추론이 안되는 상태임, 내용 확인이 되면 개선이 필요
  }
  isTopSlimBanner = () => {
    return this.bannerType === BannerTypesEnum.BannerTopSlim
  }
  isTopRollBanner = () => {
    return this.bannerType === BannerTypesEnum.BannerTopRoll
  }
}
