import moment from 'moment'
import { CouponDiscountTypeEnum } from '@/constants/coupon-discount-type.enum'
import { IModelCouponDefault } from '@/containers/models/base/defaultModelInterfaces'
import { ModelCouponDefault } from '@/containers/models/base/defaultModels'
import { IModelCouponGroup } from '@/containers/models/modelCouponGroup'
import { ceil, floor } from '@/utils/utilCommon'
import { date2string, DATE_FORMAT_MMDDHHmm_KOREAN } from '@/utils/utilDate'

export interface IModelCoupon extends IModelCouponDefault {
  couponGroup: IModelCouponGroup
  calculateDiscountAmount: (productCustomerPrice: number, purchaseQuantity: number) => number
  calcDefaultOptionDiscountAmount: (productPromotionCustomerPrice: number) => number
  getExpiredTextInMonth: () => string | undefined
  getExpiredDateText: () => string
  getDaysRemainingToUse: () => number
  canConjunctUse: () => boolean
  getCouponName: () => string
}

export default class ModelCoupon extends ModelCouponDefault {
  canConjunctUse = () => {
    return this.couponGroup.canConjunctUse
  }

  getDaysRemainingToUse = () => {
    const _expiredAt = moment(this.expiredAt).utc()
    const duration = moment.duration(_expiredAt.diff(moment()))
    return ceil(duration.asDays(), 0)
  }

  getExpiredTextInMonth = () => {
    const daysRemaining = this.getDaysRemainingToUse()
    if (daysRemaining > 29 || 0 > daysRemaining) {
      return undefined
    }

    if (daysRemaining === 1) {
      return '오늘 만료'
    }
    return `${daysRemaining}일 남음`
  }

  getExpiredDateText = () => {
    return `${date2string(this.expiredAt!, DATE_FORMAT_MMDDHHmm_KOREAN)} 까지`
  }

  calculateDiscountAmount = (productCustomerPrice: number, purchaseQuantity: number) => {
    const { discountType, discountAmount, discountRate, maxDiscountAmount } = this.couponGroup

    let _discountAmount = 0
    const _totalProductAmount = productCustomerPrice * purchaseQuantity

    if (discountType === CouponDiscountTypeEnum.Amount) {
      _discountAmount = discountAmount
    } else if (discountType === CouponDiscountTypeEnum.Rate) {
      _discountAmount = _totalProductAmount * discountRate
    }

    if (maxDiscountAmount > 0 && _discountAmount > maxDiscountAmount) {
      _discountAmount = maxDiscountAmount
    } else {
      _discountAmount = _discountAmount
    }

    if (_discountAmount > _totalProductAmount) {
      _discountAmount = _totalProductAmount
    }

    return floor(_discountAmount)
  }

  calcDefaultOptionDiscountAmount = (productPromotionCustomerPrice: number) => {
    return this.calculateDiscountAmount(productPromotionCustomerPrice, 1)
  }

  getCouponName = () => {
    return this.couponGroup?.name ?? ''
  }
}
