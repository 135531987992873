import { FC, useState } from 'react'
import moment from 'moment'
import { useRouter } from 'next/router'
import styled, { useTheme } from 'styled-components'
import FlatIcon from '@/components/common/icons/FlatIcon'
import { SkeletonPopularKeyword } from '@/components/domains/search/skeleton'
import { IconNameEnum } from '@/constants/icon-name.enum'
import { QueryStringKeyEnum } from '@/constants/query-string-key.enum'
import { SearchLocationEnum } from '@/constants/search-location.enum'
import { useTracker } from '@/containers/contexts/EventTrackingProvider'
import { useSearch } from '@/containers/hooks'
import { usePopularKeywordsQuery } from '@/graphql/generated/hooks'
import { AdPetType, PopularKeywordType } from '@/graphql/generated/schemas'
import { FitpetMallEventEnum } from '@/utils/event-tracker/events'

const PopularKeywords: FC = () => {
  const [popularKeywords, setPopularKeywords] = useState<Array<PopularKeywordType>>([])
  const {
    query: { [QueryStringKeyEnum.SearchPetType]: searchPetType },
  } = useRouter()

  const { triggerCustomEvent } = useTracker()
  const { goToResultPage } = useSearch()

  const { loading } = usePopularKeywordsQuery({
    fetchPolicy: 'cache-first',
    variables: {
      petType: searchPetType as AdPetType,
    },
    onCompleted(data) {
      if (!data) {
        return
      }
      setPopularKeywords(data?.popularKeywords?.edges.map(({ node }) => node as PopularKeywordType) || [])
    },
  })

  const clickPopularKeyword = (node: PopularKeywordType, index: number) => {
    triggerCustomEvent(FitpetMallEventEnum.ClickSearchPopularKeyword, {
      search_keyword: node.keyword,
      autoCompleteKeyword: node.keyword,
      listIndex: index,
      petTypeSearch: searchPetType,
    })

    goToResultPage({
      [QueryStringKeyEnum.SearchQuery]: node.keyword || '',
      [QueryStringKeyEnum.SearchLocation]: SearchLocationEnum.Popular,
    })
  }

  const NoArrowRank = ({ isNewRank }: { isNewRank?: boolean }): JSX.Element => {
    const { spacing } = useTheme()
    if (isNewRank) {
      return <StyledNoArrowRankNEW>NEW</StyledNoArrowRankNEW>
    } else {
      return <FlatIcon type={IconNameEnum.IcRankingNone} size={spacing.size18} />
    }
  }

  const ArrowRank = ({ changeRank }: { changeRank: number }): JSX.Element => {
    const { spacing } = useTheme()
    if (changeRank > 0) {
      return (
        <StyledRank>
          <FlatIcon type={IconNameEnum.IcRankingUp} size={spacing.size18} />
        </StyledRank>
      )
    } else {
      return (
        <StyledRank>
          <FlatIcon type={IconNameEnum.IcRankingDown} size={spacing.size18} />
        </StyledRank>
      )
    }
  }

  return (
    <>
      <StyledPopularKeywordHeader>
        <StyledTitle>인기 검색어</StyledTitle>
        <StyledCurrentTime>{moment().format('MM. DD A hh:mm')}</StyledCurrentTime>
      </StyledPopularKeywordHeader>
      {!loading ? (
        <StyledKeywordList isTwoColumn={popularKeywords.length > 5}>
          {popularKeywords.map((node, index) => (
            <StyledKeywordNode onClick={() => clickPopularKeyword(node, index)} key={index}>
              <StyledRankKeyword>
                <div className="rank">{node.rank}</div>
                <div className="keyword" data-cy="popular-search-keyword">
                  {node.keyword}
                </div>
              </StyledRankKeyword>
              <StyledChangeRank>
                {node.changeRank === 0 ? (
                  <NoArrowRank isNewRank={node.isNewRank} />
                ) : (
                  <ArrowRank changeRank={node.changeRank} />
                )}
              </StyledChangeRank>
            </StyledKeywordNode>
          ))}
        </StyledKeywordList>
      ) : (
        <SkeletonPopularKeyword />
      )}
    </>
  )
}

const StyledPopularKeywordHeader = styled.div`
  padding: 0 16px;
`

const StyledTitle = styled.span`
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.02em;
  color: ${({ theme: { color } }) => color.gray900};
`

const StyledCurrentTime = styled.div`
  margin-top: 4px;
  margin-bottom: 12px;
  color: ${({ theme: { color } }) => color.gray400};
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: -0.02em;
`

const StyledKeywordList = styled.div<{ isTwoColumn: boolean }>`
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: repeat(5, 1fr);
  grid-template-columns: ${({ isTwoColumn }) => (isTwoColumn ? 'repeat(2, 50%)' : 'repeat(1, 100%)')};
`

const StyledKeywordNode = styled.div`
  padding: 11px 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`

const StyledRankKeyword = styled.div`
  display: flex;
  flex: 1;
  width: 1px;
  gap: 6px;
  color: ${({ theme: { color } }) => color.gray900};
  letter-spacing: -0.02em;
  font-size: 14px;
  line-height: 18px;

  .rank {
    min-width: 18px;
    font-weight: 700;
    text-align: center;
  }

  .keyword {
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`

const StyledNoArrowRankNEW = styled.span`
  font-weight: 600;
  font-size: 8px;
  line-height: 16px;
  letter-spacing: -0.02em;
  color: ${({ theme: { color } }) => color.red500};
`

const StyledChangeRank = styled.div`
  width: 20px;
  padding-left: 2px;
  display: flex;
  justify-content: center;
`

const StyledRank = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export default PopularKeywords
