import DataBase, { IDataBase } from '@/containers/gqls/data/dataBase'

export interface IDataResult extends IDataBase {
  data: any
}

export default class DataResult extends DataBase implements IDataResult {
  totalCount?: number

  setData(data: any) {
    this.data = DataBase.getModel(data)
    return this
  }
}
