import { useDispatch } from 'react-redux'
import { BottomSheetModal } from '@/components/common/modals/BottomSheetModal'
import { ProductChoice } from '@/components/domains/cart/product-choice/ProductChoice'
import { doSetBottomSheetData } from '@/stores/reduxData'

export const ProductChoiceBottomSheet = ({ visible }: { visible: boolean }) => {
  const dispatch = useDispatch()
  return (
    <BottomSheetModal
      title="상품 선택"
      titleNoBorder
      centerTitle
      visible={visible}
      onClose={() => {
        dispatch(
          doSetBottomSheetData({
            isProductChoiceVisible: false,
          })
        )
      }}
    >
      <ProductChoice />
    </BottomSheetModal>
  )
}
