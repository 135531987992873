import { UseInfiniteQueryOptions, useInfiniteQuery } from '@tanstack/react-query'
import { getFrequentlyBoughtV2, getGetFrequentlyBoughtV2QueryKey } from '@/apis/rest/generated/hooks'
import { FrequentlyBoughtResponseDtoV2, GetFrequentlyBoughtV2Params } from '@/apis/rest/generated/types'
import { UserContainer } from '@/containers/users/UserContainer'

interface UseFrequentlyBoughtV2Props extends GetFrequentlyBoughtV2Params {
  options?: Omit<
    UseInfiniteQueryOptions<
      FrequentlyBoughtResponseDtoV2,
      unknown,
      FrequentlyBoughtResponseDtoV2,
      FrequentlyBoughtResponseDtoV2,
      any
    >,
    'queryKey'
  >
  keySuffix?: string
}

const useFrequentlyBoughtV2 = ({ page = 1, size = 8, options = {}, keySuffix }: UseFrequentlyBoughtV2Props) => {
  const { me } = UserContainer.useContainer()
  const { data, fetchNextPage, isLoading, hasNextPage, isFetching } = useInfiniteQuery(
    [
      ...getGetFrequentlyBoughtV2QueryKey({
        page,
        size,
      }),
      ...(keySuffix ? [keySuffix] : []),
    ],
    ({ pageParam = page }) => getFrequentlyBoughtV2({ page: pageParam, size }),
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.data.length < size) {
          return
        }
        return lastPage.page.page + 1
      },
      enabled: !!me,
      ...options,
    }
  )

  const frequentlyBoughtProducts = data?.pages.map((page) => page.data).flat()

  return {
    data: frequentlyBoughtProducts || [],
    fetchNextPage,
    isLoading,
    isFetching,
    hasNextPage,
  }
}

export { useFrequentlyBoughtV2 }
