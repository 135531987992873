import { FC } from 'react'
import styled, { keyframes } from 'styled-components'
import { GlobalColorEnum } from '@/constants/global-color.enum'

const SkeletonPopularKeyword: FC = () => {
  return (
    <StyledKeywordList>
      {Array.from({ length: 10 }).map((_, index) => (
        <StyledKeywordNode key={index}>
          <StyledRankKeyword>
            <div className="rank">{index + 1}</div>
            <div className="keyword" />
          </StyledRankKeyword>
        </StyledKeywordNode>
      ))}
    </StyledKeywordList>
  )
}

const StyledKeywordList = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: repeat(5, 1fr);
  grid-template-columns: repeat(2, 50%);
`

const shineLines = keyframes`
  0% {
    background-position: -100px;
  }
  40%, 100% {
    background-position: 100px;
  }
`

const StyledRankKeyword = styled.div`
  display: flex;
  gap: 4px;
  color: ${(props) => props.theme.color.gray900};
  line-height: 18px;

  .rank {
    min-width: 18px;
    font-weight: 700;
    text-align: center;
  }

  .keyword {
    width: 12rem;
    height: 18px;
    border-radius: 5px;
    background-image: linear-gradient(
      90deg,
      ${GlobalColorEnum.Gray70} 0px,
      ${GlobalColorEnum.Gray50} 40px,
      ${GlobalColorEnum.Gray70} 80px
    );
    background-size: 600px;
    animation: ${shineLines} 3.5s infinite;
  }
`

const StyledKeywordNode = styled.div`
  padding: 11px 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`

export default SkeletonPopularKeyword
