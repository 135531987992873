/*
 * react-hotjar 참고 - https://github.com/abdalla/react-hotjar
 */

import { BaseEventHandler } from '@/utils/event-tracker/handlers/baseEventHandler'

type HotjarType = {
  initialize: (id: number, sv: number) => void
  identify: (userId: string, properties: { [key: string]: any }) => void
  event: (event: string) => void
  stateChange: (relativePath: string) => void
}

const hotjar: HotjarType = {
  initialize,
  identify: (userId, properties) => {
    // https://help.hotjar.com/hc/en-us/articles/360033640653
    hj('identify', userId, properties)
  },
  event: (event) => {
    // https://help.hotjar.com/hc/en-us/articles/4405109971095
    hj('event', event)
  },
  stateChange: (relativePath) => {
    // https://help.hotjar.com/hc/en-us/articles/360034378534
    hj('stateChange', relativePath)
  },
}

export class HotjarHandler extends BaseEventHandler {
  private hotjarVersion: number = 6
  private hotjarId: string = process.env.HOTJAR_API_ID || ''

  initialize = () => {
    hotjar.initialize(Number(this.hotjarId), this.hotjarVersion)
  }

  // eslint-disable-next-line class-methods-use-this
  setUserId = (userId: string) => {
    hotjar.identify(userId, {})
  }
}

// hotjar에서 제공하는 script로 typescript 적용불필요
// TODO typescript 오류 수정 필요
// @ts-ignore
function initialize(id, sv) {
  ;(function (h, o, t, j, a, r) {
    // TODO typescript 오류 수정 필요
    // @ts-ignore
    h.hj =
      // TODO typescript 오류 수정 필요
      // @ts-ignore
      h.hj ||
      function () {
        // TODO typescript 오류 수정 필요
        // @ts-ignore
        // eslint-disable-next-line prefer-rest-params
        ;(h.hj.q = h.hj.q || []).push(arguments)
      }
    // TODO typescript 오류 수정 필요
    // @ts-ignore
    h._hjSettings = { hjid: id, hjsv: sv }
    // TODO typescript 오류 수정 필요
    // @ts-ignore
    h._scriptPath = t + h._hjSettings.hjid + j + h._hjSettings.hjsv

    // hj script가 없는 경우에만 script 다시붙임
    // TODO typescript 오류 수정 필요
    // @ts-ignore
    if (!document.querySelector('script[src*="' + h._scriptPath + '"]')) {
      // TODO typescript 오류 수정 필요
      // @ts-ignore
      a = o.getElementsByTagName('head')[0]
      // TODO typescript 오류 수정 필요
      // @ts-ignore
      r = o.createElement('script')
      // TODO typescript 오류 수정 필요
      // @ts-ignore
      r.async = 1
      // TODO typescript 오류 수정 필요
      // @ts-ignore
      r.src = h._scriptPath
      // TODO typescript 오류 수정 필요
      // @ts-ignore
      a.appendChild(r)
    }
  })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=')
}

// TODO typescript 오류 수정 필요
// @ts-ignore
function hj(...params) {
  // TODO typescript 오류 수정 필요
  // @ts-ignore
  if (!window.hj) {
    throw new Error('Hotjar is not initialized')
  }

  // TODO typescript 오류 수정 필요
  // @ts-ignore
  window.hj(...params)
}
