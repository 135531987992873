import { match } from 'ts-pattern'
import { FitpetMallEventEnum } from '@/constants/fitpet-mall-event.enum'
import { UserCertificationTypeEnum } from '@/constants/user-certification-type.enum'
import { useTracker } from '@/containers/contexts/EventTrackingProvider'
import { SocialServiceSocialType } from '@/graphql/generated/schemas'
import { decodeIdForEventTracker } from '@/utils/utilApi'

type SubmitInfoEventParams = {
  signupType?: typeof SocialServiceSocialType[keyof typeof SocialServiceSocialType] | string
  errorType?: string
}

type ClickVerifyEventParams = {
  certType: UserCertificationTypeEnum
  socialType?: string
}
type ClickConfirmVerifyEventParams = {
  certType: UserCertificationTypeEnum
  socialType?: string
  isError?: boolean
}
type ClickSignUpInfoCodeCheckParams = {
  socialType?: string
  isError?: boolean
}

type CouponProperties = {
  id?: string
  name?: string
  couponType?: string
  discountAmount?: number
  discountRate?: number
}
type UserCouponMileageProperties = {
  coupons?: CouponProperties[]
  mileage?: number
}
const useAccountsEventTracker = () => {
  const tracker = useTracker()
  const trackClickLoginSearchIdCodeSendEvent = () => {
    tracker.triggerCustomEvent(FitpetMallEventEnum.ClickLoginSearchIdCodeSend)
  }
  const trackClickLoginSearchIdCodeCheckEvent = () => {
    tracker.triggerCustomEvent(FitpetMallEventEnum.ClickLoginSearchIdCodeCheck)
  }
  const trackClickLoginSearchPwCodeSendEvent = () => {
    tracker.triggerCustomEvent(FitpetMallEventEnum.ClickLoginSearchPwCodeSend)
  }
  const trackClickLoginSearchPwCodeCheckEvent = () => {
    tracker.triggerCustomEvent(FitpetMallEventEnum.ClickLoginSearchPwCodeCheck)
  }
  const trackClickSignupInfoCodeSendEvent = ({ signupType }: SubmitInfoEventParams) => {
    tracker.triggerCustomEvent(FitpetMallEventEnum.ClickSignupInfoCodeSend, {
      signup_type: signupType,
    })
  }

  const trackClickSignupInfoCodeCheckEvent = ({ socialType, isError }: ClickSignUpInfoCodeCheckParams) => {
    tracker.triggerCustomEvent(FitpetMallEventEnum.ClickSignupInfoCodeCheck, {
      is_error: isError,
      signup_type: socialType,
    })
  }
  const trackClickVerifyEvent = ({ certType, socialType }: ClickVerifyEventParams) => {
    return match(certType)
      .with(UserCertificationTypeEnum.NewUser, () => {
        trackClickSignupInfoCodeSendEvent({
          signupType: socialType,
        })
      })
      .with(UserCertificationTypeEnum.FindIdByMobileNumber, () => {
        trackClickLoginSearchIdCodeSendEvent()
      })
      .with(UserCertificationTypeEnum.ResetPasswordByEmail, () => {
        trackClickLoginSearchPwCodeSendEvent()
      })
      .with(UserCertificationTypeEnum.ResetPasswordByMobileNumber, () => {
        trackClickLoginSearchPwCodeSendEvent()
      })
  }

  const trackClickConfirmVerifyEvent = ({ certType, socialType, isError }: ClickConfirmVerifyEventParams) => {
    return match(certType)
      .with(UserCertificationTypeEnum.NewUser, () => {
        trackClickSignupInfoCodeCheckEvent({
          isError,
          socialType,
        })
      })
      .with(UserCertificationTypeEnum.FindIdByMobileNumber, () => {
        trackClickLoginSearchIdCodeCheckEvent()
      })
      .with(UserCertificationTypeEnum.ResetPasswordByEmail, () => {
        trackClickLoginSearchPwCodeCheckEvent()
      })
      .with(UserCertificationTypeEnum.ResetPasswordByMobileNumber, () => {
        trackClickLoginSearchPwCodeCheckEvent()
      })
  }

  const trackUserCouponMileageProperties = ({ coupons, mileage }: UserCouponMileageProperties) => {
    const couponProperties =
      coupons?.map((coupon: CouponProperties) => {
        return {
          coupon_id: decodeIdForEventTracker(coupon.id),
          coupon_name: coupon.name,
          coupon_type: coupon.couponType,
          coupon_discount_amount: coupon.discountAmount,
          coupon_discount_rate: coupon.discountRate,
        }
      }) || []
    tracker.setUserProperties({
      coupons: couponProperties,
      mileage: mileage,
    })
  }

  return {
    trackClickVerifyEvent,
    trackClickConfirmVerifyEvent,
    trackUserCouponMileageProperties,
  }
}

export { useAccountsEventTracker }
