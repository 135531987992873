import { gql } from '@apollo/client'

export const BRAND_QUERY_GQLS = {
  BRAND_NAME: gql`
    query brandName($id: ID!) {
      brand(id: $id) {
        id
        name
      }
    }
  `,
}
