import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import styled, { useTheme } from 'styled-components'
import { Typo, TypoVariant } from '@/components/common/typography'
import { useCartController } from '@/containers/hooks/cart/useCartController'
import { RootState } from '@/stores/store'
import { removeScrollbar } from '@/utils/utilCSS'
import { currencyText } from '@/utils/utilNumber'

export const CartChangeOptionContents = () => {
  const { color } = useTheme()
  const [selectedOption, setSelectedOption] = useState<number | null>(null)
  const { confirmChangeOption } = useCartController()
  const optionChangeBottomSheetData = useSelector(
    (state: RootState) => state.reduxDataReducers.cartChangeOptionBottomSheetData
  )

  const handleClickOption = (optionId: number) => {
    setSelectedOption(optionId)
  }

  const handleClickChange = () => {
    if (optionChangeBottomSheetData && selectedOption) {
      confirmChangeOption({
        cartItemId: optionChangeBottomSheetData.cartItemId,
        productOptionId: selectedOption,
      })
    }
  }

  useEffect(() => {
    if (optionChangeBottomSheetData) {
      setSelectedOption(optionChangeBottomSheetData.productOptionId)
    }
  }, [])

  if (!optionChangeBottomSheetData) {
    return null
  }

  return (
    <>
      <StyledContainer>
        <StyledOptionsContainer>
          {optionChangeBottomSheetData?.options?.map((option, index) => {
            const isSoldOut = option.availableQuantity === 0
            const textColor = isSoldOut ? color.gray400 : color.gray900
            return (
              <StyledProductOption
                key={option.id}
                isSelected={selectedOption === option.id}
                isSoldOut={isSoldOut}
                onClick={() => !isSoldOut && handleClickOption(option.id)}
              >
                <StyledOptionLabel>
                  {isSoldOut && (
                    <Typo variant={TypoVariant.Body3Bold} color={color.gray400}>
                      입고예정
                    </Typo>
                  )}

                  <Typo
                    variant={TypoVariant.Body3Regular}
                    style={{
                      display: '-webkit-box',
                      WebkitBoxOrient: 'vertical',
                      WebkitLineClamp: 2,
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      wordBreak: 'break-all',
                    }}
                    color={textColor}
                  >
                    {option.name}
                  </Typo>
                </StyledOptionLabel>
                <PriceBox>
                  <Typo
                    variant={TypoVariant.Body3Bold}
                    color={selectedOption === option.id ? color.blue500 : textColor}
                  >
                    {currencyText(option.salePrice)}
                  </Typo>
                </PriceBox>
              </StyledProductOption>
            )
          })}
        </StyledOptionsContainer>
        <StyledChangeButtonContainer>
          <ApplyCouponButton
            disabled={!selectedOption || optionChangeBottomSheetData.productOptionId === selectedOption}
            onClick={handleClickChange}
          >
            <Typo variant={TypoVariant.Body1Bold} color={color.grayWhite}>
              변경하기
            </Typo>
          </ApplyCouponButton>
        </StyledChangeButtonContainer>
      </StyledContainer>
    </>
  )
}

const StyledContainer = styled.div`
  overflow: auto;
  max-height: calc(90vh - 82px);
  ${removeScrollbar};
`

const StyledOptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.color.gray100};
  border-radius: 0.8rem;
  overflow-y: auto;
  margin: 1.6rem;
`

const StyledProductOption = styled.div<{ isSelected: boolean; isSoldOut: boolean }>`
  height: 6rem;
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  padding: 1.6rem;
  background-color: ${({ isSelected, theme, isSoldOut }) =>
    isSelected ? theme.color.blue30 : isSoldOut ? theme.color.gray50 : theme.color.grayWhite};
  gap: 1.6rem;
  align-items: center;
`

const StyledOptionLabel = styled.div`
  display: flex;
  gap: 0.8rem;
`

const StyledChangeButtonContainer = styled.div`
  padding: 1.6rem;
  margin-bottom: calc(env(safe-area-inset-bottom));
  margin-top: 1.6rem;
  position: sticky;
  background-color: ${({
    theme: {
      color: { grayWhite },
    },
  }) => grayWhite};
  bottom: 0;
  left: 0;
  width: 100%;
`

const ApplyCouponButton = styled.button<{ disabled?: boolean }>`
  width: 100%;
  padding: 1.4rem 1.6rem;
  background-color: ${({
    theme: {
      color: { blue500, gray200 },
    },
    disabled,
  }) => (disabled ? gray200 : blue500)};
  border-radius: 0.8rem;
  border: none;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`

const PriceBox = styled.div`
  flex-shrink: 0;
`
