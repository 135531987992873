import { AdPetType } from '@/graphql/generated/schemas'

const isAdPetType = (petType: unknown): petType is AdPetType => {
  return Object.values(AdPetType).includes(petType as AdPetType)
}

const UtilUrlController = {
  urlParamToPetType: (pathParam?: string | string[]) => {
    if (!pathParam || Array.isArray(pathParam)) {
      return AdPetType.All
    }
    const upperCasePathParam = pathParam.toUpperCase()
    return isAdPetType(upperCasePathParam) ? upperCasePathParam : AdPetType.All
  },
}

export { UtilUrlController }
