import styled, { css, useTheme } from 'styled-components'
import FlatIcon from '@/components/common/icons/FlatIcon'
import { IconNameEnum } from '@/constants/icon-name.enum'

interface LikeButtonProps {
  onToggle: () => void
  isLiked: boolean
}

const LikeButton = ({ onToggle, isLiked }: LikeButtonProps) => {
  const { color, iconSize } = useTheme()

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    onToggle()
  }

  return (
    <LikeButtonWrapper onClick={handleClick} isLiked={isLiked} aria-label="찜하기">
      {isLiked ? (
        <FlatIcon type={IconNameEnum.IcHeartFilled} color={color.red500} size={iconSize.size24} />
      ) : (
        <FlatIcon type={IconNameEnum.IcHeartDimmedThumbnail} color={color.grayWhite} size={iconSize.size24} />
      )}
    </LikeButtonWrapper>
  )
}

const LikeButtonWrapper = styled.button<{ isLiked: boolean }>`
  outline: none;
  background: transparent;
  border: none;
  cursor: pointer;
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 0.6rem;
  will-change: transform, opacity;
  ${({ isLiked }) =>
    isLiked &&
    css`
      animation-duration: 0.2s;
      animation-fill-mode: forwards;
      animation-name: pulse;

      @keyframes pulse {
        0% {
          transform: scale(1);
          opacity: 0;
        }
        50% {
          transition-timing-function: ease-in-out-cubic;
          opacity: 1;
          transform: scale(1.3);
        }
        100% {
          transform: scale(1);
        }
      }
    `}
`

export default LikeButton
