import { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { useSelector } from 'react-redux'
import styled, { useTheme } from 'styled-components'
import { ProductsListItemResponseDto } from '@/apis/rest/generated/types'
import FlatIcon from '@/components/common/icons/FlatIcon'
import { Typo, TypoVariant } from '@/components/common/typography'
import { ScrollContainer } from '@/components/domains/etc'
import { NewProductCard } from '@/components/domains/products/new-product-card/new-product-card'
import {
  PRODUCT_CARD_SIZES,
  THUMBNAIL_STATES,
} from '@/components/domains/products/new-product-card/new-product-card.type'
import { IconNameEnum } from '@/constants/icon-name.enum'
import { IconSizeEnum } from '@/constants/icon-size.enum'
import ROUTES from '@/constants/legacy/constRoutes'
import { ProductLocation } from '@/constants/product-location.const'
import { useBottomSheetEventTracker } from '@/containers/event-tracker/bottom-sheet.event.tracker'
import { useCustomRouter } from '@/containers/hooks'
import { useCartBottomSheetRestReduxData } from '@/containers/hooks/cart/useCartBottomSheetRestReduxData'
import { ABTestGroupCase, useAbGroup } from '@/containers/hooks/useAbGroup'
import { RootState } from '@/stores/store'
import { ProductTypeDefinedByFrontend } from '@/types/product-type-defined-by.frontend'
import { getLocationByPathname } from '@/utils/getLocationByPathname'
import { transformProductTypeDefinedByFrontend } from '@/utils/product/product-card.util'
import { removeScrollbar } from '@/utils/utilCSS'

type RecentlyProductListProps = {
  data: ProductsListItemResponseDto[]
}

const RecentlyProductListTitle = '최근 본 상품'

export const RecentlyProductListInBottomSheet = ({ data }: RecentlyProductListProps) => {
  const [isTracked, setIsTracked] = useState(false)
  const { ref, inView } = useInView()
  const { reduxProductInCartConfirmationBottomSheet } = useSelector(
    (state: RootState) => state.reduxDataReducers.cartConfirmationBottomSheetData
  )
  const { push, pathname, reload } = useCustomRouter()
  const { color } = useTheme()
  const { resetCartBottomSheetReduxData } = useCartBottomSheetRestReduxData()

  const {
    trackViewAddCartBottomUpProductEvent,
    trackClickAddCartBottomUpSectionMoreEvent,
    trackClickAddCartBottomUpProductEvent,
  } = useBottomSheetEventTracker()
  const { getAbByKey } = useAbGroup()
  const { abCode } = getAbByKey({
    abKey: ABTestGroupCase.BOTTOM_SHEET_RECOMMENDATION_SLOT_IMPROVEMENT_ON_ADD_TO_CART,
  })
  const isNewCartBottomSheet = abCode !== 'A'

  const handleMoreClick = () => {
    if (reduxProductInCartConfirmationBottomSheet) {
      trackClickAddCartBottomUpSectionMoreEvent({
        sectionName: RecentlyProductListTitle,
        listType: 'recentproduct',
        originProductId: reduxProductInCartConfirmationBottomSheet?.id,
        originProductName: reduxProductInCartConfirmationBottomSheet?.name,
        supplyType: reduxProductInCartConfirmationBottomSheet?.supplyType,
        brandId: reduxProductInCartConfirmationBottomSheet?.brand.id,
        brandName: reduxProductInCartConfirmationBottomSheet?.brand.name,
        addLocation: 'bottomsheet',
        location: getLocationByPathname(pathname),
      })
    }
    resetCartBottomSheetReduxData()

    if (pathname.startsWith(ROUTES.MYPAGE.FAVORITE_LIST.VIEW)) {
      reload()
    } else {
      push(ROUTES.MYPAGE.FAVORITE_LIST.VIEW)
    }
  }

  const handleClickProductCardNew = ({
    sectionName,
    productData,
    index,
  }: {
    sectionName: string
    productData: ProductTypeDefinedByFrontend
    index: number
  }) => {
    if (reduxProductInCartConfirmationBottomSheet) {
      trackClickAddCartBottomUpProductEvent({
        originProductId: reduxProductInCartConfirmationBottomSheet?.id,
        originProductName: reduxProductInCartConfirmationBottomSheet?.name,
        productId: productData.id,
        productName: productData.name,
        supplyType: productData.supplyType,
        brandId: productData.brand.id,
        brandName: productData.brand.name,
        sectionName,
        listIndex: index,
        listType: 'recentproduct',
      })
    }
  }
  useEffect(() => {
    if (data.length > 0 && reduxProductInCartConfirmationBottomSheet && inView && !isTracked) {
      setIsTracked(true)
      trackViewAddCartBottomUpProductEvent({
        sectionName: RecentlyProductListTitle,
        listType: 'recentproduct',
        originProductId: reduxProductInCartConfirmationBottomSheet?.id,
        originProductName: reduxProductInCartConfirmationBottomSheet?.name,
        supplyType: reduxProductInCartConfirmationBottomSheet?.supplyType,
        brandId: reduxProductInCartConfirmationBottomSheet?.brand.id,
        brandName: reduxProductInCartConfirmationBottomSheet?.brand.name,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, inView])

  if (!data.length) {
    return null
  }

  return (
    <RecentlyProductListWrapper className="recently-product-list" ref={ref}>
      <div className="recently-product-list__header">
        <Typo variant={TypoVariant.Body1Bold} color={color.gray900}>
          {RecentlyProductListTitle}
        </Typo>
        <button type="button" className="recently-product-list__link" onClick={handleMoreClick}>
          <Typo variant={TypoVariant.Body3Regular} color={color.gray500}>
            더보기
          </Typo>
          <FlatIcon type={IconNameEnum.IcArrowRight} color={color.gray500} size={IconSizeEnum.Size16} />
        </button>
      </div>
      <StyledContainer>
        <ScrollContainer hideRightButton={data.length < 3} buttonPositionY={30}>
          <StyledListContainer isNewCartBottomSheet={isNewCartBottomSheet}>
            {data.map((product, index) => {
              const productData = transformProductTypeDefinedByFrontend(product)
              return (
                <StyledProductCardContainer key={product.id}>
                  <NewProductCard
                    product={productData}
                    size={PRODUCT_CARD_SIZES.lg}
                    thumbnailState={THUMBNAIL_STATES.basicLabel}
                    rankingNumber={index}
                    hasCartButton
                    trackerData={{
                      addLocation: ProductLocation.BottomSheet,
                      sectionName: RecentlyProductListTitle,
                      listIndex: index,
                      listType: 'recentproduct',
                      originProductId: reduxProductInCartConfirmationBottomSheet?.id,
                      originProductName: reduxProductInCartConfirmationBottomSheet?.name,
                    }}
                    onClickProductCard={() =>
                      handleClickProductCardNew({ sectionName: RecentlyProductListTitle, productData, index })
                    }
                  />
                </StyledProductCardContainer>
              )
            })}
          </StyledListContainer>
        </ScrollContainer>
      </StyledContainer>
    </RecentlyProductListWrapper>
  )
}

const RecentlyProductListWrapper = styled.div`
  .recently-product-list {
    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1.6rem;
    }
    &__link {
      border: none;
      padding: 0;
      background-color: transparent;
      outline: none;
      cursor: pointer;
      display: flex;
      align-items: center;
    }
  }
`

const StyledContainer = styled.div`
  overflow: hidden;
  position: relative;
  background-color: ${({ theme }) => theme.color.grayWhite};
`

const StyledListContainer = styled.div<{ isNewCartBottomSheet: boolean }>`
  ${removeScrollbar};
  display: flex;
  gap: 0.8rem;
  margin-bottom: ${({ isNewCartBottomSheet }) => (isNewCartBottomSheet ? '' : '2.4rem')};

  & > *:first-child {
    margin-left: 16px;
  }

  & > *:last-child {
    padding-right: 16px;
  }
`

const StyledProductCardContainer = styled.div`
  position: relative;
`
