type Base64Key =
  | 'CouponType'
  | 'ProductOptionType'
  | 'ProductPromotionType'
  | 'CustomerTemplateAddressType'
  | 'ProductType'
  | 'DisplayCollectionType'
  | 'BrandType'
  | 'CategoryType'

export const Base64ConverterUtil = {
  convertNumberIdToBase64ByKey: ({ key, id }: { key: Base64Key; id?: number }) => {
    if (id === undefined) {
      return ''
    }
    if (isNaN(id)) {
      return ''
    }

    return Buffer.from(`${key}:${id}`).toString('base64')
  },
  convertBase64ToNumberId: (base64String: string) => {
    const converted = Buffer.from(decodeURIComponent(base64String), 'base64').toString('utf-8')
    try {
      return Number(converted.split(':')[1])
    } catch (error) {
      return NaN
    }
  },
}
