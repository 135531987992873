import { DocumentNode } from 'graphql'
import ADDRESS_MUTATION_GQLS from '@/containers/gqls/accounts/address/mutation'
import ADDRESS_QUERY_GQLS from '@/containers/gqls/accounts/address/queries'
import MILEAGE_QUERY_GQLS from '@/containers/gqls/accounts/mileage/queries'
import USER_MUTATION_GQLS from '@/containers/gqls/accounts/user/mutations'
import USER_QUERY_GQLS from '@/containers/gqls/accounts/user/queries'
import BANNER_QUERY_GQLS from '@/containers/gqls/banners/queries'
import DEFAULT_MUTATION_GQLS from '@/containers/gqls/base/defaultMutations'
import { BRAND_QUERY_GQLS } from '@/containers/gqls/brands/queries'
import { COMMON_QUERY_GQLS } from '@/containers/gqls/commons/queries'
import DISPLAY_COLLECTION_QUERY_GQLS from '@/containers/gqls/display_collection/queries'
import ORDER_MUTATION_GQLS from '@/containers/gqls/orders/mutations'
import ORDER_QUERY_GQLS from '@/containers/gqls/orders/queries'
import POLICY_QUERY_GQLS from '@/containers/gqls/policy/queries'
import CATEGORY_QUERY_GQLS from '@/containers/gqls/products/categories/queries'
import PRODUCT_MUTATION_GQLS from '@/containers/gqls/products/mutation'
import { PRODUCT_QUERY_GQLS } from '@/containers/gqls/products/queries'
import { AD_QUERY_GQLS } from '@/containers/gqls/promotions/ads/queries'
import { COUPON_MUTATION_GQLS } from '@/containers/gqls/promotions/coupons/mutations'
import COUPON_QUERY_GQLS from '@/containers/gqls/promotions/coupons/queries'
import REVIEW_MUTATION_GQLS from '@/containers/gqls/reviews/mutations'
import REVIEW_QUERY_GQLS from '@/containers/gqls/reviews/queries'
import { SEARCH_QUERY_GQLS } from '@/containers/gqls/search/queries'
import TRANSACTION_MUTATION_GQLS from '@/containers/gqls/transactions/mutations'

export const QUERY_KEY2GQLS: { [index: string]: DocumentNode } = {
  // accounts/address
  customerAddress: ADDRESS_QUERY_GQLS.CUSTOMER_ADDRESS,
  customerAddresses: ADDRESS_QUERY_GQLS.CUSTOMER_ADDRESSES,

  // accounts/user
  user: USER_QUERY_GQLS.USER,
  userWithPassword: USER_QUERY_GQLS.USER_WITH_PASSWORD,
  leaveReasons: USER_QUERY_GQLS.LEAVE_REASONS,
  userOrderBenefits: USER_QUERY_GQLS.USER_ORDER_BENEFITS,
  userMyShoppingInfo: USER_QUERY_GQLS.USER_MY_SHOPPING_INFO,
  userMileageInfo: USER_QUERY_GQLS.USER_MILEAGE_INFO,
  userBankAccountInfo: USER_QUERY_GQLS.USER_BANK_ACCOUNT_INFO,
  reviewPromotionInfo: USER_QUERY_GQLS.REVIEW_PROMOTION_INFO,

  // accounts/mileage
  mileageList: MILEAGE_QUERY_GQLS.MILEAGE_LIST,

  // coupons
  userCoupons: COUPON_QUERY_GQLS.USER_COUPONS,
  couponGroupIncludes: COUPON_QUERY_GQLS.COUPON_GROUP_INCLUDES,

  // brands
  brandName: BRAND_QUERY_GQLS.BRAND_NAME,

  // displayCollection
  displayCollectionProductInfos: DISPLAY_COLLECTION_QUERY_GQLS.DISPLAY_COLLECTION_PRODUCT_INFOS,
  cachedDisplayCollectionProductInfos: DISPLAY_COLLECTION_QUERY_GQLS.CACHED_DISPLAY_COLLECTION_PRODUCT_INFOS,
  displayCollectionsBest: DISPLAY_COLLECTION_QUERY_GQLS.DISPLAY_COLLECTIONS_BEST,

  // orders
  orders: ORDER_QUERY_GQLS.ORDERS,
  ordersHistory: ORDER_QUERY_GQLS.ORDERS_HISTORY,
  order: ORDER_QUERY_GQLS.ORDER,
  orderFinish: ORDER_QUERY_GQLS.ORDER_FINISH,
  orderMinimumOrderItems: ORDER_QUERY_GQLS.ORDER_MINIMUM_ORDER_ITEMS,
  orderItemCancelReasons: ORDER_QUERY_GQLS.ORDER_ITEM_CANCEL_REASONS,
  orderItemReturnReasons: ORDER_QUERY_GQLS.ORDER_ITEM_RETURN_REASONS,
  orderItem: ORDER_QUERY_GQLS.ORDER_ITEM,
  transactionForTrackingFailure: ORDER_QUERY_GQLS.TRANSACTION_FOR_TRACKING_FAILURE,

  // products/categories
  rootCategories: CATEGORY_QUERY_GQLS.ROOT_CATEGORIES,

  // products
  searchProductNameList: PRODUCT_QUERY_GQLS.SEARCH_PRODUCT_NAME_LIST,
  searchProducts: PRODUCT_QUERY_GQLS.SEARCH_PRODUCTS,
  searchedKeywords: PRODUCT_QUERY_GQLS.SEARCHED_KEYWORDS,
  productList: PRODUCT_QUERY_GQLS.PRODUCT_LIST,
  productDetail: PRODUCT_QUERY_GQLS.PRODUCT_DETAIL,
  productProductOptions: PRODUCT_QUERY_GQLS.PRODUCT_PRODUCT_OPTIONS,
  productListInCart: PRODUCT_QUERY_GQLS.PRODUCT_LIST_IN_CART,
  cartProductList: PRODUCT_QUERY_GQLS.CART_PRODUCT_LIST,
  cartProductListCount: PRODUCT_QUERY_GQLS.CART_PRODUCT_LIST_COUNT,
  productInfoForInquiry: PRODUCT_QUERY_GQLS.PRODUCT_INFO_FOR_INQUIRY,
  productQnas: PRODUCT_QUERY_GQLS.PRODUCT_QNA_LIST,
  productReturnExchangeGuide: PRODUCT_QUERY_GQLS.PRODUCT_RETURN_EXCHANGE_GUIDE,
  recommendedProducts: PRODUCT_QUERY_GQLS.RECOMMENDED_PRODUCTS,
  recommendedCartProducts: PRODUCT_QUERY_GQLS.RECOMMENDED_CART_PRODUCTS,
  recommendedProductDetailProducts: PRODUCT_QUERY_GQLS.RECOMMENDED_PRODUCT_DETAIL_PRODUCTS,
  recommendedSimilarProducts: PRODUCT_QUERY_GQLS.RECOMMENDED_SIMILAR_PRODUCTS,
  likedProducts: PRODUCT_QUERY_GQLS.LIKED_PRODUCTS,
  likedProductIds: PRODUCT_QUERY_GQLS.LIKED_PRODUCT_IDS,
  productsWithoutPetType: PRODUCT_QUERY_GQLS.RECENTLY_PRODUCTS,
  recommendedRelatedProducts: PRODUCT_QUERY_GQLS.RECOMMENDED_RELATED_PRODUCTS,
  recommendBundledProducts: PRODUCT_QUERY_GQLS.RECOMMEND_BUNDLED_PRODUCTS,

  // promotions/ads
  ads: AD_QUERY_GQLS.ADS,
  adHundredWonDeal: AD_QUERY_GQLS.AD_HUNDRED_WON_DEAL,

  // reviews
  reviewList: REVIEW_QUERY_GQLS.REVIEW_LIST,
  reviewWritableOrderItems: REVIEW_QUERY_GQLS.REVIEW_WRITABLE_ORDER_ITEMS,
  reviewWritableOrderItemsIds: REVIEW_QUERY_GQLS.REVIEW_WRITABLE_ORDER_ITEMS_IDS,
  reviewDetail: REVIEW_QUERY_GQLS.REVIEW_DETAIL,

  // commons
  banks: COMMON_QUERY_GQLS.BANKS,
  initData: COMMON_QUERY_GQLS.INIT_DATA,
  // policy
  sellers: POLICY_QUERY_GQLS.SELLERS,

  // banners
  banners: BANNER_QUERY_GQLS.BANNERS,

  // search
  recommendedKeywords: SEARCH_QUERY_GQLS.RECOMMENDED_KEYWORDS,
}

export const MUTATION_KEY2GQLS: { [index: string]: DocumentNode } = {
  // accounts/user
  emailSignup: USER_MUTATION_GQLS.EMAIL_SIGNUP,
  socialSignup: USER_MUTATION_GQLS.SOCIAL_SIGNUP,
  socialSignin: USER_MUTATION_GQLS.SOCIAL_SIGNIN,
  updateUserByCustomer: USER_MUTATION_GQLS.UPDATE_USER_BY_CUSTOMER,
  linkAccountBySocial: USER_MUTATION_GQLS.LINK_ACCOUNT_BY_SOCIAL,
  linkAccountByEmail: USER_MUTATION_GQLS.LINK_ACCOUNT_BY_EMAIL,
  updateRefundAccount: USER_MUTATION_GQLS.UPDATE_REFUND_ACCOUNT,
  leaveUser: USER_MUTATION_GQLS.LEAVE_USER,
  updateDisplayPetType: USER_MUTATION_GQLS.UPDATE_DISPLAY_PET_TYPE,
  checkEmailDuplicated: DEFAULT_MUTATION_GQLS.CHECK_EMAIL_DUPLICATED,
  UpdateAgreeTerms: USER_MUTATION_GQLS.UPDATE_AGREE_TERMS,

  // accounts/address
  createCustomerTemplateAddress: ADDRESS_MUTATION_GQLS.CREATE_CUSTOMER_ADDRESS,
  updateCustomerTemplateAddress: ADDRESS_MUTATION_GQLS.UPDATE_CUSTOMER_ADDRESS,
  deleteCustomerTemplateAddress: ADDRESS_MUTATION_GQLS.DELETE_CUSTOMER_ADDRESS,

  // coupons
  downloadEventCoupons: COUPON_MUTATION_GQLS.DOWNLOAD_EVENT_COUPONS,
  registerCoupon: COUPON_MUTATION_GQLS.REGISTER_COUPON,

  // orders
  createOrder: ORDER_MUTATION_GQLS.CREATE_ORDER,
  estimateOrder: ORDER_MUTATION_GQLS.ESTIMATE_ORDER,
  estimateOrderForCart: ORDER_MUTATION_GQLS.ESTIMATE_ORDER_FOR_CART,
  estimateOrderCalculationOnly: ORDER_MUTATION_GQLS.ESTIMATE_ORDER_CALCULATION_ONLY,
  cancelOrderItems: ORDER_MUTATION_GQLS.CANCEL_ORDER_ITEMS,
  returnOrderItems: ORDER_MUTATION_GQLS.REQUEST_RETURN_ORDER_ITEMS,
  exchangeOrderItems: ORDER_MUTATION_GQLS.REQUEST_EXCHANGE_ORDER_ITEMS,
  confirmOrderItem: ORDER_MUTATION_GQLS.CONFIRM_ORDER_ITEM,

  // transactions
  createTransaction: TRANSACTION_MUTATION_GQLS.CREATE_TRANSACTION,
  approveTransaction: TRANSACTION_MUTATION_GQLS.APPROVE_TRANSACTION,

  //products
  deleteCartItems: PRODUCT_MUTATION_GQLS.DELETE_CART_ITEMS,
  createCartItems: PRODUCT_MUTATION_GQLS.CREATE_CART_ITEMS,
  updateCartItem: PRODUCT_MUTATION_GQLS.UPDATE_CART_ITEM,
  refreshCartItem: DEFAULT_MUTATION_GQLS.REFRESH_CART_ITEMS,
  askProductQuestion: PRODUCT_MUTATION_GQLS.ASK_PRODUCT_QUESTION,
  toggleProductLiked: PRODUCT_MUTATION_GQLS.TOGGLE_PRODUCT_LIKED,
  toggleRestockProductOption: PRODUCT_MUTATION_GQLS.TOGGLE_RESTOCK_PRODUCT_OPTION,

  //reviews
  likeReview: REVIEW_MUTATION_GQLS.LIKE_REVIEW,
  createReview: REVIEW_MUTATION_GQLS.CRAETE_REVIEW,
  updateReview: REVIEW_MUTATION_GQLS.UPDATE_REVIEW,
}
