export enum ProductThumbnailSizeEnum {
  Card76 = '7.6rem',
  Card86 = '8.6rem',
  Card109 = '10.9rem',
  Card136 = '13.6rem',
  Card167AndHalf = '16.75rem',
  Card200 = '20rem', // 홈화면의 BestReview 에서만 사용하는 커스텀 사이즈
  Card375 = '3.75rem',
  Fill = '100%',
}
