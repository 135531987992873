import styled from 'styled-components'
import { TextBaseProps } from '@/components/common/texts/TextBase'
import { GlobalColorEnum } from '@/constants/global-color.enum'
import { SCREEN_MEDIA_QUERY } from '@/constants/legacy/constLayout'
import { textEllipsis } from '@/utils/utilCSS'

const TextH2 = styled.h2<TextBaseProps>`
  ${({ lineNumber = 2 }) => textEllipsis(lineNumber)};
  opacity: ${({ opacity }) => opacity ?? 1};
  overflow: ${({ ellipsis }) => (ellipsis ? 'hidden' : 'inherit')};
  margin: 0;
  border-bottom: ${({ borderBottom }) => `${borderBottom}` ?? 'none'};
  text-overflow: ellipsis;
  word-break: break-all;
  line-height: ${({ lineHeight }) => lineHeight ?? '1.4'};
  white-space: pre-line !important;
  font-size: ${({ size }) => {
    if (!size) return '16px'
    return typeof size === 'number' ? `${size}px` : `${size.md}px`
  }};
  color: ${({ color }) => {
    if (color === 'black') return GlobalColorEnum.AppleBlack
    if (color === 'white') return GlobalColorEnum.GrayWhite
    if (color === 'green') return GlobalColorEnum.AheadMint
    if (color === '100-deal-inactive') return '#979595'
    if (color === '100-deal-active') return '#282828'
    if (color === 'gray200') return GlobalColorEnum.Gray200
    if (color === 'gray400') return GlobalColorEnum.Gray400
    if (color === 'gray500') return GlobalColorEnum.Gray500
    if (color === 'gray600') return GlobalColorEnum.Gray600
    if (color === 'gray700') return GlobalColorEnum.Gray700
    if (color === 'blue500') return GlobalColorEnum.Blue500
    if (color === 'red500') return GlobalColorEnum.Red500
    if (color) return color
    return GlobalColorEnum.Gray900
  }};
  font-weight: ${({ weight }) => {
    if (!weight) return 400
    if (typeof weight === 'number') return weight
    const _weight = typeof weight === 'string' ? weight : weight.md
    if (_weight === 'thin') return 300
    if (_weight === 'medium') return 500
    if (_weight === 'semibold') return 600
    if (_weight === 'bold') return 700
    return 400
  }};
  text-align: ${({ align }) => align || 'left'};
  text-decoration-line: ${({ lineThrough, underline }) => {
    if (lineThrough) return 'line-through'
    if (underline) return 'underline'
    return 'none'
  }};
  white-space: ${({ ellipsis }) => (ellipsis ? 'nowrap' : 'inherit')};
  ${({ customStyle }) => customStyle};

  @media ${SCREEN_MEDIA_QUERY.xs} {
    font-size: ${({ size }) => {
      if (!size) return '14px'
      return typeof size === 'number' ? `${size}px` : `${size.xs}px`
    }};
  }
`

export default TextH2
