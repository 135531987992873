import qs from 'qs'
import { HEALTH_HOST } from '@/constants/legacy/constEnv'
import { isInApp } from '@/utils/utilCommon'
import { localUserToken } from '@/utils/utilLocalStorage'

export type HealthUrlParam = {
  register_location?: string
  petId?: number
  token?: string
}

// TODO useHealth hook 을 사용하는지 확인하여 완전 삭제 가능한지 판단 필요 - 2023-09-06 by joker
const useHealth = () => {
  const getToken = () => {
    const userToken = localUserToken.load()
    return userToken !== undefined || userToken ? userToken.token : ''
  }

  const getParam = (param: HealthUrlParam) => {
    Object.keys(param).forEach((k) => {
      // @ts-ignore
      if (['', -1].includes(param[k])) {
        // @ts-ignore
        delete param[k]
      }
    })
    return qs.stringify(param)
  }

  const getUrl = (baseUrl: string, param: HealthUrlParam) => {
    return `${baseUrl}?${getParam(param)}`
  }

  const isHealthHost = (url: string) => {
    if (!HEALTH_HOST) {
      return false
    }
    return url.startsWith(HEALTH_HOST)
  }

  // 펫 리스트 페이지 이동
  const markHealthPetListUrl = (register_location: string = '', token: string = '') => {
    if (isInApp()) {
      return undefined;
    }

    return getUrl(`${HEALTH_HOST}/pet/list`, {
      register_location,
      token: token !== '' ? token : getToken(),
    })
  }
  // 펫 등록 페이지 이동
  const markHealthPetRegisterUrl = (register_location: string = '', token: string = '') => {
    if (isInApp()) {
      return undefined;
    }
    
    return getUrl(`${HEALTH_HOST}/pet/register`, {
      register_location,
      token: token !== '' ? token : getToken(),
    })
  }

  const replaceHealthUrl = (replaceUrl: string | undefined, token: string = '') => {
    if (!replaceUrl) {
      return
    }

    const url = replaceUrl.split('?')
    const _token = token !== '' ? token : getToken()
    let _param = { token: _token }
    if (url.length > 1) {
      _param = { ...qs.parse(url[1]), ..._param }
    }
    const path = `${url[0]}?${qs.stringify(_param)}`
    location.replace(path)
  }

  /**
   * TODO: 플레이SQ 사진 콘테스트 종료 후 삭제
   * @link {https://app.asana.com/0/1202516197912187/1203638044435752/f}
   */
  const isPlayEventContest = (url: string | undefined) => {
    return url && isHealthHost(url) && new RegExp('/play/event/contest').test(url)
  }

  return {
    isHealthHost,
    markHealthPetListUrl,
    markHealthPetRegisterUrl,
    replaceHealthUrl,
    isPlayEventContest,
  }
}

export default useHealth
