import { S3_HOST_URL } from '@/constants/legacy/constEnv'
import { IModelBrandDefault } from '@/containers/models/base/defaultModelInterfaces'
import { ModelBrandDefault } from '@/containers/models/base/defaultModels'

export interface IModelBrand extends IModelBrandDefault {
  getListImage: (absoluteUrl: boolean) => string
}

export default class ModelBrand extends ModelBrandDefault {
  getListImage = (absoluteUrl = false) => {
    if (absoluteUrl) {
      return `${S3_HOST_URL}/${this.listImage}`
    }

    return this.listImage
  }
}
