import styled, { useTheme } from 'styled-components'
import { LinkButton } from '@/components/common/buttons'
import FlatIcon from '@/components/common/icons/FlatIcon'
import { Typo, TypoVariant } from '@/components/common/typography'
import { CartItemCountCircle } from '@/components/domains/etc'
import { FitpetMallEventEnum } from '@/constants/fitpet-mall-event.enum'
import { IconNameEnum } from '@/constants/icon-name.enum'
import { Z_INDEXES } from '@/constants/legacy/constLayout'
import ROUTES from '@/constants/legacy/constRoutes'
import { QueryStringKeyEnum } from '@/constants/query-string-key.enum'
import { useTracker } from '@/containers/contexts/EventTrackingProvider'
import { useCustomRouter, usePetType } from '@/containers/hooks'
import { Bridge } from '@/utils/bridge/bridge'
import appBridgeProvider, { isInAppFlag } from '@/utils/utilBridge'
import { textEllipsis } from '@/utils/utilCSS'

/**
 * TODO: 피그마와 같이 컴포넌트 정리 필요
 * @link https://www.figma.com/file/5VDCLszeSek9nP9MivS7X1/FitPet-Design-System?node-id=5575%3A15958&t=EsjIjI9ECxxC7Hgs-4
 */

type TopNavigationProps = {
  title: string
  isAlignTitleCenter?: boolean
  isShowHomeIcon?: boolean
  isShowSearchIcon?: boolean
  isShowCartIcon?: boolean
  isShowShareIcon?: boolean
  onShareIconClick?: () => void
}

const TopNavigation = ({
  title,
  isAlignTitleCenter = false,
  isShowHomeIcon = true,
  isShowSearchIcon = true,
  isShowCartIcon = true,
  isShowShareIcon = false,
  onShareIconClick,
}: TopNavigationProps) => {
  const { iconSize } = useTheme()
  const { triggerCustomEvent } = useTracker()
  const { getPetType } = usePetType()
  const { query } = useCustomRouter()

  const handleShareLinkClick = () => {
    if (onShareIconClick) {
      onShareIconClick()
    }
  }

  return (
    <StyledContainer>
      <StyledHeaderContent>
        <LinkButton goBack>
          <FlatIcon type={IconNameEnum.IcBack} size={iconSize.size24} />
        </LinkButton>
        <StyledHeaderTitle isAlignTitleCenter={isAlignTitleCenter}>
          <Typo as={'h1'} variant={TypoVariant.Heading6Bold}>
            {title}
          </Typo>
        </StyledHeaderTitle>
        {isShowHomeIcon && (
          <LinkButton
            url={isInAppFlag ? undefined : ROUTES.INDEX}
            onClick={() => {
              triggerCustomEvent(FitpetMallEventEnum.ClickGnbHomeIcon)
              if (isInAppFlag) appBridgeProvider((bridge: Bridge) => bridge.goDeepLink('fitpetmall', '/main'))
            }}
          >
            <FlatIcon type={IconNameEnum.IcHome} size={iconSize.size24} />
          </LinkButton>
        )}
        {isShowSearchIcon && (
          <LinkButton
            rel="nosublink"
            url={{
              pathname: ROUTES.SEARCH,
              query: {
                [QueryStringKeyEnum.SearchPetType]: query?.[QueryStringKeyEnum.SearchPetType] || getPetType(),
              },
            }}
            onClick={() => {
              triggerCustomEvent(FitpetMallEventEnum.ClickGnbSearchIcon)
            }}
          >
            <FlatIcon type={IconNameEnum.IcSearch} size={iconSize.size24} />
          </LinkButton>
        )}
        {isShowCartIcon && (
          <LinkButton
            rel="nosublink"
            url={ROUTES.CART}
            onClick={() => {
              triggerCustomEvent(FitpetMallEventEnum.ClickGnbCartIcon)
            }}
            style={{ position: 'relative' }}
          >
            <CartItemCountCircle />
            <FlatIcon type={IconNameEnum.IcCart} size={iconSize.size24} />
          </LinkButton>
        )}
        {isShowShareIcon && (
          <LinkButton onClick={handleShareLinkClick}>
            <FlatIcon type={IconNameEnum.IcShare} size={iconSize.size24} />
          </LinkButton>
        )}
      </StyledHeaderContent>
    </StyledContainer>
  )
}

const StyledContainer = styled.header`
  z-index: ${Z_INDEXES.HEADER};
  position: sticky;
  top: 0;
  box-sizing: border-box;
  width: 100%;
  max-width: 576px;
  background-color: ${({ theme: { color } }) => color.grayWhite};
  transition: transform 0.2s;
  -webkit-transition: transform 0.2s;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 0.1rem;
    background-color: ${(props) => props.theme.color.gray50};
  }
`

const StyledHeaderContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.6rem;
  column-gap: 1.6rem;
`

const StyledHeaderTitle = styled.div<{ isAlignTitleCenter: boolean }>`
  ${textEllipsis(1)};
  flex-grow: 1;
  text-align: ${({ isAlignTitleCenter }) => (isAlignTitleCenter ? 'center' : 'left')};
`

export default TopNavigation
