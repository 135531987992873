import * as Sentry from '@sentry/nextjs'
import { AxiosError } from 'axios'
import { useDispatch } from 'react-redux'
import { usePurchaseEstimateProduct } from '@/apis/rest/generated/hooks'
import { PurchaseEstimateProductRequestDtoProductsItem } from '@/apis/rest/generated/types'
import { ProductOption } from '@/components/common/modals/bottomSheet.type'
import { doSetGlobalToastContent } from '@/stores/reduxUI'
import { ApiErrorUtils } from '@/utils/utilApiErrors'

export const usePurchaseEstimate = () => {
  const { mutateAsync, isLoading: isGetPurchaseEstimateMutateLoading } = usePurchaseEstimateProduct()
  const dispatch = useDispatch()

  const getPurchaseEstimateMutate = async ({
    productId,
    productPromotionId,
    products = [],
  }: {
    productId: number
    productPromotionId?: number
    products?: PurchaseEstimateProductRequestDtoProductsItem[]
  }) => {
    try {
      const data = await mutateAsync({
        id: productId,
        data: {
          products,
          ...(productPromotionId && { productPromotionId: productPromotionId }),
        },
      })
      const result: ProductOption[] = data.data.map((item) => {
        const existed = products.find((product) => product.productOptionId === item.productOptionId)
        if (existed) {
          return {
            ...item,
            quantity: existed.quantity,
            isSelected: true,
            productPromotionId: productPromotionId || undefined,
          }
        }
        return {
          ...item,
          quantity: data.isSingleOption ? 1 : 0,
          isSelected: data.isSingleOption,
          productPromotionId: productPromotionId,
        }
      })
      return {
        data: result,
        productSummary: {
          totalProductPrice: data.totalProductPrice,
          totalProductPriceAfterCoupon: data.totalProductPriceAfterCoupon,
          totalExpectedMileage: data.totalExpectedMileage,
          hasApplicableProductCoupon: data.hasApplicableProductCoupon,
          hasUnreceivedProductCoupon: data.hasUnreceivedProductCoupon,
          isSingleOption: data.isSingleOption,
        },
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        const responseData = error.response?.data.errors
        const errorInfo = ApiErrorUtils.getError(error)

        Sentry.captureException(`상품 구매 예상 금액 계산 서버 에러`, {
          extra: {
            productId,
            productPromotionId,
            products,
            responseData,
            error,
            errorInfo,
          },
          tags: { errorMessage: errorInfo.message },
        })

        dispatch(
          doSetGlobalToastContent({
            content: `${errorInfo.message}\n오류가 지속된다면 고객센터에 문의해 주세요`,
            duration: 5,
          })
        )
      } else {
        const errorInfo = ApiErrorUtils.getError(error)
        Sentry.captureException(`상품 구매 예상 금액 계산 네트워크 에러`, {
          extra: { productId, productPromotionId, products, error, errorInfo },
          tags: { errorMessage: errorInfo.message },
        })

        dispatch(
          doSetGlobalToastContent({
            content: `${errorInfo.message}\n오류가 지속된다면 고객센터에 문의해 주세요`,
            duration: 5,
          })
        )
      }
    }
  }

  return { getPurchaseEstimateMutate, isGetPurchaseEstimateMutateLoading }
}
