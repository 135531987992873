import { useApolloClient } from '@apollo/client'
import Router from 'next/router'
import { useDispatch } from 'react-redux'
import ROUTES from '@/constants/legacy/constRoutes'
import { IModelFakeWhoami } from '@/constants/legacy/constType'
import { QueryStringKeyEnum } from '@/constants/query-string-key.enum'
import { useCartNew, useCustomRouter } from '@/containers/hooks'
import { useSignOutMutation } from '@/graphql/generated/hooks'
import { EmailSigninMutation, TokenSigninMutation } from '@/graphql/generated/schemas'
import { doInitAllData } from '@/stores/reduxData'
import { doInitAllUI, doSetGlobalToastContent } from '@/stores/reduxUI'
import { store } from '@/stores/store'
import { Bridge } from '@/utils/bridge/bridge'
import { deleteFitpetCookie, setFitpetCookie } from '@/utils/cookie.util'
import appBridgeProvider, { appBridgeLogin, isInAppFlag } from '@/utils/utilBridge'
import { localDeviceUuid, localRemoveAll, localUserToken } from '@/utils/utilLocalStorage'
import { sessionRemoveAll } from '@/utils/utilSessionStorage'

const loadToken = () => {
  if (isInAppFlag) {
    const apitoken = window?.getToken()?.apiToken || { token: '' }
    return apitoken.token === '' ? null : apitoken
  } else {
    return localUserToken.load()
  }
}
const cleanUserInfo = async () => {
  deleteFitpetCookie()
  await localRemoveAll()
  await sessionRemoveAll()
  store.dispatch(doInitAllData())
  store.dispatch(doInitAllUI())
}

export const hasSignedIn = () => {
  if (isInAppFlag) {
    return !(window.getToken().apiToken.token === '')
  } else {
    const token = localUserToken.load()
    return !!token
  }
}

const useSaveUserInfo = () => {
  const { refreshCartProductOptionsCount, mergeLocalCartToServerCartIfAvailable } = useCartNew()

  // @desc localStorage, cookie에 token 저장, Redux Store에 fakeWhoami에 저장
  const saveTokenNWhoami = async (whoami: IModelFakeWhoami | EmailSigninMutation | TokenSigninMutation) => {
    const { device } = whoami
    const { token = '', refreshToken = '', expiredAt = '' } = device || {}
    if (!isInAppFlag) {
      localUserToken.save({ token, refreshToken, expiredAt: expiredAt.toString() })
    }
    setFitpetCookie({ token, expires: new Date(expiredAt.toString()) })

    await mergeLocalCartToServerCartIfAvailable()
    await refreshCartProductOptionsCount()
  }

  const saveToken = ({
    token,
    refreshToken,
    expiredAt,
  }: {
    token: string
    refreshToken: string
    expiredAt: string
  }) => {
    localUserToken.save({ token, refreshToken, expiredAt })
    setFitpetCookie({ token, expires: new Date(expiredAt.toString()) })
  }

  return {
    saveTokenNWhoami,
    saveToken,
  }
}

const useLogout = () => {
  const dispatch = useDispatch()
  const apolloClient = useApolloClient()
  const router = useCustomRouter()

  const [signoutMutation] = useSignOutMutation()

  const logout = async (redirectToHome: boolean) => {
    try {
      // 인앱 환경이 아닐 때만 서버측 로그아웃 실행
      if (!isInAppFlag) {
        const token = loadToken()
        const uuid = localDeviceUuid.load()

        await signoutMutation({
          variables: {
            input: {
              token: token?.token || '', // token이 없을 경우 빈 문자열 전달
              uuid: uuid || '',
            },
          },
        })
      } else {
        appBridgeProvider((bridge: Bridge) => bridge.logout())
      }

      // 사용자 정보 클리어
      await cleanUserInfo()

      // Apollo 클라이언트의 캐시 클리어
      await apolloClient.clearStore()

      // 홈으로 리다이렉트
      if (redirectToHome) {
        await router.replace({ pathname: ROUTES.INDEX })
      }
    } catch (error) {
      dispatch(
        doSetGlobalToastContent({
          content: '네트워크 장애가 발생했어요\n' + '오류가 지속된다면 고객센터에 문의해 주세요',
        })
      )
    }
  }

  return {
    logout,
  }
}

const useAuthenticatedRoute = (nextPathname?: string | { pathname: string; query?: object }) => {
  const router = useCustomRouter()
  const bridgeLogin = appBridgeLogin()

  const checkLogin = (shouldLogin: boolean): boolean => {
    if (!shouldLogin) return true
    if (hasSignedIn()) return true

    let returnUrl: string
    if (typeof nextPathname === 'string') {
      returnUrl = nextPathname
    } else if (nextPathname?.query) {
      returnUrl = nextPathname.pathname
      const query = nextPathname.query
      const queryKeys = Object.keys(query)
      queryKeys.forEach((key) => {
        // @ts-ignore TODO typescript 오류 수정 필요
        if (query[key]) {
          // @ts-ignore TODO typescript 오류 수정 필요
          returnUrl = returnUrl.replace(`[${key}]`, query[key])
        }
      })
    } else {
      returnUrl = nextPathname?.pathname || ''
    }

    if (isInAppFlag) {
      appBridgeProvider((bridge: Bridge) =>
        bridge.login().then(() => {
          bridgeLogin(window.getToken())
        })
      )
    } else {
      router.push({
        pathname: ROUTES.ACCOUNTS.LOGIN,
        query: {
          [QueryStringKeyEnum.ReturnUrl]: returnUrl,
        },
      })
    }
    return false
  }

  const routeToAddInfo = (asPath?: string, afterReload: boolean = true, onforegroundCallback?: () => void) => {
    if (isInAppFlag) {
      appBridgeProvider((bridge: Bridge) => bridge.authentication())
      window.onforeground = async () => {
        if (window.getToken().userInfo.mobileNumber.length > 0) {
          await bridgeLogin(window.getToken())
          // eslint-disable-next-line no-unused-expressions
          onforegroundCallback && (await onforegroundCallback())
          // eslint-disable-next-line no-unused-expressions
          afterReload && window.location.reload()
        }
      }
    } else {
      window.onforeground = null
      router.push({
        pathname: ROUTES.ACCOUNTS.ADD_INFO,
        query: {
          [QueryStringKeyEnum.ReturnUrl]: asPath || Router.asPath,
        },
      })
    }
  }

  return {
    checkLogin,
    routeToAddInfo,
  }
}

const useUserInfo = {
  useSaveUserInfo,
  loadToken,
  cleanUserInfo,
  hasSignedIn,
  useAuthenticatedRoute,
  useLogout,
}

export default useUserInfo
