/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * Fitpet Mall
 * 핏펫몰 API 스펙

API 스펙 작성시 다음 가이드를 숙지하시기 바랍니다.

- [Open API 가이드 참고](https://swagger.io/docs/specification/about/)
- [Kotlin-Spring Open API Generator 설정 참고](https://openapi-generator.tech/docs/generators/kotlin-spring)
- REST API 디자인 가이드 참고
  - doc > guide > rest-api-design.md
- OpenAPI Spec 컨벤션 참고
  - doc > guid > openapi-spec-convention.md
- 설명(description) 작성시 마크다운([CommonMark](https://commonmark.org/help/)) 구문 참고

로컬에서 스웨거 에디터를 사용하는 방법은 다음과 같다.

```shell
docker pull swaggerapi/swagger-editor
docker run -d -p 8123:8080 swaggerapi/swagger-editor
open http://localhost:8123
```

Api 스펙을 하나의 파일로 합치려면 다음과 같이 실행한다.

```shell
# 설치
npm install -g swagger-cli

# 실행
swagger-cli bundle api-spec.yaml --outfile build/openapi.yaml --type yaml
```
 * OpenAPI spec version: 1.0.0
 */

/**
 * - `BANNER_TOP_SLIM`: 상단슬림배너
- `BANNER_SLIM`: 슬림배너
- `BANNER_TOP_ROLL`: 상단 롤 배너
- `BANNER_HOME_MIDDLE`: 홈 중단 띠 배너
- `BANNER_HOME_BOTTOM`: 홈 하단단 띠 배너
- `BANNER_PRODUCT_TOP`: 상품상세 페이지 띠 배너
- `BANNER_REVIEW`: 리뷰 배너
- `BANNER_HOME_MULTI_LINE`: 홈 멀티라인 배너
 */
export type HomeSectionBannerTypeEnum = typeof HomeSectionBannerTypeEnum[keyof typeof HomeSectionBannerTypeEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const HomeSectionBannerTypeEnum = {
  BANNER_TOP_SLIM: 'BANNER_TOP_SLIM',
  BANNER_SLIM: 'BANNER_SLIM',
  BANNER_TOP_ROLL: 'BANNER_TOP_ROLL',
  BANNER_HOME_MIDDLE: 'BANNER_HOME_MIDDLE',
  BANNER_HOME_BOTTOM: 'BANNER_HOME_BOTTOM',
  BANNER_PRODUCT_TOP: 'BANNER_PRODUCT_TOP',
  BANNER_REVIEW: 'BANNER_REVIEW',
  BANNER_HOME_MULTI_LINE: 'BANNER_HOME_MULTI_LINE',
} as const
