import { round } from '@/utils/utilCommon'

const floatToPercentageNumber = (x: number) => (x ? x * 100 : 0)
export const numberWithCommas = (x?: string | number) => (x ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0)
export const currencyText = (x: number) => (x ? `${numberWithCommas(round(x, 1))}원` : '0원')
const toDecimalNumber = (x: number, decimalPlace: number = 0) => (x ? Number(x.toFixed(decimalPlace)) : 0)

// 반올림 퍼센트
export const percentText = (x: number): string => (x ? `${numberWithCommas(round(x * 100))}%` : '0%')
export const percentTextWithoutPercent = (x: number): string => (x ? `${numberWithCommas(round(x * 100))}` : '0')

// 소수점 1자리 퍼센트, 소수점 이하가 0 이면 정수 return
export const percentDecimalText = (x: number) => {
  const _numberPercent = toDecimalNumber(floatToPercentageNumber(x), 0)
  const _decimalPercent = toDecimalNumber(floatToPercentageNumber(x), 1)
  const result = _numberPercent === _decimalPercent ? _numberPercent : _decimalPercent
  return `${numberWithCommas(result)}%`
}

export const tenThousandCurrencyText = (x: number) => {
  if (!x) return '-'
  if (x >= 10000) return `${x * 0.0001}만원`
  if (x >= 1000) return `${x * 0.001}천원`

  return currencyText(x)
}

export const getHalfProbability = () => {
  const zeroOrOne = Math.round(Math.random())
  return Boolean(zeroOrOne)
}

export const getRandomNumber = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1) + min)
}
