import { FC } from 'react'
import { useRouter } from 'next/router'
import styled from 'styled-components'
import { LinkButton } from '@/components/common/buttons'
import FlatIcon from '@/components/common/icons/FlatIcon'
import { CartItemCountCircle } from '@/components/domains/etc'
import HeaderPageTitle from '@/components/domains/layouts/header/HeaderPageTitle'
import { PetTypeSelectButton } from '@/components/domains/layouts/header/index'
import { GlobalColorEnum } from '@/constants/global-color.enum'
import { IconNameEnum } from '@/constants/icon-name.enum'
import { IconSizeEnum } from '@/constants/icon-size.enum'
import { FIXED_HEIGHT, SCREEN_MEDIA_QUERY } from '@/constants/legacy/constLayout'
import ROUTES from '@/constants/legacy/constRoutes'
import { QueryStringKeyEnum } from '@/constants/query-string-key.enum'
import { ServiceEnum } from '@/constants/service.enum'
import { useTracker } from '@/containers/contexts/EventTrackingProvider'
import { usePetType } from '@/containers/hooks'
import useLayoutHeader from '@/containers/hooks/layout-header.hook'
import useCustomRouter from '@/containers/hooks/useCustomRouter'
import { CloseButtonOldIdLogin } from '@/pages/accounts/need-convert'
import { Bridge } from '@/utils/bridge/bridge'
import { FitpetMallEventEnum } from '@/utils/event-tracker/events'
import appBridgeProvider, { isInAppFlag } from '@/utils/utilBridge'
import { isInApp } from '@/utils/utilCommon'

const AccountHeaderTypePages = [ROUTES.ACCOUNTS.LOGIN]

const Header: FC = () => {
  const { containerStyle, adSlugProperties, handleLinkShare, _isShareAvailable, shareLink } = useLayoutHeader()
  const {
    query,
    isMainTab,
    shouldHiddenHeader,
    isBlueTheme,
    shouldHideHeaderLeftSide,
    ableToShowHeaderShareDeepLinkButton,
    ableToShowHeaderShareButton,
    ableToShowHeaderHomeButton,
    ableToShowHeaderSearchButton,
    ableToShowHeaderCartButton,
    ableToCloseButtonOldIdLogin,
    ableToMypageSettingConfig,
    ableToMembershipConfig,
  } = useCustomRouter()
  const { getPetType } = usePetType()
  const tracker = useTracker()
  const { pathname } = useRouter()

  const isIconClose = AccountHeaderTypePages.includes(pathname)

  if (shouldHiddenHeader()) {
    return null
  }

  return (
    <StyledContainer style={containerStyle}>
      <StyledCol
        flex={isMainTab() ? '0' : '1'}
        style={isMainTab() ? { marginLeft: '1.6rem' } : { marginInline: '1.6rem' }}
      >
        {isMainTab() ? (
          <PetTypeSelectButton />
        ) : !shouldHideHeaderLeftSide() ? (
          <LinkButton goBack>
            <StyledRow justify="flex-start" align={'center'}>
              <FlatIcon
                type={isIconClose ? IconNameEnum.IcClose : IconNameEnum.IcBack}
                color={isBlueTheme() ? GlobalColorEnum.GrayWhite : undefined}
                size={IconSizeEnum.Size24}
              />
            </StyledRow>
          </LinkButton>
        ) : null}
      </StyledCol>
      <HeaderPageTitle />
      <StyledCol style={isMainTab() ? { marginRight: '1.6rem' } : { marginInline: '1.6rem' }}>
        <StyledRow align="center" justify="flex-end">
          {isMainTab() && (
            <StyledCol flex={'1'} style={{ width: '100%' }}>
              <LinkButton
                rel="nosublink"
                url={{ pathname: ROUTES.SEARCH, query: { [QueryStringKeyEnum.SearchPetType]: getPetType() } }}
                onClick={() => {
                  tracker.triggerCustomEvent(FitpetMallEventEnum.ClickGnbSearchIcon)
                }}
              >
                <SearchInput data-cy="search-input">
                  <span className={'search-placeholder'}>검색어를 입력해 주세요!</span>
                  <FlatIcon type={IconNameEnum.IcSearch} size={IconSizeEnum.Size24} />
                </SearchInput>
              </LinkButton>
            </StyledCol>
          )}
          {ableToShowHeaderShareDeepLinkButton() && (
            <StyledRow align={'center'} style={{ padding: '0 5px' }}>
              <StyledButton onClick={handleLinkShare}>
                <FlatIcon type={IconNameEnum.IcShare} size={IconSizeEnum.Size24} />
              </StyledButton>
            </StyledRow>
          )}
          {ableToShowHeaderShareButton() && _isShareAvailable && (
            <StyledRow align={'center'} style={{ padding: '0 5px' }}>
              <StyledButton
                onClick={() => {
                  if (!adSlugProperties) {
                    return
                  }
                  tracker.triggerCustomEvent(FitpetMallEventEnum.ClickEventsaleShareButton, adSlugProperties)
                  shareLink(tracker.getEventProperties(FitpetMallEventEnum.ClickEventsaleShareButton, adSlugProperties))
                }}
              >
                <FlatIcon type={IconNameEnum.IcShare} size={IconSizeEnum.Size24} />
              </StyledButton>
            </StyledRow>
          )}
          {ableToShowHeaderHomeButton() && (
            <StyledRow align={'center'} style={{ padding: '0 5px' }} data-cy="go-home-button">
              <LinkButton
                url={isInAppFlag ? undefined : ROUTES.INDEX}
                onClick={() => {
                  tracker.triggerCustomEvent(FitpetMallEventEnum.ClickGnbHomeIcon)
                  if (isInAppFlag) appBridgeProvider((bridge: Bridge) => bridge.goDeepLink('fitpetmall', '/main'))
                }}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <FlatIcon type={IconNameEnum.IcHome} size={IconSizeEnum.Size24} />
              </LinkButton>
            </StyledRow>
          )}
          {ableToShowHeaderSearchButton() && (
            <StyledRow align={'center'} style={{ padding: '0 5px' }}>
              <LinkButton
                rel="nosublink"
                url={{
                  pathname: ROUTES.SEARCH,
                  query: {
                    [QueryStringKeyEnum.SearchPetType]: query?.[QueryStringKeyEnum.SearchPetType] || getPetType(),
                  },
                }}
                onClick={() => {
                  tracker.triggerCustomEvent(FitpetMallEventEnum.ClickGnbSearchIcon)
                }}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <FlatIcon type={IconNameEnum.IcSearch} size={IconSizeEnum.Size24} />
              </LinkButton>
            </StyledRow>
          )}
          {ableToMypageSettingConfig() && (
            <StyledRow align={'center'} style={{ padding: '0 5px' }}>
              <LinkButton
                rel="nosublink"
                style={{ display: 'flex', alignItems: 'center', position: 'relative' }}
                url={isInAppFlag ? undefined : ROUTES.MYPAGE.BASE}
                onClick={() => {
                  if (isInApp()) {
                    appBridgeProvider((bridge: Bridge) => bridge.goDeepLink('mypage', '/setting'))
                  }
                }}
              >
                <FlatIcon type={IconNameEnum.IcSetting} size={IconSizeEnum.Size24} />
              </LinkButton>
            </StyledRow>
          )}
          {ableToShowHeaderCartButton() && (
            <StyledRow align={'center'} style={isMainTab() ? {} : { padding: '0 5px' }}>
              <LinkButton
                rel="nosublink"
                url={ROUTES.CART}
                onClick={() => {
                  const service = pathname.includes('/mypage') ? ServiceEnum.Mypage : ServiceEnum.Fitpetmall
                  tracker.triggerCustomEvent(FitpetMallEventEnum.ClickGnbCartIcon, { service })
                }}
                style={{ display: 'flex', alignItems: 'center', position: 'relative' }}
              >
                <CartItemCountCircle />
                <FlatIcon type={IconNameEnum.IcCart} size={IconSizeEnum.Size24} />
              </LinkButton>
            </StyledRow>
          )}
          {ableToCloseButtonOldIdLogin() && <CloseButtonOldIdLogin />}
          {ableToMembershipConfig() && (
            <StyledRow align={'center'} style={{ padding: '0 5px' }}>
              <LinkButton
                rel="nosublink"
                url={ROUTES.MEMBERSHIP.SETTING.BASE}
                style={{ display: 'flex', alignItems: 'center', position: 'relative' }}
              >
                <FlatIcon type={IconNameEnum.IcSetting} size={IconSizeEnum.Size24} />
              </LinkButton>
            </StyledRow>
          )}
        </StyledRow>
      </StyledCol>
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: ${FIXED_HEIGHT.HEADER / 10}rem;
`

const StyledRow = styled.div<{
  justify?: 'flex-start' | 'flex-end' | 'center' | 'space-around' | 'space-between'
  align?: 'flex-start' | 'center' | 'flex-end'
}>`
  display: flex;
  justify-content: ${(props) => props.justify ?? 'flex-start'};
  align-items: ${(props) => props.align ?? 'flex-start'};
  flex-wrap: nowrap;
  // safari 스크롤 시 헤더 떨리는 현상 대응
  -webkit-transform: translate3d(0, 0, 0);
`

const StyledCol = styled.div<{ flex?: string }>`
  flex: ${(props) => props.flex ?? '1'};
`

const SearchInput = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 1.2rem;
  padding-right: 1.2rem;
  margin-right: 1.6rem;
  height: 3.6rem;
  background: transparent;
  border: 1px solid ${GlobalColorEnum.Gray200};
  border-radius: 8px;

  @media ${SCREEN_MEDIA_QUERY.xs} {
    justify-content: flex-start;
  }
  span.search-placeholder {
    font-size: 1.3rem;
    color: ${(props) => props.theme.color.gray200};
    @media ${SCREEN_MEDIA_QUERY.xs} {
      display: none;
    }
  }
`
const StyledButton = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
`

export default Header
