import { ReactNode } from 'react'
import { message as antdMessage } from 'antd'
import { LAYOUT_UNITS } from '@/constants/legacy/constLayout'

type UseMessageType = {
  show: (message: ReactNode, duration?: number, onClick?: () => void) => void
  destroy: () => void
}

const useMessage = (): UseMessageType => {
  const show = (message: ReactNode, duration: number = 3, onClick?: () => void) => {
    antdMessage.config({
      top: 60,
      getContainer: () => {
        return document.body.querySelector('#fitpetMallBody') || document.body
      },
    })
    // TODO typescript 오류 수정 필요
    // @ts-ignore
    antdMessage.info({
      content: message,
      style: {
        // position: 'fixed',
        width: '100%',
        maxWidth: LAYOUT_UNITS.BODY_MAX_WIDTH,
        marginTop: '5px',
        textAlign: 'center',
      },
      duration,
      onClick,
    })
  }

  const destroy = () => {
    antdMessage.destroy()
  }

  return {
    show,
    destroy,
  }
}

export default useMessage
