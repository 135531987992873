import { deleteCookie, setCookie } from 'cookies-next'

export const cookieKey = 'FPUC' // Fitpet User Cookie

interface CookieOptions {
  token: string
  expires: Date
}

const setFitpetCookie = ({ token, expires }: CookieOptions) => {
  setCookie(cookieKey, token, {
    path: '/',
    secure: process.env.NODE_ENV !== 'development',
    expires,
    sameSite: 'lax',
    ...(process.env.NODE_ENV !== 'development' && { domain: process.env.COOKIE_DOMAIN }),
  })
}

const deleteFitpetCookie = () => {
  deleteCookie(cookieKey, {
    path: '/',
    domain: process.env.COOKIE_DOMAIN,
  })
}

export { setFitpetCookie, deleteFitpetCookie }
