import { useEffect } from 'react'
import { NextRouter } from 'next/router'
import { useTracker } from '@/containers/contexts/EventTrackingProvider'
import { sessionScrollRestorationURL, sessionShouldRestoreScroll } from '@/utils/utilSessionStorage'

const saveScrollPos = (url: string) => {
  // 현재 스크롤 위치를 세션 스토리지에 저장
  const scrollPos = { x: window.scrollX, y: window.scrollY }
  sessionScrollRestorationURL.save(url, scrollPos)
}

const removeScrollPos = (url: string) => {
  sessionScrollRestorationURL.remove(url)
}

const restoreScrollPos = (url: string) => {
  // 세션 스토리지에서 스크롤 위치를 가져와서 스크롤 이동
  const scrollPos = sessionScrollRestorationURL.load(url)
  if (scrollPos) {
    setTimeout(() => {
      window.scrollTo(scrollPos.x, scrollPos.y)
      removeScrollPos(url)
    }, 400)
  }
}

export const useScrollRestoration = (router: NextRouter) => {
  const tracker = useTracker()
  useEffect(() => {
    if ('scrollRestoration' in window.history) {
      window.history.scrollRestoration = 'manual'

      // 페이지 이동 시작 시 스크롤 위치 저장
      const onRouteChangeStart = () => {
        const scrollPos = sessionScrollRestorationURL.load(router.asPath)

        if (scrollPos?.y !== undefined && scrollPos.y !== 0) {
          return
        }

        saveScrollPos(router.asPath)
      }

      // (브라우저 뒤로 가기 동작으로 인한) 페이지 이동 완료 시
      // 스크롤을 이전 위치로 복원
      const onRouteChangeComplete = (url: string) => {
        tracker.triggerPageviewEvent(url)
        const shouldRestore = sessionShouldRestoreScroll.load()
        if (shouldRestore === 'true') {
          sessionShouldRestoreScroll.save('false')
          const transformUrl = (url: string) => {
            const mallPrefix = '/mall'

            if (url === mallPrefix) {
              // '/mall'일 경우 '/'로 변환
              return '/'
            } else if (url.startsWith(mallPrefix)) {
              // URL이 '/mall'로 시작하면 '/mall' 제거
              return url.replace(mallPrefix, '')
            }

            // 기본적으로 변경하지 않은 URL 반환
            return url
          }
          restoreScrollPos(transformUrl(url))
        }
      }

      router.events.on('routeChangeStart', onRouteChangeStart)
      router.events.on('routeChangeComplete', onRouteChangeComplete)
      // 뒤로 가기 시 동작, 앞으로 가기 시 동작 이때만, 스크롤 위치 저장
      // 새로 고침때는 동작하지 않음
      router.beforePopState(() => {
        sessionShouldRestoreScroll.save('true')
        return true
      })

      return () => {
        router.events.off('routeChangeStart', onRouteChangeStart)
        router.events.off('routeChangeComplete', onRouteChangeComplete)
        router.beforePopState(() => true)
      }
    }
  }, [router])
}
