import { useEffect, useRef } from 'react'
import * as ChannelService from '@channel.io/channel-web-sdk-loader'
import { UserContainer } from '@/containers/users/UserContainer'
import { Base64ConverterUtil } from '@/utils/base64-converter.util'
import { Bridge } from '@/utils/bridge/bridge'
import appBridgeProvider, { isAbleAppChannelTalk } from '@/utils/utilBridge' 
import { isInApp } from '@/utils/utilCommon'

export const useChannelTalk = () => {
  const { me } = UserContainer.useContainer()
  const isInitialized = useRef(false)

  const showMessenger = () => {
    if (isInApp() && !!isAbleAppChannelTalk) {
      appBridgeProvider((bridge: Bridge) => bridge.goView({ type: 'inquiry' }))
    } else {
      ChannelService.showMessenger()
    }
  }

  const initChannelTalk = () => {
    if (me) {
      ChannelService.boot({
        pluginKey: process.env.CHANNEL_TALK_PLUGIN_KEY,
        memberId: String(Base64ConverterUtil.convertBase64ToNumberId(me.id)),
        hideChannelButtonOnBoot: true,
        profile: {
          name: me.name,
          email: me.email,
          mobileNumber: me.mobileNumber || '',
          tags: process.env.NODE_ENV === 'production' ? [] : ['develop'],
        },
      })
    } else {
      ChannelService.boot({
        pluginKey: process.env.CHANNEL_TALK_PLUGIN_KEY,
        hideChannelButtonOnBoot: true,
        profile: {
          tags: process.env.NODE_ENV === 'production' ? [] : ['develop'],
        },
      })
    }
  }

  useEffect(() => {
    if (isInApp() && !!isAbleAppChannelTalk) {
      return
    }
    if (me && !isInitialized.current) {
      ChannelService.loadScript()
      initChannelTalk()
      isInitialized.current = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [me])

  return {
    showMessenger,
  }
}
