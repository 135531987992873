import styled, { useTheme } from 'styled-components'
import FlatIcon from '@/components/common/icons/FlatIcon'
import { Typo, TypoVariant } from '@/components/common/typography'
import { GlobalColorEnum } from '@/constants/global-color.enum'
import { IconNameEnum } from '@/constants/icon-name.enum'

interface SnackBarContentProps {
  text: string
  onActionClick: () => void
  actionText: string
}

const SnackBarContent = ({ text, actionText, onActionClick }: SnackBarContentProps) => {
  const { color, iconSize } = useTheme()

  return (
    <StyledWrapper>
      <StyledContentSection>
        <Typo variant={TypoVariant.Body1Regular} color={GlobalColorEnum.GrayWhite}>
          {text}
        </Typo>
        <StyledLinkBox onClick={onActionClick}>
          <Typo variant={TypoVariant.Body1Medium} color={GlobalColorEnum.Blue200}>
            {actionText}
          </Typo>
          <FlatIcon type={IconNameEnum.IcChevronRight} size={iconSize.size20} color={color.blue200} />
        </StyledLinkBox>
      </StyledContentSection>
    </StyledWrapper>
  )
}

export default SnackBarContent

const StyledWrapper = styled.div`
  width: 100%;
`
const StyledContentSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.8rem;
`

const StyledLinkBox = styled.a`
  display: flex;
  align-items: center;
  padding: 0.4rem;
`
