import { FC } from 'react'
import styled, { keyframes } from 'styled-components'
import { GlobalColorEnum } from '@/constants/global-color.enum'
import { getRandomNumber } from '@/utils/utilNumber'

const minWidth = 50
const maxWidth = 80

const SkeletonRecommendKeyword: FC = () => {
  return (
    <StyledKeywordList>
      {Array.from({ length: 10 }).map((_, index) => (
        <StyledRecommendKeyword key={index} width={getRandomNumber(minWidth, maxWidth)} />
      ))}
    </StyledKeywordList>
  )
}

const StyledKeywordList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding: 0 16px;
`

const shineLines = keyframes`
  0% {
    background-position: -100px;
  }
  40%, 100% {
    background-position: 100px;
  }
`

const StyledRecommendKeyword = styled.div<{ width: number }>`
  width: ${({ width }) => width}px;
  height: 36px;
  display: inline-block;
  border-radius: 24px;
  background-image: linear-gradient(
    90deg,
    ${GlobalColorEnum.Gray70} 0px,
    ${GlobalColorEnum.Gray50} 40px,
    ${GlobalColorEnum.Gray70} 80px
  );
  background-size: 600px;
  animation: ${shineLines} 3.5s infinite;

  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
`

export default SkeletonRecommendKeyword
