import { useState } from 'react'
import styled, { useTheme } from 'styled-components'
import FlatIcon from '@/components/common/icons/FlatIcon'
import { ProductOption } from '@/components/common/modals/bottomSheet.type'
import { Typo, TypoVariant } from '@/components/common/typography'
import ModalRestockProductOptionAdded from '@/components/domains/modals/ModalRestockProductOptionAdded'
import { IconNameEnum } from '@/constants/icon-name.enum'
import { useRestockProductOption } from '@/containers/hooks'
import { encodeId } from '@/utils/utilApi'

interface ProductChoiceSelectProps {
  productOptions: ProductOption[]
  onSelectProductOption: ({ productOptionId }: { productOptionId: number }) => void
  hasApplicableProductCoupon?: boolean
}

export const ProductChoiceSelect = ({
  productOptions,
  onSelectProductOption,
  hasApplicableProductCoupon = true,
}: ProductChoiceSelectProps) => {
  const { color } = useTheme()
  const { requestRestockProductOption, restockProductOptionAddedProps, isRequestedRestockProductOption } =
    useRestockProductOption()

  const [isOpen, setIsOpen] = useState(false)

  const handleClickPlaceHolder = () => {
    setIsOpen((prev) => !prev)
  }

  const handleClickItem = ({ productOptionId }: { productOptionId: number }) => {
    onSelectProductOption({ productOptionId })
    setIsOpen(false)
  }

  /**
   *
   * @description 재입고 알림 버튼 클릭 시 동작
   *
   *  isRequestRestock이 true면 알림 신청 완료, false면 재입고 알림
   *
   *  isRequestRestock이 true면 false로 요청해서 재입고 알림으로 변경
   *
   *  isRequestRestock이 false true 요청해서 알림 신청 완료로 변경
   */
  const handleClickRestockButton = async ({ productOptionId }: { productOptionId: number }) => {
    const isRequestedRestock = isRequestedRestockProductOption(encodeId('ProductOptionType', productOptionId))
    const encodedProductOptionId = encodeId('ProductOptionType', productOptionId)

    try {
      await requestRestockProductOption(!isRequestedRestock, encodedProductOptionId)
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <ProductChoiceSelectWrapper
      className="product-choice-select"
      isOpen={isOpen}
      hasApplicableProductCoupon={hasApplicableProductCoupon}
    >
      <div className="product-choice-select__header" onClick={handleClickPlaceHolder}>
        <div className="product-choice-select__placeholder">
          <Typo variant={TypoVariant.Body3Regular} color={color.gray300}>
            상품을 선택해 주세요.
          </Typo>
        </div>
        <div className="product-choice-select__icon">
          <FlatIcon type={IconNameEnum.IcChevronDown} color={color.gray700} size="2.4rem" />
        </div>
      </div>
      {isOpen && hasApplicableProductCoupon && (
        <div className="product-choice-select__has-applicable-coupon">
          <div className="product-choice-select__has-applicable-coupon-icon">
            <FlatIcon type={IconNameEnum.IcInfo} color={color.gray500} size="1.6rem" />
          </div>
          <Typo variant={TypoVariant.Body5Regular} color={color.gray500}>
            쿠폰 적용가 상품 선택 시 확인 가능
          </Typo>
        </div>
      )}
      {isOpen && (
        <div className="product-choice-select__content">
          <ul className="product-choice-select__list">
            {productOptions?.map(({ productOptionId, salePrice, name, pricePerSku, availableQuantity }, index) => {
              const isRequestedRestock = isRequestedRestockProductOption(encodeId('ProductOptionType', productOptionId))
              return availableQuantity === 0 ? (
                <li className="product-choice-select__item-wrapper" key={productOptionId}>
                  <div className="product-choice-select__item product-choice-select__item--restock">
                    <div className="product-choice-select__item-left">
                      <div className="product-choice-select__name">
                        <Typo as="span" variant={TypoVariant.Body3Bold} color={color.gray400}>
                          입고 예정
                        </Typo>
                        <Typo
                          as="span"
                          variant={TypoVariant.Body3Regular}
                          color={color.gray300}
                          style={{
                            marginLeft: '0.4rem',
                            display: 'inline',
                          }}
                        >
                          {name}
                        </Typo>
                      </div>
                    </div>
                    <div className="product-choice-select__price-info">
                      <Typo variant={TypoVariant.Body3Bold} color={color.gray400}>
                        {salePrice.toLocaleString()}원
                      </Typo>
                      <button
                        type="button"
                        className="product-choice-select__restock-button"
                        onClick={() => handleClickRestockButton({ productOptionId })}
                      >
                        <Typo
                          variant={TypoVariant.Body5Regular}
                          color={isRequestedRestock ? color.gray600 : color.blue500}
                          style={{
                            textDecoration: 'underline',
                          }}
                        >
                          {isRequestedRestock ? '알림 신청 완료' : '재입고 알림'}
                        </Typo>
                      </button>
                    </div>
                  </div>
                </li>
              ) : (
                <li className="product-choice-select__item-wrapper" key={productOptionId}>
                  <button
                    type="button"
                    className="product-choice-select__item"
                    onClick={() =>
                      handleClickItem({
                        productOptionId,
                      })
                    }
                  >
                    <div className="product-choice-select__name">
                      <Typo variant={TypoVariant.Body3Regular} color={color.gray900}>
                        {name}
                      </Typo>
                    </div>
                    <div className="product-choice-select__price-info">
                      <Typo variant={TypoVariant.Body3SemiBold} color={color.gray900}>
                        {salePrice.toLocaleString()}원
                      </Typo>
                      {!!pricePerSku && (
                        <Typo variant={TypoVariant.Body5Regular} color={color.gray500}>
                          1개당 {pricePerSku.toLocaleString()}원
                        </Typo>
                      )}
                    </div>
                  </button>
                </li>
              )
            })}
          </ul>
        </div>
      )}
      <ModalRestockProductOptionAdded useModalProps={restockProductOptionAddedProps} />
    </ProductChoiceSelectWrapper>
  )
}

const ProductChoiceSelectWrapper = styled.div<{ isOpen: boolean; hasApplicableProductCoupon: boolean }>`
  border: 1px solid
    ${({
      theme: {
        color: { gray100 },
      },
    }) => gray100};
  border-radius: 0.8rem;
  overflow: hidden;

  .product-choice-select {
    &__header {
      cursor: pointer;
      padding: 1.4rem 1.6rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      user-select: none;
    }
    &__content {
      border-top: 1px solid
        ${({
          theme: {
            color: { gray100 },
          },
        }) => gray100};
      ${({ hasApplicableProductCoupon }) => hasApplicableProductCoupon && 'border-top: none;'}
    }
    &__icon {
      ${({ isOpen }) => isOpen && 'transform: rotate(180deg);'};
    }
    &__has-applicable-coupon {
      background-color: ${({
        theme: {
          color: { gray30 },
        },
      }) => gray30};
      padding: 1.2rem 1.6rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.4rem;
      border-top: 1px solid
        ${({
          theme: {
            color: { gray100 },
          },
        }) => gray100};
    }
    &__list {
      cursor: pointer;
      margin-bottom: 0;
    }
    &__item {
      height: 6rem;
      cursor: pointer;
      width: 100%;
      gap: 0.8rem;
      background-color: transparent;
      border: none;
      padding: 0 1.6rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      &--restock {
        background-color: ${({
          theme: {
            color: { gray30 },
          },
        }) => gray30};
        cursor: default;
      }
    }
    &__restock-button {
      display: flex;
      background: none;
      border: none;
      padding: 0;
      margin: 0;
      cursor: pointer;
    }
    &__name {
      text-align: left;
    }
    &__price-info {
      gap: 0.2rem;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      flex-shrink: 0;
    }
  }
`
