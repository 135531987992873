import { useState } from 'react'
import { ApolloQueryResult } from '@apollo/client'
import { useDispatch } from 'react-redux'
import { useTracker } from '@/containers/contexts/EventTrackingProvider'
import MUTATION_VARIABLES from '@/containers/gqls/base/mutation_variables'
import { useApiMutation } from '@/containers/hooks'
import { doSetToggleRefetchOrderList } from '@/stores/reduxData'
import { doSetGlobalNoticeModal, doSetGlobalToastContent } from '@/stores/reduxUI'
import { convertConfirmOrderParams } from '@/utils/event-tracker/convertParams/convertProductParams'
import { FitpetMallEventEnum } from '@/utils/event-tracker/events'
import { currencyText } from '@/utils/utilNumber'

type UseOrderConfirmType = {
  confirmConfirmOrderItem: (
    orderItemId: string,
    mileageExpected: number,
    trackingInfo: OrderButtonsTrackInfoType,
    isPurchaseMileage: boolean
  ) => void
}

export type OrderButtonsTrackInfoType = {
  option_id: number
  optionName: string
  product_id: number
  productName: string
}

const useOrderConfirm = ({
  refreshConfirmButtonClick,
}: {
  refreshConfirmButtonClick?: () => void
}): UseOrderConfirmType => {
  const dispatch = useDispatch()
  const tracker = useTracker()
  const [disable, setDisable] = useState(false)

  const _onOrderItemConfirmed = () => {
    dispatch(doSetToggleRefetchOrderList(true))
    refreshConfirmButtonClick?.()
  }

  const [confirmOrderItemMutation] = useApiMutation('confirmOrderItem', _onOrderItemConfirmed)
  const _confirmOrderItem = async (orderItemId: string) => {
    const params = {
      id: orderItemId,
    }
    const variables = MUTATION_VARIABLES.CONFIRM_ORDER_ITEM(params)
    // @ts-ignore
    await confirmOrderItemMutation({ variables })
  }

  const handleOk = async (orderItemId: string, trackingInfo: OrderButtonsTrackInfoType) => {
    if (disable) {
      return
    }
    setDisable(true)
    await _confirmOrderItem(orderItemId)
    tracker.triggerCustomEvent(FitpetMallEventEnum.ClickOrderlistConfirmButton, convertConfirmOrderParams(trackingInfo))
    dispatch(
      doSetGlobalToastContent({
        content: '구매 확정이 완료되었습니다.',
      })
    )
  }

  const getModalSubText = (mileageExpected: number, isPurchaseMileage: boolean) =>
    `예상 적립금 : ${currencyText(mileageExpected)}` + (isPurchaseMileage ? `\n 적립금으로 구매하신 상품입니다.` : '')

  const confirmConfirmOrderItem = (
    orderItemId: string,
    mileageExpected: number,
    trackingInfo: OrderButtonsTrackInfoType,
    isPurchaseMileage: boolean
  ) => {
    dispatch(
      doSetGlobalNoticeModal({
        buttonType: 'ACTION_CANCEL',
        okText: '구매확정',
        text: `구매확정시 교환/반품이 불가합니다.\n구매확정 하시겠습니까?`,
        subText: getModalSubText(mileageExpected, isPurchaseMileage),
        onOk: () => handleOk(orderItemId, trackingInfo),
        visible: true,
      })
    )
  }

  return {
    confirmConfirmOrderItem,
  }
}

export default useOrderConfirm
