import { FunctionComponent, ReactNode } from 'react'
import { ApolloQueryResult, FetchMoreOptions, FetchMoreQueryOptions } from '@apollo/client'
// TODO typescript 오류 수정 필요
// @ts-ignore
import { DocumentNode, TypedDocumentNode } from 'graphql'
import { ApiError, ApiLoader } from '@/components/api'
import { OnApiCompletedType, OnApiErrorType } from '@/constants/legacy/constType'
import { DataResult, DataResults } from '@/containers/gqls/data'
import { PageInfoInputProps } from '@/containers/gqls/query_variables'
import useApiQuery from '@/containers/hooks/useApiQuery'
import { AdPetType } from '@/graphql/generated/schemas'
import * as utilApi from '@/utils/utilApi'

export type ListVariablesProps = {
  filter?: object
  orderBy?: string
  pageInfo?: PageInfoInputProps
}

export type RoleProps = { seller?: string; influnecer?: string }
type IdProps = { id: string }
type UserIdProps = { user: string }
type SlugProps = { slug: string }
type NameProps = { name: string }
type SearchProps = { search: string }
type ProductsProps = { products: string[] }
type BrandsProps = { brands: string[] }
type SubCategoryProps = { subCategory: string }
export type KeywordProps = { keyword: string; petType: AdPetType }
type RecommendProductProps = { productId: string }
type VariableProps = IdProps | UserIdProps | SlugProps

export type QueryParamsVariables =
  | ListVariablesProps
  | VariableProps
  | RoleProps
  | NameProps
  | ProductsProps
  | SubCategoryProps
  | SearchProps
  | KeywordProps
  | RecommendProductProps
  | BrandsProps

export type QueryParamsProps = {
  gqlKey: string
  variables?: QueryParamsVariables
  dataKey?: string
}

export type ApiQueryFetchMoreType = ((
  fetchMoreOptions: FetchMoreQueryOptions<any, any> & FetchMoreOptions<any, any>
) => Promise<ApolloQueryResult<any>>) &
  (<TData2, TVariables2>(
    fetchMoreOptions: {
      query?: DocumentNode | TypedDocumentNode<any, any>
    } & FetchMoreQueryOptions<TVariables2, any> &
      FetchMoreOptions<TData2, TVariables2>
  ) => Promise<ApolloQueryResult<TData2>>)

type QueryChildrenProps = {
  loading: boolean
  data: { [index: string]: DataResult | DataResults }
  refetch: (variables?: Partial<any> | undefined) => Promise<ApolloQueryResult<any>>
  fetchMore: ApiQueryFetchMoreType
}

export type ApiQueryProps = {
  queryParams: QueryParamsProps
  onAPISuccess?: OnApiCompletedType
  onAPIError?: OnApiErrorType
  children?: ({ data, refetch }: QueryChildrenProps) => ReactNode
  loader?: boolean
  noCache?: boolean
}

export type ApiQueryDataConnection<T> = {
  data: T[] | T
  totalCount: number
}

export type ApiQueryData<QueryName extends string, T> = {
  data: {
    [key in QueryName]: ApiQueryDataConnection<T>
  }
  loading: boolean
  refetch?: Function
}

const ApiQuery: FunctionComponent<ApiQueryProps> = ({
  queryParams,
  children,
  onAPISuccess,
  onAPIError,
  noCache = false,
  loader = false,
}) => {
  const { gqlKey, variables } = queryParams
  const { loading, error, data, refetch, fetchMore } = useApiQuery(gqlKey, variables, onAPISuccess, onAPIError, noCache)

  if (loading && loader) return <ApiLoader />
  // TODO typescript 오류 수정 필요
  // @ts-ignore
  return <>{children && children({ loading, data, refetch, fetchMore })}</>
}

export default ApiQuery
