import { gql } from '@apollo/client'
import TRANSACTION_FRAGMENTS from '@/containers/gqls/transactions/fragments'

const TRANSACTION_MUTATION_GQLS = {
  CREATE_TRANSACTION: gql`
    mutation createTransaction($input: CreateTransactionInput!) {
      createTransaction(input: $input) {
        transaction {
          ...createTransaction
        }
      }
    }
    ${TRANSACTION_FRAGMENTS.createTransaction}
  `,
  APPROVE_TRANSACTION: gql`
    mutation approveTransaction($id: ID!, $input: ApproveTransactionInput) {
      approveTransaction(id: $id, input: $input) {
        transaction {
          ...approveTransaction
        }
      }
    }
    ${TRANSACTION_FRAGMENTS.approveTransaction}
  `,
}

export default TRANSACTION_MUTATION_GQLS
