import LayoutBody from '@/components/domains/layouts/LayoutBody'
import LayoutBottomFixed from '@/components/domains/layouts/LayoutBottomFixed'
import LayoutBottomTabNavigator from '@/components/domains/layouts/LayoutBottomTabNavigator'
import LayoutFooter from '@/components/domains/layouts/LayoutFooter'
import LayoutHeader from '@/components/domains/layouts/LayoutHeader'
import LayoutMain from '@/components/domains/layouts/LayoutMain'
import LayoutMiddleFixed from '@/components/domains/layouts/LayoutMiddleFixed'
import LayoutSelectableTabs from '@/components/domains/layouts/LayoutSelectableTabs'

export {
  LayoutMain,
  LayoutBottomFixed,
  LayoutBottomTabNavigator,
  LayoutFooter,
  LayoutHeader,
  LayoutBody,
  LayoutSelectableTabs,
  LayoutMiddleFixed,
}
