import { FC } from 'react'
import { NewRow } from '@/components/common/layouts/NewRow'
import TextLegacy, { TextBaseProps } from '@/components/common/texts/TextBase'

type BulletedTextProps = {
  text: string
  textStyle?: TextBaseProps
}

const BulletedText: FC<BulletedTextProps> = ({ text, textStyle }) => {
  return (
    <NewRow gap="0.5rem">
      <TextLegacy {...textStyle}>·</TextLegacy>
      <TextLegacy {...textStyle}>{text}</TextLegacy>
    </NewRow>
  )
}

export default BulletedText
