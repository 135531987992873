import { useDispatch } from 'react-redux'
import ROUTES from '@/constants/legacy/constRoutes'
import { QueryStringKeyEnum } from '@/constants/query-string-key.enum'
import { useCustomRouter } from '@/containers/hooks'
import { doSetToggleModalLogin } from '@/stores/reduxUI'
import { Bridge } from '@/utils/bridge/bridge'
import appBridgeProvider, { appBridgeLogin, isInAppFlag } from '@/utils/utilBridge'

/**
 * `useGoToLoginIfNotAuthenticated`는 인증되지 않은 사용자가 접근하려 할 때 로그인 페이지로 이동하는 모달을 보여주는 React Hook입니다.
 *
 * 이 Hook은 사용자가 인증되지 않은 상태에서 특정 페이지나 기능에 접근하려 할 때 모달을 보여주고, 확인 버튼 클릭 시 로그인 페이지로 이동합니다.
 * 앱 내에서 실행되는 경우, 앱 브리지를 통해 로그인을 시도합니다.
 *
 * @returns {Object} `displayModalAndGoToLogin` 함수를 반환합니다. 이 함수는 모달을 보여주고, 확인 버튼 클릭 시 로그인 페이지로 이동합니다.
 */

const useGoToLoginIfNotAuthenticated = () => {
  const dispatch = useDispatch()
  const bridgeLogin = appBridgeLogin()
  const { push, asPath } = useCustomRouter()

  const goToLogin = () => {
    if (isInAppFlag) {
      appBridgeProvider((bridge: Bridge) =>
        bridge.login().then(() => {
          bridgeLogin(window.getToken())
        })
      )
    } else {
      push({
        pathname: ROUTES.ACCOUNTS.LOGIN,
        query: {
          [QueryStringKeyEnum.ReturnUrl]: asPath,
        },
      })
    }
  }

  // TODO: 추후 필요시 text, okText, cancelText을 인자로 받아서 커스텀 가능하도록 변경
  const displayModalAndGoToLogin = () => {
    dispatch(doSetToggleModalLogin(true))
  }
  return {
    displayModalAndGoToLogin,
    goToLogin,
  }
}

export { useGoToLoginIfNotAuthenticated }
