import { useDispatch, useSelector } from 'react-redux'
import ROUTES from '@/constants/legacy/constRoutes'
import { QueryStringKeyEnum } from '@/constants/query-string-key.enum'
import { SearchLocationEnum } from '@/constants/search-location.enum'
import { useCustomRouter } from '@/containers/hooks/index'
import { AdPetType } from '@/graphql/generated/schemas'
import { doSetRecentSearchKeywordList } from '@/stores/reduxData'
import { RootState } from '@/stores/store'
import { localRecentSearchKeywordList } from '@/utils/utilLocalStorage'

export type SearchUrlQuery = {
  [QueryStringKeyEnum.SearchQuery]: string
  [QueryStringKeyEnum.SearchLocation]: SearchLocationEnum
  [QueryStringKeyEnum.SearchPetType]?: AdPetType

  orderBy?: string
  categoryPrice?: string
  categoryInStock?: string
}

export type SearchUrlRequiredQuery = {
  [QueryStringKeyEnum.SearchQuery]: string
  [QueryStringKeyEnum.SearchPetType]: AdPetType
  [QueryStringKeyEnum.SearchLocation]: SearchLocationEnum
}

export type UseSearchType = {
  initSearchBodyMount: () => void
  recentSearchKeywordList: string[]
  removeKeyword: (keyword: string) => void
  removeAllKeywords: () => void
  goToResultPage: (queries: SearchUrlQuery) => void
}

const useSearch = (): UseSearchType => {
  const dispatch = useDispatch()
  const { query, push } = useCustomRouter()
  const recentSearchKeywordList = useSelector((state: RootState) => state.reduxDataReducers.recentSearchKeywordList)

  const setRecentSearchKeyword = (keywordList: string[]) => {
    const sliceKeywordList = keywordList.filter((item: string) => item !== '').slice(0, 10)
    localRecentSearchKeywordList.save(sliceKeywordList)
    dispatch(doSetRecentSearchKeywordList(sliceKeywordList))
  }

  const initSearchBodyMount = () => {
    const localKeywordList = localRecentSearchKeywordList.load()
    setRecentSearchKeyword(localKeywordList)
  }

  const removeKeyword = (keyword: string) => {
    const newKeywordList = recentSearchKeywordList.filter((item: string) => item !== keyword)
    setRecentSearchKeyword(newKeywordList)
  }

  const removeAllKeywords = () => {
    const emptyList: [] = []
    setRecentSearchKeyword(emptyList)
  }

  const goToResultPage = (queries: SearchUrlQuery) => {
    const _query: SearchUrlRequiredQuery = {
      ...queries,
      [QueryStringKeyEnum.SearchPetType]: query?.[QueryStringKeyEnum.SearchPetType] as AdPetType,
    }
    push({
      pathname: ROUTES.SEARCH_RESULT,
      query: _query,
    })
  }

  return {
    initSearchBodyMount,
    recentSearchKeywordList,
    removeKeyword,
    removeAllKeywords,
    goToResultPage,
  }
}

export default useSearch
