import styled, { useTheme } from 'styled-components'
import { Spinner } from '@/components/common/etc'
import FlatIcon from '@/components/common/icons/FlatIcon'
import { Typo, TypoVariant } from '@/components/common/typography'
import { IconNameEnum } from '@/constants/icon-name.enum'
import BasicCouponCardContent from './BasicCouponCardContent'

type CouponCardType = {
  title: string
  name: string
  amount: string
  datePeriod: string
  isMembership: boolean
  isConjunctUse: boolean
  couponCoverageContent: string
  isExcludeCouponCoverage: boolean
  disabled: boolean
  isAvailableDownload: boolean
  onClick: () => void
  errorMessage?: string
  downloadableQuantity?: number
  isLoading: boolean
}

const DownloadProductCouponCard = ({
  title,
  isMembership,
  isConjunctUse,
  name,
  amount,
  datePeriod,
  couponCoverageContent,
  isExcludeCouponCoverage,
  disabled,
  isAvailableDownload,
  onClick,
  errorMessage,
  downloadableQuantity,
  isLoading,
}: CouponCardType) => {
  const { iconSize, color } = useTheme()

  const handleDownloadClick = () => {
    if (isLoading || !isAvailableDownload) {
      return
    }
    onClick()
  }

  return (
    <Wrapper disabled={disabled} isAvailableDownload={isAvailableDownload} className="download-product-coupon-card">
      <div className="download-product-coupon-card__content">
        <BasicCouponCardContent
          title={title}
          name={name}
          amount={amount}
          datePeriod={datePeriod}
          isMembership={isMembership}
          isConjunctUse={isConjunctUse}
          couponCoverageContent={couponCoverageContent}
          isExcludeCouponCoverage={isExcludeCouponCoverage}
          errorMessage={errorMessage}
          disabled={disabled}
        />
      </div>
      {!disabled && (
        <DownloadButton onClick={handleDownloadClick}>
          {isAvailableDownload ? (
            <AvailableDownloadIcon>
              {isLoading ? (
                <Spinner />
              ) : (
                <>
                  <FlatIcon type={IconNameEnum.IcDownloadRadius} size={iconSize.size16} color={color.blue500} />
                  {!!downloadableQuantity && downloadableQuantity > 0 && (
                    <Typo variant={TypoVariant.Body4Bold} color={color.blue500}>
                      {downloadableQuantity > 99 ? '+99' : downloadableQuantity}장
                    </Typo>
                  )}
                </>
              )}
            </AvailableDownloadIcon>
          ) : (
            <FinishDownload>
              <FinishDownloadIcon type={IconNameEnum.IcCheck} size={iconSize.size16} color={color.gray500} />
              <Typo variant={TypoVariant.Body5Bold} color={color.gray500}>
                받기완료
              </Typo>
            </FinishDownload>
          )}
        </DownloadButton>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div<{ disabled: boolean; isAvailableDownload: boolean }>`
  & * {
    ${({
      disabled,
      theme: {
        color: { gray300 },
      },
    }) => disabled && `color: ${gray300}`}
  }
  width: 100%;
  overflow: hidden;
  border-radius: 0.8rem;
  border: 1px solid
    ${({
      theme: {
        color: { gray100 },
      },
    }) => gray100};
  background: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1.2rem;
  &[disabled] {
    pointer-events: none;
    border-color: ${({
      theme: {
        color: { gray100 },
      },
    }) => gray100};
    order: 2;
  }
  .download-product-coupon-card {
    &__content {
      padding: 1.6rem 0 1.6rem 1.6rem;
    }
  }
  ${({ isAvailableDownload }) => !isAvailableDownload && `order: 1`}
`
const DownloadButton = styled.div`
  text-align: center;
  display: flex;
  align-self: stretch;
`
const AvailableDownloadIcon = styled.div`
  width: 5.9rem;
  background-color: ${({
    theme: {
      color: { blue30 },
    },
  }) => blue30};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const FinishDownload = styled.div`
  width: 5.9rem;
  background-color: ${({
    theme: {
      color: { gray30 },
    },
  }) => gray30};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
const FinishDownloadIcon = styled(FlatIcon)``

export default DownloadProductCouponCard
