import axios, { AxiosResponse } from 'axios'
import qs from 'qs'
import { useDispatch } from 'react-redux'
import { welcomeDealEventSlug } from '@/constants/events/welcome-deal.const'
import { FitpetMallEventEnum } from '@/constants/fitpet-mall-event.enum'
import { ONELINK_HOST } from '@/constants/legacy/constEnv'
import { ToastConst } from '@/constants/toast.const'
import { UserContainer } from '@/containers/users/UserContainer'
import { doSetGlobalToastContent } from '@/stores/reduxUI'
import { Bridge } from '@/utils/bridge/bridge'
import appBridgeProvider, { isShareAvailable } from '@/utils/utilBridge'
import { copy2clipboard } from '@/utils/utilCommon'

interface AppBridgeData {
  title: string
  eventName: FitpetMallEventEnum
  eventProperty?: {
    [key: string]: string
  }
}

interface AppShareLink extends AppBridgeData {
  shortLink: string
}

interface ShortLinkOptions {
  meta: {
    ogTitle?: string
    ogDesc?: string
    ogImageUrl?: string
  }
  appBridgeData: AppBridgeData
}

const useShareLink = () => {
  const { me } = UserContainer.useContainer()
  const dispatch = useDispatch()
  /**
   * 상품상세/이벤트 페이지를 공유할떄 공유될 oneLink URL을 필요한 쿼리스트링과 조합해주는 함수
   * @param sharePageUrl 공유될 페이지 URL
   */
  const generateOneLinkUrl = (sharePageUrl: string) => {
    const shareQuery = {
      af_force_deeplink: true,
      af_dp: 'fitpet://detail-url',
      af_web_dp: sharePageUrl,
      deep_link_value: 'fitpet://detail-url',
      deep_link_sub1: sharePageUrl,
    }

    return `${ONELINK_HOST}?${qs.stringify(shareQuery)}`
  }

  const appShareLink = ({ title, shortLink, eventName, eventProperty }: AppShareLink) => {
    appBridgeProvider((bridge: Bridge) =>
      bridge.linkShare({
        title,
        link: shortLink,
        event: {
          eventName: eventName as string,
          eventParam: eventProperty ?? {},
        },
      })
    )
  }

  /**
   * 페이지 공유 시 oneLinkUrl을 firebase dynamic link API를 통해 shortLink를 반환받는 함수
   * @param url - 공유할 웹 페이지 URL
   * @param meta - 공유할 웹 페이지의 메타데이터
   * @param appBridgeData - 공유할 웹 페이지의 앱 브릿지 데이터
   */
  const processShareLink = async (
    url: string,
    { meta: { ogTitle, ogDesc, ogImageUrl }, appBridgeData: { title, eventName, eventProperty } }: ShortLinkOptions
  ) => {
    const linkWithLocation = `${url}${/\?/.test(url) ? '&location=share' : '?location=share'}`

    if (isShareAvailable) {
      const link = `${linkWithLocation}&oneLinkUrl=${generateOneLinkUrl(linkWithLocation)}`
      const firebaseURL = 'https://firebasedynamiclinks.googleapis.com/v1/shortLinks'
      const firebaseWebApiKey = process.env.FIREBASE_API_KEY
      const shortLinkPromise: AxiosResponse<{ previewLink: string; shortLink: string }> = await axios(
        `${firebaseURL}`,
        {
          method: 'post',
          headers: {
            'Content-Type': 'application/json',
          },
          params: {
            key: firebaseWebApiKey,
          },
          data: {
            dynamicLinkInfo: {
              domainUriPrefix: 'https://fitpetmall.page.link',
              link,
              socialMetaTagInfo: {
                socialTitle: ogTitle,
                socialDescription: ogDesc,
                socialImageLink: ogImageUrl,
              },
              navigationInfo: {
                enableForcedRedirect: 1,
              },
            },
            suffix: {
              option: 'SHORT',
            },
          },
        }
      )
      appShareLink({
        shortLink: shortLinkPromise.data.shortLink,
        title,
        eventName,
        eventProperty,
      })
    } else {
      const isWithCTAButtonPage = url.includes(welcomeDealEventSlug) && !me

      // 웹은 공유하기 대신, clipboard에 복사처리
      copy2clipboard(linkWithLocation)
      dispatch(
        doSetGlobalToastContent({
          content: 'URL이 복사되었어요',
          variant: isWithCTAButtonPage ? ToastConst.ToastVariant.withCTA : ToastConst.ToastVariant.default,
        })
      )
    }
  }
  return {
    processShareLink,
  }
}

export default useShareLink
