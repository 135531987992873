import { FC, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { ImageAsset } from '@/components/common'
import { DragScrollWrapper } from '@/components/common/etc'
import { GlobalColorEnum } from '@/constants/global-color.enum'
import { QueryStringKeyEnum } from '@/constants/query-string-key.enum'
import { SearchLocationEnum } from '@/constants/search-location.enum'
import { useTracker } from '@/containers/contexts/EventTrackingProvider'
import { useCustomRouter, useSearch } from '@/containers/hooks'
import { FitpetMallEventEnum } from '@/utils/event-tracker/events'

interface SearchKeywordListProps {
  keywordList: string[]
  removeKeyword: (e: React.MouseEvent<HTMLDivElement, MouseEvent>, keyword: string) => void
  handleKeywordClick: (keyword: string, index: number) => any
}

const EmptySearchKeywordList = () => {
  return <StyledEmptyRecentlySearchKeywords>검색 시 자동으로 검색어가 저장됩니다.</StyledEmptyRecentlySearchKeywords>
}

const SearchKeywordList: FC<SearchKeywordListProps> = ({ keywordList, removeKeyword, handleKeywordClick }) => {
  return (
    <RecentlySearchKeywordContent>
      {keywordList.map((keyword: string, index: number) => (
        <RecentlySearchKeywordBox key={keyword} onClick={() => handleKeywordClick(keyword, index)}>
          <div className="recently-search-keyword" data-cy="recently-search-keyword">
            {keyword}
          </div>
          <StyledRemoveButton onClick={(e) => removeKeyword(e, keyword)}>
            <ImageAsset name="ic_close_small" size={16} svg />
          </StyledRemoveButton>
        </RecentlySearchKeywordBox>
      ))}
    </RecentlySearchKeywordContent>
  )
}

const RecentSearchKeywords = () => {
  const { recentSearchKeywordList, removeKeyword, goToResultPage, removeAllKeywords } = useSearch()
  const [keywordList, setKeywordList] = useState(recentSearchKeywordList)
  const {
    query: { [QueryStringKeyEnum.SearchPetType]: searchPetType },
  } = useCustomRouter()
  const tracker = useTracker()

  useEffect(() => {
    setKeywordList(recentSearchKeywordList)
  }, [recentSearchKeywordList])

  const handleKeywordClick = (keyword: string, index: number) => {
    tracker.triggerCustomEvent(FitpetMallEventEnum.ClickSearchRecentKeyword, {
      searchKeyword: keyword,
      listIndex: index,
      petTypeSearch: searchPetType,
    })

    goToResultPage({
      [QueryStringKeyEnum.SearchQuery]: keyword,
      [QueryStringKeyEnum.SearchLocation]: SearchLocationEnum.Recent,
    })
  }

  const handleRemoveKeyword = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, keyword: string) => {
    event.stopPropagation()
    removeKeyword(keyword)
  }

  return (
    <>
      <RecentlySearchKeywordHeader>
        <StyledTitle>최근 검색어</StyledTitle>
        {keywordList.length > 0 && (
          <StyledRemoveButton onClick={removeAllKeywords} data-cy="remove-recently-keyword">
            전체삭제
          </StyledRemoveButton>
        )}
      </RecentlySearchKeywordHeader>
      <DragScrollWrapper>
        {keywordList.length === 0 ? (
          <EmptySearchKeywordList />
        ) : (
          <SearchKeywordList
            keywordList={keywordList}
            handleKeywordClick={handleKeywordClick}
            removeKeyword={handleRemoveKeyword}
          />
        )}
      </DragScrollWrapper>
    </>
  )
}

const RecentlySearchKeywordHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  height: auto;
`

const StyledTitle = styled.span`
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.02em;
  color: ${(props) => props.theme.color.gray900};

  &.hidden {
    visibility: hidden;
  }
`

const StyledRemoveButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
  cursor: pointer;
  color: ${(props) => props.theme.color.gray400};
`

const StyledEmptyRecentlySearchKeywords = styled.div`
  min-height: 36px;
  margin-block-start: 12px;
  padding-left: 16px;
  padding-top: 7px;
  padding-bottom: 7px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.02em;
  color: ${(props) => props.theme.color.gray400};
`

const RecentlySearchKeywordContent = styled.section`
  display: inline-block;
  min-width: max-content;
  margin-block-start: 12px;
  padding: 0 16px;
`

const RecentlySearchKeywordBox = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 4px;
  margin: 0 4px;
  padding: 8px 14px;
  border-radius: 20px;
  min-width: max-content;
  text-align: center;
  background-color: ${(props) => props.theme.color.gray70};
  color: ${(props) => props.theme.color.gray900};
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  cursor: pointer;

  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }

  .recently-search-keyword {
    // 텍스트가 166px 넘어가는 경우 ...로 표시
    max-width: 166px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 500;
  }
`

export default RecentSearchKeywords
