import crypto from 'crypto'
import { useEffect, useRef } from 'react'
import { useRouter } from 'next/router'
import { CriteoScriptConst } from '@/constants/criteo-script.const'
import ROUTES from '@/constants/legacy/constRoutes'
import { UserContainer } from '@/containers/users/UserContainer'

const executePath = [ROUTES.INDEX]

const htmlString = (email: string) =>
  `window.criteo_q = window.criteo_q || [];
    var deviceType = /iPad/.test(navigator.userAgent) ? "t" : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(navigator.userAgent) ? "m" : "d";
    window.criteo_q.push(
    { event: "setAccount", account: ${CriteoScriptConst.accountId}},
    { event: "setEmail", email: "${email}", hash_method: "sha256" },
    { event: "setSiteType", type: deviceType},
    { event: "viewHome"})`

/**
 * Criteo ViewHome 스크립트 실행
 * 홈화면에서만 실행
 */
const CriteoViewHome = () => {
  const router = useRouter()
  const { me } = UserContainer.useContainer()
  const email = me?.email ? crypto.createHash('sha256').update(me.email).digest('hex') : ''

  const divRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    // 홈화면에서만 실행
    if (!executePath.includes(router.pathname)) {
      return
    }

    const script = document.createElement('script')
    script.id = CriteoScriptConst.viewHomeId
    script.type = 'text/javascript'
    script.innerHTML = htmlString(email)
    script.async = false
    divRef.current?.appendChild(script)

    return () => {
      const script = document.getElementById(CriteoScriptConst.viewHomeId)
      script?.remove()
    }
  }, [email, router.pathname])

  return <div ref={divRef} />
}

export { CriteoViewHome }
