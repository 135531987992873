import { QueryLazyOptions, useLazyQuery } from '@apollo/client'
import { useDispatch, useSelector } from 'react-redux'
import { PRODUCT_QUERY_GQLS } from '@/containers/gqls/products/queries'
import { doSetLikeProductList } from '@/stores/reduxData'
import { RootState } from '@/stores/store'
import { Bridge } from '@/utils/bridge/bridge'
import { getResultFromData } from '@/utils/utilApi'
import appBridgeProvider, { isLikedPromotion } from '@/utils/utilBridge'
import { isEqual, isInAndroid, isInIos, uniqWith } from '@/utils/utilCommon'
import { localLikeProductList } from '@/utils/utilLocalStorage'

type UseLikeProductListType = {
  initLikedProductList: (likeProductList: LikeProductType[]) => void
  manageLikeProductList: (isLikedData: boolean, productId: string, promotionId: string) => void
  likeProductList: LikeProductType[]
  likeProductListQuery: (options?: QueryLazyOptions<{ pageInfo: any }> | undefined) => void
  isLikedProduct: (productId: string, promotionId: string) => boolean
}

export type LikeProductType = {
  productId: string
  promotionId: string
}

const useLikeProduct = (): UseLikeProductListType => {
  const dispatch = useDispatch()
  const likeProductList = useSelector((state: RootState) => state.reduxDataReducers.likeProductList)
  const [likeProductListQuery] = useLazyQuery(PRODUCT_QUERY_GQLS.LIKED_PRODUCT_IDS, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      if (!data) {
        return
      }
      const _data = getResultFromData(data)!.likedProductsWithPromotion.data
      const likeProductList = _data.map((item: any) => {
        if (item.productPromotion?.productPromotionType && item.productPromotion?.productPromotionType !== 'NORMAL') {
          return { productId: item.product?.id, promotionId: item.productPromotion?.id || '' }
        } else {
          return { productId: item.product?.id, promotionId: '' }
        }
      })
      initLikedProductList(likeProductList)
    },
  })

  const initLikedProductList = (likeProductList: LikeProductType[]) => {
    dispatch(doSetLikeProductList(likeProductList))
    localLikeProductList.save(likeProductList)
    if (isInIos()) {
      appBridgeProvider((bridge: Bridge) => {
        bridge.storageSetItem('likeProductList', likeProductList)
      })
    }
  }

  const manageLikeProductList = (isLikedData: boolean, productId: string, promotionId: string) => {
    const localProductIds = localLikeProductList.load()
    let localLikeProductListArr: LikeProductType[]

    if (isLikedData) {
      localLikeProductListArr = uniqWith([...localProductIds, ...[{ productId, promotionId }]], isEqual)
    } else {
      localLikeProductListArr = localProductIds.filter((item: LikeProductType) => {
        return !(item.productId === productId && item.promotionId === promotionId)
      })
    }
    if (isInAndroid()) {
      appBridgeProvider((bridge: Bridge) => {
        bridge.storageSetItem(
          isLikedData ? 'likeProductId' : 'unLikeProductId',
          isLikedPromotion ? { productId, promotionId } : { productId }
        )
      })
    }
    initLikedProductList(localLikeProductListArr)
  }

  const isLikedProduct = (productId: string, promotionId: string) => {
    return !!likeProductList.find((item: LikeProductType) => {
      return item.productId === productId && item.promotionId === promotionId
    })
  }

  return {
    initLikedProductList,
    manageLikeProductList,
    likeProductList,
    likeProductListQuery,
    isLikedProduct,
  }
}

export default useLikeProduct
