import { useDispatch } from 'react-redux'
import { doSetGlobalToastContent } from '@/stores/reduxUI'
import { ToastVariantKey } from '@/types/toast.type'
import { copy2clipboard } from '@/utils/utilCommon'

type CopyTextOptions = {
  text: string
  successMessage: string
  toastVariant: ToastVariantKey
}

const useCommonCopyText = () => {
  const dispatch = useDispatch()
  const copyText = ({ text, successMessage, toastVariant }: CopyTextOptions) => {
    copy2clipboard(text)
    dispatch(
      doSetGlobalToastContent({
        variant: toastVariant,
        content: successMessage,
      })
    )
  }

  return {
    copyText,
  }
}

export { useCommonCopyText }
