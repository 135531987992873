import { FC, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { ImageAsset, Modal, TextLegacy } from '@/components/common'
import { LinkButton } from '@/components/common/buttons'
import { TextBaseProps } from '@/components/common/texts/TextBase'
import { LayoutHeader } from '@/components/domains/layouts'
import { GlobalColorEnum } from '@/constants/global-color.enum'
import ROUTES from '@/constants/legacy/constRoutes'
import { useCustomRouter, useModal } from '@/containers/hooks'
import { doSetHeaderTitle } from '@/stores/reduxUI'

const TextProps: TextBaseProps = {
  size: { xs: 16, md: 18 },
  weight: 'bold',
}

const NeedConvert: FC = () => {
  const { query } = useCustomRouter()

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(doSetHeaderTitle('아이디 변경 요청'))
  }, [])

  return (
    <>
      <LayoutHeader />
      <StyledTextContainer>
        <StyledTextArea>
          <TextLegacy {...TextProps}>
            {query?.username && query?.username}
            &nbsp;아이디를
          </TextLegacy>
          <TextLegacy {...TextProps}>
            <TextLegacy {...TextProps} color="blue500">
              Email형식
            </TextLegacy>
            으로 변경해주세요.
          </TextLegacy>
        </StyledTextArea>
        <StyledTextArea>
          <TextLegacy>
            핏펫몰 정책 변경으로, 아이디 형식이 Email형식으로 변경되었습니다. 로그인 시도하신 아이디로 더이상 핏펫
            서비스 이용이 어렵습니다.
            <br />
            <br />
            번거로우시겠지만 핏펫몰 고객센터(02-6339-1800)로 연락주시면 이메일 아이디로 변경을 도와드리겠습니다.
            <br />
            <br />
            이용에 불편을 드려 죄송합니다. 항상 고객님의 편의를 우선시하는 핏펫이 되겠습니다. 감사합니다.
          </TextLegacy>
        </StyledTextArea>
        <a href="tel:02-6339-1800" style={{ display: 'block', width: '85%' }}>
          <InquiryButton>
            <ImageAsset name="ic_call" size={16} />
            <TextLegacy {...TextProps} color="white">
              &nbsp;고객센터 문의하기
            </TextLegacy>
          </InquiryButton>
        </a>
      </StyledTextContainer>
    </>
  )
}

export const CloseButtonOldIdLogin = () => {
  const { push } = useCustomRouter()
  const { useModalProps } = useModal()
  return (
    <>
      <LinkButton
        onClick={() => {
          useModalProps.showModal()
        }}
      >
        <ImageAsset name="close_new" size={24} svg />
      </LinkButton>
      <Modal
        useModalProps={useModalProps}
        buttonType="ACTION_CANCEL"
        okText="확인"
        cancelText="홈으로"
        onCancel={() => {
          push(ROUTES.INDEX)
        }}
      >
        <StyledModalContainer>
          <TextLegacy {...TextProps}>원활한 이용을 위해 아이디를 Email형식으로 변경해 주세요.</TextLegacy>
        </StyledModalContainer>
      </Modal>
    </>
  )
}

const StyledModalContainer = styled.div`
  height: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledTextArea = styled.div`
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  width: 85%;
`

const StyledTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
`

const InquiryButton = styled.div`
  width: 100%;
  height: 48px;
  background-color: ${(props) => props.theme.color.blue500};
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export default NeedConvert
