import { FC } from 'react'
import { TextLegacy } from '@/components/common'
import {
  FontColorType,
  FontResponsiveSizeType,
  FontResponsiveWeightType,
  FontWeightType,
} from '@/components/common/texts/TextBase'
import { CheckboxAntd } from '@/components/common/ui/checkbox-antd'

type InputCheckboxProps = {
  text?: string
  size?: FontResponsiveSizeType
  weight?: FontWeightType | FontResponsiveWeightType
  color?: FontColorType
  checked?: boolean
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  value?: string
}

// 디자인 시스템 적용 전 레거시 체크박스 InputCheckBox 사용할 것
const InputCheckboxLegacy: FC<InputCheckboxProps> = ({
  text = '',
  size = { xs: 14, md: 16 },
  weight = 'normal',
  color = 'default',
  ...props
}) => {
  return (
    <CheckboxAntd {...props}>
      <TextLegacy size={size} weight={weight} color={color}>
        {text}
      </TextLegacy>
    </CheckboxAntd>
  )
}

export default InputCheckboxLegacy
