import { gql } from '@apollo/client'

const REVIEW_FRAGMENTS = {
  get reviewImage() {
    return gql`
      fragment reviewImage on ReviewImageType {
        id
        image
        thumbnail(reviewImageType: "SMALL")
      }
    `
  },
  get reviewList() {
    return gql`
      fragment reviewList on ReviewType {
        createdAt
        id
        productOptionName
        reviewBody
        score
        username
        isLiked
        isPhotoReview
        likedUsers {
          totalCount
          edges {
            node {
              id
            }
          }
        }
        product {
          id
          productNumber
          name
        }
      }
    `
  },
}

export default REVIEW_FRAGMENTS
