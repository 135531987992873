import { IModelRestockProductOptionUserDefault } from '@/containers/models/base/defaultModelInterfaces'
import { ModelRestockProductOptionUserDefault } from '@/containers/models/base/defaultModels'

export interface IModelRestockProductOptionUser extends IModelRestockProductOptionUserDefault {}

export default class ModelRestockProductOptionUser extends ModelRestockProductOptionUserDefault {
  constructor(data: IModelRestockProductOptionUser) {
    super(data)
  }
}
