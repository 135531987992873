import { gql } from '@apollo/client'

export const COMMON_QUERY_GQLS = {
  INIT_DATA: gql`
    query initData {
      initData {
        mileageRewardPolicy {
          reviewRate
          orderConfirmRate
          imageReviewRate
        }
        shippingCompanies {
          id
          name
          sweetTrackerCode
        }
      }
    }
  `,
  BANKS: gql`
    query banks {
      banks {
        name
        code
      }
    }
  `,
}
