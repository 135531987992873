import { ValueOf } from 'type-fest'
import { GlobalTheme } from '@/styles/globalTheme'

const { color } = GlobalTheme

export enum TooltipTypeEnum {
  Accent = 'Accent',
  Basic = 'Basic',
  White = 'White',
  Blue = 'Blue',
}

type TooltipTypeClassType = {
  borderColor: ValueOf<typeof color>
  backgroundColor: ValueOf<typeof color>
  closeButtonColor: ValueOf<typeof color>
}

export const TooltipTypeClass: { [key in TooltipTypeEnum]: TooltipTypeClassType } = {
  [TooltipTypeEnum.Accent]: {
    borderColor: color.blue800,
    backgroundColor: color.blue800,
    closeButtonColor: color.grayWhite,
  },
  [TooltipTypeEnum.Basic]: {
    borderColor: color.gray800,
    backgroundColor: color.gray800,
    closeButtonColor: color.grayWhite,
  },
  [TooltipTypeEnum.White]: {
    borderColor: color.gray400,
    backgroundColor: color.grayWhite,
    closeButtonColor: color.gray900,
  },
  [TooltipTypeEnum.Blue]: {
    borderColor: color.blue500,
    backgroundColor: color.grayWhite,
    closeButtonColor: color.grayWhite,
  },
}
