import { useEffect } from 'react'

export const useAppendIamportScripts = () => {
  useEffect(() => {
    const iamportScript = document.createElement('script')
    iamportScript.type = 'text/javascript'
    iamportScript.src = 'https://service.iamport.kr/js/iamport.payment-1.2.0.js'
    iamportScript.defer = true
    document.head.appendChild(iamportScript)

    const jqueryScript = document.createElement('script')
    jqueryScript.type = 'text/javascript'
    jqueryScript.src = 'https://code.jquery.com/jquery-1.12.4.min.js'
    jqueryScript.defer = true
    document.head.appendChild(jqueryScript)

    return () => {
      document.head.removeChild(iamportScript)
      document.head.removeChild(jqueryScript)
    }
  })
}
