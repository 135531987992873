import { FC } from 'react'
import styled from 'styled-components'
import { Modal, TextLegacy } from '@/components/common'
import { ModalBaseProps } from '@/components/common/modals/ModalBase'
import { Omit } from '@/utils/utilTs'

type ModalNoticeProps<T> = {} & Omit<ModalBaseProps<T>, 'buttonType'>

const ModalNotice: FC<ModalNoticeProps<any>> = (props) => {
  const { useModalProps, okText = '확인' } = props
  const noticeText = useModalProps.actionParams?.noticeText || '-'
  const subText = useModalProps.actionParams?.subText

  return (
    <Modal buttonType="ACTION" okText={okText} {...props}>
      <StyledModalWrapper>
        <TextLegacy align="center" weight="bold" size={{ xs: 16, md: 18 }}>
          {noticeText}
        </TextLegacy>

        {subText && (
          <TextLegacy size={{ xs: 14, md: 16 }} align="center">
            {subText}
          </TextLegacy>
        )}
      </StyledModalWrapper>
    </Modal>
  )
}

const StyledModalWrapper = styled.div`
  padding: 40px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 1rem;
`

export default ModalNotice
