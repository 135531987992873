import { IModelInitDataDefault } from '@/containers/models/base/defaultModelInterfaces'
import { ModelInitDataDefault } from '@/containers/models/base/defaultModels'
import { round } from '@/utils/utilCommon'

export interface IModelInitData extends IModelInitDataDefault {
  orderConfirmRate: number
  imageReviewRate: number
  reviewRate: number
  maxMileageRate: number
  maxReviewRate: number

  getSweetTrackerCode: (shippingCompanyId?: string) => string
  getShippingCompanyName: (shippingCompanyId?: string) => string
}

export default class ModelInitData extends ModelInitDataDefault {
  orderConfirmRate = 0
  imageReviewRate = 0
  reviewRate = 0
  maxMileageRate = 0
  maxReviewRate = 0

  constructor(data: IModelInitData) {
    super(data)
    this.orderConfirmRate = data.mileageRewardPolicy.orderConfirmRate
    this.imageReviewRate = data.mileageRewardPolicy.imageReviewRate
    this.reviewRate = data.mileageRewardPolicy.reviewRate
    this.maxReviewRate = Math.max(this.imageReviewRate, this.reviewRate)
    this.maxMileageRate = round(this.orderConfirmRate + this.maxReviewRate, 2)
  }

  getSweetTrackerCode = (shippingCompanyId?: string): string => {
    if (shippingCompanyId && this.shippingCompanies) {
      const shippingCompany = this.shippingCompanies.find((x) => x.id === shippingCompanyId)
      if (shippingCompany) {
        return String(shippingCompany.sweetTrackerCode).padStart(2, '0')
      }
    }
    return '00'
  }

  getShippingCompanyName = (shippingCompanyId?: string): string => {
    if (shippingCompanyId && this.shippingCompanies) {
      const shippingCompany = this.shippingCompanies.find((x) => x.id === shippingCompanyId)
      if (shippingCompany) {
        return shippingCompany.name
      }
    }
    return ''
  }
}
