import styled, { useTheme } from 'styled-components'
import { Text } from '@/components/common'
import FlatIcon from '@/components/common/icons/FlatIcon'
import { IconNameEnum } from '@/constants/icon-name.enum'
import FitpetPlusCouponLogo from '@/images/fitpet_plus_coupon_logo.svg'

type BasicCouponCardContentType = {
  title: string
  name: string
  amount: string
  dDayDate?: string
  datePeriod: string
  isMembership: boolean
  isConjunctUse: boolean
  couponCoverageContent: string
  isExcludeCouponCoverage: boolean
  errorMessage?: string
  disabled: boolean
}

const BasicCouponCardContent = ({
  title,
  name,
  amount,
  dDayDate,
  datePeriod,
  isMembership,
  isConjunctUse,
  couponCoverageContent,
  isExcludeCouponCoverage,
  errorMessage,
  disabled,
}: BasicCouponCardContentType) => {
  const { iconSize, color } = useTheme()

  return (
    <div>
      <Header>
        <TitleWrapper>
          <Title>{title}</Title>
          <TitleLabel>
            {isMembership && (
              <MemberShipLabel disabled={disabled}>
                <StyledFitpetPlusCouponLogo />
              </MemberShipLabel>
            )}
            {!isConjunctUse && <ConjunctLabel>중복불가</ConjunctLabel>}
          </TitleLabel>
        </TitleWrapper>
        <Name>{name}</Name>
      </Header>
      <Content>
        {amount && <AmountWrapper>{amount}</AmountWrapper>}
        <DateWrapper>
          {dDayDate && <DDay>{dDayDate}</DDay>}
          <DatePeriod>{datePeriod}</DatePeriod>
        </DateWrapper>
        {couponCoverageContent && (
          <CoverageWrapper>
            <CoverageHeader>
              <CoverageText>쿠폰 적용대상</CoverageText>
              <CoverageArrowIcon type={IconNameEnum.IcChevronDown} size={iconSize.size16} color={color.gray400} />
            </CoverageHeader>
            <CoverageContent>{couponCoverageContent}</CoverageContent>
            {isExcludeCouponCoverage && <ExcludeCouponCoverage>(일부 카테고리 및 상품 제외)</ExcludeCouponCoverage>}
          </CoverageWrapper>
        )}
      </Content>
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </div>
  )
}

const Header = styled.div``

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const Title = styled(Text.H5)``

const TitleLabel = styled.div`
  display: flex;
  margin-left: 0.4rem;
`
const MemberShipLabel = styled.div<{ disabled: boolean }>`
  padding: 0.2rem 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  ${({ disabled }) =>
    disabled &&
    `${StyledFitpetPlusCouponLogo} {
      opacity: 0.3;
      mix-blend-mode: luminosity;
    }`}
`

const StyledFitpetPlusCouponLogo = styled(FitpetPlusCouponLogo)`
  width: 6.3rem;
  height: 1.7rem;
`

const ConjunctLabel = styled(Text.Body5)`
  padding: 0.2rem 0.4rem;
  margin-left: 0.4rem;
  background-color: ${({ theme }) => theme.color.gray50};
  color: ${({ theme }) => theme.color.gray500};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  border-radius: 0.4rem;
`
const Name = styled(Text.Body2)`
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`
const Content = styled.div`
  margin-top: 0.8rem;
`
const AmountWrapper = styled(Text.Body5)`
  color: ${({ theme }) => theme.color.gray500};
`
const DateWrapper = styled(Text.Body5)``
const DDay = styled.span`
  margin-right: 0.4rem;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.color.red500};
`
const DatePeriod = styled.span`
  color: ${({ theme }) => theme.color.gray500};
`
const CoverageText = styled(Text.Body5)`
  color: ${({ theme }) => theme.color.gray500};
`
const CoverageArrowIcon = styled(FlatIcon)``

const CoverageHeader = styled.summary`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  &::marker,
  &::-webkit-details-marker {
    display: none;
    content: '';
  }
`
const CoverageWrapper = styled.details`
  margin-top: 0.4rem;
  i {
    transition: all 0.3s ease-in-out;
    transform: rotate(0deg);
  }
  &[open] {
    /* 
     i 태그 사용
     FlatIcon 컴포넌트의 rotate 속성을 사용하면, rotate(180deg)가 적용되지 않는다.
     FlatIcon이 styled-components로 만들어져 있지 않기 때문에, styled-components의 스타일을 적용할 수 없다. 
     */
    i {
      transform: rotate(180deg);
    }
  }
`

const CoverageContent = styled(Text.Body5)`
  margin-top: 0.4rem;
  color: ${({ theme }) => theme.color.gray400};
`

const ExcludeCouponCoverage = styled(Text.Body5)`
  color: ${({ theme }) => theme.color.gray300};
`

const ErrorMessage = styled(Text.Body5)`
  margin-top: 0.4rem;
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  color: ${({ theme }) => theme.color.red500} !important;
`

export default BasicCouponCardContent
