import Axios, { AxiosError, AxiosRequestConfig } from 'axios'
import qs from 'qs'
import { isInAppFlag } from '@/utils/utilBridge'
import { localUserToken } from '@/utils/utilLocalStorage'

/**
 * pages/plugins/custom-instance.ts 참고
 */
export const customAxiosInstance = <T>(config: AxiosRequestConfig, options?: AxiosRequestConfig): Promise<T> => {
  let userToken
  if (typeof window !== undefined && isInAppFlag) {
    userToken = window.getToken().apiToken.token === '' ? undefined : window.getToken().apiToken
  } else {
    userToken = localUserToken.load()
  }

  const AXIOS_INSTANCE = Axios.create({
    baseURL: process.env.REST_API_DOMAIN,
    headers: {
      'Content-Type': 'application/json',
      Authorization: userToken !== undefined || userToken ? `Bearer ${userToken.token}` : null,
    },
    /**
     * 배열로 된 파라미터 쿼리스트링으로 변환
     * @link https://github.com/axios/axios/issues/5217
     */
    paramsSerializer: {
      serialize: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      },
    },
  })

  const source = Axios.CancelToken.source()
  const promise = AXIOS_INSTANCE({
    ...config,
    ...options,
    cancelToken: source.token,
  }).then(({ data }) => data)

  // @ts-ignore
  promise.cancel = () => {
    source.cancel('Query was cancelled')
  }

  return promise
}

export type ErrorType<Error> = AxiosError<Error>
