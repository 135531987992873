import { ToastConst } from '@/constants/toast.const'

const ToastVariantMargin = {
  default: 2.6,
  withCTA: 8.8,
} as const
type ToastVariantKey = keyof typeof ToastConst.ToastVariant

export { ToastVariantMargin }
export type { ToastVariantKey }
