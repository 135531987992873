import { ReactNode } from 'react'
import { EventTrackingProvider } from '@/containers/contexts/EventTrackingProvider'

interface FitpetMallProviderProps {
  children: ReactNode
}

export const FitpetMallProvider = ({ children }: FitpetMallProviderProps) => (
  <EventTrackingProvider>{children}</EventTrackingProvider>
)
