/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * Fitpet Mall
 * 핏펫몰 API 스펙

API 스펙 작성시 다음 가이드를 숙지하시기 바랍니다.

- [Open API 가이드 참고](https://swagger.io/docs/specification/about/)
- [Kotlin-Spring Open API Generator 설정 참고](https://openapi-generator.tech/docs/generators/kotlin-spring)
- REST API 디자인 가이드 참고
  - doc > guide > rest-api-design.md
- OpenAPI Spec 컨벤션 참고
  - doc > guid > openapi-spec-convention.md
- 설명(description) 작성시 마크다운([CommonMark](https://commonmark.org/help/)) 구문 참고

로컬에서 스웨거 에디터를 사용하는 방법은 다음과 같다.

```shell
docker pull swaggerapi/swagger-editor
docker run -d -p 8123:8080 swaggerapi/swagger-editor
open http://localhost:8123
```

Api 스펙을 하나의 파일로 합치려면 다음과 같이 실행한다.

```shell
# 설치
npm install -g swagger-cli

# 실행
swagger-cli bundle api-spec.yaml --outfile build/openapi.yaml --type yaml
```
 * OpenAPI spec version: 1.0.0
 */

/**
 * 이미지 유형 배너 구좌

- `MAIN_HOME_TOP_ROLL` : 메인 상단롤링 배너, 레거시 BANNER_TOP_ROLL 대응용
- `MAIN_HOME_MIDDLE_MULTI_LINE` : 메인 중단 멀티라인 배너, 레거시 BANNER_HOME_MULTI_LINE 대응용
- `MAIN_HOME_MIDDLE` : 메인 중단배너
- `MAIN_HOME_BOTTOM` : 메인 하단배너
- `PDP_BAND` : PDP 띠배너
- `COMPLETE_PAYMENT` : 결제 완료
- `CART`: 장바구니
- `COUPON`: 쿠폰함
 */
export type ImageTypeBannerAreaEnum = typeof ImageTypeBannerAreaEnum[keyof typeof ImageTypeBannerAreaEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ImageTypeBannerAreaEnum = {
  MAIN_HOME_TOP_ROLL: 'MAIN_HOME_TOP_ROLL',
  MAIN_HOME_MIDDLE_MULTI_LINE: 'MAIN_HOME_MIDDLE_MULTI_LINE',
  MAIN_HOME_MIDDLE: 'MAIN_HOME_MIDDLE',
  MAIN_HOME_BOTTOM: 'MAIN_HOME_BOTTOM',
  PDP_BAND: 'PDP_BAND',
  COMPLETE_PAYMENT: 'COMPLETE_PAYMENT',
  CART: 'CART',
  COUPON: 'COUPON',
} as const
