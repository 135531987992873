import axios from 'axios'
import { API_HOSPITAL_DOMAIN } from '@/constants/legacy/constEnv'

/**
 * 비회원 예약 건수 조회
 * @param mobileNumber
 * TODO error 발생 시 sentry 에 로그 남기기 - 2023-09-06 by joker
 */
const useNonUserReservation = () => {
  const getNonUserReservationCountByMobileNumber = async (mobileNumber: number) => {
    try {
      const result = await axios
        .create({ baseURL: API_HOSPITAL_DOMAIN })
        .get<number>(`/hospitals/reservations/valid/non-user?mobileNo=${mobileNumber}`)
      return result.data
    } catch (error) {
      return 0
    }
  }
  return { getNonUserReservationCountByMobileNumber }
}

export default useNonUserReservation
