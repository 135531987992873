import { FC } from 'react'
import { PlainBorderButtonLegacy } from '@/components/common/buttons'
import FlatIcon from '@/components/common/icons/FlatIcon'
import { NewCol } from '@/components/common/layouts/NewCol'
import { NewRow } from '@/components/common/layouts/NewRow'
import { IconNameEnum } from '@/constants/icon-name.enum'
import { Bridge } from '@/utils/bridge/bridge'
import appBridgeProvider, { isInAppFlag } from '@/utils/utilBridge'

const ButtonToCart: FC = () => {
  return (
    <NewRow justify="center" align="center" style={{ marginTop: 15 }}>
      <NewCol style={{ width: 187 }}>
        <PlainBorderButtonLegacy
          text="장바구니로 이동"
          suffix={
            <div style={{ paddingLeft: 2 }}>
              <FlatIcon type={IconNameEnum.IcChevronRight} size="1.6rem" />
            </div>
          }
          textStyle={{
            size: { xs: 14, md: 16 },
          }}
          onClick={() => {
            if (isInAppFlag) {
              appBridgeProvider((bridge: Bridge) => bridge.goDeepLink('fitpetmall', '/detail/cart'))
            }
          }}
          height={50}
        />
      </NewCol>
    </NewRow>
  )
}

export default ButtonToCart
