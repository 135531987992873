import { DealTypeEnum } from '@/constants/deal-type.enum'
import { IModelOrderItemDefault } from '@/containers/models/base/defaultModelInterfaces'
import { CartProductOptionType, CartProductOptionTypeUtil } from '@/containers/types/CartProductOption'
import { deepcopy } from '@/utils/utilData'

export type CartProductType = {
  productId: string
  product_id: string
  productPromotionType: 'DEAL_100' | 'DEAL_990' | 'NORMAL'

  productName: string
  thumbnail: string
  productPromotionId?: string

  checked: boolean
  cartProductOptions: CartProductOptionType[]
  totalProductOptionCount: number
}

export type CartProductKeyType = {
  productId: string
  productPromotionType: 'DEAL_100' | 'DEAL_990' | 'NORMAL'
}

export type CartProductTypeUtilType = {
  createCartProduct: (orderItem: IModelOrderItemDefault) => CartProductType
  getCartProduct: (
    cartProducts: CartProductType[],
    productId: string,
    productPromotionType: string
  ) => CartProductType | undefined
  getCartProductKey: (cartProduct: CartProductType) => CartProductKeyType
  getCartProductKeyByCartProductOption: (cartProductOption: CartProductOptionType) => CartProductKeyType
  equalsByProductKey: (cartProduct: CartProductType, cartProductKey: CartProductKeyType) => boolean
  updateCartProductValues: (oriCartProduct: CartProductType, newCartProduct: CartProductType) => void
  isEqualsForRendering: (one: CartProductType, other: CartProductType) => boolean
}

const createCartProduct = (orderItem: IModelOrderItemDefault): CartProductType => {
  const { productPromotion, productOption } = orderItem
  const { product } = productOption
  const { id: productId, _id: product_id, name: productName } = product

  return {
    productId,
    product_id,
    // TODO typescript 오류 수정 필요
    // @ts-ignore
    productPromotionType: productPromotion?.productPromotionType || DealTypeEnum.Normal,
    productName,
    thumbnail: product.mainImage.thumbnail,
    productPromotionId: productPromotion?.id,
    checked: true,
    cartProductOptions: [],
    totalProductOptionCount: product.isSingleOption
      ? 1
      : product.productProductOptions.filter((productOption) => !productOption.isDefault).length,
  }
}

const getCartProduct = (cartProducts: CartProductType[], productId: string, productPromotionType: string) => {
  for (const cartProduct of cartProducts) {
    if (cartProduct.productId === productId && cartProduct.productPromotionType === productPromotionType) {
      return cartProduct
    }
  }
  return undefined
}

const getCartProductKey = (cartProduct: CartProductType): CartProductKeyType => {
  return {
    productId: cartProduct.productId,
    productPromotionType: cartProduct.productPromotionType,
  }
}

const getCartProductKeyByCartProductOption = (cartProductOption: CartProductOptionType): CartProductKeyType => {
  return {
    productId: cartProductOption.productId,
    // TODO typescript 오류 수정 필요
    // @ts-ignore
    productPromotionType: cartProductOption.productPromotionType,
  }
}

const equalsByProductKey = (cartProduct: CartProductType, cartProductKey: CartProductKeyType): boolean => {
  return (
    cartProduct.productId === cartProductKey.productId &&
    cartProduct.productPromotionType === cartProductKey.productPromotionType
  )
}

const updateCartProductValues = (oriCartProduct: CartProductType, newCartProduct: CartProductType) => {
  oriCartProduct.productPromotionType = newCartProduct.productPromotionType
  oriCartProduct.productName = newCartProduct.productName
  oriCartProduct.thumbnail = newCartProduct.thumbnail
  oriCartProduct.productPromotionId = newCartProduct.productPromotionId
  oriCartProduct.cartProductOptions = deepcopy(newCartProduct.cartProductOptions)
  oriCartProduct.totalProductOptionCount = newCartProduct.totalProductOptionCount
}

const isEqualsForRendering = (one: CartProductType, other: CartProductType): boolean => {
  if (
    one.productId !== other.productId ||
    one.product_id !== other.product_id ||
    one.productPromotionType !== other.productPromotionType ||
    one.productName !== other.productName ||
    one.thumbnail !== other.thumbnail ||
    one.productPromotionId !== other.productPromotionId ||
    one.checked !== other.checked ||
    one.cartProductOptions !== other.cartProductOptions ||
    one.totalProductOptionCount !== other.totalProductOptionCount
  ) {
    return false
  }

  if (one.cartProductOptions.length !== other.cartProductOptions.length) {
    return false
  }

  for (let i = 0; i < one.cartProductOptions.length; i++) {
    if (!CartProductOptionTypeUtil.isEqualsForRendering(one.cartProductOptions[i], other.cartProductOptions[i])) {
      return false
    }
  }

  return true
}

export const CartProductTypeUtil: CartProductTypeUtilType = {
  createCartProduct,
  getCartProduct,
  getCartProductKey,
  getCartProductKeyByCartProductOption,
  equalsByProductKey,
  updateCartProductValues,
  isEqualsForRendering,
}
