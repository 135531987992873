import { FitpetMallEventEnum } from '@/constants/fitpet-mall-event.enum'
import { UserCouponListStatusEnum } from '@/constants/user-coupon-list-status.enum'
import { useTracker } from '@/containers/contexts/EventTrackingProvider'
import { UserCouponsQuery } from '@/graphql/generated/operations'
import { Base64ConverterUtil } from '@/utils/base64-converter.util'
import { createCouponList } from '@/utils/coupon.util'

type BasicCouponPageEventProps = {
  totalCouponCount: number
  couponListData?: UserCouponsQuery
}

type ClickCouponPageTabsEventProps = BasicCouponPageEventProps & {
  couponStatus: string
}

type BannerEventProps = BasicCouponPageEventProps & {
  bannerNameSlug: string
  bannerClickUrl: string
}

const getCouponIdsAndNames = (couponListData?: UserCouponsQuery) => {
  const couponList = createCouponList(couponListData)
  const { ids: couponIds, names: couponNames } = couponList.reduce(
    (acc, item) => {
      const { id, name } = item as { id: string; name: string }
      const decodedId = Base64ConverterUtil.convertBase64ToNumberId(id)
      return {
        ids: [...acc.ids, decodedId],
        names: [...acc.names, name],
      }
    },
    { ids: [], names: [] } as {
      ids: number[]
      names: string[]
    }
  )

  return { couponIds, couponNames }
}

export const useCouponEventTracker = () => {
  const tracker = useTracker()

  const trackViewCouponPageEvent = ({ totalCouponCount, couponListData }: BasicCouponPageEventProps) => {
    const { couponIds, couponNames } = getCouponIdsAndNames(couponListData)

    tracker.triggerCustomEvent(FitpetMallEventEnum.ViewCouponPage, {
      coupon_count: totalCouponCount,
      coupon_ids: couponIds,
      coupon_names: couponNames,
    })
  }

  const trackClickCouponPageTabsEvent = ({
    couponStatus,
    totalCouponCount,
    couponListData,
  }: ClickCouponPageTabsEventProps) => {
    if (couponStatus === UserCouponListStatusEnum.Available) {
      return
    }
    const { couponIds, couponNames } = getCouponIdsAndNames(couponListData)
    tracker.triggerCustomEvent(FitpetMallEventEnum.ClickCouponPageExpired, {
      coupon_count: totalCouponCount,
      coupon_ids: couponIds,
      coupon_names: couponNames,
    })
  }

  const trackClickCouponPageRegisterEvent = ({ totalCouponCount, couponListData }: BasicCouponPageEventProps) => {
    const { couponIds, couponNames } = getCouponIdsAndNames(couponListData)
    tracker.triggerCustomEvent(FitpetMallEventEnum.ClickCouponPageRegister, {
      coupon_count: totalCouponCount,
      coupon_ids: couponIds,
      coupon_names: couponNames,
    })
  }

  const trackViewCouponPageBannerEvent = ({
    totalCouponCount,
    couponListData,
    bannerNameSlug,
    bannerClickUrl,
  }: BannerEventProps) => {
    const { couponIds, couponNames } = getCouponIdsAndNames(couponListData)
    tracker.triggerCustomEvent(FitpetMallEventEnum.ViewCouponPageBanner, {
      coupon_count: totalCouponCount,
      coupon_ids: couponIds,
      coupon_names: couponNames,
      bannername_slug: bannerNameSlug,
      banner_clickurl: bannerClickUrl,
    })
  }

  const trackClickCouponPageBannerEvent = ({
    totalCouponCount,
    couponListData,
    bannerNameSlug,
    bannerClickUrl,
  }: BannerEventProps) => {
    const { couponIds, couponNames } = getCouponIdsAndNames(couponListData)
    tracker.triggerCustomEvent(FitpetMallEventEnum.ClickCouponPageBanner, {
      coupon_count: totalCouponCount,
      coupon_ids: couponIds,
      coupon_names: couponNames,
      bannername_slug: bannerNameSlug,
      banner_clickurl: bannerClickUrl,
    })
  }

  /**
   * @description 상품 쿠폰 바텀시트 > 쿠폰별 다운로드 버튼 클릭 시
   * add_location : 바텀시트 노출되기 전 영역/위치 기준
   */
  const trackClickCouponDownloadButtonEvent = ({
    addLocation,
    couponId,
    couponExpiredAt,
    couponDiscountAmount,
    couponDiscountRate,
    couponName,
    couponGroupId,
  }: {
    addLocation: string
    couponId: number
    couponExpiredAt: string
    couponDiscountAmount: number
    couponDiscountRate: number
    couponName: string
    couponGroupId: number
  }) => {
    tracker.triggerCustomEvent(FitpetMallEventEnum.ClickCouponDownloadButton, {
      add_location: addLocation,
      coupon_id: couponId,
      days_to_expire: couponExpiredAt,
      coupon_discount_amount: couponDiscountAmount,
      coupon_discount_rate: couponDiscountRate,
      coupon_name: couponName,
      coupon_group_id: couponGroupId,
    })
  }

  /**
   * @description 쿠폰 받기(쿠폰 바텀 시트 노출을 위한) 클릭 시
   * add_location : 바텀시트 노출되기 전 영역/위치 기준
   */
  const trackClickListCouponDownloadEvent = ({
    addLocation,
    productId,
    productName,
    buttonText,
    hasApplicableProductCoupon,
    salePriceAfterCoupon,
    isRealSoldOut,
  }: {
    addLocation: string
    productId: number
    productName: string
    buttonText: string
    hasApplicableProductCoupon: boolean
    salePriceAfterCoupon: number
    isRealSoldOut: boolean
  }) => {
    tracker.triggerCustomEvent(FitpetMallEventEnum.ClickListCouponDownload, {
      add_location: addLocation,
      product_id: productId,
      product_name: productName,
      button_text: buttonText,
      is_shownprice_coupondiscounted: hasApplicableProductCoupon,
      product_purchase_coupondiscount_price: salePriceAfterCoupon,
      product_is_in_stock: !isRealSoldOut,
    })
  }

  /**
   * @description 쿠폰 받기(쿠폰 바텀 시트) 노출 시
   * add_location : 바텀시트 노출되기 전 영역/위치 기준
   */
  const trackViewProductCouponEvent = ({
    addLocation,
    productId,
    productName,
    buttonText,
    isRealSoldOut,
  }: {
    addLocation: string
    productId: number
    productName: string
    buttonText: string
    isRealSoldOut: boolean
  }) => {
    tracker.triggerCustomEvent(FitpetMallEventEnum.ViewProductCoupon, {
      add_location: addLocation,
      product_id: productId,
      product_name: productName,
      button_text: buttonText,
      product_is_in_stock: !isRealSoldOut,
    })
  }

  return {
    trackViewCouponPageEvent,
    trackClickCouponPageTabsEvent,
    trackClickCouponPageRegisterEvent,
    trackViewCouponPageBannerEvent,
    trackClickCouponPageBannerEvent,
    trackClickCouponDownloadButtonEvent,
    trackClickListCouponDownloadEvent,
    trackViewProductCouponEvent,
  }
}
