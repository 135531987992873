import { FC } from 'react'
import styled, { keyframes } from 'styled-components'
import { GlobalColorEnum } from '@/constants/global-color.enum'
import { FIXED_SIZE } from '@/constants/legacy/constLayout'

const SkeletonPopularBrand: FC = () => {
  return (
    <StyledBrandList>
      {Array.from({ length: 10 }).map((_, index) => (
        <StyledSkeletonItem key={index}>
          <StyledSkeletonImage />
          <StyledSkeletonBrandText />
        </StyledSkeletonItem>
      ))}
    </StyledBrandList>
  )
}

const StyledBrandList = styled.div`
  min-width: max-content;
  padding: 0 16px;
  padding-inline-end: 50px;
`

const shineLines = keyframes`
  0% {
    background-position: -100px;
  }
  40%, 100% {
    background-position: 100px;
  }
`

const StyledSkeletonItem = styled.div`
  display: inline-block;
  cursor: pointer;
  margin: 0 6px;

  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
`

const StyledSkeletonImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${FIXED_SIZE.POPULAR_BRAND_LOGO}px;
  height: ${FIXED_SIZE.POPULAR_BRAND_LOGO}px;
  overflow: hidden;
  border-radius: 50%;
  background-image: linear-gradient(
    90deg,
    ${GlobalColorEnum.Gray70} 0px,
    ${GlobalColorEnum.Gray50} 40px,
    ${GlobalColorEnum.Gray70} 80px
  );
  background-size: 600px;
  animation: ${shineLines} 3.5s infinite;
`

const StyledSkeletonBrandText = styled.div`
  margin-block-start: 8px;
  margin-inline: auto;
  text-align: center;
  width: ${FIXED_SIZE.POPULAR_BRAND_LOGO}px;
  height: 16px;
  border-radius: 5px;
  background-image: linear-gradient(
    90deg,
    ${GlobalColorEnum.Gray70} 0px,
    ${GlobalColorEnum.Gray50} 40px,
    ${GlobalColorEnum.Gray70} 80px
  );
  background-size: 600px;
  animation: ${shineLines} 3.5s infinite;
`

export default SkeletonPopularBrand
