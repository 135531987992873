import { S3_HOST_URL } from '@/constants/legacy/constEnv'
import { IModelAdDefault } from '@/containers/models/base/defaultModelInterfaces'
import { ModelAdDefault } from '@/containers/models/base/defaultModels'
import { IModelCouponGroup } from '@/containers/models/modelCouponGroup'
import { IModelDisplayCollection } from '@/containers/models/modelDisplayCollection'
import { formatDate } from '@/utils/utilDate'

export interface IModelAd extends IModelAdDefault {
  adDisplayCollections: IModelDisplayCollection[]
  adCouponGroups: IModelCouponGroup[]
  couponGroups: IModelCouponGroup[]

  getFormattedStartedAt: () => string
  getFormattedEndedAt: () => string
  getListThumbnail: () => string
}

export default class ModelAd extends ModelAdDefault {
  getFormattedStartedAt = () => {
    return formatDate(this.startedAt)
  }

  getFormattedEndedAt = () => {
    return formatDate(this.endedAt)
  }

  getListThumbnail = () => {
    return S3_HOST_URL + '/' + this.mainImage.image
  }
}
