export enum QueryStringKeyEnum {
  SearchPetType = 'pt',
  SearchQuery = 'q',
  SearchLocation = 'lo',
  OrderBy = 'ob',
  FilterExcept = 'fe',
  FilterCategory = 'fc',
  FilterBrand = 'fb',
  FilterPrice = 'fp',
  ABTest = 'ab',

  // 로그인 관련 QueryString
  ReturnUrl = 'return_url',
  ReturnTo = 'return_to',

  CancelType = 'cancel_type',
  // 멤버십 관련 QueryString
  CancelReasonType = 'cancel_reason_type',
  EndedAt = 'ended_at',
  InvitationCode = 'invitation_code',
  FromOneLink = 'from_one_link',
  OrderId = 'order_id',

  //APP 화면 대응 관련 QueryString
  IsFullscreen = 'is_fullscreen',

  //이벤트 트래킹 속성 관련
  Location = 'location',

  //GNB 탭 관련
  CategoryName = 'categoryName',
  RootCategoryId = 'rootCategoryId',
  SubCategoryId = 'subCategoryId',
  BrandId = 'brandId',
  ProductId = 'productId',
  AdSlug = 'adSlug',
  PrevPath = 'prev_path', // 이전 페이지 경로

  // 섹션 이름 (홈 또는 섹션리스트에서 진입하는 경우만 필요)
  SectionName = 'section',

  // tab 클릭
  TabClick = 'tab_click',
}

export type QueryStringParamType = {
  [key in QueryStringKeyEnum]: string | undefined
}
