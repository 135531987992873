import { useDispatch, useSelector } from 'react-redux'
import { ProductPromotionScheme, PromotionTypeEnum } from '@/apis/rest/generated/types'
import { useLikeEventTracker } from '@/containers/event-tracker/like.event-tracker'
import { useCustomRouter } from '@/containers/hooks'
import { LikeProductType } from '@/containers/hooks/useLikeProduct'
import { useToggleProductLikedMutation } from '@/graphql/generated/hooks'
import { ProductLikedPromotionInput, ProductPromotionProductPromotionType } from '@/graphql/generated/schemas'
import { doSetLikeProductList } from '@/stores/reduxData'
import {
  doInitGlobalSnackBarContent,
  doInitGlobalToastContent,
  doSetGlobalSnackBarType,
  doSetGlobalToastContent,
} from '@/stores/reduxUI'
import { RootState } from '@/stores/store'
import { Bridge } from '@/utils/bridge/bridge'
import { decodeId, encodeId } from '@/utils/utilApi'
import { ApiErrorUtils } from '@/utils/utilApiErrors'
import appBridgeProvider, { isLikedPromotion } from '@/utils/utilBridge'
import { isInAndroid, isInApp, isInIos } from '@/utils/utilCommon'
import { localLikeProductList } from '@/utils/utilLocalStorage'

const saveLikedProductsToBridge = ({
  likedProducts,
  isLiked,
  id,
  promotionId,
}: {
  likedProducts: LikeProductType[]
  isLiked?: boolean
  id: string
  promotionId?: string
}) => {
  appBridgeProvider((bridge: Bridge) => {
    if (isInIos()) {
      bridge.storageSetItem('likeProductList', likedProducts)
    }
    if (isInAndroid()) {
      bridge.storageSetItem(
        isLiked ? 'likeProductId' : 'unLikeProductId',
        isLikedPromotion ? { productId: id, promotionId } : { productId: id }
      )
    }
  })
}

interface UseToggleLikeProps {
  productName: string
  price: number
  discountRate: number
  discountPrice: number
  reviewCount: number
  reviewScore: number
}

export const useToggleLike = ({
  productName,
  price,
  discountRate,
  discountPrice,
  reviewCount,
  reviewScore,
}: UseToggleLikeProps) => {
  const dispatch = useDispatch()
  const { trackClickLikeButtonEvent, trackClickUnLikeButtonEvent } = useLikeEventTracker()
  const { pathname } = useCustomRouter()
  const isHome = pathname.startsWith('/home') || pathname === '/'
  const likedList = useSelector((rootState: RootState) => rootState.reduxDataReducers.likeProductList)

  const [toggleLikeMutation] = useToggleProductLikedMutation()

  const toggleLike = async ({ productId, promotion }: { productId: number; promotion?: ProductPromotionScheme }) => {
    const input =
      promotion?.type && promotion?.type !== PromotionTypeEnum.NORMAL
        ? ({ productPromotion: encodeId('ProductPromotionType', promotion?.id) } as ProductLikedPromotionInput)
        : {}
    try {
      const { data, errors } = await toggleLikeMutation({
        variables: { id: encodeId('ProductType', productId), input },
      })
      if (errors) {
        throw errors
      } else {
        if (!data || !data.toggleProductLiked?.product) {
          return
        }

        const id = data.toggleProductLiked.product.id
        const isLiked = data.toggleProductLiked.product.isLiked
        const productPromotionType = data.toggleProductLiked.product.productPromotion?.productPromotionType
        const promotionId =
          productPromotionType && productPromotionType !== ProductPromotionProductPromotionType.Normal
            ? data.toggleProductLiked.product.productPromotion?.id
            : ''

        const newLikedProducts = isLiked
          ? [...likedList, { productId: id, promotionId: promotionId || '' }]
          : likedList.filter((item) => item.productId !== id)
        dispatch(doSetLikeProductList(newLikedProducts))
        localLikeProductList.save(newLikedProducts)

        // 찜하기 앱 브릿지 상태 저장
        saveLikedProductsToBridge({
          likedProducts: newLikedProducts,
          isLiked,
          id,
          promotionId,
        })

        // 찜하기 토스트 메시지
        toggleLikeToast(!!isLiked)

        // 찜하기 이벤트 트래커
        triggerLikeEventTracker({
          isLiked: !!isLiked,
          id: Number(decodeId(id).id),
          productName: productName,
          price,
          discountRate,
          discountPrice,
          reviewCount,
          reviewScore,
        })
      }
    } catch (error) {
      showErrorMessageToast(error)
    }
  }

  const showErrorMessageToast = (error: unknown) => {
    const errorInfo = ApiErrorUtils.getError(error)
    dispatch(
      doSetGlobalToastContent({
        content: `${errorInfo.message}\n오류가 지속된다면 고객센터에 문의해 주세요`,
        duration: 5,
      })
    )
  }

  const toggleLikeToast = (isLiked: boolean) => {
    if (isLiked) {
      dispatch(doInitGlobalToastContent([]))
      dispatch(doSetGlobalSnackBarType('liked'))
    } else {
      dispatch(doInitGlobalSnackBarContent([]))
      dispatch(
        doSetGlobalToastContent({
          content: '찜한 상품에서 제거되었어요',
          variant: isInApp() && isHome ? 'default' : 'withCTA',
        })
      )
    }
  }

  const triggerLikeEventTracker = ({
    isLiked,
    id,
    productName,
    price,
    discountRate,
    discountPrice,
    reviewCount,
    reviewScore,
  }: {
    isLiked: boolean
    id: number
    productName: string
    price: number
    discountRate: number
    discountPrice: number
    reviewCount: number
    reviewScore: number
  }) => {
    if (isLiked) {
      trackClickLikeButtonEvent({
        id,
        productName,
        price,
        discountRate,
        discountPrice,
        reviewCount,
        reviewScore,
      })
    } else {
      trackClickUnLikeButtonEvent({
        id,
        productName,
        price,
        discountRate,
        discountPrice,
        reviewCount,
        reviewScore,
      })
    }
  }

  return {
    toggleLike,
  }
}
