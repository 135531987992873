import { IModelReturnInfoDefault } from '@/containers/models/base/defaultModelInterfaces'
import { ModelReturnInfoDefault } from '@/containers/models/base/defaultModels'
import { IModelCustomerAddress } from '@/containers/models/modelCustomerAddress'

export interface IModelReturnInfo extends IModelReturnInfoDefault {
  customerAddress: IModelCustomerAddress
  returnShippingExtraFee: number
}

export default class ModelReturnInfo extends ModelReturnInfoDefault {}
