import { CSSProperties, createContext, useContext } from 'react'
import styled, { useTheme } from 'styled-components'
import { match } from 'ts-pattern'
import FlatIcon from '@/components/common/icons/FlatIcon'
import { FitpetIcon } from '@/components/common/icons/fitpet-icon'
import { Typo, TypoVariant } from '@/components/common/typography'
import {
  CARD_BODY_PRICE_INFO_TYPES,
  CARD_BODY_VERTICAL_SIZES,
  CardBodyPriceInfoType,
  CardBodyVerticalSize,
} from '@/components/domains/products/new-product-card/new-product-card.type'
import { IconNameEnum } from '@/constants/icon-name.enum'
import { useCardComponentSeoHook } from '@/containers/hooks/seo/use-card-component-seo.hook'
import { currencyText, percentText } from '@/utils/utilNumber'

interface CardBodyVerticalProps {
  children: React.ReactNode
  size: CardBodyVerticalSize
}

interface PriceInfoProps {
  type: CardBodyPriceInfoType
  hasDiscount: boolean
  discountRate: number
  price: number
  salePrice: number
}

interface PriceInfoPropsWithoutType extends Omit<PriceInfoProps, 'type'> {}

const CardBodyVerticalContext = createContext({} as { size: CardBodyVerticalSize })

export const CardBodyVertical = ({ children, size }: CardBodyVerticalProps) => {
  return (
    <CardBodyVerticalContext.Provider
      value={{
        size,
      }}
    >
      <CardBodyVerticalWrapper>{children}</CardBodyVerticalWrapper>
    </CardBodyVerticalContext.Provider>
  )
}

const CardBodyVerticalWrapper = ({ children }: { children: React.ReactNode }) => {
  return <CardBodyVerticalContainer>{children}</CardBodyVerticalContainer>
}

const CardBodyVerticalContainer = styled.div`
  width: 100%;
`

const Name = ({ children }: { children: React.ReactNode }) => {
  const { size } = useContext(CardBodyVerticalContext)
  const { isSeoH2TagByRoute } = useCardComponentSeoHook()
  const { color } = useTheme()

  const typoVariantBySize: Record<CardBodyVerticalSize, TypoVariant> = {
    [CARD_BODY_VERTICAL_SIZES.md]: TypoVariant.Body4Regular,
    [CARD_BODY_VERTICAL_SIZES.lg]: TypoVariant.Body3Regular,
    [CARD_BODY_VERTICAL_SIZES.half]: TypoVariant.Body3Regular,
    [CARD_BODY_VERTICAL_SIZES.full]: TypoVariant.Body3Regular,
  }

  return (
    <NameWrapper>
      <Typo
        as={isSeoH2TagByRoute() ? 'h2' : 'h3'}
        variant={typoVariantBySize[size]}
        color={color.gray700}
        style={{
          display: '-webkit-box',
          WebkitBoxOrient: 'vertical',
          WebkitLineClamp: 2,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          wordBreak: 'break-all',
        }}
      >
        {children}
      </Typo>
    </NameWrapper>
  )
}

const NameWrapper = styled.div`
  display: flex;
  margin-bottom: 0.4rem;
`

const DefaultOriginalPrice = ({ children }: { children: React.ReactNode }) => {
  const { size } = useContext(CardBodyVerticalContext)
  const { color } = useTheme()

  const typoVariantBySize: Record<CardBodyVerticalSize, TypoVariant> = {
    [CARD_BODY_VERTICAL_SIZES.md]: TypoVariant.Caption2Regular,
    [CARD_BODY_VERTICAL_SIZES.lg]: TypoVariant.Body5Regular,
    [CARD_BODY_VERTICAL_SIZES.half]: TypoVariant.Body5Regular,
    [CARD_BODY_VERTICAL_SIZES.full]: TypoVariant.Body5Regular,
  }

  const styleVariantBySize: Record<CardBodyVerticalSize, CSSProperties> = {
    [CARD_BODY_VERTICAL_SIZES.md]: {
      marginBottom: '0',
    },
    [CARD_BODY_VERTICAL_SIZES.lg]: {
      marginBottom: '0.2rem',
    },
    [CARD_BODY_VERTICAL_SIZES.half]: {
      marginBottom: '0.2rem',
    },
    [CARD_BODY_VERTICAL_SIZES.full]: {
      marginBottom: '0.2rem',
    },
  }

  return (
    <DefaultOriginalPriceWrapper variant={styleVariantBySize[size]}>
      <Typo
        variant={typoVariantBySize[size]}
        color={color.gray300}
        style={{
          textDecoration: 'line-through',
        }}
      >
        {children}
      </Typo>
    </DefaultOriginalPriceWrapper>
  )
}

const DefaultOriginalPriceWrapper = styled.div<{ variant: CSSProperties }>`
  display: flex;
  margin-bottom: ${({ variant: { marginBottom } }) => marginBottom};
`

const DefaultDiscountInfo = ({ children }: { children: React.ReactNode }) => {
  const { size } = useContext(CardBodyVerticalContext)

  const styleVariantBySize: Record<CardBodyVerticalSize, CSSProperties> = {
    [CARD_BODY_VERTICAL_SIZES.md]: {
      marginBottom: '0.2rem',
    },
    [CARD_BODY_VERTICAL_SIZES.lg]: {
      marginBottom: '0.4rem',
    },
    [CARD_BODY_VERTICAL_SIZES.half]: {
      marginBottom: '0.4rem',
    },
    [CARD_BODY_VERTICAL_SIZES.full]: {
      marginBottom: '0.4rem',
    },
  }

  return <DefaultDiscountInfoWrapper variant={styleVariantBySize[size]}>{children}</DefaultDiscountInfoWrapper>
}

const DefaultDiscountInfoWrapper = styled.div<{ variant: CSSProperties }>`
  display: flex;
  flex-direction: row;
  align-items: end;
  gap: 0.2rem;
  margin-bottom: ${({ variant: { marginBottom } }) => marginBottom};
`

const DefaultDiscountRate = ({ children }: { children: React.ReactNode }) => {
  const { size } = useContext(CardBodyVerticalContext)
  const { color } = useTheme()

  const variantBySize: Record<CardBodyVerticalSize, TypoVariant> = {
    [CARD_BODY_VERTICAL_SIZES.md]: TypoVariant.Body4Bold,
    [CARD_BODY_VERTICAL_SIZES.lg]: TypoVariant.Body1Bold,
    [CARD_BODY_VERTICAL_SIZES.half]: TypoVariant.Body1Bold,
    [CARD_BODY_VERTICAL_SIZES.full]: TypoVariant.Body1Bold,
  }

  return (
    <Typo variant={variantBySize[size]} color={color.red500}>
      {children}
    </Typo>
  )
}

const DefaultDiscountPrice = ({ children }: { children: React.ReactNode }) => {
  const { size } = useContext(CardBodyVerticalContext)
  const { color } = useTheme()

  const variantBySize: Record<CardBodyVerticalSize, TypoVariant> = {
    [CARD_BODY_VERTICAL_SIZES.md]: TypoVariant.Body4Bold,
    [CARD_BODY_VERTICAL_SIZES.lg]: TypoVariant.Body1Bold,
    [CARD_BODY_VERTICAL_SIZES.half]: TypoVariant.Body1Bold,
    [CARD_BODY_VERTICAL_SIZES.full]: TypoVariant.Body1Bold,
  }

  return (
    <Typo variant={variantBySize[size]} color={color.gray900}>
      {children}
    </Typo>
  )
}

const DiscountedBulkPrice = ({ children }: { children: React.ReactNode }) => {
  const { size } = useContext(CardBodyVerticalContext)
  const { color } = useTheme()

  const typoVariantBySize: Record<CardBodyVerticalSize, TypoVariant> = {
    [CARD_BODY_VERTICAL_SIZES.md]: TypoVariant.Caption2Regular,
    [CARD_BODY_VERTICAL_SIZES.lg]: TypoVariant.Body5Regular,
    [CARD_BODY_VERTICAL_SIZES.half]: TypoVariant.Body5Regular,
    [CARD_BODY_VERTICAL_SIZES.full]: TypoVariant.Body5Regular,
  }

  const styleVariantBySize: Record<CardBodyVerticalSize, CSSProperties> = {
    [CARD_BODY_VERTICAL_SIZES.md]: {
      marginBottom: '0.2rem',
    },
    [CARD_BODY_VERTICAL_SIZES.lg]: {
      marginBottom: '0.4rem',
    },
    [CARD_BODY_VERTICAL_SIZES.half]: {
      marginBottom: '0.4rem',
    },
    [CARD_BODY_VERTICAL_SIZES.full]: {
      marginBottom: '0.4rem',
    },
  }

  return (
    <DiscountedBulkPriceWrapper variant={styleVariantBySize[size]}>
      <Typo variant={typoVariantBySize[size]} color={color.blue800}>
        {children}
      </Typo>
    </DiscountedBulkPriceWrapper>
  )
}

const DiscountedBulkPriceWrapper = styled.div<{ variant: CSSProperties }>`
  display: flex;
  margin-bottom: ${({ variant: { marginBottom } }) => marginBottom};
`

const ReviewInfo = ({ children }: { children: React.ReactNode }) => {
  return <ReviewInfoWrapper>{children}</ReviewInfoWrapper>
}

const ReviewInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const ReviewScore = ({ children }: { children: React.ReactNode }) => {
  const { color } = useTheme()
  return (
    <ReviewScoreWrapper>
      <FlatIcon type={IconNameEnum.IcStar} color={color.yellow500} size="1.2rem" />
      <Typo variant={TypoVariant.Body5Medium} color={color.gray700}>
        {children}
      </Typo>
    </ReviewScoreWrapper>
  )
}

const ReviewScoreWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.4rem;
`

const ReviewCount = ({ children }: { children: React.ReactNode }) => {
  const { color } = useTheme()
  return (
    <ReviewCountWrapper>
      <Typo variant={TypoVariant.Body5Regular} color={color.gray400}>
        {children}
      </Typo>
    </ReviewCountWrapper>
  )
}

const ReviewCountWrapper = styled.div`
  display: flex;
  margin-left: 0.2rem;
`

const CouponDiscountInfo = ({ children }: { children: React.ReactNode }) => {
  const { size } = useContext(CardBodyVerticalContext)

  const styleVariantBySize: Record<CardBodyVerticalSize, CSSProperties> = {
    [CARD_BODY_VERTICAL_SIZES.md]: {
      gap: '0.2rem',
    },
    [CARD_BODY_VERTICAL_SIZES.lg]: {
      gap: '0.2rem',
    },
    [CARD_BODY_VERTICAL_SIZES.half]: {
      gap: '0.2rem',
    },
    [CARD_BODY_VERTICAL_SIZES.full]: {
      gap: '0.2rem',
    },
  }

  return <CouponInfoWrapper variant={styleVariantBySize[size]}>{children}</CouponInfoWrapper>
}

const CouponInfoWrapper = styled.div<{ variant: CSSProperties }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ variant: { gap } }) => gap};
`

const CouponDiscountRate = ({ children }: { children: React.ReactNode }) => {
  const { size } = useContext(CardBodyVerticalContext)
  const { color } = useTheme()

  const typoVariantBySize: Record<CardBodyVerticalSize, TypoVariant> = {
    [CARD_BODY_VERTICAL_SIZES.md]: TypoVariant.Caption2Medium,
    [CARD_BODY_VERTICAL_SIZES.lg]: TypoVariant.Body5Medium,
    [CARD_BODY_VERTICAL_SIZES.half]: TypoVariant.Body5Medium,
    [CARD_BODY_VERTICAL_SIZES.full]: TypoVariant.Body5Medium,
  }

  return (
    <CouponRateWrapper>
      <Typo variant={typoVariantBySize[size]} color={color.gray500}>
        {children}
      </Typo>
    </CouponRateWrapper>
  )
}

const CouponRateWrapper = styled.div`
  display: flex;
`

const CouponOriginalPrice = ({ children }: { children: React.ReactNode }) => {
  const { size } = useContext(CardBodyVerticalContext)
  const { color } = useTheme()

  const typoVariantBySize: Record<CardBodyVerticalSize, TypoVariant> = {
    [CARD_BODY_VERTICAL_SIZES.md]: TypoVariant.Caption2Regular,
    [CARD_BODY_VERTICAL_SIZES.lg]: TypoVariant.Body5Regular,
    [CARD_BODY_VERTICAL_SIZES.half]: TypoVariant.Body5Regular,
    [CARD_BODY_VERTICAL_SIZES.full]: TypoVariant.Body5Regular,
  }

  return (
    <Typo
      variant={typoVariantBySize[size]}
      color={color.gray300}
      style={{
        textDecoration: 'line-through',
      }}
    >
      {children}
    </Typo>
  )
}

const CouponDiscountPrice = ({ children }: { children: React.ReactNode }) => {
  const { size } = useContext(CardBodyVerticalContext)
  const { color } = useTheme()
  const typoVariantBySize: Record<CardBodyVerticalSize, TypoVariant> = {
    [CARD_BODY_VERTICAL_SIZES.md]: TypoVariant.Body4Bold,
    [CARD_BODY_VERTICAL_SIZES.lg]: TypoVariant.Body1Bold,
    [CARD_BODY_VERTICAL_SIZES.half]: TypoVariant.Body1Bold,
    [CARD_BODY_VERTICAL_SIZES.full]: TypoVariant.Body1Bold,
  }
  const styleVariantBySize: Record<CardBodyVerticalSize, CSSProperties> = {
    [CARD_BODY_VERTICAL_SIZES.md]: {
      gap: '0',
    },
    [CARD_BODY_VERTICAL_SIZES.lg]: {
      gap: '0.2rem',
    },
    [CARD_BODY_VERTICAL_SIZES.half]: {
      gap: '0.2rem',
    },
    [CARD_BODY_VERTICAL_SIZES.full]: {
      gap: '0.2rem',
    },
  }
  return (
    <CouponPriceWrapper variant={styleVariantBySize[size]}>
      <Typo variant={typoVariantBySize[size]} color={color.red500}>
        {children}
      </Typo>
      <Typo variant={TypoVariant.Caption1Bold} color={color.red500}>
        쿠폰적용시
      </Typo>
    </CouponPriceWrapper>
  )
}

const CouponPriceWrapper = styled.div<{ variant: CSSProperties }>`
  display: flex;
  flex-direction: column;
  gap: ${({ variant: { gap } }) => gap};
  margin-bottom: 0.2rem;
`

const DefaultPriceInfo = ({ hasDiscount, discountRate, price, salePrice }: PriceInfoPropsWithoutType) => {
  return (
    <>
      {hasDiscount && <DefaultOriginalPrice>{currencyText(price)}</DefaultOriginalPrice>}
      <DefaultDiscountInfo>
        {hasDiscount && <DefaultDiscountRate>{percentText(discountRate)}</DefaultDiscountRate>}
        <DefaultDiscountPrice>{currencyText(salePrice)}</DefaultDiscountPrice>
      </DefaultDiscountInfo>
    </>
  )
}

const CouponPriceInfo = ({ hasDiscount, discountRate, price, salePrice }: PriceInfoPropsWithoutType) => {
  return (
    <>
      <CouponDiscountInfo>
        {hasDiscount && <CouponDiscountRate>{percentText(discountRate)}</CouponDiscountRate>}
        {price !== salePrice && <CouponOriginalPrice>{currencyText(price)}</CouponOriginalPrice>}
      </CouponDiscountInfo>
      <CouponDiscountPrice>{currencyText(salePrice)}</CouponDiscountPrice>
    </>
  )
}

const PriceInfo = ({ hasDiscount, discountRate, price, salePrice, type }: PriceInfoProps) => {
  return match(type)
    .with(CARD_BODY_PRICE_INFO_TYPES.DEFAULT, () => (
      <DefaultPriceInfo hasDiscount={hasDiscount} discountRate={discountRate} price={price} salePrice={salePrice} />
    ))
    .with(CARD_BODY_PRICE_INFO_TYPES.COUPON, () => (
      <CouponPriceInfo hasDiscount={hasDiscount} discountRate={discountRate} price={price} salePrice={salePrice} />
    ))
    .exhaustive()
}

const MaxMileage = ({ children }: { children: React.ReactNode }) => {
  const { size } = useContext(CardBodyVerticalContext)
  const { color } = useTheme()

  const typoVariantBySize: Record<CardBodyVerticalSize, TypoVariant> = {
    [CARD_BODY_VERTICAL_SIZES.md]: TypoVariant.Body5SemiBold,
    [CARD_BODY_VERTICAL_SIZES.lg]: TypoVariant.Body5SemiBold,
    [CARD_BODY_VERTICAL_SIZES.half]: TypoVariant.Body5SemiBold,
    [CARD_BODY_VERTICAL_SIZES.full]: TypoVariant.Body5SemiBold,
  }

  return (
    <MaxMileageWrapper>
      <MaxMileageContent>
        <FitpetIcon type="ic-coin" size="1.6rem" color={color.gray500} />
        <Typo variant={typoVariantBySize[size]} color={color.gray500}>
          {children}
        </Typo>
      </MaxMileageContent>
    </MaxMileageWrapper>
  )
}

// 줄바꿈 용도
const MaxMileageWrapper = styled.div``
const MaxMileageContent = styled.div`
  margin-bottom: 0.4rem;
  border-radius: 0.4rem;
  padding: 0.2rem 0.4rem;
  background-color: ${({ theme }) => theme.color.gray50};
  display: inline-flex;
  gap: 0.2rem;
  align-items: center;
`

const TotalPurchases = ({ children }: { children: React.ReactNode }) => {
  const { size } = useContext(CardBodyVerticalContext)
  const { color } = useTheme()

  const typoVariantBySize: Record<CardBodyVerticalSize, TypoVariant> = {
    [CARD_BODY_VERTICAL_SIZES.md]: TypoVariant.Body5SemiBold,
    [CARD_BODY_VERTICAL_SIZES.lg]: TypoVariant.Body5SemiBold,
    [CARD_BODY_VERTICAL_SIZES.half]: TypoVariant.Body5SemiBold,
    [CARD_BODY_VERTICAL_SIZES.full]: TypoVariant.Body5SemiBold,
  }

  return (
    <TotalPurchasesWrapper>
      <TotalPurchasesContent>
        <FitpetIcon type="ic-buy-count" size="1.6rem" color={color.gray500} />
        <Typo variant={typoVariantBySize[size]} color={color.gray500}>
          {children}
        </Typo>
      </TotalPurchasesContent>
    </TotalPurchasesWrapper>
  )
}

// 줄바꿈 용도
const TotalPurchasesWrapper = styled.div``
const TotalPurchasesContent = styled.div`
  margin-bottom: 0.4rem;
  border-radius: 0.4rem;
  padding: 0.2rem 0.4rem;
  background-color: ${({ theme }) => theme.color.gray50};
  display: inline-flex;
  gap: 0.2rem;
  align-items: center;
`

CardBodyVertical.Name = Name

CardBodyVertical.DiscountedBulkPrice = DiscountedBulkPrice
CardBodyVertical.ReviewInfo = ReviewInfo
CardBodyVertical.ReviewScore = ReviewScore
CardBodyVertical.ReviewCount = ReviewCount

CardBodyVertical.DefaultPriceInfo = DefaultPriceInfo
CardBodyVertical.CouponPriceInfo = CouponPriceInfo

CardBodyVertical.PriceInfo = PriceInfo

CardBodyVertical.MaxMileage = MaxMileage
CardBodyVertical.TotalPurchases = TotalPurchases
