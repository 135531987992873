import { gql } from '@apollo/client'
import CATEGORY_FRAGMENTS from '@/containers/gqls/products/categories/fragments'

export const CATEGORY_QUERY_GQLS = {
  ROOT_CATEGORIES: gql`
    query rootCategories($filter: GenericScalar) {
      categories(filter: $filter, pageInfo: { isUnlimited: true }) {
        totalCount
        edges {
          node {
            ...categoryMinimum
            categoryChildren {
              edges {
                node {
                  ...categoryMinimum
                  categoryChildren {
                    edges {
                      node {
                        ...categoryMinimum
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    ${CATEGORY_FRAGMENTS.categoryMinimum}
  `,
}

export default CATEGORY_QUERY_GQLS
