import { match } from 'ts-pattern'
import { AccessIntroMembershipEnum } from '@/constants/access-intro-membership.enum'
import { FitpetMallEventEnum } from '@/constants/fitpet-mall-event.enum'
import { LocationEnum } from '@/constants/location.enum'
import { MembershipCancelReasonTypeSnakeEnum } from '@/constants/membership-cancel-reason-type.enum'
import { MembershipTeminatedTypeEnum } from '@/constants/membership-cancel-type.enum'
import { MembershipStatusLowerEnum } from '@/constants/membership-status.enum'
import { ShareTypeEnum } from '@/constants/share-type.enum'
import { ProductSupplyType } from '@/graphql/generated/schemas'

export { FitpetMallEventEnum }

export type FitpetMallEventPropertyType = {
  location: LocationEnum
  listType: AccessIntroMembershipEnum
  memberType: MembershipStatusLowerEnum
  subscribeType: string
  terminatedType: MembershipTeminatedTypeEnum
  terminatedReason: MembershipCancelReasonTypeSnakeEnum
  terminatedFamilyMember: MembershipStatusLowerEnum
  shareType: ShareTypeEnum
  eventsaleSlug: string
  eventsaleId: string
  eventsaleName: string
  path?: string
  productId: string | number
  promotion?: string | number
  redirectProductId?: string
  membershipUsertype: string
  membershipPrice: number
  membershipDiscountRate: number
  buttonText: string
}

export const getEventSubscribeType = ({
  payDuration, // 기간
  isFreeFirstMonthMembership, // 1달 무료 체험
}: {
  payDuration: number
  isFreeFirstMonthMembership: boolean
}) => {
  return match({
    payDuration,
    isFreeFirstMonthMembership,
  })
    .with(
      {
        isFreeFirstMonthMembership: true,
      },
      () => '1개월'
    )
    .with(
      {
        payDuration,
      },
      () => `${payDuration}개월`
    )
    .otherwise(() => '')
}

/**
 *
 * @param type string
 * API 정리되면 타입 1개로 통일
 * 현재 ProductSupplyType | SupplyTypeEnum | string 총 3개의 타입으로 되어 있음
 * @returns string
 */
export const convertToKoreanValueFromSupplyType = (type?: string): string => {
  const supplyTypeMap: Record<string, string> = {
    [ProductSupplyType.Buy]: '사입',
    [ProductSupplyType.Consignment]: '위탁',
    [ProductSupplyType.None]: '없음',
    [ProductSupplyType.Pb]: 'PB',
  }
  return type ? supplyTypeMap[type] : ''
}
