import styled from 'styled-components'
import { ButtonStyle } from '@/components/common/buttons/Button/button.type'

export const StyledRawButton = styled.button<ButtonStyle>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  cursor: pointer;
  &[disabled] {
    cursor: not-allowed;
  }
`
