import { useEffect, useRef, useCallback } from 'react'

interface UseDebounceCallbackProps {
  callback: (...args: any[]) => void
  delay: number
}

export const useDebounceCallback = ({ callback, delay }: UseDebounceCallbackProps) => {
  const callbackRef = useRef(callback)
  const timeoutRef = useRef<number | undefined>(undefined)

  useEffect(() => {
    callbackRef.current = callback
  }, [callback])

  const debouncedFunction = useCallback(
    (...args: any[]) => {
      if (timeoutRef.current !== undefined) {
        clearTimeout(timeoutRef.current)
      }

      timeoutRef.current = window.setTimeout(() => {
        callback(...args)
      }, delay)
    },
    [callback, delay]
  )

  const clear = () => {
    if (timeoutRef.current !== undefined) {
      clearTimeout(timeoutRef.current)
    }
  }

  useEffect(() => {
    return () => clear()
  }, [])

  return { debouncedFunction, clear }
}
