export enum OrderStatusEnum {
  UnconfirmedPayment = '결제대기중',
  NewOrder = '결제완료',
  ReadyForShipping = '배송준비중',
  Shipping = '배송중',
  Shipped = '배송완료',
  CancelRequested = '취소신청',
  Canceled = '취소완료',
  Confirmed = '구매확정',
  AutoVbank = '가상계좌 미입금 취소',
  ReturnRequested = '반품신청',
  ReturnUnderReturn = '반품진행중',
  ReturnReturned = '반품완료',
  ReturnConfirmed = '환불완료',
  ExchangeRequested = '교환신청',
  ExchangeUnderReturn = '교환진행중',
  ExchangeConfirmed = '교환완료',
  UnpaidCanceled = '미입금 취소',
}
