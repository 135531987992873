import styled, { useTheme } from 'styled-components'
import { Button } from '@/components/common/buttons'
import FlatIcon from '@/components/common/icons/FlatIcon'
import { Typo, TypoVariant } from '@/components/common/typography'
import { IconNameEnum } from '@/constants/icon-name.enum'

interface CartButtonProps {
  disabled: boolean
  onClick: () => void
}

export const CartButton = ({ disabled, onClick }: CartButtonProps) => {
  const { color } = useTheme()

  const contentColor = disabled ? color.gray200 : color.gray900

  const handleButtonClick = async () => {
    onClick()
  }

  return (
    <CartButtonWrapper onClick={handleButtonClick} disabled={disabled} width="100%" height="3.2rem">
      <FlatIcon type={IconNameEnum.IcCart} color={contentColor} size="1.6rem" />
      <Typo variant={TypoVariant.Body3Medium} color={contentColor}>
        담기
      </Typo>
    </CartButtonWrapper>
  )
}
const CartButtonWrapper = styled(Button.Tertiary)`
  display: flex;
  align-items: center;
  gap: 0.4rem;
  margin-top: 0.8rem;
`
