import { match } from 'ts-pattern'
import { FitpetMallEventEnum } from '@/constants/fitpet-mall-event.enum'
import { UserGenderEnum } from '@/constants/user-gender.enum'
import { useTracker } from '@/containers/contexts/EventTrackingProvider'
import useNonUserReservation from '@/containers/event-tracker/container/non-user-reservation.container.hook'
import { SocialServiceSocialType } from '@/graphql/generated/schemas'
import { convertUserProperties } from '@/utils/event-tracker/convertParams/convertUserParams'
import { localInvitationCode } from '@/utils/utilLocalStorage'

type ViewSignupInfoEventParams = {
  signupType?: typeof SocialServiceSocialType[keyof typeof SocialServiceSocialType]
  isInviteCodeIncluded: boolean
}

type SubmitInfoEventParams = {
  signupType?: typeof SocialServiceSocialType[keyof typeof SocialServiceSocialType] | string
  errorType?: string
}

interface SubmitInfoIdEventParams extends SubmitInfoEventParams {
  isValid?: boolean
  isDuplicated?: boolean
}

interface SubmitInfoGenderEventParams extends SubmitInfoEventParams {
  gender?: string
}

type ClickInfoDetailTermsEventParams = {
  termsType: string
  signupType?: typeof SocialServiceSocialType[keyof typeof SocialServiceSocialType] | string
}

interface ViewSignupInfoPopupEventParams extends SubmitInfoEventParams {
  popupText: string
}

interface ClickSignupInfoPopUpEventParams extends ViewSignupInfoPopupEventParams {
  buttonText: string
}

interface ClickSignupInfoAllTermsEventParams extends SubmitInfoEventParams {
  checkBox: boolean
}

const convertGenderValueToPrivate = (value: UserGenderEnum) => {
  if (value === UserGenderEnum.Unknown) {
    return 'private'
  }
  return value
}

const useSignupEventTracker = () => {
  const tracker = useTracker()
  const { getNonUserReservationCountByMobileNumber } = useNonUserReservation()

  const trackViewSignupInfoEvent = (properties: ViewSignupInfoEventParams) => {
    tracker.triggerCustomEvent(FitpetMallEventEnum.ViewSignupInfo, properties)
  }

  const trackCompleteSignUpEvent = async (data: any) => {
    const nonUserReservationCount = await getNonUserReservationCountByMobileNumber(data.user.data.mobileNumber)

    const userProperties = convertUserProperties(data, FitpetMallEventEnum.CompleteSignup)
    tracker.setUserProperties({ ...userProperties, is_invited: !!localInvitationCode.load() })

    tracker.triggerCustomEvent(FitpetMallEventEnum.CompleteSignup, {
      signupCompleteType: data.device.data.socialService?.socialType?.toLowerCase() || 'email',
      signupMarketingAgreement: data.user.agreeMarketing,
      signupUserId: data.user._id,
      isInvited: !!localInvitationCode.load(),
      nonuserReservation: nonUserReservationCount > 0,
      nonuserReservationCount: nonUserReservationCount,
    })
  }

  const trackSubmitSignupInfoIdEvent = ({ signupType, isDuplicated, isValid }: SubmitInfoIdEventParams) => {
    const errorType = match({ isDuplicated, isValid })
      .with({ isDuplicated: true }, () => {
        return 'email_existing'
      })
      .with({ isValid: false }, () => {
        return 'error_email'
      })
      .otherwise(() => {
        return 'normal'
      })

    tracker.triggerCustomEvent(FitpetMallEventEnum.SubmitSignupInfoId, {
      signup_type: signupType,
      error_type: errorType,
    })
  }

  const trackSubmitSignupInfoPasswordEvent = ({ signupType, errorType }: SubmitInfoEventParams) => {
    tracker.triggerCustomEvent(FitpetMallEventEnum.SubmitSignupInfoPassword, {
      signup_type: signupType,
      error_type: errorType,
    })
  }

  const trackSubmitSignupInfoPasswordConfirmEvent = ({ signupType, errorType }: SubmitInfoEventParams) => {
    tracker.triggerCustomEvent(FitpetMallEventEnum.SubmitSignupInfoPasswordConfirm, {
      signup_type: signupType,
      error_type: errorType,
    })
  }

  const trackSubmitSignupInfoNameEvent = ({ signupType, errorType }: SubmitInfoEventParams) => {
    tracker.triggerCustomEvent(FitpetMallEventEnum.SubmitSignupInfoName, {
      signup_type: signupType,
      error_type: errorType,
    })
  }

  const trackClickSignupInfoGenderEvent = ({ signupType, gender }: SubmitInfoGenderEventParams) => {
    tracker.triggerCustomEvent(FitpetMallEventEnum.ClickSignupInfoGender, {
      signup_type: signupType,
      gender: gender,
    })
  }

  const trackClickSignupInfoCodeSendEvent = ({ signupType }: SubmitInfoEventParams) => {
    tracker.triggerCustomEvent(FitpetMallEventEnum.ClickSignupInfoCodeSend, {
      signup_type: signupType,
    })
  }

  const trackerSubmitSignupInfoBirthdayEvent = ({ signupType, errorType }: SubmitInfoEventParams) => {
    tracker.triggerCustomEvent(FitpetMallEventEnum.SubmitSignupInfoBirthday, {
      signup_type: signupType,
      error_type: errorType,
    })
  }

  const trackerSubmitSignupInfoInviteCodeEvent = ({ signupType, errorType }: SubmitInfoEventParams) => {
    tracker.triggerCustomEvent(FitpetMallEventEnum.SubmitSignupInfoInviteCode, {
      signup_type: signupType,
      error_type: errorType,
    })
  }

  const trackerViewSignupInfoPopUpEvent = ({ signupType, popupText }: ViewSignupInfoPopupEventParams) => {
    tracker.triggerCustomEvent(FitpetMallEventEnum.ViewSignupInfoPopUp, {
      popup_text: popupText,
      signup_type: signupType,
    })
  }

  const trackerClickSignupInfoPopUpEvent = ({ signupType, popupText, buttonText }: ClickSignupInfoPopUpEventParams) => {
    tracker.triggerCustomEvent(FitpetMallEventEnum.ClickSignupInfoPopUp, {
      popup_text: popupText,
      button_text: buttonText,
      signup_type: signupType,
    })
  }

  const trackerClickSignupInfoAllTermsEvent = ({ signupType, checkBox }: ClickSignupInfoAllTermsEventParams) => {
    tracker.triggerCustomEvent(FitpetMallEventEnum.ClickSignupInfoAllTerms, {
      signup_type: signupType,
      check_box: checkBox,
    })
  }

  const trackerClickInfoDetailTermsEvent = ({ signupType, termsType }: ClickInfoDetailTermsEventParams) => {
    match(termsType)
      .with('age', () => {
        tracker.triggerCustomEvent(FitpetMallEventEnum.ClickSignupInfoDetailTerms, {
          is_necessary: true,
          terms_type: 'age',
          signup_type: signupType,
        })
      })
      .with('usage', () => {
        tracker.triggerCustomEvent(FitpetMallEventEnum.ClickSignupInfoDetailTerms, {
          is_necessary: true,
          terms_type: 'general',
          signup_type: signupType,
        })
      })
      .with('privacyToCollect', () => {
        tracker.triggerCustomEvent(FitpetMallEventEnum.ClickSignupInfoDetailTerms, {
          is_necessary: true,
          terms_type: 'personal_info_necessary',
          signup_type: signupType,
        })
      })
      .with('privacyToProvide', () => {
        tracker.triggerCustomEvent(FitpetMallEventEnum.ClickSignupInfoDetailTerms, {
          is_necessary: true,
          terms_type: 'personal_info_share',
          signup_type: signupType,
        })
      })
      .with('geofence', () => {
        tracker.triggerCustomEvent(FitpetMallEventEnum.ClickSignupInfoDetailTerms, {
          is_necessary: true,
          terms_type: 'location',
          signup_type: signupType,
        })
      })
      .with('optionalPrivacyToCollect', () => {
        tracker.triggerCustomEvent(FitpetMallEventEnum.ClickSignupInfoDetailTerms, {
          is_necessary: false,
          terms_type: 'personal_info_optional',
          signup_type: signupType,
        })
      })
      .with('agreePrMarketing', () => {
        tracker.triggerCustomEvent(FitpetMallEventEnum.ClickSignupInfoDetailTerms, {
          is_necessary: false,
          terms_type: 'marketing',
          signup_type: signupType,
        })
      })
      .with('agreeMarketing', () => {
        tracker.triggerCustomEvent(FitpetMallEventEnum.ClickSignupInfoDetailTerms, {
          is_necessary: false,
          terms_type: 'benefit',
          signup_type: signupType,
        })
      })
  }

  return {
    trackCompleteSignUpEvent,
    trackViewSignupInfoEvent,
    trackClickSignupInfoCodeSendEvent,
    trackerViewSignupInfoPopUpEvent,
    trackerClickSignupInfoPopUpEvent,
    trackerClickSignupInfoAllTermsEvent,
    trackerClickInfoDetailTermsEvent,
    trackerSubmitSignupInfoBirthdayEvent,
    trackerSubmitSignupInfoInviteCodeEvent,
    trackSubmitSignupInfoIdEvent,
    trackSubmitSignupInfoPasswordEvent,
    trackSubmitSignupInfoPasswordConfirmEvent,
    trackSubmitSignupInfoNameEvent,
    trackClickSignupInfoGenderEvent,
    convertGenderValueToPrivate,
  }
}

export { useSignupEventTracker }
