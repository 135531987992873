import * as Sentry from '@sentry/nextjs'
import { useQueryClient } from '@tanstack/react-query'
import { useDispatch, useSelector } from 'react-redux'
import { useEstimateCart, useSumCartQuantity } from '@/apis/rest/generated/hooks'
import {
  ErrorResponseDto,
  EstimateCartRequestDtoCartItemsItem,
  EstimateCartResponseDto,
} from '@/apis/rest/generated/types'
import { CartResponseDtoWithChecked } from '@/containers/cart/useCartContents'
import { ErrorType } from '@/plugins/rest-api-v4'
import { doSetCartData } from '@/stores/reduxData'
import { RootState } from '@/stores/store'

export const useGetCartData = () => {
  const dispatch = useDispatch()
  const queryClient = useQueryClient()
  const { queryKey: cartCountQueryKey } = useSumCartQuantity({ query: { enabled: false } })

  const cartStorageData = useSelector((state: RootState) => state.reduxDataReducers.cartData)
  const { mutate: getCartData } = useEstimateCart()

  const cartDataParams =
    cartStorageData?.shippings
      .flatMap((group) => group.orderItems)
      .filter((item) => item.isChecked)
      .map((item) => ({
        checkedCartItemId: item.cartItemId,
        productCouponId: item?.appliedCouponId,
      })) || []

  const initCartData = () => {
    dispatch(doSetCartData(undefined))
    getCartData(
      { data: { cartItems: undefined } },
      {
        onSuccess: (res) => {
          if (res) {
            const cartDataWithChecked = transformCartData(res)
            dispatch(doSetCartData(cartDataWithChecked))
          }
        },
      }
    )
  }

  const getCartDataByChecked = ({
    param,
    onError,
    onSuccess,
    enabledUpdateCartCount = true,
  }: {
    param: EstimateCartRequestDtoCartItemsItem[]
    onError?: (err: ErrorType<ErrorResponseDto>) => void
    onSuccess?: (res: CartResponseDtoWithChecked) => void
    enabledUpdateCartCount?: boolean
  }) => {
    if (!param) return

    getCartData(
      { data: { cartItems: param } },
      {
        onSuccess: (res) => {
          if (res) {
            const cartDataWithChecked = transformCartData(res, param)
            dispatch(doSetCartData(cartDataWithChecked))
            if (enabledUpdateCartCount) {
              queryClient.invalidateQueries(cartCountQueryKey)
            }
            onSuccess?.(cartDataWithChecked)
          }
        },
        onError: (error) => {
          onError?.(error)
          const errorSubCode = error.response?.data?.errors[0]?.subCode
          Sentry.captureMessage(`장바구니 호출 에러 ${errorSubCode}`)
          Sentry.captureException(error)
        },
      }
    )
  }

  const transformCartData = (
    res: EstimateCartResponseDto,
    prevData?: EstimateCartRequestDtoCartItemsItem[]
  ): CartResponseDtoWithChecked => ({
    ...res,
    shippings: res?.shippings.map((shipping) => ({
      ...shipping,
      orderItems: shipping.orderItems.map((orderItem) => ({
        ...orderItem,
        isChecked: prevData
          ? prevData.some((item) => item.checkedCartItemId === orderItem.cartItemId)
          : orderItem?.isChecked,
        appliedCouponId: prevData
          ? prevData.find((item) => item.checkedCartItemId === orderItem.cartItemId)?.productCouponId
          : undefined,
      })),
    })),
  })

  const mergeCartItems = (arr1: EstimateCartRequestDtoCartItemsItem[], arr2: EstimateCartRequestDtoCartItemsItem[]) => {
    const combinedArray = [...arr1, ...arr2]
    const uniqueIds = new Set()
    return combinedArray.filter((item) => {
      if (!uniqueIds.has(item.checkedCartItemId)) {
        uniqueIds.add(item.checkedCartItemId)
        return true
      }
      return false
    })
  }

  const updateCartDataParamsWithNewIds = (newIds: number[]) => {
    const newParams = newIds.map((id) => ({
      checkedCartItemId: id,
      productCouponId: undefined,
    }))
    return cartDataParams ? mergeCartItems(cartDataParams, newParams) : []
  }

  return {
    initCartData,
    getCartDataByChecked,
    updateCartDataParamsWithNewIds,
    cartDataParams,
  }
}
