import { FC, useEffect, useState } from 'react'
import styled from 'styled-components'
import { TextLegacy } from '@/components/common'
import { NewCol } from '@/components/common/layouts/NewCol'
import { NewRow } from '@/components/common/layouts/NewRow'
import { GlobalColorEnum } from '@/constants/global-color.enum'
import { useCustomRouter } from '@/containers/hooks'

type ItemType = {
  id: string
  title: string
}

type LayoutSelectableTabsProps = {
  items: ItemType[]
  onChange: (id: string) => void
}

const LayoutSelectableTabs: FC<LayoutSelectableTabsProps> = ({ items, onChange }) => {
  const [selectedId, setSelectedId] = useState<string>(items[0].id)

  const { query } = useCustomRouter()
  const { tab } = query as { tab?: string }

  useEffect(() => {
    if (tab) {
      _onTabChanged(tab)
    }
  }, [tab])

  const _onTabChanged = (id: string) => {
    setSelectedId(id)
    onChange(id)
  }

  return (
    <StyledRow>
      {items.map(({ id, title }) => {
        const selected = selectedId === id

        return (
          <StatusTabCol key={id} flex="1" selected={selected} className="button" onClick={() => _onTabChanged(id)}>
            <TextLegacy color={selected ? 'default' : 'gray400'} weight={selected ? 'bold' : 'normal'}>
              {title}
            </TextLegacy>
          </StatusTabCol>
        )
      })}
    </StyledRow>
  )
}

const StyledRow = styled(NewRow)`
  background-color: ${(props) => props.theme.color.grayWhite};
`

const StatusTabCol = styled(NewCol)<{ selected: boolean }>`
  padding: 13px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: ${({ selected }) =>
    selected ? `1.5px solid ${GlobalColorEnum.Gray700}` : `1.5px solid ${GlobalColorEnum.Gray70}`};
  background-color: ${(props) => props.theme.color.grayWhite};
`

export default LayoutSelectableTabs
