// eslint-disable-next-line no-console
// @ts-ignore
// 공통 스타일 디자인

console.clear()

/* 공통 영역 디자인 Start */
const consoleFitpetWhiteText = 'color: white;'
const consoleFitpetBlueText = 'color: rgb(0, 80, 255);'
const consoleFitpetOrangeText = 'color: #FF9300;'
const consoleFitpetFontStyle =
  "font-family: 'Pretendard', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;"
/* 공통 영역 디자인 End */

/* 로고 영역 디자인 Start */
const consoleFitpetLogoText = '%cF%c!%cTPET'

const consoleFitpetLogoCommonStyle =
  'margin:16px 0;font-weight:bold;font-size:60px;background-color:rgb(0, 80, 255);' + consoleFitpetFontStyle
const consoleFitpetLogoLeftStyle = consoleFitpetLogoCommonStyle + consoleFitpetWhiteText + 'padding: 32px 0 32px 64px;'
const consoleFitpetLogoCenterStyle = consoleFitpetLogoCommonStyle + consoleFitpetOrangeText + 'padding: 32px 0 32px 0;'
const consoleFitpetLogoRightStyle = consoleFitpetLogoCommonStyle + consoleFitpetWhiteText + 'padding: 32px 64px 32px 0;'
/* 로고 영역 디자인 End */

/* 공고 유도 영역 디자인 Start */
const consoleFitpetWantedText1 = '이걸 열어보는 자네 '
const consoleFitpetWantedText2 = '가 되어보겠나?'
const consoleFitpetWantedFullText = `%c${consoleFitpetWantedText1}%c핏%c펫%c터%c즈%c${consoleFitpetWantedText2}`

const consoleFitpetWantedWhiteTextStyle =
  'font-size:20px;padding: 12px 0 12px 0;' + consoleFitpetWhiteText + consoleFitpetFontStyle
const consoleFitpetWantedOrangeTextStyle =
  'font-size:32px;font-weight:bold;padding: 12px 0 12px 0;' + consoleFitpetOrangeText + consoleFitpetFontStyle
const consoleFitpetWantedBlueTextStyle =
  'font-size:32px;font-weight:bold;padding: 12px 0 12px 0;' + consoleFitpetBlueText + consoleFitpetFontStyle
/* 공고 유도 영역 디자인 End */

/* 공고 영역 디자인 Start */
const consoleFitpetWantedLinkTitle = 'IT 파트 지원  >>>>>>'
const consoleFitpetWantedLinkUrl = 'https://fitpet.co.kr/development'
const consoleFitpetWantedLinkPaddingStyle = 'padding: 8px 0 24px 0;margin-bottom:64px'

const consoleFitpetWantedLinkText = `%c${consoleFitpetWantedLinkTitle}  %c${consoleFitpetWantedLinkUrl}`

const consoleFitpetWantedLinkStyle = [
  'font-size:16px;',
  consoleFitpetWhiteText,
  consoleFitpetFontStyle,
  consoleFitpetWantedLinkPaddingStyle,
].join()

if (process.env.NODE_ENV === 'production') {
  console.log(
    consoleFitpetWantedFullText,
    consoleFitpetWantedWhiteTextStyle,
    consoleFitpetWantedBlueTextStyle,
    consoleFitpetWantedOrangeTextStyle,
    consoleFitpetWantedBlueTextStyle,
    consoleFitpetWantedOrangeTextStyle,
    consoleFitpetWantedWhiteTextStyle
  )
  console.log(
    consoleFitpetLogoText,
    consoleFitpetLogoLeftStyle,
    consoleFitpetLogoCenterStyle,
    consoleFitpetLogoRightStyle
  )
  console.log(consoleFitpetWantedLinkText, consoleFitpetWantedLinkStyle, consoleFitpetWantedLinkStyle)
}

/* 공고 영역 디자인 End */
