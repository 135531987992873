/*
 * 디자인 시스템 아이콘 사이즈
 * https://www.figma.com/file/5VDCLszeSek9nP9MivS7X1/FitPet-Design-System?node-id=280%3A1019
 */
export enum IconSizeEnum {
  Size11 = '1.1rem',
  Size12 = '1.2rem',
  Size16 = '1.6rem',
  Size20 = '2rem',
  Size24 = '2.4rem',
  Size32 = '3.2rem',
  Size48 = '4.8rem',
  Size56 = '5.6rem',
  Size64 = '6.4rem',
}
