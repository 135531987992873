import { FC } from 'react'
import styled, { useTheme } from 'styled-components'
import { FitpetButton } from '@/components/common/buttons/fitpet-button'
import { FitpetIcon } from '@/components/common/icons/fitpet-icon'
import { Typo, TypoVariant } from '@/components/common/typography'
import { CHANNEL_TALK_URL } from '@/constants/legacy/constEnv'
import { useChannelTalk } from '@/containers/hooks/useChannelTalk'
import { openExternalWithBridgePopup } from '@/utils/utilURL'

const MyPageCustomerCenterTab: FC = () => {
  const { color, iconSize } = useTheme()
  const { showMessenger } = useChannelTalk()

  const handleClickInquiry = () => {
    showMessenger()
  }

  const handleClickNotice = () => {
    openExternalWithBridgePopup(CHANNEL_TALK_URL.NOTICE)
  }

  const handleClickCustomerCenter = () => {
    openExternalWithBridgePopup(CHANNEL_TALK_URL.CUSTOMER_CENTER)
  }

  return (
    <StyledContainer>
      <div>
        <StyledTitleContainer>
          <div className="title-left">
            <Typo variant={TypoVariant.Heading6Bold}>고객센터</Typo>
            <Typo variant={TypoVariant.Body1Bold} color={color.gray400}>
              평일 09:00 - 18:00
            </Typo>
          </div>
          <div className="title-right" onClick={handleClickCustomerCenter}>
            <FitpetIcon type="ic-chevron-right" size={iconSize.size24} />
          </div>
        </StyledTitleContainer>
        <StyledButtonContainer>
          <FitpetButton variant="secondary" width="full" onClick={handleClickInquiry}>
            <FitpetButton.ButtonText>
              <Typo variant={TypoVariant.Body1Bold} color={color.blue500}>
                1:1 문의
              </Typo>
            </FitpetButton.ButtonText>
          </FitpetButton>
          <FitpetButton variant="tertiary" width="full" onClick={handleClickNotice}>
            <FitpetButton.ButtonText>
              <Typo variant={TypoVariant.Body1Medium}>공지사항</Typo>
            </FitpetButton.ButtonText>
          </FitpetButton>
        </StyledButtonContainer>
      </div>
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
  padding: 1.6rem;
  background-color: ${(props) => props.theme.color.grayWhite};
`

const StyledTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 1.6rem;

  .title-left {
    display: flex;
    align-items: center;
    gap: 0.4rem;
  }

  .title-right {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
`

const StyledButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
`

export default MyPageCustomerCenterTab
