import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSumCartQuantity } from '@/apis/rest/generated/hooks'
import { PurchaseEstimateProductRequestDtoProductsItem } from '@/apis/rest/generated/types'
import { ProductOption, ProductSummary } from '@/components/common/modals/bottomSheet.type'
import { MAXIMUM_PURCHASE_QUANTITY } from '@/constants/constants'
import { useCartCreateItemsMutation } from '@/containers/hooks/cart/useCartCreateItemsMutation'
import { useCartUpdateItemMutation } from '@/containers/hooks/cart/useCartUpdateItemMutation'
import { usePurchaseEstimate } from '@/containers/hooks/cart/usePurchaseEstimate.hook'
import { doSetCartItemCount } from '@/stores/reduxUI'
import { Base64ConverterUtil } from '@/utils/base64-converter.util'
import { encodeId } from '@/utils/utilApi'

export const useCartBottomSheet = () => {
  const dispatch = useDispatch()
  const { getPurchaseEstimateMutate, isGetPurchaseEstimateMutateLoading } = usePurchaseEstimate()
  const { createCartItems } = useCartCreateItemsMutation()
  const { updateCartItem } = useCartUpdateItemMutation()
  const [purchaseEstimateData, setPurchaseEstimateData] = useState<{
    productOptions: ProductOption[]
    productSummary: ProductSummary | undefined
  }>({
    productOptions: [],
    productSummary: undefined,
  })

  const { refetch: refetchCountCart } = useSumCartQuantity({
    query: {
      enabled: false,
      onSuccess: (data) => {
        dispatch(doSetCartItemCount(data.count))
      },
    },
  })

  const fetchPurchaseEstimate = async ({
    productId,
    productPromotionId,
    products,
  }: {
    productId: number
    productPromotionId?: number
    products?: PurchaseEstimateProductRequestDtoProductsItem[]
  }) => {
    const result = await getPurchaseEstimateMutate({
      productId,
      productPromotionId,
      products,
    })

    if (!result) {
      return
    }

    setPurchaseEstimateData({ productOptions: result.data, productSummary: result.productSummary })
  }

  const addProductOption = ({
    productId,
    productOptionId,
    productPromotionId,
  }: {
    productId: number
    productOptionId: number
    productPromotionId?: number
  }) => {
    // productOptions에 추가
    const selectedProductOption = purchaseEstimateData.productOptions
      .filter((product) => product.isSelected)
      .map((product) => ({
        productOptionId: product.productOptionId,
        quantity: product.quantity,
      }))

    fetchPurchaseEstimate({
      productId: productId,
      productPromotionId: productPromotionId,
      products: [
        ...selectedProductOption,
        {
          productOptionId: productOptionId,
          quantity: 1,
        },
      ],
    })
  }

  const removeProductOption = ({
    productId,
    productOptionId,
    productPromotionId,
  }: {
    productId: number
    productOptionId: number
    productPromotionId?: number
  }) => {
    const selectedProductOption = purchaseEstimateData.productOptions
      .filter((product) => product.isSelected)
      .filter((product) => product.productOptionId !== productOptionId)
      .map((product) => ({
        productOptionId: product.productOptionId,
        quantity: product.quantity,
      }))

    fetchPurchaseEstimate({
      productId: productId,
      productPromotionId: productPromotionId,
      products: selectedProductOption,
    })
  }

  const updateProductOptionQuantity = async ({
    productId,
    productOptionId,
    quantity,
    productPromotionId,
  }: {
    productId: number
    productOptionId: number
    quantity: number
    productPromotionId?: number
  }) => {
    const selectedProductOption = purchaseEstimateData.productOptions
      .filter((product) => product.isSelected)
      .map((product) => ({
        productOptionId: product.productOptionId,
        quantity: product.productOptionId === productOptionId ? quantity : product.quantity,
      }))

    fetchPurchaseEstimate({
      productId: productId,
      productPromotionId: productPromotionId,
      products: selectedProductOption,
    })
  }

  const addToCartItems = async ({
    productPromotionId,
    onSuccess,
  }: {
    productPromotionId?: number
    onSuccess?: (ids: number[]) => void
  }) => {
    const cartItemIds: number[] = []
    const productOptionsToCreateCartItem = purchaseEstimateData.productOptions.filter(
      (productOption) => productOption.isSelected && !productOption.cartItemId
    )
    const productOptionsToUpdateCartItem = purchaseEstimateData.productOptions.filter(
      (productOption) => productOption.isSelected && productOption.cartItemId
    )

    // TODO: productOption, productPromotion 데이터 encodeId 확인(GraphQL, REST API) -> CartButton 처리하는곳 즉, VerticalProductCard에서 GraphQL로 들어올 경우 REST API에 맞게 number로 들어와서 별도 작업 필요 없음
    // TODO: 에러처리는 어떻게 할지 고민 createCartItems, updateCartItem 둘중 1개만 성공하고 1개는 실패할 경우
    if (productOptionsToCreateCartItem.length !== 0) {
      const result = await createCartItems({
        productOptions: productOptionsToCreateCartItem.map((productOption) => ({
          productOption: encodeId('ProductOptionType', productOption.productOptionId),
          quantity: productOption.quantity,
          ...(productPromotionId && { productPromotion: encodeId('ProductPromotionType', productPromotionId) }),
        })),
      })
      if (!result) return false
      const createdCartItemIds = result.createCartItems?.cartItems?.map((cartItem) =>
        Base64ConverterUtil.convertBase64ToNumberId(cartItem.id)
      )
      cartItemIds.push(...(createdCartItemIds ?? []))
    }

    if (productOptionsToUpdateCartItem.length !== 0) {
      const updatePromises = productOptionsToUpdateCartItem.map(async (productOption) => {
        const quantity = productOption.quantity + (productOption.quantityInCart ?? 0)
        const result = await updateCartItem({
          id: encodeId('CartItemType', productOption.cartItemId),
          input: {
            quantity: Math.min(quantity, MAXIMUM_PURCHASE_QUANTITY),
            productOption: encodeId('ProductOptionType', productOption.productOptionId),
          },
        })
        if (!result) {
          throw new Error('Update failed')
        }
        return result
      })

      try {
        await Promise.all(updatePromises).then((result) => {
          const updatedCartItemIds = result.map((cartItem) =>
            Base64ConverterUtil.convertBase64ToNumberId(cartItem.updateCartItem?.cartItem?.id || '')
          )
          cartItemIds.push(...(updatedCartItemIds ?? []))
        })
      } catch (error) {
        return false
      }
    }
    onSuccess?.(cartItemIds)

    // 바텀시트 장바구니 버튼 클릭 시, 장바구니 수량 증가
    refetchCountCart()

    return true
  }
  return {
    productOptions: purchaseEstimateData.productOptions,
    productSummary: purchaseEstimateData.productSummary,
    isGetPurchaseEstimateMutateLoading,
    addProductOption,
    removeProductOption,
    updateProductOptionQuantity,
    addToCartItems,
    fetchPurchaseEstimate,
  }
}
