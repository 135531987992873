import React, { ButtonHTMLAttributes, FC } from 'react'
import styled, { CSSProperties } from 'styled-components'
import { ImageAsset } from '@/components/common'
import { ImageIconBaseProps } from '@/components/common/assets/ImageAssetBase'
import { LinkButtonProps } from '@/components/common/buttons/LinkButton'
import { LinkButton } from '@/components/common/buttons/index'
import TextLegacy, {
  FontColorType,
  FontResponsiveSizeType,
  FontResponsiveWeightType,
  FontSizeType,
  FontWeightType,
} from '@/components/common/texts/TextBase'
import { useUserInfo } from '@/containers/hooks'
import { GlobalTheme } from '@/styles/globalTheme'

const { color } = GlobalTheme

type StyleType = {
  colorTheme?: typeof color[keyof typeof color]
  height?: number
  radius?: number
  border?: string
}

type ImageAssetProps = {
  position: 'left' | 'right'
  margin?: number
} & ImageIconBaseProps

type RectangleButtonLegacyLinkType = Omit<LinkButtonProps, 'noHoverEffect' | 'textUnderline'>

export type RectangleButtonLegacyProps = {
  text?: string
  textStyle?: {
    weight?: FontWeightType | FontResponsiveWeightType
    color?: FontColorType
    size?: FontSizeType | FontResponsiveSizeType
    lineHeight?: number
  }
  onClick?: (event: any) => void
  disabled?: boolean
  style?: CSSProperties
  form?: string
  shouldLogin?: boolean
  link?: RectangleButtonLegacyLinkType
  loading?: boolean
  imageAssetProps?: ImageAssetProps
} & ButtonHTMLAttributes<HTMLButtonElement> &
  StyleType

/**
 * @deprecated
 */
const RectangleButtonLegacy: FC<RectangleButtonLegacyProps> = ({
  textStyle = {
    weight: 'bold',
    size: 16,
    lineHeight: 1.6,
  },
  type = 'button',
  text,
  loading,
  disabled,
  onClick,
  link,
  shouldLogin = false,
  colorTheme = color.blue500,
  height = 48,
  radius = 8,
  imageAssetProps,
  ...restProps
}) => {
  const { checkLogin } = useUserInfo.useAuthenticatedRoute()

  const textColor = disabled ? color.grayWhite : colorTheme === color.blue500 ? color.grayWhite : color.blue500
  const { position: imagePosition, margin } = imageAssetProps || {}

  const ImageIcon = () => {
    const isPositionLeft = imagePosition === 'left'
    const marginLeft = isPositionLeft ? undefined : margin
    const marginRight = isPositionLeft ? margin : undefined
    return imageAssetProps ? (
      <ImageAsset style={{ marginLeft, marginRight }} {...(imageAssetProps as ImageAssetProps)} />
    ) : null
  }

  const _onClick = (e: any) => {
    if (shouldLogin) {
      e.preventDefault()
    }

    const hasLoggedIn = link || checkLogin(shouldLogin)
    if (!hasLoggedIn) return

    if (onClick) {
      onClick(e)
    }
  }

  const Button = () => (
    <StyledButton
      className="button"
      disabled={disabled || loading}
      colorTheme={colorTheme}
      height={height}
      radius={radius}
      onClick={_onClick}
      {...restProps}
    >
      {imagePosition === 'left' && <ImageIcon />}
      <TextLegacy
        lineHeight={textStyle.lineHeight}
        weight={textStyle.weight}
        size={textStyle.size}
        color={textStyle.color || textColor}
      >
        {text}
      </TextLegacy>
      {imagePosition === 'right' && <ImageIcon />}
    </StyledButton>
  )

  if (link && !disabled) {
    return (
      <LinkButton {...link} shouldLogin={shouldLogin}>
        <Button />
      </LinkButton>
    )
  }

  return <Button />
}

const StyledButton = styled.button<StyleType>`
  width: 100%;
  cursor: pointer;
  height: ${({ height }) => `${height}px`};
  //border: none !important;
  background-color: ${({ theme: { color }, colorTheme, disabled }) => {
    if (disabled) {
      return color.gray200
    }
    if (colorTheme === color.blue500) {
      return color.blue500
    }
    return color.grayWhite
  }};
  border: ${({ disabled, border, theme }) => {
    if (disabled) {
      return `1px solid ${theme.color.gray200}`
    }
    if (border) {
      return border
    }
    return `1px solid ${color.blue500}`
  }};
  border-radius: ${({ radius }) => `${radius}px`};
  min-width: 112px;

  &:disabled {
    background-color: ${color.gray200};
  }

  &:hover,
  &:active,
  &:focus,
  &[disabled]:hover {
    background-color: ${({ theme: { color }, colorTheme, disabled }) => {
      if (disabled) {
        return color.gray200
      }
      if (colorTheme === color.blue500) {
        return color.blue500
      }
      return color.grayWhite
    }};
  }
`
export default RectangleButtonLegacy
