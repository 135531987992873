import { OrderCancelTypeEnum } from '@/constants/order-cancel-type.enum'
import { OrderStatusTypeEnum } from '@/constants/order-status-type.enum'
import { PaymentMethodKoreanEnum } from '@/constants/payment-method-korean.enum'
import { PaymentMethodTypeEnum } from '@/constants/payment-method-type.enum'

export const FITPET = {
  INDEX: 'https://fitpet.co.kr/',
  TEL: '02-6339-1800',
  FAX: '02-6009-9068',
  HELP_EMAIL: 'help@fitpet.co.kr',
  CONTACT_EMAIL: 'ads@fitpet.co.kr',
  CARE_EMAIL: 'care@fitpet.co.kr',
  SELLER_QNA_EMAIL: 'md@fitpet.co.kr',
  MARKETING_EMAIL: 'marketing_ask@fitpet.co.kr',
  PR_EMAIL: 'pr@fitpet.co.kr',
  HELP_JUNGLEBOOK_EMAIL: 'help@junglebook.co.kr',
  ADDRESS: '서울특별시 강남구 테헤란로 107길 6 2층',
  PRIVACY_OFFICER: '장재훈',
  PRIVACY_OFFICER_POSITION: '커머스개발실장',
  LOCATION_INFORMATION_OFFICER: '장재훈',
  LOCATION_INFORMATION_POSITION: 'IT 프로덕트 본부',
  LOCATION_INFORMATION_OFFICER_TEL: '<02-6339-1800>, <contact@fitpet.co.kr>',
  COMPANY_NAME: '핏펫',
  CORPORATION_COMPANY: '주식회사 핏펫(Fitpet)',
  CEO: '고정욱',
  BUSINESS_REGISTRATION_NUMBER: '543-87-00755',
  COMMUNICATION_SALES_BUSINESS_REPORT_NUMBER: '2018-서울강남-01774',
  PURCHASE_SAFETY_SERVICE: '토스페이먼츠 구매안전서비스 이용 확인증',
}

export const PERIOD_LIST = [
  { days: -183, text: '최근 6개월' },
  { days: -365, text: '최근 1년' },
  { days: -1, text: '전체' },
]

export const POLICY = {
  MAX_PURCHASE_QUANTITY_AT_ONCE: 20,
}

export const isOrderStatusOneOfCancel = (status?: OrderStatusTypeEnum) => {
  return isOrderStatusCancel(status) || isOrderStatusReturn(status) || isOrderStatusExchange(status)
}

export const isOrderStatusOneOfCancelConfirmed = (status?: OrderStatusTypeEnum) => {
  switch (status) {
    case OrderStatusTypeEnum.Canceled:
    case OrderStatusTypeEnum.ReturnConfirmed:
    case OrderStatusTypeEnum.ExchangeConfirmed:
      return true
  }

  return false
}

export const getOrderCancelTypeByOrderStatus = (status: OrderStatusTypeEnum): OrderCancelTypeEnum => {
  if (isOrderStatusReturn(status)) return OrderCancelTypeEnum.Return
  if (isOrderStatusExchange(status)) return OrderCancelTypeEnum.Exchange
  if (isOrderStatusCancel(status)) return OrderCancelTypeEnum.Cancel
  return OrderCancelTypeEnum.All
}

export const isOrderStatusCancel = (status?: OrderStatusTypeEnum) => {
  switch (status) {
    case OrderStatusTypeEnum.CancelRequested:
    case OrderStatusTypeEnum.Canceled:
      return true
  }
  return false
}

export const isOrderStatusReturn = (status?: OrderStatusTypeEnum) => {
  switch (status) {
    case OrderStatusTypeEnum.ReturnRequested:
    case OrderStatusTypeEnum.ReturnUnderReturn:
    case OrderStatusTypeEnum.ReturnConfirmed:
    case OrderStatusTypeEnum.ReturnReturned:
      return true
  }
  return false
}

export const isOrderStatusExchange = (status?: OrderStatusTypeEnum) => {
  switch (status) {
    case OrderStatusTypeEnum.ExchangeRequested:
    case OrderStatusTypeEnum.ExchangeUnderReturn:
    case OrderStatusTypeEnum.ExchangeConfirmed:
      return true
  }
  return false
}

export const isOrderStatusExchangeFinished = (status?: OrderStatusTypeEnum) => {
  return OrderStatusTypeEnum.ExchangeConfirmed === status
}

export const PaymentMethodTypeKorean: { [key in PaymentMethodTypeEnum]: PaymentMethodKoreanEnum } = {
  [PaymentMethodTypeEnum.CreditCard]: PaymentMethodKoreanEnum.CreditCard,
  [PaymentMethodTypeEnum.NaverPay]: PaymentMethodKoreanEnum.NaverPay,
  [PaymentMethodTypeEnum.KakaoPay]: PaymentMethodKoreanEnum.KakaoPay,
  [PaymentMethodTypeEnum.TossPay]: PaymentMethodKoreanEnum.TossPay,
  [PaymentMethodTypeEnum.Payco]: PaymentMethodKoreanEnum.Payco,
  [PaymentMethodTypeEnum.KbPay]: PaymentMethodKoreanEnum.KbPay,
  [PaymentMethodTypeEnum.VirtualAccount]: PaymentMethodKoreanEnum.VirtualAccount,
  [PaymentMethodTypeEnum.Bank]: PaymentMethodKoreanEnum.Bank,
  [PaymentMethodTypeEnum.Mobile]: PaymentMethodKoreanEnum.Mobile,
  [PaymentMethodTypeEnum.ZeroPrice]: PaymentMethodKoreanEnum.ZeroPrice,
  [PaymentMethodTypeEnum.Mileage]: PaymentMethodKoreanEnum.ZeroPrice,
  [PaymentMethodTypeEnum.Point]: PaymentMethodKoreanEnum.Point,
}

export const CancelTypeText: {
  [key in OrderCancelTypeEnum]: string
} = {
  [OrderCancelTypeEnum.Cancel]: '취소',
  [OrderCancelTypeEnum.Return]: '반품',
  [OrderCancelTypeEnum.Exchange]: '교환',
  [OrderCancelTypeEnum.All]: '',
}

export const SHIPPING_REQUEST_LIST = [
  { id: 1, message: '문 앞에 놓아주세요.' },
  { id: 2, message: '경비실에 맡겨주세요.' },
  { id: 3, message: '택배함에 넣어주세요.' },
  { id: 4, message: '부재시 연락주세요.' },
  { id: 5, message: '직접 입력' },
]

export const SHIPPING_REQUEST_DIRECT_INPUT_ID = 5

export const FIXED_GID = {
  SELLER: {
    FITPET: 'U2VsbGVyVHlwZTox',
  },
  CATEGORY: {
    DOG: {
      FEED: 'Q2F0ZWdvcnlUeXBlOjEwMTA=',
      SUPPLIES: 'Q2F0ZWdvcnlUeXBlOjEwMTI=',
      SNACK: 'Q2F0ZWdvcnlUeXBlOjEwMTE=',
      HEALTHCARE: 'Q2F0ZWdvcnlUeXBlOjEwMTM=',
    },
    CAT: {
      FEED: 'Q2F0ZWdvcnlUeXBlOjEwMjA=',
      SUPPLIES: 'Q2F0ZWdvcnlUeXBlOjEwMjI=',
      SNACK: 'Q2F0ZWdvcnlUeXBlOjEwMjE=',
      HEALTHCARE: 'Q2F0ZWdvcnlUeXBlOjEwMjM=', //TODO: fix
    },
    ALL: {
      FEED: '',
      SUPPLIES: '',
      SNACK: '',
      HEALTHCARE: '',
    },
  },
  DISPLAY_COLLECTION: {
    DOG: {
      SNACK: 'RGlzcGxheUNvbGxlY3Rpb25UeXBlOjMxNw==',
      FEED: 'RGlzcGxheUNvbGxlY3Rpb25UeXBlOjMxOQ==',
      SUPPLIES: 'RGlzcGxheUNvbGxlY3Rpb25UeXBlOjMyMQ==',
    },
    CAT: {
      SNACK: 'RGlzcGxheUNvbGxlY3Rpb25UeXBlOjMxOA==',
      FEED: 'RGlzcGxheUNvbGxlY3Rpb25UeXBlOjMyMA==',
      SUPPLIES: 'RGlzcGxheUNvbGxlY3Rpb25UeXBlOjMyMg==',
    },
    ALL: {
      SNACK: '',
      FEED: '',
      SUPPLIES: '',
    },
  },
  AD: {
    DOG: {
      MD_PICK: 'QWRUeXBlOjkw',
      OUTLET: 'QWRUeXBlOjQ=',
    },
    CAT: {
      MD_PICK: 'QWRUeXBlOjkx',
      OUTLET: 'QWRUeXBlOjg=',
    },
    ALL: {
      MD_PICK: '',
      OUTLET: '',
    },
  },
}

export const ARIA_LABEL = {
  SEARCH_BAR_INPUT_TEXT: 'search_bar_input_text',
}

export const MembershipFamilyMaxSize = 3
