import { useDispatch } from 'react-redux'
import { UserContainer } from '@/containers/users/UserContainer'
import { doSetBottomSheetData, doSetProductChoiceBottomSheetData } from '@/stores/reduxData'
import { doSetToggleModalLogin } from '@/stores/reduxUI'
import { BottomSheetEventTrackerType } from '@/types/bottom-sheet-event-tracker.type'
import { ProductTypeDefinedByFrontend } from '@/types/product-type-defined-by.frontend'

export const useOpenProductOptionChoiceBottomSheet = () => {
  const { me } = UserContainer.useContainer()
  const dispatch = useDispatch()
  const checkAuth = () => {
    if (!me) {
      dispatch(doSetToggleModalLogin(true))
      return false
    }
    return true
  }

  const openProductChoiceBottomSheet = () => {
    dispatch(
      doSetBottomSheetData({
        isProductChoiceVisible: true,
      })
    )
  }

  const setProductChoiceBottomSheetData = ({
    product,
    trackerData,
    callback,
  }: {
    product: ProductTypeDefinedByFrontend
    trackerData?: BottomSheetEventTrackerType
    callback?: () => void
  }) => {
    dispatch(
      doSetProductChoiceBottomSheetData({
        reduxProductInProductChoiceBottomSheet: product,
        reduxTrackerDataInProductChoiceBottomSheet: trackerData,
        reduxCallbackInProductChoiceBottomSheet: callback,
      })
    )
  }

  const openProductOptionChoiceBottomSheet = ({
    product,
    trackerData,
    callback,
  }: {
    product: ProductTypeDefinedByFrontend
    trackerData?: BottomSheetEventTrackerType
    callback?: (ids?: number[]) => void
  }) => {
    if (!checkAuth()) {
      return
    }
    setProductChoiceBottomSheetData({ product, trackerData, callback })
    openProductChoiceBottomSheet()
  }

  return {
    openProductOptionChoiceBottomSheet,
  }
}
