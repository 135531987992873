import { gql } from '@apollo/client'

const BRAND_FRAGMENTS = {
  get brandMinimum() {
    return gql`
      fragment brandMinimum on BrandType {
        id
        name
        listImage
        status {
          value
        }
      }
    `
  },
  get searchFilterBrand() {
    return gql`
      fragment searchFilterBrand on SearchBrandConnection {
        edges {
          node {
            id
            name
            listImage
          }
        }
      }
    `
  },
}

export default BRAND_FRAGMENTS
