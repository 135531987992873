import { FitpetMallEventEnum } from '@/constants/fitpet-mall-event.enum'
import { useTracker } from '@/containers/contexts/EventTrackingProvider'
import { ReferrerUtil } from '@/utils/referrer.util'

interface TrackerProperties {
  search_engine?: string
  search_query?: string
  visit_url?: string
}

export const useAppEventTracker = () => {
  const tracker = useTracker()
  const referrer = ReferrerUtil.getReferrer()
  const trackSearchEngineInflowEvent = () => {
    const referrerDomain = ReferrerUtil.getReferrerDomain(referrer)
    const searchQuery = ReferrerUtil.getSearchQuery(referrer)
    const trackerProperties: TrackerProperties = {}
    if (!searchQuery && !referrerDomain) return
    trackerProperties['search_engine'] = referrerDomain
    trackerProperties['search_query'] = searchQuery
    trackerProperties['visit_url'] = window.location.href

    tracker.triggerCustomEvent(FitpetMallEventEnum.SearchEngineInflow, trackerProperties)
  }

  return {
    trackSearchEngineInflowEvent,
  }
}
