import moment from 'moment'
import {
  isOrderStatusCancel,
  isOrderStatusOneOfCancelConfirmed,
  isOrderStatusReturn,
} from '@/constants/legacy/constData'
import { OrderProductInfoType } from '@/constants/legacy/constType'
import { OrderStatusTypeEnum } from '@/constants/order-status-type.enum'
import { OrderStatusEnum } from '@/constants/order-status.enum'
import { IModelOrderItemDefault } from '@/containers/models/base/defaultModelInterfaces'
import { ModelOrderItemDefault } from '@/containers/models/base/defaultModels'
import { IModelBrand } from '@/containers/models/modelBrand'
import { IModelCoupon } from '@/containers/models/modelCoupon'
import { IModelProductImage } from '@/containers/models/modelProductImage'
import { IModelProductOption } from '@/containers/models/modelProductOption'
import { IModelProductPromotion } from '@/containers/models/modelProductPromotion'
import { IModelReturnInfo } from '@/containers/models/modelReturnInfo'
import { IModelReturnInfoImage } from '@/containers/models/modelReturnInfoImage'
import { IModelShipping } from '@/containers/models/modelShipping'
import { IModelTransaction } from '@/containers/models/modelTransaction'
import { ActionPowerOrderType, TransactionTransactionStatus } from '@/graphql/generated/schemas'
import { encodeId } from '@/utils/utilApi'
import { round } from '@/utils/utilCommon'
import { date2string, isNowBefore } from '@/utils/utilDate'
import { currencyText } from '@/utils/utilNumber'

export interface IModelOrderItem extends IModelOrderItemDefault {
  productOption: IModelProductOption
  brand: IModelBrand
  returnInfo: IModelReturnInfo
  productPromotion: IModelProductPromotion
  productImage: IModelProductImage
  shipping: IModelShipping
  actionPowerOrder: ActionPowerOrderType
  approvedTransaction: IModelTransaction
  membershipMileage: number
  membershipOrderConfirmMileage: number
  membershipOrderConfirmDiffMileageRate: number
  mileage: number
  shippingCompanyId?: string
  shippingNumbers: string[]
  availableCoupons: IModelCoupon[]
  getOrderProductInfo: () => OrderProductInfoType
  canWriteReview: () => boolean
  exchangeNewOrderItem: IModelOrderItem
  getOrderStatusText: () => OrderStatusEnum
  getRewardPhotoReviewMileage: () => number
  getRewardTextReviewMileage: () => number
  getMaxRewardMileage: () => number
  getRewardedOrderConfirmMileage: () => number
  getCancelGuideMessageText: () => string | undefined
  getExchangeShippingFeeText: () => string | undefined
  getCancelReasonText: () => string
  getCancelRequestedAtText: () => string
  getCancelFinishedAtText: () => string
  getCancelImages: () => IModelReturnInfoImage[] | undefined
  getCustomerPrice: () => number
  getWriteReviewRemainingDays: () => number
  getWriteReviewRemainingDaysText: () => string | undefined
  isStatusCancelConfirmed: () => boolean
}

export default class ModelOrderItem extends ModelOrderItemDefault {
  shippingCompanyId?: string = undefined
  shippingNumbers: string[] = []
  exchangeNewOrderItem: IModelOrderItem = {} as IModelOrderItem
  membershipOrderConfirmDiffMileageRate?: number

  constructor(data: IModelOrderItem) {
    super(data)
    this.exchangeNewOrderItem = data.exchangeNewOrderItem
    this.shippingCompanyId = this.getShippingCompanyId()
    this.shippingNumbers = this.getShippingNumbers()
    this.membershipOrderConfirmDiffMileageRate = data.membershipOrderConfirmDiffMileageRate
  }

  _getRewardOrderConfirmMileage = () => {
    return this.orderStatus === OrderStatusTypeEnum.Confirmed ? 0 : this.orderConfirmMileage
  }

  getRewardedOrderConfirmMileage = () => {
    return this.orderStatus === OrderStatusTypeEnum.Confirmed ? this.orderConfirmMileage : 0
  }

  getRewardTextReviewMileage = () => {
    if (this.hasUserReview) {
      return 0
    }
    return this.reviewMileage + this._getRewardOrderConfirmMileage()
  }

  getRewardPhotoReviewMileage = () => {
    if (this.hasUserReview) {
      return 0
    }
    return this.imageReviewMileage + this._getRewardOrderConfirmMileage()
  }

  getMaxRewardMileage = () => {
    return Math.max(this.getRewardTextReviewMileage(), this.getRewardPhotoReviewMileage())
  }

  getCustomerPrice = () => {
    if (this.productPromotionCustomerPrice > 0) {
      return this.productPromotionCustomerPrice
    }

    return this.customerPrice
  }

  _isOrderStatusCancel = () => {
    const _status = this.orderStatus as OrderStatusTypeEnum
    return isOrderStatusCancel(_status)
  }

  getCancelGuideMessageText = () => {
    if (this._isOrderStatusCancel()) {
      return this.cancelGuideMessage
    }

    return this.returnInfo.guideMessage
  }

  getExchangeShippingFeeText = () => {
    const _status = this.orderStatus as OrderStatusTypeEnum
    if (isOrderStatusCancel(_status) || isOrderStatusReturn(_status)) {
      return undefined
    }

    const returnShippingFee = this.returnInfo.returnShippingFee
    return currencyText(returnShippingFee)
  }

  getCancelImages = () => {
    if (this._isOrderStatusCancel()) {
      return undefined
    }

    const { returnInfoReturnInfoImages: returnImages } = this.returnInfo
    if (returnImages.length === 0) {
      return undefined
    }

    return returnImages
  }

  getCancelReasonText = () => {
    if (this._isOrderStatusCancel()) {
      return this.cancelRequestMessage
    }

    const { requestType, requestMessage } = this.returnInfo
    if (requestType === 'ETC') {
      return `기타\n${requestMessage}`
    }

    return requestMessage
  }

  getCancelRequestedAtText = () => {
    if (this._isOrderStatusCancel()) {
      return date2string(this.cancelRequestedAt!)
    }

    return date2string(this.returnInfo.createdAt)
  }

  getCancelFinishedAtText = () => {
    if (this._isOrderStatusCancel()) {
      return date2string(this.canceledAt!)
    }

    return date2string(this.returnInfo.returnedAt)
  }

  getOrderProductInfo = (): OrderProductInfoType => {
    return {
      mileage: this.mileage,
      productId: this.productOption.productId,
      productCode: this.productOption.productId,
      optionId: this.productOption.id,
      thumbnail: this.productImage.thumbnail,
      image: this.productImage.image,
      productName: this.productName,
      productNumber: this.productOption.productId,
      optionName: this.productOption.name,
      quantity: this.quantity,
      // customerPrice: this.orderStatus === ORDER_STATUS_TYPE.CANCELED ? this.getCustomerPrice() : this.amount,
      customerPrice: this.getCustomerPrice(),
      seller: {
        id: this.seller.id,
        name: this.seller.shopName,
      },
    }
  }

  canWriteReview = (): boolean => {
    if (this.hasUserReview) {
      return false
    }
    // if(this.orderStatus !== ORDER_STATUS_TYPE.SHIPPED){
    //   return false
    // }
    if (!(this.orderStatus === OrderStatusTypeEnum.Confirmed || this.orderStatus === OrderStatusTypeEnum.Shipped)) {
      return false
    }
    return isNowBefore(moment(this.reviewWritableDatetime))
  }

  getWriteReviewRemainingDays = (): number => {
    const now = moment()
    const _expiredAt = moment(this.reviewWritableDatetime).utc()
    const duration = moment.duration(_expiredAt.diff(now))
    // @ts-ignore
    return round(duration.asDays(), 0)
  }

  getWriteReviewRemainingDaysText = (): string | undefined => {
    const daysRemaining = this.getWriteReviewRemainingDays()
    if (daysRemaining > 29 || 0 > daysRemaining) {
      return undefined
    }

    if (daysRemaining === 0) {
      return '작성 기한 오늘까지'
    }
    return `작성 기한 ${daysRemaining + 1}일 남음`
  }

  getOrderStatusText = (): OrderStatusEnum => {
    if (
      this.approvedTransaction.payMethodType === 'VBANK' &&
      this.approvedTransaction.transactionStatus === TransactionTransactionStatus.UnpaidCanceled
    ) {
      return OrderStatusEnum.UnpaidCanceled
    }

    switch (this.orderStatus) {
      case OrderStatusTypeEnum.UnconfirmedPayment:
        return OrderStatusEnum.UnconfirmedPayment
      case OrderStatusTypeEnum.NewOrder:
        return OrderStatusEnum.NewOrder
      case OrderStatusTypeEnum.ReadyForShipping:
        return OrderStatusEnum.ReadyForShipping
      case OrderStatusTypeEnum.Shipping:
        return OrderStatusEnum.Shipping
      case OrderStatusTypeEnum.Shipped:
        return OrderStatusEnum.Shipped
      case OrderStatusTypeEnum.CancelRequested:
        return OrderStatusEnum.CancelRequested
      case OrderStatusTypeEnum.Canceled:
        return OrderStatusEnum.Canceled
      case OrderStatusTypeEnum.Confirmed:
        return OrderStatusEnum.Confirmed
      case OrderStatusTypeEnum.ReturnRequested:
        return OrderStatusEnum.ReturnRequested
      case OrderStatusTypeEnum.ReturnUnderReturn:
        return OrderStatusEnum.ReturnUnderReturn
      case OrderStatusTypeEnum.ReturnReturned:
        return OrderStatusEnum.ReturnReturned
      case OrderStatusTypeEnum.ReturnConfirmed:
        return OrderStatusEnum.ReturnConfirmed
      case OrderStatusTypeEnum.ExchangeRequested:
        return OrderStatusEnum.ExchangeRequested
      case OrderStatusTypeEnum.ExchangeUnderReturn:
        return OrderStatusEnum.ExchangeUnderReturn
      case OrderStatusTypeEnum.ExchangeConfirmed:
        return OrderStatusEnum.ExchangeConfirmed
      default:
        return OrderStatusEnum.UnconfirmedPayment
    }
  }

  isStatusCancelConfirmed = (): boolean => {
    // @ts-ignore
    return isOrderStatusOneOfCancelConfirmed(this.orderStatus)
  }

  private getShippingCompanyId = () => {
    if (this.shipping?.shippingCompanyAndNumbers) {
      const shippingCompanyAndNumbers = JSON.parse(this.shipping.shippingCompanyAndNumbers)
      if (shippingCompanyAndNumbers[this._id]) {
        const { shippingCompanyId } = shippingCompanyAndNumbers[this._id]
        return encodeId('ShippingCompanyType', shippingCompanyId)
      }
    }
    return this.shipping?.shippingCompany?.id
  }

  private getShippingNumbers = () => {
    if (this.shipping?.shippingCompanyAndNumbers) {
      const shippingCompanyAndNumbers = JSON.parse(this.shipping.shippingCompanyAndNumbers)
      if (shippingCompanyAndNumbers[this._id]) {
        const { shippingNumbers } = shippingCompanyAndNumbers[this._id]
        return shippingNumbers || []
      }
    }
    return []
  }
}
