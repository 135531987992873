import styled, { useTheme } from 'styled-components'
import { ImageAsset } from '@/components/common'
import FlatIcon from '@/components/common/icons/FlatIcon'
import { Typo, TypoVariant } from '@/components/common/typography'
import { GlobalColorEnum } from '@/constants/global-color.enum'
import { IconNameEnum } from '@/constants/icon-name.enum'

interface ProductChoiceSelectedItemProps {
  productOptionId: number
  name: string
  quantity: number
  isDisabledSubtractButton: boolean
  isDisabledAddButton: boolean
  price: number
  salePrice: number
  isSingleOption?: boolean
  purchaseSalePrice?: number
  purchaseSalePriceAfterCoupon?: number
  hasApplicableProductCoupon?: boolean
  onClickRemoveButton?: ({ productOptionId }: { productOptionId: number }) => void
  onUpdateProductOptionQuantity: ({ productOptionId, quantity }: { productOptionId: number; quantity: number }) => void
}

export const ProductChoiceSelectedItem = ({
  productOptionId,
  name,
  quantity,
  isDisabledSubtractButton,
  isDisabledAddButton,
  salePrice,
  isSingleOption,
  price,
  purchaseSalePrice,
  purchaseSalePriceAfterCoupon,
  hasApplicableProductCoupon,
  onClickRemoveButton,
  onUpdateProductOptionQuantity,
}: ProductChoiceSelectedItemProps) => {
  const { color } = useTheme()

  const handleClickRemoveButton = ({ productOptionId }: { productOptionId: number }) => {
    onClickRemoveButton?.({ productOptionId })
  }

  const handleClickSubtractButton = ({ productOptionId, quantity }: { productOptionId: number; quantity: number }) => {
    onUpdateProductOptionQuantity({
      productOptionId,
      quantity: quantity - 1,
    })
  }

  const handleClickAddButton = ({ productOptionId, quantity }: { productOptionId: number; quantity: number }) => {
    onUpdateProductOptionQuantity({
      productOptionId,
      quantity: quantity + 1,
    })
  }

  return (
    <ProductChoiceSelectedItemWrapper>
      <div className="product-choice-selected-item__top">
        <div className="product-choice-selected-item__name">
          <Typo
            variant={TypoVariant.Body3Regular}
            color={color.gray900}
            style={{
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 2,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              wordBreak: 'break-all',
            }}
          >
            {name}
          </Typo>
        </div>
        {!isSingleOption && (
          <button
            type="button"
            onClick={() => handleClickRemoveButton({ productOptionId })}
            className="product-choice-selected-item__remove-button"
          >
            <FlatIcon type={IconNameEnum.IcClose} color={color.gray300} size="1.6rem" />
          </button>
        )}
      </div>
      <div className="product-choice-selected-item__bottom">
        <div className="product-choice-selected-item__quantity-control">
          <button
            type="button"
            disabled={isDisabledSubtractButton}
            onClick={() => handleClickSubtractButton({ productOptionId, quantity })}
            className="product-choice-selected-item__quantity-control-subtract"
          >
            <ImageAsset
              style={{
                verticalAlign: 'top',
              }}
              name={isDisabledSubtractButton ? 'ic_remove_gray200_20' : 'ic_remove_gray_20'}
              size={20}
              svg
            />
          </button>
          <div className="product-choice-selected-item__quantity-control-value">
            <Typo variant={TypoVariant.Body2Regular} color={color.gray900}>
              {quantity}
            </Typo>
          </div>
          <button
            type="button"
            onClick={() => handleClickAddButton({ productOptionId, quantity })}
            className="product-choice-selected-item__quantity-control-add"
          >
            <ImageAsset
              style={{
                verticalAlign: 'top',
              }}
              name={isDisabledAddButton ? 'ic_plus_gray200_20' : 'ic_plus_gray_20'}
              size={20}
              svg
            />
          </button>
        </div>
        <div className="product-choice-selected-item__price-info">
          {hasApplicableProductCoupon ? (
            <div className="product-choice-selected-item__price">
              <Typo
                variant={TypoVariant.Body5Regular}
                color={color.gray300}
                style={{
                  textDecoration: 'line-through',
                }}
              >
                {(purchaseSalePrice || 0).toLocaleString('ko-KR')}원
              </Typo>
            </div>
          ) : (
            price > 0 && (
              <Typo
                variant={TypoVariant.Body5Regular}
                color={color.gray300}
                style={{
                  textDecoration: 'line-through',
                }}
              >
                {((price || 0) * quantity).toLocaleString('ko-KR')}원
              </Typo>
            )
          )}
          <div className="product-choice-selected-item__discount-price">
            {hasApplicableProductCoupon ? (
              <>
                <Typo variant={TypoVariant.Body4Regular} color={color.gray500}>
                  쿠폰적용시
                </Typo>
                <Typo variant={TypoVariant.Heading6SemiBold} color={color.gray900}>
                  {purchaseSalePriceAfterCoupon?.toLocaleString('ko-KR')}원
                </Typo>
              </>
            ) : (
              <>
                <Typo variant={TypoVariant.Heading6SemiBold} color={color.gray900}>
                  {(salePrice * quantity).toLocaleString('ko-KR')}원
                </Typo>
              </>
            )}
          </div>
        </div>
      </div>
    </ProductChoiceSelectedItemWrapper>
  )
}

const ProductChoiceSelectedItemWrapper = styled.div`
  margin: 0;
  .product-choice-selected-item {
    background-color: ${({
      theme: {
        color: { gray50 },
      },
    }) => gray50};
    border-radius: 0.8rem;
    &:not(:first-child) {
      margin-top: 1rem;
    }
    &__top {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-direction: row;
    }
    &__bottom {
      margin-top: 1.4rem;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      flex-direction: row;
    }
    &__remove-button {
      cursor: pointer;
      margin-left: 2.7rem;
      padding: 0.2rem;
      border: none;
      background-color: transparent;
    }

    &__quantity-control {
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: ${({
        theme: {
          color: { grayWhite },
        },
      }) => grayWhite};
      border-radius: 0.8rem;
      border: 0.1rem solid
        ${({
          theme: {
            color: { gray200 },
          },
        }) => gray200};

      &-subtract,
      &-add {
        padding: 0.8rem;
        cursor: pointer;
        border: none;
        background-color: transparent;
      }
      &-value {
        font-variant-numeric: tabular-nums;
        padding: 0.8rem 0;
        width: 3.4rem;
        text-align: center;
        border-left: 0.1rem solid ${GlobalColorEnum.Gray200};
        border-right: 0.1rem solid ${GlobalColorEnum.Gray200};
      }
    }
    &__price-info {
      text-align: right;
    }
    &__discount-price {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.2rem;
    }
  }
`
