import { useState, useRef, Dispatch, SetStateAction } from 'react'
import { PageSizeEnum } from '@/constants/page-size.enum'
import { PageInfoInputProps } from '@/containers/gqls/query_variables'
import { decodeId, encodeId } from '@/utils/utilApi'

type PageInfoVariableReturnType = {
  pageNumber: number
  pageSizeNumber: number
  // setPageListFilter: (props: setPageListFilterProps) => void;
  setPageNumber: Dispatch<SetStateAction<number>>
  getTablePageInfoVariables: GetTablePageInfoVariables
  getTableCursor: GetTableCursor
  getNextPage: (endCursor: string) => number
}

type GetTablePageInfoVariables = (page: number, pageSize: number) => { pageInfo: PageInfoInputProps }

type GetTableCursor = (page: number, pageSize?: number) => string

export default function usePageInfo(defaultPageSize?: number): PageInfoVariableReturnType {
  const [pageNumber, setPageNumber] = useState<number>(1)
  // const [pageSizeNumber, setPageSizeNumber] = useState<number>(defaultPageSize || 8)
  const pageSizeRef = useRef<number>(8)

  const getTableCursor: GetTableCursor = (page, pageSize?) => {
    const _pageSize: number = pageSize || pageSizeRef.current

    const prevRowIndex: number = page === 1 || !page ? -1 : (page - 1) * _pageSize - 1
    const encodedConnection: string = encodeId('arrayconnection', prevRowIndex.toString())

    return encodedConnection
  }

  const getTablePageInfoVariables: GetTablePageInfoVariables = (page, pageSize?) => {
    const first = pageSize || pageSizeRef.current
    return {
      pageInfo: {
        first,
        after: getTableCursor(page, pageSize),
      },
    }
  }

  const getNextPage = (endCursor: string): number => {
    // 상품 조회 후 상품리스트로 이동 시 페이지를 기억하기 위해 data 내부에 저장된 startCursor를 이용하여 페이지네이션 처리를 하기 위해 작성돈 코드
    // 최초로 fetchMore가 실행될땐 startCursor가 0이여서 pageSize만큼을 더해주어야 함.
    const lastProductIndex = parseInt(decodeId(endCursor).id, 10) + 1
    const currentPage = lastProductIndex / pageSizeNumber
    return currentPage + 1
  }

  const pageSizeNumber = defaultPageSize || PageSizeEnum.PageSize8
  return {
    pageNumber,
    setPageNumber,
    pageSizeNumber,
    getTableCursor,
    getTablePageInfoVariables,
    getNextPage,
  }
}
