import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { GlobalColorEnum } from '@/constants/global-color.enum'
import { SCREEN_MEDIA_QUERY } from '@/constants/legacy/constLayout'
import useCustomRouter from '@/containers/hooks/useCustomRouter'
import { RootState } from '@/stores/store'

const HeaderPageTitle = () => {
  const { getPageTitle, isMainTab, isNoneHeaderTitle, isNoneHeaderTitleH1Tag } = useCustomRouter()
  const reduxHeaderTitle = useSelector((state: RootState) => state.reduxUIReducers.headerTitle)
  const [headerTitle, setHeaderTitle] = useState<string | undefined>('')

  useEffect(() => {
    if (!getPageTitle() && reduxHeaderTitle) {
      setHeaderTitle(reduxHeaderTitle)
    }
  }, [reduxHeaderTitle])

  if (isMainTab()) {
    return null
  }

  if (!isNoneHeaderTitle() && (getPageTitle() || headerTitle)) {
    const title = getPageTitle() || headerTitle

    return (
      <StyledRow justify="center">
        <PageTitleCol as={!title || isNoneHeaderTitleH1Tag() ? 'div' : 'h1'}>
          {getPageTitle() ?? headerTitle}
        </PageTitleCol>
      </StyledRow>
    )
  }

  return null
}

const StyledRow = styled.div<{
  justify?: 'flex-start' | 'flex-end' | 'center' | 'space-around' | 'space-between'
  align?: 'flex-start' | 'center' | 'flex-end'
}>`
  display: flex;
  justify-content: ${(props) => props.justify ?? 'flex-start'};
  align-items: ${(props) => props.align ?? 'flex-start'};
  flex-wrap: nowrap;
`

const PageTitleCol = styled.h1`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  word-break: break-all;
  text-align: center;
  margin: auto;
  color: ${(props) => props.theme.color.gray900};
  font-weight: bold;
  font-size: 1.8rem;

  @media ${SCREEN_MEDIA_QUERY.xs} {
    font-size: 1.6rem;
  }
`

export default HeaderPageTitle
