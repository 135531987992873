// 1. 마이페이지 : mypage
// 2. 상세페이지 : productdetail
// 3. 주문완료페이지 : ordercomplete
// 4. 쇼핑 홈 메인페이지/메인페이지 배너 : main
// 5.건강수첩 : play_recommend_carenote
// 6. 카테고리 페이지 : category
// 7. 쿠폰페이지(쿠폰함) : coupon
// 8. 건강탭(플레이) 메인 : playmain
// 9. 커뮤니티 메인 : play_community_main
// 10. 커뮤니티 검색 결과 : play_community_searchresult
// 11. 핏펫몰 메인홈 > 하단섹션 : gnb_home
// 12. 핏펫몰 메인홈 > 검색 페이지 : search
// 13. 관심상품 페이지 : favorite

export const getLocationByPathname = (pathname: string) => {
  if (!pathname) {
    return ''
  }
  const locationMap: Record<string, string> = {
    '/mypage': 'mypage',
    '/products': 'productdetail',
    '/sections': 'sections',
    '/category': 'category',
    '/search': 'search',
    '/mypage/favorites': 'favorite',
  }

  for (const key in locationMap) {
    if (pathname.startsWith(key)) {
      return locationMap[key]
    }
  }
  return ''
}
