import { useRecommendBundledProductsQuery } from '@/graphql/generated/hooks'
import { GraphQLProductType } from '@/utils/product/product-card.util'

const useRecommendBundleProductsByGen = ({ productId }: { productId?: string }) => {
  const { data, loading: isRecommendBundleProductsLoading } = useRecommendBundledProductsQuery({
    variables: {
      id: productId ?? '',
    },
    skip: !productId,
  })

  const recommendBundledProducts: GraphQLProductType[] =
    data?.recommendBundledProducts?.edges.map(({ node }) => node as unknown as GraphQLProductType) || []

  return {
    recommendBundledProducts,
    isRecommendBundleProductsLoading,
  }
}

export { useRecommendBundleProductsByGen }
