import { InputHTMLAttributes } from 'react'
import styled from 'styled-components'
import Text from '../typeface/Text'

interface RadioButtonProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string
  id: string
  disabled?: boolean
  variant: 'small' | 'medium' | 'large'
}

type Variant = {
  width: string
  height: string
  innerWidth: string
  innerHeight: string
}

type Variants = {
  small: Variant
  medium: Variant
  large: Variant
}

const variants: Variants = {
  small: {
    width: '1.6rem',
    height: '1.6rem',
    innerWidth: '0.6rem',
    innerHeight: '0.6rem',
  },
  medium: {
    width: '2rem',
    height: '2rem',
    innerWidth: '0.8rem',
    innerHeight: '0.8rem',
  },
  large: {
    width: '2.4rem',
    height: '2.4rem',
    innerWidth: '1rem',
    innerHeight: '1rem',
  },
} as const

const RadioButton = ({ label = '', id = '', disabled = false, variant = 'small', ...rest }: RadioButtonProps) => {
  return (
    <Wrapper>
      <Radio type="radio" id={id} aria-describedby={id} disabled={disabled} variant={variant} {...rest} />
      {label && (
        <Label htmlFor={id} disabled={disabled} variant={variant}>
          <Text.Body1>{label}</Text.Body1>
        </Label>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  gap: 0.4rem;
  align-items: center;
`
const Radio = styled.input<{ variant: keyof Variants }>`
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  width: ${({ variant }) => variants[variant].width};
  height: ${({ variant }) => variants[variant].width};
  border: 1px solid ${({ theme }) => theme.color.gray200};
  border-radius: 50%;
  background-color: white;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    width: ${({ variant }) => variants[variant].innerWidth};
    height: ${({ variant }) => variants[variant].innerHeight};
  }
  &:hover {
    border-color: ${({ theme }) => theme.color.blue500};
    background-color: ${({ theme }) => theme.color.blue500};
    &::after {
      background-color: white;
    }
  }
  &:checked {
    border-color: ${({ theme }) => theme.color.blue500};
    background-color: ${({ theme }) => theme.color.blue500};
    &::after {
      background-color: white;
    }
  }
  &:disabled {
    cursor: not-allowed;
    border-color: ${({ theme }) => theme.color.gray100};
    background-color: ${({ theme }) => theme.color.gray50};
    &:hover {
      ::after {
        background-color: ${({ theme }) => theme.color.gray50};
      }
    }
    &:checked {
      border-color: ${({ theme }) => theme.color.blue100};
      background-color: ${({ theme }) => theme.color.blue100};
    }
  }
`
// TODO: 추후 Label 작업 필요
const Label = styled.label<{ disabled: boolean; variant: keyof Variants }>``

export { RadioButton, Radio }
