import { S3_HOST_URL } from '@/constants/legacy/constEnv'
import { IModelReviewImageDefault } from '@/containers/models/base/defaultModelInterfaces'
import { ModelReviewImageDefault } from '@/containers/models/base/defaultModels'

export interface IModelReviewImage extends IModelReviewImageDefault {
  getImageUrl: () => string
}

export default class ModelReviewImage extends ModelReviewImageDefault {
  getImageUrl = () => {
    return `${S3_HOST_URL}/${this.image}`
  }
}
