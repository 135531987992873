import { ROUTE_POLICY_BASE } from '@/constants/legacy/constRoutes'

export const GlobalConst = {
  GoMarketRnAndroidUrl: `/${ROUTE_POLICY_BASE}/go-market-rn-android?hideHeader=true`,
  /**
   * meta title, description, ogImage 등은 이벤트 어드민에 값이 추가되어 운영측면에서 관리되어야 합니다.
   * @link: https://app.asana.com/0/0/1203765237593544/f
   */
  EventMeta: {
    'gnb_fitpetonly-dog': {
      title: '강아지 only - 핏펫몰',
      metaDescription: '오직 핏펫에서만 만나 볼 수 있는 강아지를 위한 다양한 상품 목록입니다.',
    },
    'gnb_fitpetonly-cat': {
      title: '고양이 only - 핏펫몰',
      metaDescription: '오직 핏펫에서만 만나 볼 수 있는 고양이를 위한 다양한 상품 목록입니다.',
    },
    'sale-dog': {
      title: '강아지 아울렛 - 핏펫몰',
      metaDescription: '강아지들을 위한 특별한 가격 핏펫에서 만나보세요',
    },
    'sale-cat': {
      title: '고양이 아울렛 - 핏펫몰',
      metaDescription: '고양이들을 위한 특별한 가격 핏펫에서 만나보세요',
    },
    'New-dog': {
      title: '강아지 신상백서 - 핏펫몰',
      metaDescription: '강아지들을 위한 신제품을 만나보세요',
    },
    'New-cat': {
      title: '고양이 신상백서 - 핏펫몰',
      metaDescription: '고양이들을 위한 신제품을 만나보세요',
    },
    'point-dog': {
      title: 'NEW 핏펫 베네핏 | 강아지 - 핏펫몰',
      metaDescription:
        ' 더 새로워진 강아지를 위한 다양한 핏펫 베네핏을 한눈에! NEW 핏펫 베네핏에서 쏟아지는 다양한 혜택을 지금 바로 핏펫과 함께 시작해보세요!',
    },
    'point-cat': {
      title: 'NEW 핏펫 베네핏 | 고양이 - 핏펫몰',
      metaDescription:
        ' 더 새로워진 고양이를 위한 다양한 핏펫 베네핏을 한눈에! NEW 핏펫 베네핏에서 쏟아지는 다양한 혜택을 지금 바로 핏펫과 함께 시작해보세요!',
    },
    'app-only': {
      title: '이벤트 | APP 회원 쿠폰 - 핏펫몰',
      metaDescription: ' 오직 핏펫의 고객님만을 위한 특별한 혜택들을 만나보세요.',
    },
    default: {
      title: '이벤트 - 핏펫몰',
      metaDescription: '오직 핏펫의 고객님만을 위한 특별한 혜택들을 만나보세요.',
    },
  },
}
