import styled, { useTheme } from 'styled-components'
import { ImageAsset } from '@/components/common'
import { ProductOption, ProductSummary } from '@/components/common/modals/bottomSheet.type'
import ToolTip from '@/components/common/toolTips/ToolTip'
import { Typo, TypoVariant } from '@/components/common/typography'
import { TooltipArrowPositionEnum } from '@/constants/tooltip-arrow-position.enum'
import { UserContainer } from '@/containers/users/UserContainer'

interface ProductChoiceSelectBottomProps {
  productSummary?: ProductSummary
  productOptions: ProductOption[]
  onClickAddToCart: () => void
}

export const ProductChoiceSelectBottom = ({
  productSummary,
  productOptions,
  onClickAddToCart,
}: ProductChoiceSelectBottomProps) => {
  const { color } = useTheme()
  const { isMembership } = UserContainer.useContainer()
  const { totalProductPrice, totalProductPriceAfterCoupon, totalExpectedMileage } = productSummary || {}
  const noOptionSelected = productOptions.every((productOption) => !productOption.isSelected)
  const textOptions = {
    membership: {
      text: '핏펫플러스 최대',
      tooltipText: '구매 확정 및 리뷰 작성 시 지급되며\n할인쿠폰 적용에 따라 달라질 수 있습니다',
    },
    regularMember: {
      text: '구매 시 최대',
      tooltipText: '구매 확정 및 리뷰 작성 시 지급되며\n할인쿠폰 적용에 따라 달라질 수 있습니다 ',
    },
  }
  const { text, tooltipText } = isMembership ? textOptions.membership : textOptions.regularMember

  const handleClickAddToCart = () => {
    onClickAddToCart()
  }

  return (
    <ProductChoiceSelectBottomWrapper className="product-choice-select-bottom">
      <div className="product-choice-select-bottom__price-info">
        <div className="product-choice-select-bottom__price-info-top">
          <Typo variant={TypoVariant.Body3Medium} color={color.gray500}>
            총 상품 금액
          </Typo>
          <Typo variant={TypoVariant.Heading5} color={color.gray900}>
            {totalProductPrice?.toLocaleString('ko-KR')}원
          </Typo>
        </div>
        {productSummary?.hasApplicableProductCoupon && !noOptionSelected && (
          <div className="product-choice-select-bottom__price-info-top product-choice-select-bottom__price-info-top--after-coupon">
            <Typo variant={TypoVariant.Body3Medium} color={color.gray500}>
              쿠폰 적용시 금액
            </Typo>
            <Typo variant={TypoVariant.Heading5} color={color.red500}>
              {totalProductPriceAfterCoupon?.toLocaleString('ko-KR')}원
            </Typo>
          </div>
        )}

        <div className="product-choice-select-bottom__price-info-bottom">
          <Typo variant={TypoVariant.Body4Regular} color={color.gray700}>
            {text}
          </Typo>
          <Typo variant={TypoVariant.Body4Bold} color={color.gray900}>
            {`${totalExpectedMileage?.toLocaleString('ko-KR')}원 적립`}
          </Typo>
          <ToolTip
            tooltipData={tooltipText}
            tooltipStyle={{ top: '-6.5rem', right: '0' }}
            arrowPosition={TooltipArrowPositionEnum.BottomRight}
          >
            <div className="product-choice-select-bottom__tooltip-icon">
              <ImageAsset name="ic_question_gray300_16" size={16} svg />
            </div>
          </ToolTip>
        </div>
      </div>
      <div className="product-choice-select-bottom__add-to-cart-wrapper">
        <button
          type="button"
          className="product-choice-select-bottom__add-to-cart"
          onClick={handleClickAddToCart}
          disabled={noOptionSelected}
        >
          <Typo variant={TypoVariant.Body1Bold} color={color.grayWhite}>
            장바구니 담기
          </Typo>
        </button>
      </div>
    </ProductChoiceSelectBottomWrapper>
  )
}

const ProductChoiceSelectBottomWrapper = styled.div`
  .product-choice-select-bottom {
    &__price-info {
      padding: 1.2rem 1.6rem 0.6rem;
      &-top {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }
      &-bottom {
        margin-top: 0.6rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        gap: 0.4rem;
      }
    }
    &__price-info-top--after-coupon {
      margin-top: 0.6rem;
    }
    &__tooltip-icon {
      display: flex;
    }
    &__add-to-cart-wrapper {
      padding: 1.6rem;
    }
    &__add-to-cart {
      cursor: pointer;
      width: 100%;
      padding: 1.3rem 1.6rem;
      border: none;
      border-radius: 0.8rem;
      background-color: ${({
        theme: {
          color: { blue500 },
        },
      }) => blue500};
      &:disabled {
        cursor: default;
        pointer-events: none;
        background-color: ${({
          theme: {
            color: { gray200 },
          },
        }) => gray200};
      }
    }
  }
`
