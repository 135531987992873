import { gql } from '@apollo/client'

const DEFAULT_FRAGMENTS: { [index: string]: any } = {
  get pageInfo() {
    return gql`
      fragment pageInfo on PageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    `
  },
  get banner() {
    return gql`
      fragment banner on BannerType {
        displayOrder
        petType
        createdAt
        updatedAt
        id
        isAlways
        startedAt
        endedAt
        name
        bannerType
        isPluralDisplay
        allowDisplay
        displayDevice
        bannerTypeInfo
        style
      }
    `
  },
  get cancelReason() {
    return gql`
      fragment cancelReason on CancelReasonType {
        requestType
        requestMessage
        isUserVisible
      }
    `
  },
  get cartItem() {
    return gql`
      fragment cartItem on CartItemType {
        createdAt
        updatedAt
        id
        quantity
      }
    `
  },
  get coupon() {
    return gql`
      fragment coupon on CouponType {
        createdAt
        updatedAt
        id
        code
        registeredAt
        startedAt
        expiredAt
        usedAt
        message
        usedStatus
        issuedFrom
        issueCount
        isMembershipCoupon
      }
    `
  },
  get customerTemplateAddress() {
    return gql`
      fragment customerTemplateAddress on CustomerTemplateAddressType {
        id
        postNumber
        basicAddress
        detailedAddress
        isDefault
        templateName
        name
        mobileNumber
        message
      }
    `
  },
  get device() {
    return gql`
      fragment device on DeviceType {
        id
        uuid
        token
        refreshToken
        pushToken
        platformType
        osVersion
        modelNumber
        appVersion
        lastLoggedAt
        keepSignIn
        expiredAt
      }
    `
  },
  get mileageHistory() {
    return gql`
      fragment mileageHistory on MileageHistoryType {
        createdAt
        updatedAt
        id
        mileageType
        message
        subMessage
        beforeMileage
        afterMileage
        mileageDiff
        expiredAt
      }
    `
  },
  get order() {
    return gql`
      fragment order on OrderType {
        createdAt
        updatedAt
        id
        orderNumber
        originalAmount
        productDiscountAmount
        productPromotionDiscountAmount
        couponDiscountAmount
        orderCouponDiscountAmount
        mileageDiscountAmount
        shippingFees
        shippingExtraFees
        shippingDiscountAmount
        shippingMileageDiscountAmount
        shippingCouponDiscountAmount
        couponInfo
        productPromotionCustomerAmount
        amount
        shippingAmount
        mileage
        imageReviewMileage
        reviewMileage
        orderConfirmMileage
        totalItemQuantity
        canPartialCancel
        beforeOrderedAt
        isFirstPurchase
      }
    `
  },
  get productOption() {
    return gql`
      fragment productOption on ProductOptionType {
        id
        series
        price
        discountPrice
        isDefault
        isUse
        isDelete
        name
        customerPrice
        difference
        productPromotionCustomerPrice
        productPromotionDiscountPrice
        productOptionPrice
        stockQuantity
        purchasePrice
        productId
        product {
          shippingPreset {
            id
          }
        }
      }
    `
  },
  get productPromotion() {
    return gql`
      fragment productPromotion on ProductPromotionType {
        startedAt
        endedAt
        createdAt
        updatedAt
        id
        productPromotionType
        name
        description
        discountPrice
        fitpetFeeRate
        mileageRewardRate
        reviewMileageRewardRate
        confirmMileageRewardRate
        isDelete
        minOrderAmount
        maxOrderCount
        productPromotionCustomerPrice
        discountRate
        defaultFitpetFeeRate
        boughtCount
      }
    `
  },
  get productQna() {
    return gql`
      fragment productQna on ProductQnaType {
        createdAt
        updatedAt
        id
        questionBody
        answerBody
        answeredAt
        isSecret
        answeredUserType
        zendeskTicketId
        isAnswered
        isAdmin
        username
      }
    `
  },
  get returnReason() {
    return gql`
      fragment returnReason on ReturnReasonType {
        requestType
        requestMessage
        responsibility
        isUserVisible
      }
    `
  },
  get review() {
    return gql`
      fragment review on ReviewType {
        createdAt
        updatedAt
        id
        checkAdminAt
        productOptionName
        reviewBody
        answerBody
        answeredAt
        score
        weight
        isDelete
        isPhotoReview
        bestAt
        bestSeries
        mileage
        isAnswered
        isFirstReviewProduct
        username
        isLiked
        mileageText
        likedCount
      }
    `
  },
  get shippingPreset() {
    return gql`
      fragment shippingPreset on ShippingPresetType {
        id
        name
        shippingFee
        freeShippingBaseAmount
        returnFee
        isPackage
        isPrepayment
        guide
        notice
        applyRemoteAreaFee
      }
    `
  },
  get shipping() {
    return gql`
      fragment shipping on ShippingType {
        id
        fee
        extraFee
        discountPrice
        couponDiscountPrice
        mileageDiscountPrice
        shippingType
        shippingNumbers
        sentAt
        receivedAt
        couponInfo
        shippingTypeText
        isTrackable
        untrackedReason
        amount
        sumFee
        isFitpet
        isRefundable
        shippingCompanyAndNumbers
      }
    `
  },
  get userCertification() {
    return gql`
      fragment userCertification on UserCertificationType {
        createdAt
        updatedAt
        id
        mobileNumber
        email
        confirmedAt
        certType
        expiredAt
      }
    `
  },
  get userInfo() {
    return gql`
      fragment userInfo on UserInfoType {
        id
        email
        userStatus
      }
    `
  },
  get user() {
    return gql`
      fragment user on UserType {
        createdAt
        updatedAt
        accountNumber
        accountHolderName
        bankCode
        bankName
        id
        lastLogin
        isSuperuser
        firstName
        lastName
        email
        isStaff
        isActive
        dateJoined
        username
        name
        inactivatedAt
        activatedAt
        leftAt
        mobileNumber
        gradeUpdatedAt
        invitationCode
        inviteCount
        birthday
        gender
        updateApprovedAt
        passwordUpdatedAt
        godoId
        leftReason
        leftMessage
        agreeNightMarketing
        agreeNightMarketingAt
        agreeMarketing
        agreeMarketingAt
        agreeThirdPartyProvision
        agreeThirdPartyProvisionAt
        userStatus
        signInCount
        signInFailCount
        lastSignInFailedAt
        joinedPlatform
        displayPetType
        boughtDeal100
        sellerCount
        approvalSellerCount
        isSeller
        isInfluencer
        orderCount
        ongoingOrderCount
        reviewCount
        writableReviewCount
        cartItemCount
        cartItemQuantity
        orderAmount
        mileagePoint
        couponCount
        userStatusText
        hasEmailAccount
        lastOrderedAt
        unusedMileage
        likedCount
        canBuyDeal100
      }
    `
  },
}

export default DEFAULT_FRAGMENTS
