import { useDispatch } from 'react-redux'
import { useCreateCartItemsMutation } from '@/graphql/generated/hooks'
import { CreateCartItemsInput } from '@/graphql/generated/schemas'
import { doSetGlobalToastContent } from '@/stores/reduxUI'
import { ApiErrorUtils } from '@/utils/utilApiErrors'

export const useCartCreateItemsMutation = () => {
  const [createCartItemsMutation] = useCreateCartItemsMutation()
  const dispatch = useDispatch()

  const createCartItems = async (input: CreateCartItemsInput) => {
    try {
      const { data, errors } = await createCartItemsMutation({
        variables: {
          input,
        },
      })
      if (errors) {
        // backend에서 넘어온 에러
        const errorInfo = ApiErrorUtils.getError(errors[0])
        throw new Error(errorInfo.message)
      }
      return data
    } catch (error) {
      // network error
      const errorInfo = ApiErrorUtils.getError(error)
      dispatch(
        doSetGlobalToastContent({
          content: `${errorInfo.message}\n오류가 지속된다면 고객센터에 문의해 주세요`,
          duration: 5,
        })
      )
      return null
    }
  }
  return { createCartItems }
}
