import { PlatformDetailEnum } from '@/constants/platform-detail.enum'
import { InAppBridge } from '@/utils/bridge'
import { FitpetMallEventEnum } from '@/utils/event-tracker/events'
import { BaseEventHandler } from '@/utils/event-tracker/handlers/baseEventHandler'
import { isInApp } from '@/utils/utilCommon'
import type AmplitudeJs from 'amplitude-js'

declare global {
  interface Window {
    location: Location
  }
}

// eslint-disable-next-line global-require
const Amplitude = process.browser && require('amplitude-js')

export class AmplitudeHandler extends BaseEventHandler {
  private amplitudeAPIKey: string = process.env.AMPLITUDE_API_KEY || ''
  loggerName = 'LOG_AMPLITUDE'
  private appBridge: InAppBridge = new InAppBridge()
  private amplitudeClient?: AmplitudeJs.AmplitudeClient = process.browser ? Amplitude.getInstance() : undefined
  initialize = () => {
    this.logger.info('key: ', this.amplitudeAPIKey)
    const amplitudeOpt: AmplitudeJs.Config = {
      includeReferrer: true,
      includeUtm: true,
      includeGclid: true,
      saveParamsReferrerOncePerSession: true,
    }
    this.amplitudeClient?.init(this.amplitudeAPIKey, undefined, amplitudeOpt)
    this.appBridge.init()
    this.logger.info('Amplitude client is initialized.')
  }
  setUserId = (userID: string) => {
    this.logger.info(userID)
    this.amplitudeClient?.setUserId(userID)
  }
  setUserProperties = (properties: any) => {
    const identify: AmplitudeJs.Identify = process.browser ? new Amplitude.Identify() : undefined
    Object.keys(properties).forEach((key) => {
      identify?.set(key, properties[key])
    })
    this.amplitudeClient?.identify(identify)
  }
  sendPageviewEvent = (url: string) => {
    this.logger.info('please use event-event-tracker.sendEvent() for amplitude pageview events.', url)
  }
  // 수익 추적용 트래커
  sendRevenueEvent = (
    productId: string,
    price: number,
    quantity: number,
    revenueType: string,
    eventProperties: Record<string, any>
  ) => {
    const revenue = new Amplitude.Revenue()
      .setProductId(productId) // 제품 아이디
      .setPrice(price) // 제품 개당 가격
      .setQuantity(quantity) // 제품 구매 수량
      .setRevenueType(revenueType) // 수익 유형 (e.g. tax, refund, income)
      .setEventProperties(eventProperties) // 이벤트 속성 객체
    this.amplitudeClient?.logRevenueV2(revenue)
  }

  sendEvent = (event: FitpetMallEventEnum, platform: PlatformDetailEnum, properties: Record<string, any>) => {
    this.logger.info(`sendEvent :: ${event} :: ${properties}`)
    this.setInitProperties(properties)
    const eventProperties = {
      ...properties,
      ...{ platform_detail: platform },
    }
    this.logger.info(this.amplitudeClient, event, eventProperties)
    if (isInApp()) {
      this.logger.info(`send isInApp Event ${event}`)
      const data = { eventName: event, eventParam: properties }
      this.appBridge?.amplitudeEvent(data)
      if (FitpetMallEventEnum.CompletePurchase === event) {
        // TODO typescript 오류 수정 필요
        // @ts-ignore
        revenueProductoptData(eventProperties).forEach((revenue) => {
          this.appBridge?.amplitudeRevenueEvent({
            eventName: 'revenue',
            eventParam: {
              ...revenue,
              revenueType: 'income',
            },
          })
        })
      }
    } else {
      this.amplitudeClient?.logEvent(event, eventProperties)
      if (FitpetMallEventEnum.CompletePurchase === event) {
        // TODO typescript 오류 수정 필요
        // @ts-ignore
        revenueProductoptData(eventProperties).forEach((revenue) => {
          this.sendRevenueEvent(revenue.product_id, revenue.price, revenue.quantity, 'income', revenue)
        })
      }
    }
  }

  /**
   * @deprecated: 구 버전의 triggerCustomEvent에 사용되는 이벤트 트래커 지정 방식입니다.
   */
  sendCustomEvent = (event: FitpetMallEventEnum, properties: Record<string, any>, platform: PlatformDetailEnum) => {
    this.sendEvent(event, platform, properties)
  }
}

// Todo : revenueProductOptionData 리팩토링 필요
const revenueProductoptData = (properties: Record<string, any>) => {
  // 전체 금액 계산
  let total_product_amount = 0
  // TODO typescript 오류 수정 필요
  // @ts-ignore
  properties.products.forEach((product) =>
    // TODO typescript 오류 수정 필요
    // @ts-ignore
    product.product_options.forEach((product_option) => {
      total_product_amount = total_product_amount + product_option.price * product_option.product_option_quantity
    })
  )
  // TODO typescript 오류 수정 필요
  // @ts-ignore
  properties.products.forEach((product) =>
    // TODO typescript 오류 수정 필요
    // @ts-ignore
    product.product_options.forEach((product_option) => {
      product_option.price_per = (product_option.price * product_option.product_option_quantity) / total_product_amount
      product_option.price_cal =
        (product_option.price * product_option.product_option_quantity -
          Math.round(properties.mileage_discount_amount) * product_option.price_per -
          properties.coupon_discount_amount * product_option.price_per) /
          product_option.product_option_quantity || 0 // 0/0 NaN 처리
    })
  )
  // TODO typescript 오류 수정 필요
  // @ts-ignore
  return properties.products.reduce((pre, product) => {
    pre.push(
      // TODO typescript 오류 수정 필요
      // @ts-ignore
      ...product.product_options.map((product_option) => ({
        // 기본 정보
        order_id: properties.order_id,
        service: properties.service,
        platform_detail: properties.platform_detail,
        event_version: properties.event_version,
        pet_type_mall: properties.pet_type_mall,
        coupon_discount_amount: properties.coupon_discount_amount,
        mileage_discount_amount: Math.round(properties.mileage_discount_amount),
        eventsale_id: 0, // 기획전번호
        eventsale_name: '', // 기획전명
        eventsale_slug: '', //기획전url"
        // 제품 정보
        product_id: product.product_id,
        // TODO typescript 오류 수정 필요
        // @ts-ignore
        category_ids: product.categories.map((c) => c.category_id),
        // TODO typescript 오류 수정 필요
        // @ts-ignore
        category_names: product.categories.map((c) => c.category_name),
        product_name: product.product_name,
        // 옵션 가격
        price: product_option.price_cal,
        quantity: product_option.product_option_quantity,
        product_option_id: product_option.product_option_id,
        product_option_name: product_option.product_option_name,
        // 계산검증용 데이터
        cal_price: product_option.price, // 개당가격
        cal_total_product_amount: total_product_amount,
        cal_total_product_discount_amount: properties.total_product_discount_amount,
        cal_price_per: product_option.price_per,
      }))
    )
    return pre
  }, [])
}
