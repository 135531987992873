import { FitpetMallEventEnum } from '@/constants/fitpet-mall-event.enum'
import { ProductLocation, ProductLocationTrackerValue } from '@/constants/product-location.const'
import { useTracker } from '@/containers/contexts/EventTrackingProvider'
import { ProductTypeDefinedByFrontend } from '@/types/product-type-defined-by.frontend'
import { convertToKoreanValueFromSupplyType } from '@/utils/event-tracker/events'

export const useProductListEventTracker = () => {
  const tracker = useTracker()

  /**
   * 상품 리스트에서 "담기" 버튼을 클릭했을 때
   */
  const trackClickListStartPurchaseButtonEvent = ({
    product,
    addLocation,
    sectionName,
    sectionIndex,
    listIndex,
    isNudge,
    location,
    searchKeyword,
    searchLocation,
    listType,
    originProductId,
    originProductName,
    categoryName,
    categoryTypeName,
  }: {
    product: ProductTypeDefinedByFrontend
    sectionName?: string
    sectionIndex?: number
    listIndex?: number
    isNudge?: boolean
    location?: string
    addLocation?: ProductLocation
    searchKeyword?: string
    searchLocation?: string
    listType?: string
    originProductId?: number
    originProductName?: string
    categoryName?: string
    categoryTypeName?: string
  }) => {
    const properties = {
      add_location: addLocation ? ProductLocationTrackerValue[addLocation] : undefined,
      list_index: listIndex,
      product_id: product.id,
      product_name: product.name,
      supply_type: convertToKoreanValueFromSupplyType(product.supplyType),
      brand_id: product.brand?.id,
      brand_name: product.brand.name,
      category_ids: product.categories?.map((category) => category.id),
      category_names: product.categories?.map((category) => category.name),
      product_price: product.price,
      product_purchase_price: product.salePrice,
      product_discount_rate: product.discountRate,
      product_review_total_count: product.reviewCount,
      section_index: sectionIndex,
      section_name: sectionName,
      search_keyword: searchKeyword,
      search_location: searchLocation,
      isNudge,
      location,
      cum_sales_skuquantity: product.salesQuantity,
      max_mileage: product.expectedMileage,
      list_type: listType,
      originProductId,
      originProductName,
      thumbnail_webp: product.hasAnimatedMainImage,
      category_name: categoryName,
      category_type_name: categoryTypeName,
    }
    tracker.triggerCustomEvent(FitpetMallEventEnum.ClickListStartPurchaseButton, properties)
  }

  return {
    trackClickListStartPurchaseButtonEvent,
  }
}
