import { useEffect } from 'react'
import { useMutation } from '@apollo/client'
import { useDispatch } from 'react-redux'
import { OnApiCompletedType, OnApiErrorType } from '@/constants/legacy/constType'
import { MUTATION_KEY2GQLS } from '@/containers/gqls'
import { doSetToggleVisibleLoader } from '@/stores/reduxUI'
import { getResultFromData } from '@/utils/utilApi'
import * as utilApi from '@/utils/utilApi'

const useApiMutation = (gqlKey: string, onAPISuccess?: OnApiCompletedType, onAPIError?: OnApiErrorType) => {
  const dispatch = useDispatch()

  const gqlMutation = MUTATION_KEY2GQLS[gqlKey]
  const [mutation, { loading, error, data: _data, called }] = useMutation(gqlMutation, {
    onCompleted(data) {
      if (!onAPISuccess) return
      const hasDataWithoutError = utilApi.maybe(() => !!Object.keys(data).length, false) && called && !error
      if (!hasDataWithoutError) return
      const result: any = getResultFromData(data[gqlKey])
      onAPISuccess(result)
    },
    onError(_error) {
      if (!onAPIError) return
      onAPIError(_error)
    },
  })

  useEffect(() => {
    if (hideLoader(gqlKey)) {
      return
    }

    dispatch(doSetToggleVisibleLoader(loading))
  }, [loading])

  if (error && onAPIError) {
    onAPIError(error)
  }

  return [mutation, { loading, error, data: _data, called }] as any
}

const hideLoader = (gqlKey: string) => {
  if ('updateCartItem' === gqlKey) {
    return true
  }

  return false
}

export default useApiMutation
