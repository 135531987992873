import { gql } from '@apollo/client'
import DEFAULT_FRAGMENTS from '@/containers/gqls/base/defaultFragments'
import COUPON_FRAGMENTS from '@/containers/gqls/promotions/coupons/fragments'

export const COUPON_MUTATION_GQLS = {
  DOWNLOAD_EVENT_COUPONS: gql`
    mutation downloadEventCoupons($input: DownloadEventCouponsInput!) {
      downloadEventCoupons(input: $input) {
        coupons {
          id
          couponGroup {
            name
          }
        }
      }
    }
  `,
  REGISTER_COUPON: gql`
    mutation registerCoupon($input: RegisterCouponInput!) {
      registerCoupon(input: $input) {
        coupon {
          ...coupon
          couponGroup {
            ...couponGroupInCouponList
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.coupon}
    ${COUPON_FRAGMENTS.couponGroupInCouponList}
  `,
}
