import { useEffect, useRef } from 'react'
import { useRouter } from 'next/router'
import ROUTES from '@/constants/legacy/constRoutes'
import { NaverScriptConst } from '@/constants/naver-script.const'

const htmlString = `if(!wcs_add) var wcs_add = {}; wcs_add["wa"] = "${NaverScriptConst.accountId}"; if (!_nasa) var _nasa={}; if(window.wcs) { wcs.inflow(); wcs_do(); }`
const notExecutePath = [ROUTES.ORDER.FINISH, ROUTES.CART]

/**
 * 네이버 공통 스크립트 실행
 * 장바구니, 결제완료 페이지를 제외한 모든 페이지에서 실행
 */
const NaverCommonPixel = () => {
  const router = useRouter()
  const divRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (notExecutePath.includes(router.pathname)) return

    const script = document.createElement('script')
    script.id = NaverScriptConst.commonScriptId
    script.type = 'text/javascript'
    script.innerHTML = htmlString
    script.defer = true
    divRef.current?.appendChild(script)

    return () => {
      const script = document.getElementById(NaverScriptConst.commonScriptId)
      script?.remove()
    }
  }, [router.pathname])

  return <div ref={divRef} />
}

export { NaverCommonPixel }
