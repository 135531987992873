// eslint-disable-next-line import/no-extraneous-dependencies
import { Rule, NamePath, StoreValue } from 'rc-field-form/lib/interface'
import { FieldKeysEnum } from '@/constants/field-keys.enum'
import * as utilFormValidators from '@/utils/utilFormValidators'

const PW_REGEX_ENG = '[a-zA-Z]'
const PW_REGEX_NUM = '[0-9]'
const PW_REGEX_SPC = '[!@#$%^&*()_+=`~-]'
const PW_REGEX_NAME = '^[가-힣]{2,20}$|^[a-zA-Z][a-zA-Z\\s]{0,18}[a-zA-Z]$'
const REGEX_INVITATION_CODE = /^[가-힣a-zA-Z0-9]{1,12}$/
const RECIPIENT_NAME_REGEX = '^[가-힣A-z0-9][가-힣A-z0-9\\s]{0,19}$'

export const pwRegexEng = new RegExp(PW_REGEX_ENG)
export const pwRegexNum = new RegExp(PW_REGEX_NUM)
export const pwRegexSpc = new RegExp(PW_REGEX_SPC)
export const pwRegexName = new RegExp(PW_REGEX_NAME)
const regexInvitationCode = new RegExp(REGEX_INVITATION_CODE)
const recipientNameRegex = new RegExp(RECIPIENT_NAME_REGEX)

/**
 * async-validator: Antd Form에서 사용하는 form validator library
 *  - usages와 API 정의를 확인할 수 있음
 *  - URL: https://github.com/yiminghe/async-validator
 *  - Type 참고: rc-field-form/lib/interface.d.ts
 * pattern에 정규식 사용하기:
 *  - URL: https://stackoverflow.com/questions/61040985/regex-pattern-match-validation-for-form-does-not-work-in-ant-design
 */

type ValidatorRuleType = Rule & {
  field?: string
  fullField?: string
  message?: string
  required?: boolean
}

export const FORM_ITEM_RULES = {
  FORM_ITEM_RULE_REQUIRED: {
    validator: async (rule: ValidatorRuleType, value: StoreValue) => {
      if (!value || value === '') {
        await utilFormValidators.failWithoutMessage()
      }
      return utilFormValidators.pass()
    },
  } as { [key: string]: Rule },
  FORM_ITEM_RULE_NAME: {
    validator: async (_: ValidatorRuleType, value: StoreValue) => {
      if (value && value !== '' && pwRegexName.test(value)) {
        return utilFormValidators.pass()
      }
      await utilFormValidators.fail('· 이름을 정확히 입력해 주세요.')
    },
  } as { [key: string]: Rule },
  FORM_ITEM_RULE_RECIPIENT_NAME: {
    validator: async (_: ValidatorRuleType, value: StoreValue) => {
      if (value && value !== '' && recipientNameRegex.test(value.trim())) {
        return utilFormValidators.pass()
      }
      await utilFormValidators.fail('· 이름을 정확히 입력해 주세요.')
    },
  } as { [key: string]: Rule },
  FORM_ITEM_RULE_URL: { type: 'url', message: '· 올바른 URL 형식을 입력해 주세요' } as { [key: string]: Rule },
  FORM_ITEM_RULE_EMAIL: {
    validator: (_: any, value: string) => {
      return utilFormValidators.emailFormatValidator(value, '· 이메일 형식이 올바르지 않습니다.')
    },
  },
  FORM_ITEM_RULE_USERNAME: { required: true, message: '· 이름을 입력해 주세요' },
  FORM_ITEM_RULE_PASSWORD: { required: true, message: '· 비밀번호를 입력해 주세요' },
  FORM_ITEM_RULE_BIRTHDAY: {
    pattern: /^(19[0-9][0-9]|20\d{2})-(0[0-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/,
    message: '입력하신 생년월일을 확인해주세요',
  },
  FORM_ITEM_RULE_INVITATION_CODE: {
    validator: async (_: ValidatorRuleType, value: StoreValue) => {
      if (value === '' || regexInvitationCode.test(value)) {
        return utilFormValidators.pass()
      }
      await utilFormValidators.fail('· 초대코드를 다시 확인해 주세요.')
    },
  } as { [key: string]: Rule },
  FORM_ITEM_RULE_ACCOUNT_NUMBER: { pattern: /^[0-9]*$/, message: '입력하신 계좌번호를 확인해주세요' },
  FORM_ITEM_RULE_MOBILE_NUMBER: {
    validator: (_: any, value: string) => {
      return utilFormValidators.mobilNumberValidator(value)
    },
  },
  FORM_ITEM_RULE_AGREE_AGE: {
    required: true,
    validator: (_: any, value?: boolean) => {
      return utilFormValidators.checkboxRequiredValidator(value, '만 14세 이상 확인 동의에 동의해주세요')
    },
  },
  FORM_ITEM_RULE_AGREE_AGREEMENT: {
    required: true,
    validator: (_: any, value?: boolean) => {
      return utilFormValidators.checkboxRequiredValidator(value, '이용약관 동의에 동의해주세요')
    },
  },
  FORM_ITEM_RULE_AGREE_PRIVACY_POLICY: {
    required: true,
    validator: (_: any, value?: boolean) => {
      return utilFormValidators.checkboxRequiredValidator(value, '개인정보 수집 및 이용 동의에 동의해주세요')
    },
  },
  FORM_ITEM_RULE_INSTAGRAM_FEED_URL: {
    pattern: /^(?:https?:\/\/)?(?:www\.)?instagram\.com.*\/p\/([\d\w\-_]+)\/?(\?.*)?$/,
    message: '입력하신 SNS 링크 주소를 확인해주세요',
  },
  FORM_ITEM_RULE_CONFIRM_PASSWORD: ({ getFieldValue }: { getFieldValue: (name: NamePath) => StoreValue }) => ({
    validator(_: any, value: string) {
      if (!value || getFieldValue(FieldKeysEnum.Password) === value) {
        return utilFormValidators.pass()
      }
      return utilFormValidators.fail('· 비밀번호가 서로 일치하지 않습니다.')
    },
  }),
  FORM_ITEM_RULE_UPDATE_CONFIRM_PASSWORD: ({ getFieldValue }: { getFieldValue: (name: NamePath) => StoreValue }) => ({
    validator(_: any, value: string) {
      if (getFieldValue(FieldKeysEnum.Password) === value) {
        return utilFormValidators.pass()
      }
      return utilFormValidators.fail('· 비밀번호가 서로 일치하지 않습니다.')
    },
  }),
  FORM_ITEM_RULE_COUPON_CODE: () => ({
    validator(_: any, value: string) {
      if (value && value.trim()) {
        return utilFormValidators.pass()
      }
      return utilFormValidators.fail('쿠폰 코드를 입력해 주세요')
    },
  }),
}
