import { gql } from '@apollo/client'
import DEFAULT_FRAGMENTS from '@/containers/gqls/base/defaultFragments'

const ADDRESS_QUERY_GQLS = {
  CUSTOMER_ADDRESS: gql`
    query customerAddress($id: ID!) {
      customerTemplateAddress(id: $id) {
        ...customerTemplateAddress
      }
    }
    ${DEFAULT_FRAGMENTS.customerTemplateAddress}
  `,
  CUSTOMER_ADDRESSES: gql`
    query customerAddresses($filter: GenericScalar, $pageInfo: PageInfoInputType, $orderBy: String) {
      customerTemplateAddresses(filter: $filter, pageInfo: $pageInfo, orderBy: $orderBy) {
        totalCount
        pageInfo {
          ...pageInfo
        }
        edges {
          node {
            ...customerTemplateAddress
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.customerTemplateAddress}
    ${DEFAULT_FRAGMENTS.pageInfo}
  `,
  REMOTE_AREA: gql`
    query remoteArea($postNumber: String!) {
      remoteArea(postNumber: $postNumber) {
        id
        postNumber
        extraFee
        extraReturnFee
      }
    }
  `,
}

export default ADDRESS_QUERY_GQLS
