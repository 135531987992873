import { gql } from '@apollo/client'

export const POLICY_QUERY_GQLS = {
  SELLERS: gql`
    query sellers($filter: GenericScalar) {
      sellers(filter: $filter) {
        totalCount
        edges {
          node {
            id
            shopName
          }
        }
      }
    }
  `,
}

export default POLICY_QUERY_GQLS
