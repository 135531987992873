import { gql } from '@apollo/client'

const PRODUCT_FRAGMENTS = {
  get productListDefaultItem() {
    return gql`
      fragment productListDefaultItem on ProductType {
        id
        name
        price
        customerPrice
        discountPrice
        discountRate
        isRealSoldOut
        isSoldOut
        averageReviewScore
        finalDiscountRate
        reviewCount
        productPromotionCustomerPrice
        productNumber
        isTimesale
        isLiked
        likedCount
        pricePerSku
        maxProductOptionSkuCount
        mainImage {
          id
          thumbnail(productImageType: "NORMAL")
        }
        status {
          value
        }
        productPromotion {
          id
          productPromotionType
        }
      }
    `
  },
  get productOption() {
    return gql`
      fragment productOption on ProductOptionType {
        id
        name
        stockQuantity
        price
        customerPrice
        productOptionPrice
        productPromotionCustomerPrice
        productPromotionDiscountPrice
        isUse
        isDelete
        mileage
        pricePerSku
        productOptionKinds {
          key
          value
        }
        isDefault
        productOptionSkus {
          edges {
            node {
              sku {
                id
                code
                stockQuantity
              }
              count
            }
          }
        }
        product {
          shippingPreset {
            id
          }
        }
      }
    `
  },
  get productPromotion() {
    return gql`
      fragment productPromotion on ProductPromotionType {
        id
        startedAt
        endedAt
        isDelete
        minOrderAmount
        boughtCount
        maxOrderCount
        discountPrice
        discountRate
        productPromotionType
      }
    `
  },
  get likedProductsItem() {
    return gql`
      fragment likedProductsItem on LikedProductType {
        id
        product {
          ...productListDefaultItem
        }
        likedAt
        productPromotion {
          id
          productPromotionType
        }
      }
      ${this.productListDefaultItem}
    `
  },
  get searchProductsDefaultItem() {
    return gql`
      fragment searchProductsDefaultItem on SearchProductsItemType {
        id
        name
        price
        customerPrice
        discountPrice
        discountRate
        isRealSoldOut
        isSoldOut
        finalDiscountRate
        averageReviewScore
        reviewCount
        productPromotionDiscountRate
        productPromotionCustomerPrice
        productNumber
        isTimesale
        mainImage {
          id
          thumbnail(productImageType: "PRODUCT_LIST")
        }
        productPromotion {
          id
        }
        brand {
          id
          name
          listImage
        }
        categories {
          edges {
            node {
              id
              name
              code
            }
          }
        }
      }
    `
  },
}

export default PRODUCT_FRAGMENTS
