export enum MembershipStatusEnum {
  None = 'NONE',
  Owner = 'OWNER',
  Family = 'FAMILY',
}

export enum MembershipStatusLowerEnum {
  Owner = 'owner',
  Family = 'family',
}
