import { gql } from '@apollo/client'
import DEFAULT_FRAGMENTS from '@/containers/gqls/base/defaultFragments'

const DEFAULT_MUTATION_GQLS = {
  REFRESH_CART_ITEMS: gql`
    mutation refreshCartItemsDefault($id: ID!) {
      refreshCartItems(id: $id) {
        cartItems {
          ...cartItem
          productOption {
            ...productOption
          }
          user {
            ...user
          }
          productPromotion {
            ...productPromotion
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.cartItem}
    ${DEFAULT_FRAGMENTS.productOption}
    ${DEFAULT_FRAGMENTS.productPromotion}
    ${DEFAULT_FRAGMENTS.user}
  `,
  CHECK_EMAIL_DUPLICATED: gql`
    mutation checkEmailDuplicatedDefault($input: CheckEmailDuplicatedInput!) {
      checkEmailDuplicated(input: $input) {
        isDuplicated
      }
    }
  `,
}

export default DEFAULT_MUTATION_GQLS
