import { HOSPITAL_HOST } from '@/constants/legacy/constEnv'
import { SocialServiceSocialType } from '@/graphql/generated/schemas'
import { UtilToken } from '@/utils/utilToken'

const getSocialCircleIcon = (method?: SocialServiceSocialType) => {
  switch (method) {
    case SocialServiceSocialType.Apple:
      return 'btn_circle_apple_new'
    case SocialServiceSocialType.Naver:
      return 'btn_circle_naver_new'
    case SocialServiceSocialType.Kakao:
      return 'btn_circle_kakao_new'
    default:
      return 'btn_circle_email_new'
  }
}

const UtilLogin = {
  getRedirectUrlWithToken: (returnUrl: string) => {
    const url = new URL(returnUrl)
    url.searchParams.append('token', UtilToken.getTokenFromLocalStorage())
    return url.toString()
  },
  isHospitalRelatedUrls: (url: string) => {
    const hospitalRelatedUrls = [HOSPITAL_HOST, 'http://localhost:7001']
    return hospitalRelatedUrls.some((validUrl) => validUrl && url.startsWith(validUrl))
  },
}

export { UtilLogin, getSocialCircleIcon }
