import { FC } from 'react'
import { ImageAsset, TextEllipsis } from '@/components/common'
import { NewRow } from '@/components/common/layouts/NewRow'
import { SocialServiceSocialType } from '@/graphql/generated/schemas'
import { getSocialCircleIcon } from '@/utils/utilLogin'

type SocialEmailAccountProps = {
  socialType?: SocialServiceSocialType
  email: string
}

const SocialEmailAccount: FC<SocialEmailAccountProps> = ({ socialType, email }) => {
  return (
    <NewRow align="center" wrap={false} gap={'0.8rem'}>
      <ImageAsset name={getSocialCircleIcon(socialType)} size={24} svg />
      <TextEllipsis lineNumber={1}>{email}</TextEllipsis>
    </NewRow>
  )
}

export default SocialEmailAccount
