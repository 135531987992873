import { useDispatch, useSelector } from 'react-redux'
import { doSetBackActionClear, doSetBackActionEnQueue } from '@/stores/reduxData'
import type { BackActionMap, BackAction, BackActionQueue } from '@/components/common/back-action/types'
import type { RootState } from '@/stores/store'

interface IUseBackAction {
  (name: BackActionMap['name']): {
    /** 정해진 이름(name)의 backAction 데이터 */
    data: BackAction
    /** 정해진 이름(name)의 backAction에 queue를 추가하는 함수 */
    push: (queue: BackActionQueue['condition']) => void
    /** 정해진 이름(name)의 backAction 데이터를 초기화하는 함수 */
    clear: () => void
    /** 정해진 이름(name)의 backAction 가장 앞에 queue와 조건이 일치하는지 확인하는 함수 */
    contain: (condition: BackActionQueue['condition']) => boolean
    /** 정해진 이름(name)의 backAction 가장 앞에 queue를 반환하는 함수 */
    front: () => BackActionQueue | undefined
  }
}

/**
 * '뒤로가기' 시 조건을 만족하는 액션을 수행 및 관리하는 Hook
 *
 * `<BackActionContainer />`가 호출된 영역 내에서 사용해야함
 */
export const useBackAction: IUseBackAction = (name) => {
  const dispatch = useDispatch()
  const data = useSelector((state: RootState) => state.reduxDataReducers.backActionMapData[name])

  const push = (condition: BackActionQueue['condition']) => {
    if (data.queue.length < 1) {
      dispatch(doSetBackActionEnQueue({ name, condition }))
    }
  }

  const clear = () => {
    dispatch(doSetBackActionClear({ name }))
  }

  const contain = (condition: BackActionQueue['condition']) => {
    if (data.queue.length === 0) return false
    return data.queue[0].condition === condition && data.queue[0].status === 'active'
  }

  const front = () => {
    if (data.queue.length === 0) return undefined
    return data.queue[0]
  }

  return { data, push, clear, contain, front }
}
