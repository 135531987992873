import { S3_HOST_URL } from '@/constants/legacy/constEnv'
import { cloneDeep, isObject } from '@/utils/utilCommon'

const objectSize = (object: {}) => {
  return object ? Object.keys(object).length : 0
}

export const hasData = (a?: object | []) => {
  if (!a) return false
  if (isObject(a)) {
    return objectSize(a) > 0
  }
  if (Array.isArray(a)) {
    return (a as []).length > 0
  }
  return false
}
export const deepcopy = (object: any) => cloneDeep(object)

export const getFullUrl = (initUrl: string) => {
  return `${S3_HOST_URL}/${initUrl}`
}

export const getBase64 = (img: File, callback: Function) => {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result))
  reader.readAsDataURL(img)
}

export const convertUrlToFileObj = async (url: string) => {
  const res = await fetch(url)
  const blob = await res.blob()
  return new File([blob], `test.jpeg`, { type: blob.type })
}

export const convertToBase64 = (str: any) => {
  if (!str) return undefined

  return encodeURIComponent(Buffer.from(JSON.stringify(str)).toString('base64'))
}

export const convertFromBase64 = (targetBase64: string, parse: boolean = true) => {
  if (!targetBase64) return undefined

  const _decoded = Buffer.from(decodeURIComponent(targetBase64), 'base64').toString('utf-8')
  if (!parse) return _decoded
  return JSON.parse(_decoded)
}

/**
 * 주어진 파일(File)에 대한 객체 URL을 생성합니다.
 * 이 URL은 브라우저에서 파일을 나타내는 데 사용할 수 있으며, 예를 들어 <img> 태그의 src 속성 값으로 사용될 수 있습니다.
 * 생성된 URL은 window.URL.revokeObjectURL()을 호출하여 프로그램이 사용을 마친 후 해제해야 합니다.
 *
 * @param {file} params - 함수의 매개변수를 포함하는 객체
 * @param {File} params.file - 객체 URL을 생성할 파일(File)
 * @returns {string} 생성된 객체 URL
 */
export const createObjectURL = ({ file }: { file: File }): string | undefined => {
  try {
    // 브라우저가 URL.createObjectURL을 지원하는지 확인
    if (!window.URL || !window.URL.createObjectURL) {
      console.error('This browser does not support URL.createObjectURL.')
      return undefined // 또는 적절한 대체 방안을 제공
    }
    return window.URL.createObjectURL(file)
  } catch (error) {
    console.error('Error creating object URL:', error)
    return undefined // 오류가 발생하면 null 반환 또는 사용자에게 피드백 제공
  }
}

/**
 * HEIC 이미지를 JPEG 형식으로 변환하여 새로운 File 객체를 생성합니다.
 * @param {Object} params - 옵션 객체
 * @param {File} params.file - 변환할 HEIC 파일
 * @returns {Promise<File|undefined>} - 변환된 JPEG 파일 객체 또는 undefined
 */
export const createHeicImageURL = async ({ file }: { file: File }): Promise<File | undefined> => {
  try {
    const heic2any = (await import('heic2any')).default
    const result = await heic2any({
      blob: file,
      toType: 'image/jpeg',
    })
    const blob = Array.isArray(result) ? result[0] : result
    return new File([blob], file.name, { type: 'image/jpeg' })
  } catch (error) {
    console.error('Error creating heic image URL:', error)
    return undefined
  }
}
