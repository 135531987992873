import { useEffect, useState } from 'react'
import { ApolloQueryResult, useLazyQuery, useQuery } from '@apollo/client'
import { useSelector } from 'react-redux'
import { RequestInputType } from '@/constants/legacy/constType'
import { OrderCancelTypeEnum } from '@/constants/order-cancel-type.enum'
import { OrderStatusTypeEnum } from '@/constants/order-status-type.enum'
import ADDRESS_QUERY_GQLS from '@/containers/gqls/accounts/address/queries'
import ORDER_QUERY_GQLS from '@/containers/gqls/orders/queries'
import { useCustomRouter } from '@/containers/hooks'
import { IModelCustomerTemplateAddress } from '@/containers/models/modelCustomerTemplateAddress'
import { IModelOrder } from '@/containers/models/modelOrder'
import { IModelOrderRefundInfo } from '@/containers/models/modelOrderRefundInfo'
import { IModelTransaction } from '@/containers/models/modelTransaction'
import { RootState } from '@/stores/store'
import { getResultFromData } from '@/utils/utilApi'
import { convertFromBase64 } from '@/utils/utilData'
import { classifyPayMethodType, extractItemsStatus, getPaymentMethodText, isUnPaidCanceled } from '@/utils/utilOrder'

type UseOrderInfoType = {
  cancelType: OrderCancelTypeEnum
  requestInputDict: RequestInputType
  retrievalCustomerAddress: IModelCustomerTemplateAddress | undefined
  orderId: string
  orderInfo: IModelOrder
  orderItemsStatus: OrderStatusTypeEnum[]
  isLoadedOrder: boolean
  payMethodName: string
  paymentAmount: number
  totalRefundAmount: number
  refundPayAmount: number
  extraShippingFee: number
  returnExtraShippingFee: number
  exchangeRoundShippingFee: number
  refetchOrderQuery: (variables?: Partial<{ id: string; skip: boolean }> | undefined) => Promise<ApolloQueryResult<any>>
}

const useOrderInfo = (): UseOrderInfoType => {
  const { query } = useCustomRouter()
  const {
    cancelType,
    orderId,
    requestInput: requestInputBase64,
  } = query as {
    cancelType: OrderCancelTypeEnum
    orderId: string
    requestInput: string
  }
  const retrievalCustomerAddress = useSelector(
    (rootState: RootState) => rootState.reduxDataReducers.retrievalCustomerAddress
  )
  const [orderInfo, setOrderInfo] = useState({} as IModelOrder)
  const [orderItemsStatus, setOrderItemsStatus] = useState<OrderStatusTypeEnum[]>([])
  const [payMethodName, setPayMethodName] = useState<string>('')
  const [paymentAmount, setPaymentAmount] = useState(0)
  const [totalRefundAmount, setTotalRefundAmount] = useState(0)
  const [refundPayAmount, setRefundPayAmount] = useState(0)
  const [extraShippingFee, setExtraShippingFee] = useState(0)
  const [returnExtraShippingFee, setReturnExtraShippingFee] = useState(0)
  const [exchangeRoundShippingFee, setExchangeRoundShippingFee] = useState(0)
  const [isLoadedOrder, setIsLoadedOrder] = useState(false)

  const { refetch: refetchOrderQuery } = useQuery(ORDER_QUERY_GQLS.ORDER, {
    fetchPolicy: 'no-cache',
    variables: {
      id: orderId,
      skip: !orderId,
    },
    onCompleted: (data) => {
      if (!data) {
        return
      }
      setOrderData(getResultFromData(data)?.order.data as IModelOrder)
    },
  })

  const [queryRemoteArea] = useLazyQuery(ADDRESS_QUERY_GQLS.REMOTE_AREA, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      if (data !== null && !data) {
        return
      }
      const { extraReturnFee } = getResultFromData(data)?.remoteArea.data
      setReturnExtraShippingFee(extraReturnFee ?? 0)
    },
  })

  useEffect(() => {
    if (Object.keys(orderInfo).length > 0) {
      queryRemoteArea({
        variables: {
          postNumber: orderInfo.customerAddress.postNumber,
        },
      })
    }
  }, [orderInfo])

  const setOrderData = (orderInfo: IModelOrder) => {
    const getRefundPayAmount = (approvedTransaction: IModelTransaction, orderRefundInfo: IModelOrderRefundInfo) => {
      return isUnPaidCanceled(approvedTransaction)
        ? 0
        : orderRefundInfo.soldAmount -
            orderRefundInfo.couponDiscountAmount -
            orderRefundInfo.orderCouponDiscountAmount -
            orderRefundInfo.mileageDiscountAmount
    }

    const { approvedTransaction, orderOrderItems: orderItems, orderRefundInfo, amount } = orderInfo
    setOrderInfo(orderInfo)
    setOrderItemsStatus(extractItemsStatus(orderItems))
    setPayMethodName(approvedTransaction.paymentText)
    setPaymentAmount(isUnPaidCanceled(approvedTransaction) ? 0 : amount)
    setTotalRefundAmount(isUnPaidCanceled(approvedTransaction) ? 0 : orderRefundInfo.totalRefundAmount)
    setRefundPayAmount(getRefundPayAmount(approvedTransaction, orderRefundInfo))
    setExtraShippingFee(orderInfo.shippingExtraFees)
    setExchangeRoundShippingFee(
      orderRefundInfo.exchangeNewShippingFeeAmount + orderRefundInfo.exchangeReturnShippingFeeAmount
    )
    setIsLoadedOrder(true)
  }

  return {
    cancelType,
    requestInputDict: convertFromBase64(requestInputBase64) as RequestInputType,
    retrievalCustomerAddress,
    orderId,
    orderInfo,
    orderItemsStatus,
    isLoadedOrder,
    payMethodName,
    paymentAmount,
    totalRefundAmount,
    refundPayAmount,
    extraShippingFee,
    returnExtraShippingFee,
    exchangeRoundShippingFee,
    refetchOrderQuery,
  }
}

export default useOrderInfo
