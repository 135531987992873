import { Modelize } from '@/containers/models/base/modelize'

export interface IDataBase {
  data: any | undefined
}

export default class DataBase implements IDataBase {
  public data: any | undefined

  static isListData(data: object) {
    try {
      // TODO typescript 오류 수정 필요
      // @ts-ignore
      return 'edges' in data && typeof data.edges !== 'string'
    } catch {
      return false
    }
  }

  setData(data: any) {
    this.data = data
    return this
  }

  static getModel(data: any): any {
    if (typeof data === 'string') {
      return data
    }
    const model = Modelize.modelize(data)
    return model
  }

  static getListModel(data: any): any {
    if (!data) {
      // Todo 여기서 sentry Error : https://sentry.io/organizations/fitpet/issues/3399123679/?project=5962478&referrer=slack#exception
      return null
    }
    if (data && data.edges) {
      const edgesModel = data.edges.map((_data: any) => {
        const model = DataBase.getModel(_data.node)
        return model
      })
      if (data.totalCount) {
        edgesModel.totalCount = data.totalCount
      }
      return edgesModel
    }

    if (data.length > 0) {
      return data.map((_data: any) => {
        const model = DataBase.getModel(_data)
        return model
      })
    }
  }
}
