import styled from 'styled-components'
import { ValueOf } from 'type-fest'
import { Typo, TypoVariant } from '@/components/common/typography'
import { BasicLabelType } from '@/components/domains/products/new-product-card/new-product-card.type'
import { GlobalTheme } from '@/styles/globalTheme'

export const BasicLabel = ({
  value,
  fontColor = GlobalTheme.color.grayWhite,
  variant = TypoVariant.Caption1Bold,
  backgroundColor = GlobalTheme.color.red500,
}: BasicLabelType) => {
  return (
    <BasicLabelWrapper backgroundColor={backgroundColor}>
      <Typo variant={variant} color={fontColor}>
        {value}
      </Typo>
    </BasicLabelWrapper>
  )
}

const BasicLabelWrapper = styled.div<{ backgroundColor: ValueOf<typeof GlobalTheme.color> }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.4rem;
  height: 2.1rem;
  border-radius: 0.4rem;
  background-color: ${({ backgroundColor }) => backgroundColor};
`
