import { ProductSortByParamParameter } from '@/apis/rest/generated/types'
import { ProductsOrderByKey } from './product-orderby-key.const'

export type ProductsSelectFilterOrderBy = {
  [key: string]: {
    value: string
    text: string
  }
}

export const ProductsSelectFilterOrderBy = {
  Point: {
    value: [ProductsOrderByKey.point, '-id'].join(','),
    text: '인기 판매순',
  },
  CreatedAt: {
    value: ProductsOrderByKey.createdAt,
    text: '최신순',
  },
  AscendingCustomerPrice: {
    value: ProductsOrderByKey.productPromotionCustomerPriceAsc,
    text: '낮은 가격순',
  },
  DescendingCustomerPrice: {
    value: ProductsOrderByKey.productPromotionCustomerPriceDesc,
    text: '높은 가격순',
  },
  FinalDiscountRate: {
    value: ProductsOrderByKey.finalDiscountRateDesc,
    text: '할인율 높은순',
  },
  ReviewCount: {
    value: ProductsOrderByKey.reviewCountDesc,
    text: '리뷰 많은순',
  },
  DisplayOrder: {
    value: ProductsOrderByKey.displayOrder,
    text: 'MD 추천순',
  },
} as const

export const PRODUCTS_SELECT_FILTER_ORDER_BY_V4 = {
  RECOMMEND: {
    value: 'MD_RECOMMENDED',
    text: '추천순',
  },
  BEST_SELLING: {
    value: 'SALES_SCORE',
    text: '인기 판매순',
  },
  NEWEST: {
    value: 'CREATED',
    text: '최신순',
  },
  PRICE_ASC: {
    value: 'SALE_PRICE_ASC',
    text: '낮은 가격순',
  },
  PRICE_DESC: {
    value: 'SALE_PRICE_DESC',
    text: '높은 가격순',
  },
  DISCOUNT_RATE: {
    value: 'DISCOUNT_RATE',
    text: '할인율 높은순',
  },
  REVIEW_COUNT: {
    value: 'REVIEW_COUNT',
    text: '리뷰 많은순',
  },
  REVIEW_SCORE: {
    value: 'REVIEW_SCORE',
    text: '별점순',
  },
} as const

export type ProductsSelectFilterOrderByV4 = keyof typeof PRODUCTS_SELECT_FILTER_ORDER_BY_V4
