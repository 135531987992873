// TODO typescript 오류 수정 필요
// @ts-ignore
import JWTDecode from 'jwt-decode'
// @ts-ignore
import { appleAuthHelpers, useScript } from 'react-apple-signin-auth'
import { isMobileSafari, isSafari } from 'react-device-detect'
import { APPLE_CLIENT_ID } from '@/constants/legacy/constEnv'
import ROUTES from '@/constants/legacy/constRoutes'
import { AppleAuthResponseType, SocialAuthType, SocialServiceUserProfileType } from '@/constants/legacy/constType'
import { SocialServicesEnum } from '@/constants/social-services.enum'
import { localSocialAuthType } from '@/utils/utilLocalStorage'

const useApple = () => {
  useScript(appleAuthHelpers.APPLE_SCRIPT_SRC)

  const openAuth = (authType?: SocialAuthType) => {
    // 로컬 테스트 안됨 stage환경으로 테스트 필요
    // clientId: 'com.fitpetmall.stg',
    // redirectURI: 'https://stg.fitpetmall.com/mall/accounts/sns/apple/callback',
    localSocialAuthType.save(authType || 'LOGIN')
    appleAuthHelpers.signIn({
      authOptions: {
        clientId: APPLE_CLIENT_ID,
        scope: 'email name',
        redirectURI: `${window.location.origin}${ROUTES.PREFIX}${ROUTES.ACCOUNTS.CALLBACK_APPLE}`,
        state: 'state',
        nonce: 'nonce',
        usePopup: false,
      },
      onSuccess: (response: any) => {},
      onError: (error: any) => console.error(error),
    })
  }

  const getSocialServiceFromAuthResponse = (authRes: AppleAuthResponseType): SocialServiceUserProfileType => {
    const { email } = JWTDecode(authRes.id_token) as { email: string }

    // @ts-ignore
    const user = authRes.user ? JSON.parse(authRes.user) : undefined
    const name = user && user.name ? `${user.name.lastName} ${user.name.firstName}` : undefined
    return {
      socialType: SocialServicesEnum.Apple,
      accessToken: authRes.id_token,
      uniqueId: authRes.code,
      clientType: 'WEB',
      accessTokenTtl: 3000,
      email,
      name,
    }
  }

  const getNavigationBackCount = () => {
    if (isSafari || isMobileSafari) {
      return -1
    }

    return -2
  }

  return {
    openAuth,
    getSocialServiceFromAuthResponse,
    getNavigationBackCount,
  }
}

export default useApple
