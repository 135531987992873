import { useDispatch } from 'react-redux'
import { doSetBottomSheetData, doSetProductCouponDownloadBottomSheetData } from '@/stores/reduxData'

export const useProductCouponDownloadBottomSheet = () => {
  const dispatch = useDispatch()
  const closeBottomSheet = () => {
    dispatch(
      doSetBottomSheetData({
        isProductCouponDownloadVisible: false,
      })
    )
    dispatch(
      doSetProductCouponDownloadBottomSheetData({
        productId: 0,
        promotionId: undefined,
        productName: '',
        isRealSoldOut: undefined,
        buttonText: '',
        addLocation: undefined,
      })
    )
  }
  return {
    closeBottomSheet,
  }
}
