import { IModelDisplayProductInfoDefault } from '@/containers/models/base/defaultModelInterfaces'
import { ModelDisplayProductInfoDefault } from '@/containers/models/base/defaultModels'
import ModelProduct, { IModelProduct } from '@/containers/models/modelProduct'

export interface IModelDisplayProductInfo extends IModelDisplayProductInfoDefault {
  promotionStartedAt: moment.Moment
  promotionEndedAt: moment.Moment
  product: IModelProduct
}

export default class ModelDisplayProductInfo extends ModelDisplayProductInfoDefault {
  product: IModelProduct

  constructor(data: IModelDisplayProductInfo) {
    super(data)

    // @ts-ignore
    this.product = new ModelProduct(data.product)
  }
}
