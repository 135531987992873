import { PaymentMethodTypeKorean } from '@/constants/legacy/constData'
import { PaymentMethodKoreanEnum } from '@/constants/payment-method-korean.enum'
import { PaymentMethodTypeEnum } from '@/constants/payment-method-type.enum'
import { IModelTransactionDefault } from '@/containers/models/base/defaultModelInterfaces'
import { ModelTransactionDefault } from '@/containers/models/base/defaultModels'
import { IModelOrder } from '@/containers/models/modelOrder'

export interface IModelTransaction extends IModelTransactionDefault {
  order: IModelOrder

  isVBankTransaction: () => boolean
  getRefundMethodText: () => string
}

export default class ModelTransaction extends ModelTransactionDefault {
  isVBankTransaction = () => {
    return this.payMethodType === PaymentMethodTypeEnum.VirtualAccount.toUpperCase()
  }

  getRefundMethodText = () => {
    // 네이버페이 검수 건으로 paymentText가 naverpay면 무조건 네이버페이 return
    const payMethodType = this.payMethodType.toLowerCase() as PaymentMethodTypeEnum
    if (this.paymentText === 'NAVERPAY') {
      return PaymentMethodKoreanEnum.NaverPay
    }
    if (payMethodType === PaymentMethodTypeEnum.VirtualAccount) {
      return PaymentMethodKoreanEnum.DepositAccount
    }
    return PaymentMethodTypeKorean[payMethodType]
  }
}
