type ApiErrorProps = {
  error: string
}

function ApiError(pros: ApiErrorProps) {
  const { error } = pros
  return <>{error}</>
}

export default ApiError
