import { PropsWithChildren } from 'react'
import ReactDOM from 'react-dom'

const ModalSliderPortal = ({ children }: PropsWithChildren<{}>): JSX.Element => {
  if (typeof window === 'undefined') {
    return <></>
  }

  const element = document.getElementById('modal-root') as HTMLElement
  return ReactDOM.createPortal(children, element)
}

export default ModalSliderPortal
