import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import SnackBarContent from '@/components/common/snackBar/SnackBarContent'
import { Z_INDEXES } from '@/constants/legacy/constLayout'
import ROUTES from '@/constants/legacy/constRoutes'
import { useCustomRouter } from '@/containers/hooks'
import { doInitGlobalSnackBarContent } from '@/stores/reduxUI'
import { RootState } from '@/stores/store'
import { isInApp } from '@/utils/utilCommon'

enum SnackBarMargin {
  SMALL = 8.8,
}

enum SnackBarAnimationTime {
  TIME_OUT = 2.3,
  FADE_IN_DELAY = 0.1,
  FADE_IN_DURATION = 0.2,
  FADE_OUT_DELAY = 2,
  FADE_OUT_DURATION = 0.3,
}

const GlobalSnackBar: React.FC = () => {
  const dispatch = useDispatch()
  const globalSnackBarContent = useSelector((state: RootState) => state.reduxUIReducers.globalSnackBarContent)
  const { pathname, push } = useCustomRouter()
  const isHome = pathname.startsWith('/home') || pathname === '/'

  const snackBarContent = {
    addCart: {
      text: '상품이 장바구니에 담겼습니다.',
      actionText: '바로가기',
      onActionClick: () => push(ROUTES.CART),
    },
    liked: {
      text: '찜한 상품에 추가되었어요',
      actionText: '바로가기',
      onActionClick: () => push(ROUTES.MYPAGE.FAVORITE_LIST.LIKE),
    },
  }

  const getRect = () => {
    if (typeof document !== 'undefined') {
      return document.querySelector('#main')?.getBoundingClientRect()
    }
  }
  const clear = () => {
    dispatch(doInitGlobalSnackBarContent([]))
  }

  useEffect(() => {
    clear()
  }, [pathname])

  // clear container
  useEffect(() => {
    const timeout = setTimeout(() => {
      clear()
    }, SnackBarAnimationTime.TIME_OUT * 1000)

    return () => {
      // eslint-disable-next-line no-unused-expressions
      timeout && clearTimeout(timeout)
    }
  }, [globalSnackBarContent.length]) // CAUTION: list를 넣으면 무한루프 발생하므로, list.length가 바뀔때만 실행

  if (globalSnackBarContent.length === 0) return null

  return (
    <StyledWrapper left={getRect()?.left} isHome={isHome}>
      <StyledContentSection>
        <StyledBox>
          <SnackBarContent {...snackBarContent[globalSnackBarContent[0]]} />
        </StyledBox>
      </StyledContentSection>
    </StyledWrapper>
  )
}

export default GlobalSnackBar

const StyledWrapper = styled.div<{ left: number | undefined; isHome: boolean }>`
  position: fixed;
  bottom: ${({ isHome }) =>
    `calc(env(safe-area-inset-bottom) + ${isInApp() && isHome ? '2.6rem' : SnackBarMargin.SMALL + 'rem'})`};

  left: ${(props) => props.left}px;
  margin-inline-start: 16px;
  width: calc(100% - 32px);
  max-width: calc(576px - 32px);
  z-index: ${Z_INDEXES.TOAST};
`

const StyledContentSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;

  margin-top: 8px;
  min-height: 46px;
  border-radius: 8px;

  background-color: ${(props) => props.theme.color.gray900};
  color: ${(props) => props.theme.color.grayWhite};
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;

  @keyframes fadeIn {
    100% {
      opacity: 0.9;
    }
  }

  @keyframes fadeOut {
    100% {
      opacity: 0;
      visibility: hidden;
    }
  }

  @keyframes hide {
    100% {
      position: absolute;
      bottom: -2000px;
    }
  }
  animation: ${SnackBarAnimationTime.FADE_IN_DURATION}s cubic-bezier(0.65, 0, 0.35, 1)
      ${SnackBarAnimationTime.FADE_IN_DELAY}s forwards fadeIn,
    ${SnackBarAnimationTime.FADE_OUT_DURATION}s cubic-bezier(0.65, 0, 0.35, 1) ${SnackBarAnimationTime.FADE_OUT_DELAY}s
      forwards fadeOut,
    0s step-end ${SnackBarAnimationTime.TIME_OUT}s forwards hide;
`

const StyledBox = styled.div`
  width: 100%;
  padding: 0.8rem 1.6rem;
`
