import { useDispatch } from 'react-redux'
import { useUpdateCartItemMutation } from '@/graphql/generated/hooks'
import { UpdateCartItemInput } from '@/graphql/generated/schemas'
import { doSetGlobalToastContent } from '@/stores/reduxUI'
import { ApiErrorUtils } from '@/utils/utilApiErrors'

export const useCartUpdateItemMutation = () => {
  const [updateCartItemMutation] = useUpdateCartItemMutation()
  const dispatch = useDispatch()

  // id는 cartItemId
  const updateCartItem = async ({ id, input }: { id: string; input: UpdateCartItemInput }) => {
    try {
      const { data, errors } = await updateCartItemMutation({
        variables: {
          id,
          input,
        },
      })
      if (errors) {
        // backend에서 넘어온 에러
        const errorInfo = ApiErrorUtils.getError(errors[0])
        throw new Error(errorInfo.message)
      }
      return data
    } catch (error) {
      // network error
      const errorInfo = ApiErrorUtils.getError(error)
      dispatch(
        doSetGlobalToastContent({
          content: `${errorInfo.message}\n오류가 지속된다면 고객센터에 문의해 주세요`,
          duration: 5,
        })
      )
      return null
    }
  }
  return { updateCartItem }
}
