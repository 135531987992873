import styled, { css, useTheme } from 'styled-components'
import FlatIcon from '@/components/common/icons/FlatIcon'
import { Typo, TypoVariant } from '@/components/common/typography'
import { IconNameEnum } from '@/constants/icon-name.enum'

interface ProductChoiceCouponDownloadProps {
  onCouponDownloadClick: () => void
  hasUnreceivedProductCoupon?: boolean
}

export const ProductChoiceCouponDownload = ({
  onCouponDownloadClick,
  hasUnreceivedProductCoupon,
}: ProductChoiceCouponDownloadProps) => {
  const { color } = useTheme()
  const handleClickCouponDownload = () => {
    onCouponDownloadClick()
  }
  return (
    <ProductChoiceCouponDownloadWrapper
      onClick={handleClickCouponDownload}
      hasUnreceivedProductCoupon={hasUnreceivedProductCoupon}
    >
      <Typo variant={TypoVariant.Body3Medium} color={color.gray900}>
        {hasUnreceivedProductCoupon ? '받지 않은 상품쿠폰이 있어요' : '상품쿠폰을 다 받았어요'}
      </Typo>
      <div className="product-choice-coupon-download__sub-title-icon">
        <Typo variant={TypoVariant.Body3Bold} color={color.gray900}>
          {hasUnreceivedProductCoupon ? '쿠폰 받기' : '쿠폰 보기'}
        </Typo>
        <FlatIcon
          type={hasUnreceivedProductCoupon ? IconNameEnum.IcDownload : IconNameEnum.IcChevronRight}
          color={color.gray900}
          size="1.6rem"
        />
      </div>
    </ProductChoiceCouponDownloadWrapper>
  )
}

const ProductChoiceCouponDownloadWrapper = styled.button<{ hasUnreceivedProductCoupon?: boolean }>`
  cursor: pointer;
  padding: 1.5rem 1.6rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border: none;
  background-color: transparent;
  border: 1px solid
    ${({
      theme: {
        color: { gray900 },
      },
    }) => gray900};
  border-radius: 0.8rem;
  ${({
    hasUnreceivedProductCoupon,
    theme: {
      color: { gray100 },
    },
  }) =>
    !hasUnreceivedProductCoupon &&
    css`
      border-color: ${gray100};
    `}

  .product-choice-coupon-download {
    &__sub-title-icon {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 0.4rem;
    }
  }
`
