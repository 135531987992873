import { gql } from '@apollo/client'
import DEFAULT_FRAGMENTS from '@/containers/gqls/base/defaultFragments'

const USER_FRAGMENTS: { [index: string]: any } = {
  get userProfile() {
    return gql`
      fragment userProfile on UserType {
        id
        name
        email
        gender
        mobileNumber
        birthday
        gender
        agreeMarketing
        agreePrMarketing
        agreeNightMarketing
        agreeThirdPartyProvision
        orderCount
        reviewCount
        couponCount
        cartItemCount
        mileagePoint
        willExpiredMileageIn30Days
        displayPetType
        lastOrderedAt
        canBuyDeal100
        payMethodType
        isUseAllMileage
        invitationCode
        isTest
        userSocialServices {
          totalCount
          edges {
            node {
              socialType
            }
          }
        }
      }
    `
  },
  get userDevice() {
    return gql`
      fragment userDevice on DeviceType {
        ...device
        socialService {
          socialType
        }
      }
      ${DEFAULT_FRAGMENTS.device}
    `
  },
}

export default USER_FRAGMENTS
