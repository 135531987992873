import { HistoryBackKeyEnum } from '@/constants/history-back-key.enum'
import { PaymentMethodTypeEnum } from '@/constants/payment-method-type.enum'
import { isEmpty } from '@/utils/utilCommon'

const KEY = {
  INFINITE_LIST_FILTER_KEY: 'INFINITE_LIST_FILTER_KEY',
  TEMP_ORDER_ITEMS: 'ORDER_ITEMS',
  NAVER_AUTH_FOR_LOGIN: 'NAVER_AUTH_FOR_LOGIN',
  TEMP_CANCEL_ORDER_ITEMS_INPUT: 'TEMP_CANCEL_ORDER_ITEMS_INPUT',
  TEMP_PAYMENT_METHOD_FOR_FAILURE_MOBILE: 'TEMP_PAYMENT_METHOD_FOR_FAILURE_MOBILE',
  MEMBERSHIP_PAY_AGREED_OF_OWNER: 'MEMBERSHIP_PAY_AGREED_OF_OWNER',
  MEMBERSHIP_REFUND_AGREED_OF_OWNER: 'MEMBERSHIP_REFUND_AGREED_OF_OWNER',
  MEMBERSHIP_TERM_AGREED_OF_OWNER: 'MEMBERSHIP_TERM_AGREED_OF_OWNER',
  MEMBERSHIP_PRIVACY_AGREED_OF_OWNER: 'MEMBERSHIP_PRIVACY_AGREED_OF_OWNER',
  MEMBERSHIP_MONTH: 'MEMBERSHIP_MONTH',
  INITIAL_HISTORY_LENGTH: 'INITIAL_HISTORY_LENGTH',
  LAYOUT_BACKGROUND_IMAGE_INDEX: 'LAYOUT_BACKGROUND_IMAGE_INDEX',
  REFERRER_TRACKER: 'REFERRER_TRACKER',
  SHOULD_RESTORE_SCROLL: 'SHOULD_RESTORE_SCROLL',
}

const save = (k: string, v: any) => {
  if (typeof sessionStorage === 'undefined') return
  sessionStorage.setItem(k, JSON.stringify(v))
}

const load = (k: string) => {
  if (typeof sessionStorage === 'undefined') return null
  const value = sessionStorage.getItem(k)
  if (value && value !== 'undefined') {
    return JSON.parse(value)
  }
  return null
}

const remove = (k: string) => {
  if (typeof sessionStorage === 'undefined') return
  sessionStorage.removeItem(k)
}

export const sessionRemoveAll = async () => {
  if (typeof localStorage === 'undefined') return
  const REMOVE_KEYS: string[] = [
    KEY.MEMBERSHIP_PAY_AGREED_OF_OWNER,
    KEY.MEMBERSHIP_TERM_AGREED_OF_OWNER,
    KEY.MEMBERSHIP_PRIVACY_AGREED_OF_OWNER,
  ]
  REMOVE_KEYS.forEach((key) => sessionStorage.removeItem(key))
}

export const sessionTempPaymentMethodForFailureMobile = {
  save: (v: PaymentMethodTypeEnum) => save(KEY.TEMP_PAYMENT_METHOD_FOR_FAILURE_MOBILE, v),
  load: (): PaymentMethodTypeEnum | undefined => {
    const res = load(KEY.TEMP_PAYMENT_METHOD_FOR_FAILURE_MOBILE)
    return isEmpty(res) ? undefined : res
  },
  remove: () => remove(KEY.TEMP_PAYMENT_METHOD_FOR_FAILURE_MOBILE),
}

export const sessionMembershipPayAgreedOfOwner = {
  save: (v: boolean) => save(KEY.MEMBERSHIP_PAY_AGREED_OF_OWNER, v),
  load: (): boolean => {
    const res = load(KEY.MEMBERSHIP_PAY_AGREED_OF_OWNER)
    return res === 'true' || !!res
  },
  remove: () => remove(KEY.MEMBERSHIP_PAY_AGREED_OF_OWNER),
}

export const sessionMembershipRefundAgreedOfOwner = {
  save: (v: boolean) => save(KEY.MEMBERSHIP_REFUND_AGREED_OF_OWNER, v),
  load: (): boolean => {
    const res = load(KEY.MEMBERSHIP_REFUND_AGREED_OF_OWNER)
    return res === 'true' || !!res
  },
  remove: () => remove(KEY.MEMBERSHIP_REFUND_AGREED_OF_OWNER),
}

export const sessionMembershipTermAgreedOfOwner = {
  save: (v: boolean) => save(KEY.MEMBERSHIP_TERM_AGREED_OF_OWNER, v),
  load: (): boolean => {
    const res = load(KEY.MEMBERSHIP_TERM_AGREED_OF_OWNER)
    return res === 'true' || !!res
  },
  remove: () => remove(KEY.MEMBERSHIP_TERM_AGREED_OF_OWNER),
}

export const sessionMembershipPrivacyAgreedOfOwner = {
  save: (v: boolean) => save(KEY.MEMBERSHIP_PRIVACY_AGREED_OF_OWNER, v),
  load: (): boolean => {
    const res = load(KEY.MEMBERSHIP_PRIVACY_AGREED_OF_OWNER)
    return res === 'true' || !!res
  },
  remove: () => remove(KEY.MEMBERSHIP_PRIVACY_AGREED_OF_OWNER),
}

export const sessionMembershipMonth = {
  save: (v: number) => save(KEY.MEMBERSHIP_MONTH, v),
  load: (): number => {
    return load(KEY.MEMBERSHIP_MONTH)
  },
  remove: () => remove(KEY.MEMBERSHIP_MONTH),
}

export const sessionLayoutBackgroundImageIndex = {
  save: (v: number) => save(KEY.LAYOUT_BACKGROUND_IMAGE_INDEX, v),
  load: (): number | null => {
    const res = load(KEY.LAYOUT_BACKGROUND_IMAGE_INDEX)
    return res
  },
  remove: () => remove(KEY.LAYOUT_BACKGROUND_IMAGE_INDEX),
}

export const sessionInitialHistoryLength = {
  save: (k: HistoryBackKeyEnum, v: number) => {
    save(KEY.INITIAL_HISTORY_LENGTH, { [k]: v })
  },
  load: (k: HistoryBackKeyEnum): number | undefined => {
    const res = load(KEY.INITIAL_HISTORY_LENGTH)
    return isEmpty(res) ? undefined : res[k]
  },
  remove: () => remove(KEY.INITIAL_HISTORY_LENGTH),
}

export const sessionReferrerTracker = {
  save: (v: string) => save(KEY.REFERRER_TRACKER, v),
  load: (): string | null => {
    return load(KEY.REFERRER_TRACKER)
  },
  remove: () => remove(KEY.REFERRER_TRACKER),
}

export const sessionScrollRestorationURL = {
  save: (k: string, v: { x: number; y: number }) => save(k, v),
  load: (k: string): { x: number; y: number } => {
    return load(k)
  },
  remove: (k: string) => remove(k),
}

export const sessionShouldRestoreScroll = {
  save: (v: string) => save(KEY.SHOULD_RESTORE_SCROLL, v),
  load: (): string | null => {
    return load(KEY.SHOULD_RESTORE_SCROLL)
  },
  remove: () => remove(KEY.SHOULD_RESTORE_SCROLL),
}
