/*
 * 디자인 시스템 색상
 * https://www.figma.com/file/5VDCLszeSek9nP9MivS7X1/FitPet-Design-System?node-id=2%3A10
 */
export enum GlobalColorEnum {
  // GRAY
  GrayBlack = '#000000',
  Gray900 = '#1B1E21',
  Gray800 = '#2C3136',
  Gray700 = '#42494F',
  Gray600 = '#5A6169',
  Gray500 = '#727A82',
  Gray400 = '#8C949C',
  Gray300 = '#A7AEB5',
  Gray200 = '#C2C8CF',
  Gray100 = '#DFE3E8',
  Gray70 = '#EEF1F5',
  Gray50 = '#F4F7FA',
  Gray30 = '#FAFBFD',
  GrayWhite = '#FFFFFF',

  // BLUE
  Blue900 = '#002999',
  Blue800 = '#0035A8',
  Blue700 = '#0041CC',
  Blue600 = '#0048E3',
  Blue500 = '#0050FF',
  Blue400 = '#4580FF',
  Blue300 = '#6E9EFF',
  Blue200 = '#8FB8FF',
  Blue100 = '#B2CFFF',
  Blue70 = '#CCDFFF',
  Blue50 = '#E3EDFF',
  Blue30 = '#EDF4FF',

  // RED
  Red900 = '#991F25',
  Red800 = '#B22B32',
  Red700 = '#CC313E',
  Red600 = '#E5404E',
  Red500 = '#FF5967',
  Red400 = '#FF737E',
  Red300 = '#FF99A2',
  Red200 = '#FFB2B9',
  Red100 = '#FFCFD3',
  Red70 = '#FFDDE0',
  Red50 = '#FFEDEE',
  Red30 = '#FFF5F6',

  // YELLOW
  Yellow900 = '#997B0E',
  Yellow700 = '#DEB215',
  Yellow500 = '#FFD633',
  Yellow300 = '#FFE880',
  Yellow100 = '#FFF3B2',

  // ORANGE
  Orange900 = '#BD5500',
  Orange800 = '#CC5F00',
  Orange700 = '#E06E02',
  Orange600 = '#F28100',
  Orange500 = '#FF9300',
  Orange400 = '#FFA933',
  Orange300 = '#FFB44F',
  Orange200 = '#FFC575',
  Orange100 = '#FFDCAB',
  Orange70 = '#FFE6C4',
  Orange50 = '#FFEFD9',
  Orange30 = '#FFF7ED',

  // header theme
  BlueTheme = '#002066',

  // HOSPITAL
  AheadMint = '#31BEC8',

  // SNS
  KakaoYellow = '#FFE812',
  NaverGreen = '#1EC700',
  AppleBlack = '#000000',

  //Line
  ColorLink = '#0b4ecb',
}
