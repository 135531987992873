import { FC, useEffect, useState } from 'react'
import { MutationFunction } from '@apollo/client'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { ApiMutation } from '@/components/api'
import { ImageAsset, TextLegacy } from '@/components/common'
import { NewRow } from '@/components/common/layouts/NewRow'
import { GlobalColorEnum } from '@/constants/global-color.enum'
import MUTATION_VARIABLES from '@/containers/gqls/base/mutation_variables'
import { useUserInfo } from '@/containers/hooks'
import { doSetToggleModalLogin } from '@/stores/reduxUI'

type LikeProductReviewProps = {
  reviewId: string
  isLikedDefault: boolean
  likedCountDefault: number
}

const LikeProductReview: FC<LikeProductReviewProps> = ({ reviewId, isLikedDefault, likedCountDefault }) => {
  const [isLiked, toggleIsLiked] = useState<boolean>(false)
  const dispatch = useDispatch()
  const { hasSignedIn } = useUserInfo

  useEffect(() => {
    toggleIsLiked(isLikedDefault)
  }, [isLikedDefault])

  const getLikedCount = () => {
    if (isLikedDefault && !isLiked) {
      return likedCountDefault - 1
    }

    if (!isLikedDefault && isLiked) {
      return likedCountDefault + 1
    }

    return likedCountDefault
  }

  const onClick = (mutation: MutationFunction) => {
    if (!hasSignedIn()) {
      dispatch(doSetToggleModalLogin(true))
      return
    }

    const variables = MUTATION_VARIABLES.TOGGLE_REVIEW_LIKED({ id: reviewId })
    // @ts-ignore
    mutation({
      variables,
    })

    toggleIsLiked(!isLiked)
  }

  const _text = isLiked ? '도움이 됐어요!' : null

  return (
    <ApiMutation gqlKey="likeReview">
      {(mutation: MutationFunction) => (
        <NewRow justify="flex-end" align="center" gap="1rem">
          {_text && (
            <TextLegacy size={{ xs: 13, md: 15 }} weight="bold">
              {_text}
            </TextLegacy>
          )}
          <StyledButton
            className="button"
            onClick={() => onClick(mutation)}
            color={isLiked ? GlobalColorEnum.Blue500 : GlobalColorEnum.Gray200}
          >
            <NewRow justify="center" align="center">
              <ImageAsset name={isLiked ? 'thumb_up_newBlue' : 'thumb_up_new'} size={20} svg />
              <TextLegacy size={{ xs: 12, md: 14 }} color={isLiked ? 'blue500' : 'default'}>
                {getLikedCount()}
              </TextLegacy>
            </NewRow>
          </StyledButton>
        </NewRow>
      )}
    </ApiMutation>
  )
}

const StyledButton = styled.div<{ color: string }>`
  padding: 9px 15px;
  border: ${({ color }) => `1px solid ${color}`};
  border-radius: 4px;
  background-color: ${(props) => props.theme.color.grayWhite};
`

export default LikeProductReview
