import { useRef, useCallback } from 'react'

type ElementRefNode = HTMLDivElement

type UseIntersectionObserverHooks = (
  callback: (referenceNode: ElementRefNode) => void,
  options?: IntersectionObserverInit,
  deps?: unknown
) => { elementRef: (node: ElementRefNode) => void }

/**
 * 인피니트 스크롤이나 이벤트 트래킹에서 사용
 * @param callback node가 관찰(observe)되면 실행하는 함수 (parameter는 관찰된 node)
 * @param options
 * @param deps
 */
const useIntersectionObserver: UseIntersectionObserverHooks = (callback, options, deps) => {
  const observerRef = useRef<IntersectionObserver | null>(null)
  const _options: IntersectionObserverInit = options ?? { rootMargin: '200%' }

  const _disconnectObserver = () => {
    observerRef.current?.disconnect()
  }

  const elementRef = useCallback(
    (node: ElementRefNode) => {
      observerRef.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          callback(node)
          _disconnectObserver() // Element 관찰이 되면 관찰을 중지함
        }
      }, _options)

      if (node) {
        observerRef.current?.observe(node)
      }
    },
    [deps]
  )

  return {
    elementRef,
  }
}

export default useIntersectionObserver
