/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * Fitpet Mall
 * 핏펫몰 API 스펙

API 스펙 작성시 다음 가이드를 숙지하시기 바랍니다.

- [Open API 가이드 참고](https://swagger.io/docs/specification/about/)
- [Kotlin-Spring Open API Generator 설정 참고](https://openapi-generator.tech/docs/generators/kotlin-spring)
- REST API 디자인 가이드 참고
  - doc > guide > rest-api-design.md
- OpenAPI Spec 컨벤션 참고
  - doc > guid > openapi-spec-convention.md
- 설명(description) 작성시 마크다운([CommonMark](https://commonmark.org/help/)) 구문 참고

로컬에서 스웨거 에디터를 사용하는 방법은 다음과 같다.

```shell
docker pull swaggerapi/swagger-editor
docker run -d -p 8123:8080 swaggerapi/swagger-editor
open http://localhost:8123
```

Api 스펙을 하나의 파일로 합치려면 다음과 같이 실행한다.

```shell
# 설치
npm install -g swagger-cli

# 실행
swagger-cli bundle api-spec.yaml --outfile build/openapi.yaml --type yaml
```
 * OpenAPI spec version: 1.0.0
 */

/**
 * - `INTERNAL_ERROR` : 내부 오류
- `VALIDATION_ERROR` : 밸리데이션 오류
- `BAD_REQUEST` : 요청이 올바르지 않음
- `NOT_FOUND` : 요청 대상을 찾을 수 없음
- `METHOD_NOT_ALLOWED` : 적절하지 않은 메소드 호출
 */
export type ErrorItemResponseDtoCode = typeof ErrorItemResponseDtoCode[keyof typeof ErrorItemResponseDtoCode]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ErrorItemResponseDtoCode = {
  INTERNAL_ERROR: 'INTERNAL_ERROR',
  VALIDATION_ERROR: 'VALIDATION_ERROR',
  BAD_REQUEST: 'BAD_REQUEST',
  NOT_FOUND: 'NOT_FOUND',
  METHOD_NOT_ALLOWED: 'METHOD_NOT_ALLOWED',
} as const
