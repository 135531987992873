import { FC, ReactNode } from 'react'
import { DefaultTheme } from 'styled-components'
import { ValueOf } from 'type-fest'
import {
  Body1,
  Body2,
  Body3,
  Body4,
  Body5,
  Caption1,
  Caption2,
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
} from '@/components/common/typeface/text.style'
import { TextComponentNameEnum } from '@/constants/text-component-name.enum'

type StyledFontColor = { fontColor?: ValueOf<DefaultTheme['color']> }
type StyledFontColorAndWeight = StyledFontColor & { fontWeight?: ValueOf<DefaultTheme['fontWeight']> }
type SubComponents = {
  [key in TextComponentNameEnum]: FC<{ as?: string; children: ReactNode } & StyledFontColorAndWeight>
}

const Text: FC & SubComponents = () => {
  return <></>
}

// @ts-ignore
Text.H1 = H1
// @ts-ignore
Text.H2 = H2
// @ts-ignore
Text.H3 = H3
// @ts-ignore
Text.H4 = H4
// @ts-ignore
Text.H5 = H5
// @ts-ignore
Text.H6 = H6

// @ts-ignore
Text.Body1 = Body1
// @ts-ignore
Text.Body2 = Body2
// @ts-ignore
Text.Body3 = Body3
// @ts-ignore
Text.Body4 = Body4
// @ts-ignore
Text.Body5 = Body5

// @ts-ignore
Text.Caption1 = Caption1
// @ts-ignore
Text.Caption2 = Caption2

export default Text
