/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * Fitpet Mall
 * 핏펫몰 API 스펙

API 스펙 작성시 다음 가이드를 숙지하시기 바랍니다.

- [Open API 가이드 참고](https://swagger.io/docs/specification/about/)
- [Kotlin-Spring Open API Generator 설정 참고](https://openapi-generator.tech/docs/generators/kotlin-spring)
- REST API 디자인 가이드 참고
  - doc > guide > rest-api-design.md
- OpenAPI Spec 컨벤션 참고
  - doc > guid > openapi-spec-convention.md
- 설명(description) 작성시 마크다운([CommonMark](https://commonmark.org/help/)) 구문 참고

로컬에서 스웨거 에디터를 사용하는 방법은 다음과 같다.

```shell
docker pull swaggerapi/swagger-editor
docker run -d -p 8123:8080 swaggerapi/swagger-editor
open http://localhost:8123
```

Api 스펙을 하나의 파일로 합치려면 다음과 같이 실행한다.

```shell
# 설치
npm install -g swagger-cli

# 실행
swagger-cli bundle api-spec.yaml --outfile _build/openapi.yaml --type yaml
```
 * OpenAPI spec version: 1.0.0
 */

/**
 * - `DISPLAY_ORDER`: MD 추천 순서
- `FINAL_DISCOUNT_RATE`: 할인율 순서
- `REVIEW_COUNT`: 리뷰 순서
- `PRODUCT_PROMOTION_CUSTOMER_PRICE`: 가격 순서
- `RANDOM`: 랜덤 10개
 */
export type SearchDisplayProductInfoSortByEnum =
  typeof SearchDisplayProductInfoSortByEnum[keyof typeof SearchDisplayProductInfoSortByEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SearchDisplayProductInfoSortByEnum = {
  DISPLAY_ORDER: 'DISPLAY_ORDER',
  FINAL_DISCOUNT_RATE: 'FINAL_DISCOUNT_RATE',
  REVIEW_COUNT: 'REVIEW_COUNT',
  PRODUCT_PROMOTION_CUSTOMER_PRICE: 'PRODUCT_PROMOTION_CUSTOMER_PRICE',
  RANDOM: 'RANDOM',
} as const
