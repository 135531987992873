import { FC, ReactNode } from 'react'
import styled from 'styled-components'
import { NewCol } from '@/components/common/layouts/NewCol'
import { NewRow } from '@/components/common/layouts/NewRow'
import TextLegacy, {
  FontColorType,
  FontResponsiveSizeType,
  FontResponsiveWeightType,
  FontSizeType,
  FontWeightType,
} from '@/components/common/texts/TextBase'
import { GlobalColorEnum } from '@/constants/global-color.enum'

type PlainBorderButtonProps = {
  text: string
  textStyle?: {
    weight?: FontWeightType | FontResponsiveWeightType
    color?: FontColorType
    size?: FontSizeType | FontResponsiveSizeType
  }
  height?: string | number
  suffix?: ReactNode
  backgroundColor?: string
  borderColor?: string
  disabled?: boolean
  onClick?: () => void
}

const PlainBorderButtonLegacy: FC<PlainBorderButtonProps> = ({
  text,
  suffix,
  onClick,
  textStyle = {
    size: { xs: 12, md: 14 },
  },
  height = 'inherit',
  disabled = false,
  backgroundColor = GlobalColorEnum.GrayWhite,
  borderColor = GlobalColorEnum.Gray200,
}) => {
  return (
    <StyledButton
      className={!disabled ? 'button' : ''}
      onClick={onClick}
      style={{ height }}
      justify="center"
      align="center"
      color={backgroundColor}
      border={borderColor}
    >
      <NewCol>
        <TextLegacy {...textStyle}>{text}</TextLegacy>
      </NewCol>
      {suffix && <NewCol>{suffix}</NewCol>}
    </StyledButton>
  )
}

const StyledButton = styled(NewRow)<{ color: string; border: string }>`
  border-radius: 8px;
  border: ${({ border }) => `1px solid ${border}`};
  background-color: ${({ color }) => color};
  padding: 5px 15px;
  flex-wrap: nowrap;
`

export default PlainBorderButtonLegacy
