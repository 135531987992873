import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled, { css, useTheme } from 'styled-components'
import { CouponCoverageScheme } from '@/apis/rest/generated/types'
import { Radio, RadioButton } from '@/components/common/inputs/RadioButton'
import { BottomSheetModal } from '@/components/common/modals/BottomSheetModal'
import { Typo } from '@/components/common/typography'
import { NewCouponCard } from '@/components/domains/coupon/new-coupon-card'
import { GlobalColorEnum } from '@/constants/global-color.enum'
import useCartEventTracker from '@/containers/event-tracker/cart.event-tracker'
import { useCartController } from '@/containers/hooks/cart/useCartController'
import { doSetBottomSheetData, doSetCartProductCouponBottomSheetData } from '@/stores/reduxData'
import { RootState } from '@/stores/store'
import { getAmountText, getBenefitTextNew, getDatePeriodTextNew } from '@/utils/coupon.util'
import { removeScrollbar } from '@/utils/utilCSS'
import { currencyText } from '@/utils/utilNumber'

const CartProductCouponBottomSheetContents = () => {
  const bottomSheetData = useSelector((state: RootState) => state.reduxDataReducers.cartProductCouponBottomSheetData)
  const [selectedId, setSelectedId] = useState<number | undefined>(undefined)
  const dispatch = useDispatch()
  const { color } = useTheme()
  const { applyCoupon, cancelApplyCoupon } = useCartController()
  const { trackClickProductCouponApplyEvent } = useCartEventTracker()
  const cartStorageData = useSelector((state: RootState) => state.reduxDataReducers.cartData)

  const appliedCoupon = bottomSheetData?.coupons.find((coupon) => coupon.isApplied)
  const selectedCouponInfo = bottomSheetData?.coupons.find((coupon) => coupon.id === selectedId)

  const handleSelectCoupon = (id: number | undefined) => {
    setSelectedId(id)
  }

  const handleConfirmCoupon = () => {
    if (!bottomSheetData?.optionId) {
      return
    }

    if (appliedCoupon?.id === selectedId) {
      dispatch(doSetBottomSheetData({ isCartProductCouponVisible: false }))
      return
    }

    const target = cartStorageData?.shippings
      .flatMap((group) => group.orderItems)
      .find((item) => item.productOptionId === bottomSheetData?.optionId)

    if (target && selectedCouponInfo) {
      trackClickProductCouponApplyEvent({
        couponId: selectedId,
        productOptionId: bottomSheetData.optionId,
        expiredAt: selectedCouponInfo?.expiredAt,
        productId: target?.productId,
        couponDiscountAmount: selectedCouponInfo?.discountAmount,
        productOptionName: target?.productOptionName,
        productName: target?.productName,
        buttonText: !!selectedId
          ? `${currencyText(selectedCouponInfo?.appliedDiscountAmount || 0)} 할인 적용하기`
          : '쿠폰 적용하지 않기',
        couponName: selectedCouponInfo.name,
      })
    }

    if (selectedId) {
      // dispatch
      applyCoupon(bottomSheetData.optionId, selectedId)
    } else {
      // dispatch
      cancelApplyCoupon(bottomSheetData.optionId)
    }
  }

  useEffect(() => {
    setSelectedId(appliedCoupon?.id || undefined)
  }, [appliedCoupon])

  if (!bottomSheetData) return null
  return (
    <>
      <StyledCouponListContainer count={bottomSheetData.coupons.length}>
        <StyledCouponList>
          <StyledCoupon selected={!selectedId} disabled={false} onClick={() => handleSelectCoupon(undefined)}>
            <Typo variant="Body2 Bold">쿠폰 적용 안함</Typo>
            <Radio type="radio" checked={!selectedId} variant={'medium'} readOnly />
          </StyledCoupon>
          {bottomSheetData.coupons.map(
            ({
              id,
              name,
              isMembership,
              isAvailable,
              startedAt,
              expiredAt,
              canConjunctUse,
              discountType,
              discountRate,
              issueType,
              minOrderAmount,
              maxDiscountAmount,
              hasUsingPeriod,
              hasSpecifiedCoverage,
              coverage,
              appliedDiscountAmount,
              discountAmount,
            }) => {
              const couponTitle = getBenefitTextNew({
                appliedDiscountAmount,
                discountAmount,
                discountType,
                discountRate,
                issueType,
                name,
                isAvailable,
              })
              const amountText = getAmountText({
                hasMinOrderAmount: minOrderAmount > 0,
                minOrderAmount,
                maxDiscountAmount,
              })
              const datePeriodText = getDatePeriodTextNew({
                hasUsingPeriod,
                startedAt: new Date(startedAt),
                expiredAt: new Date(expiredAt),
                hasTime: true,
              })

              return (
                <StyledCoupon
                  key={id}
                  selected={selectedId === id}
                  disabled={!isAvailable}
                  onClick={() => isAvailable && handleSelectCoupon(id)}
                >
                  <NewCouponCard
                    title={couponTitle}
                    name={name}
                    amountText={amountText}
                    datePeriodText={datePeriodText}
                    isMembership={isMembership}
                    isConjunctUse={canConjunctUse}
                    disabled={!isAvailable}
                    hasSpecifiedCoverage={hasSpecifiedCoverage}
                    coverage={coverage as CouponCoverageScheme}
                  />
                  <RadioButton
                    id={id.toString()}
                    variant="medium"
                    checked={selectedId === id}
                    disabled={!isAvailable}
                    readOnly
                  />
                </StyledCoupon>
              )
            }
          )}
        </StyledCouponList>

        <ApplyCouponButtonWrapper>
          <ApplyCouponButton onClick={handleConfirmCoupon} disabled={appliedCoupon?.id === selectedId}>
            <Typo variant="Body1 Bold" color={color.grayWhite}>
              {!!selectedId
                ? `${currencyText(selectedCouponInfo?.appliedDiscountAmount || 0)} 할인 적용하기`
                : '쿠폰 적용하지 않기'}
            </Typo>
          </ApplyCouponButton>
        </ApplyCouponButtonWrapper>
      </StyledCouponListContainer>
    </>
  )
}

export const CartProductCouponBottomSheet = ({ visible }: { visible: boolean }) => {
  const dispatch = useDispatch()

  return (
    <BottomSheetModal
      title="상품쿠폰 선택"
      centerTitle
      titleNoBorder
      visible={visible}
      onClose={() => {
        dispatch(
          doSetBottomSheetData({
            isCartProductCouponVisible: false,
          })
        )
      }}
    >
      <CartProductCouponBottomSheetContents />
    </BottomSheetModal>
  )
}

const StyledCouponListContainer = styled.div<{ count: number }>``

const StyledCouponList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  border-radius: 0.8rem;

  overflow: auto;
  max-height: calc(90vh - 82px);
  padding: 1.6rem 1.6rem calc(env(safe-area-inset-bottom) + 8.4rem) 1.6rem;
  //padding-bottom: calc(env(safe-area-inset-bottom) + 8.4rem);
  ${removeScrollbar};
`

const StyledCoupon = styled.div<{ selected: boolean; disabled: boolean }>`
  padding: 1.6rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: ${({ theme, selected }) =>
    selected ? `1px solid ${theme.color.blue500}` : `1px solid ${theme.color.gray100}`};
  box-sizing: border-box;
  border-radius: 0.8rem;
  background-color: ${(props) => props.theme.color.grayWhite};
  cursor: pointer;
  &[disabled] {
    border-color: ${({ theme }) => theme.color.gray100};
  }
`

const ApplyCouponButtonWrapper = styled.div`
  padding: 1.6rem 1.6rem calc(env(safe-area-inset-bottom) + 1.6rem);

  position: sticky;
  background-color: ${({
    theme: {
      color: { grayWhite },
    },
  }) => grayWhite};
  bottom: 0;
  left: 0;
  width: 100%;
  box-shadow: rgb(0 0 0 / 5%) 0 -2px 6px;
`

const ApplyCouponButton = styled.button<{ disabled: boolean }>`
  width: 100%;
  padding: 1.4rem 1.6rem;
  background-color: ${({
    theme: {
      color: { blue500, gray200 },
    },
    disabled,
  }) => (disabled ? gray200 : blue500)};
  border-radius: 0.8rem;
  border: none;
`
