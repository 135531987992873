import { gql } from '@apollo/client'

const CATEGORY_FRAGMENTS = {
  get categoryMinimum() {
    return gql`
      fragment categoryMinimum on CategoryType {
        id
        name
        text
        isVisible
        listImage
        slug
      }
    `
  },
  get searchFilterCategory() {
    return gql`
      fragment searchFilterCategory on SearchCategoryConnection {
        edges {
          node {
            id
            name
            code
            categoryChildren {
              edges {
                node {
                  id
                  name
                  code
                }
              }
            }
          }
        }
      }
    `
  },
}

export default CATEGORY_FRAGMENTS
