import { useDispatch } from 'react-redux'
import ROUTES from '@/constants/legacy/constRoutes'
import MUTATION_VARIABLES from '@/containers/gqls/base/mutation_variables'
import { useMessage, useApiMutation, useCustomRouter } from '@/containers/hooks'
import { UserContainer } from '@/containers/users/UserContainer'
import { doSetToggleRefetchOrderList } from '@/stores/reduxData'
import { doSetGlobalNoticeModal } from '@/stores/reduxUI'

type UseOrderCancelType = {
  confirmCancelAllUnpaidOrderItems: (orderItemIds: string[]) => void
}

const useOrderCancel = (): UseOrderCancelType => {
  const dispatch = useDispatch()
  const { show } = useMessage()
  const { pathname, back } = useCustomRouter()

  const { refreshUserInfo } = UserContainer.useContainer()

  const _onAllOrderItemsCancelled = () => {
    show('주문이 취소되었습니다.')
    refreshUserInfo()

    if (ROUTES.MYPAGE.ORDER_DETAIL === pathname) {
      back()
    } else {
      dispatch(doSetToggleRefetchOrderList(true))
    }
  }

  const [cancelOrderItemsMutation] = useApiMutation('cancelOrderItems', _onAllOrderItemsCancelled)
  const _cancelOrderItems = (orderItemIds: string[]) => {
    const params = {
      orderItems: orderItemIds.map((id) => ({ orderItem: id })),
    }

    const variables = MUTATION_VARIABLES.CANCEL_ORDER_ITEMS(params)
    // @ts-ignore
    cancelOrderItemsMutation({ variables })
  }

  const confirmCancelAllUnpaidOrderItems = (orderItemIds: string[]) => {
    dispatch(
      doSetGlobalNoticeModal({
        buttonType: 'ACTION_CANCEL',
        okText: '주문취소',
        cancelText: '취소',
        text: '주문을 취소하시겠습니까?',
        subText: '주문하신 전체 상품이 취소됩니다.',
        onOk: () => _cancelOrderItems(orderItemIds),
        visible: true,
      })
    )
  }

  return {
    confirmCancelAllUnpaidOrderItems,
  }
}

export default useOrderCancel
