import { FC, memo } from 'react'
import styled, { useTheme } from 'styled-components'
import FlatIcon from '@/components/common/icons/FlatIcon'
import { IconNameEnum } from '@/constants/icon-name.enum'

type ReviewStarRatingProps = {
  rating: number
}

const ReviewStarRating: FC<ReviewStarRatingProps> = ({ rating }) => {
  const { color, iconSize } = useTheme()

  return (
    <StyledStarRating>
      {Array.from({ length: 5 }).map((_, index) => (
        <FlatIcon
          key={index}
          type={IconNameEnum.IcStar}
          color={index < rating ? color.yellow500 : color.gray100}
          size={iconSize.size12}
        />
      ))}
    </StyledStarRating>
  )
}

const StyledStarRating = styled.div`
  display: flex;
  align-items: center;
  gap: 1px;
`

export default memo(ReviewStarRating)
