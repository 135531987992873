import styled from 'styled-components'
import { SkeletonRecommendKeyword } from '@/components/domains/search/skeleton'
import { QueryStringKeyEnum } from '@/constants/query-string-key.enum'
import { SearchLocationEnum } from '@/constants/search-location.enum'
import { useTracker } from '@/containers/contexts/EventTrackingProvider'
import { useCustomRouter, useSearch } from '@/containers/hooks'
import { useRecommendSearchKeywordQuery } from '@/graphql/generated/hooks'
import { FitpetMallEventEnum } from '@/utils/event-tracker/events'

const RecommendKeywords = () => {
  const { goToResultPage } = useSearch()
  const { triggerCustomEvent } = useTracker()
  const {
    query: { [QueryStringKeyEnum.SearchPetType]: searchPetType },
  } = useCustomRouter()

  const { loading, data, error } = useRecommendSearchKeywordQuery({
    fetchPolicy: 'cache-first',
    variables: {
      petType: searchPetType as string,
    },
  })

  const recommendSearchKeywords = data?.recommendSearchKeywords || []

  const onClickRecommendKeyword = (targetKeyword: string, keywordIndex: number) => {
    triggerCustomEvent(FitpetMallEventEnum.ClickSearchRecommendKeyword, {
      search_keyword: targetKeyword,
      list_index: keywordIndex,
      pet_type_search: searchPetType,
    })
    goToResultPage({
      [QueryStringKeyEnum.SearchQuery]: targetKeyword,
      [QueryStringKeyEnum.SearchLocation]: SearchLocationEnum.Recommend,
    })
  }

  return (
    <>
      {error && <></>}
      <StyledTitle>핏펫 추천 검색어</StyledTitle>
      {!loading ? (
        <KeywordsList>
          {recommendSearchKeywords.map((item, index) => (
            <RecommendKeyword
              key={index}
              onClick={() => onClickRecommendKeyword(item, index)}
              data-cy="recommend-search-keyword"
            >
              {item}
            </RecommendKeyword>
          ))}
        </KeywordsList>
      ) : (
        <SkeletonRecommendKeyword />
      )}
    </>
  )
}

const StyledTitle = styled.div`
  padding-left: 16px;
  margin-bottom: 12px;
  font-size: 16px;
  font-weight: 700;
  color: ${({ theme: { color } }) => color.gray900};
`

const KeywordsList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding: 0 16px;
`

const RecommendKeyword = styled.div`
  display: inline-block;
  padding: 8px 14px;
  min-width: max-content;
  border-radius: 24px;
  background-color: ${({ theme: { color } }) => color.blue50};
  cursor: pointer;
  font-size: 15px;
  white-space: nowrap;
  line-height: 20px;
  font-weight: 500;
  color: ${({ theme: { color } }) => color.blue500};

  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
`

export default RecommendKeywords
