import { PlatformDetailEnum } from '@/constants/platform-detail.enum'
import { InAppBridge } from '@/utils/bridge'
import { FitpetMallEventEnum } from '@/utils/event-tracker/events'
import { BaseEventHandler } from '@/utils/event-tracker/handlers/baseEventHandler'
import { isInAppFlag } from '@/utils/utilBridge'
import { isInApp } from '@/utils/utilCommon'

// eslint-disable-next-line global-require
const Braze = process.browser && require('@braze/web-sdk')

export class BrazeHandler extends BaseEventHandler {
  private brazeAPIKey: string = process.env.BRAZE_API_KEY || ''
  private baseUrl: string = 'sdk.iad-05.braze.com'
  private appBridge: InAppBridge = new InAppBridge()
  /**
   * triggerCustomEvent 에 사용되는 이벤트 트래커 지정
   */

  private brazeEvents: Array<FitpetMallEventEnum> = [
    FitpetMallEventEnum.CompleteSignup,
    FitpetMallEventEnum.CompleteLogin,
    FitpetMallEventEnum.ViewProductdetail,
    FitpetMallEventEnum.View100Wondeal,
    FitpetMallEventEnum.ViewEventsaleDetail,
    FitpetMallEventEnum.ViewCart,
    FitpetMallEventEnum.ClickReviewwriteCompleted,
    FitpetMallEventEnum.CompletePurchase,
    FitpetMallEventEnum.SubmitSearchKeyword,
    FitpetMallEventEnum.ClickLikeProduct,
    FitpetMallEventEnum.ClickListLikeButton,
    FitpetMallEventEnum.ClickUnlikeButton,
    FitpetMallEventEnum.ViewLikeproduct,
    FitpetMallEventEnum.ViewSearch,
    FitpetMallEventEnum.ClickSearchRecentKeyword,
    FitpetMallEventEnum.ClickSearchAutoKeyword,
    FitpetMallEventEnum.ClickSearchBrand,
    FitpetMallEventEnum.ClickSearchPopularKeyword,
    FitpetMallEventEnum.ViewSearchResult,
    FitpetMallEventEnum.ClickMypageBanner,
    FitpetMallEventEnum.ViewFriendevent,
    FitpetMallEventEnum.ViewInvitedFriendevent,
    FitpetMallEventEnum.ClickFriendeventSignupButton,
    FitpetMallEventEnum.ClickOrdercompleteBanner,
    FitpetMallEventEnum.ClickSearchRecommendKeyword,
    // 리스트 장바구니, 바로구매 관련
    FitpetMallEventEnum.ViewOrderlist,
    FitpetMallEventEnum.ClickOrderlistConfirmButton,
    FitpetMallEventEnum.ClickOrderlistReviewButton,
    FitpetMallEventEnum.ClickOrderlistAddtocartButton,
    FitpetMallEventEnum.ClickMypageMarketingAgreement,

    FitpetMallEventEnum.ClickEventsaleDetailImage,
    FitpetMallEventEnum.ClickEventsaleCouponDownload,

    // home
    FitpetMallEventEnum.ViewHome,
    FitpetMallEventEnum.ViewHomeBanner,
    FitpetMallEventEnum.ViewMypage,
    FitpetMallEventEnum.ViewRecentproduct,
    FitpetMallEventEnum.ViewProductdetail,
    FitpetMallEventEnum.ViewEventsaleDetail,
    FitpetMallEventEnum.ClickEventsaleProduct,
    FitpetMallEventEnum.ClickSearchListProduct,
    // 카테고리
    FitpetMallEventEnum.ClickCategoryItem,
    FitpetMallEventEnum.ClickCategoryListProduct,
    FitpetMallEventEnum.ClickCategoryTab,
    FitpetMallEventEnum.ViewCategory,
    FitpetMallEventEnum.ClickCategoryListTab,
    FitpetMallEventEnum.ClickHomeCategoryItem,
    FitpetMallEventEnum.ClickHomeCategoryTab,

    FitpetMallEventEnum.ViewHomeFloatingbannerTop,
    FitpetMallEventEnum.ClickGnbCartIcon,
    FitpetMallEventEnum.ClickGnbHomeIcon,
    FitpetMallEventEnum.ClickGnbPetToggle,
    FitpetMallEventEnum.ClickGnbSearchIcon,
    FitpetMallEventEnum.ClickHomeBanner,
    FitpetMallEventEnum.ClickMypageMarketingNightAgreement,
    FitpetMallEventEnum.ClickMypageMileage,
    FitpetMallEventEnum.ClickMypageRecentproduct,
    FitpetMallEventEnum.ClickMypageReview,
    FitpetMallEventEnum.ClickMypageCoupon,
    FitpetMallEventEnum.ClickMypageOrder,
    FitpetMallEventEnum.ClickMypageWithdrawal,
    FitpetMallEventEnum.ClickMypageWithdrawalComplete,
    FitpetMallEventEnum.ClickOrderlistAddtocartButton,
    FitpetMallEventEnum.ClickProductdetailSharetypeButton,
    FitpetMallEventEnum.ClickBrandListProduct,
    FitpetMallEventEnum.ClickSearchPopularKeyword,
    FitpetMallEventEnum.ClickRecentlyProduct,
    FitpetMallEventEnum.ClickSearchRelatedKeyword,
    FitpetMallEventEnum.ClickUnlikeButton,
    FitpetMallEventEnum.ClickEventsalepageListItem,
    FitpetMallEventEnum.ClickHomeBannerReviewrequest,
    FitpetMallEventEnum.ClickHomeBrandshopItem,
    FitpetMallEventEnum.ClickHomeDisplayProduct,
    FitpetMallEventEnum.ClickHomeFloatingbannerTop,
    FitpetMallEventEnum.ClickHomeNewProduct,
    FitpetMallEventEnum.ClickHomeSpecialsaleProduct,
    FitpetMallEventEnum.ClickHomeTopmenuTab,
    FitpetMallEventEnum.ViewHomeBrandShopBrand,
    FitpetMallEventEnum.ClickHomeWeeklybestProduct,
    FitpetMallEventEnum.ClickMainRecentproductButton,
    FitpetMallEventEnum.ClickMypageFitpetmallSection,
    FitpetMallEventEnum.ClickMypagePet,
    FitpetMallEventEnum.ClickMypagePetAdd,
    FitpetMallEventEnum.ClickSignupEmail,
    FitpetMallEventEnum.ClickSignupLogin,
    FitpetMallEventEnum.ClickSignupNaver,
    // 장바구니
    FitpetMallEventEnum.ClickCartDeleteIcon,
    FitpetMallEventEnum.ClickCartDeleteSelected,
    FitpetMallEventEnum.ViewCartLikeTab,
    FitpetMallEventEnum.ViewCartFrequentTab,
    FitpetMallEventEnum.ClickHomeSectionProduct,
    FitpetMallEventEnum.ClickHomeSectionMore,
    FitpetMallEventEnum.ClickCartNudgeMessage,
    FitpetMallEventEnum.ViewCartNudgeMessage,
    // 회원가입 완료 페이지(welcome)
    FitpetMallEventEnum.ClickSignUpCompleteMainbanner,
    FitpetMallEventEnum.ClickSignUpCompleteBanner,
    // 파트너스 페이지
    FitpetMallEventEnum.ViewEventSalePartners,
    FitpetMallEventEnum.ClickEventSalePartnersRegister,
    FitpetMallEventEnum.ClickEventSalePartnersShare,
    // 바텀시트
    FitpetMallEventEnum.ClickAddCartBottomUpProduct,
    FitpetMallEventEnum.ClickListSinglePurchaseButton,
    FitpetMallEventEnum.ClickListBottomSheetToCart,
    FitpetMallEventEnum.ClickListAddToCartButton,
    FitpetMallEventEnum.ClickListStartPurchaseButton,
    FitpetMallEventEnum.ViewAddCartBottomUpProduct,
    // 상품 상세
    FitpetMallEventEnum.ClickProductDetailAddToCartButton,
    FitpetMallEventEnum.ClickProductDetailSinglePurchaseButton,
    // 파트너스
    FitpetMallEventEnum.ViewEventSalePartners,
    FitpetMallEventEnum.ClickEventSalePartnersRegister,
    FitpetMallEventEnum.ClickEventSalePartnersShare,
    FitpetMallEventEnum.ClickPartnersRegisterRegister,
    FitpetMallEventEnum.ViewPartnersDetail,
    FitpetMallEventEnum.ClickPartnersDetailMakeCoupon,
    FitpetMallEventEnum.ViewPartnersDetailMyReward,
    FitpetMallEventEnum.ClickMypagePartners,
    // 액션파워
    FitpetMallEventEnum.ClickOrderListOtherInfoButton,
    // 마이페이지
    FitpetMallEventEnum.ClickMyPageHealth,
    FitpetMallEventEnum.ClickMyPageHospital,
    // 관심상품 - 자주 본 상품 + 찜한 상품
    FitpetMallEventEnum.ViewFavoritePage,
  ]
  initialize = () => {
    Braze.initialize(this.brazeAPIKey, {
      baseUrl: this.baseUrl,
      manageServiceWorkerExternally: !isInAppFlag,
      // serviceWorkerLocation: `${ process.env.HOST }/files/service-worker.js`,
      enableLogging: process.env.FITPETMALL_ENV !== 'production',
    })
    this.appBridge.init()

    if (!isInAppFlag) {
      Braze.automaticallyShowInAppMessages()
      Braze.openSession()
    }
  }

  // eslint-disable-next-line class-methods-use-this
  setUserId = (userID: string) => {
    Braze.changeUser(userID)
  }

  // eslint-disable-next-line class-methods-use-this
  setUserProperties = (properties: any) => {
    Object.keys(properties).forEach((key) => {
      Braze?.getUser()?.setCustomUserAttribute(key, properties[key])
    })
  }

  // eslint-disable-next-line class-methods-use-this
  sendPageviewEvent = (url: string) => {}

  // eslint-disable-next-line class-methods-use-this
  sendRevenueEvent = (productId: string, price: number, quantity: number, eventProperties: Record<string, any>) => {
    // logPurchase 이벤트
    const purchaseProperties = {
      order_id: eventProperties.order_id,
      payment_type: eventProperties.payment_type,
      coupon_id: eventProperties.coupons.map((v: Record<string, any>) => v.coupon_id),
      coupon_name: eventProperties.coupons.map((v: Record<string, any>) => v.coupon_name),
      mileage_discount_amount: Math.round(eventProperties.mileage_discount_amount),
      coupon_discount_amount: eventProperties.coupon_discount_amount,
      total_order_discount_amount: eventProperties.total_order_discount_amount,
      total_product_discount_amount: eventProperties.total_product_discount_amount,
    }
    Braze?.logPurchase(productId, price, 'USD', quantity, purchaseProperties)
  }
  // complete_purchase_productoption 모델 만드는 함수
  // eslint-disable-next-line class-methods-use-this
  completePurchaseProductoptionDataArray = (properties: Record<string, any>) => {
    return properties.products.map((product: Record<string, any>) => ({
      order_id: properties.order_id,
      product_id: product.product_id,
      product_name: product.product_name,
      product_option_id: product.product_options.length > 0 ? product.product_options[0].product_option_id : '',
      product_option_name: product.product_options.length > 0 ? product.product_options[0].product_option_name : '',
      product_option_quantity:
        product.product_options.length > 0 ? product.product_options[0].product_option_quantity : '',
      category_id: product.categories.length > 0 ? product.categories[0].category_id : '',
      category_name: product.categories.length > 0 ? product.categories[0].category_name : '',
    }))
  }

  sendEvent = (event: FitpetMallEventEnum, platform: PlatformDetailEnum, properties: Record<string, any>) => {
    this.setInitProperties(properties)
    if (isInApp()) {
      const data = { eventName: event, eventParam: properties }
      this.appBridge?.brazeEvent(data)
      // 예외 이벤트
      if (FitpetMallEventEnum.CompletePurchase === event) {
        this.completePurchaseProductoptionDataArray(properties).forEach((purchaseProperties: Record<string, any>) => {
          this.appBridge?.brazeEvent({ eventName: 'complete_purchase_productoption', eventParam: purchaseProperties })
        })
        // 구매 이벤트
        this.appBridge?.brazeRevenueEvent({
          eventName: 'logPurchase',
          eventParam: {
            product_id: 'product',
            currency: 'USD',
            price: properties.total_payment_amount,
            quantity: properties.product_option_quantity,
            order_id: properties.order_id,
            payment_type: properties.payment_type,
            coupon_id: properties.coupons.map((v: Record<string, any>) => v.coupon_id),
            coupon_name: properties.coupons.map((v: Record<string, any>) => v.coupon_name),
            mileage_discount_amount: Math.round(properties.mileage_discount_amount),
            coupon_discount_amount: properties.coupon_discount_amount,
            total_order_discount_amount: properties.total_order_discount_amount,
            total_product_discount_amount: properties.total_product_discount_amount,
          },
        })
      }
    } else {
      Braze?.logCustomEvent(event, properties)
      if (FitpetMallEventEnum.CompletePurchase === event) {
        // complete_purchase_productoption 이벤트
        this.completePurchaseProductoptionDataArray(properties).forEach((purchaseProperties: Record<string, any>) => {
          Braze?.logCustomEvent('complete_purchase_productoption', purchaseProperties)
        })
        // logPurchase 이벤트
        this.sendRevenueEvent(
          'product',
          properties.total_payment_amount,
          properties.product_option_quantity,
          properties
        )
      }
    }
  }

  sendCustomEvent = (event: FitpetMallEventEnum, properties: Record<string, any>, platform: PlatformDetailEnum) => {
    if (!this.brazeEvents.includes(event)) {
      return
    }
    this.sendEvent(event, platform, properties)
  }
}
