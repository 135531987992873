import { IModelProductQnaDefault } from '@/containers/models/base/defaultModelInterfaces'
import { ModelProductQnaDefault } from '@/containers/models/base/defaultModels'
import { IModelProductOption } from '@/containers/models/modelProductOption'
import { BOARD_DATE_DISPLAY_FORMAT, formatDate } from '@/utils/utilDate'

export interface IModelProductQna extends IModelProductQnaDefault {
  productOption: IModelProductOption
  getFormattedQuestionAt: () => string
  getFormattedAnswerAt: () => string
}

export default class ModelProductQna extends ModelProductQnaDefault {
  getFormattedQuestionAt = () => {
    return formatDate(this.createdAt, BOARD_DATE_DISPLAY_FORMAT)
  }

  getFormattedAnswerAt = () => {
    return formatDate(this.answeredAt, BOARD_DATE_DISPLAY_FORMAT)
  }
}
