import React, { useEffect, useState } from 'react'
import styled, { useTheme } from 'styled-components'
import FlatIcon from '@/components/common/icons/FlatIcon'
import ModalSliderPortal from '@/components/common/modals/ModalSliderPortal'
import { Typo, TypoVariant } from '@/components/common/typography'
import { IconNameEnum } from '@/constants/icon-name.enum'
import { IconSizeEnum } from '@/constants/icon-size.enum'
import { LAYOUT_UNITS, Z_INDEXES } from '@/constants/legacy/constLayout'

interface BottomSheetModalProps {
  title: string
  centerTitle?: boolean
  titleNoBorder?: boolean
  noHeader?: boolean
  onClose: () => void
  visible: boolean
  children: React.ReactNode
  onCancel?: () => void
  height?: number
}

export const BottomSheetModal = ({
  title,
  centerTitle = false,
  titleNoBorder = false,
  children,
  onClose,
  visible,
  noHeader = false,
  onCancel,
  height,
}: BottomSheetModalProps) => {
  const { color } = useTheme()
  const [isModalVisible, setIsModalVisible] = useState(visible)

  const handleClose = () => {
    onClose()
  }

  useEffect(() => {
    if (visible) {
      setIsModalVisible(true)
    } else {
      setTimeout(() => {
        setIsModalVisible(false)
      }, 300)
    }
  }, [visible])

  if (!isModalVisible) {
    return null
  }

  return (
    <ModalSliderPortal>
      <StyledContainer
        className="bottom-sheet-modal"
        data-cy="modal-slider"
        centerTitle={centerTitle}
        titleNoBorder={titleNoBorder}
      >
        <Popup visible={visible} height={height}>
          {!noHeader && (
            <div className="bottom-sheet-modal__header">
              <div className="bottom-sheet-modal__title">
                <Typo variant={TypoVariant.Heading6SemiBold} color={color.gray900}>
                  {title}
                </Typo>
              </div>
              {onCancel && typeof onCancel === 'function' && (
                <button className="bottom-sheet-modal__button" type="button" onClick={handleClose}>
                  <FlatIcon type={IconNameEnum.IcClose} color={color.gray300} size={IconSizeEnum.Size24} />
                </button>
              )}
            </div>
          )}
          <div className="bottom-sheet-modal__content">{children}</div>
        </Popup>
        <Background visible={visible} onClick={handleClose} />
      </StyledContainer>
    </ModalSliderPortal>
  )
}

const StyledContainer = styled.div<{ centerTitle: boolean; titleNoBorder: boolean }>`
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  max-width: ${LAYOUT_UNITS.BODY_MAX_WIDTH};
  z-index: ${Z_INDEXES.BOTTOM_SLIDE_POPUP};
  .bottom-sheet-modal {
    &__header {
      display: flex;
      align-items: center;
      padding: 1.4rem 1.6rem;
      border-bottom: ${({
        titleNoBorder,
        theme: {
          color: { gray200 },
        },
      }) => (titleNoBorder ? 'none' : `1px solid ${gray200}`)};
    }
    &__title {
      flex: 1;
      margin-right: auto;
      text-align: ${({ centerTitle }) => (centerTitle ? 'center' : 'left')};
    }
    &__button {
      border: none;
      background: none;
      cursor: pointer;
    }
    &__content {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
    }
  }
`

const Background = styled.div<{ visible: boolean }>`
  width: 100%;
  max-width: ${LAYOUT_UNITS.BODY_MAX_WIDTH};
  height: 100vh;
  position: fixed;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background: ${({ visible }) => (visible ? 'rgba(0, 0, 0, 0.5)' : 'rgba(0, 0, 0, 0)')};
  transition: background-color 0.3s ease;
`

const Popup = styled.div<{ visible: boolean; height?: number }>`
  z-index: ${Z_INDEXES.BOTTOM_SLIDE_POPUP};
  position: absolute;
  bottom: 0;
  display: block;
  width: 100%;
  max-width: ${LAYOUT_UNITS.BODY_MAX_WIDTH};
  max-height: ${({ height }) => (height ? `${height}vh` : '90vh')};
  border-radius: 20px 20px 0 0;
  background-color: ${(props) => props.theme.color.grayWhite};
  animation: ${({ visible }) => (visible ? `0.3s forwards slideIn` : `0.2s ease forwards slideOut`)};
  display: flex;
  flex-direction: column;
  @keyframes slideIn {
    0% {
      transform: translateY(100%);
    }
    100% {
      transform: translateY(0%);
    }
  }
  @keyframes slideOut {
    0% {
      transform: translateY(0%);
    }
    100% {
      transform: translateY(100%);
    }
  }
`
