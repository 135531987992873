import styled, { css } from 'styled-components'
import { SCREEN_MEDIA_QUERY } from '@/constants/legacy/constLayout'
import { ISelectOptions } from '@/constants/legacy/constType'

interface InputRadioProps extends Omit<React.ComponentPropsWithoutRef<'input'>, 'value'> {
  layout?: 'vertical' | 'horizontal'
  innerSize?: 'sm' | 'lg'
  options?: ISelectOptions
  value?: any
}

export const InputRadio = ({ layout, innerSize, options, value, className, onChange, ...rest }: InputRadioProps) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const targetValue = options?.[index]?.value
    onChange?.({ ...e, target: { ...e.target, value: targetValue } } as unknown as React.ChangeEvent<HTMLInputElement>)
  }

  return (
    <StyledRadioGroup className={className}>
      {options?.map((option, index) => {
        return (
          <StyledRadioLabel key={`${option.title}-${option.value}`} layout={layout} innerSize={innerSize}>
            <StyledRadioSpan innerSize={innerSize}>
              <StyledMyRadioInput
                type="radio"
                disabled={option.disabled}
                value={option.value}
                checked={value === option.value}
                onChange={(e) => handleChange(e, index)}
                {...rest}
              />
              <StyledRadioInnerSpan innerSize={innerSize} />
            </StyledRadioSpan>
            <span>{option.title}</span>
          </StyledRadioLabel>
        )
      })}
    </StyledRadioGroup>
  )
}

const StyledRadioGroup = styled.div`
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  line-height: 1.5715;
  display: inline-block;
`

const StyledRadioLabel = styled.label<Pick<InputRadioProps, 'layout' | 'innerSize'>>`
  font-size: 16px;
  font-weight: 500;
  color: ${(props) => props.theme.color.gray700};
  box-sizing: border-box;
  position: relative;
  align-items: baseline;
  margin-right: 8px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;

  ${({ layout }) => {
    if (layout === 'vertical') {
      return css`
        display: flex;
        height: 40px;
        line-height: 40px;
      `
    }
  }}

  @media ${SCREEN_MEDIA_QUERY.xs} {
    font-size: 14px;
  }
`

const StyledRadioSpan = styled.span<Pick<InputRadioProps, 'innerSize'>>`
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  display: inline-block;
  outline: none;
  cursor: pointer;
  padding-bottom: 1px;

  & > input[type='radio']:checked + span {
    border-color: ${(props) => props.theme.color.blue500};
    border-width: ${({ innerSize }) => {
      if (innerSize === 'sm') return '5px'
      if (innerSize === 'lg') return '7px'
      return '6px'
    }};
  }

  & + * {
    padding-right: 8px;
    padding-left: 8px;
  }
`

const StyledMyRadioInput = styled.input`
  box-sizing: border-box;
  padding: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  cursor: pointer;
  opacity: 0;
  color: ${(props) => props.theme.color.blue500};
  font-weight: 600;
`

const StyledRadioInnerSpan = styled.span<Pick<InputRadioProps, 'innerSize'>>`
  position: relative;
  top: 0;
  left: 0;
  display: block;
  background-color: #fff;
  border-color: #d9d9d9;
  border-style: solid;
  border-radius: 50%;
  border-width: 2px;
  transition: all 0.3s;

  width: ${({ innerSize }) => {
    if (innerSize === 'sm') return '16px'
    if (innerSize === 'lg') return '24px'
    return '20px'
  }};
  height: ${({ innerSize }) => {
    if (innerSize === 'sm') return '16px'
    if (innerSize === 'lg') return '24px'
    return '20px'
  }};

  &:after {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: 16px;
    height: 16px;
    margin-top: -8px;
    margin-left: -8px;
    background-color: #1890ff;
    border-top: 0;
    border-left: 0;
    border-radius: 16px;
    transform: scale(0);
    opacity: 0;
    transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
    content: ' ';
  }
`

export default InputRadio
