import { CartProductKeyType, CartProductType, CartProductTypeUtil } from '@/containers/types/CartProduct'
import {
  CartProductOptionKeyType,
  CartProductOptionType,
  CartProductOptionTypeUtil,
} from '@/containers/types/CartProductOption'
import { deepcopy } from '@/utils/utilData'

export type CartShippingGroupAmountType = {
  totalProductOptionAmount: number
  totalProductOptionPromotionAmount: number
  totalProductOptionDiscountAmount: number
  totalShippingFee: number
  totalPaymentAmount: number
}

export type CartShippingGroupType = {
  shippingId?: string
  seller: {
    id: string
    shopName: string
    shippingFee: number
    isPackage: boolean
    freeShippingBaseAmount?: number
  }
  cartProducts: CartProductType[]
}

export type CartShippingGroupTypeUtilType = {
  getCartShippingGroupAmount: (cartShippingGroups: CartShippingGroupType[]) => CartShippingGroupAmountType
  getCartProductsChecked: (cartShippingGroups: CartShippingGroupType[]) => CartProductType[]
  getCartProductOptionsChecked: (cartShippingGroups: CartShippingGroupType[]) => CartProductOptionType[]
  getCartProductInCartShippingGroup: (
    cartShippingGroups: CartShippingGroupType[],
    cartProductKey: CartProductKeyType
  ) => CartProductType | undefined
  getCartProductOptionInCartShippingGroup: (
    cartShippingGroups: CartShippingGroupType[],
    cartProductOptionKey: CartProductOptionKeyType
  ) => CartProductOptionType | undefined
  getCheckedCartShippingGroups: (cartShippingGroups: CartShippingGroupType[]) => CartShippingGroupType[]
  removeCartProductInCartShippingGroup: (
    cartShippingGroups: CartShippingGroupType[],
    cartProductKeys: CartProductKeyType[]
  ) => CartShippingGroupType[]
  removeCartProductOptionInCartShippingGroup: (
    cartShippingGroups: CartShippingGroupType[],
    cartProductOptionKeys: CartProductOptionKeyType[]
  ) => CartShippingGroupType[]
  updateCartShippingGroups: (
    oriCartShippingGroups: CartShippingGroupType[],
    cartShippingGroupsForUpdate: CartShippingGroupType[]
  ) => CartShippingGroupType[]
}

const getCartShippingGroupAmount = (cartShippingGroups: CartShippingGroupType[]): CartShippingGroupAmountType => {
  const cartShippingGroupAmount: CartShippingGroupAmountType = {
    totalProductOptionAmount: 0,
    totalProductOptionPromotionAmount: 0,
    totalProductOptionDiscountAmount: 0,
    totalShippingFee: 0,
    totalPaymentAmount: 0,
  }

  cartShippingGroups.forEach((cartShippingGroup) => {
    const { seller } = cartShippingGroup
    const { shippingFee, freeShippingBaseAmount = 0 } = seller
    let productOptionPromotionAmountOfShipping = 0

    // const cartShippingGroupChecked = cartShippingGroup.cartProducts.filter((cartProduct) => cartProduct.checked)
    const cartShippingGroupChecked = cartShippingGroup.cartProducts.reduce(
      (_checkedCartProductOptions, cartProduct) => {
        const checkedProductOptions = cartProduct.cartProductOptions.filter(
          (cartProductOption) => cartProductOption.checked
        )
        return [..._checkedCartProductOptions, ...checkedProductOptions]
      },
      [] as CartProductOptionType[]
    )

    cartShippingGroupChecked.forEach((cartProductOption) => {
      const productOptionAmount = cartProductOption.price * cartProductOption.purchaseQuantity
      const productOptionPromotionAmount =
        cartProductOption.productPromotionCustomerPrice * cartProductOption.purchaseQuantity
      const productOptionDiscountAmount = productOptionAmount - productOptionPromotionAmount

      cartShippingGroupAmount.totalProductOptionAmount += productOptionAmount
      cartShippingGroupAmount.totalProductOptionPromotionAmount += productOptionPromotionAmount
      cartShippingGroupAmount.totalProductOptionDiscountAmount += productOptionDiscountAmount
      productOptionPromotionAmountOfShipping += productOptionPromotionAmount
    })

    if (
      cartShippingGroupChecked.length > 0 &&
      (freeShippingBaseAmount === null || productOptionPromotionAmountOfShipping < freeShippingBaseAmount)
    ) {
      cartShippingGroupAmount.totalShippingFee += shippingFee
    }
  })
  cartShippingGroupAmount.totalPaymentAmount =
    cartShippingGroupAmount.totalProductOptionPromotionAmount + cartShippingGroupAmount.totalShippingFee

  return cartShippingGroupAmount
}

const getCartProductsChecked = (cartShippingGroups: CartShippingGroupType[]) => {
  const _cartProducts: CartProductType[] = []
  cartShippingGroups.forEach((cartShippingGroup) => {
    cartShippingGroup.cartProducts
      .filter((cartProduct) => cartProduct.checked)
      .forEach((cartProduct) => _cartProducts.push(cartProduct))
  })
  return _cartProducts
}

const getCartProductOptionsChecked = (cartShippingGroups: CartShippingGroupType[]) => {
  const _cartProductOptions: CartProductOptionType[] = []
  getCartProductsChecked(cartShippingGroups).forEach((cartProduct) => {
    const cartProductOptions = cartProduct.cartProductOptions.filter((cartProductOption) => cartProductOption.checked)
    _cartProductOptions.push(...cartProductOptions)
  })
  return _cartProductOptions
}

const getCartProductOptionsNotChecked = (cartShippingGroups: CartShippingGroupType[]) => {
  const _cartProductOptions: CartProductOptionType[] = []
  getCartProductsChecked(cartShippingGroups).forEach((cartProduct) => {
    const cartProductOptions = cartProduct.cartProductOptions.filter((cartProductOption) => {
      return !cartProductOption.checked
    })
    _cartProductOptions.push(...cartProductOptions)
  })
  return _cartProductOptions
}

const getCartProductInCartShippingGroup = (
  cartShippingGroups: CartShippingGroupType[],
  cartProductKey: CartProductKeyType
): CartProductType | undefined => {
  for (let i = 0; i < cartShippingGroups.length; i++) {
    const foundCartProduct = cartShippingGroups[i].cartProducts.find((cartProduct) =>
      CartProductTypeUtil.equalsByProductKey(cartProduct, cartProductKey)
    )
    if (foundCartProduct) {
      return foundCartProduct
    }
  }
  return undefined
}

const getCartProductOptionInCartShippingGroup = (
  cartShippingGroups: CartShippingGroupType[],
  cartProductOptionKey: CartProductOptionKeyType
): CartProductOptionType | undefined => {
  for (const cartShippingGroup of cartShippingGroups) {
    for (const cartProduct of cartShippingGroup.cartProducts) {
      for (const cartProductOption of cartProduct.cartProductOptions) {
        if (CartProductOptionTypeUtil.equalsByProductOptionKey(cartProductOption, cartProductOptionKey)) {
          return cartProductOption
        }
      }
    }
  }
  return undefined
}

const removeCartProductInCartShippingGroup = (
  cartShippingGroups: CartShippingGroupType[],
  cartProductKeys: CartProductKeyType[]
): CartShippingGroupType[] => {
  return cartShippingGroups.filter((cartShippingGroup) => {
    cartShippingGroup.cartProducts = cartShippingGroup.cartProducts.filter((cartProduct) => {
      return (
        cartProductKeys.findIndex((cartProductKey) =>
          CartProductTypeUtil.equalsByProductKey(cartProduct, cartProductKey)
        ) === -1
      )
    })
    return cartShippingGroup.cartProducts.length > 0
  })
}

const removeCartProductOptionInCartShippingGroup = (
  cartShippingGroups: CartShippingGroupType[],
  cartProductOptionKeys: CartProductOptionKeyType[]
): CartShippingGroupType[] => {
  return cartShippingGroups.filter((cartShippingGroup) => {
    cartShippingGroup.cartProducts = cartShippingGroup.cartProducts.filter((cartProduct) => {
      cartProduct.cartProductOptions = cartProduct.cartProductOptions.filter((cartProductOption) => {
        for (const cartProductOptionKey of cartProductOptionKeys) {
          if (CartProductOptionTypeUtil.equalsByProductOptionKey(cartProductOption, cartProductOptionKey)) {
            return false
          }
        }
        return true
      })
      return cartProduct.cartProductOptions.length > 0
    })
    return cartShippingGroup.cartProducts.length > 0
  })
}

const updateCartShippingGroups = (
  oriCartShippingGroups: CartShippingGroupType[],
  cartShippingGroupsForUpdate: CartShippingGroupType[]
): CartShippingGroupType[] => {
  const newCartShippingGroups = deepcopy(oriCartShippingGroups)

  // key: `${cartShippingGroup.shippingId}-${cartProduct.productId}-${cartProduct.productPromotionId}`
  // value: cartProduct
  const cartProductsMapForUpdate: Map<string, CartProductType> = cartShippingGroupsForUpdate.reduce(
    (_cartProductsMapForUpdate, cartShippingGroup) => {
      cartShippingGroup.cartProducts.forEach((cartProduct) => {
        _cartProductsMapForUpdate.set(
          `${cartShippingGroup.shippingId}-${cartProduct.productId}-${cartProduct.productPromotionId}`,
          cartProduct
        )
      })
      return _cartProductsMapForUpdate
    },
    new Map<string, CartProductType>()
  )

  newCartShippingGroups.forEach((cartShippingGroup: CartShippingGroupType) => {
    cartShippingGroup.cartProducts.forEach((cartProduct) => {
      const cartProductForUpdate = cartProductsMapForUpdate.get(
        `${cartShippingGroup.shippingId}-${cartProduct.productId}-${cartProduct.productPromotionId}`
      )
      if (cartProductForUpdate) {
        CartProductTypeUtil.updateCartProductValues(cartProduct, cartProductForUpdate)
      }
    })
  })

  return newCartShippingGroups
}

const getCheckedCartShippingGroups = (cartShippingGroups: CartShippingGroupType[]) => {
  const _cartShippingGroups = deepcopy(cartShippingGroups)
  const checkedCartProductOptionKeys = getCartProductOptionsNotChecked(_cartShippingGroups).map((cartProductOption) => {
    return CartProductOptionTypeUtil.getCartProductOptionKey(cartProductOption)
  })
  return removeCartProductOptionInCartShippingGroup(_cartShippingGroups, checkedCartProductOptionKeys)
}

export const CartShippingGroupTypeUtil: CartShippingGroupTypeUtilType = {
  getCartShippingGroupAmount,
  getCartProductsChecked,
  getCartProductOptionsChecked,
  getCartProductInCartShippingGroup,
  getCartProductOptionInCartShippingGroup,
  getCheckedCartShippingGroups,
  removeCartProductInCartShippingGroup,
  removeCartProductOptionInCartShippingGroup,
  updateCartShippingGroups,
}
