import { FC, useState, useEffect, useRef, useCallback, PropsWithChildren } from 'react'
import styled from 'styled-components'
import { useCustomRouter } from '@/containers/hooks'
import { preventDragSelect, removeScrollbar } from '@/utils/utilCSS'

type DragScrollProps = {
  isResettable?: boolean
  // 스크롤이 존재하는 element 스크롤 시 ref 상위로 전달
  elementScroll?: (containerRef: HTMLDivElement | null) => void
}

const DragScrollWrapper: FC<PropsWithChildren<DragScrollProps>> = ({
  isResettable = false,
  elementScroll,
  children,
}) => {
  const containerRef = useRef<HTMLDivElement | null>(null)

  /**
   * isDrag : 드래그가 진행 중인지 분기하여 중복 드래그 실행을 방지
   * isMove : 드래그와 단순 클릭 상태를 분기하여 하위 이벤트를 방지
   * startPageX : 마우스 클릭 시 X 좌표
   * currentScrollLeft : 컨테이너의 현재 스크롤 위치를 저장
   */
  const [isDrag, setIsDrag] = useState<boolean>(false)
  const [isMove, setIsMove] = useState<boolean>(false)
  const [startPageX, setStartPageX] = useState<number>(0)
  const [currentScrollLeft, setCurrentScrollLeft] = useState<number>(0)
  const { asPath } = useCustomRouter()

  const onMouseDown = useCallback(
    (e: MouseEvent) => {
      if (!containerRef.current) {
        return
      }

      setIsDrag(true)
      setStartPageX(e.pageX)
      setCurrentScrollLeft(containerRef.current.scrollLeft)
    },
    [containerRef]
  )

  const onMouseUp = useCallback(() => {
    setIsDrag(false)
    setIsMove(false)
  }, [])

  const onMouseMove = useCallback(
    (e: MouseEvent) => {
      if (!containerRef.current || !isDrag) {
        return
      }

      /**
       * startPageX : 마우스 클릭시 저장되는 상태값 (다음 클릭때까지는 상수)
       * e.pageX : 마우스 이동에 따라 변하는 값
       * move : 왼쪽으로 드래그하면 양수, 오른쪽으로 드래그하면 음수
       */
      const move = startPageX - e.pageX

      // 마우스로 이동시킨만큼 container의 스크롤을 이동시킴
      containerRef.current.scrollLeft = currentScrollLeft + move
      setIsMove(true)
    },
    [containerRef, isDrag, currentScrollLeft, startPageX]
  )

  useEffect(() => {
    if (!containerRef.current) {
      return
    }

    // element 드래그 시 cursor style
    containerRef.current.style.cursor = isMove ? 'grab' : 'inherit'
    // element 드래그 시 하위 컴포넌트의 이벤트 방지
    const collection = containerRef.current.children as HTMLCollectionOf<HTMLElement>
    for (let i = 0; i < collection.length; ++i) {
      // HTMLCollection 은 map, forEach 등 사용할 수 없음
      collection[i].style.pointerEvents = isMove ? 'none' : 'inherit'
    }
  }, [containerRef, isMove])

  useEffect(() => {
    if (isResettable && containerRef.current) {
      // 리스트 업데이트 시 x축 스크롤 0으로 초기화
      containerRef.current.scrollLeft = 0
    }
  }, [isResettable, asPath])

  useEffect(() => {
    const ref = containerRef.current
    if (ref) {
      ref.addEventListener('mousedown', onMouseDown)
      ref.addEventListener('mousemove', onMouseMove)
      ref.addEventListener('mouseup', onMouseUp)
      ref.addEventListener('mouseleave', onMouseUp)
    }

    return () => {
      if (ref) {
        ref.removeEventListener('mousedown', onMouseDown)
        ref.removeEventListener('mousemove', onMouseMove)
        ref.removeEventListener('mouseup', onMouseUp)
        ref.removeEventListener('mouseleave', onMouseUp)
      }
    }
  }, [onMouseDown, onMouseMove])

  return (
    <StyledScrollContainer
      ref={containerRef}
      onScroll={() => {
        elementScroll?.(containerRef.current)
      }}
    >
      {children}
    </StyledScrollContainer>
  )
}

const StyledScrollContainer = styled.div`
  ${removeScrollbar};
  ${preventDragSelect};
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
`

export default DragScrollWrapper
