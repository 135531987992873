import * as utilApi from '@/utils/utilApi'

export interface IModelBase {
  id: string
  _id: string
  __typename: string
}

function isNumeric(str: string) {
  if (typeof str !== 'string') return false // we only process strings!
  if (str.startsWith('010')) return false
  // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
  // ...and ensure strings of whitespace fail
  // eslint-disable-next-line no-restricted-globals
  // TODO typescript 오류 수정 필요
  // @ts-ignore
  return !isNaN(parseFloat(str)) && !isNaN(str - 0)
}

const nonNumbers = [
  'mobileNumber',
  'orderNumber',
  'postNumber',
  'sweetTrackerCode',
  'accountNumber',
  'vbankAccountNumber',
  'reviewBody',
]

export default class ModelBase implements IModelBase {
  public id: string = ''
  public _id: string = ''
  public __typename: string = ''

  // eslint-disable-next-line class-methods-use-this
  makeNumberIfAny = (data: any) => {
    const _data: any = {}
    if (data && Object.keys(data).length > 0) {
      Object.entries(data).forEach(([k, v]) => {
        if (nonNumbers.includes(k)) {
          _data[k] = v
          // TODO typescript 오류 수정 필요
          // @ts-ignore
        } else if (isNumeric(v)) {
          _data[k] = Number(v)
        } else {
          _data[k] = v
        }
      })
    }
    return _data
  }

  constructor(data: any) {
    const _data = this.makeNumberIfAny(data)
    Object.assign(this, _data)
    if (_data && _data.id && typeof _data.id === 'string') {
      this._id = utilApi.decodeId(_data.id).id
    }
  }
}
