export const appendQueryStringToPathname = ({
  pathname,
  param,
  value,
}: {
  pathname: string
  param: string
  value: string
}): string => {
  if (!param || !value || !pathname) {
    return pathname
  }
  const queryString = `${param}=${value}`

  const hasQueryString = pathname.includes('?')

  const newPathname = hasQueryString ? `${pathname}&${queryString}` : `${pathname}?${queryString}`

  return newPathname
}
