import { FunctionComponent, ReactNode } from 'react'
import { ApolloError } from '@apollo/client'
import { OnApiCompletedType } from '@/constants/legacy/constType'
import useApiMutation from '@/containers/hooks/useApiMutation'
import * as utilApi from '@/utils/utilApi'

type ApiMutationProps = {
  gqlKey: string
  onAPISuccess?: OnApiCompletedType
  onAPIError?: (error: ApolloError) => ReactNode | void
  // displayLoader?: boolean;
  children: any
}

const ApiMutation: FunctionComponent<ApiMutationProps> = ({
  gqlKey,
  onAPISuccess,
  onAPIError,
  // displayLoader = true,
  children,
}): any => {
  const [mutation, { loading, error, data, called }] = useApiMutation(gqlKey, onAPISuccess)

  const hasDataWithoutError: boolean = utilApi.maybe(() => !!Object.keys(data).length, false) && called && !error
  if (error && !hasDataWithoutError) {
    if (onAPIError) {
      onAPIError(error)
    } else {
      console.error(error.message)
    }
  }

  // 로더를 여기서 보여주게 되면 기존 컨텐츠가 사라지고 로더로 대체되기 때문에 보기 좋지않음.
  // if ( displayLoader && loading ) {
  //   return <ApiLoader full={false} />
  // }

  return <>{children(mutation, { data, error, hasDataWithoutError, loading })}</>
}

export default ApiMutation
