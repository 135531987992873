import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useDownloadEventCouponsMutation } from '@/graphql/generated/hooks'
import { doSetGlobalToastContent } from '@/stores/reduxUI'
import { ApiErrorUtils } from '@/utils/utilApiErrors'

export const useMutationDownloadCoupon = () => {
  const dispatch = useDispatch()
  const [downloadCouponMutation] = useDownloadEventCouponsMutation()
  const [isLoading, setIsLoading] = useState(false)

  const downloadCoupon = async ({ id }: { id: string }) => {
    setIsLoading(true)
    try {
      const { data, errors } = await downloadCouponMutation({
        variables: {
          input: {
            couponGroup: id,
          },
        },
      })
      if (errors) {
        // backend에서 넘어온 에러
        const errorInfo = ApiErrorUtils.getError(errors[0])
        throw new Error(errorInfo.message)
      }
      return data
    } catch (error) {
      // network error
      const errorInfo = ApiErrorUtils.getError(error)
      dispatch(
        doSetGlobalToastContent({
          content: `${errorInfo.message}\n오류가 지속된다면 고객센터에 문의해 주세요`,
          duration: 5,
        })
      )
      return null
    } finally {
      setIsLoading(false)
    }
  }
  return {
    downloadCoupon,
    isLoading,
  }
}
