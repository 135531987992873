import { useDispatch } from 'react-redux'
import { BottomSheetModal } from '@/components/common/modals/BottomSheetModal'
import { CartChangeOptionContents } from '@/components/domains/cart/CartChangeOptionContents'
import { doSetBottomSheetData } from '@/stores/reduxData'

export const CartChangeOptionBottomSheet = ({ visible }: { visible: boolean }) => {
  const dispatch = useDispatch()

  return (
    <BottomSheetModal
      title="옵션 변경"
      centerTitle
      visible={visible}
      titleNoBorder
      onClose={() => {
        dispatch(
          doSetBottomSheetData({
            isCartProductOptionChangeVisible: false,
          })
        )
      }}
    >
      <CartChangeOptionContents />
    </BottomSheetModal>
  )
}
