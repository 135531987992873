import { gql } from '@apollo/client'
import DEFAULT_FRAGMENTS from '@/containers/gqls/base/defaultFragments'
import ORDER_FRAGMENTS from '@/containers/gqls/orders/fragments'

export const ORDER_QUERY_GQLS = {
  ORDER_ITEM: gql`
    query orderItem($id: ID!) {
      orderItem(id: $id) {
        ...orderListItem
        order {
          user {
            writableReviewCount
          }
        }
      }
    }
    ${ORDER_FRAGMENTS.orderListItem}
  `,
  ORDER: gql`
    query order($id: ID!) {
      order(id: $id) {
        ...orderDetail
        membership {
          id
        }
        orderOrderItems {
          totalCount
          edges {
            node {
              ...orderItemDetail
              membershipOrderConfirmMileage
            }
          }
        }
      }
    }
    ${ORDER_FRAGMENTS.orderDetail}
    ${ORDER_FRAGMENTS.orderItemDetail}
  `,
  BUNDLED_ORDER_ITEMS: gql`
    query bundledOrderItems($id: ID!) {
      bundledOrderItems(orderItem: $id) {
        edges {
          node {
            id
            orderStatus
            orderStatusText
            createdAt
            hasUserReview
            productName
            quantity
            mileage
            orderConfirmMileage
            orderConfirmMileageRate
            originalPrice
            productDiscountPrice
            couponDiscountPrice
            orderCouponDiscountPrice
            mileageDiscountPrice
            amount
            originalAmount
            productDiscountAmount
            refundAmount
            customerPrice
            customerAmount
            productPromotionCustomerPrice
            productPromotionDiscountPrice
            cancelRequestMessage
            cancelRequestType
            cancelResponsibility
            cancelRequestedAt
            canceledAt
            cancelGuideMessage
            order {
              id
              orderNumber
            }
            productPromotion {
              id
              productPromotionType
            }
            refundShippingInfo {
              fee
              extraFee
            }
            exchangeShippingFee
            exchangeNewOrderItem {
              id
              orderStatus
              orderStatusText
              shipping {
                id
                amount
                extraFee
                fee
                sumFee
              }
            }
            returnResponsibility
            returnInfo {
              id
              responsibility
              returnShippingFee
              returnShippingExtraFee
              guideMessage
              requestType
              requestMessage
              createdAt
              acceptedAt
              returnedAt
              confirmedAt
              customerAddress {
                id
                name
                mobileNumber
                postNumber
                basicAddress
                detailedAddress
                message
              }
              returnInfoReturnInfoImages {
                totalCount
                edges {
                  node {
                    thumbnail(imageType: "SMALL")
                  }
                }
              }
            }
            productImage {
              id
              image
              displayType
              thumbnail(productImageType: "SMALL")
            }
            productOption {
              id
              name
              customerPrice
              productId
              product {
                id
                name
                productType
              }
            }
            seller {
              id
              shopName
            }
            shipping {
              id
              amount
              couponDiscountPrice
              mileageDiscountPrice
              discountPrice
              extraFee
              fee
              shippingCompany {
                id
                name
                sweetTrackerCode
              }
              shippingPreset {
                returnFee
                shippingFee
              }
              shippingNumbers
              shippingCompanyAndNumbers
              shippingOrderItems {
                edges {
                  node {
                    id
                    returnRequestedAt
                    cancelRequestedAt
                  }
                }
              }
            }
            approvedTransaction {
              id
              payMethodType
              paymentText
              transactionStatus
            }
            returnResponsibility
            returnShippingFee
            exchangeShippingFee
          }
        }
      }
    }
  `,
  ORDER_FINISH: gql`
    query order($id: ID!) {
      order(id: $id) {
        ...orderDetail
        approvedTransaction {
          id
          payMethodType
          pgProviderType
          paymentText
        }
        orderOrderItems {
          totalCount
          edges {
            node {
              ...orderItemDetail
            }
          }
        }
      }
    }
    ${ORDER_FRAGMENTS.orderDetail}
    ${ORDER_FRAGMENTS.orderItemDetail}
  `,
  TRANSACTION_FOR_TRACKING_FAILURE: gql`
    query transactionForTrackingFailure($id: ID!) {
      transaction(id: $id) {
        payMethodType
        amount
        order {
          id
          shippings {
            edges {
              node {
                shippingOrderItems {
                  edges {
                    node {
                      productOption {
                        product {
                          id
                          name
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          orderOrderItems {
            edges {
              node {
                productOption {
                  product {
                    id
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
  `,
  ORDER_MINIMUM_ORDER_ITEMS: gql`
    query orderMinimumOrderItems($id: ID!) {
      order(id: $id) {
        orderOrderItems {
          totalCount
          edges {
            node {
              id
              orderStatus
            }
          }
        }
      }
    }
  `,
  ORDERS_HISTORY: gql`
    query ordersHistory($filter: GenericScalar, $pageInfo: PageInfoInputType) {
      orders(filter: $filter, pageInfo: $pageInfo) {
        pageInfo {
          ...pageInfo
        }
        totalCount
        edges {
          node {
            id
            createdAt
            membership {
              id
            }
            orderOrderItems(excludeExchanges: true) {
              edges {
                node {
                  id
                  orderStatus
                  actionPowerOrder {
                    orderUrl
                  }
                  hasUserReview
                  reviewWritableDatetime
                  exchangeNewOrderItem {
                    id
                    orderStatus
                  }
                  brand {
                    id
                    name
                  }
                  supplyType
                  approvedTransaction {
                    id
                    payMethodType
                    transactionStatus
                    pgProviderType
                  }
                  mileage
                  productOption {
                    id
                    name
                    customerPrice
                    productId
                    isUse
                    isDelete
                    stockQuantity
                    product {
                      productType
                      isRealSoldOut
                      isSoldOut
                      status {
                        value
                      }
                    }
                  }
                  productPromotion {
                    id
                    productPromotionType
                  }
                  productImage {
                    thumbnail(productImageType: "SMALL")
                    image
                  }
                  productName
                  productPromotion {
                    id
                  }
                  quantity
                  productPromotionCustomerPrice
                  customerPrice
                  seller {
                    id
                    shopName
                  }
                  orderConfirmMileage
                  membershipOrderConfirmMileage

                  shipping {
                    shippingCompany {
                      id
                      name
                      sweetTrackerCode
                    }
                    shippingNumbers
                    shippingCompanyAndNumbers
                  }
                }
              }
            }
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.pageInfo}
  `,
  ORDERS: gql`
    query orders($filter: GenericScalar, $pageInfo: PageInfoInputType) {
      orders(filter: $filter, pageInfo: $pageInfo) {
        pageInfo {
          ...pageInfo
        }
        totalCount
        edges {
          node {
            id
            createdAt
            orderOrderItems(excludeExchanges: true) {
              edges {
                node {
                  id
                  orderStatus
                  hasUserReview
                  reviewWritableDatetime
                  approvedTransaction {
                    id
                    payMethodType
                    transactionStatus
                  }
                  actionPowerOrder {
                    orderUrl
                  }
                  mileage
                  productOption {
                    id
                    name
                    customerPrice
                    productId
                    product {
                      productType
                    }
                  }
                  productImage {
                    thumbnail(productImageType: "SMALL")
                    image
                  }
                  productName
                  productPromotion {
                    id
                  }
                  quantity
                  productPromotionCustomerPrice
                  customerPrice
                  seller {
                    id
                    shopName
                  }
                  orderConfirmMileage
                  orderConfirmMileageRate
                  shipping {
                    shippingCompany {
                      id
                      name
                      sweetTrackerCode
                    }
                    shippingNumbers
                    shippingCompanyAndNumbers
                  }
                }
              }
            }
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.pageInfo}
  `,
  ORDER_ITEM_CANCEL_REASONS: gql`
    query orderItemCancelReasons {
      orderItemCancelReasons {
        cancelReasons {
          ...cancelReason
        }
      }
    }
    ${DEFAULT_FRAGMENTS.cancelReason}
  `,
  ORDER_ITEM_RETURN_REASONS: gql`
    query orderItemReturnReasons {
      orderItemReturnReasons {
        returnReasons {
          ...returnReason
        }
      }
    }
    ${DEFAULT_FRAGMENTS.returnReason}
  `,
  SHIPPING_TRACK: gql`
    query shippingTrack($orderItem: ID!) {
      shippingTrack(orderItem: $orderItem) {
        shipping {
          id
          shippingCompany {
            id
            name
          }
        }
        shippingTrack
      }
    }
  `,
}

export default ORDER_QUERY_GQLS
