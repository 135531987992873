import { ReactNode, useState } from 'react'
import styled, { CSSObject } from 'styled-components'
import { ImageAsset } from '@/components/common'
import { ImageAssetNameType } from '@/components/common/assets/ImageAssetBase'

type CollapseIconProps = {
  position: 'left' | 'right'
  style?: CSSObject
  icon: ImageAssetNameType
}

type CollapseProps = {
  header: ReactNode
  styleHeader?: CSSObject
  content: ReactNode
  styleContent?: CSSObject
  iconProps?: CollapseIconProps
  initialIsCollapsed?: boolean
}

const Collapse = ({
  header,
  styleHeader,
  content,
  styleContent,
  iconProps,
  initialIsCollapsed = true,
}: CollapseProps) => {
  const [isCollapsed, setIsCollapsed] = useState(initialIsCollapsed)
  const IconLeft = () => {
    if (!iconProps || iconProps.position !== 'left') {
      return null
    }
    return (
      <StyledImageAssetContainer isCollapsed={isCollapsed}>
        <ImageAsset size={16} rem name={iconProps.icon} style={iconProps.style} svg />
      </StyledImageAssetContainer>
    )
  }
  const IconRight = () => {
    if (!iconProps || iconProps.position !== 'right') {
      return null
    }
    return (
      <StyledImageAssetContainer isCollapsed={isCollapsed}>
        <ImageAsset size={16} rem name={iconProps.icon} style={iconProps.style} svg />
      </StyledImageAssetContainer>
    )
  }
  return (
    <>
      <Header
        onClick={() => {
          setIsCollapsed(!isCollapsed)
        }}
        style={styleHeader}
      >
        <IconLeft />
        {header}
        <IconRight />
      </Header>
      <Panel className={`collapse-content ${isCollapsed ? 'collapsed' : 'expanded'}`} style={styleContent}>
        {content}
      </Panel>
    </>
  )
}

const StyledImageAssetContainer = styled.div<{ isCollapsed: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  transform: ${({ isCollapsed }) => (isCollapsed ? 'rotate(0deg)' : 'rotate(180deg)')};
`

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${({
    theme: {
      color: { grayWhite },
    },
  }) => grayWhite};
  cursor: pointer;
  outline: none;
  overflow: hidden;
  border: 1px solid transparent;
`

const Panel = styled.div`
  display: block;
  width: 100%;
  &.collapsed {
    display: none;
  }
  &.expanded {
    display: block;
  }
`

export default Collapse
