import { FC } from 'react'
import { TextLegacy } from '@/components/common'
import { SocialIcon } from '@/components/common/etc'
import { NewRow } from '@/components/common/layouts/NewRow'
import { SocialServiceSocialType } from '@/graphql/generated/schemas'

type SocialIconNameProps = {
  socialType: SocialServiceSocialType
}

const SocialIconName: FC<SocialIconNameProps> = ({ socialType }) => {
  const getName = (): string => {
    switch (socialType) {
      case SocialServiceSocialType.Apple:
        return '애플'
      case SocialServiceSocialType.Naver:
        return '네이버'
      case SocialServiceSocialType.Kakao:
        return '카카오톡'
      default:
        return '이메일'
    }
  }

  return (
    <NewRow gap={'1rem'} align="center">
      <SocialIcon socialType={socialType} />
      <TextLegacy>{getName()}</TextLegacy>
    </NewRow>
  )
}

export default SocialIconName
