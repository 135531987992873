import { PathParamKeyEnum } from '@/constants/path-param-key.enum'
import { PRODUCTS_SELECT_FILTER_ORDER_BY_V4 } from '@/constants/products-select-filter-orderby.const'
import { QueryStringKeyEnum } from '@/constants/query-string-key.enum'
import { QueryStringValueEnum } from '@/constants/query-string-value.enum'
import useCustomRouter from '@/containers/hooks/useCustomRouter'
import { AdPetType } from '@/graphql/generated/schemas'
import { Base64ConverterUtil } from '@/utils/base64-converter.util'
import { getSortDirectionByOrder, parseSortByOptionSelected } from '@/utils/getSortDirectionByOrder'
import { UtilUrlController } from '@/utils/utilUrlController'

const useGetQueryParamsSearchResult = () => {
  const { query } = useCustomRouter()
  const {
    [QueryStringKeyEnum.SearchPetType]: petTypeQueryParam,
    [QueryStringKeyEnum.SearchQuery]: searchKeywordQueryParam,
    [QueryStringKeyEnum.SearchLocation]: searchLocation,
    [QueryStringKeyEnum.FilterExcept]: except,
    [QueryStringKeyEnum.OrderBy]: sortBy,
    [QueryStringKeyEnum.FilterCategory]: filterCategory,
    [QueryStringKeyEnum.FilterBrand]: filterBrand,
    [QueryStringKeyEnum.FilterPrice]: filterPrice,
    [PathParamKeyEnum.PetType]: petTypePathParam,
    [PathParamKeyEnum.Keyword]: searchKeywordPathParam,
  } = query as {
    [QueryStringKeyEnum.SearchPetType]?: string
    [QueryStringKeyEnum.SearchQuery]?: string
    [QueryStringKeyEnum.SearchLocation]?: string
    [QueryStringKeyEnum.FilterExcept]?: string
    [QueryStringKeyEnum.OrderBy]?: string
    [QueryStringKeyEnum.FilterCategory]?: string
    [QueryStringKeyEnum.FilterBrand]?: string
    [QueryStringKeyEnum.FilterPrice]?: string
    [PathParamKeyEnum.PetType]?: string
    [PathParamKeyEnum.Keyword]?: string
  }

  const searchKeyword = searchKeywordQueryParam || searchKeywordPathParam || ''
  const petType = UtilUrlController.urlParamToPetType(petTypeQueryParam || petTypePathParam)

  const queryParams = {
    keyword: searchKeyword as string,
    petType: petType as AdPetType,
    includeSoldOut: except !== QueryStringValueEnum.SoldOut,
    sortBy: parseSortByOptionSelected(sortBy || PRODUCTS_SELECT_FILTER_ORDER_BY_V4.RECOMMEND.value),
    sortDirection: getSortDirectionByOrder(sortBy || PRODUCTS_SELECT_FILTER_ORDER_BY_V4.RECOMMEND.value),
    ...(filterCategory &&
      typeof filterCategory === 'string' && {
        categoryIds: filterCategory.split('_').map((i) => Base64ConverterUtil.convertBase64ToNumberId(i)),
      }),
    ...(filterBrand &&
      typeof filterBrand === 'string' && {
        brandIds: filterBrand.split('_').map((i) => Base64ConverterUtil.convertBase64ToNumberId(i)),
      }),
    ...(filterPrice &&
      typeof filterPrice === 'string' && {
        minPrice: Number(filterPrice.split('~')[0]),
        maxPrice: Number(filterPrice.split('~')[1]),
      }),
  }

  return {
    searchKeyword,
    petType,
    query: {
      petTypeQueryParam,
      searchKeywordQueryParam,
      searchLocation,
      except,
      sortBy,
      filterCategory,
      filterBrand,
      filterPrice,
      petTypePathParam,
      searchKeywordPathParam,
    },
    queryParams,
    filterCategory,
    filterBrand,
    filterPrice,
  }
}

export { useGetQueryParamsSearchResult }
