import { CSSProperties, ReactNode, useEffect } from 'react'
import { createPortal } from 'react-dom'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { Z_INDEXES } from '@/constants/legacy/constLayout'
import { RootState } from '@/stores/store'

interface ModalProps {
  /** 모달 열림 닫힘(부모 컴포넌트에서 컨트롤) */
  open: boolean
  /** 모달 바디영역의 설명 영역 */
  children?: ReactNode
}

/**
 *
 * 디자인 시스템 적용 모달(FDS)
 * 이 코드 수정시 모든 모달에 영향을 미칠 수 있습니다.
 */
const Modal = ({ open, children }: ModalProps) => {
  /** SSR에 의해 페이지 이동시 modal-root가 바뀌므로, Modal 컴포넌트 내에서 계산되어야함 */
  const modalRootEl = typeof document !== 'undefined' && document.getElementById('modal-root')
  const bottomSheetData = useSelector((rootState: RootState) => rootState.reduxDataReducers.bottomSheetData)
  const isAnyVisible = Object.values(bottomSheetData).some((visible) => visible)

  useEffect(() => {
    // setTimeout 사용 이유:
    // 이 useEffect는 컴포넌트가 마운트될 때 실행되며, 이 때 body의 overflow 스타일을 'hidden'으로 설정하여 스크롤을 방지합니다.
    // 그러나, 이 프로젝트에서는 enabledScroll이라는 전역 함수를 사용하여 스크롤을 활성화/비활성화하는 로직이 있습니다.
    // 이 enabledScroll 함수가 실행되면 body의 overflow 스타일이 변경되어 스크롤이 가능해질 수 있습니다.
    // 따라서 setTimeout을 사용하여 이 useEffect 내부의 로직이 enabledScroll 함수 실행 이후에 실행되도록 합니다.
    // 이렇게 하면 이 useEffect가 마지막에 실행되어 스크롤을 정상적으로 방지할 수 있습니다.
    if (open) {
      setTimeout(() => {
        document.body.style.overflow = 'hidden'
      })
    } else {
      if (isAnyVisible) {
        return
      }
      setTimeout(() => {
        document.body.style.overflow = 'unset'
      })
    }
    // 페퍼 저축 모달에서는 모달이 닫힐 때 스크롤 활성화되게끔 수정함
    return () => {
      if (isAnyVisible) {
        return
      }
      document.body.style.overflow = 'unset'
    }
  }, [open])

  if (!modalRootEl || !open) {
    return null
  }

  return createPortal(
    <ModalWrapper className="modal">
      <div className="modal__body">{children}</div>
      <ModalOverlay />
    </ModalWrapper>,
    modalRootEl
  )
}

const Content = ({ children, style }: { children: ReactNode; style?: CSSProperties }) => (
  <div className="modal__content" style={style}>
    {children}
  </div>
)

const Title = ({ children, style }: { children: ReactNode; style?: CSSProperties }) => (
  <div className="modal__title" style={style}>
    {children}
  </div>
)

const SubTitle = ({ children, style }: { children: ReactNode; style?: CSSProperties }) => (
  <div className="modal__sub-title" style={style}>
    {children}
  </div>
)

const Footer = ({ children, style }: { children: ReactNode; style?: CSSProperties }) => (
  <div className="modal__footer" style={style}>
    {children}
  </div>
)

const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  z-index: ${Z_INDEXES.BOTTOM_SLIDE_POPUP};
  padding: 0 2.8rem;
  display: flex;
  align-items: center;
  justify-content: center;

  width: min(100%, ${(props) => props.theme.deviceSize.tablet});
  height: 100vh;
  .modal {
    &__body {
      z-index: 2;
      border-radius: 1.6rem;
      width: min(100%, 394px);
      background-color: ${({ theme }) => theme.color.grayWhite};
    }
    &__content {
      padding: 4rem 1.6rem 2.4rem 1.6rem;
    }
    &__title {
      text-align: center;
      white-space: pre-wrap;
      display: flex;
      justify-content: center;
      margin: 0 1.6rem;
    }
    &__sub-title {
      text-align: center;
      white-space: pre-wrap;
      display: flex;
      justify-content: center;
      margin: 1.6rem 1.6rem 0 1.6rem;
    }
    &__footer {
      padding: 1.6rem 1.6rem 2.4rem 1.6rem;
    }
  }
`

const ModalOverlay = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
`

Modal.Content = Content
Modal.Title = Title
Modal.SubTitle = SubTitle
Modal.Footer = Footer

export default Modal
