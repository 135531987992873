import { css } from 'styled-components'
import { GlobalColorEnum } from '@/constants/global-color.enum'

export const shineLinesCSS = css`
  @keyframes shineLines {
    0% {
      background-position: -20rem;
    }

    100% {
      background-position: 80rem;
    }
  }

  background-image: linear-gradient(
    90deg,
    ${GlobalColorEnum.Gray70} 0,
    ${GlobalColorEnum.Gray50} 12rem,
    ${GlobalColorEnum.Gray70} 20rem
  );
  background-size: 100rem;
  animation: forwards shineLines 3.5s infinite;
`
