import styled from 'styled-components'
import { Text } from '@/components/common'
import { DragScrollWrapper } from '@/components/common/etc'
import {
  ClickRelatedKeywordHandler,
  RelatedSearchKeywordTracker,
} from '@/components/domains/layouts/header/searchHeader.type'
import useSearchedKeywords from '@/components/domains/search/hooks/useSearchedKeywords'
import { QueryStringKeyEnum } from '@/constants/query-string-key.enum'
import { SearchLocationEnum } from '@/constants/search-location.enum'
import { useTracker } from '@/containers/contexts/EventTrackingProvider'
import { useSearch } from '@/containers/hooks'
import { SearchResultContainer } from '@/containers/search/search-result.context'
import { FitpetMallEventEnum } from '@/utils/event-tracker/events'

const RelatedSearchKeywords = () => {
  const { goToResultPage } = useSearch()
  const tracker = useTracker()
  const { isShowSearchedKeywords } = useSearchedKeywords()
  const { relatedSearchKeywordLoading, relatedKeywords, petType, searchKeyword } = SearchResultContainer.useContainer()

  const eventTracker: RelatedSearchKeywordTracker = (keyword, index) => {
    tracker.triggerCustomEvent(FitpetMallEventEnum.ClickSearchRelatedKeyword, {
      search_keyword: searchKeyword,
      related_keyword_count: relatedKeywords.length,
      related_keyword: keyword,
      list_index: index,
      pet_type_search: petType,
    })
  }

  const clickKeywordHandler: ClickRelatedKeywordHandler = (keyword, index) => {
    eventTracker(keyword, index)
    goToResultPage({
      [QueryStringKeyEnum.SearchQuery]: keyword,
      /**
       * 검색어를 직접 입력한 것이 아니라면 SearchLocationEnum.None 으로 설정
       */
      [QueryStringKeyEnum.SearchLocation]: SearchLocationEnum.None,
    })
  }

  const filteredKeywords = relatedKeywords.filter((keyword) => keyword !== searchKeyword)

  if ((!relatedSearchKeywordLoading && filteredKeywords.length === 0) || isShowSearchedKeywords) return null

  return (
    <StyledKeywordListContainer>
      <DragScrollWrapper>
        <StyledKeywordList>
          {relatedSearchKeywordLoading
            ? Array.from({ length: 5 }).map((_, index) => <StyledSkeletonKeyword key={index} />)
            : filteredKeywords.slice(0, 10).map((keyword, index) => (
                <StyledKeywordArea key={`${keyword}-${index}`} onClick={() => clickKeywordHandler(keyword, index)}>
                  <StyledKeyword>{keyword}</StyledKeyword>
                </StyledKeywordArea>
              ))}
        </StyledKeywordList>
      </DragScrollWrapper>
    </StyledKeywordListContainer>
  )
}

const StyledKeywordListContainer = styled.div`
  margin: 0.8rem 0 1.6rem;
`

const StyledKeywordList = styled.div`
  display: flex;
  min-width: max-content;
  padding: 0 1.6rem;
`

const StyledKeywordArea = styled.div`
  display: inline-flex;
  margin: 0 0.5rem;
  cursor: pointer;

  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
`

const StyledKeyword = styled(Text.Body3)`
  padding: 0.6rem 1.4rem;
  border-radius: 2.4rem;
  font-weight: 500;
  color: ${(props) => props.theme.color.blue500};
  background-color: ${(props) => props.theme.color.blue50};
`

const StyledSkeletonKeyword = styled.div`
  display: inline-flex;
  min-width: 8.8rem;
  height: 3.2rem;
  border-radius: 2.4rem;
  background-color: ${(props) => props.theme.color.gray50};
  margin: 0 0.5rem;

  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
`

export default RelatedSearchKeywords
