/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * Fitpet Mall
 * 핏펫몰 API 스펙

API 스펙 작성시 다음 가이드를 숙지하시기 바랍니다.

- [Open API 가이드 참고](https://swagger.io/docs/specification/about/)
- [Kotlin-Spring Open API Generator 설정 참고](https://openapi-generator.tech/docs/generators/kotlin-spring)
- REST API 디자인 가이드 참고
  - doc > guide > rest-api-design.md
- OpenAPI Spec 컨벤션 참고
  - doc > guid > openapi-spec-convention.md
- 설명(description) 작성시 마크다운([CommonMark](https://commonmark.org/help/)) 구문 참고

로컬에서 스웨거 에디터를 사용하는 방법은 다음과 같다.

```shell
docker pull swaggerapi/swagger-editor
docker run -d -p 8123:8080 swaggerapi/swagger-editor
open http://localhost:8123
```

Api 스펙을 하나의 파일로 합치려면 다음과 같이 실행한다.

```shell
# 설치
npm install -g swagger-cli

# 실행
swagger-cli bundle api-spec.yaml --outfile build/openapi.yaml --type yaml
```
 * OpenAPI spec version: 1.0.0
 */

/**
 * - `BANNER`: 배너
- `BEST_REVIEW`: 베스트 리뷰, =리뷰 좋은 상품
- `BRAND`: 브랜드
- `CATEGORY`: 카테고리
- `DISPLAY_COLLECTION`: 전시 컬렉션
- `RECOMMEND_BY_PET_PRODUCT`: 반려동물별 추천 상품
- `RECOMMEND_FREQUENTLY_BOUGHT_PRODUCT`: 자주 사는 상품
- `RECOMMEND_RECENTLY_BOUGHT_PRODUCT`: 최근 구매 상품
- `RECOMMEND_RELATED_PRODUCT`: 연관 상품
- `WEEKLY_BEST`: 주간 베스트
- `MAGAZINE_IMAGE`: 매거진 이미지
- `MAGAZINE_BACKGROUND_IMAGE`: 매거진 이미지 배경
- `RECOMMEND_BY_PET_PRODUCT_BY_PERSONALIZE`: 반려동물별 추천 상품 (개인화)
 */
export type HomeSectionTypeEnum = typeof HomeSectionTypeEnum[keyof typeof HomeSectionTypeEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const HomeSectionTypeEnum = {
  BANNER: 'BANNER',
  BEST_REVIEW: 'BEST_REVIEW',
  BRAND: 'BRAND',
  CATEGORY: 'CATEGORY',
  DISPLAY_COLLECTION: 'DISPLAY_COLLECTION',
  RECOMMEND_BY_PET_PRODUCT: 'RECOMMEND_BY_PET_PRODUCT',
  RECOMMEND_FREQUENTLY_BOUGHT_PRODUCT: 'RECOMMEND_FREQUENTLY_BOUGHT_PRODUCT',
  RECOMMEND_RECENTLY_BOUGHT_PRODUCT: 'RECOMMEND_RECENTLY_BOUGHT_PRODUCT',
  RECOMMEND_RELATED_PRODUCT: 'RECOMMEND_RELATED_PRODUCT',
  WEEKLY_BEST: 'WEEKLY_BEST',
  MAGAZINE_IMAGE: 'MAGAZINE_IMAGE',
  MAGAZINE_BACKGROUND_IMAGE: 'MAGAZINE_BACKGROUND_IMAGE',
  RECOMMEND_BY_PET_PRODUCT_BY_PERSONALIZE: 'RECOMMEND_BY_PET_PRODUCT_BY_PERSONALIZE',
} as const
