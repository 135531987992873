import { FC } from 'react'
import Link from 'next/link'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { Image } from '@/components/common/images/Image'
import { NewCol } from '@/components/common/layouts/NewCol'
import { NewRow } from '@/components/common/layouts/NewRow'
import { S3_HOST_URL } from '@/constants/legacy/constEnv'
import { SCREEN_MEDIA_QUERY } from '@/constants/legacy/constLayout'
import { IModelReviewImage } from '@/containers/models/modelReviewImage'
import { doSetFullScreenReviewImages } from '@/stores/reduxUI'
import { createFullURL } from '@/utils/utilURL'

type ReviewImagesProps = {
  images: IModelReviewImage[]
}

const ReviewImages: FC<ReviewImagesProps> = ({ images }) => {
  const dispatch = useDispatch()

  const _onClick = (imageIdx: number) => {
    dispatch(
      doSetFullScreenReviewImages({
        reviewImages: images,
        showIndex: imageIdx,
      })
    )
  }

  return (
    <NewRow gap="0.6rem">
      {images.map((image: IModelReviewImage, index: number) => (
        <ImageContainer key={image.id} onClick={() => _onClick(index)}>
          <Link href={`#review-${image.id}`}>
            <Image objectFit="cover" src={createFullURL(image.thumbnail)} alt="Review Thumbnail" />
          </Link>
        </ImageContainer>
      ))}
    </NewRow>
  )
}

const ImageContainer = styled(NewCol)`
  width: 100px;
  height: 100px;
  border-radius: 3px;
  overflow: hidden;
  display: inline-block;
  position: relative;
  cursor: pointer;

  @media ${SCREEN_MEDIA_QUERY.xs} {
    width: 76px;
    height: 76px;
  }
`

export default ReviewImages
