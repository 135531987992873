import * as utilData from '@/utils/utilData'

/**
 * TODO codegen type 으로 변환 필요
 */
type ApproveTransactionInput = any
type AskProductQuestionInput = any
type CancelOrderItemsInput = any
type CheckEmailDuplicatedInput = any
type CreateCartItemsInput = any
type CreateCustomerTemplateAddressInput = any
type CreateReviewInput = any
type CreateTransactionInput = any
type DeleteCartItemsInput = any
type DownloadEventCouponsInput = any
type EmailSignupInput = any
type EstimateOrderInput = any
type LeaveUserInput = any
type LinkAccountByEmailInput = any
type LinkAccountBySocialInput = any
type OrderInput = any
type RefundAccountInput = any
type RegisterCouponInput = any
type SocialSigninInput = any
type SocialSignupInput = any
type ToggleRestockProductOptionInput = any
type UpdateAgreeTermsInput = any
type UpdateCartItemInput = any
type UpdateCustomerTemplateAddressInput = any
type UpdateDisplayPetTypeInput = any
type UpdateReviewInput = any
type UpdateUserByCustomerInput = any

const parameterize = (params: { [index: string]: any }) => {
  const _: { [index: string]: string | object } = {}
  const input: { [index: string]: string | boolean | object | null } = {}

  Object.entries(params).forEach(([k, v]) => {
    if (v || v === 0) {
      if (k === 'id') {
        _[k] = v
      } else {
        input[k] = v
      }
    } else if (v === null) {
      if (k === 'startedAt' || k === 'endedAt') {
        input[k] = null
      }
    } else {
      if (typeof v === 'boolean') {
        input[k] = false
      }
    }
  })
  _.input = input
  return utilData.deepcopy(_)
}

type ID = { id: string }

const MUTATION_VARIABLES = {
  APPROVE_TRANSACTION: (params: ID & ApproveTransactionInput) => parameterize(params),
  ASK_PRODUCT_QUESTION: (params: AskProductQuestionInput) => parameterize(params),
  CANCEL_ORDER_ITEMS: (params: CancelOrderItemsInput) => parameterize(params),
  CHECK_EMAIL_DUPLICATED: (params: CheckEmailDuplicatedInput) => parameterize(params),
  CONFIRM_ORDER_ITEM: (params: ID) => parameterize(params),
  CREATE_CART_ITEMS: (params: CreateCartItemsInput) => parameterize(params),
  CREATE_CUSTOMER_TEMPLATE_ADDRESS: (params: CreateCustomerTemplateAddressInput) => parameterize(params),
  CREATE_ORDER: (params: OrderInput) => parameterize(params),
  CREATE_REVIEW: (params: CreateReviewInput) => parameterize(params),
  CREATE_TRANSACTION: (params: CreateTransactionInput) => parameterize(params),
  DELETE_CART_ITEMS: (params: DeleteCartItemsInput) => parameterize(params),
  DELETE_CUSTOMER_TEMPLATE_ADDRESS: (params: ID) => parameterize(params),
  DOWNLOAD_EVENT_COUPONS: (params: DownloadEventCouponsInput) => parameterize(params),
  EMAIL_SIGNUP: (params: EmailSignupInput) => parameterize(params),
  ESTIMATE_ORDER: (params: EstimateOrderInput) => parameterize(params),
  LEAVE_USER: (params: ID & LeaveUserInput) => parameterize(params),
  LINK_ACCOUNT_BY_EMAIL: (params: ID & LinkAccountByEmailInput) => parameterize(params),
  LINK_ACCOUNT_BY_SOCIAL: (params: ID & LinkAccountBySocialInput) => parameterize(params),
  REGISTER_COUPON: (params: RegisterCouponInput) => parameterize(params),
  SOCIAL_SIGNIN: (params: SocialSigninInput) => parameterize(params),
  SOCIAL_SIGNUP: (params: SocialSignupInput) => parameterize(params),
  TOGGLE_RESTOCK_PRODUCT_OPTION: (params: ToggleRestockProductOptionInput) => parameterize(params),
  TOGGLE_REVIEW_LIKED: (params: ID) => parameterize(params),
  UPDATE_AGREE_TERMS: (params: ID & UpdateAgreeTermsInput) => parameterize(params),
  UPDATE_CART_ITEM: (params: ID & UpdateCartItemInput) => parameterize(params),
  UPDATE_CUSTOMER_TEMPLATE_ADDRESS: (params: ID & UpdateCustomerTemplateAddressInput) => parameterize(params),
  UPDATE_DISPLAY_PET_TYPE: (params: ID & UpdateDisplayPetTypeInput) => parameterize(params),
  UPDATE_REFUND_ACCOUNT: (params: ID & RefundAccountInput) => parameterize(params),
  UPDATE_REVIEW: (params: ID & UpdateReviewInput) => parameterize(params),
  UPDATE_USER_BY_CUSTOMER: (params: ID & UpdateUserByCustomerInput) => parameterize(params),
}

export default MUTATION_VARIABLES
