import { OperationVariables, QueryLazyOptions, useLazyQuery } from '@apollo/client'
import USER_QUERY_GQLS from '@/containers/gqls/accounts/user/queries'
import { UserContainer } from '@/containers/users/UserContainer'

type UseCanBuyDeal100 = {
  queryUserCanBuyDeal100: (options?: QueryLazyOptions<OperationVariables> | undefined) => void
}

const useCanBuyDeal100 = (): UseCanBuyDeal100 => {
  const { refreshUserInfo } = UserContainer.useContainer()

  const saveCanBuyDeal100 = () => {
    refreshUserInfo()
  }

  const [queryUserCanBuyDeal100] = useLazyQuery(USER_QUERY_GQLS.USER_CAN_BUY_DEAL100, {
    fetchPolicy: 'no-cache',
    onCompleted: saveCanBuyDeal100,
  })

  return {
    queryUserCanBuyDeal100,
  }
}

export default useCanBuyDeal100
