import { gql } from '@apollo/client'
import USER_FRAGMENTS from '@/containers/gqls/accounts/user/fragments'

const USER_MUTATION_GQLS = {
  EMAIL_SIGNUP: gql`
    mutation emailSignup($input: EmailSignupInput!) {
      emailSignup(input: $input) {
        user {
          ...userProfile
          grade {
            id
            name
            code
          }
          userCoupons {
            edges {
              node {
                usedAt
                couponGroup {
                  id
                }
              }
            }
          }
        }
        device {
          ...userDevice
        }
      }
    }
    ${USER_FRAGMENTS.userProfile}
    ${USER_FRAGMENTS.userDevice}
  `,
  SOCIAL_SIGNUP: gql`
    mutation socialSignup($input: SocialSignupInput!) {
      socialSignup(input: $input) {
        user {
          ...userProfile
          grade {
            id
            name
            code
          }
        }
        device {
          ...userDevice
        }
      }
    }
    ${USER_FRAGMENTS.userProfile}
    ${USER_FRAGMENTS.userDevice}
  `,
  SOCIAL_SIGNIN: gql`
    mutation socialSignin($input: SocialSigninInput!) {
      socialSignin(input: $input) {
        user {
          ...userProfile
          grade {
            id
            name
            code
          }
        }
        device {
          ...userDevice
        }
      }
    }
    ${USER_FRAGMENTS.userProfile}
    ${USER_FRAGMENTS.userDevice}
  `,
  UPDATE_USER_BY_CUSTOMER: gql`
    mutation updateUserByCustomer($id: ID!, $input: UpdateUserByCustomerInput!) {
      updateUserByCustomer(id: $id, input: $input) {
        user {
          ...userProfile
        }
      }
    }
    ${USER_FRAGMENTS.userProfile}
  `,
  LINK_ACCOUNT_BY_SOCIAL: gql`
    mutation linkAccountBySocial($id: ID!, $input: LinkAccountBySocialInput!) {
      linkAccountBySocial(id: $id, input: $input) {
        user {
          id
          userSocialServices {
            totalCount
            edges {
              node {
                socialType
              }
            }
          }
        }
      }
    }
  `,
  LINK_ACCOUNT_BY_EMAIL: gql`
    mutation linkAccountByEmail($id: ID!, $input: LinkAccountByEmailInput!) {
      linkAccountByEmail(id: $id, input: $input) {
        user {
          ...userProfile
        }
      }
    }
    ${USER_FRAGMENTS.userProfile}
  `,
  UPDATE_REFUND_ACCOUNT: gql`
    mutation updateRefundAccount($id: ID!, $input: RefundAccountInput!) {
      updateRefundAccount(id: $id, input: $input) {
        user {
          id
          username
          name
          accountNumber
          accountHolderName
          bankName
        }
      }
    }
  `,
  UPDATE_AGREE_TERMS: gql`
    mutation updateAgreeTermsDefault($id: ID!, $input: UpdateAgreeTermsInput!) {
      updateAgreeTerms(id: $id, input: $input) {
        user {
          id
        }
      }
    }
  `,
  LEAVE_USER: gql`
    mutation leaveUser($id: ID!, $input: LeaveUserInput!) {
      leaveUser(id: $id, input: $input) {
        user {
          id
        }
      }
    }
  `,
  UPDATE_DISPLAY_PET_TYPE: gql`
    mutation updateDisplayPetType($id: ID!, $input: UpdateDisplayPetTypeInput!) {
      updateDisplayPetType(id: $id, input: $input) {
        user {
          id
        }
      }
    }
  `,
}

export default USER_MUTATION_GQLS
