import moment from 'moment'
import { pwRegexEng, pwRegexName, pwRegexNum, pwRegexSpc } from '@/constants/legacy/constForm'

export const pass = () => Promise.resolve()
export const fail = (msg: string) => Promise.reject(new Error(msg))
export const failWithoutMessage = () => Promise.reject()

// export function emailValidator ( email: string ) {
//   // eslint-disable-next-line max-len
//   const isValidated = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)
//   if ( isValidated ) {
//     return pass()
//   }
//   return fail( '일' )
// }

export function mobilNumberValidator(mobileNumber?: string) {
  let _mobileNumber = ''
  if (mobileNumber) {
    // _mobileNumber = mobileNumber.replace(/-/gi, '').replace(/ /, '').trim()
    _mobileNumber = mobileNumber
  }
  const isValidated = /^(010|011|016|018|019)\d{7,8}$/.test(_mobileNumber)
  if (isValidated) {
    return pass()
  }
  return fail('· 휴대폰 번호를 정확히 입력해 주세요.')
}

export function checkboxRequiredValidator(checked?: boolean, message?: string) {
  if (checked) {
    return pass()
  }
  return fail(message || '항목을 체크해주세요')
}

export function validatePasswordFormat(inputPassword: string) {
  let passCount = 0
  passCount += pwRegexEng.test(inputPassword) ? 1 : 0
  passCount += pwRegexNum.test(inputPassword) ? 1 : 0
  passCount += pwRegexSpc.test(inputPassword) ? 1 : 0
  if (passCount < 2) {
    return false
  }

  // 비허용 문자 처리
  for (let i = 0; i < inputPassword.length; i++) {
    if (
      !pwRegexEng.test(inputPassword[i]) &&
      !pwRegexNum.test(inputPassword[i]) &&
      !pwRegexSpc.test(inputPassword[i])
    ) {
      return false
    }
  }

  return true
}

export function validateEmailFormat(email: string) {
  if (email.length < 4 || 50 < email.length || email.indexOf(' ') > -1) return false

  const splittedEmail = email.split('@')
  if (splittedEmail.length !== 2) return false

  const [localPart, domainPart] = splittedEmail

  const dotRule = new RegExp('\\.{2}|^\\.|\\.$')
  if (dotRule.test(localPart)) return false

  const localPartRule = new RegExp('^[a-zA-Z0-9!#$%^&*\\-_.]+$')
  if (!localPartRule.test(localPart)) return false

  const domainRule = new RegExp('^[0-9a-zA-Z]([\\-_.]?[0-9a-zA-Z])*\\.[a-zA-Z]{2,}$')
  return domainRule.test(domainPart)
}

export function emailFormatValidator(email: string, message?: string) {
  if (email.indexOf(' ') > -1) {
    return fail('· 공백이 포함되어 있습니다.')
  }

  if (validateEmailFormat(email)) {
    return pass()
  }
  return fail(message || '· 이메일 형식이 올바르지 않습니다.')
}

export function validateBirthdayFormat(birthday: string) {
  return moment(birthday, 'YYYYMMDD', true).isValid()
}

export function validatedName(value: string) {
  return pwRegexName.test(value)
}
