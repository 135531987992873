export const ProductsOrderByKey = {
  id: '-id', // 인덱스
  recommend: 'recommend', // 추천순
  point: '-point', // 인기순
  createdAt: '-createdAt', // 최신순
  customerPriceDesc: '-customerPrice', // 높은 가격순
  customerPriceAsc: 'customerPrice', // 낮은 가격순
  finalDiscountRateDesc: '-finalDiscountRate', // 할인율 높은순
  reviewCountDesc: '-reviewCount', // 리뷰 많은순
  displayOrder: 'displayOrder', // MD 추천순 (어드민에서 설정한 순서로 숫자가 낮으면 우선순위 높음)
  isRealSoldOut: 'isRealSoldOut', // 품절상품을 마지막에 정렬
  productPromotionCustomerPriceAsc: 'productPromotionCustomerPrice', // 낮은 가격순 (프로모션 가격)
  productPromotionCustomerPriceDesc: '-productPromotionCustomerPrice', // 높은 가격순 (프로모션 가격)
  reviewScoreDesc: '-reviewScore', // 리뷰 높은순
} as const
