import { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { Header, HomeNavigationBar, SearchHeader } from '@/components/domains/layouts/header'
import ListFilterHeader from '@/components/domains/layouts/header/ListFilterHeader'
import { Z_INDEXES } from '@/constants/legacy/constLayout'
import ROUTES from '@/constants/legacy/constRoutes'
import { HeaderIdEnum } from '@/constants/local-navigation-tab-title.enum'
import useCustomRouter from '@/containers/hooks/useCustomRouter'
import { doSetIsShowHeader } from '@/stores/reduxUI'
import { RootState } from '@/stores/store'

const SearchHeaderTypePages = [ROUTES.SEARCH, ROUTES.SEARCH_RESULT, ROUTES.SEARCH_KEYWORD]
const FilterHeaderTypePages = [ROUTES.PRODUCTS.BUNDLED_LIST]

const LayoutHeader = () => {
  const dispatch = useDispatch()
  const { isMainTab, pathname, shouldHiddenLayoutHeader } = useCustomRouter()
  const isShowHeader = useSelector((state: RootState) => state.reduxUIReducers.isShowHeader)
  const lastScrollRef = useRef<number>(0)

  const returnHeaderType = () => {
    if (SearchHeaderTypePages.includes(pathname)) {
      return <SearchHeader />
    }
    if (FilterHeaderTypePages.includes(pathname)) {
      return <ListFilterHeader />
    }
    return <Header />
  }

  // 스크롤 방향에 따라 헤더 특정 영역 노출 분기
  const onScroll = () => {
    const { pageYOffset } = window
    dispatch(doSetIsShowHeader(pageYOffset < 100 || lastScrollRef.current > pageYOffset))
    lastScrollRef.current = pageYOffset
  }

  useEffect(() => {
    if (isMainTab()) {
      window.addEventListener('scroll', onScroll)
      return () => window.removeEventListener('scroll', onScroll)
    }
  }, [pathname])

  if (shouldHiddenLayoutHeader()) {
    return null
  }

  return (
    <StyledHeader
      id={HeaderIdEnum.Header}
      className={isShowHeader ? '' : 'hidden'} // HomeEventDetailContent에서 Header 숨김영역 확인을 위해서 사용
    >
      <div id="header-above-nav">{returnHeaderType()}</div>
      {isMainTab() && <HomeNavigationBar />}
    </StyledHeader>
  )
}

const StyledHeader = styled.div`
  z-index: ${Z_INDEXES.HEADER};
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  box-sizing: border-box;
  width: 100%;
  max-width: 576px;
  background-color: ${({
    theme: {
      color: { grayWhite },
    },
  }) => grayWhite};
  transition: transform 0.2s;
  -webkit-transition: transform 0.2s;
`

export default LayoutHeader
