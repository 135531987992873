import { FC } from 'react'
import { ScaledSVG } from '@/components/common/images'

const ProductChevronRight = () => (
  <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5 5L5.35355 4.64645C5.54882 4.84171 5.54882 5.15829 5.35355 5.35355L5 5ZM0.646446 1.35355C0.451184 1.15829 0.451184 0.841709 0.646446 0.646446C0.841709 0.451184 1.15829 0.451184 1.35355 0.646446L0.646446 1.35355ZM1.35355 9.35355C1.15829 9.54882 0.841709 9.54882 0.646447 9.35355C0.451185 9.15829 0.451185 8.84171 0.646447 8.64645L1.35355 9.35355ZM4.64645 5.35355L0.646446 1.35355L1.35355 0.646446L5.35355 4.64645L4.64645 5.35355ZM0.646447 8.64645L4.64645 4.64645L5.35355 5.35355L1.35355 9.35355L0.646447 8.64645Z"
      fill="white"
    />
  </svg>
)

const ProductChevronRightIcon: FC = () => <ScaledSVG component={<ProductChevronRight />} />

export default ProductChevronRightIcon
