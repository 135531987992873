import { isInAppFlag } from '@/utils/utilBridge'
import { localUserToken } from '@/utils/utilLocalStorage'

const UtilToken = {
  getTokenFromLocalStorage: () => {
    const userToken = localUserToken.load()
    return userToken?.token || ''
  },
  getTokenFromApp: () => {
    const apiToken = window.getToken()?.apiToken.token
    return apiToken || undefined
  },
  getToken: () => {
    if (typeof window === 'undefined') {
      return undefined
    }

    return isInAppFlag ? UtilToken.getTokenFromApp() : UtilToken.getTokenFromLocalStorage()
  },
}

export { UtilToken }
