import { createContext, ReactNode } from 'react'
import styled from 'styled-components'
import { ProductPromotionScheme } from '@/apis/rest/generated/types'
import { CartButton } from '@/components/domains/products/new-product-card/CartButton'
import { NewThumbnail } from '@/components/domains/products/new-product-card/NewThumbnail'
import {
  BasicLabelType,
  THUMBNAIL_SIZES,
  THUMBNAIL_STATES,
  ThumbnailState,
  ThumbnailTrackingData,
} from '@/components/domains/products/new-product-card/new-product-card.type'

interface HorizontalProductCardProps {
  children: ReactNode
  onClick: () => void
}

const HorizontalProductCardContext = createContext({} as {})

export const HorizontalProductCard = ({ children, onClick }: HorizontalProductCardProps) => {
  const handleClick = () => {
    onClick()
  }

  return (
    <HorizontalProductCardContext.Provider value={{}}>
      <StyledHorizontalProductCardWrapper onClick={handleClick}>{children}</StyledHorizontalProductCardWrapper>
    </HorizontalProductCardContext.Provider>
  )
}

HorizontalProductCard.displayName = 'HorizontalProductCard'

interface ThumbnailWrapperProps {
  productId: number
  productName: string
  productPromotionScheme?: ProductPromotionScheme
  thumbnailState?: ThumbnailState
  imageUrl: string
  isLiked: boolean
  isRealSoldOut: boolean
  isNotAvailable?: boolean
  rankingNumber?: number
  basicLabel?: BasicLabelType
  trackingData: ThumbnailTrackingData
  callbackLike?: () => void
  sticker?: string
}

const Thumbnail = ({
  productId,
  productName,
  productPromotionScheme,
  thumbnailState,
  imageUrl,
  isLiked,
  isRealSoldOut,
  isNotAvailable,
  rankingNumber,
  basicLabel,
  trackingData,
  callbackLike,
  sticker,
}: ThumbnailWrapperProps) => {
  const thumbnailSize = 'md'
  return (
    <StyledThumbnailWrapper>
      <NewThumbnail
        productId={productId}
        productName={productName}
        productPromotionScheme={productPromotionScheme}
        size={thumbnailSize || THUMBNAIL_SIZES.lg}
        state={thumbnailState || THUMBNAIL_STATES.basicLabel}
        direction="horizontal"
        imageUrl={imageUrl}
        isLiked={isLiked}
        isRealSoldOut={isRealSoldOut}
        isNotAvailable={isNotAvailable}
        rankingNumber={rankingNumber === undefined ? undefined : rankingNumber + 1}
        basicLabel={basicLabel}
        trackingData={trackingData}
        callbackLike={callbackLike}
        sticker={sticker}
      />
    </StyledThumbnailWrapper>
  )
}

Thumbnail.displayName = 'Thumbnail'

interface CartButton2Props {
  disabled: boolean
  onClick: () => void
}

const CartButtonComponent = ({ disabled, onClick }: CartButton2Props) => {
  return (
    <StyledCartButton>
      <CartButton disabled={disabled} onClick={onClick} />
    </StyledCartButton>
  )
}

const BodyContainer = ({ children }: { children: ReactNode }) => {
  return <StyledBodyContainer>{children}</StyledBodyContainer>
}

const StyledBodyContainer = styled.div`
  flex: 1;
  min-height: 12.3rem;
  display: flex;
  flex-direction: column;
`

interface BodyProps {
  children: ReactNode
}

const Body = ({ children }: BodyProps) => {
  return <StyledBodyWrapper>{children}</StyledBodyWrapper>
}

const StyledThumbnailWrapper = styled.div`
  width: 10.9rem;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
`

const StyledHorizontalProductCardWrapper = styled.div`
  cursor: pointer;
  width: 100%;
  display: flex;
  gap: 1.6rem;
`

const StyledBodyWrapper = styled.div``

const StyledCartButton = styled.div`
  margin-top: 0.8rem;
  width: 11.4rem;
`

HorizontalProductCard.Thumbnail = Thumbnail
HorizontalProductCard.CartButton = CartButtonComponent
HorizontalProductCard.Body = Body
HorizontalProductCard.BodyContainer = BodyContainer
