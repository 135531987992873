import {
  IModelDisplayCollectionDefault,
  IModelProductPromotionDefault,
} from '@/containers/models/base/defaultModelInterfaces'
import { ModelDisplayCollectionDefault } from '@/containers/models/base/defaultModels'
import { ModelProduct } from '@/containers/models/index'
import ModelDisplayProductInfo, { IModelDisplayProductInfo } from '@/containers/models/modelDisplayProductInfo'

export interface IModelDisplayCollection extends IModelDisplayCollectionDefault {
  displayProductInfos: IModelDisplayProductInfo[]
  totalCount: number
  getDisplayProductInfosForHomeCache: () => IModelDisplayProductInfo[]
  getProducts: () => ModelProduct[]
  getProductAndPromotions: () => { product: ModelProduct; productPromotion: IModelProductPromotionDefault }[]
}

export default class ModelDisplayCollection extends ModelDisplayCollectionDefault {
  totalCount: number

  // displayProductInfos: IModelDisplayProductInfo[]
  constructor(data: IModelDisplayCollection) {
    super(data)
    // @ts-ignore
    // this.displayProductInfos = _.map( data.displayProductInfos, ( i ) => new ModelDisplayProductInfo( i ) )
    // @ts-ignore
    if (this.collectionType === 'TODAY_SPECIAL_PRICE') {
      this.name = this.name || '오늘만 이 가격!'
    }
    // TODO typescript 오류 수정 필요
    // @ts-ignore
    this.totalCount = data.totalCount | data.displayProductInfos?.totalCount
  }

  getDisplayProductInfosForHomeCache = () => {
    // @ts-ignore
    return this.displayProductInfos.map((i) => new ModelDisplayProductInfo(i))
  }

  getProducts = (): ModelProduct[] => {
    if (!this.displayProductInfos) {
      return []
    }
    return this.displayProductInfos.map((productInfo) => {
      // TODO typescript 오류 수정 필요
      // @ts-ignore
      return new ModelProduct(productInfo.product)
    })
  }

  getProductAndPromotions = (): { product: ModelProduct; productPromotion: IModelProductPromotionDefault }[] => {
    if (!this.displayProductInfos) {
      return []
    }
    return this.displayProductInfos.map((productInfo) => {
      // TODO typescript 오류 수정 필요
      // @ts-ignore
      const product = new ModelProduct(productInfo.product)
      return {
        product,
        productPromotion: product.productPromotion,
      }
    })
  }
}
