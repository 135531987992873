import { FC } from 'react'
import styled from 'styled-components'
import { TextLegacy } from '@/components/common'
import { SocialIconName } from '@/components/common/etc'
import { NewRow } from '@/components/common/layouts/NewRow'
import { SocialServiceSocialType, UserInfoType } from '@/graphql/generated/schemas'
import { formatDate } from '@/utils/utilDate'

type AccountLastLoginInfoProps = {
  userInfo?: UserInfoType
}

const AccountLastLoginInfo: FC<AccountLastLoginInfoProps> = ({ userInfo }) => {
  if (!userInfo) {
    return null
  }

  const { email, accounts } = userInfo
  const { loginAccountType = SocialServiceSocialType.Email, lastLoggedAt } = accounts?.[0] || {}

  return (
    <StyledContainer>
      <NewRow align="center">
        <SocialIconName socialType={loginAccountType as SocialServiceSocialType} />
      </NewRow>
      <NewRow style={{ marginTop: 20 }}>
        <TextLegacy size={{ xs: 14, md: 16 }}>{email}</TextLegacy>
      </NewRow>
      <NewRow style={{ marginTop: 7 }}>
        <TextLegacy size={{ xs: 12, md: 14 }} color="gray400">
          {`마지막 로그인 ${formatDate(lastLoggedAt)}`}
        </TextLegacy>
      </NewRow>
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
  background-color: ${(props) => props.theme.color.gray50};
  padding: 20px;
  margin-top: 20px;
`

export default AccountLastLoginInfo
