import ROUTES from '@/constants/legacy/constRoutes'
import { useCustomRouter } from '@/containers/hooks'

const PageListToChangeCardBodyProductNameLinkToH2Tag = [
  ROUTES.CATEGORY.SUB,
  ROUTES.CATEGORY.ROOT,
  ROUTES.BRAND_DETAIL,
  ROUTES.SECTION_DETAIL,
  ROUTES.SEARCH_RESULT,
  ROUTES.HOME.BEST_SELLER,
  ROUTES.HOME.EVENTS.DETAIL,
  ROUTES.EVENTS_100_DEAL,
  ROUTES.SEARCH_KEYWORD,
] as const

export const useCardComponentSeoHook = () => {
  const { pathname } = useCustomRouter()
  const isSeoH2TagByRoute = () => PageListToChangeCardBodyProductNameLinkToH2Tag.includes(pathname)

  return {
    isSeoH2TagByRoute,
  }
}
