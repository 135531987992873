import React, { FC, PropsWithChildren } from 'react'
import styled from 'styled-components'
import CheckIcon from '@/components/common/inputs/icons/CheckIcon'
import CheckboxIcon, { CheckboxIconProps } from '@/components/common/inputs/icons/CheckboxIcon'
import { GlobalSizeEnum } from '@/constants/global-size.enum'

type InputCheckboxProps = {
  id: string
  checked: boolean
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  size?: GlobalSizeEnum
  disabled?: boolean
  useBackground?: boolean
}

type InputCheckboxSubComponent = {
  Icon: React.FC<CheckboxIconProps>
}

const InputCheckbox: FC<PropsWithChildren<InputCheckboxProps>> & InputCheckboxSubComponent = ({
  id,
  checked = false,
  onChange = () => {},
  size = GlobalSizeEnum.Sm,
  disabled = false,
  useBackground = true,
  children,
}) => {
  return (
    <>
      <StyledLabel htmlFor={id}>
        {useBackground ? (
          <InputCheckbox.Icon checked={checked} size={size} disabled={disabled} />
        ) : (
          <CheckIcon checked={checked} size={size} disabled={disabled} />
        )}

        {children}
      </StyledLabel>
      <input
        id={id}
        type="checkbox"
        checked={checked}
        onChange={onChange}
        style={{
          position: 'absolute',
          width: '1px',
          height: '1px',
          margin: '-1px',
          border: '0',
          padding: '0',
          whiteSpace: 'nowrap',
          clipPath: 'inset(100%)',
          clip: 'rect(0 0 0 0)',
          overflow: 'hidden',
        }}
        onClick={(e) => e.stopPropagation()}
        disabled={disabled}
      />
    </>
  )
}

const StyledLabel = styled.label`
  display: inline-flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
`

InputCheckbox.Icon = CheckboxIcon
export default InputCheckbox
