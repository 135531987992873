import produce from 'immer'
import { createAction, handleActions } from 'redux-actions'
import { EstimateOrderRequestDtoV1, EstimateOrderResponseDtoV1 } from '@/apis/rest/generated/types'
import { FilterModalSelectListType } from '@/components/domains/search/types/filterModal.type'
import {
  AdSlugPropertiesType,
  BeforePageType,
  OrderItemType,
  PartnersKeyword,
  SignupLinkAccountType,
  UserRefundAccountType,
} from '@/constants/legacy/constType'
import { CartResponseDtoWithChecked } from '@/containers/cart/useCartContents'
import { LikeProductType } from '@/containers/hooks/useLikeProduct'
import { IModelCustomerAddress } from '@/containers/models/modelCustomerAddress'
import { IModelCustomerTemplateAddress } from '@/containers/models/modelCustomerTemplateAddress'
import { IModelInitData } from '@/containers/models/modelInitData'
import { IModelOrder } from '@/containers/models/modelOrder'
import { CartShippingGroupType } from '@/containers/types/CartShippingGroup'
import { AdPetType, CustomerTemplateAddressType, SocialServiceSocialType } from '@/graphql/generated/schemas'
import { BusinessForm, PersonalForm } from '@/schemas/partners.schema'
import {
  BottomSheetData,
  CartConfirmationBottomSheetData,
  CartProductCouponData,
  CartProductOptionChangeBottomSheetData,
  PartialBottomSheetData,
  ProductChoiceBottomSheetData,
  ProductCouponDownloadBottomSheetData,
} from '@/types/bottom-sheet.type'
import {
  localLikeProductList,
  localPetType,
  localRecentSearchKeywordList,
  localSearchFilterSelectList,
} from '@/utils/utilLocalStorage'
import type { BackAction, BackActionMap, BackActionQueue } from '@/components/common/back-action/types'

const INIT_ALL = 'data/INIT_ALL'
const SET_INIT_DATA = 'data/SET_INIT_DATA'
const SET_PET_TYPE = 'data/SET_PET_TYPE'
const SET_CART_SHIPPING_GROUPS = 'data/SET_CART_SHIPPING_GROUPS'
const SET_ORDER_ITEMS = 'data/SET_ORDER_ITEMS'
const SET_CUSTOMER_ADDRESS_FOR_ORDER = 'data/SET_CUSTOMER_ADDRESS_FOR_ORDER'
const SET_RETRIEVAL_CUSTOMER_ADDRESS = 'data/SET_RETRIEVAL_CUSTOMER_ADDRESS'
const SET_FILTER_MODAL_SELECT_LIST = 'data/SET_FILTER_MODAL_SELECT_LIST'
const SET_PASSWORD_CONFIRMED = 'data/SET_PASSWORD_CONFIRMED'
const SET_TEMP_USER_CERTIFICATION = 'data/SET_TEMP_USER_CERTIFICATION'
const SET_ESTIMATE_ORDER = 'data/SET_ESTIMATE_ORDER'
const SET_SIGNUP_LINK_ACCOUNT = 'data/SET_SIGNUP_LINK_ACCOUNT'
const SET_REFUND_ACCOUNT = 'data/SET_REFUND_ACCOUNT'
const SET_TOGGLE_REFETCH_ORDER_LIST = 'data/SET_TOGGLE_REFETCH_ORDER_LIST'
const SET_LIKE_PRODUCT_LIST = 'data/SET_LIKE_PRODUCT_LIST'
const SET_RECENT_SEARCH_KEYWORD_LIST = 'data/SET_RECENT_SEARCH_KEYWORD_LIST'
const SET_RESTOCK_PRODUCT_OPTIONS = 'data/SET_RESTOCK_PRODUCT_OPTIONS'
const SET_EVENT_DETAIL_SLUG = 'data/SET_EVENT_DETAIL_SLUG'
const SET_AD_SLUG_PROPERTIES = 'data/SET_AD_SLUG_PROPERTIES'
const SET_BEFORE_PAGE = 'data/SET_BEFORE_PAGE'
const SET_ADDRESS_LIST_LENGTH = 'data/SET_ADDRESS_LIST_LENGTH'
const SET_PERSONAL_PARTNERS_REGISTER_DATA = 'data/SET_PERSONAL_PARTNERS_REGISTER_DATA'
const SET_BUSINESS_PARTNERS_REGISTER_DATA = 'data/SET_BUSINESS_PARTNERS_REGISTER_DATA'
const SET_PARTNERS_KEYWORD_DATA = 'data/SET_PARTNERS_KEYWORD_DATA'
const SET_ESTIMATE_ORDER_REQUEST_DATA = 'data/SET_ESTIMATE_ORDER_REQUEST_DATA'
const SET_ESTIMATE_ORDER_REQUEST_DATA_SNAPSHOT = 'data/SET_ESTIMATE_ORDER_REQUEST_DATA_SNAPSHOT'
const SET_ESTIMATE_ORDER_RESPONSE_DATA = 'data/SET_ESTIMATE_ORDER_RESPONSE_DATA'
const SET_ORDER_PAGE_COUPON_APPLY_MESSAGE = 'data/SET_ORDER_PAGE_COUPON_APPLY_MESSAGE'
const SET_INIT_LIKE_SYNC = 'data/SET_INIT_LIKE_SYNC'
const SET_BOTTOM_SHEET_DATA = 'data/SET_BOTTOM_SHEET_DATA'
const SET_CART_PRODUCT_COUPON_BOTTOM_SHEET_DATA = 'data/SET_CART_PRODUCT_COUPON_BOTTOM_SHEET_DATA'
const SET_CART_DATA = 'data/SET_CART_DATA'
const SET_PRODUCT_CHOICE_BOTTOM_SHEET_DATA = 'data/SET_PRODUCT_CHOICE_BOTTOM_SHEET_DATA'
const SET_CART_CONFIRMATION_BOTTOM_SHEET_DATA = 'data/SET_CART_CONFIRMATION_BOTTOM_SHEET_DATA'
const SET_PRODUCT_COUPON_DOWNLOAD_BOTTOM_SHEET_DATA = 'data/SET_PRODUCT_COUPON_DOWNLOAD_BOTTOM_SHEET_DATA'
const SET_CART_CHANGE_OPTION_BOTTOM_SHEET_DATA = 'data/SET_CART_CHANGE_OPTION_BOTTOM_SHEET_DATA'
const SET_BACK_ACTION_EN_QUEUE = 'data/SET_BACK_ACTION_EN_QUEUE'
const SET_BACK_ACTION_DE_QUEUE = 'data/SET_BACK_ACTION_DE_QUEUE'
const SET_BACK_ACTION_CLEAR = 'data/SET_BACK_ACTION_CLEAR'
const SET_BACK_ACTION_STATUS_QUEUE = 'data/SET_BACK_ACTION_STATUS_QUEUE'
const SET_USER_SOCIAL_TYPE = 'data/SET_USER_SOCIAL_TYPE'

export const doInitAllData = createAction(INIT_ALL)
export const doSetInitData = createAction(SET_INIT_DATA, (data: any) => data)
export const doSetPetType = createAction(SET_PET_TYPE, (data: AdPetType) => data)
export const doSetCustomerAddressForOrder = createAction(
  SET_CUSTOMER_ADDRESS_FOR_ORDER,
  (data: CustomerTemplateAddressType | undefined) => data
)
export const doSetRetrievalCustomerAddress = createAction(
  SET_RETRIEVAL_CUSTOMER_ADDRESS,
  (data: IModelCustomerTemplateAddress | IModelCustomerAddress | undefined) => data
)
export const doSetPasswordConfirmed = createAction(SET_PASSWORD_CONFIRMED, (data: boolean) => data)
export const doSetTempUserCertification = createAction(
  SET_TEMP_USER_CERTIFICATION,
  (data: TempUserCertificationType) => data
)
export const doSetSignupLinkAccount = createAction(
  SET_SIGNUP_LINK_ACCOUNT,
  (data: SignupLinkAccountType | undefined) => data
)
export const doSetRefundAccount = createAction(SET_REFUND_ACCOUNT, (data: UserRefundAccountType | undefined) => data)
export const doSetToggleRefetchOrderList = createAction(SET_TOGGLE_REFETCH_ORDER_LIST, (data: boolean) => data)
export const doSetLikeProductList = createAction(SET_LIKE_PRODUCT_LIST, (data: LikeProductType[]) => data)

export const doInitLikeSync = createAction(SET_INIT_LIKE_SYNC)
export const doSetRecentSearchKeywordList = createAction(SET_RECENT_SEARCH_KEYWORD_LIST)
export const doSetRestockProductOptions = createAction(
  SET_RESTOCK_PRODUCT_OPTIONS,
  (data: string[] | undefined) => data
)
export const doSetAdSlugProperties = createAction(SET_AD_SLUG_PROPERTIES, (data: AdSlugPropertiesType) => data)

export const doSetAddressListLength = createAction(SET_ADDRESS_LIST_LENGTH, (data: number) => data)
export const doSetPersonalPartnersRegisterData = createAction(
  SET_PERSONAL_PARTNERS_REGISTER_DATA,
  (data: PersonalForm | undefined) => data
)
export const doSetBusinessPartnersRegisterData = createAction(
  SET_BUSINESS_PARTNERS_REGISTER_DATA,
  (data: BusinessForm | undefined) => data
)

export const doSetPartnersKeywordData = createAction(SET_PARTNERS_KEYWORD_DATA, (data: PartnersKeyword) => data)

export const doSetEstimateOrderRequestData = createAction(
  SET_ESTIMATE_ORDER_REQUEST_DATA,
  (data: EstimateOrderRequestDtoV1 | undefined) => data
)

export const doSetEstimateOrderRequestDataSnapshot = createAction(
  SET_ESTIMATE_ORDER_REQUEST_DATA_SNAPSHOT,
  (data: EstimateOrderRequestDtoV1 | undefined) => data
)

export const doSetEstimateOrderResponseData = createAction(
  SET_ESTIMATE_ORDER_RESPONSE_DATA,
  (data: EstimateOrderResponseDtoV1 | undefined) => data
)

export const doSetBottomSheetData = createAction(SET_BOTTOM_SHEET_DATA, (data: PartialBottomSheetData) => data)

export const doSetCartChangeOptionBottomSheetData = createAction(
  SET_CART_CHANGE_OPTION_BOTTOM_SHEET_DATA,
  (data: CartProductOptionChangeBottomSheetData) => data
)

export const doSetCartData = createAction(SET_CART_DATA, (data: CartResponseDtoWithChecked | undefined) => data)

export const doSetCartProductCouponBottomSheetData = createAction(
  SET_CART_PRODUCT_COUPON_BOTTOM_SHEET_DATA,
  (data: CartProductCouponData | undefined) => data
)
export const doSetProductChoiceBottomSheetData = createAction(
  SET_PRODUCT_CHOICE_BOTTOM_SHEET_DATA,
  (data: ProductChoiceBottomSheetData) => data
)
export const doSetCartConfirmationBottomSheetData = createAction(
  SET_CART_CONFIRMATION_BOTTOM_SHEET_DATA,
  (data: CartConfirmationBottomSheetData) => data
)

export const doSetProductCouponDownloadBottomSheetData = createAction(
  SET_PRODUCT_COUPON_DOWNLOAD_BOTTOM_SHEET_DATA,
  (data: ProductCouponDownloadBottomSheetData) => data
)

export const doSetOrderPageCouponApplyMessage = createAction(
  SET_ORDER_PAGE_COUPON_APPLY_MESSAGE,
  (data: string | undefined) => data
)
export const doSetBackActionEnQueue = createAction(
  SET_BACK_ACTION_EN_QUEUE,
  (data: { name: BackActionMap['name']; condition: BackActionQueue['condition'] }) => data
)
export const doSetBackActionDeQueue = createAction(
  SET_BACK_ACTION_DE_QUEUE,
  (data: { name: BackActionMap['name'] }) => data
)
export const doSetBackActionClear = createAction(SET_BACK_ACTION_CLEAR, (data: { name: BackActionMap['name'] }) => data)
export const doSetBackActionStatusQueue = createAction(
  SET_BACK_ACTION_STATUS_QUEUE,
  (data: { name: BackActionMap['name']; status: BackActionQueue['status'] }) => data
)

export const doSetUserSocialType = createAction(SET_USER_SOCIAL_TYPE, (data: SocialServiceSocialType) => data)

type TempUserCertificationType = {
  userId: string
  userCertificationId: string
}

type BackActionMapData = {
  [P in BackActionMap['name']]: BackAction
}

type ReduxDataStateType = {
  petType: AdPetType
  cartShippingGroups?: CartShippingGroupType[]
  orderItems?: OrderItemType[]
  customerAddressForOrder?: CustomerTemplateAddressType
  retrievalCustomerAddress?: IModelCustomerTemplateAddress
  filterModalSelectList: FilterModalSelectListType
  passwordConfirmed: boolean
  tempUserCertification?: TempUserCertificationType
  estimateOrder?: IModelOrder
  signupLinkAccount?: SignupLinkAccountType
  refundAccount?: UserRefundAccountType
  shouldRefetchOrderList: boolean
  initData?: IModelInitData
  likeProductList: LikeProductType[]
  recentSearchKeywordList: string[]
  restockProductOptionIds?: string[]
  eventDetailSlug?: string
  adSlugProperties?: AdSlugPropertiesType
  beforePage?: BeforePageType
  addressLength?: number
  personalRegisterData?: PersonalForm
  businessRegisterData?: BusinessForm
  partnersKeywordData?: PartnersKeyword
  estimatedOrderRequest?: EstimateOrderRequestDtoV1
  estimatedOrderRequestSnapshot?: EstimateOrderRequestDtoV1
  estimatedOrderResponse?: EstimateOrderResponseDtoV1
  orderPageCouponApplyMessage: string
  initLikeSyncData?: { [p: string]: LikeProductType }
  bottomSheetData: BottomSheetData
  cartData?: CartResponseDtoWithChecked
  cartProductCouponBottomSheetData?: CartProductCouponData
  productChoiceBottomSheetData: ProductChoiceBottomSheetData
  cartConfirmationBottomSheetData: CartConfirmationBottomSheetData
  productCouponDownloadBottomSheetData: ProductCouponDownloadBottomSheetData
  cartChangeOptionBottomSheetData?: CartProductOptionChangeBottomSheetData
  backActionMapData: BackActionMapData
  userSocialType?: SocialServiceSocialType
}

const initialState: ReduxDataStateType = {
  petType: localPetType.load() || AdPetType.Dog,
  cartShippingGroups: undefined,
  orderItems: undefined,
  customerAddressForOrder: undefined,
  retrievalCustomerAddress: undefined,
  filterModalSelectList: localSearchFilterSelectList.load(),
  passwordConfirmed: false,
  tempUserCertification: undefined,
  estimateOrder: undefined,
  signupLinkAccount: undefined,
  refundAccount: undefined,
  shouldRefetchOrderList: false,
  initData: undefined,
  likeProductList: localLikeProductList.load(),
  recentSearchKeywordList: localRecentSearchKeywordList.load(),
  restockProductOptionIds: undefined,
  eventDetailSlug: '',
  adSlugProperties: undefined,
  beforePage: undefined,
  addressLength: undefined,
  personalRegisterData: undefined,
  businessRegisterData: undefined,
  partnersKeywordData: undefined,
  estimatedOrderRequest: undefined,
  estimatedOrderRequestSnapshot: undefined,
  estimatedOrderResponse: undefined,
  orderPageCouponApplyMessage: '',
  initLikeSyncData: undefined,
  cartData: undefined,
  cartProductCouponBottomSheetData: undefined,
  cartConfirmationBottomSheetData: {
    reduxProductInCartConfirmationBottomSheet: undefined,
  },

  cartChangeOptionBottomSheetData: undefined,
  bottomSheetData: {
    isCartProductCouponVisible: false,
    isCartProductOptionChangeVisible: false,
    isProductChoiceVisible: false,
    isCartConfirmationVisible: false,
    isProductCouponDownloadVisible: false,
    isCartOrderCouponDownloadVisible: false,
  },
  productChoiceBottomSheetData: {
    reduxProductInProductChoiceBottomSheet: undefined,
    reduxTrackerDataInProductChoiceBottomSheet: undefined,
  },
  productCouponDownloadBottomSheetData: {
    productId: 0,
    promotionId: undefined,
    productName: '',
    isRealSoldOut: undefined,
    buttonText: '',
    addLocation: undefined,
  },
  backActionMapData: {
    'recommend-list-for-search-result': {
      queue: [],
      pathname: '/search/result',
    },
    'recommend-list-for-category-list': {
      queue: [],
      pathname: '/category/[rootCategorySlug]/[subCategorySlug]',
    },
  },
  userSocialType: undefined,
}

export default handleActions<ReduxDataStateType, any>(
  {
    [SET_INIT_DATA]: (state, { payload: data }) =>
      produce(state, (draft) => {
        draft.initData = data
      }),
    [SET_PET_TYPE]: (state, { payload: petType }) =>
      produce(state, (draft) => {
        draft.petType = petType
      }),
    [SET_CART_SHIPPING_GROUPS]: (state, { payload: cartShippingGroups }) =>
      produce(state, (draft) => {
        draft.cartShippingGroups = cartShippingGroups
      }),
    [SET_ORDER_ITEMS]: (state, { payload: orderItems }) =>
      produce(state, (draft) => {
        draft.orderItems = orderItems
      }),
    [SET_CUSTOMER_ADDRESS_FOR_ORDER]: (state, { payload: customerAddressForOrder }) =>
      produce(state, (draft) => {
        draft.customerAddressForOrder = customerAddressForOrder
      }),
    [SET_FILTER_MODAL_SELECT_LIST]: (state, { payload: filterModalSelectList }) =>
      produce(state, (draft) => {
        draft.filterModalSelectList = filterModalSelectList
      }),
    [SET_RETRIEVAL_CUSTOMER_ADDRESS]: (state, { payload: retrievalCustomerAddress }) =>
      produce(state, (draft) => {
        draft.retrievalCustomerAddress = retrievalCustomerAddress
      }),
    [SET_PASSWORD_CONFIRMED]: (state, { payload: confirmed }) =>
      produce(state, (draft) => {
        draft.passwordConfirmed = confirmed
      }),
    [SET_TEMP_USER_CERTIFICATION]: (state, { payload: userCertification }) =>
      produce(state, (draft) => {
        draft.tempUserCertification = userCertification
      }),
    [SET_ESTIMATE_ORDER]: (state, { payload: estimateOrder }) =>
      produce(state, (draft) => {
        draft.estimateOrder = estimateOrder
      }),
    [SET_SIGNUP_LINK_ACCOUNT]: (state, { payload: signupLinkAccount }) =>
      produce(state, (draft) => {
        draft.signupLinkAccount = signupLinkAccount
      }),
    [SET_REFUND_ACCOUNT]: (state, { payload: refundAccount }) =>
      produce(state, (draft) => {
        draft.refundAccount = refundAccount
      }),
    [SET_TOGGLE_REFETCH_ORDER_LIST]: (state, { payload: shouldRefetchOrderList }) =>
      produce(state, (draft) => {
        draft.shouldRefetchOrderList = shouldRefetchOrderList
      }),
    [SET_LIKE_PRODUCT_LIST]: (state, { payload: likeProductList }) =>
      produce(state, (draft) => {
        draft.likeProductList = likeProductList
      }),
    [SET_RECENT_SEARCH_KEYWORD_LIST]: (state, { payload: recentSearchKeywordList }) =>
      produce(state, (draft) => {
        draft.recentSearchKeywordList = recentSearchKeywordList
      }),
    [SET_RESTOCK_PRODUCT_OPTIONS]: (state, { payload: restockProductOptionIds }) =>
      produce(state, (draft) => {
        draft.restockProductOptionIds = restockProductOptionIds
      }),
    [SET_EVENT_DETAIL_SLUG]: (state, { payload: data }) =>
      produce(state, (draft) => {
        draft.eventDetailSlug = data
      }),
    [SET_AD_SLUG_PROPERTIES]: (state, { payload: data }) =>
      produce(state, (draft) => {
        draft.adSlugProperties = data
      }),
    [SET_BEFORE_PAGE]: (state, { payload: data }) =>
      produce(state, (draft) => {
        draft.beforePage = data
      }),
    [SET_ADDRESS_LIST_LENGTH]: (state, { payload: data }) =>
      produce(state, (draft) => {
        draft.addressLength = data
      }),
    [SET_PERSONAL_PARTNERS_REGISTER_DATA]: (state, { payload: data }) =>
      produce(state, (draft) => {
        draft.personalRegisterData = data
      }),
    [SET_BUSINESS_PARTNERS_REGISTER_DATA]: (state, { payload: data }) =>
      produce(state, (draft) => {
        draft.businessRegisterData = data
      }),
    [SET_PARTNERS_KEYWORD_DATA]: (state, { payload: data }) =>
      produce(state, (draft) => {
        draft.partnersKeywordData = data
      }),
    [SET_INIT_LIKE_SYNC]: (state, { payload: data }) =>
      produce(state, (draft) => {
        draft.initLikeSyncData = data
      }),

    [SET_ESTIMATE_ORDER_REQUEST_DATA]: (state, { payload: data }) =>
      produce(state, (draft) => {
        draft.estimatedOrderRequest = data
      }),

    [SET_ESTIMATE_ORDER_REQUEST_DATA_SNAPSHOT]: (state, { payload: data }) =>
      produce(state, (draft) => {
        draft.estimatedOrderRequestSnapshot = data
      }),

    [SET_ESTIMATE_ORDER_RESPONSE_DATA]: (state, { payload: data }) =>
      produce(state, (draft) => {
        draft.estimatedOrderResponse = data
      }),
    [SET_BOTTOM_SHEET_DATA]: (state, { payload: data }) =>
      produce(state, (draft) => {
        draft.bottomSheetData = {
          ...state.bottomSheetData,
          ...data,
        }
      }),
    [SET_CART_DATA]: (state, { payload: data }) =>
      produce(state, (draft) => {
        draft.cartData = data
      }),

    [SET_CART_PRODUCT_COUPON_BOTTOM_SHEET_DATA]: (state, { payload: data }) =>
      produce(state, (draft) => {
        draft.cartProductCouponBottomSheetData = data
      }),
    [SET_PRODUCT_CHOICE_BOTTOM_SHEET_DATA]: (state, { payload: data }) =>
      produce(state, (draft) => {
        draft.productChoiceBottomSheetData = data
      }),
    [SET_CART_CONFIRMATION_BOTTOM_SHEET_DATA]: (state, { payload: data }) =>
      produce(state, (draft) => {
        draft.cartConfirmationBottomSheetData = data
      }),
    [SET_PRODUCT_COUPON_DOWNLOAD_BOTTOM_SHEET_DATA]: (state, { payload: data }) =>
      produce(state, (draft) => {
        draft.productCouponDownloadBottomSheetData = data
      }),
    [SET_CART_CHANGE_OPTION_BOTTOM_SHEET_DATA]: (state, { payload: data }) =>
      produce(state, (draft) => {
        draft.cartChangeOptionBottomSheetData = data
      }),

    [SET_ORDER_PAGE_COUPON_APPLY_MESSAGE]: (state, { payload: data }) =>
      produce(state, (draft) => {
        draft.orderPageCouponApplyMessage = data
      }),
    [SET_BACK_ACTION_EN_QUEUE]: (
      state,
      { payload: data }: { payload: { name: BackActionMap['name']; condition: BackActionQueue['condition'] } }
    ) =>
      produce(state, (draft) => {
        const { name, condition } = data

        draft.backActionMapData[name] = {
          ...draft.backActionMapData[name],
          queue: [...draft.backActionMapData[name].queue, { condition, status: 'inactive' }],
        }
      }),
    [SET_BACK_ACTION_DE_QUEUE]: (state, { payload: data }: { payload: { name: BackActionMap['name'] } }) =>
      produce(state, (draft) => {
        const { name } = data
        if (draft.backActionMapData[name].queue.length === 0) return

        const popped = draft.backActionMapData[name].queue.slice(1, origin.length)
        draft.backActionMapData[name] = { ...draft.backActionMapData[name], queue: popped }
      }),
    [SET_BACK_ACTION_CLEAR]: (state, { payload: data }: { payload: { name: BackActionMap['name'] } }) =>
      produce(state, (draft) => {
        const { name } = data
        draft.backActionMapData[name] = initialState.backActionMapData[name]
      }),
    [SET_BACK_ACTION_STATUS_QUEUE]: (
      state,
      { payload: data }: { payload: { name: BackActionMap['name']; status: BackActionQueue['status'] } }
    ) =>
      produce(state, (draft) => {
        const { name, status } = data
        const queue = draft.backActionMapData[name].queue

        if (queue.length === 0) return

        queue[0].status = status
        draft.backActionMapData[name] = { ...draft.backActionMapData[name], queue }
      }),
    [SET_USER_SOCIAL_TYPE]: (state, { payload: data }) =>
      produce(state, (draft) => {
        draft.userSocialType = data
      }),
    [INIT_ALL]: (state) =>
      produce(state, (draft) => {
        draft.petType = initialState.petType
        draft.cartShippingGroups = initialState.cartShippingGroups
        draft.orderItems = initialState.orderItems
        draft.customerAddressForOrder = initialState.customerAddressForOrder
        draft.retrievalCustomerAddress = initialState.retrievalCustomerAddress
        draft.filterModalSelectList = initialState.filterModalSelectList
        draft.passwordConfirmed = initialState.passwordConfirmed
        draft.tempUserCertification = initialState.tempUserCertification
        draft.estimateOrder = initialState.estimateOrder
        draft.signupLinkAccount = initialState.signupLinkAccount
        draft.refundAccount = initialState.refundAccount
        draft.shouldRefetchOrderList = initialState.shouldRefetchOrderList
        draft.likeProductList = initialState.likeProductList
        draft.restockProductOptionIds = initialState.restockProductOptionIds
        draft.eventDetailSlug = initialState.eventDetailSlug
        draft.beforePage = initialState.beforePage
        draft.addressLength = initialState.addressLength
        draft.personalRegisterData = initialState.personalRegisterData
        draft.businessRegisterData = initialState.businessRegisterData
        draft.estimatedOrderRequest = initialState.estimatedOrderRequest
        draft.estimatedOrderRequestSnapshot = initialState.estimatedOrderRequestSnapshot
        draft.estimatedOrderResponse = initialState.estimatedOrderResponse
        draft.bottomSheetData = initialState.bottomSheetData
        draft.cartData = initialState.cartData
        draft.cartProductCouponBottomSheetData = initialState.cartProductCouponBottomSheetData
        draft.productChoiceBottomSheetData = initialState.productChoiceBottomSheetData
        draft.cartConfirmationBottomSheetData = initialState.cartConfirmationBottomSheetData
        draft.productCouponDownloadBottomSheetData = initialState.productCouponDownloadBottomSheetData
        draft.cartChangeOptionBottomSheetData = initialState.cartChangeOptionBottomSheetData
        draft.orderPageCouponApplyMessage = initialState.orderPageCouponApplyMessage
        draft.backActionMapData = initialState.backActionMapData
        draft.userSocialType = initialState.userSocialType
      }),
  },
  initialState
)
