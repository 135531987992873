import { FC, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled, { keyframes } from 'styled-components'
import FlatIcon from '@/components/common/icons/FlatIcon'
import { IconNameEnum } from '@/constants/icon-name.enum'
import { IconSizeEnum } from '@/constants/icon-size.enum'
import { Z_INDEXES } from '@/constants/legacy/constLayout'
import ROUTES from '@/constants/legacy/constRoutes'
import { useTracker } from '@/containers/contexts/EventTrackingProvider'
import { useCustomRouter, usePetType } from '@/containers/hooks'
import { AdPetType } from '@/graphql/generated/schemas'
import { Bridge } from '@/utils/bridge/bridge'
import { FitpetMallEventEnum } from '@/utils/event-tracker/events'
import appBridgeProvider, { isInAppFlag } from '@/utils/utilBridge'
import { getPetTypeRootCategoryKoreanText } from '@/utils/utilCategory'
import { localShowPetTypeTooltip } from '@/utils/utilLocalStorage'

const PetTypeSelectButton: FC = () => {
  const tracker = useTracker()
  const { replace } = useCustomRouter()
  const { setPetType, getPetType } = usePetType()
  const isFirstRender = useRef(true)
  const currentPetType = getPetType()
  const [selectedPetType, setSelectedPetType] = useState<AdPetType | undefined>()
  const changeIconRef = useRef<HTMLDivElement | null>(null)
  const [showToolTip, setShowToolTip] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    setSelectedPetType(currentPetType)
    if (isFirstRender.current) {
      isFirstRender.current = false
    } else {
      tracker.triggerCustomEvent(FitpetMallEventEnum.ClickGnbPetToggle)
    }
  }, [currentPetType])

  // 현재 앱을 통채로 서버사이드 렌더링을 해서 오는 과정에서 컴포넌트 state값에 따라 렌더링이 안되는 현상으로
  // 강제로 selectedPetType을 상태변수로 만들어 기본 값을 undefined로 두고 클라이언트에서 petType을 받으면
  // 해당 값으로 렌더링을 하는 작업을 함,
  // 컴포넌트가 currentPetType이 변함에도 컴포넌트는 재실행되나 렌더링이 안되는 이유는 파악이 안됨. -> ReactDOM의 버그로 추정

  useEffect(() => {
    setSelectedPetType(currentPetType)

    if (!localShowPetTypeTooltip.load()) {
      localShowPetTypeTooltip.save('SHOWING_PET_TYPE_TOOLTIP')
      setShowToolTip(true)
    }

    return () => {
      setShowToolTip(false)
    }
  }, [])

  const onChangePetType = () => {
    changeIconRef.current?.classList.remove('rotateAnimation')
    setPetType(AdPetType.Dog === selectedPetType ? AdPetType.Cat : AdPetType.Dog)
    if (isInAppFlag) {
      appBridgeProvider((bridge: Bridge) => bridge.setting({ petTypeMall: getPetType().toLocaleLowerCase() }))
    }
    replace(ROUTES.INDEX)
    setTimeout(() => {
      changeIconRef.current?.classList.add('rotateAnimation')
    }, 100)
  }
  return (
    <PetTypeButton onClick={onChangePetType}>
      <PetTypeText>{getPetTypeRootCategoryKoreanText(selectedPetType as AdPetType)}</PetTypeText>
      <ChangeIconWrapper ref={changeIconRef}>
        <FlatIcon type={IconNameEnum.IcChange} size={IconSizeEnum.Size24} />
      </ChangeIconWrapper>
      {showToolTip && <PetTypeTooltip>우리 아이에게 맞는 상품을 볼 수 있어요.</PetTypeTooltip>}
    </PetTypeButton>
  )
}

const rotateIcon = keyframes`
  100% {
    transform: rotate(360deg)
  }
`

const fadeIn = keyframes`
  0% {
    transform: scale(0);
    opacity: 0;
  }
  50% {
    transform: scale(1.1);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
  
`
const fadeOut = keyframes`
  
  100% {
    opacity: 0;
    visibility: hidden;
    transform: scale(0);
  }
`

const PetTypeButton = styled.div`
  position: relative;
  width: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 1.6rem;
  background-color: transparent;
  transition: all 0.1s linear;

  &::before {
    visibility: hidden;
    opacity: 0;
    content: '';
    position: absolute;
    width: 7.7rem;
    height: 3.4rem;
    border-radius: 17px;
    transition: all 50ms linear;
    background-color: ${(props) => props.theme.color.gray100};
  }

  &:active {
    &::before {
      visibility: visible;
      opacity: 1;
    }
  }
`

const ChangeIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  &.rotateAnimation {
    animation: ${rotateIcon} 0.5s ease-in-out;
    transform-origin: 50% 50%;
  }
`

const PetTypeText = styled.button`
  position: relative;
  font-size: 1.6rem;
  color: ${(props) => props.theme.color.gray900};
  font-weight: 700;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
`

const PetTypeTooltip = styled.div`
  position: absolute;
  z-index: ${Z_INDEXES.HEADER};
  left: 1.2rem;
  bottom: -4.2rem;
  transform-origin: 0 0;
  transform: scale(0);
  opacity: 0;
  animation: 0.5s Cubic-Bezier(0.9, -0.01, 0.2, 1) 3s forwards ${fadeIn}, 0.18s ease-in-out 6.68s forwards ${fadeOut};
  cursor: default;
  width: 23.6rem;
  padding: 0.8rem 0;
  border-radius: 8px;
  color: ${(props) => props.theme.color.grayWhite};
  background-color: ${(props) => props.theme.color.blue800};
  font-size: 1.4rem;
  font-weight: 500;
  text-align: center;

  &::after {
    content: '';
    position: absolute;
    z-index: ${Z_INDEXES.HEADER};
    left: 12px;
    top: -7px;
    width: 14px;
    height: 8px;
    background-color: ${(props) => props.theme.color.blue800};
    clip-path: path('M6.24742 0.860087C6.64583 0.40476 7.35416 0.404761 7.75258 0.860088L14 8L0 8L6.24742 0.860087Z');
  }
`

export default PetTypeSelectButton
