/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * Fitpet Mall
 * 핏펫몰 API 스펙

API 스펙 작성시 다음 가이드를 숙지하시기 바랍니다.

- [Open API 가이드 참고](https://swagger.io/docs/specification/about/)
- [Kotlin-Spring Open API Generator 설정 참고](https://openapi-generator.tech/docs/generators/kotlin-spring)
- REST API 디자인 가이드 참고
  - doc > guide > rest-api-design.md
- OpenAPI Spec 컨벤션 참고
  - doc > guid > openapi-spec-convention.md
- 설명(description) 작성시 마크다운([CommonMark](https://commonmark.org/help/)) 구문 참고

로컬에서 스웨거 에디터를 사용하는 방법은 다음과 같다.

```shell
docker pull swaggerapi/swagger-editor
docker run -d -p 8123:8080 swaggerapi/swagger-editor
open http://localhost:8123
```

Api 스펙을 하나의 파일로 합치려면 다음과 같이 실행한다.

```shell
# 설치
npm install -g swagger-cli

# 실행
swagger-cli bundle api-spec.yaml --outfile build/openapi.yaml --type yaml
```
 * OpenAPI spec version: 1.0.0
 */

/**
 * 쿠폰 사용 불가능한 이유
- ALREADY_USED : 사용된 쿠폰
- EXPIRED : 사용 기간 만료된 쿠폰
- NOT_PRODUCT_COUPON_TYPE : 상품 쿠폰이 아님
- ONLY_FIRST_ORDER : 첫주문에서만 사용 가능한 쿠폰
- ONLY_MEMBERSHIP : 멤벼십만 사용 가능한 쿠폰
- LESS_THAN_MIN_AMOUNT : 쿠폰 최소 주문 금액보다 작음
 */
export type CouponUnavailableReasonEnum = typeof CouponUnavailableReasonEnum[keyof typeof CouponUnavailableReasonEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CouponUnavailableReasonEnum = {
  ALREADY_USED: 'ALREADY_USED',
  EXPIRED: 'EXPIRED',
  NOT_PRODUCT_COUPON_TYPE: 'NOT_PRODUCT_COUPON_TYPE',
  ONLY_FIRST_ORDER: 'ONLY_FIRST_ORDER',
  ONLY_MEMBERSHIP: 'ONLY_MEMBERSHIP',
  LESS_THAN_MIN_AMOUNT: 'LESS_THAN_MIN_AMOUNT',
} as const
