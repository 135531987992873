import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { welcomeDealEventSlug } from '@/constants/events/welcome-deal.const'
import { GlobalColorEnum } from '@/constants/global-color.enum'
import { InitSocialMetaTag } from '@/constants/initial-social-meta-tag.enum'
import { FITPETMALL_HOST, S3_HOST_URL } from '@/constants/legacy/constEnv'
import { FIXED_HEIGHT } from '@/constants/legacy/constLayout'
import ROUTES from '@/constants/legacy/constRoutes'
import { MembershipColorEnum } from '@/constants/membership-color.enum'
import { MembershipImageUrl } from '@/constants/membership-image-url'
import { usePetType } from '@/containers/hooks'
import useCustomRouter from '@/containers/hooks/useCustomRouter'
import { useShareLinkEventOgQuery } from '@/graphql/generated/hooks'
import { AdPetType } from '@/graphql/generated/schemas'
import { RootState } from '@/stores/store'
import { Bridge } from '@/utils/bridge/bridge'
import { FitpetMallEventEnum } from '@/utils/event-tracker/events'
import appBridgeProvider, { isInAppFlag, isShareAvailable } from '@/utils/utilBridge'
import useShareLink from './share-link.hook'

const isFullscreen = [ROUTES.MEMBERSHIP.JOIN.OWNER.BASE, ROUTES.MEMBERSHIP.JOIN.FAMILY.BASE]

const useLayoutHeader = () => {
  const { isMainTab, isBlueTheme, pathname, query } = useCustomRouter()

  const containerStyle = {
    ...(isMainTab() ? {} : { borderBottom: `0.1rem solid ${GlobalColorEnum.Gray50}` }),
    ...(isBlueTheme() ? { backgroundColor: MembershipColorEnum.BlueBackground, borderBottom: 'unset' } : {}),
    ...(isInAppFlag && isFullscreen.includes(pathname)
      ? {
          paddingTop:
            window.getToken().nativeScreenInfo.statusBarHeight +
            (FIXED_HEIGHT.HEADER - window.getToken().nativeScreenInfo.statusBarHeight),
          height: `${(FIXED_HEIGHT.HEADER + window.getToken().nativeScreenInfo.statusBarHeight) / 10}rem`,
          border: 0,
        }
      : {}),
  }

  const { getPetType } = usePetType()
  const { adSlug, pagePetType } = query as { adSlug: string; pagePetType: string }
  const adSlugProperties = useSelector((state: RootState) => state.reduxDataReducers.adSlugProperties)
  const [_isShareAvailable, setIsShareAvailable] = useState<boolean>(false)
  const ableShareLinkFlag = useRef(true)

  /**
   * 웰컴딜은 100-won-deal.tsx (더 정확한 경로)이기 때문에 pathname을 사용
   * 이외에 이벤트 페이지는 [adSlug].tsx이기 때문에 query의 adSlug값을 사용
   */
  const adSlugParam = pathname.includes(welcomeDealEventSlug)
    ? getPetType() === AdPetType.Dog
      ? '100-won-deal-dog'
      : '100-won-deal-cat'
    : adSlug

  const { data } = useShareLinkEventOgQuery({
    fetchPolicy: 'network-only',
    skip: !adSlugParam || pathname === ROUTES.NOT_FOUND,
    variables: {
      slug: adSlugParam,
    },
  })

  useEffect(() => {
    setIsShareAvailable(isShareAvailable)
  }, [isShareAvailable])

  const shareStaticLink = async (shareLink: string, title?: string, description?: string, imageUrl?: string) => {
    appBridgeProvider((bridge: Bridge) =>
      bridge.linkShare({
        title: `${title}`,
        link: shareLink,
        event: {
          eventName: FitpetMallEventEnum.ClickEventsaleSharetypeButton, // TODO: event 변경
          eventParam: {},
        },
      })
    )
  }

  const { processShareLink } = useShareLink()

  const shareLink = async (eventProperty: { [key: string]: string }) => {
    const url = pagePetType
      ? `${FITPETMALL_HOST}/events/${adSlugParam}?pagePetType=${pagePetType}&petType=${pagePetType}`
      : `${FITPETMALL_HOST}/events/${adSlugParam}`
    const ogTitle = data?.adBySlug?.name
    const ogDesc = InitSocialMetaTag.Description
    const ogImageUrl = `${S3_HOST_URL}/${data?.adBySlug?.mainImage?.image}`

    processShareLink(url, {
      meta: {
        ogTitle,
        ogDesc,
        ogImageUrl,
      },
      appBridgeData: {
        title: `${adSlugProperties && adSlugProperties.eventsaleName}`,
        eventName: FitpetMallEventEnum.ClickEventsaleSharetypeButton,
        eventProperty,
      },
    })
  }

  const handleLinkShare = async () => {
    // TODO: add event event-event-tracker
    /*
     * 링크 쉐어가 끝나는 기준은 onforeground 되어 화면에 돌아오는 타이밍이 아니라
     * 네이티브 앱에 브릿지를 쏘고난 기준입니다.
     * 약간의 시간차가 있어 setTimeout을 넣었습니다.
     * useDebounce나 useThrottle 라이브러리 설치시 딥링크 동작이 느렸습니다.
     * */
    if (!ableShareLinkFlag.current) {
      return
    }
    ableShareLinkFlag.current = false
    await shareStaticLink(
      'https://fitpet.onelink.me/zbGh/yzomczdg',
      '핏펫플러스 멤버십',
      '무조건 2배 적립 + 무료배송 혜택을 받을 수 있어요!',
      MembershipImageUrl.OgImageUrl
    )
    setTimeout(() => {
      ableShareLinkFlag.current = true
    }, 1000)
  }
  return {
    containerStyle,
    adSlugProperties,
    handleLinkShare,
    _isShareAvailable,
    shareLink,
  }
}

export default useLayoutHeader
