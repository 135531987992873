import { useDispatch } from 'react-redux'
import { useGetDownloadableProductCoupons } from '@/apis/rest/generated/hooks'
import { ToastConst } from '@/constants/toast.const'
import { useProductCouponDownloadBottomSheet } from '@/containers/hooks/bottom-sheet/useProductCouponDownloadBottomSheet'
import { doSetGlobalToastContent } from '@/stores/reduxUI'
import { ApiErrorUtils } from '@/utils/utilApiErrors'

interface UseQueryDownloadCouponListProps {
  productId: number
  promotionId?: number
}

export const useQueryDownloadCouponList = ({ productId, promotionId }: UseQueryDownloadCouponListProps) => {
  const dispatch = useDispatch()
  const { closeBottomSheet } = useProductCouponDownloadBottomSheet()
  const { data, isLoading, refetch } = useGetDownloadableProductCoupons(
    productId,
    {
      promotionId,
    },
    {
      query: {
        retry: 1,
        onError: (error) => {
          closeBottomSheet()
          const errorInfo = ApiErrorUtils.getError(error)
          dispatch(
            doSetGlobalToastContent({
              content: `${errorInfo.message}\n오류가 지속된다면 고객센터에 문의해 주세요`,
              duration: 5,
              variant: ToastConst.ToastVariant.withCTA,
            })
          )
        },
      },
    }
  )

  return { data, isLoading, refetch }
}
