import { FitpetMallEventEnum } from '@/constants/fitpet-mall-event.enum'
import { ProductLocation, ProductLocationTrackerValue } from '@/constants/product-location.const'
import { useTracker } from '@/containers/contexts/EventTrackingProvider'
import { CartProductOptionType } from '@/containers/types/CartProductOption'
import { BottomSheetEventTrackerType } from '@/types/bottom-sheet-event-tracker.type'
import { ProductTypeDefinedByFrontend } from '@/types/product-type-defined-by.frontend'
import { convertToKoreanValueFromSupplyType } from '@/utils/event-tracker/events'
import { decodeIdForEventTracker } from '@/utils/utilApi'

const useBottomSheetEventTracker = () => {
  const tracker = useTracker()

  /**
   * 장바구니 담기 이후 노출되는 바텀시트에서 상품 클릭
   */
  const trackClickAddCartBottomUpProductEvent = ({
    sectionName,
    originProductId,
    originProductName,
    productId,
    productName,
    supplyType,
    brandId,
    brandName,
    listType,
    listIndex,
  }: {
    sectionName: string
    originProductId: number
    originProductName: string
    productId: number
    productName: string
    supplyType: string
    brandId?: number
    brandName: string
    listType: string
    listIndex: number
  }) => {
    tracker.triggerCustomEvent(FitpetMallEventEnum.ClickAddCartBottomUpProduct, {
      sectionName,
      originProductId,
      originProductName,
      productId,
      productName,
      supplyType,
      brandId,
      brandName,
      listType,
      listIndex,
    })
  }

  /**
   * 상품 진열 리스트 바텀시트에서 옵션 선택 후 바로 구매 버튼을 클릭했을 때
   * (로그인 팝업이 뜨는 경우에는 이벤트 미발생)
   */
  const trackClickListSinglePurchaseButtonEvent = ({
    supplyType,
    brandId,
    brandName,
    singlePurchasePrice,
    addLocation,
    sectionIndex,
    sectionName,
    searchKeyword,
    searchLocation,
    listIndex,
    cartProductOptionsSelected,
    boughtCount,
  }: {
    supplyType: string
    brandId: string
    brandName: string
    singlePurchasePrice: number
    addLocation?: ProductLocation
    sectionIndex?: number
    sectionName?: string
    searchKeyword?: string
    searchLocation?: string
    listIndex?: number
    cartProductOptionsSelected: CartProductOptionType[]
    boughtCount?: number
  }) => {
    if (!cartProductOptionsSelected.length) {
      return
    }
    const properties = {
      add_location: addLocation ? ProductLocationTrackerValue[addLocation] : undefined,
      list_index: listIndex,
      product_id: decodeIdForEventTracker(cartProductOptionsSelected[0].productId),
      product_name: cartProductOptionsSelected[0].productName,
      supply_type: convertToKoreanValueFromSupplyType(supplyType),
      brand_id: decodeIdForEventTracker(brandId),
      brand_name: brandName,
      product_option_ids: cartProductOptionsSelected.map((option) => decodeIdForEventTracker(option.productOptionId)),
      product_option_names: cartProductOptionsSelected.map((option) => option.productOptionName),
      category_ids: cartProductOptionsSelected.flatMap((option) =>
        option.categories.map((category) => decodeIdForEventTracker(category.id))
      ),
      category_names: cartProductOptionsSelected.flatMap((option) =>
        option.categories.map((category) => category.name)
      ),
      singlepurchase_price: singlePurchasePrice,
      section_index: sectionIndex,
      section_name: sectionName,
      ...(searchKeyword && {
        search_keyword: searchKeyword,
      }),
      ...(searchLocation && {
        search_location: searchLocation,
      }),
      ...(boughtCount && {
        purchase_count: boughtCount,
      }),
    }
    tracker.triggerCustomEvent(FitpetMallEventEnum.ClickListSinglePurchaseButton, properties)
  }

  /**
   * 바텀시트에서 장바구니 담은 후 장바구니 보러 가기 텍스트를 클릭했을 때
   */
  const trackClickListBottomSheetToCartEvent = ({
    supplyType,
    brandId,
    brandName,
    addLocation,
    searchLocation,
    cartProductOptionsSelected,
  }: {
    supplyType: string
    brandId: string
    brandName: string
    addLocation?: ProductLocation
    searchLocation?: string
    cartProductOptionsSelected: CartProductOptionType[]
  }) => {
    if (!cartProductOptionsSelected.length) {
      return
    }
    const properties = {
      add_location: addLocation ? ProductLocationTrackerValue[addLocation] : undefined,
      product_id: decodeIdForEventTracker(cartProductOptionsSelected[0].productId),
      product_name: cartProductOptionsSelected[0].productName,
      supply_type: convertToKoreanValueFromSupplyType(supplyType),
      brand_id: decodeIdForEventTracker(brandId),
      brand_name: brandName,
      addtocart_price: cartProductOptionsSelected.reduce(
        (acc, option) => acc + option.productPromotionCustomerPrice * option.purchaseQuantity,
        0
      ),
      product_option_ids: cartProductOptionsSelected.map((option) => decodeIdForEventTracker(option.productOptionId)),
      product_option_names: cartProductOptionsSelected.map((option) => option.productOptionName),
      category_ids: cartProductOptionsSelected.flatMap((option) =>
        option.categories.map((category) => decodeIdForEventTracker(category.id))
      ),
      category_names: cartProductOptionsSelected.flatMap((option) =>
        option.categories.map((category) => category.name)
      ),
      ...(searchLocation && { search_location: searchLocation }),
    }
    tracker.triggerCustomEvent(FitpetMallEventEnum.ClickListBottomSheetToCart, properties)
  }

  /**
   * 상품 진열 리스트 바텀시트에서 옵션 선택 후 장바구니 담기 버튼을 클릭했을 때
   */
  const trackClickListAddToCartButtonEvent = ({
    listIndex,
    supplyType,
    brandId,
    brandName,
    addLocation,
    sectionIndex,
    sectionName,
    searchKeyword,
    searchLocation,
    cartProductOptionsSelected,
    boughtCount,
  }: {
    listIndex?: number
    supplyType: string
    brandId: string
    brandName: string
    addLocation?: ProductLocation
    sectionIndex?: number
    sectionName?: string
    searchKeyword?: string
    searchLocation?: string
    cartProductOptionsSelected: CartProductOptionType[]
    boughtCount?: number
  }) => {
    const properties = {
      add_location: addLocation ? ProductLocationTrackerValue[addLocation] : undefined,
      list_index: listIndex,
      addtocart_price: cartProductOptionsSelected.reduce(
        (acc, option) => acc + option.productPromotionCustomerPrice * option.purchaseQuantity,
        0
      ),
      product_id: decodeIdForEventTracker(cartProductOptionsSelected[0].productId),
      product_name: cartProductOptionsSelected[0].productName,
      supply_type: convertToKoreanValueFromSupplyType(supplyType),
      brand_id: decodeIdForEventTracker(brandId),
      brand_name: brandName,
      product_option_ids: cartProductOptionsSelected.map((option) => decodeIdForEventTracker(option.productOptionId)),
      product_option_names: cartProductOptionsSelected.map((option) => option.productOptionName),
      category_ids: cartProductOptionsSelected.flatMap((option) =>
        option.categories.map((category) => decodeIdForEventTracker(category.id))
      ),
      category_names: cartProductOptionsSelected.flatMap((option) =>
        option.categories.map((category) => category.name)
      ),
      section_index: sectionIndex,
      section_name: sectionName,
      ...(searchKeyword && { search_keyword: searchKeyword }), // 검색페이지일때만 search_keyword 추가
      ...(searchLocation && { search_location: searchLocation }), // 검색페이지일때만 search_location 추가
      ...(boughtCount && { purchase_count: boughtCount }), // 검색페이지일때만 boughtCount 추가
    }
    tracker.triggerCustomEvent(FitpetMallEventEnum.ClickListAddToCartButton, properties)
  }

  const trackViewAddCartBottomUpProductEvent = ({
    sectionName,
    listType,
    originProductId,
    originProductName,
    supplyType,
    brandId,
    brandName,
  }: {
    sectionName: string
    listType: string
    originProductId: number
    originProductName: string
    supplyType: string
    brandId?: number
    brandName: string
  }) => {
    tracker.triggerCustomEvent(FitpetMallEventEnum.ViewAddCartBottomUpProduct, {
      section_name: sectionName,
      list_type: listType,
      origin_product_id: originProductId,
      origin_product_name: originProductName,
      supply_type: supplyType,
      brand_id: brandId,
      brand_name: brandName,
    })
  }

  /**
   * 상품 진열 리스트 바텀시트에서 옵션 선택 후 장바구니 담기 버튼을 클릭했을 때
   */
  const trackClickListAddToCartButtonEventNew = ({
    product,
    productOptionIds,
    productOptionNames,
    addToCartPrice,
    trackerData,
  }: {
    product: ProductTypeDefinedByFrontend
    productOptionIds: number[]
    productOptionNames: string[]
    addToCartPrice: number
    trackerData?: BottomSheetEventTrackerType
  }) => {
    const properties = {
      add_location: trackerData?.addLocation ? ProductLocationTrackerValue[trackerData.addLocation] : undefined,
      location: trackerData?.location ? trackerData.location : undefined,
      list_index: trackerData?.listIndex,
      product_id: product.id,
      addtocart_price: addToCartPrice,
      product_name: product.name,
      supply_type: convertToKoreanValueFromSupplyType(product.supplyType),
      brand_id: product.brand.id,
      brand_name: product.brand.name,
      product_option_ids: productOptionIds,
      product_option_names: productOptionNames,
      category_ids: product.categories?.map((category) => category.id),
      category_names: product.categories?.map((category) => category.name),
      section_name: trackerData?.sectionName,
      ...(trackerData?.searchKeyword && { search_keyword: trackerData.searchKeyword }), // 검색페이지일때만 search_keyword 추가
      ...(trackerData?.searchLocation && { search_location: trackerData.searchLocation }), // 검색페이지일때만 search_location 추가
      ...(trackerData?.boughtCount && { purchase_count: trackerData.boughtCount }), // 검색페이지일때만 boughtCount 추가
    }
    tracker.triggerCustomEvent(FitpetMallEventEnum.ClickListAddToCartButton, properties)
  }

  const trackViewAddCartBottomUpBottomSheetEvent = ({
    originProductId,
    originProductName,
    supplyType,
    brandId,
    brandName,
    abTestGroup,
  }: {
    originProductId: number
    originProductName: string
    supplyType: string
    brandId?: number
    brandName: string
    abTestGroup: string
  }) => {
    tracker.triggerCustomEvent(FitpetMallEventEnum.ViewAddCartBottomUpBottomSheet, {
      origin_product_id: originProductId,
      origin_product_name: originProductName,
      supply_type: supplyType,
      brand_id: brandId,
      brand_name: brandName,
      ab_test_group: abTestGroup,
    })
  }

  const trackClickAddCartBottomUpCartEvent = ({
    originProductId,
    originProductName,
    supplyType,
    brandId,
    brandName,
    location,
    addLocation,
  }: {
    originProductId: number
    originProductName: string
    supplyType: string
    brandId?: number
    brandName: string
    location?: string
    addLocation?: string
  }) => {
    tracker.triggerCustomEvent(FitpetMallEventEnum.ClickAddCartBottomUpCart, {
      origin_product_id: originProductId,
      origin_product_name: originProductName,
      supply_type: supplyType,
      brand_id: brandId,
      brand_name: brandName,
      location,
      add_location: addLocation,
    })
  }

  const trackClickAddCartBottomUpSectionMoreEvent = ({
    originProductId,
    originProductName,
    supplyType,
    brandId,
    brandName,
    listType,
    sectionName,
    addLocation,
    location,
  }: {
    originProductId: number
    originProductName: string
    supplyType: string
    brandId?: number
    brandName: string
    listType: string
    sectionName: string
    addLocation: string
    location: string
  }) => {
    tracker.triggerCustomEvent(FitpetMallEventEnum.ClickAddCartBottomUpSectionMore, {
      origin_product_id: originProductId,
      origin_product_name: originProductName,
      supply_type: supplyType,
      brand_id: brandId,
      brand_name: brandName,
      list_type: listType,
      section_name: sectionName,
      add_location: addLocation,
      location: location,
    })
  }

  return {
    trackClickAddCartBottomUpProductEvent,
    trackClickListSinglePurchaseButtonEvent,
    trackClickListBottomSheetToCartEvent,
    trackClickListAddToCartButtonEvent,
    trackViewAddCartBottomUpProductEvent,
    trackClickListAddToCartButtonEventNew,
    trackViewAddCartBottomUpBottomSheetEvent,
    trackClickAddCartBottomUpCartEvent,
    trackClickAddCartBottomUpSectionMoreEvent,
  }
}

export { useBottomSheetEventTracker }
