import { match } from 'ts-pattern'
import { ProductSortByParamParameter, SortDirectionParamParameter } from '@/apis/rest/generated/types'

export const getSortDirectionByOrder = (order: string) => {
  return match(order)
    .with('SALE_PRICE_ASC', () => SortDirectionParamParameter.ASC)
    .with('MD_RECOMMENDED', () => SortDirectionParamParameter.ASC)
    .otherwise(() => SortDirectionParamParameter.DESC)
}

export const parseSortByOptionSelected = (order: string): ProductSortByParamParameter => {
  return match(order)
    .with('SALE_PRICE_ASC', () => ProductSortByParamParameter.SALE_PRICE)
    .with('SALE_PRICE_DESC', () => ProductSortByParamParameter.SALE_PRICE)
    .otherwise(() => order as ProductSortByParamParameter)
}
