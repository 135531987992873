import { FC } from 'react'
import styled from 'styled-components'
import { StyledRawButton } from '@/components/common/buttons/Button/StyledRawButton'
import { ButtonProps } from '@/components/common/buttons/Button/button.type'
import { GlobalColorEnum } from '@/constants/global-color.enum'
import { SCREEN_MEDIA_QUERY } from '@/constants/legacy/constLayout'

const Tertiary: FC<ButtonProps> = ({ type = 'button', disabled = false, onClick, children, ...buttonStyle }) => {
  return (
    <StyledButton
      type={type}
      disabled={disabled}
      onClick={(e) => {
        e.stopPropagation()
        if (onClick) {
          onClick()
        }
      }}
      {...buttonStyle}
    >
      {children}
    </StyledButton>
  )
}

const StyledButton = styled(StyledRawButton)`
  border: 0.1rem solid ${GlobalColorEnum.Gray200};
  border-radius: 0.8rem;
  color: ${(props) => props.theme.color.gray900};
  background-color: ${(props) => props.theme.color.grayWhite};
  // mobile hover 이슈로 데스크탑에서만 hover 적용
  @media only screen and ${SCREEN_MEDIA_QUERY.md} {
    &:hover:not([disabled]) {
      background-color: ${(props) => props.theme.color.gray50};
    }
  }
  :active:not([disabled]) {
    background-color: ${(props) => props.theme.color.gray100};
  }
  &[disabled] {
    color: ${(props) => props.theme.color.gray200};
    border: 0.1rem solid ${GlobalColorEnum.Gray200};
  }
`
export default Tertiary
