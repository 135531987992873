import styled, { css } from 'styled-components'
import { ValueOf } from 'type-fest'
import { GlobalTheme } from '@/styles/globalTheme'

const { fontWeight, color } = GlobalTheme

type StyledFontColor = { fontColor?: ValueOf<typeof color> }
type StyledFontColorAndWeight = StyledFontColor & { fontWeight?: ValueOf<typeof fontWeight> }

// Heading
const headingProperties = css`
  margin-top: 0;
  margin-bottom: 0;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  letter-spacing: ${({ theme }) => theme.letterSpacing.font};
`

/**
 * SEO(Search Engine Optimization) Htag 적용을 위함
 * TODO : 현재 디자인 시스템과 html tag의 분리에 대해서 관리 방안 논의 후 리팩토링 필요
 * @example 피그마상 H5 스타일링을 적용시 seo 때문에 h2 태그를 사용하는 경우
 * @link https://www.notion.so/industrious-ethernet-850/Html-Css-de133d101b7d45ba84b91e0a744a24bc
 */
export const SeoHeading = {
  h1: css`
    ${headingProperties};
    font-size: ${({ theme }) => theme.fontSize.h1};
    line-height: ${({ theme }) => theme.lineHeight.h1};
    color: ${({ theme }) => theme.color.gray900};
  `,
  h2: css`
    ${headingProperties};
    font-size: ${({ theme }) => theme.fontSize.h2};
    line-height: ${({ theme }) => theme.lineHeight.h2};
    color: ${({ theme }) => theme.color.gray900};
  `,
  h3: css`
    ${headingProperties};
    font-size: ${({ theme }) => theme.fontSize.h3};
    line-height: ${({ theme }) => theme.lineHeight.h3};
    color: ${({ theme }) => theme.color.gray900};
  `,
  h4: css`
    ${headingProperties};
    font-size: ${({ theme }) => theme.fontSize.h4};
    line-height: ${({ theme }) => theme.lineHeight.h4};
    color: ${({ theme }) => theme.color.gray900};
  `,
  h5: css`
    ${headingProperties};
    font-size: ${({ theme }) => theme.fontSize.h5};
    line-height: ${({ theme }) => theme.lineHeight.h5};
    color: ${({ theme }) => theme.color.gray900};
  `,
  h6: css`
    ${headingProperties};
    font-size: ${({ theme }) => theme.fontSize.h6};
    line-height: ${({ theme }) => theme.lineHeight.h6};
    color: ${({ theme }) => theme.color.gray900};
  `,
}

export const H1 = styled.h1<StyledFontColor>`
  ${headingProperties};
  font-size: ${({ theme }) => theme.fontSize.h1};
  line-height: ${({ theme }) => theme.lineHeight.h1};
  color: ${({ fontColor, theme }) => fontColor ?? theme.color.gray900};
`
export const H2 = styled.h2<StyledFontColor>`
  ${headingProperties};
  font-size: ${({ theme }) => theme.fontSize.h2};
  line-height: ${({ theme }) => theme.lineHeight.h2};
  color: ${({ fontColor, theme }) => fontColor ?? theme.color.gray900};
`
export const H3 = styled.h3<StyledFontColor>`
  ${headingProperties};
  font-size: ${({ theme }) => theme.fontSize.h3};
  line-height: ${({ theme }) => theme.lineHeight.h3};
  color: ${({ fontColor, theme }) => fontColor ?? theme.color.gray900};
`
export const H4 = styled.h4<StyledFontColor>`
  ${headingProperties};
  font-size: ${({ theme }) => theme.fontSize.h4};
  line-height: ${({ theme }) => theme.lineHeight.h4};
  color: ${({ fontColor, theme }) => fontColor ?? theme.color.gray900};
`
export const H5 = styled.h5<StyledFontColor>`
  ${headingProperties};
  font-size: ${({ theme }) => theme.fontSize.h5};
  line-height: ${({ theme }) => theme.lineHeight.h5};
  color: ${({ fontColor, theme }) => fontColor ?? theme.color.gray900};
`
export const H6 = styled.h6<StyledFontColorAndWeight>`
  ${headingProperties};
  font-size: ${({ theme }) => theme.fontSize.h6};
  line-height: ${({ theme }) => theme.lineHeight.h6};
  font-weight: ${({ theme, fontWeight }) => fontWeight ?? theme.fontWeight.bold};
  color: ${({ fontColor, theme }) => fontColor ?? theme.color.gray900};
`

// Body
const BodyComponent = styled.p<StyledFontColorAndWeight>`
  margin-top: 0;
  margin-bottom: 0;
  font-weight: ${({ fontWeight, theme }) => fontWeight ?? theme.fontWeight.regular};
  letter-spacing: ${({ theme }) => theme.letterSpacing.font};
  color: ${({ fontColor, theme }) => fontColor ?? theme.color.gray900};
`

export const Body1 = styled(BodyComponent)`
  font-size: ${({ theme }) => theme.fontSize.body1};
  line-height: ${({ theme }) => theme.lineHeight.body1};
`
export const Body2 = styled(BodyComponent)`
  font-size: ${({ theme }) => theme.fontSize.body2};
  line-height: ${({ theme }) => theme.lineHeight.body3};
`
export const Body3 = styled(BodyComponent)`
  font-size: ${({ theme }) => theme.fontSize.body3};
  line-height: ${({ theme }) => theme.lineHeight.body3};
`
export const Body4 = styled(BodyComponent)`
  font-size: ${({ theme }) => theme.fontSize.body4};
  line-height: ${({ theme }) => theme.lineHeight.body4};
`
export const Body5 = styled(BodyComponent)`
  font-size: ${({ theme }) => theme.fontSize.body5};
  line-height: ${({ theme }) => theme.lineHeight.body5};
`

// Caption
const CaptionComponent = styled.span<StyledFontColorAndWeight>`
  font-weight: ${({ fontWeight, theme }) => fontWeight ?? theme.fontWeight.regular};
  letter-spacing: ${({ theme }) => theme.letterSpacing.font};
  color: ${({ fontColor, theme }) => fontColor ?? theme.color.gray900};
`

export const Caption1 = styled(CaptionComponent)`
  font-size: ${({ theme }) => theme.fontSize.caption1};
  line-height: ${({ theme }) => theme.lineHeight.caption1};
`

export const Caption2 = styled(CaptionComponent)`
  font-size: ${({ theme }) => theme.fontSize.caption2};
  line-height: ${({ theme }) => theme.lineHeight.caption2};
`
