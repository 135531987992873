import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { ProductOption } from '@/components/common/modals/bottomSheet.type'
import { ProductChoiceSelectedItem } from '@/components/domains/cart/product-choice/ProductChoiceSelectedItem'
import { ToastConst } from '@/constants/toast.const'
import { doSetGlobalToastContent } from '@/stores/reduxUI'

const MAXIMUM_PURCHASE_QUANTITY = 20

interface ProductChoiceSelectedItemListProps {
  productOptions: ProductOption[]
  onUpdateProductOptionQuantity: ({ productOptionId, quantity }: { productOptionId: number; quantity: number }) => void
  onRemoveProductOption?: ({ productOptionId }: { productOptionId: number }) => void
  isSingleOption?: boolean
  hasApplicableProductCoupon?: boolean
}

export const ProductChoiceSelectedItemList = ({
  productOptions,
  onRemoveProductOption,
  onUpdateProductOptionQuantity,
  isSingleOption = false,
  hasApplicableProductCoupon,
}: ProductChoiceSelectedItemListProps) => {
  const dispatch = useDispatch()
  const selectedProductOptions = productOptions.filter((product) => product.isSelected)
  const isSelectedProductOption = selectedProductOptions.length > 0

  const handleClickRemoveButton = ({ productOptionId }: { productOptionId: number }) => {
    onRemoveProductOption?.({
      productOptionId,
    })
  }

  const handleUpdateProductOptionQuantity = ({
    productOptionId,
    quantity,
    maxQuantity,
  }: {
    productOptionId: number
    quantity: number
    maxQuantity: number
  }) => {
    if (quantity > MAXIMUM_PURCHASE_QUANTITY) {
      dispatch(
        doSetGlobalToastContent({
          content: '최대 구매수량을 초과하였습니다.',
          variant: ToastConst.ToastVariant.withCTA,
        })
      )
      return
    }
    if (quantity > maxQuantity) {
      dispatch(
        doSetGlobalToastContent({
          content: '재고 수량을 초과하였습니다.',
          variant: ToastConst.ToastVariant.withCTA,
        })
      )
      return
    }
    onUpdateProductOptionQuantity({
      productOptionId,
      quantity,
    })
  }

  return (
    <ProductChoiceSelectedItemListWrapper
      className="product-choice-selected-item-list"
      isSelectedProductOption={isSelectedProductOption}
      isSingleOption={isSingleOption}
    >
      <ul className="product-choice-selected-item-list__list">
        {selectedProductOptions.map((product) => {
          const maxQuantity = Math.min(
            product.availableQuantity - (product.quantityInCart || 0),
            MAXIMUM_PURCHASE_QUANTITY
          )
          return (
            <li key={product.productOptionId} className="product-choice-selected-item-list__item">
              <ProductChoiceSelectedItem
                productOptionId={product.productOptionId}
                name={product.name}
                isSingleOption={isSingleOption}
                quantity={product.quantity}
                isDisabledSubtractButton={product.quantity <= 1}
                isDisabledAddButton={product.quantity >= maxQuantity}
                price={product.price}
                salePrice={product.salePrice}
                purchaseSalePrice={product.purchaseSalePrice}
                purchaseSalePriceAfterCoupon={product.purchaseSalePriceAfterCoupon}
                hasApplicableProductCoupon={hasApplicableProductCoupon}
                onClickRemoveButton={({ productOptionId }) =>
                  handleClickRemoveButton({
                    productOptionId,
                  })
                }
                onUpdateProductOptionQuantity={({ productOptionId, quantity }) =>
                  handleUpdateProductOptionQuantity({
                    productOptionId,
                    quantity,
                    maxQuantity,
                  })
                }
              />
            </li>
          )
        })}
      </ul>
    </ProductChoiceSelectedItemListWrapper>
  )
}

const ProductChoiceSelectedItemListWrapper = styled.div<{ isSelectedProductOption: boolean; isSingleOption: boolean }>`
  ${({ isSelectedProductOption, isSingleOption }) => isSelectedProductOption && !isSingleOption && 'margin-top: 1rem;'}
  .product-choice-selected-item-list {
    &__list {
      margin: 0;
    }
    &__item {
      padding: 1.6rem;
      background-color: ${({
        theme: {
          color: { gray50 },
        },
      }) => gray50};
      border-radius: 0.8rem;
      &:not(:first-child) {
        margin-top: 1rem;
      }
    }
  }
`
