import TagManager, { DataLayerArgs, TagManagerArgs } from 'react-gtm-module'
import { PlatformDetailEnum } from '@/constants/platform-detail.enum'
import { FitpetMallEventEnum } from '@/utils/event-tracker/events'
import { BaseEventHandler } from '@/utils/event-tracker/handlers/baseEventHandler'
import { isInApp } from '@/utils/utilCommon'

type GtmEventMapType = Record<string, string>

const GTM_CONTAINER_ID = 'GTM-PM4GKSN'

export class GTMHandler extends BaseEventHandler {
  loggerName = 'LOG_GTM'
  tagManagerArgs: TagManagerArgs = {
    gtmId: GTM_CONTAINER_ID,
  }
  gtmEventMap: GtmEventMapType = {
    [FitpetMallEventEnum.ViewHome]: 'view_home',
    [FitpetMallEventEnum.ViewProductdetail]: 'view_productdetail',
    [FitpetMallEventEnum.ViewCart]: 'view_cart',
    [FitpetMallEventEnum.ClickCartPaymentButton]: 'init_checkout',
    [FitpetMallEventEnum.ViewSectionListItems]: 'view_productlist',
    [FitpetMallEventEnum.CompleteSignup]: 'join_complete',
    [FitpetMallEventEnum.CompletePurchase]: 'complete_purchase',
    [FitpetMallEventEnum.SubmitSearchKeyword]: 'do_search',
  }
  /**
   * @deprecated: 구 버전의 triggerCustomEvent에 사용되는 이벤트 트래커 지정 방식입니다.
   */
  gtmEventList: Array<FitpetMallEventEnum> = [
    FitpetMallEventEnum.ViewHome,
    FitpetMallEventEnum.ViewProductdetail,
    FitpetMallEventEnum.ViewCart,
    FitpetMallEventEnum.ClickCartPaymentButton,
    FitpetMallEventEnum.ViewSectionListItems,
    FitpetMallEventEnum.CompleteSignup,
    FitpetMallEventEnum.CompletePurchase,
    FitpetMallEventEnum.SubmitSearchKeyword,
  ]

  initialize = () => {
    if (process.browser) {
      TagManager.initialize(this.tagManagerArgs)
      this.logger.info(`TagManager initialized: ${JSON.stringify(this.tagManagerArgs)}`)
    }
  }

  setUserId = (userID: string) => {
    this.logger.info(`set userId datalayer variable: ${userID}`)
    const dataLayerArgs: DataLayerArgs = {
      dataLayer: { user_id: userID },
    }
    try {
      if (process.browser) {
        TagManager.dataLayer(dataLayerArgs)
      }
    } catch {}
  }

  // eslint-disable-next-line class-methods-use-this
  setUserProperties = (/* properties: any */) => {}
  sendPageviewEvent = (url: string) => {
    this.logger.info(`trigger 'virtual_pageview' event for url: ${url}`)
    const dataLayerArgs: DataLayerArgs = {
      dataLayer: { event: 'virtual_pageview' },
    }
    try {
      if (process.browser) {
        TagManager.dataLayer(dataLayerArgs)
      }
    } catch {}
  }

  triggerEvent(event: FitpetMallEventEnum, eventParams: Record<string, any>): void {
    const gtmEvent = this.gtmEventMap[event]
    const dataLayerArgs: DataLayerArgs = {
      dataLayer: {
        event: gtmEvent,
        eventParams,
        inapp: isInApp(),
      },
    }
    try {
      if (process.browser) {
        TagManager.dataLayer(dataLayerArgs)
      }
      this.logger.info(`'${gtmEvent}' triggered: ${JSON.stringify(dataLayerArgs, null, 2)}`)
    } catch {}
  }

  sendEvent = (event: FitpetMallEventEnum, platform: PlatformDetailEnum, properties: Record<string, any>) => {
    this.logger.info(`event sent for : ${event}`)
    this.triggerEvent(event, properties)
  }

  /**
   * @deprecated: 구 버전의 triggerCustomEvent에 사용되는 이벤트 트래커 지정 방식입니다.
   */
  sendCustomEvent = (event: FitpetMallEventEnum, properties: Record<string, any>, platform: PlatformDetailEnum) => {
    if (!this.gtmEventList.includes(event)) {
      return
    }
    this.sendEvent(event, platform, properties)
  }
}
