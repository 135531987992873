export const ERROR_CODE = {
  ERROR_USER_INFO_DOES_NOT_EXISTS: 'ERROR_USER_INFO_DOES_NOT_EXISTS',
  WRONG_CERTIFICATION_CODE: 'WRONG_CERTIFICATION_CODE',
  ERROR_USER_LEFT: 'ERROR_USER_LEFT',
  ERROR_USER_LOCKED: 'ERROR_USER_LOCKED',
  ERROR_USER_BLOCKED: 'ERROR_USER_BLOCKED',
  ERROR_ORDER_EXISTS: 'ERROR_ORDER_EXISTS',
  ERROR_ALREADY_REGISTERED_MOBILE_NUMBER: 'ERROR_ALREADY_REGISTERED_MOBILE_NUMBER',
  ERROR_INVALID_EMAIL_BY_GODO: 'ERROR_INVALID_EMAIL_BY_GODO',
  ERROR_INVALID_CUSTOMER_ADDRESS: 'ERROR_INVALID_CUSTOMER_ADDRESS',
}
