import React, { FC, PropsWithChildren, ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import styled, { css, CSSObject, useTheme } from 'styled-components'
import { ValueOf } from 'type-fest'
import FlatIcon from '@/components/common/icons/FlatIcon'
import { Typo, TypoVariant } from '@/components/common/typography'
import { IconNameEnum } from '@/constants/icon-name.enum'
import { TooltipArrowPositionEnum } from '@/constants/tooltip-arrow-position.enum'
import { TooltipTypeClass, TooltipTypeEnum } from '@/constants/tooltip-type-enum'
import { GlobalTheme } from '@/styles/globalTheme'
import { preventDragSelect } from '@/utils/utilCSS'

type ToolTipSubComponent = {
  ArrowIcon: React.FC<{ tooltipType: TooltipTypeEnum }>
}

type ToolTipProps = {
  tooltipData: ReactNode
  arrowPosition: TooltipArrowPositionEnum
  /*tooltipType: tooltip의 위치에 대한 정의가 없으므로 툴팁 위치를 임의로 맞추기 위해서 사용*/
  tooltipType?: TooltipTypeEnum
  tooltipStyle?: CSSObject
  isCloseIcon?: boolean
  /*once: 닫기 아이콘이 있더라도 초기 화면에서 툴팁을 띄우도록 함*/
  once?: boolean
  /*callback: 툴팁 닫은 후 실행되는 함수*/
  callback?: () => void
  /* 상시 노출 */
  alwaysVisible?: boolean
  tooltipDataStyle?: {
    color?: ValueOf<typeof GlobalTheme.color>
    variant?: TypoVariant
  }
}

/**
 * 모달이나 툴팁이나 스낵바나, visible 요소는 상위 컴포넌트에서 관리해야 합니다. visible 과 animation은 별개의 플래그로 animation이 끝날때 컴포넌트를 사라지게 하려면
 * DOM.addEventListenser("animationend", close) 형태로 하는 것이 좋겠습니다.
 *
 * ⚠️ CAUTION: 멤버십 이슈로 디자인 컴포넌트를 만들지 않고 수정하여, 디자인 시스템과 일치하지 않는 부분이 있습니다.
 */
const ToolTip: FC<PropsWithChildren<ToolTipProps>> & ToolTipSubComponent = ({
  tooltipData,
  children,
  arrowPosition,
  tooltipType = TooltipTypeEnum.White,
  tooltipStyle,
  isCloseIcon,
  once,
  callback,
  alwaysVisible,
  tooltipDataStyle,
}) => {
  const tooltipRef = useRef<HTMLDivElement>(null)
  const [isVisible, setIsVisible] = useState(once ?? false)
  const { iconSize } = useTheme()
  const { color } = useTheme()

  /**
   * 외부 영역 인터렉션(클릭 및 스크롤) 발생 시 툴팁 닫힘 처리
   */
  useEffect(() => {
    // 닫기 아이콘이 없는 경우, 5초뒤 fadeOut 애니메이션을 실행해야함
    if (!isCloseIcon && isVisible && !alwaysVisible) {
      setTimeout(() => {
        if (callback) {
          callback()
        }
        setIsVisible(false)
      }, 5000)
    }
    if (!once && !alwaysVisible) {
      window.addEventListener('mousedown', onClickInsideDetector)
      window.addEventListener('scroll', onScroll)
    }
  }, [isCloseIcon, isVisible])

  // page unMount 될때 작동하는 클리너 함수를 따로 만들었습니다.
  useEffect(() => {
    return () => {
      window.removeEventListener('mousedown', onClickInsideDetector)
      window.removeEventListener('scroll', onScroll)
      setIsVisible(false)
    }
  }, [])

  const onClickInsideDetector = useCallback((e: MouseEvent) => {
    if (tooltipRef.current && e.target instanceof Node) {
      setIsVisible(tooltipRef.current.contains(e.target))
    }
  }, [])

  const onScroll = useCallback(() => {
    setIsVisible(false)
  }, [])

  useEffect(() => {
    if (alwaysVisible) {
      setIsVisible(alwaysVisible)
    }
  }, [alwaysVisible])

  return (
    <StyledContainer>
      <StyledTooltip
        isVisible={isVisible}
        ref={tooltipRef}
        arrowPosition={arrowPosition}
        tooltipType={tooltipType}
        tooltipStyle={tooltipStyle}
        className={`${isCloseIcon ? 'withCloseIcon' : 'noCloseIcon'} ${isVisible ? '' : 'fadeOut'}`}
      >
        <Typo
          variant={tooltipDataStyle?.variant || TypoVariant.Body3Medium}
          color={tooltipDataStyle?.color || color.gray900}
          style={{
            whiteSpace: 'pre-line',
          }}
        >
          {tooltipData}
        </Typo>
        {isCloseIcon && (
          <StyledCloseButton
            onClick={(e) => {
              e.stopPropagation()
              if (callback) {
                callback()
              }
              setIsVisible(false)
            }}
          >
            <FlatIcon
              type={IconNameEnum.IcClose}
              color={TooltipTypeClass[tooltipType].closeButtonColor}
              size={iconSize.size16}
            />
          </StyledCloseButton>
        )}
        <ToolTip.ArrowIcon tooltipType={tooltipType} />
      </StyledTooltip>
      <div
        onClick={() => {
          if (once) {
            return
          }
          setIsVisible(true)
        }}
      >
        {children}
      </div>
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
  position: relative;
`

/**
 * 현상 : 사파리에서 unmount 시 잔상남음
 * 원인 : filter 속성이 GPU 리소스를 사용하는데 unmount 시 브라우저가 제대로 인식하지 못함
 * 해결 : will-change 속성으로 GPU 사용을 강제하여 애니메이션 최적화
 * Todo : animation이 끝나면 will-change : none 으로 변경시켜야 합니다.
 * Todo : tooltipFadeOut에서 opacity보다 scale transition이 자연스러움, 디자인시스템에서 재논의 필요
 */
const StyledTooltip = styled.div<{
  isVisible: boolean
  arrowPosition: TooltipArrowPositionEnum
  tooltipStyle?: CSSObject
  tooltipType: TooltipTypeEnum
}>`
  ${preventDragSelect};
  position: absolute;
  z-index: 3;
  transform: ${({ isVisible }) => (isVisible ? 'scale(1)' : 'scale(0)')};
  transform-origin: ${(props) => props.arrowPosition.replace('_', ' ')};
  transition: all 240ms 120ms cubic-bezier(0.4, 0, 0.2, 1);
  display: flex;
  column-gap: 0.8rem;
  width: max-content;
  padding: 0.8rem 1.2rem;
  border: 0.1rem solid ${({ tooltipType }) => TooltipTypeClass[tooltipType].borderColor};
  border-radius: 0.8rem;
  color: ${(props) => props.theme.color.gray900};
  background: ${({ tooltipType }) => TooltipTypeClass[tooltipType].backgroundColor};
  filter: ${({ isVisible }) => (isVisible ? 'drop-shadow(2px 2px 5px rgba(129, 129, 129, 0.2))' : 'none')};
  will-change: transform;
  line-height: 1em;
  ${({ tooltipStyle }) =>
    css`
      ${tooltipStyle}
    `};

  & > .arrow {
    position: absolute;
    ${(props) => getArrowPosition(props.arrowPosition)};
  }

  &.withCloseIcon {
    opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
    visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
  }

  &.noCloseIcon {
    &.fadeOut {
      animation: 0.3s ease-out tooltipFadeOut forwards;
    }
  }

  @keyframes tooltipFadeOut {
    0% {
      visibility: visible;
      scale(1);
    }
    100% {
      visibility: hidden;
      scale(0);
    }
  }
`

const StyledCloseButton = styled.div`
  cursor: pointer;
  padding-top: 0.2rem;
`

// ArrowIcon 위치
const getArrowPosition = (arrowPosition: TooltipArrowPositionEnum) => {
  const arrowHeight = '9px' // TODO: ArrowIcon의 높이에 대한 정의가 되면 rem으로 변경

  if (arrowPosition === TooltipArrowPositionEnum.TopRight) {
    return css`
      top: -${arrowHeight};
      right: 0;
    `
  }

  if (arrowPosition === TooltipArrowPositionEnum.TopLeft) {
    return css`
      top: -${arrowHeight};
      left: 0;
    `
  }

  if (arrowPosition === TooltipArrowPositionEnum.TopMiddle) {
    return css`
      top: -${arrowHeight};
      left: 50%;
      transform: translateX(-50%);
    `
  }

  if (arrowPosition === TooltipArrowPositionEnum.BottomRight) {
    return css`
      transform: rotate(180deg) translateY(-100%);
      bottom: 0;
      right: 0;
    `
  }

  if (arrowPosition === TooltipArrowPositionEnum.BottomMiddle) {
    return css`
      transform: rotate(180deg) translateY(-100%) translateX(-50%);
      bottom: 0;
      right: 50%;
    `
  }
}

const ArrowIcon: FC<{ tooltipType: TooltipTypeEnum }> = ({ tooltipType }) => {
  return (
    <svg
      className="arrow"
      width="38"
      height="9"
      viewBox="0 0 38 9"
      fill={TooltipTypeClass[tooltipType].backgroundColor}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M19 1.5L12.46 9L25.53 9L19 1.5Z" fill={TooltipTypeClass[tooltipType].backgroundColor} />
      <path
        d="M12.1155 8.63837L18.6232 1.18165C18.8224 0.953339 19.1774 0.953338 19.3766 1.18165L25.8657 8.61708"
        stroke={TooltipTypeClass[tooltipType].borderColor}
      />
    </svg>
  )
}

ToolTip.ArrowIcon = ArrowIcon
export default ToolTip
