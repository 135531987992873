import { gql } from '@apollo/client'
import DEFAULT_FRAGMENTS from '@/containers/gqls/base/defaultFragments'

export const REVIEW_MUTATION_GQLS = {
  CRAETE_REVIEW: gql`
    mutation createReview($input: CreateReviewInput!) {
      createReview(input: $input) {
        review {
          ...review
          product {
            id
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.review}
  `,
  UPDATE_REVIEW: gql`
    mutation updateReview($id: ID!, $input: UpdateReviewInput!) {
      updateReview(id: $id, input: $input) {
        review {
          ...review
          product {
            id
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.review}
  `,
  DELETE_REVIEW: gql`
    mutation deleteReview($id: ID!) {
      deleteReview(id: $id) {
        review {
          id
        }
      }
    }
  `,
  LIKE_REVIEW: gql`
    mutation toggleReviewLiked($id: ID!) {
      toggleReviewLiked(id: $id) {
        review {
          id
        }
      }
    }
  `,
}

export default REVIEW_MUTATION_GQLS
