import { IModelShippingPresetDefault } from '@/containers/models/base/defaultModelInterfaces'
import { ModelShippingPresetDefault } from '@/containers/models/base/defaultModels'

export interface IModelShippingPreset extends IModelShippingPresetDefault {
  shippingDeadline: number
}

export default class ModelShippingPreset extends ModelShippingPresetDefault {
  constructor(data: IModelShippingPreset) {
    super(data)
  }
}
