/* eslint-disable */
import { ApiLoader } from '@/components/api'
import { GlobalNoticeModal } from '@/components/common/modals'
import { BottomSheet } from '@/components/common/modals/BottomSheet'
import { GlobalSnackBar } from '@/components/common/snackBar'
import { GlobalToast } from '@/components/common/toast'
import { CriteoViewHome } from '@/components/domains/etc/CriteoViewHome'
import { NaverCommonPixel } from '@/components/domains/etc/NaverCommonPixel'
import LayoutBackground from '@/components/domains/layouts/LayoutBackground'
import { LayoutBody, LayoutBottomTabNavigator, LayoutMiddleFixed } from '@/components/domains/layouts/index'
import { ModalLogin } from '@/components/domains/modals/ModalLogin'
import { GlobalConst } from '@/constants/global.const'
import { InitSocialMetaTag } from '@/constants/initial-social-meta-tag.enum'
import { S3_FOLDERS_LEGACY, S3_HOST_URL } from '@/constants/legacy/constEnv'
import { LAYOUT_UNITS, SCREEN_MEDIA_QUERY } from '@/constants/legacy/constLayout'
import ROUTES from '@/constants/legacy/constRoutes'
import { useCustomRouter } from '@/containers/hooks'
import { isInApp, isInRnAndroid } from '@/utils/utilCommon'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { FC, PropsWithChildren, useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import styled from 'styled-components'

const IncludeAppsflyer = () => {
  const { pathname } = useCustomRouter()

  useEffect(() => {
    if (!isInApp()) {
      // appsflyer pba (GA 같은 것)와 스마트 배너를 위한 스크립트, pba & banner key
      if (!window.document.getElementById('appsflyerScripts')) {
        const scriptTag = window.document.createElement('script')
        scriptTag.setAttribute('src', `${process.env.HOST}/js/appsflyerScripts.js`)
        document.head.append(scriptTag)
      }

      // 스마트 배너를 위한 스크립트,
      if (isMobile && (pathname === ROUTES.EVENT_DETAIL || pathname === ROUTES.PRODUCTS.DETAIL)) {
        const scriptTag = window.document.createElement('script')
        scriptTag.setAttribute('src', `${process.env.HOST}/js/appsflyerShowBanner.js`)
        document.head.append(scriptTag)
      }
    }
  }, [])
  return <></>
}

/**
 * ReactNative 대응 코드인 아래 코드는은 삭제하지 않기로 하였습니다.
 * @link https://github.com/FitpetKorea/fitpetmall-frontend/pull/3033#discussion_r1065456822
 */
const ForceUpdateForRnAndroid = () => {
  const _router = useRouter()
  useEffect(() => {
    if (isInRnAndroid()) {
      if (_router.pathname !== GlobalConst.GoMarketRnAndroidUrl) {
        _router.push(GlobalConst.GoMarketRnAndroidUrl)
      }
    }
  }, [_router.pathname])
  return <></>
}

const LayoutMain: FC<PropsWithChildren<{}>> = ({ children }) => {
  const [isShowBottomTabNavigator, setIsShowBottomTabNavigator] = useState<boolean>(false)
  const ignoreSeo = process.env.FITPETMALL_ENV !== 'production'
  const { pathname } = useCustomRouter()
  const isUseIamPort = pathname === ROUTES.ORDER.BASE || pathname === ROUTES.MEMBERSHIP.INDEX

  useEffect(() => {
    if (!isInApp()) {
      setIsShowBottomTabNavigator(true)
    }
  }, [])

  return (
    <div>
      <Head>
        <title>{InitSocialMetaTag.Title}</title>
        <link
          rel="icon"
          href={`${S3_HOST_URL}/${S3_FOLDERS_LEGACY.ASSET_IMAGES}/favicon.ico?temp=1`}
          type="image/x-icon"
        />
        <meta charSet="utf-8" />
        <meta name="google" content="notranslate" />
        {ignoreSeo && <meta name="robots" content="noindex,nofollow" />}
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0, user-scalable=no, viewport-fit=cover"
        />
        <meta name="author" content="핏펫" />
        <meta name="keywords" content="핏펫몰" />
        <meta name="referrer" content="no-referrer-when-downgrade" />
        <meta property="og:type" content="website" key="type" />
        <meta property="og:title" content={InitSocialMetaTag.Title} key="title" />
        <meta property="og:image" content={`${process.env.HOST}${InitSocialMetaTag.Image}`} key="image" />
        <meta property="og:description" content={InitSocialMetaTag.Description} key="description" />
        <meta property="og:site_name" content="핏펫몰" key="site_name" />
        <meta property="og:locale" content="ko_KR" key="locale" />
        <meta name="naver-site-verification" content="9f0a1469043efb40f5ba92dae1fff43de4b9db92" />
        <meta name="google-site-verification" content="SiUOEoYj7uBZGuBaKPx38gdiECCgW0hNm0205PKdy6g" />
        <script type="text/javascript" src={`${process.env.HOST}/js/jsToNative.js?version=1.11`} />
        <script
          id="naver-pixel-script"
          key="naver-pixel-script"
          type="text/javascript"
          src="https://wcs.naver.net/wcslog.js"
          defer
        />
        <script type="text/javascript" src="https://dynamic.criteo.com/js/ld/ld.js?a=59618" defer />
      </Head>
      <StyledBody>
        <LayoutBackground />
        <StyledMain id="fitpetMallBody">
          <div id="modal-root" />
          <IncludeAppsflyer />
          <ForceUpdateForRnAndroid />
          <LayoutBody>{children}</LayoutBody>
          {isShowBottomTabNavigator && <LayoutBottomTabNavigator />}
          <GlobalNoticeModal />
          <GlobalToast />
          <GlobalSnackBar />
          <ApiLoader />
          <LayoutMiddleFixed />
          <ModalLogin />
        </StyledMain>
      </StyledBody>
      <NaverCommonPixel />
      <CriteoViewHome />
      <BottomSheet />
    </div>
  )
}

const StyledBody = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  // 디자인시스템 컬러를 쓸 경우 톤이 안맞아서 BX팀에서 전달받은 컬러값을 사용 - Ulla와 협의 from Jerry
  background-color: #cbebff;
`

const StyledMain = styled.div`
  width: ${LAYOUT_UNITS.BODY_MAX_WIDTH};
  position: relative;
  background-color: ${(props) => props.theme.color.grayWhite};
  @media ${SCREEN_MEDIA_QUERY.xs} {
    width: 100%;
  }
`

export default LayoutMain
