import React, { useState, useEffect, PropsWithChildren } from 'react'
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'
import { isTouchDevice } from '@/utils/utilCommon'

const PinchZoomWrapper: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const [disableVerticalScroll, setDisableVerticalScroll] = useState<boolean>(true)
  const [isDesktopDevice, setIsDesktopDevice] = useState<boolean>(false)

  const _isTouchDevice = () => {
    return isTouchDevice()
  }

  useEffect(() => {
    setIsDesktopDevice(!_isTouchDevice())
  }, [])

  if (isDesktopDevice) {
    return <>{children}</>
  }

  return (
    <TransformWrapper
      centerOnInit
      panning={{ disabled: disableVerticalScroll, velocityDisabled: true }}
      onZoom={(ref) => setDisableVerticalScroll(ref.state.scale <= 1.5)}
      doubleClick={{
        disabled: true,
      }}
    >
      <TransformComponent>{children}</TransformComponent>
    </TransformWrapper>
  )
}

export default PinchZoomWrapper
