import { FC } from 'react'
import styled from 'styled-components'
import FlatIcon from '@/components/common/icons/FlatIcon'
import { GlobalColorEnum } from '@/constants/global-color.enum'
import { IconNameEnum } from '@/constants/icon-name.enum'
import { IconSizeEnum } from '@/constants/icon-size.enum'
import { SCREEN_MEDIA_QUERY, Z_INDEXES } from '@/constants/legacy/constLayout'

type Size = 'medium' | 'large'
type ButtonSlideScrollLeftProps = {
  visible: boolean
  onClick: () => void
  size?: Size
}

const ButtonSlideScrollLeft: FC<ButtonSlideScrollLeftProps> = ({ size = 'large', visible, onClick }) => {
  if (!visible) {
    return null
  }

  return (
    <IconContainer onClick={onClick} size={size}>
      <FlatIcon type={IconNameEnum.IcArrowLeft} color={GlobalColorEnum.Gray300} size={IconSizeEnum.Size24} />
    </IconContainer>
  )
}

const IconContainer = styled.div<{ size: Size }>`
  z-index: ${Z_INDEXES.SCROLL_TOP_BUTTON};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;
  overflow: hidden;
  width: ${(props) => (props.size === 'medium' ? '2.8rem' : '4rem')};
  height: ${(props) => (props.size === 'medium' ? '5.6em' : '7.2rem')};
  outline: none;
  box-sizing: border-box;
  border: 0.1rem solid ${GlobalColorEnum.Gray100};
  border-radius: 0 0.8rem 0.8rem 0;
  background-color: rgba(255, 255, 255, 0.85);

  @media ${SCREEN_MEDIA_QUERY.xs} {
    display: none;
  }
`
export default ButtonSlideScrollLeft
