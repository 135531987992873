import styled, { useTheme } from 'styled-components'
import FlatIcon from '@/components/common/icons/FlatIcon'
import Modal from '@/components/common/modals/Modal'
import { Typo, TypoVariant } from '@/components/common/typography'
import { IconNameEnum } from '@/constants/icon-name.enum'
import { UseModalProps } from '@/containers/hooks/useModal'

interface ModalRestockProductOptionAddedProps<T> {
  useModalProps: UseModalProps<T>
}

const ModalRestockProductOptionAdded = ({ useModalProps }: ModalRestockProductOptionAddedProps<any>) => {
  const { visible } = useModalProps
  const { color } = useTheme()
  if (!visible) {
    return null
  }
  return (
    <Modal open={visible}>
      <Modal.Content>
        <Modal.Title>
          <Typo variant={TypoVariant.Heading6Bold} color={color.gray900}>
            재입고 알림 신청이 완료되었어요
          </Typo>
        </Modal.Title>
        <Modal.SubTitle>
          <Typo variant={TypoVariant.Body3Regular} color={color.gray700}>
            상품 재입고 시 카카오톡으로 알려드릴게요
          </Typo>
        </Modal.SubTitle>
        <StyledModalNotice>
          <FlatIcon type={IconNameEnum.IcInfoFilled} color={color.gray400} size="1.6rem" />
          <Typo variant={TypoVariant.Body4Regular} color={color.gray500}>
            핏펫몰 채널을 차단한 경우 차단을 해제해 주세요
          </Typo>
        </StyledModalNotice>
      </Modal.Content>
      <Modal.Footer>
        <ModalButton className="ok" onClick={() => useModalProps.hideModal()}>
          <Typo variant={TypoVariant.Body1Bold} color={color.grayWhite}>
            확인
          </Typo>
        </ModalButton>
      </Modal.Footer>
    </Modal>
  )
}

const StyledModalNotice = styled.div`
  margin-top: 1.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.4rem;
`

const ModalButton = styled.button`
  flex: 1;
  border-radius: 0.8rem;
  border: 0.1rem solid;
  padding: 1.3rem 1.6rem;
  cursor: pointer;
  width: 100%;

  &.ok {
    border-color: ${({ theme }) => theme.color.blue500};
    background-color: ${({ theme }) => theme.color.blue500};
  }
`

export default ModalRestockProductOptionAdded
