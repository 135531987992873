import { useDispatch, useSelector } from 'react-redux'
import styled, { useTheme } from 'styled-components'
import Modal from '@/components/common/modals/Modal'
import { Typo, TypoVariant } from '@/components/common/typography'
import { useGoToLoginIfNotAuthenticated } from '@/containers/common/go-to-login-if-not-authenticated.hook'
import { doSetToggleModalLogin } from '@/stores/reduxUI'
import { RootState } from '@/stores/store'

export const ModalLogin = () => {
  const isModalOpen = useSelector((state: RootState) => state.reduxUIReducers.isShowModalLogin)
  const dispatch = useDispatch()

  const { goToLogin } = useGoToLoginIfNotAuthenticated()
  const { color } = useTheme()

  const handleOk = () => {
    goToLogin()
    dispatch(doSetToggleModalLogin(false))
  }

  const handleCancel = () => {
    dispatch(doSetToggleModalLogin(false))
  }

  return (
    <Modal open={isModalOpen}>
      <Modal.Content>
        <Modal.Title>
          <Typo variant={TypoVariant.Heading6Bold} color={color.gray900}>
            로그인이 필요해요
            <br /> 로그인 페이지로 이동하시겠어요?
          </Typo>
        </Modal.Title>
      </Modal.Content>
      <Modal.Footer>
        <ButtonContainer>
          <ModalButton onClick={handleOk} className="ok">
            <Typo variant={TypoVariant.Body1Bold} color={color.grayWhite}>
              이동
            </Typo>
          </ModalButton>
          <TouchArea onClick={handleCancel}>
            <Typo variant={TypoVariant.Body1Regular} color={color.gray500}>
              취소
            </Typo>
          </TouchArea>
        </ButtonContainer>
      </Modal.Footer>
    </Modal>
  )
}

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
`

const ModalButton = styled.button`
  flex: 1;
  border-radius: 0.8rem;
  border: 0.1rem solid;
  padding: 1.3rem 1.6rem;
  cursor: pointer;
  width: 100%;

  &.ok {
    border-color: ${({ theme }) => theme.color.blue500};
    background-color: ${({ theme }) => theme.color.blue500};
  }

  &.cancel {
    border-color: ${({ theme }) => theme.color.gray200};
    background-color: ${({ theme }) => theme.color.grayWhite};
  }
`

const TouchArea = styled.div`
  padding: 1.2rem 2rem;
`
