import { FC } from 'react'
import styled from 'styled-components'
import { GlobalColorEnum } from '@/constants/global-color.enum'
import { GlobalSizeEnum } from '@/constants/global-size.enum'

export type CheckboxIconProps = {
  checked: boolean
  size: GlobalSizeEnum
  disabled: boolean
}

const CheckboxIcon: FC<CheckboxIconProps> = ({ checked, size = GlobalSizeEnum.Md, disabled }) => {
  const backgroundColor = disabled
    ? GlobalColorEnum.Gray50
    : checked
    ? GlobalColorEnum.Blue500
    : GlobalColorEnum.GrayWhite
  const strokeColor = disabled ? GlobalColorEnum.Gray100 : checked ? GlobalColorEnum.Blue500 : GlobalColorEnum.Gray300
  const iconColor = disabled ? GlobalColorEnum.Gray100 : checked ? GlobalColorEnum.GrayWhite : GlobalColorEnum.Gray100

  if (size === GlobalSizeEnum.Sm) {
    return (
      <StyledIcon width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" y="0.5" width="13" height="13" rx="1.5" fill={backgroundColor} stroke={strokeColor} />
        <path
          d="M4 6.6L6.57143 9L10 5"
          stroke={iconColor}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </StyledIcon>
    )
  }
  if (size === GlobalSizeEnum.Lg) {
    return (
      <StyledIcon width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" y="0.5" width="19" height="19" rx="3.5" fill={backgroundColor} stroke={strokeColor} />
        <path
          d="M6 9.5L9.1746 13L14 7"
          stroke={iconColor}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </StyledIcon>
    )
  }
  return (
    <StyledIcon width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="15" height="15" rx="3.5" fill={backgroundColor} stroke={strokeColor} />
      <path
        d="M5 7.6L7.57143 10L11 6"
        stroke={iconColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </StyledIcon>
  )
}

const StyledIcon = styled.svg`
  rect {
    -webkit-transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), fill 0.1s;
    -moz-transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), fill 0.1s;
    transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), fill 0.1s;
  }
`

export default CheckboxIcon
