import { useState, useEffect, useCallback, MutableRefObject } from 'react'

type UseDragReturnType = {
  isDragging: boolean
}

const useDrag = (elementRef: MutableRefObject<HTMLDivElement | null>): UseDragReturnType => {
  const [isMouseDown, setIsMouseDown] = useState<boolean>(false)
  const [isDragging, setIsDragging] = useState<boolean>(false)

  const onMouseDown = useCallback(() => {
    setIsMouseDown(true)
  }, [elementRef])

  const onMouseMove = useCallback(() => {
    if (!isMouseDown) {
      return
    }
    setIsDragging(true)
  }, [elementRef, isMouseDown])

  const onMouseUp = useCallback(() => {
    setIsMouseDown(false)
    setIsDragging(false)
  }, [])

  useEffect(() => {
    const ref = elementRef.current
    if (ref) {
      ref.addEventListener('mousedown', onMouseDown)
      ref.addEventListener('mousemove', onMouseMove)
      ref.addEventListener('mouseup', onMouseUp)
      ref.addEventListener('mouseleave', onMouseUp)
    }

    return () => {
      if (ref) {
        ref.removeEventListener('mousedown', onMouseDown)
        ref.removeEventListener('mousemove', onMouseMove)
        ref.removeEventListener('mouseup', onMouseUp)
        ref.removeEventListener('mouseleave', onMouseUp)
      }
    }
  }, [onMouseDown, onMouseMove])

  return { isDragging }
}

export default useDrag
