import { FC, PropsWithChildren } from 'react'
import styled from 'styled-components'
import { TextLegacy } from '@/components/common'
import { TextBaseProps } from '@/components/common/texts/TextBase'
import TextH2 from '@/components/common/texts/TextH2'
import { GlobalColorEnum } from '@/constants/global-color.enum'

type TextMultiLineEllipsisProps = {
  lineNumber?: number
  lineHeight?: number
  useH2Tag?: boolean
} & Omit<TextBaseProps, 'ellipsis'>

const TextEllipsis: FC<PropsWithChildren<TextMultiLineEllipsisProps>> = ({
  children,
  lineNumber = 2,
  align,
  lineHeight,
  useH2Tag = false,
  ...props
}) => {
  const TextComponent = useH2Tag ? TextH2 : TextLegacy

  return (
    <Container lineNumber={lineNumber} align={align} lineHeight={lineHeight}>
      <TextComponent lineNumber={lineNumber} align={align} {...props}>
        {children}
      </TextComponent>
    </Container>
  )
}

const Container = styled.div<{ lineNumber: number; align?: 'center' | 'left' | 'right'; lineHeight?: number }>`
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: ${({ lineNumber }) => lineNumber};
  text-align: ${({ align }) => align || 'left'};
  display: -webkit-box;
  color: ${(props) => props.theme.color.gray400};
  -webkit-box-orient: vertical;
  line-height: ${({ lineHeight }) => `${lineHeight}px` || 'initial'};
`

export default TextEllipsis
