import { FC } from 'react'
import { useRouter } from 'next/router'
import styled from 'styled-components'
import { InputRadio } from '@/components/common/inputs'
import { GlobalColorEnum } from '@/constants/global-color.enum'
import { LAYOUT_UNITS, Z_INDEXES } from '@/constants/legacy/constLayout'
import ROUTES from '@/constants/legacy/constRoutes'
import { PathParamKeyEnum } from '@/constants/path-param-key.enum'
import { QueryStringKeyEnum } from '@/constants/query-string-key.enum'
import { SearchLocationEnum } from '@/constants/search-location.enum'
import { usePetType } from '@/containers/hooks'
import { AdPetType } from '@/graphql/generated/schemas'
import { UtilUrlController } from '@/utils/utilUrlController'

const getOptions = [
  { value: AdPetType.All, title: '전체' },
  { value: AdPetType.Dog, title: '강아지' },
  { value: AdPetType.Cat, title: '고양이' },
]

const PetTypeFilter: FC = () => {
  const { setPetType } = usePetType()
  const { query, replace } = useRouter()
  const {
    [QueryStringKeyEnum.SearchPetType]: petTypeQueryParam,
    [PathParamKeyEnum.PetType]: petTypePathParam,
    [PathParamKeyEnum.Keyword]: searchKeywordPathParam,
  } = query
  const petType = UtilUrlController.urlParamToPetType(petTypeQueryParam || petTypePathParam)

  const onChange: React.ChangeEventHandler<HTMLInputElement> = ({ target: { value } }) => {
    const _query = { ...query }
    delete _query?.[QueryStringKeyEnum.FilterCategory]
    delete _query?.[QueryStringKeyEnum.FilterBrand]
    delete _query?.[QueryStringKeyEnum.FilterPrice]

    setPetType(value)

    if (petTypePathParam && searchKeywordPathParam) {
      replace({
        pathname: ROUTES.SEARCH_KEYWORD,
        query: {
          [PathParamKeyEnum.PetType]: value,
          [QueryStringKeyEnum.SearchPetType]: value,
          keyword: searchKeywordPathParam,
          /**
           * 검색어를 직접 입력한 것이 아니라면 SearchLocationEnum.None 으로 설정
           */
          [QueryStringKeyEnum.SearchLocation]: SearchLocationEnum.None,
        },
      })
    } else {
      replace({
        pathname: ROUTES.SEARCH,
        query: {
          [PathParamKeyEnum.PetType]: value,
          [QueryStringKeyEnum.SearchPetType]: value,
        },
      })
    }
  }

  if (!petType) {
    return null
  }

  return (
    <StyledPetTypeContainer>
      <StyledPetTypeRadio>
        <InputRadio value={petType} options={getOptions} onChange={onChange} innerSize="sm" />
      </StyledPetTypeRadio>
    </StyledPetTypeContainer>
  )
}

const StyledPetTypeContainer = styled.div`
  box-sizing: border-box;
  min-height: 4.4rem;
  border-bottom: 1.5px solid ${GlobalColorEnum.Gray70};
`

const StyledPetTypeRadio = styled.div`
  z-index: ${Z_INDEXES.HEADER};
  width: 100%;
  max-width: ${LAYOUT_UNITS.BODY_MAX_WIDTH};
  padding-left: 16px;
  padding-top: 8px;
  margin: 0 auto;
  margin-left: 0 !important;
  margin-right: 0 !important;
  background-color: ${(props) => props.theme.color.grayWhite};
`

export default PetTypeFilter
