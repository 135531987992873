import { FitpetMallEventEnum } from '@/constants/fitpet-mall-event.enum'
import { PathToLocation } from '@/constants/location.enum'
import { useTracker } from '@/containers/contexts/EventTrackingProvider'
import { useCustomRouter } from '@/containers/hooks'

export const useLikeEventTracker = () => {
  const tracker = useTracker()
  const { pathname } = useCustomRouter()

  const trackClickLikeButtonEvent = ({
    id,
    productName,
    price,
    discountRate,
    discountPrice,
    reviewCount,
    reviewScore,
  }: {
    id: number
    productName: string
    price: number
    discountRate?: number
    discountPrice: number
    reviewCount: number
    reviewScore: number
  }) => {
    tracker.triggerCustomEvent(FitpetMallEventEnum.ClickListLikeButton, {
      productId: id,
      productName,
      productPrice: price,
      productDiscountRate: discountRate,
      productPurchasePrice: discountPrice,
      productReviewTotalCount: reviewCount,
      productReviewAvgscore: reviewScore,
      like_location: PathToLocation[pathname],
    })
  }

  const trackClickUnLikeButtonEvent = ({
    id,
    productName,
    price,
    discountRate,
    discountPrice,
    reviewCount,
    reviewScore,
  }: {
    id: number
    productName: string
    price: number
    discountRate?: number
    discountPrice: number
    reviewCount: number
    reviewScore: number
  }) => {
    tracker.triggerCustomEvent(FitpetMallEventEnum.ClickUnlikeButton, {
      productId: id,
      productName,
      productPrice: price,
      productDiscountRate: discountRate,
      productPurchasePrice: discountPrice,
      productReviewTotalCount: reviewCount,
      productReviewAvgscore: reviewScore,
      unlike_location: PathToLocation[pathname],
    })
  }

  return {
    trackClickLikeButtonEvent,
    trackClickUnLikeButtonEvent,
  }
}
