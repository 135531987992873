import { useMemo } from 'react'
import { ApolloClient, InMemoryCache, ApolloLink } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError, ErrorResponse } from '@apollo/client/link/error'
import { ServerError } from '@apollo/client/link/utils/throwServerError'
import * as Sentry from '@sentry/nextjs'
import { NormalizedCacheObject } from 'apollo-cache-inmemory'
import { createUploadLink } from 'apollo-upload-client'
import merge from 'deepmerge'
import getConfig from 'next/config'
import Router from 'next/router'
import ROUTES from '@/constants/legacy/constRoutes'
import { QueryStringKeyEnum } from '@/constants/query-string-key.enum'
import { useUserInfo } from '@/containers/hooks'
import { AdPetType } from '@/graphql/generated/schemas'
import { isInAppFlag } from '@/utils/utilBridge'
import { isEqual, isProduction } from '@/utils/utilCommon'
import { localPetType, localUserToken } from '@/utils/utilLocalStorage'

const { publicRuntimeConfig } = getConfig()
const uploadLink = createUploadLink({ uri: publicRuntimeConfig.API_URL })
const authLink = setContext((_, { headers }) => {
  // const userToken = getTokenFromApp() || utilCommon.localUserToken.load() // 순서 절대 바뀌면 안됨.
  const getToken = () => {
    if (typeof window !== undefined && isInAppFlag) {
      return window.getToken().apiToken.token === '' ? undefined : window.getToken().apiToken
    } else {
      return localUserToken.load()
    }
  }
  const userToken = getToken()
  const petType = localPetType.load() || AdPetType.Dog
  return {
    headers: {
      ...headers,
      'user-mode': 'USER_MODE_CUSTOMER',
      'display-pet-type': petType,
      Authorization: userToken !== undefined || userToken ? `Bearer ${userToken.token}` : null,
    },
  }
})

const errorLink = onError(({ graphQLErrors, networkError }: ErrorResponse) => {
  const { cleanUserInfo } = useUserInfo
  // const { pathname } = useCustomRouter()

  const _onUnauthorized = (statusCode: any) => {
    try {
      if (isInAppFlag) {
        // 토큰 만료시 처리를 앱일때는 앱에서 하므로 동작 하지 않게 처리
        return
      }
      cleanUserInfo()
      Router.replace({
        pathname: ROUTES.ACCOUNTS.LOGIN,
        query: {
          [QueryStringKeyEnum.ReturnUrl]: window.location.pathname,
        },
      })
    } catch (e) {
      Sentry.captureException(e)
      console.error(e)
    }
  }

  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, path }) => {
      console.error(`
        [GraphQL error] 
        Message: ${message}, 
        Path: ${path}
      `)

      // need validate code
      let error
      try {
        error = JSON.parse(message)
      } catch (e) {
        error = message
      }
      const { code, message: errorMessage } = error
      console.error(`errorCode=[${code}], errorMessage=[${errorMessage}]`)
      if (
        code === 'VALIDATION_ERROR_AUTHENTICATION_EXPIRED' ||
        code === 'ERROR_PERMISSION_DENIED' ||
        code === 'ERROR_AUTHENTICATION_EXPIRED'
      ) {
        Sentry.withScope((scope) => {
          scope.setTag('errorType', 'GraphQL Error')
          scope.setExtra('path', path)
          scope.setExtra('code', code)
          scope.setExtra('errorMessage', errorMessage)
          Sentry.captureMessage(`GraphQL Error: ${message}`)
        })
        _onUnauthorized(code)
      }
    })
  }

  if (networkError) {
    const { statusCode } = networkError as ServerError
    if (statusCode === 401 || statusCode === 403) {
      Sentry.withScope((scope) => {
        scope.setTag('errorType', 'Network Error')
        scope.setExtra('statusCode', statusCode)
        Sentry.captureMessage(`Network Error: ${networkError.message}`)
      })
      _onUnauthorized(statusCode)
    }
  }
})

const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__'

let apolloClient: ApolloClient<NormalizedCacheObject> | undefined

function createApolloClient() {
  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    // @ts-ignore
    link: ApolloLink.from([errorLink, authLink.concat(uploadLink)]),
    cache: new InMemoryCache(),
    connectToDevTools: !isProduction(),
    defaultOptions: {
      // watchQuery: {},
      query: {
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
      },
      mutate: {
        errorPolicy: 'all',
      },
    },
  })
}

export function initializeApollo(initialState: NormalizedCacheObject | null = null) {
  const _apolloClient = apolloClient ?? createApolloClient()

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract()

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(initialState, existingCache, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((d) => sourceArray.every((s) => !isEqual(d, s))),
      ],
    })

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data)
  }

  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient

  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient

  return _apolloClient
}

export function addApolloState(client: ApolloClient<NormalizedCacheObject>, pageProps: any) {
  if (pageProps?.props) {
    pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract()
  }

  return pageProps
}

export function useApollo(pageProps: any) {
  const state = pageProps[APOLLO_STATE_PROP_NAME]
  const store = useMemo(() => initializeApollo(state), [state])
  return store
}

export default useApollo
