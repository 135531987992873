import { FC, useState, useRef, PropsWithChildren, useEffect } from 'react'
import styled from 'styled-components'
import { ButtonCircle, ButtonSlideScrollLeft, ButtonSlideScrollRight } from '@/components/common/buttons'
import { Z_INDEXES } from '@/constants/legacy/constLayout'
import { removeScrollbar } from '@/utils/utilCSS'
import { floor, round } from '@/utils/utilCommon'

const leftMost = 0
const move = 480

// TODO: 임시로 type으로 버튼형태 선택하도록 해둔것으로, button component를 props로 받을 수 있도록 변경해야함
type ScrollContainerProps = {
  hideRightButton?: boolean
  isCircleType?: boolean
  buttonPositionY?: number
  id?: string
  style?: React.CSSProperties
}

const ScrollContainer: FC<PropsWithChildren<ScrollContainerProps>> = ({
  children,
  hideRightButton = false,
  isCircleType = false,
  buttonPositionY = 50,
  id,
  style,
}) => {
  const containerRef = useRef<HTMLDivElement | null>(null)

  const [visibleRightButton, setVisibleRightButton] = useState<boolean>(true)
  const [visibleLeftButton, setVisibleLeftButton] = useState<boolean>(false)

  // id가 바뀌면 scroll x를 가장 왼쪽으로 이동 (예를들어 Tab-id)
  useEffect(() => {
    scrollTo(leftMost)
  }, [id])

  const displayButtons = () => {
    if (!containerRef.current) return

    const { scrollLeft, scrollWidth } = containerRef.current
    const { width } = containerRef.current.getBoundingClientRect()

    setVisibleLeftButton(scrollLeft !== leftMost)
    setVisibleRightButton(round(scrollLeft) < floor(scrollWidth - width) - 1)
  }

  const scrollTo = (left: number) => {
    containerRef?.current?.scrollTo({
      left,
      behavior: 'smooth',
    })
  }

  const scrollToLeft = () => {
    if (!containerRef?.current) {
      return
    }

    scrollTo(containerRef.current.scrollLeft - move)
  }

  const scrollToRight = () => {
    if (!containerRef?.current) {
      return
    }

    scrollTo(containerRef.current.scrollLeft + move)
  }

  const renderLeftBtn = () => {
    return (
      <StyledScrollButtonLeft buttonPositionY={buttonPositionY}>
        {isCircleType ? (
          <ButtonCircle.Left visible={visibleLeftButton} onClick={scrollToLeft} />
        ) : (
          <ButtonSlideScrollLeft visible={visibleLeftButton} onClick={scrollToLeft} />
        )}
      </StyledScrollButtonLeft>
    )
  }

  const renderRightBtn = () => {
    return (
      <StyledScrollButtonRight buttonPositionY={buttonPositionY}>
        {isCircleType ? (
          <ButtonCircle.Right visible={visibleRightButton} onClick={scrollToRight} />
        ) : (
          <ButtonSlideScrollRight visible={visibleRightButton} onClick={scrollToRight} />
        )}
      </StyledScrollButtonRight>
    )
  }

  return (
    <StyledContainer style={style}>
      <StyledScrollContainer ref={containerRef} onScroll={displayButtons}>
        {renderLeftBtn()}
        {children}
        {!hideRightButton && renderRightBtn()}
      </StyledScrollContainer>
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
  width: 100%;
  position: relative;
`

const StyledScrollContainer = styled.div`
  ${removeScrollbar};
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
`

const StyledScrollButtonLeft = styled.div<{ buttonPositionY: number }>`
  position: absolute;
  z-index: ${Z_INDEXES.SCROLL_HORIZONTAL_BUTTON};
  top: ${({ buttonPositionY }) => `${buttonPositionY}%`};
  transform: ${({ buttonPositionY }) => `translateY(-${buttonPositionY}%)`};
  left: 0;
`

const StyledScrollButtonRight = styled(StyledScrollButtonLeft)`
  left: inherit;
  right: 0;
`

export default ScrollContainer
