import { gql } from '@apollo/client'
import DEFAULT_FRAGMENTS from '@/containers/gqls/base/defaultFragments'

export const AD_QUERY_GQLS = {
  ADS: gql`
    query ads($pageInfo: PageInfoInputType, $filter: GenericScalar, $orderBy: String) {
      ads(pageInfo: $pageInfo, filter: $filter, orderBy: $orderBy) {
        pageInfo {
          ...pageInfo
        }
        totalCount
        edges {
          cursor
          node {
            id
            isShown
            endedAt
            name
            slug
            adType
            style
            mainImage {
              image
            }
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.pageInfo}
  `,
  AD: gql`
    query ad($id: ID!) {
      ad(id: $id) {
        id
        startedAt
        endedAt
        name
        description
        adType
      }
    }
  `,
  AD_DISPLAY_MINIMUM_INFO_BY_SLUG: gql`
    query adDisplayMinimumInfoBySlug($slug: String!) {
      adBySlug(slug: $slug) {
        id
        name
        mainImage {
          image
        }
      }
    }
  `,
  AD_HUNDRED_WON_DEAL: gql`
    query adsDeal100 {
      adsDeal100 {
        edges {
          node {
            id
            name
            description
            petType
            adDisplayCollections {
              totalCount
              edges {
                node {
                  id
                }
              }
            }
          }
        }
      }
    }
  `,
}

export default AD_QUERY_GQLS
