import { forwardRef, useImperativeHandle, useRef } from 'react'
import styled, { css } from 'styled-components'
import { FontResponsiveSizeType, FontSizeType } from '@/components/common/texts/TextBase'
import { SCREEN_MEDIA_QUERY } from '@/constants/legacy/constLayout'

export interface InputTextProps extends Omit<React.ComponentPropsWithoutRef<'input'>, 'prefix'> {
  textAlign?: 'center' | 'left' | 'right'
  noPadding?: boolean
  fontSize?: FontResponsiveSizeType | FontSizeType
  /** 텍스트 clear 버튼 ui 및 기능 노출 여부 */
  allowClear?: boolean
  /** 인풋의 border 노출 여부 */
  bordered?: boolean
  /** enter 키 up 이벤트 */
  onPressEnter?: React.KeyboardEventHandler<HTMLInputElement>
  /** 인풋 앞의 UI(prefix) */
  prefix?: React.ReactNode
  /** 인풋 뒤의 UI(suffix) */
  suffix?: React.ReactNode
}

/**
 * antd를 대체하는 InputText UI
 */
const InputText = forwardRef<HTMLInputElement, InputTextProps>(
  (
    {
      textAlign = 'left',
      noPadding = false,
      fontSize = {
        xs: 14,
        md: 16,
      },
      placeholder = ' ',
      disabled,
      onKeyUp,
      onChange,
      allowClear,
      bordered = true,
      prefix,
      suffix,
      onPressEnter,

      ...rest
    },
    ref
  ) => {
    const inputRef = useRef<HTMLInputElement>(null)
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    useImperativeHandle(ref, () => inputRef.current!)

    const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
      onKeyUp?.(e)

      if (e.key === 'Enter' && onPressEnter) {
        onPressEnter(e)
      }
    }

    const handleClickClear: React.MouseEventHandler<HTMLSpanElement> = (e) => {
      e.stopPropagation()

      if (inputRef.current) {
        onChange?.({ target: { value: '' } } as unknown as React.ChangeEvent<HTMLInputElement>)
        inputRef.current.value = ''
        inputRef.current.focus()
      }
    }

    return (
      <StyledInputContainer
        textAlign={textAlign}
        noPadding={noPadding ? 1 : 0}
        fontSize={fontSize}
        bordered={bordered}
        disabled={disabled}
      >
        {prefix}
        <StyledInput
          ref={inputRef}
          textAlign={textAlign}
          fontSize={fontSize}
          allowClear={allowClear}
          disabled={disabled}
          placeholder={placeholder}
          onKeyUp={handleKeyUp}
          onChange={onChange}
          {...rest}
        />
        <StyledInputSuffix>
          {suffix || <StyledClear id="new-input-text-clear" onClick={handleClickClear} />}
        </StyledInputSuffix>
      </StyledInputContainer>
    )
  }
)

InputText.displayName = 'InputText'

type StyledInputProps = Pick<InputTextProps, 'textAlign' | 'fontSize' | 'allowClear' | 'bordered' | 'disabled'> & {
  noPadding?: number
}

const StyledInputContainer = styled.span<Partial<StyledInputProps>>`
  position: relative;
  width: 100%;
  min-width: 0;
  min-height: 48px;
  padding: 4px 11px;
  background-color: ${({ theme }) => theme.color.grayWhite};
  border-radius: 3px;
  transition: all 0.3s;
  display: inline-flex;
  align-items: center;
  gap: 4px;

  caret-color: rgb(67, 67, 67);
  padding-left: ${({ noPadding }) => (noPadding ? '0px' : '10px')};
  padding-right: ${({ noPadding }) => (noPadding ? '0px' : '10px')};
  color: rgb(67, 67, 67);
  text-align: ${({ textAlign }) => textAlign};
  font-size: ${({ fontSize }) => {
    if (!fontSize) return '15px'
    return typeof fontSize === 'number' ? `${fontSize}px !important` : `${fontSize.md}px !important`
  }};

  ${({ bordered }) =>
    bordered &&
    css`
      border: 1px solid #d9d9d9;
    `}

  ${({ disabled }) =>
    css`
      ${disabled
        ? `
          background-color: #f5f5f5;

          & > input:disabled {
            background-color: #f5f5f5;
            opacity: 0.3;
          }
        `
        : `
          &:hover,
          &:focus-within {
            border-color: rgb(67, 67, 67) !important;
            box-shadow: none !important;
          }
        `}
    `}

  

  @media ${SCREEN_MEDIA_QUERY.xs} {
    font-size: ${({ fontSize }) => {
      if (!fontSize) return '15px'
      return typeof fontSize === 'number' ? `${fontSize}px !important` : `${fontSize.md}px !important`
    }};

    & > input:first-child {
      font-size: ${({ fontSize }) => {
        if (!fontSize) return '15px'
        return typeof fontSize === 'number' ? `${fontSize}px !important` : `${fontSize.md}px !important`
      }};
    }
  }
`

const StyledInput = styled.input<Partial<StyledInputProps>>`
  position: relative;
  display: inline-block;
  width: 100%;
  padding: 4px 11px;
  transition: all 0.3s;
  text-align: ${({ textAlign }) => textAlign};
  text-overflow: ellipsis;
  line-clamp: 1;
  border: none;
  outline: none;
  font-size: ${({ fontSize }) => {
    if (!fontSize) return '15px'
    return typeof fontSize === 'number' ? `${fontSize}px !important` : `${fontSize.md}px !important`
  }};

  ::placeholder {
    color: ${(props) => props.theme.color.gray400} !important;
  }

  &:not(textarea) {
    padding: 0px;
  }

  ${({ allowClear }) =>
    allowClear &&
    css`
      &:not(:placeholder-shown):focus {
        & + span > #new-input-text-clear {
          visibility: visible;
        }
      }
    `}
`

const StyledInputSuffix = styled.span`
  display: flex;
  flex: none;
  align-items: center;
  margin-left: 4px;
`

const StyledClear = styled.span`
  width: 12px;
  height: 12px;
  border: 1px solid #c8c8c8;
  border-radius: 50%;
  background-color: #c8c8c8;
  position: relative;
  margin-right: 8px;
  visibility: hidden;

  &:active {
    visibility: visible;
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 6px;
    height: 1px;
    background-color: white;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &::after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  &:hover {
    background-color: ${({ theme }) => theme.color.gray600};
    border: 1px solid ${({ theme }) => theme.color.gray600};
    transition: background-color 0.3s, border 0.3s;
  }

  &:active {
    background-color: ${({ theme }) => theme.color.gray800};
    border: 1px solid ${({ theme }) => theme.color.gray800};
    transition: background-color 0.3s, border 0.3s;
  }
`

export default InputText
