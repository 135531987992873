import { FC } from 'react'
import styled, { keyframes, useTheme } from 'styled-components'
import FlatIcon from '@/components/common/icons/FlatIcon'
import { Image } from '@/components/common/images/Image'
import useSearchedKeywords from '@/components/domains/search/hooks/useSearchedKeywords'
import { IconNameEnum } from '@/constants/icon-name.enum'
import { QueryStringKeyEnum } from '@/constants/query-string-key.enum'
import { useCustomRouter } from '@/containers/hooks'
import { AdPetType } from '@/graphql/generated/schemas'
import { RegexUtils } from '@/utils/utilRegex'
import { getImageUrl } from '@/utils/utilURL'

type SearchedKeywordsType = {
  searchInputText: string
}

const { escapeRegExp } = RegexUtils

const SearchedKeywords: FC<SearchedKeywordsType> = ({ searchInputText }) => {
  const { query } = useCustomRouter()
  const { [QueryStringKeyEnum.SearchPetType]: searchPetType } = query
  const { loading, searchedKeywords, searchedBrands, isShowSearchedKeywords, onClickKeyword } = useSearchedKeywords(
    escapeRegExp(searchInputText),
    searchPetType as AdPetType
  )
  const { spacing, color } = useTheme()

  const highLightSearchText = (searchedKeyword: string) => {
    const pattern = new RegExp(`(.*)(${escapeRegExp(searchInputText.trim())})(.*)`, 'i')
    const matchedKeyword = searchedKeyword.match(pattern) ?? []
    const [_unused, first, keyword, last] = matchedKeyword

    // 매칭되는 문자가 없을 시 문자 그대로 보여주도록 처리
    if (!matchedKeyword.length) {
      return searchedKeyword
    }

    return (
      <>
        {first}
        <span>{keyword}</span>
        {last}
      </>
    )
  }

  if (!searchInputText || !isShowSearchedKeywords) {
    return null
  }

  return (
    <StyledSearchedKeywordList isShow={isShowSearchedKeywords} loading={loading}>
      {searchedBrands.map((brand, index) => (
        <StyledBrandKeywordItem key={brand.id} onClick={() => onClickKeyword(brand.name, index, brand.id)}>
          <StyledBrandCircleItem>
            <StyledImageContainer>
              <Image src={getImageUrl(brand.listImage) as string} alt={`${brand.name} Brand Logo`} />
            </StyledImageContainer>
            <StyledText>{highLightSearchText(brand.name)}</StyledText>
          </StyledBrandCircleItem>
          <FlatIcon type={IconNameEnum.IcArrowRight} color={color.gray300} size={spacing.size16} />
        </StyledBrandKeywordItem>
      ))}
      {searchedBrands.length > 0 && <Divider />}
      {searchedKeywords.map((keyword, index) => (
        <StyledKeywordItem key={index} onClick={() => onClickKeyword(keyword, index)}>
          <FlatIcon type={IconNameEnum.IcSearch} color={color.gray200} size={spacing.size20} />
          <StyledText>{highLightSearchText(keyword)}</StyledText>
        </StyledKeywordItem>
      ))}
    </StyledSearchedKeywordList>
  )
}

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const StyledSearchedKeywordList = styled.div<{ isShow: boolean; loading: boolean }>`
  z-index: 10;
  position: absolute;
  padding: 0 ${({ theme: { spacing } }) => spacing.size16} ${({ theme: { spacing } }) => spacing.size104};
  top: 10.2rem;
  left: 0;
  width: ${({ theme: { spacing } }) => spacing.per100};
  height: 100vh;
  display: ${({ isShow, loading }) => (!loading && isShow ? 'block' : 'none')};
  background: ${({ theme: { color } }) => color.grayWhite};
  overflow-x: hidden;
  animation: ${fadeIn} 0.5s ease-in-out;
`

const StyledBrandKeywordItem = styled.div`
  padding: ${({ theme: { spacing } }) => spacing.size8} 0;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  cursor: pointer;

  &:first-child {
    margin-top: ${({ theme: { spacing } }) => spacing.size8};
  }
`

const StyledBrandCircleItem = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme: { spacing } }) => spacing.size8};
`

const StyledKeywordItem = styled.div`
  padding: ${({ theme: { spacing } }) => spacing.size16} 0;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: ${({ theme: { spacing } }) => spacing.size8};
  background-color: transparent;
  cursor: pointer;
`

const StyledText = styled.div`
  // TODO 검색 홈 rem 사이즈 적용 시 수정 사항
  font-size: 16px;
  color: ${({ theme: { color } }) => color.gray700};
  span {
    color: ${({ theme: { color } }) => color.blue500};
  }
`

const StyledImageContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  // TODO 검색홈 rem 사이즈 적용 시 수정 사항
  width: 38px;
  height: 38px;
  border-radius: 50%;
  border: 1px solid ${({ theme: { color } }) => color.gray100};
`

const Divider = styled.div`
  padding: 0 ${({ theme: { spacing } }) => spacing.size16};
  margin-top: ${({ theme: { spacing } }) => spacing.size8};
  width: 100%;
  height: ${({ theme }) => theme.spacing.size1};
  border-bottom: ${({ theme }) => `${theme.spacing.size1} solid ${theme.color.gray70}`};
`

export default SearchedKeywords
