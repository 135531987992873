import { useState } from 'react'

export type UseModalProps<T> = {
  visible: boolean
  data: T
  actionParams: any
  maskClosable?: boolean
  setData(modalData: T): void
  setActionParams(params: any): void
  showModal(): void
  hideModal(): void
}

const useModal = (initVisible: boolean = false, initData = {}) => {
  const [visible, toggleVisible] = useState(initVisible)
  const [data, setData] = useState(initData)
  const [actionParams, setActionParams] = useState(undefined)

  const statusByShowing = (valVisible: boolean) => {
    if (!valVisible) setData({})
    toggleVisible(valVisible)
  }

  const showModal = () => {
    statusByShowing(true)
  }

  const hideModal = () => {
    statusByShowing(false)
  }

  const useModalProps: UseModalProps<any> = {
    visible,
    data,
    setData,
    actionParams,
    setActionParams,
    showModal,
    hideModal,
  }

  return { useModalProps }
}

export default useModal
