const S3_ROOT_FOLDER_LEGACY = process.env.S3_ROOT_FOLDER_LEGACY || 'alpha'
export const S3_ROOT_FOLDER = process.env.S3_ROOT_FOLDER || 'alpha'
export const S3_HOST_URL = `https://static.fitpetcdn.com`

export const FITPETMALL_HOST = process.env.HOST
export const HOSPITAL_HOST = process.env.HOSPITAL_HOST
export const HEALTH_HOST = process.env.HEALTH_HOST

export const API_HOSPITAL_LEGACY_DOMAIN = process.env.API_HOSPITAL_LEGACY_DOMAIN
export const API_HOSPITAL_DOMAIN = process.env.API_HOSPITAL_DOMAIN
export const API_HEALTH_DOMAIN = process.env.API_HEALTH_DOMAIN

export const APPLE_CLIENT_ID = process.env.APPLE_CLIENT_ID
export const KAKAO_KEY = process.env.KAKAO_APP_KEY

export const ONELINK_HOST = process.env.ONELINK_HOST

export const S3_PET_FOLDER = process.env.S3_PET_FOLDER

export const S3_FOLDERS_LEGACY = {
  ASSETS: S3_ROOT_FOLDER_LEGACY + '/assets',
  ASSET_IMAGES: S3_ROOT_FOLDER_LEGACY + '/assets/images',
  ASSET_FONTS: S3_ROOT_FOLDER_LEGACY + '/assets/fonts',
}

export const IAMPORT = {
  MERCHANT_CODE: process.env.IAMPORT_MERCHANT_CODE,
  KEY: process.env.IAMPORT_KEY,
}

export const ZENDESK_URLS = {
  ACCESS: process.env.ZENDESK_URL_ACCESS,
  INQUIRY: process.env.ZENDESK_URL_INQUIRY,
  NOTICE: process.env.ZENDESK_URL_NOTICE,
  CUSTOMER_CENTER: process.env.ZENDESK_URL_CUSTOMER_CENTER,
}

export const SWEET_TRACKER = {
  URL: process.env.SWEET_TRACKER_URL,
  API_KEY: process.env.SWEET_TRACKER_API_KEY,
}

export const CHANNEL_TALK_URL = {
  INQUIRY: 'https://fitpet.channel.io/home',
  NOTICE: 'https://docs.channel.io/fitpetmall/ko/categories/16aa01a2-%EA%B3%B5%EC%A7%80%EC%82%AC%ED%95%AD',
  CUSTOMER_CENTER: 'https://docs.channel.io/fitpetmall',
}
