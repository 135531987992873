import { ButtonHTMLAttributes, createContext, ReactNode, useContext } from 'react'
import styled, { css, useTheme } from 'styled-components'
import { match } from 'ts-pattern'
import { FitpetIcon, SvgIconType } from '@/components/common/icons/fitpet-icon'
import { Typo, TypoVariant } from '@/components/common/typography'
import { GlobalColorEnum } from '@/constants/global-color.enum'
import { GlobalTheme } from '@/styles/globalTheme'

type ButtonVariant = 'primary' | 'secondary' | 'tertiary' | 'grayFill' | 'blueFill' | 'whiteFill' | 'primaryCritical'
type ButtonSize = 'small' | 'medium' | 'large'
type ButtonWidth = 'auto' | 'full' | `${number}%` | `${number}rem`

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: ButtonVariant
  size?: ButtonSize
  width?: ButtonWidth
  isPill?: boolean
}

const BaseButton = styled.button<ButtonProps>`
  cursor: pointer;
  transition: all 0.3s ease;

  &:disabled {
    cursor: not-allowed;
  }

  ${({ width }) => {
    if (width === 'auto')
      return css`
        width: auto;
      `
    if (width === 'full')
      return css`
        width: 100%;
      `
    if (typeof width === 'string')
      return css`
        width: ${width};
      `
  }}

  max-width: 100%;
`

const buttonVariants = {
  primary: css`
    background-color: ${GlobalColorEnum.Blue500};
    border: none;

    &:hover {
      background-color: ${GlobalColorEnum.Blue700};
    }
    &:active {
      background-color: ${GlobalColorEnum.Blue900};
    }
    &:disabled {
      background-color: ${GlobalColorEnum.Gray200};
    }
  `,
  secondary: css`
    background-color: ${GlobalColorEnum.GrayWhite};
    border: 1px solid ${GlobalColorEnum.Blue500};

    &:hover {
      background-color: ${GlobalColorEnum.Blue30};
    }
    &:active {
      background-color: ${GlobalColorEnum.Blue70};
    }
    &:disabled {
      background-color: ${GlobalColorEnum.GrayWhite};
      border: 1px solid ${GlobalColorEnum.Gray200};
    }
  `,
  tertiary: css`
    background-color: ${GlobalColorEnum.GrayWhite};
    border: 1px solid ${GlobalColorEnum.Gray200};

    &:hover {
      background-color: ${GlobalColorEnum.Gray50};
    }
    &:active {
      background-color: ${GlobalColorEnum.Gray100};
    }
    &:disabled {
      background-color: ${GlobalColorEnum.GrayWhite};
      border: 1px solid ${GlobalColorEnum.Gray200};
    }
  `,
  grayFill: css`
    background-color: ${GlobalColorEnum.Gray70};
    border: 1px solid ${GlobalColorEnum.Gray70};

    &:hover {
      background-color: ${GlobalColorEnum.Gray100};
      border: 1px solid ${GlobalColorEnum.Gray100};
    }
    &:active {
      background-color: ${GlobalColorEnum.Gray200};
      border: 1px solid ${GlobalColorEnum.Gray200};
    }
    &:disabled {
      background-color: ${GlobalColorEnum.Gray70};
      border: 1px solid ${GlobalColorEnum.Gray70};
    }
  `,
  blueFill: css`
    background-color: ${GlobalColorEnum.Blue50};
    border: 1px solid ${GlobalColorEnum.Blue50};

    &:hover {
      background-color: ${GlobalColorEnum.Blue100};
      border: 1px solid ${GlobalColorEnum.Blue100};
    }
    &:active {
      background-color: ${GlobalColorEnum.Blue200};
      border: 1px solid ${GlobalColorEnum.Blue200};
    }
    &:disabled {
      background-color: ${GlobalColorEnum.Gray70};
      border: 1px solid ${GlobalColorEnum.Gray70};
    }
  `,
  whiteFill: css`
    background-color: ${GlobalColorEnum.GrayWhite};
    border: 1px solid ${GlobalColorEnum.GrayWhite};

    &:hover {
      background-color: ${GlobalColorEnum.Gray100};
      border: 1px solid ${GlobalColorEnum.Gray100};
    }
    &:active {
      background-color: ${GlobalColorEnum.Gray200};
      border: 1px solid ${GlobalColorEnum.Gray200};
    }
    &:disabled {
      background-color: ${GlobalColorEnum.GrayWhite};
      border: 1px solid ${GlobalColorEnum.GrayWhite};
    }
  `,
  primaryCritical: css`
    background-color: ${GlobalColorEnum.Red500};
    border: 1px solid ${GlobalColorEnum.Red500};

    &:hover {
      background-color: ${GlobalColorEnum.Red700};
    }
    &:active {
      background-color: ${GlobalColorEnum.Red900};
    }
    &:disabled {
      background-color: ${GlobalColorEnum.GrayWhite};
      border: 1px solid ${GlobalColorEnum.Gray200};
    }
  `,
}

const buttonSizes = {
  small: css`
    padding: 0.6rem 1.4rem;
    height: 3.2rem;
  `,
  medium: css`
    padding: 0.75rem 1.4rem;
    height: 3.6rem;
  `,
  large: css`
    padding: 1.3rem 1.6rem;
    height: 4.8rem;
  `,
}

const buttonPill = (isPill: boolean) => {
  return isPill
    ? css`
        border-radius: 2rem;
      `
    : css`
        border-radius: 0.8rem;
      `
}

const StyledButton = styled(BaseButton)<ButtonProps>`
  ${({ variant = 'primary' }) => buttonVariants[variant]}
  ${({ size = 'large' }) => buttonSizes[size]}
  ${({ isPill = false }) => buttonPill(isPill)}
`

interface FitpetButtonContextType {
  variant: ButtonVariant
  size: ButtonSize
}

const FitpetButtonContext = createContext<FitpetButtonContextType>({ variant: 'primary', size: 'large' })

const useButtonContext = () => {
  const context = useContext(FitpetButtonContext)
  if (context === undefined) {
    throw new Error('useButtonContext must be used within a FitpetButton')
  }
  return context
}

export const FitpetButton = ({
  children,
  variant = 'primary',
  size = 'large',
  width = 'auto',
  isPill = false,
  ...props
}: ButtonProps) => {
  return (
    <FitpetButtonContext.Provider value={{ variant, size }}>
      <StyledButton type="button" variant={variant} size={size} isPill={isPill} width={width} {...props}>
        {children}
      </StyledButton>
    </FitpetButtonContext.Provider>
  )
}
FitpetButton.displayName = 'FitpetButton'

const ButtonText = ({
  children,
  leftIcon,
  rightIcon,
}: {
  children: ReactNode
  leftIcon?: {
    icon: SvgIconType
    color?: typeof GlobalTheme.color[keyof typeof GlobalTheme.color]
    width?: typeof GlobalTheme.iconSize[keyof typeof GlobalTheme.iconSize]
  }
  rightIcon?: {
    icon: SvgIconType
    color?: typeof GlobalTheme.color[keyof typeof GlobalTheme.color]
    width?: typeof GlobalTheme.iconSize[keyof typeof GlobalTheme.iconSize]
  }
}) => {
  const { color } = useTheme()
  const { variant, size } = useButtonContext()
  const typeVariant = match(size)
    .with('small', () => TypoVariant.Body3Bold)
    .with('medium', () => TypoVariant.Body2Bold)
    .with('large', () => TypoVariant.Body1Bold)
    .exhaustive()

  const fontColor = match(variant)
    .with('primary', () => color.grayWhite)
    .with('secondary', () => color.blue500)
    .with('tertiary', () => color.gray900)
    .with('grayFill', () => color.gray900)
    .with('blueFill', () => color.blue500)
    .with('whiteFill', () => color.gray900)
    .with('primaryCritical', () => color.grayWhite)
    .exhaustive()

  const iconSize = match(size)
    .with('small', () => GlobalTheme.iconSize.size16)
    .with('medium', () => GlobalTheme.iconSize.size16)
    .with('large', () => GlobalTheme.iconSize.size20)
    .exhaustive()

  return (
    <StyledIconText>
      {leftIcon?.icon && <FitpetIcon type={leftIcon.icon} color={leftIcon.color} size={iconSize} />}
      <Typo variant={typeVariant} color={fontColor}>
        {children}
      </Typo>
      {rightIcon?.icon && <FitpetIcon type={rightIcon.icon} color={rightIcon.color} size={iconSize} />}
    </StyledIconText>
  )
}
ButtonText.displayName = 'ButtonText'

FitpetButton.ButtonText = ButtonText

const StyledIconText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.4rem;
`
