import styled, { useTheme } from 'styled-components'
import { Text } from '@/components/common'
import FlatIcon from '@/components/common/icons/FlatIcon'
import { Typo } from '@/components/common/typography'
import { IconNameEnum } from '@/constants/icon-name.enum'
import FitpetPlusCouponLogo from '@/images/fitpet_plus_coupon_logo.svg'
import type { CouponCoverageScheme } from '@/apis/rest/generated/types'

type BasicCouponCardContentType = {
  title: string
  name: string
  amountText: string
  datePeriodText: string
  isMembership: boolean
  isConjunctUse: boolean
  errorMessage?: string
  disabled: boolean
  hasSpecifiedCoverage: boolean
  coverage?: CouponCoverageScheme
}

export const NewCouponCard = ({
  title,
  name,
  amountText,
  datePeriodText,
  isMembership,
  isConjunctUse,
  errorMessage,
  disabled,
  hasSpecifiedCoverage,
  coverage,
}: BasicCouponCardContentType) => {
  const { iconSize, color } = useTheme()

  const coverageText = coverage?.targets.map((target) => target.name).join(', ')

  return (
    <div>
      <div>
        <StyledTitleWrapper>
          <Typo variant="Heading5 Bold" color={disabled ? color.gray400 : color.gray900}>
            {title}
          </Typo>
          <StyledMembershipLogoWrapper>
            {isMembership && (
              <StyledMembershipLogo disabled={disabled}>
                <StyledFitpetPlusCouponLogo />
              </StyledMembershipLogo>
            )}
            {!isConjunctUse && (
              <StyledConjunctLabel>
                <Typo variant="Body5 Medium" color={color.gray400}>
                  중복불가
                </Typo>
              </StyledConjunctLabel>
            )}
          </StyledMembershipLogoWrapper>
        </StyledTitleWrapper>
        <Typo variant="Body2 Medium" color={disabled ? color.gray400 : color.gray900}>
          {name}
        </Typo>
      </div>
      <StyledContent>
        {amountText && (
          <Typo as="div" variant="Body5 Regular" color={disabled ? color.gray400 : color.gray500}>
            {amountText}
          </Typo>
        )}
        <Typo as="div" variant="Body5 Regular" color={disabled ? color.gray400 : color.gray500}>
          {datePeriodText}
        </Typo>
        {hasSpecifiedCoverage && !!coverageText && (
          <StyledCoverageWrapper>
            <StyledCoverageHeader>
              <Typo variant="Body5 Medium">쿠폰 적용대상</Typo>
              <FlatIcon type={IconNameEnum.IcChevronDown} size={iconSize.size16} color={color.gray900} />
            </StyledCoverageHeader>
            <StyledCoverageContent>
              <Typo variant="Body5 Regular" color={color.gray400}>
                {coverageText}
              </Typo>
              <Typo variant="Body5 Regular" color={color.gray300}>
                (일부 카테고리 및 상품 제외)
              </Typo>
            </StyledCoverageContent>
          </StyledCoverageWrapper>
        )}
        {!coverageText && coverage?.hasExclusions && (
          <StyledCoverageContent>
            <Typo variant="Body5 Regular" color={color.gray300}>
              (일부 카테고리 및 상품 제외)
            </Typo>
          </StyledCoverageContent>
        )}
      </StyledContent>
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </div>
  )
}

const StyledTitleWrapper = styled.div`
  display: flex;
  align-items: center;
`

const StyledMembershipLogoWrapper = styled.div`
  display: flex;
  margin-left: 0.4rem;
`

const StyledMembershipLogo = styled.div<{ disabled: boolean }>`
  flex-shrink: 0;
  flex-grow: 1;
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
  align-items: center;
  ${({ disabled }) =>
    disabled &&
    `${StyledFitpetPlusCouponLogo} {
      opacity: 0.3;
      mix-blend-mode: luminosity;
    }`}
`

const StyledFitpetPlusCouponLogo = styled(FitpetPlusCouponLogo)`
  width: 6.3rem;
  height: 1.7rem;
`

const StyledConjunctLabel = styled.div`
  padding: 0.2rem 0.4rem;
  margin-left: 0.4rem;
  background-color: ${({ theme }) => theme.color.gray50};
  border-radius: 0.4rem;
`

const StyledContent = styled.div`
  margin-top: 0.8rem;
  display: flex;
  flex-direction: column;
`

/**
 * ios 에서 summary 태그 기본 스타일 초기화 필요
 */
const StyledCoverageHeader = styled.summary`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  &::marker,
  &::-webkit-details-marker {
    display: none;
    content: '';
  }
`
const StyledCoverageWrapper = styled.details`
  margin-top: 0.4rem;
  i {
    transition: all 0.3s ease-in-out;
    transform: rotate(0deg);
  }
  &[open] {
    /* 
     i 태그 사용
     FlatIcon 컴포넌트의 rotate 속성을 사용하면, rotate(180deg)가 적용되지 않는다.
     FlatIcon이 styled-components로 만들어져 있지 않기 때문에, styled-components의 스타일을 적용할 수 없다. 
     */
    i {
      transform: rotate(180deg);
    }
  }
`

const StyledCoverageContent = styled.div`
  margin-top: 0.4rem;
  display: flex;
  flex-direction: column;
`

const ErrorMessage = styled(Text.Body5)`
  margin-top: 0.4rem;
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  color: ${({ theme }) => theme.color.red500} !important;
`
