// value 가 Pascal 인 이유는 value 를 query 에 type 으로 넣고
// 그 type 을 받아와서 아래 ProductBundledListPageTitleEnum 을 사용하여
// header 에 제목을 세팅해주기 때문입니다
export enum ProductDeliveryTypeEnum {
  // URL 쿼리로 사용하고 또 그 값을 컴포넌트로 내려받아서 API에 사용하기도 하므로 내부 값을 PascalCase로 작성함
  Fitpet = 'Fitpet',
  Others = 'Others',
}

export enum ProductBundledListPageTitleEnum {
  Fitpet = '핏펫 배송',
  Others = '묶음배송 상품',
}
