export const SCREEN_SIZES = {
  xs: 0,
  md: 576 /* 변경 시 global.css의 width도 같이 변경 */,
  lg: 1200,
}

export const SCREEN_SCALE = {
  /**
   * ORIGINAL_SCALE_DEVICE_WIDTH : 기본 디자인 사이즈 (1rem = 10px)
   * MAX_SCALE_DEVICE_WIDTH : 비율증가의 최대값으로 1rem 의 px 값이 더 이상 커지지 않는 값(1rem = 12px)
   * MAX_DEVICE_WIDTH : 허용 화면 최대 가로 크기
   */
  ORIGINAL_SCALE_DEVICE_WIDTH: 375,
  MAX_SCALE_DEVICE_WIDTH: 450,
  MAX_DEVICE_WIDTH: 576,
  DEFAULT_SCALE: 1,
}

// @deprecated: layout-units.enum.ts 로 대체
export const LAYOUT_UNITS = {
  BODY_MAX_WIDTH: `${SCREEN_SIZES.md}px`,
}

export const SCREEN_MEDIA_QUERY = {
  xs: `(max-width: ${SCREEN_SIZES.md - 1}px)`, // screen < 576px, mobile
  md: `(min-width: ${SCREEN_SIZES.md}px)`, // screen >= 576px, pc
}

// @deprecated: z-indexes.enum.ts 로 대체
export const Z_INDEXES = {
  CAROUSEL_ARROW_BUTTON: 1,
  PRODUCT_LIST_SOLDOUT: 1,
  CAROUSEL_HOME_EVENT: 2,
  HOME_CATEGORY_SLIDER: 3,
  EVENT_CATEGORY_SELECT: 3,
  SCROLL_TOP_BUTTON: 20,
  HEADER: 22,
  HEADER_ROOT_CATE_SLIDE: 4,
  HEADER_ROOT_CATE_BUTTON: 5,
  BOTTOM_TAB_NAVIGATOR: 6,
  BOTTOM_FIXED: 9,
  HOME_EVENT_POPUP: 10,
  BOTTOM_SLIDE_POPUP: 101,
  REVIEW_IMAGE_POPUP: 101,
  PURCHASE_CONFIRM_TOOLTIP: 2,
  PRODUCT_LIST_LIKE_LIST: 2,
  LOCAL_NAVIGATION_BAR: 3,
  ROOT_CATEGORY_NAVIGATION: 4,
  SUB_CATEGORY_NAVIGATION: 2,
  TOAST: 102,
  SCROLL_HORIZONTAL_BUTTON: 3,
  RANK_BADGE: 2,
}

export const FIXED_HEIGHT = {
  HEADER: 60,
  HEADER_HOME_NAVIGATION_BAR: 43,
  BOTTOM_TAB_NAVIGATOR: 57,
  PRODUCT_ORDER_BUTTON: 88,
  TOP_SLIM_BANNER_HEIGHT: 42,
  PRODUCTCARD_CONTENT_HEIGHT: 100,
}

export const FIXED_SIZE = {
  POPULAR_BRAND_LOGO: 60,
}

export const CAROUSEL_SCREEN_RATIO = {
  WIDTH: 2.067,
  HEIGHT: 1,
}
