import { useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import { useSumCartQuantity } from '@/apis/rest/generated/hooks'
import { POLICY } from '@/constants/legacy/constData'
import MUTATION_VARIABLES from '@/containers/gqls/base/mutation_variables'
import { PRODUCT_QUERY_GQLS } from '@/containers/gqls/products/queries'
import { useApiMutation, useCustomRouter } from '@/containers/hooks'
import { ModelCartItem } from '@/containers/models'
import {
  CartProductOptionKeyType,
  CartProductOptionType,
  CartProductOptionTypeUtil,
} from '@/containers/types/CartProductOption'
import { getResultFromData } from '@/utils/utilApi'
import { initializeApollo } from '@/utils/utilApolloClient'
import { deepcopy } from '@/utils/utilData'

type UseCartServerType = {
  refreshCartProductOptionsCount: () => void
  refreshCartProductOptions: () => void
  addOrUpdateCartProductOptions: (cartProductOptions: CartProductOptionType[]) => void
  mergeCartProductOptionsWithApiQuery: (localCartProductOptions: CartProductOptionType[]) => void
  removeCartProductOption: (cartProductOptionKey: CartProductOptionKeyType) => void
  removeCartProductOptions: (cartProductOptionKeys: CartProductOptionKeyType[]) => void
  updateCartProductOption: (
    cartProductOptionKey: CartProductOptionKeyType,
    cartProductOption: CartProductOptionType,
    availableMaxPurchaseQuantity?: number
  ) => void
  updateCartProductOptionPurchaseQuantity: (
    cartProductOptionKey: CartProductOptionKeyType,
    purchaseQuantity: number
  ) => void
}

export const useCartServer = ({
  handleCartProductOptionQuantityChange,
  handleCartProductOptionChange,
}: {
  handleCartProductOptionQuantityChange?: (cartProductOptionQuantity: number) => void
  handleCartProductOptionChange?: (cartProductOptions: CartProductOptionType[]) => void
}): UseCartServerType => {
  const { pathname } = useCustomRouter()
  const isHome = pathname === '/'

  const [serverCartProductOptions, setServerCartProductOptions] = useState<CartProductOptionType[]>([])

  const { refetch: refetchCountCart } = useSumCartQuantity({
    query: {
      enabled: false,
      onSuccess: (data) => {
        if (handleCartProductOptionQuantityChange) {
          handleCartProductOptionQuantityChange(data.count)
        }
      },
    },
  })

  const [createCartItemsMutation] = useApiMutation('createCartItems')
  const [deleteCartItemsMutation] = useApiMutation('deleteCartItems')
  const [updateCartItemMutation] = useApiMutation('updateCartItem')

  const [queryCartItems] = useLazyQuery(PRODUCT_QUERY_GQLS.CART_PRODUCT_LIST, {
    fetchPolicy: isHome ? 'cache-first' : 'no-cache',
    onCompleted: (data) => {
      if (data) {
        const cartItems: ModelCartItem[] = getResultFromData(data)?.cartItems.data || []
        const newServerCartProductOptions: CartProductOptionType[] = []
        cartItems.forEach((cartItem) => {
          newServerCartProductOptions.push(cartItem.getCartProductOption())
        })
        _setServerCartProductOptions(newServerCartProductOptions)
      }
    },
  })

  const _setServerCartProductOptions = (newServerCartProductOptions: CartProductOptionType[]) => {
    setServerCartProductOptions(newServerCartProductOptions)
    if (handleCartProductOptionChange) {
      handleCartProductOptionChange(newServerCartProductOptions)
    }
  }

  const refreshCartProductOptionsCount = () => {
    refetchCountCart()
  }
  const refreshCartProductOptions = async () => {
    await queryCartItems()
  }

  const _addOrUpdateCartProductOptions = async (
    cartProductOptions: CartProductOptionType[],
    localCartProductOptions: CartProductOptionType[]
  ) => {
    const _serverCartProductOptions = deepcopy(cartProductOptions)
    const productOptions = localCartProductOptions
      .filter((cartProductOption) => {
        return (
          CartProductOptionTypeUtil.getCartProductOption(
            _serverCartProductOptions,
            CartProductOptionTypeUtil.getCartProductOptionKey(cartProductOption)
          ) === undefined
        )
      })
      .map((cartProductOption) => {
        return {
          productOption: cartProductOption.productOptionId,
          quantity: cartProductOption.purchaseQuantity,
          productPromotion: cartProductOption.productPromotionId,
        }
      })
    if (productOptions.length > 0) {
      const variables = MUTATION_VARIABLES.CREATE_CART_ITEMS({ productOptions })
      await createCartItemsMutation({ variables }).then(() => {
        refetchCountCart()
      })
    }
    const variablesListForUpdate: { id: string; quantity: number; productOption: string }[] = []
    localCartProductOptions.forEach((cartProductOption) => {
      const cartProductOptionForUpdate = CartProductOptionTypeUtil.getCartProductOption(
        _serverCartProductOptions,
        CartProductOptionTypeUtil.getCartProductOptionKey(cartProductOption)
      )
      if (cartProductOptionForUpdate) {
        const variables = MUTATION_VARIABLES.UPDATE_CART_ITEM({
          id: cartProductOptionForUpdate.cartItemId,
          quantity: cartProductOptionForUpdate.purchaseQuantity + cartProductOption.purchaseQuantity,
          productOption: cartProductOptionForUpdate.productOptionId,
        })
        variablesListForUpdate.push(variables)
      }
    })
    await Promise.all(variablesListForUpdate.map((variables) => updateCartItemMutation({ variables }))).then(() =>
      refreshCartProductOptions()
    )
  }

  const mergeCartProductOptionsWithApiQuery = async (localCartProductOptions: CartProductOptionType[]) => {
    const client = initializeApollo()
    const serverCartItemsData = await client.query({
      query: PRODUCT_QUERY_GQLS.CART_PRODUCT_LIST,
      fetchPolicy: 'no-cache',
    })
    const cartItems: ModelCartItem[] = getResultFromData(serverCartItemsData?.data)?.cartItems.data || []
    const cartProductOptions: CartProductOptionType[] = []
    cartItems.forEach((cartItem) => {
      cartProductOptions.push(cartItem.getCartProductOption())
    })
    await _addOrUpdateCartProductOptions(cartProductOptions, localCartProductOptions)
  }

  const addOrUpdateCartProductOptions = async (cartProductOptions: CartProductOptionType[]) => {
    await _addOrUpdateCartProductOptions(serverCartProductOptions, cartProductOptions)
  }

  const removeCartProductOption = async (cartProductOptionKey: CartProductOptionKeyType) => {
    await removeCartProductOptions([cartProductOptionKey])
  }
  const removeCartProductOptions = async (cartProductOptionKeys: CartProductOptionKeyType[]) => {
    const cartItemIds = CartProductOptionTypeUtil.getCartProductOptions(serverCartProductOptions, cartProductOptionKeys)
      .filter((cartProductOption) => cartProductOption.cartItemId !== undefined)
      .map((cartProductOption) => cartProductOption.cartItemId)
    const variables = MUTATION_VARIABLES.DELETE_CART_ITEMS({
      ids: cartItemIds,
    })
    const newServerCartProductOptions = CartProductOptionTypeUtil.removeCartProductOptions(
      serverCartProductOptions,
      cartProductOptionKeys
    )
    await deleteCartItemsMutation({ variables }).then(() => {
      refetchCountCart()
    })
    _setServerCartProductOptions(newServerCartProductOptions)
  }

  const updateCartProductOption = async (
    cartProductOptionKey: CartProductOptionKeyType,
    cartProductOption: CartProductOptionType,
    availableMaxPurchaseQuantity?: number
  ) => {
    const _serverCartProductOptions: CartProductOptionType[] = deepcopy(serverCartProductOptions)
    const mergeCartProductOption = CartProductOptionTypeUtil.getCartProductOption(
      _serverCartProductOptions,
      CartProductOptionTypeUtil.getCartProductOptionKey(cartProductOption)
    )
    let cartProductOptionForUpdate = CartProductOptionTypeUtil.getCartProductOption(
      _serverCartProductOptions,
      cartProductOptionKey
    )

    if (mergeCartProductOption && cartProductOptionForUpdate) {
      const oldCartProductOptionQuantity = cartProductOptionForUpdate.purchaseQuantity
      cartProductOptionForUpdate = mergeCartProductOption
      const mergeQuantity = oldCartProductOptionQuantity + cartProductOptionForUpdate.purchaseQuantity
      // 최대 구매 가능 수량이 있어햐 함 - sku 필요
      if (availableMaxPurchaseQuantity !== undefined) {
        const adjustAbleMaxQuantity =
          mergeQuantity > availableMaxPurchaseQuantity ? availableMaxPurchaseQuantity : mergeQuantity
        cartProductOptionForUpdate.purchaseQuantity =
          adjustAbleMaxQuantity > POLICY.MAX_PURCHASE_QUANTITY_AT_ONCE
            ? POLICY.MAX_PURCHASE_QUANTITY_AT_ONCE
            : adjustAbleMaxQuantity
      } else {
        cartProductOptionForUpdate.purchaseQuantity =
          mergeQuantity > POLICY.MAX_PURCHASE_QUANTITY_AT_ONCE ? POLICY.MAX_PURCHASE_QUANTITY_AT_ONCE : mergeQuantity
      }
      await removeCartProductOption(cartProductOptionKey)
    }

    if (cartProductOptionForUpdate) {
      const variables = MUTATION_VARIABLES.UPDATE_CART_ITEM({
        id: cartProductOptionForUpdate.cartItemId,
        quantity: cartProductOptionForUpdate.purchaseQuantity,
        productOption: cartProductOption.productOptionId,
      })
      CartProductOptionTypeUtil.setCartProductOption(cartProductOptionForUpdate, cartProductOption)
      setServerCartProductOptions(_serverCartProductOptions)
      await updateCartItemMutation({ variables }).then(() => {
        refetchCountCart()
      })
    }
  }

  const updateCartProductOptionPurchaseQuantity = async (
    cartProductOptionKey: CartProductOptionKeyType,
    purchaseQuantity: number
  ) => {
    const _serverCartProductOptions: CartProductOptionType[] = deepcopy(serverCartProductOptions)
    const cartProductOptionForUpdate = CartProductOptionTypeUtil.getCartProductOption(
      _serverCartProductOptions,
      cartProductOptionKey
    )
    if (cartProductOptionForUpdate) {
      cartProductOptionForUpdate.purchaseQuantity = purchaseQuantity
      const variables = MUTATION_VARIABLES.UPDATE_CART_ITEM({
        id: cartProductOptionForUpdate.cartItemId,
        quantity: cartProductOptionForUpdate.purchaseQuantity,
        productOption: cartProductOptionForUpdate.productOptionId,
      })
      await updateCartItemMutation({ variables }).then(() => {
        refetchCountCart()
      })
      _setServerCartProductOptions(_serverCartProductOptions)
    }
  }

  return {
    refreshCartProductOptionsCount,
    refreshCartProductOptions,
    addOrUpdateCartProductOptions,
    mergeCartProductOptionsWithApiQuery,
    removeCartProductOption,
    removeCartProductOptions,
    updateCartProductOption,
    updateCartProductOptionPurchaseQuantity,
  }
}
