const three = 3
const four = 4

/** 스크롤 버튼이 보이기위한 최소 상품개수 */
export enum MinCountForScrollContainerEnum {
  /** 유사 상품추천 */
  RecommendSimilarProduct = three,
  /** 메인홈 섹션의 상품 */
  MainHomeSectionProduct = three,
  /** 성분기반 추천상품 */
  IngredientProduct = three,
  /** 묶음 상품 */
  BundledProduct = three,
  /** 최근 본 상품 */
  RecentlyViewedProduct = three,
  /** 장바구니 상품 리스트 슬라이더 */
  CartProductListSlider = three,
  /** 장바구니 추천상품 */
  CartRecommendedProduct = three,

  /** 연관 추천상품 */
  RecommendRelatedProduct = four,
  /** 멤버십화면에서 유사 상품추천 */
  RecommendSimilarProductMembership = four,
  /** 상품 상세 - 브랜드 추천 상품 리스트 */
  ProductDetailBrandProduct = three,
}
