import { gql } from '@apollo/client'

const COUPON_FRAGMENTS = {
  get couponGroupInCouponList() {
    return gql`
      fragment couponGroupInCouponList on CouponGroupType {
        id
        name
        description
        discountType
        discountRate
        discountAmount
        maxDiscountAmount
        hasMinOrderAmount
        minOrderAmount
        coverageOsType
        coverageType
        canConjunctUse
        couponType
        hasUsingPeriod
        usingPeriod
        usingStartedAt
        usingEndedAt
        canIssue
        issueType
        isMembershipCoupon
      }
    `
  },
  get couponGroupIncludeList() {
    return gql`
      fragment couponGroupIncludeList on CouponGroupType {
        id
        includedBrands {
          totalCount
          edges {
            node {
              name
            }
          }
        }
        includedCategories {
          totalCount
          edges {
            node {
              name
            }
          }
        }
        includedProducts {
          totalCount
          edges {
            node {
              name
            }
          }
        }
      }
    `
  },
}

export default COUPON_FRAGMENTS
