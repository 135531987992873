import { useRef } from 'react'
import { ApolloError } from '@apollo/client'
import { useDispatch } from 'react-redux'
import { TextLegacy } from '@/components/common'
import { FieldKeysEnum } from '@/constants/field-keys.enum'
import { ERROR_CODE } from '@/constants/legacy/constError'
import ROUTES from '@/constants/legacy/constRoutes'
import { IModelFakeWhoami, SocialServiceUserProfileType } from '@/constants/legacy/constType'
import { QueryStringKeyEnum } from '@/constants/query-string-key.enum'
import { SocialServicesEnum } from '@/constants/social-services.enum'
import { useSignupEventTracker } from '@/containers/event-tracker/signup.event-tracker'
import MUTATION_VARIABLES from '@/containers/gqls/base/mutation_variables'
import { useApiMutation, useApple, useCustomRouter, useMessage, useUserInfo } from '@/containers/hooks'
import { useAfterLogin } from '@/containers/hooks/auth'
import { IModelUser } from '@/containers/models/modelUser'
import { UserContainer } from '@/containers/users/UserContainer'
import { useEmailSigninMutation, useUserSocialInfoQuery } from '@/graphql/generated/hooks'
import { EmailSigninMutation, SocialServiceSocialType } from '@/graphql/generated/schemas'
import { doSetPasswordConfirmed, doSetSignupLinkAccount, doSetUserSocialType } from '@/stores/reduxData'
import { doSetGlobalNoticeModal } from '@/stores/reduxUI'
import { getErrorMessage } from '@/utils/utilApi'
import { createDeviceUuid } from '@/utils/utilCrypto'
import { deepcopy } from '@/utils/utilData'
import {
  localInvitationCode,
  localLoginStoreId,
  localSocialAuthType,
  localTempSocialUserProfile,
} from '@/utils/utilLocalStorage'

const useSocialAuth = () => {
  const linkAccountSocialTypeRef = useRef<SocialServicesEnum>()
  const socialUserProfileRef = useRef<SocialServiceUserProfileType>()
  const dispatch = useDispatch()
  const { show } = useMessage()
  const { getNavigationBackCount } = useApple()
  const { useSaveUserInfo } = useUserInfo
  const { me } = UserContainer.useContainer()

  const { saveToken } = useSaveUserInfo()
  const { refetch: refetchUserSocials } = useUserSocialInfoQuery({
    skip: !me,
    variables: {
      id: me?.id || '',
    },
  })
  const { setIsSocialLoginBeforeSignup, onSocialLoginSuccess, getRedirectUrlAfterSignUp } = useAfterLogin()

  const redirectUrl = getRedirectUrlAfterSignUp()

  const router = useCustomRouter()

  const onLinkApiFailure = (error: ApolloError) => {
    let errorMsg: string = error ? getErrorMessage(error) : '시스템 에러가 발생했습니다.\n나중에 다시 시도해 주세요.'
    dispatch(
      doSetGlobalNoticeModal({
        text: errorMsg,
        buttonType: 'ACTION',
        visible: true,
      })
    )
    localSocialAuthType.remove()
    router.replace(ROUTES.MYPAGE.INFO.SNS_LINK)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onLinkApiSuccess = (data: any | { user: { data: IModelUser } }) => {
    if (!data) {
      return
    }
    if (router.pathname === ROUTES.ACCOUNTS.SIGN_UP) {
      //회원가입 계정 연동
      dispatch(
        doSetGlobalNoticeModal({
          buttonType: 'ACTION',
          okText: '로그인',
          text: `연동이 완료되었습니다!\n다시 로그인해 주세요.`,
          afterClose: () => {
            router.push(ROUTES.ACCOUNTS.LOGIN)
          },
          visible: true,
        })
      )
      dispatch(doSetSignupLinkAccount(undefined))
      return
    }

    // 마이페이지 SNS 계정 연동
    if (me) {
      refetchUserSocials()
    }

    show('계정이 연동되었습니다.')
    if (router.pathname !== ROUTES.MYPAGE.INFO.SNS_LINK) {
      // meaning it's callback + social page
      window.history.go(getNavigationBackCount())
    }
  }

  const [linkAccountByEmailMutation] = useApiMutation('linkAccountByEmail', onLinkApiSuccess, onLinkApiFailure)
  const [linkAccountBySocialMutation] = useApiMutation('linkAccountBySocial', onLinkApiSuccess, onLinkApiFailure)

  const linkEmailAccount = async (input: {
    existedUserId: string
    email: string
    password: string
    userCertificationId: string
  }) => {
    const params = {
      id: input.existedUserId,
      email: input.email,
      password: input.password,
      userCertification: input.userCertificationId,
    }

    const variables = MUTATION_VARIABLES.LINK_ACCOUNT_BY_EMAIL(params)
    linkAccountByEmailMutation({ variables })
  }

  const linkSocialAccount = async (
    userProfile: SocialServiceUserProfileType,
    userId?: string,
    userCertificationId?: string
  ) => {
    linkAccountSocialTypeRef.current = userProfile.socialType

    const params = {
      id: userId || me?.id,
      socialService: {
        uniqueId: userProfile.uniqueId,
        accessToken: userProfile.accessToken,
        refreshToken: userProfile.refreshToken,
        accessTokenTtl: userProfile.accessTokenTtl,
        socialType: userProfile.socialType,
        clientType: 'WEB',
      },
      userCertification: userCertificationId,
    }
    const variables = MUTATION_VARIABLES.LINK_ACCOUNT_BY_SOCIAL(params)
    linkAccountBySocialMutation({ variables })
  }

  const [socialConfirmMutation] = useApiMutation('socialSignin', () => {})
  const confirmSocialAccount = async (userProfile: SocialServiceUserProfileType, nextUrl: string) => {
    const response = await _login(userProfile, socialConfirmMutation)
    if (!response.data.socialSignin) {
      // To Do: Handle Error
      return
    }

    const { token, refreshToken, expiredAt, socialService } = response.data.socialSignin.device

    /**
     * 소셜 로그인 서비스 저장
     */
    dispatch(doSetUserSocialType(socialService.socialType as SocialServiceSocialType))

    saveToken({ token, refreshToken, expiredAt })

    dispatch(doSetPasswordConfirmed(true))

    router.replace(nextUrl)
  }

  const onSocialLoginFailure = (apolloError?: ApolloError) => {
    localTempSocialUserProfile.save(socialUserProfileRef.current!)

    const error = JSON.parse(apolloError?.message || '')
    const errorMsg: string = error?.message || ''
    const errorCode: string = error?.code || ''

    if (errorCode === ERROR_CODE.ERROR_USER_BLOCKED) {
      router
        .replace({
          pathname: ROUTES.ACCOUNTS.LOGIN,
          query: {
            [QueryStringKeyEnum.ReturnUrl]: redirectUrl,
          },
        })
        .then(() => {
          show(
            <TextLegacy color="white" size={{ xs: 14, md: 14 }}>
              {errorMsg.replace(')', ')\n')}
            </TextLegacy>
          )
        })
      return
    }

    setIsSocialLoginBeforeSignup(true)
    router.replace({
      pathname: ROUTES.ACCOUNTS.SIGN_UP,
      query: {
        [QueryStringKeyEnum.ReturnUrl]: redirectUrl,
      },
    })
  }

  const [socialSigninMutation] = useApiMutation('socialSignin', onSocialLoginSuccess, onSocialLoginFailure)

  const socialSignupAndLogin = async (userProfile: SocialServiceUserProfileType) => {
    const response = await _login(userProfile)
    if (!response.data.socialSignin) {
      return
    }

    const { socialService } = response.data.socialSignin.device

    /**
     * 소셜 로그인 서비스 저장
     */
    dispatch(doSetUserSocialType(socialService.socialType as SocialServiceSocialType))
  }

  // 로그인 실패시 회원가입 페이지로 이동
  const _login = async (userProfile: SocialServiceUserProfileType, mutation = socialSigninMutation) => {
    localSocialAuthType.remove()

    socialUserProfileRef.current = userProfile

    const _userProfile = deepcopy(userProfile)
    if (_userProfile.email) {
      delete _userProfile.email
    }
    if (_userProfile.name) {
      delete _userProfile.name
    }

    const params = {
      socialService: _userProfile,
      device: {
        uuid: createDeviceUuid(),
      },
    }
    const variables = MUTATION_VARIABLES.SOCIAL_SIGNIN(params)
    // @ts-ignore
    return await mutation({ variables })
  }

  return {
    linkSocialAccount,
    linkEmailAccount,
    confirmSocialAccount,
    socialSignupAndLogin,
  }
}

const useAuth = () => {
  const { trackCompleteSignUpEvent } = useSignupEventTracker()
  const { initTokenSignIn } = UserContainer.useContainer()
  const { useSaveUserInfo } = useUserInfo
  const { saveTokenNWhoami } = useSaveUserInfo()
  const dispatch = useDispatch()
  const { replace } = useCustomRouter()
  const { onEmailLoginSuccess, getRedirectUrlAfterSignUp, clearRedirectUrl } = useAfterLogin()
  const redirectUrl = getRedirectUrlAfterSignUp()

  // ======================================== Email Signin ========================================
  const [emailSigninMutation] = useEmailSigninMutation()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const emailSignin = async (formValues: any, onLoginFailed: (error: any) => void, storeEmailId: boolean) => {
    const variables = {
      user: { ...formValues },
      device: {
        uuid: createDeviceUuid(),
      },
    }
    const response = await emailSigninMutation({ variables: { input: variables } })

    if (!response.data?.emailSignin || response?.errors) {
      let _error
      try {
        _error = JSON.parse(response.errors?.[0].message || '')
      } catch (e) {
        _error = response.errors?.[0]
      }
      onLoginFailed(_error)
      return
    }

    if (storeEmailId) {
      const { email } = formValues
      localLoginStoreId.save(email)
    }
    await onEmailLoginSuccess(response.data.emailSignin as EmailSigninMutation)

    /**
     * 소셜 로그인 서비스 저장
     */
    dispatch(doSetUserSocialType(SocialServiceSocialType.Email))
  }
  // ======================================== Email Signin ========================================

  // ======================================== Email / Social Signup ========================================
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const _onSignupSuccess = async (data: IModelFakeWhoami | any) => {
    if (!data) {
      return
    }

    // CompleteSignup Event Tracking
    await trackCompleteSignUpEvent(data)

    const fixWhoami = data.user.data
      ? ({
          user: data.user.data,
          device: data.device.data,
        } as unknown as IModelFakeWhoami)
      : (data as IModelFakeWhoami)

    saveTokenNWhoami(fixWhoami)
    initTokenSignIn()
    localTempSocialUserProfile.remove()
    localInvitationCode.remove()

    clearRedirectUrl()
    replace({
      pathname: ROUTES.ACCOUNTS.WELCOME,
      query: {
        [QueryStringKeyEnum.ReturnUrl]: redirectUrl,
      },
    })
  }

  const _onSignupFailure = (error?: ApolloError) => {
    let errorMsg: string = error ? getErrorMessage(error) : '시스템 에러가 발생했습니다.\n나중에 다시 시도해 주세요.'

    dispatch(
      doSetGlobalNoticeModal({
        text: errorMsg,
        buttonType: 'ACTION',
        visible: true,
      })
    )
  }

  const [socialSignupMutation] = useApiMutation('socialSignup', _onSignupSuccess, _onSignupFailure)
  const [emailSignupMutation] = useApiMutation('emailSignup', _onSignupSuccess, _onSignupFailure)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const socialSignup = (formValues: any, userSocialProfile: SocialServiceUserProfileType) => {
    delete formValues[FieldKeysEnum.ConfirmPassword]

    const params = {
      user: formValues,
      device: {
        uuid: createDeviceUuid(),
      },
      [FieldKeysEnum.SocialService]: userSocialProfile,
    }

    if (userSocialProfile.email) {
      delete userSocialProfile.email
    }
    if (userSocialProfile.name) {
      delete userSocialProfile.name
    }

    const variables = MUTATION_VARIABLES.SOCIAL_SIGNUP(params)
    // @ts-ignore
    socialSignupMutation({ variables })
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const emailSignup = (formValues: any) => {
    delete formValues[FieldKeysEnum.ConfirmPassword]
    const params = {
      user: formValues,
      device: {
        uuid: createDeviceUuid(),
      },
    }

    const variables = MUTATION_VARIABLES.EMAIL_SIGNUP(params)
    // @ts-ignore
    emailSignupMutation({ variables })
  }

  // ======================================== Email / Social Signup ========================================

  return { emailSignin, socialSignup, emailSignup }
}

const useAccountAuth = {
  useSocialAuth,
  useAuth,
}

export default useAccountAuth
