import styled, { useTheme } from 'styled-components'
import FlatIcon from '@/components/common/icons/FlatIcon'
import { Image } from '@/components/common/images/Image'
import { Typo, TypoVariant } from '@/components/common/typography'
import { IconNameEnum } from '@/constants/icon-name.enum'
import { ABTestGroupCase, useAbGroup } from '@/containers/hooks/useAbGroup'

export const CartConfirmationTop = ({ imageUrl, onClickLink }: { imageUrl: string; onClickLink: () => void }) => {
  const { color } = useTheme()
  const { getAbByKey } = useAbGroup()
  const { abCode } = getAbByKey({
    abKey: ABTestGroupCase.BOTTOM_SHEET_RECOMMENDATION_SLOT_IMPROVEMENT_ON_ADD_TO_CART,
  })
  const isNewCartBottomSheet = abCode !== 'A'

  const handleClickLink = () => {
    onClickLink()
  }

  return (
    <StyledCartConfirmationTopWrapper className="cart-confirmation-top" isNewCartBottomSheet={isNewCartBottomSheet}>
      <div className="cart-confirmation-top__image-wrapper">
        <Image src={imageUrl || ''} alt="장바구니 담긴 상품 썸네일 이미지" />
      </div>
      <div className="cart-confirmation-top__message">
        <Typo variant={TypoVariant.Body2Regular} color={color.gray900}>
          장바구니에 상품을 담았어요
        </Typo>
      </div>
      <button type="button" className="cart-confirmation-top__link" onClick={handleClickLink}>
        <Typo variant={TypoVariant.Body3Regular} color={color.blue500}>
          보러 가기
        </Typo>
        <FlatIcon type={IconNameEnum.IcArrowRight} color={color.blue500} size="1.6rem" />
      </button>
    </StyledCartConfirmationTopWrapper>
  )
}

const StyledCartConfirmationTopWrapper = styled.div<{ isNewCartBottomSheet: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;

  .cart-confirmation-top {
    &__image-wrapper {
      position: relative;
      width: ${({ isNewCartBottomSheet }) => (isNewCartBottomSheet ? '4rem' : '5.2rem')};
      height: ${({ isNewCartBottomSheet }) => (isNewCartBottomSheet ? '4rem' : '5.2rem')};
      border-radius: 0.4rem;
      overflow: hidden;
    }
    &__image {
      width: 100%;
    }
    &__message {
      margin-left: 1.2rem;
    }
    &__link {
      padding: 0;
      cursor: pointer;
      border: none;
      background-color: transparent;
      outline: none;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.2rem;
      margin-left: auto;
    }
  }
`
