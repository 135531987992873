import { ProductRecommendIngredientEnum } from '@/constants/product-recommend-ingredient.enum'
import { DataBase } from '@/containers/gqls/data'
import { ModelBase } from '@/containers/models'
import {
  IModelAdDefault,
  IModelAdImageDefault,
  IModelAsInfoDefault,
  IModelBankDefault,
  IModelBannerDefault,
  IModelBannerImageDefault,
  IModelBannerInfoDefault,
  IModelBillingItemDefault,
  IModelBrandDefault,
  IModelCancelTransactionDefault,
  IModelCartItemDefault,
  IModelCartItemHistoryDefault,
  IModelCategoryDefault,
  IModelCouponDefault,
  IModelCouponGroupDefault,
  IModelCoverflowBannerInfoDefault,
  IModelCustomerAddressDefault,
  IModelCustomerGradeDefault,
  IModelCustomerTemplateAddressDefault,
  IModelDesignersBannerInfoDefault,
  IModelDeviceDefault,
  IModelDisplayCollectionDefault,
  IModelDisplayProductInfoDefault,
  IModelExcelExportRequestDefault,
  IModelFaqDefault,
  IModelGnbDefault,
  IModelInactiveUserDefault,
  IModelInfluencerDefault,
  IModelInformKakaoQueueDefault,
  IModelInformPushQueueDefault,
  IModelInitDataDefault,
  IModelMileageDefault,
  IModelMileageHistoryDefault,
  IModelMileageRewardPolicyDefault,
  IModelNoticeDefault,
  IModelOrderDefault,
  IModelOrderItemAdminNoteDefault,
  IModelOrderItemDefault,
  IModelOrderRefundInfoDefault,
  IModelPopupBannerInfoDefault,
  IModelProductCategoryDefault,
  IModelProductDefault,
  IModelProductImageDefault,
  IModelProductOptionDefault,
  IModelProductOptionKindDefault,
  IModelProductOptionSkuDefault,
  IModelProductOptionValueDefault,
  IModelProductPromotionDefault,
  IModelProductQnaDefault,
  IModelPushUserHistoryDefault,
  IModelRefundInfoDefault,
  IModelRefundShippingInfoDefault,
  IModelRestockProductOptionUserDefault,
  IModelReturnInfoDefault,
  IModelReturnInfoImageDefault,
  IModelReviewDefault,
  IModelReviewGroupDefault,
  IModelReviewImageDefault,
  IModelSellerAddressDefault,
  IModelSellerBillingDefault,
  IModelSellerDefault,
  IModelSellerTemplateAddressDefault,
  IModelSellerUserDefault,
  IModelServiceQnaDefault,
  IModelShippingCompanyDefault,
  IModelShippingDefault,
  IModelShippingPresetDefault,
  IModelSkuDefault,
  IModelSkuHistoryDefault,
  IModelSlimBannerInfoDefault,
  IModelSocialServiceDefault,
  IModelStatusDefault,
  IModelStatusHistoryDefault,
  IModelSubRollBannerInfoDefault,
  IModelTopRollBannerInfoDefault,
  IModelTransactionDefault,
  IModelUpdatedBrandDefault,
  IModelUpdatedProductDefault,
  IModelUpdatedSellerDefault,
  IModelUserCertificationDefault,
  IModelUserDefault,
  IModelUserInfoAccountDefault,
  IModelUserInfoDefault,
  IModelUserSubscriptionDefault,
  IModelWeeklyBestBannerInfoDefault,
} from '@/containers/models/base/defaultModelInterfaces'

export class ModelAdDefault extends ModelBase {
  displayOrder = 0
  startedAt = null
  endedAt = null
  petType = ''
  createdAt = null
  updatedAt = null
  name = ''
  description = ''
  slug = ''
  adType = ''
  isShown = false
  style = ''
  adCouponGroups: IModelCouponGroupDefault[] = [] as IModelCouponGroupDefault[]
  adImages: IModelAdImageDefault[] = [] as IModelAdImageDefault[]
  adDisplayCollections: IModelDisplayCollectionDefault[] = [] as IModelDisplayCollectionDefault[]
  adGnbs: IModelGnbDefault[] = [] as IModelGnbDefault[]
  mainImage: IModelAdImageDefault = {} as IModelAdImageDefault
  isFinished = false
  isCouponDownloaded = false
  couponGroups: IModelCouponGroupDefault[] = [] as IModelCouponGroupDefault[]
  constructor(data: IModelAdDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.adCouponGroups = DataBase.getListModel(this.adCouponGroups)
    this.adImages = DataBase.getListModel(this.adImages)
    this.adDisplayCollections = DataBase.getListModel(this.adDisplayCollections)
    this.adGnbs = DataBase.getListModel(this.adGnbs)
    this.mainImage = DataBase.getModel(this.mainImage)
    this.couponGroups = DataBase.getListModel(this.couponGroups)
  }
}

export class ModelBankDefault extends ModelBase {
  name = ''
  code = ''
  constructor(data: IModelBankDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)
  }
}

export class ModelBannerImageDefault extends ModelBase {
  image = ''
  index = ''
  banner: IModelBannerDefault = {} as IModelBannerDefault
  displayDevice = ''
  link = ''
  constructor(data: IModelBannerImageDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.banner = DataBase.getModel(this.banner)
  }
}

export class ModelBannerDefault extends ModelBase {
  displayOrder = 0
  petType = ''
  createdAt = null
  updatedAt = null
  isAlways = false
  startedAt = null
  endedAt = null
  name = ''
  bannerType = ''
  isPluralDisplay = false
  allowDisplay = false
  displayDevice = ''
  bannerTypeInfo = ''
  style = ''
  bannerImages: IModelBannerImageDefault[] = [] as IModelBannerImageDefault[]
  topSlimBannerInfo: IModelSlimBannerInfoDefault = {} as IModelSlimBannerInfoDefault
  slimBannerInfo: IModelSlimBannerInfoDefault = {} as IModelSlimBannerInfoDefault
  popupBannerInfo: IModelPopupBannerInfoDefault = {} as IModelPopupBannerInfoDefault
  topRollBannerInfo: IModelTopRollBannerInfoDefault = {} as IModelTopRollBannerInfoDefault
  designersBannerInfo: IModelDesignersBannerInfoDefault = {} as IModelDesignersBannerInfoDefault
  weeklyBestBannerInfo: IModelWeeklyBestBannerInfoDefault = {} as IModelWeeklyBestBannerInfoDefault
  coverflowBannerInfo: IModelCoverflowBannerInfoDefault = {} as IModelCoverflowBannerInfoDefault
  brandRollBannerInfo: IModelSubRollBannerInfoDefault = {} as IModelSubRollBannerInfoDefault
  apparelCoverflowBannerInfo: IModelCoverflowBannerInfoDefault = {} as IModelCoverflowBannerInfoDefault
  apparelRollBannerInfo: IModelSubRollBannerInfoDefault = {} as IModelSubRollBannerInfoDefault
  accessoriesCoverflowBannerInfo: IModelCoverflowBannerInfoDefault = {} as IModelCoverflowBannerInfoDefault
  accessoriesRollBannerInfo: IModelSubRollBannerInfoDefault = {} as IModelSubRollBannerInfoDefault
  homeMiddleBannerInfo: IModelBannerInfoDefault = {} as IModelBannerInfoDefault
  homeBottomBannerInfo: IModelBannerInfoDefault = {} as IModelBannerInfoDefault
  productTopBannerInfo: IModelBannerInfoDefault = {} as IModelBannerInfoDefault
  constructor(data: IModelBannerDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.bannerImages = DataBase.getListModel(this.bannerImages)
    this.topSlimBannerInfo = DataBase.getModel(this.topSlimBannerInfo)
    this.slimBannerInfo = DataBase.getModel(this.slimBannerInfo)
    this.popupBannerInfo = DataBase.getModel(this.popupBannerInfo)
    this.topRollBannerInfo = DataBase.getModel(this.topRollBannerInfo)
    this.designersBannerInfo = DataBase.getModel(this.designersBannerInfo)
    this.weeklyBestBannerInfo = DataBase.getModel(this.weeklyBestBannerInfo)
    this.coverflowBannerInfo = DataBase.getModel(this.coverflowBannerInfo)
    this.brandRollBannerInfo = DataBase.getModel(this.brandRollBannerInfo)
    this.apparelCoverflowBannerInfo = DataBase.getModel(this.apparelCoverflowBannerInfo)
    this.apparelRollBannerInfo = DataBase.getModel(this.apparelRollBannerInfo)
    this.accessoriesCoverflowBannerInfo = DataBase.getModel(this.accessoriesCoverflowBannerInfo)
    this.accessoriesRollBannerInfo = DataBase.getModel(this.accessoriesRollBannerInfo)
    this.homeMiddleBannerInfo = DataBase.getModel(this.homeMiddleBannerInfo)
    this.homeBottomBannerInfo = DataBase.getModel(this.homeBottomBannerInfo)
    this.productTopBannerInfo = DataBase.getModel(this.productTopBannerInfo)
  }
}

export class ModelBrandDefault extends ModelBase {
  displayOrder = 0
  petType = ''
  createdAt = null
  updatedAt = null
  sellers: IModelSellerDefault[] = [] as IModelSellerDefault[]
  code = ''
  name = ''
  requestedAt = null
  approvedAt = null
  updateApprovedAt = null
  status: IModelStatusDefault = {} as IModelStatusDefault
  updateInfo: IModelUpdatedBrandDefault = {} as IModelUpdatedBrandDefault
  updateStatus: IModelStatusDefault = {} as IModelStatusDefault
  listImage = ''
  backgroundImage = ''
  wishedBrandUsers: IModelUserDefault[] = [] as IModelUserDefault[]
  brandProducts: IModelProductDefault[] = [] as IModelProductDefault[]
  includedBrandsCouponGroups: IModelCouponGroupDefault[] = [] as IModelCouponGroupDefault[]
  excludedBrandsCouponGroups: IModelCouponGroupDefault[] = [] as IModelCouponGroupDefault[]
  brandOrderItems: IModelOrderItemDefault[] = [] as IModelOrderItemDefault[]
  brandBillings: IModelSellerBillingDefault[] = [] as IModelSellerBillingDefault[]
  wishedCount = 0
  isWished = false
  constructor(data: IModelBrandDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.sellers = DataBase.getListModel(this.sellers)
    this.status = DataBase.getModel(this.status)
    this.updateInfo = DataBase.getModel(this.updateInfo)
    this.updateStatus = DataBase.getModel(this.updateStatus)
    this.wishedBrandUsers = DataBase.getListModel(this.wishedBrandUsers)
    this.brandProducts = DataBase.getListModel(this.brandProducts)
    this.includedBrandsCouponGroups = DataBase.getListModel(this.includedBrandsCouponGroups)
    this.excludedBrandsCouponGroups = DataBase.getListModel(this.excludedBrandsCouponGroups)
    this.brandOrderItems = DataBase.getListModel(this.brandOrderItems)
    this.brandBillings = DataBase.getListModel(this.brandBillings)
  }
}

export class ModelCartItemDefault extends ModelBase {
  createdAt = null
  updatedAt = null
  quantity = 0
  productOption: IModelProductOptionDefault = {} as IModelProductOptionDefault
  user: IModelUserDefault = {} as IModelUserDefault
  productPromotion: IModelProductPromotionDefault = {} as IModelProductPromotionDefault
  constructor(data: IModelCartItemDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.productOption = DataBase.getModel(this.productOption)
    this.user = DataBase.getModel(this.user)
    this.productPromotion = DataBase.getModel(this.productPromotion)
  }
}

export class ModelCategoryDefault extends ModelBase {
  displayOrder = 0
  parentCategory: IModelCategoryDefault = {} as IModelCategoryDefault
  name = ''
  code = ''
  text = ''
  isVisible = false
  description = ''
  listImage = ''
  categoryChildren: IModelCategoryDefault[] = [] as IModelCategoryDefault[]
  categoryProductCategories: IModelProductCategoryDefault[] = [] as IModelProductCategoryDefault[]
  products: IModelProductDefault[] = [] as IModelProductDefault[]
  includedCategoriesCouponGroups: IModelCouponGroupDefault[] = [] as IModelCouponGroupDefault[]
  excludedCategoriesCouponGroups: IModelCouponGroupDefault[] = [] as IModelCouponGroupDefault[]
  categoryGnbs: IModelGnbDefault[] = [] as IModelGnbDefault[]
  coupons: IModelCouponDefault[] = [] as IModelCouponDefault[]
  includedBrands: IModelBrandDefault[] = [] as IModelBrandDefault[]
  subProductCount = 0
  subCategories: IModelCategoryDefault[] = [] as IModelCategoryDefault[]
  constructor(data: IModelCategoryDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.parentCategory = DataBase.getModel(this.parentCategory)
    this.categoryChildren = DataBase.getListModel(this.categoryChildren)
    this.categoryProductCategories = DataBase.getListModel(this.categoryProductCategories)
    this.products = DataBase.getListModel(this.products)
    this.includedCategoriesCouponGroups = DataBase.getListModel(this.includedCategoriesCouponGroups)
    this.excludedCategoriesCouponGroups = DataBase.getListModel(this.excludedCategoriesCouponGroups)
    this.categoryGnbs = DataBase.getListModel(this.categoryGnbs)
    this.coupons = DataBase.getListModel(this.coupons)
    this.includedBrands = DataBase.getListModel(this.includedBrands)
    this.subCategories = DataBase.getListModel(this.subCategories)
  }
}

export class ModelCouponGroupDefault extends ModelBase {
  createdAt = null
  updatedAt = null
  fitpetAdmin: IModelUserDefault = {} as IModelUserDefault
  couponGroupStatus = ''
  eventType = ''
  couponType = ''
  name = ''
  description = ''
  canConjunctUse = false
  hasEventSms = false
  ad: IModelAdDefault = {} as IModelAdDefault
  issueType = ''
  issueCount = 0
  maxIssueCount = 0
  maxDuplicateIssueCount = 0
  issueStartedAt = null
  issueEndedAt = null
  hasDuplicateIssueFirst = false
  usingPeriod = 0
  usingStartedAt = null
  usingEndedAt = null
  expiredAlertDay = 0
  discountType = ''
  discountRate = 0
  discountAmount = 0
  minOrderAmount = 0
  maxDiscountAmount = 0
  coverageType = ''
  coverageOsType = ''
  includedBrands: IModelBrandDefault[] = [] as IModelBrandDefault[]
  includedProducts: IModelProductDefault[] = [] as IModelProductDefault[]
  includedCategories: IModelCategoryDefault[] = [] as IModelCategoryDefault[]
  excludedBrands: IModelBrandDefault[] = [] as IModelBrandDefault[]
  excludedProducts: IModelProductDefault[] = [] as IModelProductDefault[]
  excludedCategories: IModelCategoryDefault[] = [] as IModelCategoryDefault[]
  code = ''
  informkakaoqueue: IModelInformKakaoQueueDefault = {} as IModelInformKakaoQueueDefault
  couponGroupCoupons: IModelCouponDefault[] = [] as IModelCouponDefault[]
  couponGroupCustomerGrades: IModelCustomerGradeDefault[] = [] as IModelCustomerGradeDefault[]
  issueStatus = ''
  eventTypeDisplay = ''
  isDeletable = false
  hasMaxIssueCount = false
  hasUsingPeriod = false
  hasExpiredAlert = false
  hasMinOrderAmount = false
  coverageExcludeType = ''
  informKakaoQueue: IModelInformKakaoQueueDefault = {} as IModelInformKakaoQueueDefault
  canIssue = false
  constructor(data: IModelCouponGroupDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.fitpetAdmin = DataBase.getModel(this.fitpetAdmin)
    this.ad = DataBase.getModel(this.ad)
    this.includedBrands = DataBase.getListModel(this.includedBrands)
    this.includedProducts = DataBase.getListModel(this.includedProducts)
    this.includedCategories = DataBase.getListModel(this.includedCategories)
    this.excludedBrands = DataBase.getListModel(this.excludedBrands)
    this.excludedProducts = DataBase.getListModel(this.excludedProducts)
    this.excludedCategories = DataBase.getListModel(this.excludedCategories)
    this.informkakaoqueue = DataBase.getModel(this.informkakaoqueue)
    this.couponGroupCoupons = DataBase.getListModel(this.couponGroupCoupons)
    this.couponGroupCustomerGrades = DataBase.getListModel(this.couponGroupCustomerGrades)
    this.informKakaoQueue = DataBase.getModel(this.informKakaoQueue)
  }
}

export class ModelCouponDefault extends ModelBase {
  createdAt = null
  updatedAt = null
  couponGroup: IModelCouponGroupDefault = {} as IModelCouponGroupDefault
  code = ''
  user: IModelUserDefault = {} as IModelUserDefault
  registeredAt = null
  startedAt = null
  expiredAt = null
  usedAt = null
  fitpetAdmin: IModelUserDefault = {} as IModelUserDefault
  message = ''
  order: IModelOrderDefault = {} as IModelOrderDefault
  orderItem: IModelOrderItemDefault = {} as IModelOrderItemDefault
  shipping: IModelShippingDefault = {} as IModelShippingDefault
  usedOrderItem: IModelOrderItemDefault = {} as IModelOrderItemDefault
  usedStatus = ''
  issuedFrom = ''
  issueCount = 0
  isMembershipCoupon = false
  constructor(data: IModelCouponDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.couponGroup = DataBase.getModel(this.couponGroup)
    this.user = DataBase.getModel(this.user)
    this.fitpetAdmin = DataBase.getModel(this.fitpetAdmin)
    this.order = DataBase.getModel(this.order)
    this.orderItem = DataBase.getModel(this.orderItem)
    this.shipping = DataBase.getModel(this.shipping)
    this.usedOrderItem = DataBase.getModel(this.usedOrderItem)
  }
}

export class ModelCustomerAddressDefault extends ModelBase {
  postNumber = ''
  basicAddress = ''
  detailedAddress = ''
  name = ''
  mobileNumber = ''
  message = ''
  order: IModelOrderDefault = {} as IModelOrderDefault
  returninfo: IModelReturnInfoDefault = {} as IModelReturnInfoDefault
  constructor(data: IModelCustomerAddressDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.order = DataBase.getModel(this.order)
    this.returninfo = DataBase.getModel(this.returninfo)
  }
}

export class ModelCustomerGradeDefault extends ModelBase {
  createdAt = null
  updatedAt = null
  series = 0
  name = ''
  minOrderAmount = 0
  maxOrderAmount = 0
  minOrderCount = 0
  maxOrderCount = 0
  rewardRate = 0
  couponGroups: IModelCouponGroupDefault[] = [] as IModelCouponGroupDefault[]
  isActivated = false
  fitpetAdmin: IModelUserDefault = {} as IModelUserDefault
  gradeType = ''
  code = ''
  gradeUsers: IModelUserDefault[] = [] as IModelUserDefault[]
  userCount = 0
  conditionText = ''
  pushUserCount = 0
  pushMarketingUserCount = 0
  constructor(data: IModelCustomerGradeDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.couponGroups = DataBase.getListModel(this.couponGroups)
    this.fitpetAdmin = DataBase.getModel(this.fitpetAdmin)
    this.gradeUsers = DataBase.getListModel(this.gradeUsers)
  }
}

export class ModelCustomerTemplateAddressDefault extends ModelBase {
  postNumber = ''
  basicAddress = ''
  detailedAddress = ''
  isDefault = false
  templateName = ''
  name = ''
  mobileNumber = ''
  user: IModelUserDefault = {} as IModelUserDefault
  message = ''
  constructor(data: IModelCustomerTemplateAddressDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.user = DataBase.getModel(this.user)
  }
}

export class ModelDeviceDefault extends ModelBase {
  user: IModelUserDefault = {} as IModelUserDefault
  uuid = ''
  token = ''
  refreshToken = ''
  pushToken = ''
  platformType = ''
  osVersion = ''
  modelNumber = ''
  appVersion = ''
  lastLoggedAt = null
  keepSignIn = false
  socialService: IModelSocialServiceDefault = {} as IModelSocialServiceDefault
  deviceOrders: IModelOrderDefault[] = [] as IModelOrderDefault[]
  expiredAt = null
  constructor(data: IModelDeviceDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.user = DataBase.getModel(this.user)
    this.socialService = DataBase.getModel(this.socialService)
    this.deviceOrders = DataBase.getListModel(this.deviceOrders)
  }
}

export class ModelDisplayCollectionDefault extends ModelBase {
  displayOrder = 0
  startedAt = null
  endedAt = null
  petType = ''
  createdAt = null
  updatedAt = null
  name = ''
  ad: IModelAdDefault = {} as IModelAdDefault
  collectionType = ''
  isShown = false
  displayCollectionDisplayProductInfos: IModelDisplayProductInfoDefault[] = [] as IModelDisplayProductInfoDefault[]
  displayCollectionGnbs: IModelGnbDefault[] = [] as IModelGnbDefault[]
  displayProductInfos: IModelDisplayProductInfoDefault[] = [] as IModelDisplayProductInfoDefault[]
  constructor(data: IModelDisplayCollectionDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.ad = DataBase.getModel(this.ad)
    this.displayCollectionDisplayProductInfos = DataBase.getListModel(this.displayCollectionDisplayProductInfos)
    this.displayCollectionGnbs = DataBase.getListModel(this.displayCollectionGnbs)
    this.displayProductInfos = DataBase.getListModel(this.displayProductInfos)
  }
}

export class ModelDisplayProductInfoDefault extends ModelBase {
  displayOrder = 0
  startedAt = null
  endedAt = null
  product: IModelProductDefault = {} as IModelProductDefault
  displayCollection: IModelDisplayCollectionDefault = {} as IModelDisplayCollectionDefault
  productpromotion: IModelProductPromotionDefault = {} as IModelProductPromotionDefault
  constructor(data: IModelDisplayProductInfoDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.product = DataBase.getModel(this.product)
    this.displayCollection = DataBase.getModel(this.displayCollection)
    this.productpromotion = DataBase.getModel(this.productpromotion)
  }
}

export class ModelInitDataDefault extends ModelBase {
  banks: IModelBankDefault[] = [] as IModelBankDefault[]
  shippingCompanies: IModelShippingCompanyDefault[] = [] as IModelShippingCompanyDefault[]
  customerGrades: IModelCustomerGradeDefault[] = [] as IModelCustomerGradeDefault[]
  mileageRewardPolicy: IModelMileageRewardPolicyDefault = {} as IModelMileageRewardPolicyDefault
  constructor(data: IModelInitDataDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.banks = DataBase.getListModel(this.banks)
    this.shippingCompanies = DataBase.getListModel(this.shippingCompanies)
    this.customerGrades = DataBase.getListModel(this.customerGrades)
    this.mileageRewardPolicy = DataBase.getModel(this.mileageRewardPolicy)
  }
}

export class ModelMileageHistoryDefault extends ModelBase {
  createdAt = null
  updatedAt = null
  mileage: IModelMileageDefault = {} as IModelMileageDefault
  mileageType = ''
  order: IModelOrderDefault = {} as IModelOrderDefault
  orderItem: IModelOrderItemDefault = {} as IModelOrderItemDefault
  review: IModelReviewDefault = {} as IModelReviewDefault
  shipping: IModelShippingDefault = {} as IModelShippingDefault
  returnInfo: IModelReturnInfoDefault = {} as IModelReturnInfoDefault
  message = ''
  subMessage = ''
  beforeMileage = 0
  afterMileage = 0
  fitpetAdmin: IModelUserDefault = {} as IModelUserDefault
  mileageDiff = 0
  expiredAt = null
  constructor(data: IModelMileageHistoryDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.mileage = DataBase.getModel(this.mileage)
    this.order = DataBase.getModel(this.order)
    this.orderItem = DataBase.getModel(this.orderItem)
    this.review = DataBase.getModel(this.review)
    this.shipping = DataBase.getModel(this.shipping)
    this.returnInfo = DataBase.getModel(this.returnInfo)
    this.fitpetAdmin = DataBase.getModel(this.fitpetAdmin)
  }
}

export class ModelMileageDefault extends ModelBase {
  point = 0
  user: IModelUserDefault = {} as IModelUserDefault
  mileageMileageHistories: IModelMileageHistoryDefault[] = [] as IModelMileageHistoryDefault[]
  constructor(data: IModelMileageDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.user = DataBase.getModel(this.user)
    this.mileageMileageHistories = DataBase.getListModel(this.mileageMileageHistories)
  }
}

export class ModelOrderItemDefault extends ModelBase {
  createdAt = null
  updatedAt = null
  quantity = 0
  originalPrice = 0
  discountPrice = 0
  productPromotionDiscountPrice = 0
  couponDiscountPrice = 0
  orderCouponDiscountPrice = 0
  mileageDiscountPrice = 0
  fitpetFeeRate = 0
  mileageRewardRate = 0
  orderItemNumber = ''
  productName = ''
  orderStatus = ''
  order: IModelOrderDefault = {} as IModelOrderDefault
  seller: IModelSellerDefault = {} as IModelSellerDefault
  brand: IModelBrandDefault = {} as IModelBrandDefault
  productOption: IModelProductOptionDefault = {} as IModelProductOptionDefault
  shipping: IModelShippingDefault = {} as IModelShippingDefault
  productPromotion: IModelProductPromotionDefault = {} as IModelProductPromotionDefault
  exchangedOrderItem: IModelOrderItemDefault = {} as IModelOrderItemDefault
  couponInfo = ''
  checkedAt = null
  sentAt = null
  receivedAt = null
  confirmedAt = null
  cancelResponsibility = ''
  cancelUser = ''
  cancelRequestType = ''
  cancelRequestMessage = ''
  cancelRejectMessage = ''
  cancelGuideMessage = ''
  cancelRequestedAt = null
  cancelRejectedAt = null
  canceledAt = null
  refundInfo: IModelRefundInfoDefault = {} as IModelRefundInfoDefault
  refundShippingInfo: IModelRefundShippingInfoDefault = {} as IModelRefundShippingInfoDefault
  returnInfo: IModelReturnInfoDefault = {} as IModelReturnInfoDefault
  orderItemServiceQnas: IModelServiceQnaDefault[] = [] as IModelServiceQnaDefault[]
  orderItemReviews: IModelReviewDefault[] = [] as IModelReviewDefault[]
  orderItemMileageHistories: IModelMileageHistoryDefault[] = [] as IModelMileageHistoryDefault[]
  orderItemCoupons: IModelCouponDefault[] = [] as IModelCouponDefault[]
  orderItemOrderItemAdminNotes: IModelOrderItemAdminNoteDefault[] = [] as IModelOrderItemAdminNoteDefault[]
  orderitem: IModelOrderItemDefault = {} as IModelOrderItemDefault
  canceltransaction: IModelCancelTransactionDefault = {} as IModelCancelTransactionDefault
  billingitemSet: IModelBillingItemDefault[] = [] as IModelBillingItemDefault[]
  supplyType = ''
  productImage: IModelProductImageDefault = {} as IModelProductImageDefault
  refundAmount = 0
  customerPrice = 0
  originalAmount = 0
  productDiscountPrice = 0
  productDiscountAmount = 0
  customerAmount = 0
  orderStatusText = ''
  productPromotionCustomerPrice = 0
  productPromotionCustomerAmount = 0
  productPromotionCouponCustomerAmount = 0
  productPromotionCustomerPriceExists = 0
  productPromotionCustomerAmountExists = 0
  amountWithoutOrderCoupon = 0
  amount = 0
  returnRequestedAt = null
  returnAcceptedAt = null
  returnReturnedAt = null
  returnConfirmedAt = null
  returnRejectedAt = null
  returnRejectMessage = ''
  returnRequestMessage = ''
  returnAddress: IModelSellerAddressDefault = {} as IModelSellerAddressDefault
  returnResponsibility = ''
  returnGuideMessage = ''
  mileage = 0
  mileageRate = 0
  reviewMileage = 0
  reviewMileageRate = 0
  imageReviewMileage = 0
  imageReviewMileageRate = 0
  orderConfirmMileage = 0
  orderConfirmMileageRate = 0
  returnShippingFee = 0
  exchangeShippingFee = 0
  hasUserReview = false
  vbankName = ''
  vbankAccountHolderName = ''
  vbankAccountNumber = ''
  approvedTransaction: IModelTransactionDefault = {} as IModelTransactionDefault
  approvedCancelTransaction: IModelCancelTransactionDefault = {} as IModelCancelTransactionDefault
  adminNotes: IModelOrderItemAdminNoteDefault[] = [] as IModelOrderItemAdminNoteDefault[]
  availableCoupons: IModelCouponDefault[] = [] as IModelCouponDefault[]
  refundable = false
  reviewWritableDatetime = null
  canPartialCancel = false
  constructor(data: IModelOrderItemDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.order = DataBase.getModel(this.order)
    this.seller = DataBase.getModel(this.seller)
    this.brand = DataBase.getModel(this.brand)
    this.productOption = DataBase.getModel(this.productOption)
    this.shipping = DataBase.getModel(this.shipping)
    this.productPromotion = DataBase.getModel(this.productPromotion)
    this.exchangedOrderItem = DataBase.getModel(this.exchangedOrderItem)
    this.refundInfo = DataBase.getModel(this.refundInfo)
    this.refundShippingInfo = DataBase.getModel(this.refundShippingInfo)
    this.returnInfo = DataBase.getModel(this.returnInfo)
    this.orderItemServiceQnas = DataBase.getListModel(this.orderItemServiceQnas)
    this.orderItemReviews = DataBase.getListModel(this.orderItemReviews)
    this.orderItemMileageHistories = DataBase.getListModel(this.orderItemMileageHistories)
    this.orderItemCoupons = DataBase.getListModel(this.orderItemCoupons)
    this.orderItemOrderItemAdminNotes = DataBase.getListModel(this.orderItemOrderItemAdminNotes)
    this.orderitem = DataBase.getModel(this.orderitem)
    this.canceltransaction = DataBase.getModel(this.canceltransaction)
    this.billingitemSet = DataBase.getListModel(this.billingitemSet)
    this.productImage = DataBase.getModel(this.productImage)
    this.returnAddress = DataBase.getModel(this.returnAddress)
    this.approvedTransaction = DataBase.getModel(this.approvedTransaction)
    this.approvedCancelTransaction = DataBase.getModel(this.approvedCancelTransaction)
    this.adminNotes = DataBase.getListModel(this.adminNotes)
    this.availableCoupons = DataBase.getListModel(this.availableCoupons)
  }
}

export class ModelOrderRefundInfoDefault extends ModelBase {
  originalAmount = 0
  productDiscountAmount = 0
  productPromotionDiscountAmount = 0
  couponDiscountAmount = 0
  mileageDiscountAmount = 0
  orderCouponDiscountAmount = 0
  soldAmount = 0
  totalRefundAmount = 0
  returnShippingFeeAmount = 0
  refundShippingFeeAmount = 0
  constructor(data: IModelOrderRefundInfoDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)
  }
}

export class ModelOrderDefault extends ModelBase {
  createdAt = null
  updatedAt = null
  orderNumber = ''
  user: IModelUserDefault = {} as IModelUserDefault
  device: IModelDeviceDefault = {} as IModelDeviceDefault
  customerAddress: IModelCustomerAddressDefault = {} as IModelCustomerAddressDefault
  approvedTransaction: IModelTransactionDefault = {} as IModelTransactionDefault
  originalAmount = 0
  productDiscountAmount = 0
  productPromotionDiscountAmount = 0
  couponDiscountAmount = 0
  orderCouponDiscountAmount = 0
  mileageDiscountAmount = 0
  shippingFees = 0
  shippingExtraFees = 0
  shippingDiscountAmount = 0
  shippingMileageDiscountAmount = 0
  shippingCouponDiscountAmount = 0
  couponInfo = ''
  orderMileageHistories: IModelMileageHistoryDefault[] = [] as IModelMileageHistoryDefault[]
  orderCoupons: IModelCouponDefault[] = [] as IModelCouponDefault[]
  orderOrderItems: IModelOrderItemDefault[] = [] as IModelOrderItemDefault[]
  orderTransactions: IModelTransactionDefault[] = [] as IModelTransactionDefault[]
  orderCancelTransactions: IModelCancelTransactionDefault[] = [] as IModelCancelTransactionDefault[]
  productPromotionCustomerAmount = 0
  amount = 0
  shippingAmount = 0
  mileage = 0
  imageReviewMileage = 0
  reviewMileage = 0
  orderConfirmMileage = 0
  orderRefundInfo: IModelOrderRefundInfoDefault = {} as IModelOrderRefundInfoDefault
  shippings: IModelShippingDefault[] = [] as IModelShippingDefault[]
  totalItemQuantity = 0
  availableCoupons: IModelCouponDefault[] = [] as IModelCouponDefault[]
  canPartialCancel = false
  beforeOrderedAt = null
  isFirstPurchase = false
  constructor(data: IModelOrderDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.user = DataBase.getModel(this.user)
    this.device = DataBase.getModel(this.device)
    this.customerAddress = DataBase.getModel(this.customerAddress)
    this.approvedTransaction = DataBase.getModel(this.approvedTransaction)
    this.orderMileageHistories = DataBase.getListModel(this.orderMileageHistories)
    this.orderCoupons = DataBase.getListModel(this.orderCoupons)
    this.orderOrderItems = DataBase.getListModel(this.orderOrderItems)
    this.orderTransactions = DataBase.getListModel(this.orderTransactions)
    this.orderCancelTransactions = DataBase.getListModel(this.orderCancelTransactions)
    this.orderRefundInfo = DataBase.getModel(this.orderRefundInfo)
    this.shippings = DataBase.getListModel(this.shippings)
    this.availableCoupons = DataBase.getListModel(this.availableCoupons)
  }
}

export class ModelProductImageDefault extends ModelBase {
  image = ''
  product: IModelProductDefault = {} as IModelProductDefault
  displayType = ''
  thumbnail = ''
  constructor(data: IModelProductImageDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.product = DataBase.getModel(this.product)
  }
}

export class ModelProductOptionDefault extends ModelBase {
  product: IModelProductDefault = {} as IModelProductDefault
  productOptionValues: IModelProductOptionValueDefault[] = [] as IModelProductOptionValueDefault[]
  series = 0
  price = 0
  discountPrice = 0
  isDefault = false
  isUse = false
  isDelete = false
  skus: IModelSkuDefault[] = [] as IModelSkuDefault[]
  name = ''
  productOptionReviews: IModelProductQnaDefault[] = [] as IModelProductQnaDefault[]
  relatedProduct: IModelProductDefault = {} as IModelProductDefault
  productOptionRestocks: IModelRestockProductOptionUserDefault[] = [] as IModelRestockProductOptionUserDefault[]
  productOptionProductOptionSkus: IModelProductOptionSkuDefault[] = [] as IModelProductOptionSkuDefault[]
  productOptionOrderItems: IModelOrderItemDefault[] = [] as IModelOrderItemDefault[]
  productOptionCartItems: IModelCartItemDefault[] = [] as IModelCartItemDefault[]
  productOptionCartItemHistories: IModelCartItemHistoryDefault[] = [] as IModelCartItemHistoryDefault[]
  customerPrice = 0
  difference = 0
  productPromotionCustomerPrice = 0
  productPromotionDiscountPrice = 0
  productOptionPrice = 0
  productOptionKinds: IModelProductOptionKindDefault[] = [] as IModelProductOptionKindDefault[]
  stockQuantity = 0
  purchasePrice = 0
  productOptionSkus: IModelProductOptionSkuDefault[] = [] as IModelProductOptionSkuDefault[]
  productId = 0
  constructor(data: IModelProductOptionDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.product = DataBase.getModel(this.product)
    this.productOptionValues = DataBase.getListModel(this.productOptionValues)
    this.skus = DataBase.getListModel(this.skus)
    this.productOptionReviews = DataBase.getListModel(this.productOptionReviews)
    this.relatedProduct = DataBase.getModel(this.relatedProduct)
    this.productOptionRestocks = DataBase.getListModel(this.productOptionRestocks)
    this.productOptionProductOptionSkus = DataBase.getListModel(this.productOptionProductOptionSkus)
    this.productOptionOrderItems = DataBase.getListModel(this.productOptionOrderItems)
    this.productOptionCartItems = DataBase.getListModel(this.productOptionCartItems)
    this.productOptionCartItemHistories = DataBase.getListModel(this.productOptionCartItemHistories)
    this.productOptionKinds = DataBase.getListModel(this.productOptionKinds)
    this.productOptionSkus = DataBase.getListModel(this.productOptionSkus)
  }
}

export class ModelProductPromotionDefault extends ModelBase {
  startedAt = null
  endedAt = null
  createdAt = null
  updatedAt = null
  product: IModelProductDefault = {} as IModelProductDefault
  displayProductInfo: IModelDisplayProductInfoDefault = {} as IModelDisplayProductInfoDefault
  productPromotionType = ''
  name = ''
  description = ''
  discountPrice = 0
  fitpetFeeRate = 0
  mileageRewardRate = 0
  reviewMileageRewardRate = 0
  confirmMileageRewardRate = 0
  isDelete = false
  minOrderAmount = 0
  maxOrderCount = 0
  productPromotionOrderItems: IModelOrderItemDefault[] = [] as IModelOrderItemDefault[]
  productPromotionCartItems: IModelCartItemDefault[] = [] as IModelCartItemDefault[]
  productPromotionCartItemHistories: IModelCartItemHistoryDefault[] = [] as IModelCartItemHistoryDefault[]
  productPromotionCustomerPrice = 0
  discountRate = 0
  defaultFitpetFeeRate = 0
  boughtCount = 0
  constructor(data: IModelProductPromotionDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.product = DataBase.getModel(this.product)
    this.displayProductInfo = DataBase.getModel(this.displayProductInfo)
    this.productPromotionOrderItems = DataBase.getListModel(this.productPromotionOrderItems)
    this.productPromotionCartItems = DataBase.getListModel(this.productPromotionCartItems)
    this.productPromotionCartItemHistories = DataBase.getListModel(this.productPromotionCartItemHistories)
  }
}

export class ModelProductQnaDefault extends ModelBase {
  createdAt = null
  updatedAt = null
  questionBody = ''
  answerBody = ''
  answeredAt = null
  product: IModelProductDefault = {} as IModelProductDefault
  productOption: IModelProductOptionDefault = {} as IModelProductOptionDefault
  isSecret = false
  user: IModelUserDefault = {} as IModelUserDefault
  answeredUser: IModelUserDefault = {} as IModelUserDefault
  answeredUserType = ''
  zendeskTicketId = 0
  isAnswered = false
  isAdmin = false
  seller: IModelSellerDefault = {} as IModelSellerDefault
  username = ''
  constructor(data: IModelProductQnaDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.product = DataBase.getModel(this.product)
    this.productOption = DataBase.getModel(this.productOption)
    this.user = DataBase.getModel(this.user)
    this.answeredUser = DataBase.getModel(this.answeredUser)
    this.seller = DataBase.getModel(this.seller)
  }
}

export class ModelProductDefault extends ModelBase {
  displayOrder = 0
  startedAt = null
  endedAt = null
  petType = ''
  createdAt = null
  updatedAt = null
  isShownNaver = false
  isShownDaum = false
  isShownFacebook = false
  isShownGoogle = false
  isShown = false
  isSoldOut = false
  isStockSoldOut = false
  name = ''
  productNumber = ''
  supplyType = ''
  description = ''
  correspondent = ''
  fitpetFeeRate = 0
  originalProduct: IModelProductDefault = {} as IModelProductDefault
  reviewGroup: IModelReviewGroupDefault = {} as IModelReviewGroupDefault
  asInfo: IModelAsInfoDefault = {} as IModelAsInfoDefault
  categories: IModelCategoryDefault[] = [] as IModelCategoryDefault[]
  seller: IModelSellerDefault = {} as IModelSellerDefault
  brand: IModelBrandDefault = {} as IModelBrandDefault
  weight = 0
  point = 0
  searchKeywords = ''
  status: IModelStatusDefault = {} as IModelStatusDefault
  updateInfo: IModelUpdatedProductDefault = {} as IModelUpdatedProductDefault
  updateStatus: IModelStatusDefault = {} as IModelStatusDefault
  productOptionType = ''
  defaultProductOption: IModelProductOptionDefault = {} as IModelProductOptionDefault
  shippingPreset: IModelShippingPresetDefault = {} as IModelShippingPresetDefault
  shippingGuide = ''
  shippingNotice = ''
  requestedAt = null
  approvedAt = null
  updateApprovedAt = null
  deletedAt = null
  wishedProductUsers: IModelUserDefault[] = [] as IModelUserDefault[]
  productQnas: IModelProductQnaDefault[] = [] as IModelProductQnaDefault[]
  productReviews: IModelReviewDefault[] = [] as IModelReviewDefault[]
  productProductCategories: IModelProductCategoryDefault[] = [] as IModelProductCategoryDefault[]
  copiedProduct: IModelProductDefault[] = [] as IModelProductDefault[]
  productProductImages: IModelProductImageDefault[] = [] as IModelProductImageDefault[]
  productProductOptions: IModelProductOptionDefault[] = [] as IModelProductOptionDefault[]
  productInfluencers: IModelInfluencerDefault[] = [] as IModelInfluencerDefault[]
  includedProductsCouponGroups: IModelCouponGroupDefault[] = [] as IModelCouponGroupDefault[]
  excludedProductsCouponGroups: IModelCouponGroupDefault[] = [] as IModelCouponGroupDefault[]
  displayproductinfoSet: IModelDisplayProductInfoDefault[] = [] as IModelDisplayProductInfoDefault[]
  productProductPromotions: IModelProductPromotionDefault[] = [] as IModelProductPromotionDefault[]
  productGnbs: IModelGnbDefault[] = [] as IModelGnbDefault[]
  realName = ''
  reviewCount = 0
  reviews: IModelReviewDefault[] = [] as IModelReviewDefault[]
  averageReviewScore = 0
  stockQuantity = 0
  price = 0
  customerPrice = 0
  productPromotion: IModelProductPromotionDefault = {} as IModelProductPromotionDefault
  productPromotionStartedAt = null
  productPromotionEndedAt = null
  productPromotionCustomerPrice = 0
  productPromotionDiscountPrice = 0
  finalDiscountRate = 0
  discountRate = 0
  discountPrice = 0
  canDownloadCoupon = false
  downloadCouponGroups: IModelCouponGroupDefault[] = [] as IModelCouponGroupDefault[]
  couponDiscountPrice = 0
  couponDiscountRate = 0
  soldQuantity = 0
  purchasePrice = 0
  productCoupons: IModelCouponDefault[] = [] as IModelCouponDefault[]
  brandCoupons: IModelCouponDefault[] = [] as IModelCouponDefault[]
  categoryCoupons: IModelCouponDefault[] = [] as IModelCouponDefault[]
  wishedCount = 0
  likedCount = 0
  mainImage: IModelProductImageDefault = {} as IModelProductImageDefault
  isWished = false
  isLiked = false
  isTimesale = false
  mileage = 0
  imageReviewMileage = 0
  reviewMileage = 0
  orderConfirmMileage = 0
  isRealSoldOut = false
  isSingleOption = false
  pricePerSku = 0
  maxProductOptionSkuCount = 0
  ingredientTypes: ProductRecommendIngredientEnum[] = []
  feedCategoryType: string = ''

  constructor(data: IModelProductDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.originalProduct = DataBase.getModel(this.originalProduct)
    this.reviewGroup = DataBase.getModel(this.reviewGroup)
    this.asInfo = DataBase.getModel(this.asInfo)
    this.categories = DataBase.getListModel(this.categories)
    this.seller = DataBase.getModel(this.seller)
    this.brand = DataBase.getModel(this.brand)
    this.status = DataBase.getModel(this.status)
    this.updateInfo = DataBase.getModel(this.updateInfo)
    this.updateStatus = DataBase.getModel(this.updateStatus)
    this.defaultProductOption = DataBase.getModel(this.defaultProductOption)
    this.shippingPreset = DataBase.getModel(this.shippingPreset)
    this.wishedProductUsers = DataBase.getListModel(this.wishedProductUsers)
    this.productQnas = DataBase.getListModel(this.productQnas)
    this.productReviews = DataBase.getListModel(this.productReviews)
    this.productProductCategories = DataBase.getListModel(this.productProductCategories)
    this.copiedProduct = DataBase.getListModel(this.copiedProduct)
    this.productProductImages = DataBase.getListModel(this.productProductImages)
    this.productProductOptions = DataBase.getListModel(this.productProductOptions)
    this.productInfluencers = DataBase.getListModel(this.productInfluencers)
    this.includedProductsCouponGroups = DataBase.getListModel(this.includedProductsCouponGroups)
    this.excludedProductsCouponGroups = DataBase.getListModel(this.excludedProductsCouponGroups)
    this.displayproductinfoSet = DataBase.getListModel(this.displayproductinfoSet)
    this.productProductPromotions = DataBase.getListModel(this.productProductPromotions)
    this.productGnbs = DataBase.getListModel(this.productGnbs)
    this.reviews = DataBase.getListModel(this.reviews)
    this.productPromotion = DataBase.getModel(this.productPromotion)
    this.downloadCouponGroups = DataBase.getListModel(this.downloadCouponGroups)
    this.productCoupons = DataBase.getListModel(this.productCoupons)
    this.brandCoupons = DataBase.getListModel(this.brandCoupons)
    this.categoryCoupons = DataBase.getListModel(this.categoryCoupons)
    this.mainImage = DataBase.getModel(this.mainImage)
  }
}

export class ModelRestockProductOptionUserDefault extends ModelBase {
  productOption: IModelProductOptionDefault = {} as IModelProductOptionDefault
  user: IModelUserDefault = {} as IModelUserDefault
  requestedAt = null
  informedAt = null
  constructor(data: IModelRestockProductOptionUserDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.productOption = DataBase.getModel(this.productOption)
    this.user = DataBase.getModel(this.user)
  }
}

export class ModelReturnInfoImageDefault extends ModelBase {
  image = ''
  returnInfo: IModelReturnInfoDefault = {} as IModelReturnInfoDefault
  thumbnail = ''
  constructor(data: IModelReturnInfoImageDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.returnInfo = DataBase.getModel(this.returnInfo)
  }
}

export class ModelReturnInfoDefault extends ModelBase {
  createdAt = null
  updatedAt = null
  customerAddress: IModelCustomerAddressDefault = {} as IModelCustomerAddressDefault
  responsibility = ''
  returnUser = ''
  returnShippingFee = 0
  fitpetAdminName = ''
  guideMessage = ''
  requestType = ''
  requestMessage = ''
  rejectMessage = ''
  shipping: IModelShippingDefault = {} as IModelShippingDefault
  acceptedAt = null
  returnedAt = null
  confirmedAt = null
  rejectedAt = null
  returnInfoMileageHistories: IModelMileageHistoryDefault[] = [] as IModelMileageHistoryDefault[]
  returnInfoOrderItems: IModelOrderItemDefault[] = [] as IModelOrderItemDefault[]
  returnInfoReturnInfoImages: IModelReturnInfoImageDefault[] = [] as IModelReturnInfoImageDefault[]
  billingitemSet: IModelBillingItemDefault[] = [] as IModelBillingItemDefault[]
  constructor(data: IModelReturnInfoDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.customerAddress = DataBase.getModel(this.customerAddress)
    this.shipping = DataBase.getModel(this.shipping)
    this.returnInfoMileageHistories = DataBase.getListModel(this.returnInfoMileageHistories)
    this.returnInfoOrderItems = DataBase.getListModel(this.returnInfoOrderItems)
    this.returnInfoReturnInfoImages = DataBase.getListModel(this.returnInfoReturnInfoImages)
    this.billingitemSet = DataBase.getListModel(this.billingitemSet)
  }
}

export class ModelReviewImageDefault extends ModelBase {
  image = ''
  review: IModelReviewDefault = {} as IModelReviewDefault
  thumbnail = ''
  constructor(data: IModelReviewImageDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.review = DataBase.getModel(this.review)
  }
}

export class ModelReviewDefault extends ModelBase {
  createdAt = null
  updatedAt = null
  checkAdminAt = null
  user: IModelUserDefault = {} as IModelUserDefault
  product: IModelProductDefault = {} as IModelProductDefault
  productOptionName = ''
  orderItem: IModelOrderItemDefault = {} as IModelOrderItemDefault
  reviewBody = ''
  answerBody = ''
  answeredAt = null
  answeredUser: IModelUserDefault = {} as IModelUserDefault
  score = 0
  weight = 0
  isDelete = false
  isPhotoReview = false
  bestAt = null
  bestSeries = 0
  likedUsers: IModelUserDefault[] = [] as IModelUserDefault[]
  mileage = 0
  reviewReviewImages: IModelReviewImageDefault[] = [] as IModelReviewImageDefault[]
  reviewMileageHistories: IModelMileageHistoryDefault[] = [] as IModelMileageHistoryDefault[]
  isAnswered = false
  seller: IModelSellerDefault = {} as IModelSellerDefault
  isFirstReviewProduct = false
  username = ''
  isLiked = false
  mileageText = ''
  likedCount = 0
  constructor(data: IModelReviewDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.user = DataBase.getModel(this.user)
    this.product = DataBase.getModel(this.product)
    this.orderItem = DataBase.getModel(this.orderItem)
    this.answeredUser = DataBase.getModel(this.answeredUser)
    this.likedUsers = DataBase.getListModel(this.likedUsers)
    this.reviewReviewImages = DataBase.getListModel(this.reviewReviewImages)
    this.reviewMileageHistories = DataBase.getListModel(this.reviewMileageHistories)
    this.seller = DataBase.getModel(this.seller)
  }
}

export class ModelSellerDefault extends ModelBase {
  createdAt = null
  updatedAt = null
  accountNumber = ''
  accountHolderName = ''
  bankCode = ''
  bankName = ''
  users: IModelUserDefault[] = [] as IModelUserDefault[]
  companyName = ''
  shopName = ''
  companyOwnerName = ''
  businessRegistrationNumber = ''
  companyId = ''
  postNumber = ''
  basicAddress = ''
  detailedAddress = ''
  homepage = ''
  businessCondition = ''
  businessItem = ''
  businessRegistration = ''
  mailOrderReportNumber = ''
  mailOrderReportRegistration = ''
  bankBookImage = ''
  telephoneNumber = ''
  status: IModelStatusDefault = {} as IModelStatusDefault
  blockedInfluencers: IModelInfluencerDefault[] = [] as IModelInfluencerDefault[]
  updateInfo: IModelUpdatedSellerDefault = {} as IModelUpdatedSellerDefault
  updateStatus: IModelStatusDefault = {} as IModelStatusDefault
  fitpetFeeRate = 0
  accountManagerName = ''
  accountManagerTelephoneNumber = ''
  taxEmail = ''
  notificationManagerName = ''
  notificationEmail = ''
  notificationMobileNumber = ''
  returnExchangeGuide = ''
  requestedAt = null
  approvedAt = null
  updateApprovedAt = null
  sabangnetToken = ''
  sellerProducts: IModelProductDefault[] = [] as IModelProductDefault[]
  sellerBrands: IModelBrandDefault[] = [] as IModelBrandDefault[]
  sellerSkus: IModelSkuDefault[] = [] as IModelSkuDefault[]
  sellerInfluencers: IModelInfluencerDefault[] = [] as IModelInfluencerDefault[]
  sellerUsers: IModelSellerUserDefault[] = [] as IModelSellerUserDefault[]
  sellerAsInfos: IModelAsInfoDefault[] = [] as IModelAsInfoDefault[]
  sellerAddresses: IModelSellerTemplateAddressDefault[] = [] as IModelSellerTemplateAddressDefault[]
  sellerExcelRequests: IModelExcelExportRequestDefault[] = [] as IModelExcelExportRequestDefault[]
  sellerOrderItems: IModelOrderItemDefault[] = [] as IModelOrderItemDefault[]
  userShippingPresets: IModelShippingPresetDefault[] = [] as IModelShippingPresetDefault[]
  sellerBillings: IModelSellerBillingDefault[] = [] as IModelSellerBillingDefault[]
  isFollowed = false
  chiefManager: IModelUserDefault = {} as IModelUserDefault
  soldQuantity = 0
  soldQuantityLastMonth = 0
  soldAmount = 0
  productCount = 0
  sellerTemplateAddresses: IModelSellerTemplateAddressDefault[] = [] as IModelSellerTemplateAddressDefault[]
  isFitpet = false
  constructor(data: IModelSellerDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.users = DataBase.getListModel(this.users)
    this.status = DataBase.getModel(this.status)
    this.blockedInfluencers = DataBase.getListModel(this.blockedInfluencers)
    this.updateInfo = DataBase.getModel(this.updateInfo)
    this.updateStatus = DataBase.getModel(this.updateStatus)
    this.sellerProducts = DataBase.getListModel(this.sellerProducts)
    this.sellerBrands = DataBase.getListModel(this.sellerBrands)
    this.sellerSkus = DataBase.getListModel(this.sellerSkus)
    this.sellerInfluencers = DataBase.getListModel(this.sellerInfluencers)
    this.sellerUsers = DataBase.getListModel(this.sellerUsers)
    this.sellerAsInfos = DataBase.getListModel(this.sellerAsInfos)
    this.sellerAddresses = DataBase.getListModel(this.sellerAddresses)
    this.sellerExcelRequests = DataBase.getListModel(this.sellerExcelRequests)
    this.sellerOrderItems = DataBase.getListModel(this.sellerOrderItems)
    this.userShippingPresets = DataBase.getListModel(this.userShippingPresets)
    this.sellerBillings = DataBase.getListModel(this.sellerBillings)
    this.chiefManager = DataBase.getModel(this.chiefManager)
    this.sellerTemplateAddresses = DataBase.getListModel(this.sellerTemplateAddresses)
  }
}

export class ModelShippingCompanyDefault extends ModelBase {
  name = ''
  sweetTrackerCode = ''
  iamportCode = ''
  shippingCompanyShippings: IModelShippingDefault[] = [] as IModelShippingDefault[]
  shippingCompanyPresets: IModelShippingPresetDefault[] = [] as IModelShippingPresetDefault[]
  isDelete = false
  constructor(data: IModelShippingCompanyDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.shippingCompanyShippings = DataBase.getListModel(this.shippingCompanyShippings)
    this.shippingCompanyPresets = DataBase.getListModel(this.shippingCompanyPresets)
  }
}

export class ModelShippingPresetDefault extends ModelBase {
  seller: IModelSellerDefault = {} as IModelSellerDefault
  name = ''
  shippingCompany: IModelShippingCompanyDefault = {} as IModelShippingCompanyDefault
  shippingFee = 0
  freeShippingBaseAmount = 0
  returnFee = 0
  warehouseAddress: IModelSellerAddressDefault = {} as IModelSellerAddressDefault
  returnAddress: IModelSellerAddressDefault = {} as IModelSellerAddressDefault
  isPackage = false
  isPrepayment = false
  guide = ''
  notice = ''
  applyRemoteAreaFee = false
  shippingPresetProducts: IModelProductDefault[] = [] as IModelProductDefault[]
  brandGnbs: IModelGnbDefault[] = [] as IModelGnbDefault[]
  shippingPresetOrderItems: IModelShippingDefault[] = [] as IModelShippingDefault[]
  constructor(data: IModelShippingPresetDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.seller = DataBase.getModel(this.seller)
    this.shippingCompany = DataBase.getModel(this.shippingCompany)
    this.warehouseAddress = DataBase.getModel(this.warehouseAddress)
    this.returnAddress = DataBase.getModel(this.returnAddress)
    this.shippingPresetProducts = DataBase.getListModel(this.shippingPresetProducts)
    this.brandGnbs = DataBase.getListModel(this.brandGnbs)
    this.shippingPresetOrderItems = DataBase.getListModel(this.shippingPresetOrderItems)
  }
}

export class ModelShippingDefault extends ModelBase {
  fee = 0
  extraFee = 0
  discountPrice = 0
  couponDiscountPrice = 0
  mileageDiscountPrice = 0
  shippingPreset: IModelShippingPresetDefault = {} as IModelShippingPresetDefault
  shippingCompany: IModelShippingCompanyDefault = {} as IModelShippingCompanyDefault
  shippingType = ''
  shippingNumbers = ''
  sentAt = null
  receivedAt = null
  couponInfo = ''
  shippingMileageHistories: IModelMileageHistoryDefault[] = [] as IModelMileageHistoryDefault[]
  shippingCoupons: IModelCouponDefault[] = [] as IModelCouponDefault[]
  shippingOrderItems: IModelOrderItemDefault[] = [] as IModelOrderItemDefault[]
  shippingReturnInfos: IModelReturnInfoDefault[] = [] as IModelReturnInfoDefault[]
  shippingRefundShippingInfos: IModelRefundShippingInfoDefault[] = [] as IModelRefundShippingInfoDefault[]
  billingitemSet: IModelBillingItemDefault[] = [] as IModelBillingItemDefault[]
  shippingTypeText = ''
  isTrackable = false
  untrackedReason = ''
  amount = 0
  availableCoupons: IModelCouponDefault[] = [] as IModelCouponDefault[]
  seller: IModelSellerDefault = {} as IModelSellerDefault
  sumFee = 0
  isFitpet = false
  isRefundable = false
  shippingCompanyAndNumbers = ''
  constructor(data: IModelShippingDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.shippingPreset = DataBase.getModel(this.shippingPreset)
    this.shippingCompany = DataBase.getModel(this.shippingCompany)
    this.shippingMileageHistories = DataBase.getListModel(this.shippingMileageHistories)
    this.shippingCoupons = DataBase.getListModel(this.shippingCoupons)
    this.shippingOrderItems = DataBase.getListModel(this.shippingOrderItems)
    this.shippingReturnInfos = DataBase.getListModel(this.shippingReturnInfos)
    this.shippingRefundShippingInfos = DataBase.getListModel(this.shippingRefundShippingInfos)
    this.billingitemSet = DataBase.getListModel(this.billingitemSet)
    this.availableCoupons = DataBase.getListModel(this.availableCoupons)
    this.seller = DataBase.getModel(this.seller)
  }
}

export class ModelSlimBannerInfoDefault extends ModelBase {
  adId = ''
  adName = ''
  adSlug = ''
  pcImage: IModelBannerImageDefault = {} as IModelBannerImageDefault
  mobileImage: IModelBannerImageDefault = {} as IModelBannerImageDefault
  constructor(data: IModelSlimBannerInfoDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.pcImage = DataBase.getModel(this.pcImage)
    this.mobileImage = DataBase.getModel(this.mobileImage)
  }
}

export class ModelSocialServiceDefault extends ModelBase {
  createdAt = null
  updatedAt = null
  user: IModelUserDefault = {} as IModelUserDefault
  socialType = ''
  accessTokenIssuedAt = null
  lastLoggedAt = null
  constructor(data: IModelSocialServiceDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.user = DataBase.getModel(this.user)
  }
}

export class ModelTopRollBannerInfoDefault extends ModelBase {
  adId = ''
  adName = ''
  adSlug = ''
  pcImage: IModelBannerImageDefault = {} as IModelBannerImageDefault
  mobileImage: IModelBannerImageDefault = {} as IModelBannerImageDefault
  eventTypeText = ''
  description = ''
  subjectText = ''
  benefitText = ''
  constructor(data: IModelTopRollBannerInfoDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.pcImage = DataBase.getModel(this.pcImage)
    this.mobileImage = DataBase.getModel(this.mobileImage)
  }
}

export class ModelTransactionDefault extends ModelBase {
  createdAt = null
  updatedAt = null
  order: IModelOrderDefault = {} as IModelOrderDefault
  device = ''
  payMethodType = ''
  isEscrow = false
  merchantCode = ''
  merchantUid = ''
  iamportUid = ''
  pgTid = ''
  pgApprovedTid = ''
  name = ''
  amount = 0
  taxFreeAmount = 0
  cardQuota = 0
  errorCode = ''
  errorMessage = ''
  transactionStatus = ''
  pgProviderType = ''
  payAppType = ''
  startedAt = null
  approvedAt = null
  receiptUrl = ''
  applyNumber = ''
  vbankAccountNumber = ''
  vbankBankCode = ''
  vbankBankName = ''
  vbankAccountHolderName = ''
  vbankIssuedAt = null
  vbankEndedAt = null
  bankAccountNumber = ''
  bankCode = ''
  bankName = ''
  bankAccountHolderName = ''
  responseData = ''
  webhookData = ''
  fitpetAdmin: IModelUserDefault = {} as IModelUserDefault
  approvedTransactionOrder: IModelOrderDefault = {} as IModelOrderDefault
  transactionCancelTransactions: IModelCancelTransactionDefault[] = [] as IModelCancelTransactionDefault[]
  createdAtMillis = ''
  transactionStatusText = ''
  cancelTransactions: IModelCancelTransactionDefault[] = [] as IModelCancelTransactionDefault[]
  paymentText = ''
  constructor(data: IModelTransactionDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.order = DataBase.getModel(this.order)
    this.fitpetAdmin = DataBase.getModel(this.fitpetAdmin)
    this.approvedTransactionOrder = DataBase.getModel(this.approvedTransactionOrder)
    this.transactionCancelTransactions = DataBase.getListModel(this.transactionCancelTransactions)
    this.cancelTransactions = DataBase.getListModel(this.cancelTransactions)
  }
}

export class ModelUserCertificationDefault extends ModelBase {
  createdAt = null
  updatedAt = null
  mobileNumber = ''
  email = ''
  confirmedAt = null
  certType = ''
  expiredAt = null
  constructor(data: IModelUserCertificationDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)
  }
}

export class ModelUserInfoAccountDefault extends ModelBase {
  loginAccountType = ''
  lastLoggedAt = null
  createdAt = null
  constructor(data: IModelUserInfoAccountDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)
  }
}

export class ModelUserInfoDefault extends ModelBase {
  email = ''
  userStatus = ''
  accounts: IModelUserInfoAccountDefault[] = [] as IModelUserInfoAccountDefault[]
  constructor(data: IModelUserInfoDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.accounts = DataBase.getListModel(this.accounts)
  }
}

export class ModelUserDefault extends ModelBase {
  createdAt = null
  updatedAt = null
  accountNumber = ''
  accountHolderName = ''
  bankCode = ''
  bankName = ''
  lastLogin = null
  isSuperuser = false
  firstName = ''
  lastName = ''
  email = ''
  isStaff = false
  isActive = false
  dateJoined = null
  username = ''
  name = ''
  inactivatedAt = null
  activatedAt = null
  leftAt = null
  mobileNumber = ''
  influencer: IModelInfluencerDefault = {} as IModelInfluencerDefault
  grade: IModelCustomerGradeDefault = {} as IModelCustomerGradeDefault
  gradeUpdatedAt = null
  mileage: IModelMileageDefault = {} as IModelMileageDefault
  inviteUser: IModelUserDefault = {} as IModelUserDefault
  invitationCode = ''
  inviteCount = 0
  birthday = null
  gender = ''
  wishedProducts: IModelProductDefault[] = [] as IModelProductDefault[]
  wishedBrands: IModelBrandDefault[] = [] as IModelBrandDefault[]
  updateApprovedAt = null
  passwordUpdatedAt = null
  godoId = ''
  leftReason = ''
  leftMessage = ''
  agreeNightMarketing = false
  agreeNightMarketingAt = null
  agreeMarketing = false
  agreeMarketingAt = null
  agreeThirdPartyProvision = false
  agreeThirdPartyProvisionAt = null
  userStatus = ''
  signInCount = 0
  signInFailCount = 0
  lastSignInFailedAt = null
  joinedPlatform = ''
  displayPetType = ''
  boughtDeal100 = false
  invitedUser: IModelUserDefault[] = [] as IModelUserDefault[]
  inactiveuser: IModelInactiveUserDefault = {} as IModelInactiveUserDefault
  userProductQnas: IModelProductQnaDefault[] = [] as IModelProductQnaDefault[]
  answeredUserProductQnas: IModelProductQnaDefault[] = [] as IModelProductQnaDefault[]
  userServiceQnas: IModelServiceQnaDefault[] = [] as IModelServiceQnaDefault[]
  answeredUserServiceQnas: IModelServiceQnaDefault[] = [] as IModelServiceQnaDefault[]
  userReviews: IModelReviewDefault[] = [] as IModelReviewDefault[]
  answeredUserReviews: IModelReviewDefault[] = [] as IModelReviewDefault[]
  likedReviews: IModelReviewDefault[] = [] as IModelReviewDefault[]
  userRestocks: IModelRestockProductOptionUserDefault[] = [] as IModelRestockProductOptionUserDefault[]
  createdUserSkus: IModelSkuDefault[] = [] as IModelSkuDefault[]
  updatedUserSkus: IModelSkuDefault[] = [] as IModelSkuDefault[]
  fitpetAdminSkuHistories: IModelSkuHistoryDefault[] = [] as IModelSkuHistoryDefault[]
  sellers: IModelSellerDefault[] = [] as IModelSellerDefault[]
  userSellerUsers: IModelSellerUserDefault[] = [] as IModelSellerUserDefault[]
  userDevices: IModelDeviceDefault[] = [] as IModelDeviceDefault[]
  requestUserStatusHistories: IModelStatusHistoryDefault[] = [] as IModelStatusHistoryDefault[]
  fitpetAdminStatusHistories: IModelStatusHistoryDefault[] = [] as IModelStatusHistoryDefault[]
  fitpetAdminMileageHistories: IModelMileageHistoryDefault[] = [] as IModelMileageHistoryDefault[]
  userCustomerTemplateAddresses: IModelCustomerTemplateAddressDefault[] = [] as IModelCustomerTemplateAddressDefault[]
  userSocialServices: IModelSocialServiceDefault[] = [] as IModelSocialServiceDefault[]
  fitpetAdminInformPushQueues: IModelInformPushQueueDefault[] = [] as IModelInformPushQueueDefault[]
  userPushUserHistories: IModelPushUserHistoryDefault[] = [] as IModelPushUserHistoryDefault[]
  userExcelRequests: IModelExcelExportRequestDefault[] = [] as IModelExcelExportRequestDefault[]
  userOrders: IModelOrderDefault[] = [] as IModelOrderDefault[]
  fitpetAdminCouponGroups: IModelCouponGroupDefault[] = [] as IModelCouponGroupDefault[]
  userCoupons: IModelCouponDefault[] = [] as IModelCouponDefault[]
  fitpetAdminCoupons: IModelCouponDefault[] = [] as IModelCouponDefault[]
  userSubscriptions: IModelUserSubscriptionDefault[] = [] as IModelUserSubscriptionDefault[]
  fitpetAdminOrderItemAdminNotes: IModelOrderItemAdminNoteDefault[] = [] as IModelOrderItemAdminNoteDefault[]
  fitpetAdminTransactions: IModelTransactionDefault[] = [] as IModelTransactionDefault[]
  adminCustomerGrade: IModelCustomerGradeDefault[] = [] as IModelCustomerGradeDefault[]
  userNotice: IModelNoticeDefault[] = [] as IModelNoticeDefault[]
  userFaqs: IModelFaqDefault[] = [] as IModelFaqDefault[]
  cartItems: IModelCartItemDefault[] = [] as IModelCartItemDefault[]
  cartItemHistories: IModelCartItemHistoryDefault[] = [] as IModelCartItemHistoryDefault[]
  sellerCount = 0
  approvalSellerCount = 0
  isSeller = false
  isInfluencer = false
  orderCount = 0
  ongoingOrderCount = 0
  reviewCount = 0
  writableReviewCount = 0
  cartItemCount = 0
  cartItemQuantity = 0
  orderAmount = 0
  coupons: IModelCouponDefault[] = [] as IModelCouponDefault[]
  mileagePoint = 0
  customerTemplateAddresses: IModelCustomerTemplateAddressDefault[] = [] as IModelCustomerTemplateAddressDefault[]
  recentOrderCustomerAddress: IModelCustomerAddressDefault = {} as IModelCustomerAddressDefault
  couponCount = 0
  userStatusText = ''
  hasEmailAccount = false
  inactiveUser: IModelInactiveUserDefault = {} as IModelInactiveUserDefault
  lastOrderedAt = null
  unusedMileage = 0
  likedCount = 0
  canBuyDeal100 = false
  constructor(data: IModelUserDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.influencer = DataBase.getModel(this.influencer)
    this.grade = DataBase.getModel(this.grade)
    this.mileage = DataBase.getModel(this.mileage)
    this.inviteUser = DataBase.getModel(this.inviteUser)
    this.wishedProducts = DataBase.getListModel(this.wishedProducts)
    this.wishedBrands = DataBase.getListModel(this.wishedBrands)
    this.invitedUser = DataBase.getListModel(this.invitedUser)
    this.inactiveuser = DataBase.getModel(this.inactiveuser)
    this.userProductQnas = DataBase.getListModel(this.userProductQnas)
    this.answeredUserProductQnas = DataBase.getListModel(this.answeredUserProductQnas)
    this.userServiceQnas = DataBase.getListModel(this.userServiceQnas)
    this.answeredUserServiceQnas = DataBase.getListModel(this.answeredUserServiceQnas)
    this.userReviews = DataBase.getListModel(this.userReviews)
    this.answeredUserReviews = DataBase.getListModel(this.answeredUserReviews)
    this.likedReviews = DataBase.getListModel(this.likedReviews)
    this.userRestocks = DataBase.getListModel(this.userRestocks)
    this.createdUserSkus = DataBase.getListModel(this.createdUserSkus)
    this.updatedUserSkus = DataBase.getListModel(this.updatedUserSkus)
    this.fitpetAdminSkuHistories = DataBase.getListModel(this.fitpetAdminSkuHistories)
    this.sellers = DataBase.getListModel(this.sellers)
    this.userSellerUsers = DataBase.getListModel(this.userSellerUsers)
    this.userDevices = DataBase.getListModel(this.userDevices)
    this.requestUserStatusHistories = DataBase.getListModel(this.requestUserStatusHistories)
    this.fitpetAdminStatusHistories = DataBase.getListModel(this.fitpetAdminStatusHistories)
    this.fitpetAdminMileageHistories = DataBase.getListModel(this.fitpetAdminMileageHistories)
    this.userCustomerTemplateAddresses = DataBase.getListModel(this.userCustomerTemplateAddresses)
    this.userSocialServices = DataBase.getListModel(this.userSocialServices)
    this.fitpetAdminInformPushQueues = DataBase.getListModel(this.fitpetAdminInformPushQueues)
    this.userPushUserHistories = DataBase.getListModel(this.userPushUserHistories)
    this.userExcelRequests = DataBase.getListModel(this.userExcelRequests)
    this.userOrders = DataBase.getListModel(this.userOrders)
    this.fitpetAdminCouponGroups = DataBase.getListModel(this.fitpetAdminCouponGroups)
    this.userCoupons = DataBase.getListModel(this.userCoupons)
    this.fitpetAdminCoupons = DataBase.getListModel(this.fitpetAdminCoupons)
    this.userSubscriptions = DataBase.getListModel(this.userSubscriptions)
    this.fitpetAdminOrderItemAdminNotes = DataBase.getListModel(this.fitpetAdminOrderItemAdminNotes)
    this.fitpetAdminTransactions = DataBase.getListModel(this.fitpetAdminTransactions)
    this.adminCustomerGrade = DataBase.getListModel(this.adminCustomerGrade)
    this.userNotice = DataBase.getListModel(this.userNotice)
    this.userFaqs = DataBase.getListModel(this.userFaqs)
    this.cartItems = DataBase.getListModel(this.cartItems)
    this.cartItemHistories = DataBase.getListModel(this.cartItemHistories)
    this.coupons = DataBase.getListModel(this.coupons)
    this.customerTemplateAddresses = DataBase.getListModel(this.customerTemplateAddresses)
    this.recentOrderCustomerAddress = DataBase.getModel(this.recentOrderCustomerAddress)
    this.inactiveUser = DataBase.getModel(this.inactiveUser)
  }
}
