import { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { Spinner } from '@/components/common/etc'
import { ProductOption } from '@/components/common/modals/bottomSheet.type'
import { ProductChoiceCouponDownload } from '@/components/domains/cart/product-choice/ProductChoiceCouponDownload'
import { ProductChoiceSelect } from '@/components/domains/cart/product-choice/ProductChoiceSelect'
import { ProductChoiceSelectBottom } from '@/components/domains/cart/product-choice/ProductChoiceSelectBottom'
import { ProductChoiceSelectedItemList } from '@/components/domains/cart/product-choice/ProductChoiceSelectedItemList'
import { ProductLocation } from '@/constants/product-location.const'
import { ToastConst } from '@/constants/toast.const'
import { useBottomSheetEventTracker } from '@/containers/event-tracker/bottom-sheet.event.tracker'
import { useCouponEventTracker } from '@/containers/event-tracker/coupon.event-tracker'
import { useProductDetailEventTracker } from '@/containers/event-tracker/product-detail.event-tracker-new'
import { useCustomRouter } from '@/containers/hooks'
import { useCartBottomSheet } from '@/containers/hooks/cart/useCartBottomSheet'
import { UserContainer } from '@/containers/users/UserContainer'
import {
  doSetBottomSheetData,
  doSetCartConfirmationBottomSheetData,
  doSetProductCouponDownloadBottomSheetData,
} from '@/stores/reduxData'
import { doSetGlobalNoticeModal, doSetGlobalToastContent, doSetToggleModalLogin } from '@/stores/reduxUI'
import { RootState } from '@/stores/store'

export const ProductChoice = () => {
  const { pathname } = useCustomRouter()
  const dispatch = useDispatch()
  const { trackClickListCouponDownloadEvent } = useCouponEventTracker()
  const {
    reduxProductInProductChoiceBottomSheet,
    reduxTrackerDataInProductChoiceBottomSheet,
    reduxCallbackInProductChoiceBottomSheet,
  } = useSelector((state: RootState) => state.reduxDataReducers.productChoiceBottomSheetData)
  const { me } = UserContainer.useContainer()
  const isUserLoggedIn = !!me
  const bottomSheetData = useSelector((rootState: RootState) => rootState.reduxDataReducers.bottomSheetData)
  const isFirstRenderRef = useRef(true)
  const { isProductChoiceVisible } = bottomSheetData
  const {
    productOptions,
    productSummary,
    isGetPurchaseEstimateMutateLoading,
    addProductOption,
    removeProductOption,
    updateProductOptionQuantity,
    fetchPurchaseEstimate,
    addToCartItems,
  } = useCartBottomSheet()
  const { trackClickListAddToCartButtonEventNew } = useBottomSheetEventTracker()
  const { trackClickProductDetailAddToCartButtonEvent } = useProductDetailEventTracker()
  const isSingleOption = productSummary?.isSingleOption
  const hasApplicableProductCoupon = productSummary?.hasApplicableProductCoupon
  const hasUnreceivedProductCoupon = productSummary?.hasUnreceivedProductCoupon
  // const isShowCouponDownload = hasApplicableProductCoupon && hasUnreceivedProductCoupon
  const maxQuantityProductOptions = productOptions.filter(
    (productOption: ProductOption) =>
      productOption.isSelected &&
      productOption.availableQuantity !== 0 &&
      productOption.quantityInCart === productOption.availableQuantity
  )

  if (maxQuantityProductOptions.length > 0 && isProductChoiceVisible) {
    const maxQuantityProductOptionsInfoText = maxQuantityProductOptions
      .map(
        ({ name, availableQuantity }: { name: string; availableQuantity: number }) => `${name} - ${availableQuantity}개`
      )
      .join('\n')

    dispatch(
      doSetGlobalNoticeModal({
        text: '장바구니 포함\n최대 구매 가능 수량은',
        subText: maxQuantityProductOptionsInfoText,
        okText: '확인',
        buttonType: 'ACTION',
        visible: true,
        afterClose: () => {
          dispatch(
            doSetBottomSheetData({
              isProductChoiceVisible: false,
            })
          )
        },
      })
    )
  }

  const handleProductOptionSelect = ({ productOptionId }: { productOptionId: number }) => {
    if (!reduxProductInProductChoiceBottomSheet) {
      return
    }
    const isAlreadySelected = productOptions.find(
      (productOption) => productOption.productOptionId === productOptionId
    )?.isSelected

    if (isAlreadySelected) {
      dispatch(
        doSetGlobalToastContent({
          content: '이미 선택한 옵션입니다',
          variant: ToastConst.ToastVariant.withCTA,
        })
      )
      return
    }

    addProductOption({
      productId: reduxProductInProductChoiceBottomSheet.id,
      productOptionId,
      productPromotionId: reduxProductInProductChoiceBottomSheet.promotion?.id,
    })
  }

  const handleProductOptionRemove = ({ productOptionId }: { productOptionId: number }) => {
    if (!reduxProductInProductChoiceBottomSheet) {
      return
    }
    removeProductOption({
      productId: reduxProductInProductChoiceBottomSheet.id,
      productOptionId,
      productPromotionId: reduxProductInProductChoiceBottomSheet.promotion?.id,
    })
  }

  const handleProductOptionQuantityUpdate = ({
    productOptionId,
    quantity,
  }: {
    productOptionId: number
    quantity: number
  }) => {
    if (!reduxProductInProductChoiceBottomSheet) {
      return
    }
    updateProductOptionQuantity({
      productId: reduxProductInProductChoiceBottomSheet.id,
      productOptionId,
      productPromotionId: reduxProductInProductChoiceBottomSheet.promotion?.id,
      quantity,
    })
  }

  const handleCouponDownloadClick = () => {
    if (!reduxProductInProductChoiceBottomSheet) {
      return
    }
    if (!isUserLoggedIn) {
      dispatch(doSetToggleModalLogin(true))
      return
    }
    dispatch(
      doSetBottomSheetData({
        isProductCouponDownloadVisible: true,
      })
    )

    dispatch(
      doSetProductCouponDownloadBottomSheetData({
        productId: reduxProductInProductChoiceBottomSheet.id,
        productName: reduxProductInProductChoiceBottomSheet.name,
        addLocation: ProductLocation.BottomSheet,
        isRealSoldOut: reduxProductInProductChoiceBottomSheet.isRealSoldOut,
        buttonText: '쿠폰 받기',
        promotionId: reduxProductInProductChoiceBottomSheet.promotion?.id,
      })
    )

    trackClickListCouponDownloadEvent({
      addLocation: ProductLocation.BottomSheet,
      productId: reduxProductInProductChoiceBottomSheet.id,
      productName: reduxProductInProductChoiceBottomSheet.name,
      buttonText: '쿠폰 받기',
      hasApplicableProductCoupon: reduxProductInProductChoiceBottomSheet.hasApplicableProductCoupon,
      salePriceAfterCoupon:
        reduxProductInProductChoiceBottomSheet.salePriceAfterCoupon || reduxProductInProductChoiceBottomSheet.salePrice,
      isRealSoldOut: reduxProductInProductChoiceBottomSheet.isRealSoldOut,
    })
  }

  const handleClickAddToCart = async () => {
    const isSuccess = await addToCartItems({
      productPromotionId: reduxProductInProductChoiceBottomSheet?.promotion?.id,
      onSuccess: (ids) => {
        if (reduxCallbackInProductChoiceBottomSheet) {
          reduxCallbackInProductChoiceBottomSheet(ids)
        }
      },
    })
    // addToCartItems 실패 시 함수 종료
    if (!isSuccess || !reduxProductInProductChoiceBottomSheet) {
      return
    }

    const selectedOptionIds = productOptions
      ?.filter((productOption) => productOption.isSelected)
      .map((productOption) => productOption.productOptionId)

    const selectedOptionNames = productOptions
      ?.filter((productOption) => productOption.isSelected)
      .map((productOption) => productOption.name)

    if (reduxTrackerDataInProductChoiceBottomSheet?.addLocation === 'ProductDetail') {
      trackClickProductDetailAddToCartButtonEvent({
        product: reduxProductInProductChoiceBottomSheet,
        productOptionIds: selectedOptionIds,
        productOptionNames: selectedOptionNames,
        addToCartPrice: productSummary?.totalProductPrice || 0,
        trackerData: reduxTrackerDataInProductChoiceBottomSheet,
      })
    } else {
      trackClickListAddToCartButtonEventNew({
        product: reduxProductInProductChoiceBottomSheet,
        productOptionIds: selectedOptionIds,
        productOptionNames: selectedOptionNames,
        addToCartPrice: productSummary?.totalProductPrice || 0,
        trackerData: reduxTrackerDataInProductChoiceBottomSheet,
      })
    }

    dispatch(
      doSetCartConfirmationBottomSheetData({
        reduxProductInCartConfirmationBottomSheet: reduxProductInProductChoiceBottomSheet,
      })
    )
    if (pathname.startsWith('/cart')) {
      dispatch(
        doSetBottomSheetData({
          isProductChoiceVisible: false,
          isCartConfirmationVisible: false,
        })
      )
      dispatch(
        doSetGlobalToastContent({
          content: '상품이 장바구니에 담겼습니다.',
          variant: 'withCTA',
        })
      )
    } else {
      dispatch(
        doSetBottomSheetData({
          isProductChoiceVisible: false,
          isCartConfirmationVisible: true,
        })
      )
    }
  }

  useEffect(() => {
    if (!reduxProductInProductChoiceBottomSheet) {
      return
    }

    const fetchPurchaseEstimateData = async () => {
      await fetchPurchaseEstimate({
        productId: reduxProductInProductChoiceBottomSheet.id,
        products: [],
        productPromotionId: reduxProductInProductChoiceBottomSheet.promotion?.id,
      })
    }
    fetchPurchaseEstimateData()
    isFirstRenderRef.current = false
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reduxProductInProductChoiceBottomSheet])

  const isSelectedProductOptions = productOptions.filter((productOption) => productOption.isSelected)

  return (
    <>
      {(productOptions.length === 0 && isGetPurchaseEstimateMutateLoading) || isFirstRenderRef.current ? (
        <StyledSpinnerContainer>
          <Spinner />
        </StyledSpinnerContainer>
      ) : (
        <StyledProductChoiceWrapper
          className="product-choice"
          isSelectedProductOptions={isSelectedProductOptions.length > 0}
          hasApplicableProductCoupon={hasApplicableProductCoupon}
        >
          <div className="product-choice__top">
            {isSingleOption ? (
              <>
                {/* 단품 상품 옵션*/}
                <div className="product-choice__selected-item-list">
                  <ProductChoiceSelectedItemList
                    hasApplicableProductCoupon={hasApplicableProductCoupon}
                    isSingleOption={isSingleOption}
                    productOptions={productOptions}
                    onUpdateProductOptionQuantity={({ productOptionId, quantity }) =>
                      handleProductOptionQuantityUpdate({ productOptionId, quantity })
                    }
                  />
                </div>
              </>
            ) : (
              <>
                {/* 다중 상품 옵션 리스트 */}
                <div className="product-choice__select">
                  <ProductChoiceSelect
                    hasApplicableProductCoupon={hasApplicableProductCoupon}
                    productOptions={productOptions}
                    onSelectProductOption={({ productOptionId }) => handleProductOptionSelect({ productOptionId })}
                  />
                </div>
                {/* 선택된 다중 상품 옵션 리스트 */}
                {isSelectedProductOptions.length > 0 && (
                  <div className="product-choice__selected-item-list">
                    <ProductChoiceSelectedItemList
                      hasApplicableProductCoupon={hasApplicableProductCoupon}
                      productOptions={productOptions}
                      onRemoveProductOption={({ productOptionId }) => handleProductOptionRemove({ productOptionId })}
                      onUpdateProductOptionQuantity={({ productOptionId, quantity }) =>
                        handleProductOptionQuantityUpdate({ productOptionId, quantity })
                      }
                    />
                  </div>
                )}
              </>
            )}
          </div>
          {/* 상품 쿠폰 메세지 영역 */}
          {hasApplicableProductCoupon && (
            <div className="product-choice__coupon-download">
              <ProductChoiceCouponDownload
                onCouponDownloadClick={handleCouponDownloadClick}
                hasUnreceivedProductCoupon={hasUnreceivedProductCoupon}
              />
            </div>
          )}
          {/* 총 상품 금액, 장바구니 담기/ 바로 구매 버튼 */}
          <div className="product-choice__bottom">
            <ProductChoiceSelectBottom
              productSummary={productSummary}
              onClickAddToCart={handleClickAddToCart}
              productOptions={productOptions}
            />
          </div>
        </StyledProductChoiceWrapper>
      )}
    </>
  )
}

const StyledProductChoiceWrapper = styled.div<{
  isSelectedProductOptions: boolean
  hasApplicableProductCoupon?: boolean
}>`
  display: flex;
  flex-direction: column;
  max-height: calc(90vh - 60px);
  padding-top: 1.6rem;
  .product-choice {
    &__top {
      overflow-y: auto;
      ${({ hasApplicableProductCoupon }) => !hasApplicableProductCoupon && 'margin-bottom: 1.6rem;'}
    }
    &__select {
      padding: 0.1rem 1.6rem 0;
    }
    &__selected-item-list {
      padding: 0 1.6rem 0;
    }
    &__coupon-download {
      padding: 1rem 1.6rem 0rem;
      ${({ isSelectedProductOptions }) => isSelectedProductOptions && 'padding-bottom: 1.6rem;'}
    }

    &__bottom {
      padding-bottom: calc(env(safe-area-inset-bottom));
      margin-top: 1.6rem;
      ${({ isSelectedProductOptions }) => isSelectedProductOptions && 'margin-top: 0;'}
      position: sticky;
      background-color: ${({
        theme: {
          color: { grayWhite },
        },
      }) => grayWhite};
      bottom: 0;
      left: 0;
      width: 100%;
      box-shadow: rgb(0 0 0 / 5%) 0 -2px 6px;
    }
  }
`

const StyledSpinnerContainer = styled.div`
  height: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
`
