import { useEffect, useState } from 'react'
import ROUTES from '@/constants/legacy/constRoutes'
import { SocialServiceUserProfileType } from '@/constants/legacy/constType'
import { SocialServicesEnum } from '@/constants/social-services.enum'
import * as utilString from '@/utils/utilString'

const KakaoRestApiKey = process.env.KAKAO_REST_API_KEY
export type KakaoLoginResType = {
  accessToken: string
  expiresIn: number
  refreshToken: string
  refreshTokenExpiresIn: number
  scope: string
  tokenType: 'bearer' | string
}

export type KakaoStatusType = {
  status: 'connected' | 'not_connected'
  connectedAt: Date
  id: number
  kakaoAccount: {
    email: string
    emailNeedsAgreement: boolean
    hasEmail: boolean
    isEmailValid: boolean
    isEmailVerified: boolean
    profile: {
      nickname: string
      thumbnailImageUrl: string
      profileImageUrl: string
      isDefaultImage: boolean
    }
    profileNeedsAgreement: boolean
  }
}

type KakaoShareProps = {
  title: string
  description: string
  url: string
  buttonTitle: string
  imageUrl?: string
  sizes?: {
    width: number
    height: number
  }
}

type UseKakaoReturnProps = {
  logout: () => void
  makeSocialProfile: (loginRes: any, statusInfoRes: any) => SocialServiceUserProfileType
  share: ({ title, description, imageUrl, url }: KakaoShareProps) => void
  kakaoLogin: () => void
}

export const loadSdk = () => {
  return new Promise((resolve) => {
    const js: HTMLScriptElement = document.createElement('script')

    js.id = 'kakao-sdk'
    js.src = '//developers.kakao.com/sdk/js/kakao.min.js'
    js.onload = resolve

    document.body.append(js)
  })
}

const useKakao = () => {
  const [kakao, setKakao] = useState<any>()
  useEffect(() => {
    if (!kakao) {
      init()
    }
  }, [kakao])

  /**
   * useKakao Methods
   * */

  const init = async () => {
    // SSR의 경우, render 전에 build가 되어 window가 undefined된 상태가 될 수 있음
    if (typeof window === 'undefined') {
      return
    }
    await loadSdk()
    setTimeout(() => {
      // @ts-ignore
      const { Kakao } = window

      if (!!Kakao && !Kakao.isInitialized()) {
        Kakao.init(process.env.KAKAO_APP_KEY)
      }
      setKakao(Kakao)
    }, 300)
  }

  const makeSocialProfile = (loginRes: any, statusInfoRes: any): SocialServiceUserProfileType => {
    const reformRes: KakaoLoginResType = utilString.keysToCamel(loginRes)
    const reformStatusInfo: KakaoStatusType = utilString.keysToCamel(statusInfoRes)
    return setUserProfile(reformRes, reformStatusInfo)
  }

  const setUserProfile = (loginRes: KakaoLoginResType, status: KakaoStatusType): SocialServiceUserProfileType => {
    return {
      uniqueId: status.id.toString(),
      accessToken: loginRes.accessToken,
      accessTokenTtl: loginRes.expiresIn,
      refreshToken: loginRes.refreshToken,
      socialType: SocialServicesEnum.Kakao,
      email: status.kakaoAccount.email,
      clientType: 'WEB',
    }
  }

  const logout = () => {
    kakao.Auth.logout()
  }

  const share = ({ title, description, url, imageUrl = '', sizes, buttonTitle }: KakaoShareProps) => {
    /*
     * 인 앱에서 카카오 미설치를 웹에서 알수 있는 방법은 브릿지를 만들어야 합니다. 멤버십 런칭 이후 대응
     * https://app.asana.com/0/1203283987295728/1203505094133861/f
     * */
    // if (!kakao) {
    //   dispatch(
    //     doSetGlobalToastContent({
    //       content: '카카오톡이 설치되어 있지 않아요',
    //     })
    //   )
    //   return
    // }
    kakao.Share.sendDefault({
      objectType: 'feed',
      content: {
        title: title,
        description: description,
        imageUrl: imageUrl,
        ...(sizes && { imageWidth: sizes.width, imageHeight: sizes.height }),
        link: {
          mobileWebUrl: url,
          webUrl: url,
        },
      },
      buttons: [
        {
          title: buttonTitle,
          link: {
            mobileWebUrl: url,
            webUrl: url,
          },
        },
      ],
    })
  }

  const kakaoLogin = () => {
    if (typeof window !== 'undefined') {
      const kakaoRedirectUri = `${window.location.origin}${ROUTES.PREFIX}${ROUTES.ACCOUNTS.CALLBACK_KAKAO}`
      window.location.href = `https://kauth.kakao.com/oauth/authorize?client_id=${KakaoRestApiKey}&redirect_uri=${kakaoRedirectUri}&response_type=code`
    }
  }

  const useKakaoProps: UseKakaoReturnProps = {
    logout,
    makeSocialProfile,
    share,
    kakaoLogin,
  }

  return { useKakaoProps }
}

export default useKakao
