import styled from 'styled-components'
import { useSumCartQuantity } from '@/apis/rest/generated/hooks'
import { UserContainer } from '@/containers/users/UserContainer'

const LIMITED_VIEW_CART_COUNT = 99

const CartItemCountCircle = () => {
  const { me } = UserContainer.useContainer()
  const { data } = useSumCartQuantity({
    query: {
      enabled: !!me,
    },
  })

  const cartCount = !!me ? data?.count || 0 : 0

  if (!cartCount) {
    return null
  }

  // 장바구니 카운트가 99개 이상일 시 99개로 보이도록 처리
  const isOverLimitedCartCount = cartCount > LIMITED_VIEW_CART_COUNT
  const viewCartCount = isOverLimitedCartCount ? `${LIMITED_VIEW_CART_COUNT}+` : cartCount

  return (
    <StyledCircle isOverLimitedCartCount={isOverLimitedCartCount}>
      <StyledCount>{viewCartCount}</StyledCount>
    </StyledCircle>
  )
}

const StyledCircle = styled.div<{ isOverLimitedCartCount: boolean }>`
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  position: absolute;
  right: -0.6rem;
  top: -0.4rem;
  border-radius: 5rem;
  width: ${(props) => (props.isOverLimitedCartCount ? 'auto' : '1.8rem')};
  height: 1.8rem;
  padding: ${(props) => (props.isOverLimitedCartCount ? '0.1rem 0.2rem' : '0.1rem 0.3rem')};
  background-color: ${(props) => props.theme.color.red500};
`

const StyledCount = styled.div`
  text-align: center;
  font-family: Pretendard;
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.8rem;
  letter-spacing: -0.018rem;
  color: ${(props) => props.theme.color.grayWhite};
`

export default CartItemCountCircle
