import { useDispatch } from 'react-redux'
import styled, { useTheme } from 'styled-components'
import { match } from 'ts-pattern'
import { BottomSheetModal } from '@/components/common/modals/BottomSheetModal'
import { Typo, TypoVariant } from '@/components/common/typography'
import DownloadCouponCard from '@/components/domains/coupon/DownloadCouponCard'
import useEventDetailEventTracker from '@/containers/event-tracker/event-detail.event-tracker'
import { useAdCouponGroupsQuery, useDownloadEventCouponsMutation } from '@/graphql/generated/hooks'
import { CouponGroupCannotIssueReasonEnum, CouponGroupType } from '@/graphql/generated/schemas'
import { doSetBottomSheetData } from '@/stores/reduxData'
import { doSetGlobalToastContent } from '@/stores/reduxUI'
import { Base64ConverterUtil } from '@/utils/base64-converter.util'
import {
  checkExcludedCoupon,
  ExcludedCouponTypes,
  IncludedCouponTypes,
  makeCouponDisplayData,
  makeCouponIncludeTargetString,
} from '@/utils/coupon.util'
import { removeScrollbar } from '@/utils/utilCSS'
import { convertFromBase64 } from '@/utils/utilData'

const getCanNotIssueReasonText = (reason?: CouponGroupCannotIssueReasonEnum) => {
  return match(reason)
    .with(CouponGroupCannotIssueReasonEnum.OnlyMembership, () => '멤버십 전용 쿠폰은 멤버십 회원만 사용이 가능해요')
    .with(CouponGroupCannotIssueReasonEnum.OnlyFirstOrder, () => '이 쿠폰은 첫 구매 회원만 사용이 가능해요')
    .otherwise(() => '')
}

interface CartOrderCouponDownloadBottomSheetProps {
  visible: boolean
}
export const CartOrderCouponDownloadBottomSheet = ({ visible }: CartOrderCouponDownloadBottomSheetProps) => {
  const { color } = useTheme()
  const dispatch = useDispatch()
  const { trackerClickEventsaleCouponDownload } = useEventDetailEventTracker()

  const [downloadCoupon] = useDownloadEventCouponsMutation({
    onCompleted: () => {
      refetch()
      dispatch(
        doSetGlobalToastContent({
          content: '쿠폰이 발급되었어요',
        })
      )
    },
  })

  const {
    data: appCouponData,
    refetch,
    loading,
  } = useAdCouponGroupsQuery({
    variables: {
      slug: 'app-only',
    },
  })

  const handleCouponDownload = async ({
    id,
    couponName,
    canIssue,
    isDisableCoupon,
  }: {
    id: CouponGroupType['id']
    canIssue: CouponGroupType['canIssue']
    couponName: CouponGroupType['name']
    isDisableCoupon: boolean
  }) => {
    if (!canIssue || (canIssue && isDisableCoupon)) {
      return
    }

    await downloadCoupon({
      variables: {
        input: {
          couponGroup: id,
        },
      },
    })

    const adId = appCouponData?.adBySlug?.id
    const adName = appCouponData?.adBySlug?.name
    const adSlug = appCouponData?.adBySlug?.slug

    if (!adId) return

    trackerClickEventsaleCouponDownload({
      adId,
      adName,
      adSlug,
      couponGroupId: id,
      couponName,
    })
  }

  const handleClickConfirm = () => {
    dispatch(
      doSetBottomSheetData({
        isCartOrderCouponDownloadVisible: false,
      })
    )
  }

  const couponGroups = appCouponData?.adBySlug?.couponGroups?.edges.map((e) => e.node)

  return (
    <BottomSheetModal
      title="주문쿠폰"
      centerTitle
      titleNoBorder
      visible={visible}
      onClose={() => {
        dispatch(
          doSetBottomSheetData({
            isCartOrderCouponDownloadVisible: false,
          })
        )
      }}
    >
      <StyledModalContent className={visible ? 'enter' : ''} onClick={(e) => e.stopPropagation()}>
        <StyledNoticeContainer>
          <StyledNotice>
            <Typo variant={TypoVariant.Body3Medium} color={color.gray600}>
              주문쿠폰 적용은 결제화면에서 가능해요
            </Typo>
          </StyledNotice>
        </StyledNoticeContainer>

        <StyledModalBody>
          {couponGroups?.map((couponGroup) => {
            if (!couponGroup) return
            const {
              id,
              name,
              canConjunctUse,
              canIssue,
              isMembershipCoupon,
              includedBrands,
              includedCategories,
              includedProducts,
              excludedBrands,
              excludedCategories,
              excludedProducts,
              canNotIssueReason,
            } = couponGroup

            const { title, amount, datePeriodForDownload } = makeCouponDisplayData(couponGroup)

            const couponCoverageContent = makeCouponIncludeTargetString({
              includedBrands,
              includedCategories,
              includedProducts,
            } as IncludedCouponTypes)

            const isExcludeCouponCoverage = checkExcludedCoupon({
              excludedBrands,
              excludedCategories,
              excludedProducts,
            } as ExcludedCouponTypes)

            // canNotIssueReason 값이 없고 있어도 hasCoupon이 아닌 경우 다운로드 가능
            const isAvailableDownload =
              !canNotIssueReason && canNotIssueReason !== CouponGroupCannotIssueReasonEnum.HasCoupon
            // 사용 불가 사유가 아닌 쿠폰
            const isAvailableReason =
              canNotIssueReason !== CouponGroupCannotIssueReasonEnum.OnlyMembership &&
              canNotIssueReason !== CouponGroupCannotIssueReasonEnum.OnlyFirstOrder

            return (
              <DownloadCouponCard
                key={id}
                title={title}
                isMembership={!!isMembershipCoupon}
                isConjunctUse={canConjunctUse}
                name={name}
                amount={amount}
                datePeriod={`${datePeriodForDownload} 다운 가능`}
                couponCoverageContent={couponCoverageContent}
                isExcludeCouponCoverage={isExcludeCouponCoverage}
                isAvailableDownload={isAvailableDownload}
                onClick={() =>
                  handleCouponDownload({
                    id,
                    couponName: name,
                    canIssue,
                    isDisableCoupon: !isAvailableReason,
                  })
                }
                disabled={!isAvailableReason}
                errorMessage={getCanNotIssueReasonText(canNotIssueReason)}
              />
            )
          })}
        </StyledModalBody>
      </StyledModalContent>
      <StyledFooter>
        <StyledButton type="button" onClick={handleClickConfirm}>
          <Typo variant={TypoVariant.Body1Bold} color={color.grayWhite}>
            확인
          </Typo>
        </StyledButton>
      </StyledFooter>
    </BottomSheetModal>
  )
}

const StyledNoticeContainer = styled.div`
  padding: 1rem 1.6rem;
`

const StyledNotice = styled.div`
  background-color: ${({ theme }) => theme.color.gray50};
  padding: 1.2rem 1.6rem;
  border-radius: 0.8rem;
`

const StyledModalContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 63vh;
  height: auto;
  overflow: hidden;
`

const StyledModalBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 0.8rem;
  min-height: 16rem;
  width: 100%;
  padding: 1rem 1.6rem 1.6rem 1.6rem;
  background-color: ${({ theme }) => theme.color.grayWhite};
  overflow-y: scroll;
  ${removeScrollbar}
`

const StyledFooter = styled.div`
  background-color: ${({
    theme: {
      color: { grayWhite },
    },
  }) => grayWhite};
  position: sticky;
  bottom: 0;
  padding: 1.6rem 1.6rem calc(env(safe-area-inset-bottom) + 1.6rem);
`

const StyledButton = styled.button`
  cursor: pointer;
  width: 100%;
  padding: 1.6rem;
  border: none;
  border-radius: 0.8rem;
  background-color: ${({
    theme: {
      color: { blue500 },
    },
  }) => blue500};
`
