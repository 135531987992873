import { FC } from 'react'
import styled from 'styled-components'
import { GlobalColorEnum } from '@/constants/global-color.enum'

type SpinnerProps = {
  wrap?: boolean
}

const Spinner: FC<SpinnerProps> = ({ wrap = false }) => {
  if (wrap) {
    return (
      <SpinnerWrapper>
        <StyledSpinner />
      </SpinnerWrapper>
    )
  }
  return <StyledSpinner />
}

const StyledSpinner = styled.div`
  border: 2px solid #f3f3f3;
  border-radius: 50%;
  border-top: 2px solid ${GlobalColorEnum.Blue500};
  width: 26px;
  height: 26px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;

  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

const SpinnerWrapper = styled.div`
  width: 100%;
  height: 75vh;
  display: flex;
  justify-content: center;
  align-items: center;
`

export default Spinner
