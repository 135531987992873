const ApiErrorUtils = {
  getError: (error: unknown): { code: string; message: string } => {
    if (!error || typeof error === 'string') {
      return { code: '', message: (error as string) || '' }
    }

    if (typeof error === 'object' && 'message' in error) {
      const message = error['message']
      if (message && typeof message === 'string') {
        try {
          const errorInfo: { code: string; message: string } = JSON.parse(message)
          return errorInfo
        } catch (error) {
          return { code: '', message }
        }
      } else if (
        message &&
        typeof message === 'object' &&
        'message' in message &&
        typeof message['message'] === 'string'
      ) {
        return { code: '', message: message['message'] }
      }

      return { code: '', message: '오류가 발생했습니다.' }
    }
    return { code: '', message: '오류가 발생했습니다.' }
  },
}

export { ApiErrorUtils }
