import { FC } from 'react'
import { useRouter } from 'next/router'
import { useSelector } from 'react-redux'
import styled, { keyframes } from 'styled-components'
import { RootState } from '@/stores/store'

type ApiLoaderProps = {}

const ApiLoader: FC<ApiLoaderProps> = () => {
  const visible = useSelector((rootState: RootState) => rootState.reduxUIReducers.visibleLoader)
  const { asPath } = useRouter()

  // 장바구니에서 로더 2개 표기로 인한 탭 2개 예외처리
  const disabledPaths = ['/cart?tab=like', '/cart?tab=frequently']

  if (!visible || disabledPaths.includes(asPath)) {
    return null
  }

  return (
    <StyledContainer>
      <StyledFixed>
        <StyledSpin />
      </StyledFixed>
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

const StyledFixed = styled.div`
  margin: 0;
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
`

const rotation = keyframes`
  from{
    transform: rotate(0deg);
  }
  to{
    transform: rotate(360deg);
  }
`

const StyledSpin = styled.div`
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background: transparent;
  border: 2px solid transparent;
  border-top: 2px solid ${({ theme }) => theme.color.blue500};
  border-right: 2px solid ${({ theme }) => theme.color.blue500};
  clip-path: polygon(50% 50%, 0% 0%, 100% 0%, 100% 50%);
  animation: ${rotation} 1s linear infinite;
`
export default ApiLoader
