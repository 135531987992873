import { ProductOptionCartType } from '@/constants/legacy/constType'
import {
  IModelProductOptionDefault,
  IModelProductOptionSkuDefault,
} from '@/containers/models/base/defaultModelInterfaces'
import { ModelProductOptionDefault } from '@/containers/models/base/defaultModels'
import { IModelProduct } from '@/containers/models/modelProduct'

export interface IModelProductOption extends IModelProductOptionDefault {
  product: IModelProduct

  convertToProductOptionOrder: (
    cartId: string,
    productNumber: string,
    purchaseQuantity: number
  ) => ProductOptionCartType
  getName: () => string | undefined

  getPrice: () => number
  getCustomerPrice: () => number

  isSoldOut: boolean
  optionName: string
  customerPrice: number
  disabled: boolean
  mileage: number
  pricePerSku: number
  maxProductOptionSkuCount: number
}

export default class ModelProductOption extends ModelProductOptionDefault {
  isSoldOut: boolean = false
  optionName: string = ''
  customerPrice: number = 0
  disabled: boolean = false
  mileage: number = 0
  pricePerSku: number = 0
  maxProductOptionSkuCount: number = 0

  constructor(data: IModelProductOption) {
    // @ts-ignore
    super(data)
    this.isSoldOut = this.stockQuantity === 0 || data.stockQuantity === 0
    this.optionName = data.isDefault && data.product?.name ? data.product.name : data.name
    this.customerPrice =
      data.productPromotionCustomerPrice > 0 ? data.productPromotionCustomerPrice : data.customerPrice
    this.disabled = data.isDelete || !data.isUse || data.product?.disabledByAdmin || data.product?.isPromotionExpired
    this.mileage = data.mileage
    this.pricePerSku = data.pricePerSku ?? 0
    this.maxProductOptionSkuCount = data.maxProductOptionSkuCount ?? 0
  }

  convertToProductOptionOrder = (
    cartId: string,
    productNumber: string,
    purchaseQuantity: number
  ): ProductOptionCartType => {
    let stockQuantity = this.stockQuantity
    if (this.product.isSoldOut) {
      stockQuantity = 0
    }

    return {
      cartId,
      productNumber,
      purchaseQuantity,
      id: this.id,
      _id: this._id,
      optionName: this.optionName,
      stockQuantity,
      price: this.getPrice(),
      customerPrice: this.getCustomerPrice(),
      disabled: this.disabled,
      productPromotionCustomerPrice: this.productPromotionCustomerPrice,
      categories: this.product.categories,
      productOptionSkus: this.productOptionSkus as IModelProductOptionSkuDefault[],
      pricePerSku: this.pricePerSku,
      maxProductOptionSkuCount: this.maxProductOptionSkuCount,
    }
  }

  getCustomerPrice = () => {
    if (this.productPromotionCustomerPrice > 0) {
      return this.productPromotionCustomerPrice
    }

    return this.customerPrice
  }

  getPrice = () => {
    // if ( this.productPromotionCustomerPrice > 0 ) {
    //   return this.customerPrice
    // }

    return this.price
  }

  getName = () => {
    try {
      return this.name
    } catch {
      return undefined
    }
  }
}
