import { useRef, useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import { POLICY } from '@/constants/legacy/constData'
import { PRODUCT_QUERY_GQLS } from '@/containers/gqls/products/queries'
import { IModelProductOption } from '@/containers/models/modelProductOption'
import {
  CartProductOptionKeyType,
  CartProductOptionType,
  CartProductOptionTypeUtil,
} from '@/containers/types/CartProductOption'
import { AppCartProductOptionType, AppStorageDataType, Bridge } from '@/utils/bridge/bridge'
import { getResultFromData } from '@/utils/utilApi'
import appBridgeProvider, { isAppNonLoginCart } from '@/utils/utilBridge'
import { deepcopy } from '@/utils/utilData'
import { localCartProductOptionsStorage } from '@/utils/utilLocalStorage'

export const useCartLocal = ({
  handleCartProductOptionChange,
}: {
  handleCartProductOptionQuantityChange?: (cartProductOptionQuantity: number) => void
  handleCartProductOptionChange?: (cartProductOptions: CartProductOptionType[]) => void
}) => {
  const _load = () => {
    return localCartProductOptionsStorage.load()
  }

  const [localCartProductOptions, setLocalCartProductOptions] = useState<CartProductOptionType[]>([])
  const inAppLocalCartProductOptionsQuantity = useRef<{ purchaseQuantity: number }[]>([])

  const [queryNonLoginCartProductOptions] = useLazyQuery(PRODUCT_QUERY_GQLS.CART_PRODUCT_OPTIONS, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      if (data) {
        const productOptionsData = getResultFromData(data)?.productOptionsWithPromotion.data
        const cartProductOptions: CartProductOptionType[] = []
        if (productOptionsData) {
          productOptionsData.forEach((item: IModelProductOption, index: number) => {
            const quantity = inAppLocalCartProductOptionsQuantity.current[index].purchaseQuantity
            const cartProductOption = CartProductOptionTypeUtil.createCartProductOptionByProductOption(item, quantity)
            cartProductOptions.push(cartProductOption)
          })
        }
        _setLocalCartProductOptions(cartProductOptions)
      }
    },
  })

  const clear = () => {
    localCartProductOptionsStorage.remove()
    _setLocalCartProductOptions([])
  }

  const isEmpty = () => {
    return localCartProductOptions.length === 0
  }

  const _setLocalCartProductOptions = (newLocalCartProductOptions: CartProductOptionType[]) => {
    localCartProductOptionsStorage.save(newLocalCartProductOptions)
    setLocalCartProductOptions(newLocalCartProductOptions)
    if (handleCartProductOptionChange) {
      handleCartProductOptionChange(newLocalCartProductOptions)
    }
  }

  const refreshCartProductOptions = () => {
    if (isAppNonLoginCart) {
      appBridgeProvider((bridge: Bridge) =>
        bridge.storageGetItem('nonLoginCartList').then((data: AppStorageDataType<AppCartProductOptionType[]>) => {
          if (!data.data) {
            data.data = []
          }
          const inputCartProductOptions = JSON.stringify(
            data.data.map((item) => {
              inAppLocalCartProductOptionsQuantity.current.push({ purchaseQuantity: item.purchaseQuantity })
              return item.productPromotionId
                ? {
                    productOption: item.productOptionId,
                    productPromotion: item.productPromotionId,
                  }
                : {
                    productOption: item.productOptionId,
                  }
            })
          ).replace('\\', '')

          queryNonLoginCartProductOptions({
            variables: {
              productOptions: inputCartProductOptions,
            },
          })
        })
      )
    } else {
      _setLocalCartProductOptions(_load())
    }
  }

  const getCartProductOptionsCount = (): number => {
    return CartProductOptionTypeUtil.getCartProductOptionsCount(localCartProductOptions)
  }
  const getCartProductOptions = (): CartProductOptionType[] => {
    return localCartProductOptionsStorage.load()
  }
  const removeCartProductOption = (productOptionKey: CartProductOptionKeyType) => {
    const newLocalCartProductOptions = CartProductOptionTypeUtil.removeCartProductOption(
      localCartProductOptions,
      productOptionKey
    )
    if (isAppNonLoginCart) {
      const appCartProductOption = CartProductOptionTypeUtil.createAppCartProductOption(
        CartProductOptionTypeUtil.getCartProductOption(localCartProductOptions, productOptionKey)!
      )
      appBridgeProvider((bridge: Bridge) => {
        bridge.deleteNonLoginCartProductOptions([appCartProductOption]).then((data: { status: boolean }) => {
          if (data.status) {
            _setLocalCartProductOptions(newLocalCartProductOptions)
          } else {
            console.error('Fail : deleteNonLoginCartProductOptions')
          }
        })
      })
    } else {
      _setLocalCartProductOptions(newLocalCartProductOptions)
    }
  }
  const removeCartProductOptions = (productOptionKeys: CartProductOptionKeyType[]) => {
    const newLocalCartProductOptions = CartProductOptionTypeUtil.removeCartProductOptions(
      localCartProductOptions,
      productOptionKeys
    )
    if (isAppNonLoginCart) {
      const appCartProductOptions = productOptionKeys.map((productOptionKey) => {
        const cartProductOption = CartProductOptionTypeUtil.getCartProductOption(
          localCartProductOptions,
          productOptionKey
        )!
        return CartProductOptionTypeUtil.createAppCartProductOption(cartProductOption)
      })

      appBridgeProvider((bridge: Bridge) => {
        bridge.deleteNonLoginCartProductOptions(appCartProductOptions).then((data: { status: boolean }) => {
          if (data.status) {
            _setLocalCartProductOptions(newLocalCartProductOptions)
          } else {
            console.error('Fail : deleteNonLoginCartProductOptions')
          }
        })
      })
    } else {
      _setLocalCartProductOptions(newLocalCartProductOptions)
    }
  }
  const addOrUpdateCartProductOptions = (cartProductOptions: CartProductOptionType[]) => {
    const newLocalCartProductOptions = CartProductOptionTypeUtil.mergeCartProductOptions(
      localCartProductOptions,
      cartProductOptions
    )
    if (isAppNonLoginCart) {
      appBridgeProvider((bridge: Bridge) =>
        bridge.storageGetItem('nonLoginCartList').then((data: AppStorageDataType<AppCartProductOptionType[]>) => {
          if (!data.data) {
            data.data = []
          }
          const appCartProductOptions = data.data
          const addAppCartProductOptions: AppCartProductOptionType[] = []
          const updateAppCartProductOptions: AppCartProductOptionType[] = []
          cartProductOptions.forEach((cartProductOption) => {
            const existsCartProductOption = appCartProductOptions.find((appCartProductOption) => {
              return (
                cartProductOption.productOptionId === appCartProductOption.productOptionId &&
                cartProductOption.productPromotionId === appCartProductOption.productPromotionId
              )
            })
            if (existsCartProductOption) {
              existsCartProductOption.purchaseQuantity += cartProductOption.purchaseQuantity
              updateAppCartProductOptions.push(existsCartProductOption)
            } else {
              addAppCartProductOptions.push(CartProductOptionTypeUtil.createAppCartProductOption(cartProductOption))
            }
          })
          if (updateAppCartProductOptions.length > 0) {
            appBridgeProvider((bridge: Bridge) => {
              bridge.updateNonLoginCartProductOptions(updateAppCartProductOptions).then((data: { status: boolean }) => {
                if (data.status) {
                  _setLocalCartProductOptions(newLocalCartProductOptions)
                } else {
                  console.error('Fail : updateNonLoginCartProductOptions')
                }
              })
            })
          }
          if (addAppCartProductOptions.length > 0) {
            appBridgeProvider((bridge: Bridge) => {
              bridge.insertNonLoginCartProductOptions(addAppCartProductOptions).then((data: { status: boolean }) => {
                if (data.status) {
                  _setLocalCartProductOptions(newLocalCartProductOptions)
                } else {
                  console.error('Fail : insertNonLoginCartProductOptions')
                }
              })
            })
          }
        })
      )
    } else {
      _setLocalCartProductOptions(newLocalCartProductOptions)
    }
  }
  const updateCartProductOption = (
    cartProductOptionKey: CartProductOptionKeyType,
    cartProductOption: CartProductOptionType,
    availableMaxPurchaseQuantity?: number
  ) => {
    const mergeCartProductOption = CartProductOptionTypeUtil.getCartProductOption(
      localCartProductOptions,
      CartProductOptionTypeUtil.getCartProductOptionKey(cartProductOption)
    )

    let cartProductOptionForUpdate = CartProductOptionTypeUtil.getCartProductOption(
      localCartProductOptions,
      cartProductOptionKey
    )
    const oldProductOption = deepcopy(cartProductOptionForUpdate)
    if (mergeCartProductOption && cartProductOptionForUpdate) {
      const oldCartProductOptionQuantity = cartProductOptionForUpdate.purchaseQuantity
      cartProductOptionForUpdate = mergeCartProductOption
      const mergeQuantity = oldCartProductOptionQuantity + cartProductOptionForUpdate.purchaseQuantity
      if (availableMaxPurchaseQuantity !== undefined) {
        const adjustAbleMaxQuantity =
          mergeQuantity > availableMaxPurchaseQuantity ? availableMaxPurchaseQuantity : mergeQuantity
        cartProductOptionForUpdate.purchaseQuantity =
          adjustAbleMaxQuantity > POLICY.MAX_PURCHASE_QUANTITY_AT_ONCE
            ? POLICY.MAX_PURCHASE_QUANTITY_AT_ONCE
            : adjustAbleMaxQuantity
      } else {
        cartProductOptionForUpdate.purchaseQuantity =
          mergeQuantity > POLICY.MAX_PURCHASE_QUANTITY_AT_ONCE ? POLICY.MAX_PURCHASE_QUANTITY_AT_ONCE : mergeQuantity
      }
      removeCartProductOption(cartProductOptionKey)
    }

    if (cartProductOptionForUpdate) {
      CartProductOptionTypeUtil.setCartProductOption(cartProductOptionForUpdate, cartProductOption)
      if (isAppNonLoginCart) {
        const appCartProductOption = CartProductOptionTypeUtil.createAppCartProductOption(cartProductOptionForUpdate)
        const oldAppCartProductOption = CartProductOptionTypeUtil.createAppCartProductOption(oldProductOption)
        appBridgeProvider((bridge: Bridge) => {
          bridge.deleteNonLoginCartProductOptions([oldAppCartProductOption]).then((data: { status: boolean }) => {
            if (data.status) {
              appBridgeProvider((bridge: Bridge) => {
                bridge.insertNonLoginCartProductOptions([appCartProductOption]).then((data: { status: boolean }) => {
                  if (data.status) {
                    _setLocalCartProductOptions(localCartProductOptions)
                  } else {
                    console.error('Fail : insertNonLoginCartProductOptions')
                  }
                })
              })
            } else {
              console.error('Fail : deleteNonLoginCartProductOptions')
            }
          })
        })
      } else {
        _setLocalCartProductOptions(localCartProductOptions)
      }
    }
  }
  const updateCartProductOptionPurchaseQuantity = (
    cartProductOptionKey: CartProductOptionKeyType,
    purchaseQuantity: number
  ) => {
    const localCartProductOption = CartProductOptionTypeUtil.getCartProductOption(
      localCartProductOptions,
      cartProductOptionKey
    )
    if (localCartProductOption) {
      localCartProductOption.purchaseQuantity = purchaseQuantity
      if (isAppNonLoginCart) {
        const cartProductOptionInCart = CartProductOptionTypeUtil.getCartProductOption(
          localCartProductOptions,
          cartProductOptionKey
        )!
        const appCartProductOption = CartProductOptionTypeUtil.createAppCartProductOption(cartProductOptionInCart)

        appBridgeProvider((bridge: Bridge) => {
          bridge.updateNonLoginCartProductOptions([appCartProductOption]).then((data: { status: boolean }) => {
            if (data.status) {
              _setLocalCartProductOptions(localCartProductOptions)
            } else {
              console.error('Fail : updateNonLoginCartProductOptions')
            }
          })
        })
      } else {
        _setLocalCartProductOptions(localCartProductOptions)
      }
    }
  }
  return {
    clear,
    isEmpty,
    getCartProductOptionsCount,
    getCartProductOptions,
    removeCartProductOption,
    removeCartProductOptions,
    addOrUpdateCartProductOptions,
    updateCartProductOption,
    updateCartProductOptionPurchaseQuantity,
    queryNonLoginCartProductOptions,
    refreshCartProductOptions,
  }
}
