import { FC } from 'react'
import styled from 'styled-components'
import { Image } from '@/components/common/images/Image'
import { S3_HOST_URL } from '@/constants/legacy/constEnv'
import { FIXED_SIZE } from '@/constants/legacy/constLayout'
import { ProductThumbnailSizeEnum } from '@/constants/product-thumbnail-size.enum'
import { BrandType } from '@/graphql/generated/schemas'
import { getThumbnailPlaceHolder } from '@/utils/utilURL'

type BrandCircleItemProps = {
  node: BrandType
  onClick?: () => void
}

const BrandCircleItem: FC<BrandCircleItemProps> = ({ node, onClick }) => {
  const { name, listImage } = node
  const _listImage = listImage
    ? `${S3_HOST_URL}/${listImage}`
    : getThumbnailPlaceHolder(ProductThumbnailSizeEnum.Card136)

  return (
    <StyledBrandItem onClick={onClick}>
      <StyledImage>
        <Image src={_listImage} alt="Brand Logo" width="100%" />
      </StyledImage>
      <BrandTextRow fontSize={13} data-cy="search-popular-brand">
        {name}
      </BrandTextRow>
    </StyledBrandItem>
  )
}

const StyledBrandItem = styled.div`
  display: inline-block;
  cursor: pointer;
  margin: 0 6px;

  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
`

const StyledImage = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${FIXED_SIZE.POPULAR_BRAND_LOGO}px;
  height: ${FIXED_SIZE.POPULAR_BRAND_LOGO}px;
  overflow: hidden;
  border-radius: 50%;

  ::after {
    content: '';
    position: absolute;
    width: ${FIXED_SIZE.POPULAR_BRAND_LOGO}px;
    height: ${FIXED_SIZE.POPULAR_BRAND_LOGO}px;
    border: 1px solid rgba(0, 0, 0, 0.03);
    border-radius: 50%;
  }
`

const BrandTextRow = styled.div<{ fontSize: number }>`
  margin-block-start: 8px;
  margin-inline: auto;
  text-align: center;
  color: ${(props) => props.theme.color.gray900};
  font-size: ${({ fontSize }) => fontSize}px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.02em;

  // 4글자 넘는 경우, ellipsis 로 표시
  max-width: ${({ fontSize }) => fontSize * 4}px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export default BrandCircleItem
