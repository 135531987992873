import { ApolloError } from '@apollo/client'
import { GraphQLError } from 'graphql'
import { DataBase, DataResult, DataResults } from '@/containers/gqls/data'

export function maybe<T>(exp: () => T, d?: T) {
  try {
    const result = exp()
    return result === undefined ? d : result
  } catch {
    return d
  }
}

export function decodeId(_encodeId: string): { typename: string; id: string } {
  /*
    서버에서 scalar ID는 base64로 encoding해서 보내줌
    ex) ID: "U2VsbGVyUm9sZVN0YXR1c1R5cGU6MQ==" => SellerRoleStatusType:1
    참고: https://docs.graphene-python.org/en/latest/relay/nodes/#quick-example
   */

  if (!_encodeId) return { typename: '', id: '' }

  // Why is window undefined?
  // NextJS is a framework that allows you to build Static and Server Side Rendered Apps.
  // It uses NodeJS to render your application and window is not defined in NodeJS.
  // That means that we need to be careful that our code that accesses the window object is not run in NodeJS.
  const infoId = Buffer?.from(_encodeId, 'base64').toString().split(':')
  return { typename: infoId[0], id: infoId[1] }
}

/**
 * 이벤트 트래커의 id는 숫자형식의 식별번호를 사용
 * @example id가 Q291cG9uR3JvdXBUeXBlOjM0MDQ= 이면, eventsale_id: 3404로 변환
 */
export const decodeIdForEventTracker = (id: string | undefined) => {
  return id ? decodeId(id).id : ''
}

/**
 * @deprecated
 */
export function encodeId(typename: string, id?: string | number): string {
  return Buffer.from(`${typename}:${id?.toString()}`).toString('base64')
}

function getDataFromObject(obj: object) {
  if (DataBase.isListData(obj)) {
    return new DataResults().setData(obj)
  }
  return new DataResult().setData(obj)
}

export function getResultFromData(data: object, isQuery: boolean = true) {
  // this function called when declared so return if data is undefined
  if (!data) return
  // query data can have 2 nodes
  // array node have __typename with '-Connection', with edges key
  // nested if value is object, and that value act as key with __typename, value

  if (!isQuery) {
    // eslint-disable-next-line no-param-reassign,prefer-destructuring
    data = Object.values(data)[0]
  }

  const res: { [index: string]: DataResult | DataResults } = {}
  Object.entries(data).forEach(([key, value]) => {
    if (key !== '__typename') {
      res[key] = getDataFromObject(value)
    }
  })
  return res
}

export function getErrorMessage(error?: ApolloError | string): string {
  if (!error) {
    return ''
  }
  const _message = typeof error === 'string' ? error : error.message
  try {
    const _error = JSON.parse(_message)
    return _error.message
  } catch (e) {
    return _message
  }
}

export function getErrorMessageAndCode(error?: ApolloError | GraphQLError | string): { message: string; code: string } {
  if (!error) {
    return { message: '', code: '' }
  }
  const _message = typeof error === 'string' ? error : error.message
  try {
    const _error = JSON.parse(_message)
    return _error
  } catch (e) {
    return { message: '', code: '' }
  }
}
