enum PaymentMethodTypeEnum {
  CreditCard = 'card',
  NaverPay = 'naverpay',
  KakaoPay = 'kakaopay',
  TossPay = 'tosspay',
  Payco = 'payco',
  VirtualAccount = 'vbank',
  Bank = 'trans',
  Mobile = 'phone',
  Mileage = 'mileage',
  ZeroPrice = 'zero_price',
  Point = 'point',
  KbPay = 'kbpay',
}

const IamPortBankCode = {
  KB: '381',
} as const

export { PaymentMethodTypeEnum, IamPortBankCode }
