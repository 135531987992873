import { forwardRef } from 'react'
import { styled } from 'styled-components'

export interface CheckboxProps extends React.ComponentPropsWithoutRef<'input'> {
  /** label태그에 대한 스타일 */
  labelStyles?: React.CSSProperties
}

/**
 * @deprecated
 * antd의 Checkbox 컴포넌트를 대체하는 Checkbox 컴포넌트
 *
 * antd 사용하는 Form.Item에서만 사용하는 컴포넌트
 *
 * antd는 제거할 예정이니, 이 컴포넌트는 사용하지 않는다.
 */
export const CheckboxAntd = forwardRef<HTMLInputElement, CheckboxProps>(({ labelStyles, children, ...rest }, ref) => (
  <StyledLabel style={labelStyles}>
    <span>
      <input type="checkbox" ref={ref} {...rest} />
      <span />
    </span>
    <div>{children}</div>
  </StyledLabel>
))

CheckboxAntd.displayName = 'CheckboxAntd'

const StyledLabel = styled.label`
  display: inline-flex;
  align-items: baseline;
  column-gap: 0.8rem;
  line-height: unset;
  cursor: pointer;
  user-select: none;

  & > span:first-child {
    position: relative;
    top: 0.2em;
    white-space: nowrap;
    cursor: pointer;

    & > input[type='checkbox'] {
      width: 100%;
      height: 100%;
      position: absolute;
      inset: 0;
      opacity: 0;
      cursor: pointer;
    }

    & > input[type='checkbox']:checked {
      background-color: ${({ theme }) => theme.color.blue500};
      border-color: ${({ theme }) => theme.color.blue500};
      border-radius: 2px;
    }

    & > span {
      position: relative;
      top: 0;
      left: 0;
      display: block;
      width: 16px;
      height: 16px;
      background-color: ${({ theme }) => theme.color.grayWhite};
      border: 1px solid #d9d9d9;
      border-radius: 2px;
      transition: all 0.3s;
      box-sizing: content-box;

      &::after {
        position: absolute;
        top: 50%;
        left: 21.5%;
        display: table;
        width: 5.71428571px;
        height: 9.14285714px;
        border: 2px solid #d9d9d9;
        border-top: 0;
        border-left: 0;
        transform: rotate(45deg) scale(1) translate(-50%, -50%);
        opacity: 1;
        transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
        content: ' ';
      }
    }

    > input[type='checkbox']:checked + span {
      background-color: ${({ theme }) => theme.color.blue500};
      border-color: ${({ theme }) => theme.color.blue500};

      &:hover {
        border: 1px solid #1890ff;
      }
    }
  }
`
