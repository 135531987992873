import { FieldKeysEnum } from '@/constants/field-keys.enum'
import { IModelCustomerTemplateAddressDefault } from '@/containers/models/base/defaultModelInterfaces'
import { ModelCustomerTemplateAddressDefault } from '@/containers/models/base/defaultModels'

export interface IModelCustomerTemplateAddress extends IModelCustomerTemplateAddressDefault {
  messageSelect: string
  initFormValues: () => any

  getFullAddressText: () => string
  getName: () => string
  getOrderCustomerAddressInput: () => any
}

export default class ModelCustomerTemplateAddress extends ModelCustomerTemplateAddressDefault {
  initFormValues = () => {
    return {
      [FieldKeysEnum.Name]: this.name,
      [FieldKeysEnum.TemplateName]: this.templateName,
      [FieldKeysEnum.IsDefault]: this.isDefault,
      [FieldKeysEnum.MobileNumber]: this.mobileNumber,
      [FieldKeysEnum.BasicAddress]: this.basicAddress,
      [FieldKeysEnum.DetailAddress]: this.detailedAddress,
      [FieldKeysEnum.PostNumber]: this.postNumber,
    }
  }

  getOrderCustomerAddressInput = () => {
    return {
      [FieldKeysEnum.Name]: this.name,
      [FieldKeysEnum.TemplateName]: this.templateName,
      [FieldKeysEnum.IsDefault]: this.isDefault,
      [FieldKeysEnum.MobileNumber]: this.mobileNumber,
      [FieldKeysEnum.BasicAddress]: this.basicAddress,
      [FieldKeysEnum.DetailAddress]: this.detailedAddress,
      [FieldKeysEnum.PostNumber]: this.postNumber,
      customerTemplateAddress: this.id,
    }
  }

  getFullAddressText = () => {
    const { postNumber, basicAddress, detailedAddress } = this
    return `[${postNumber}] ${basicAddress} ${detailedAddress || ''}`
  }

  getName = () => {
    if (this.isDefault) {
      return `${this.name} (기본 배송지)`
    }

    return this.name
  }
}
