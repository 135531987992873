import { IModelCustomerAddressDefault } from '@/containers/models/base/defaultModelInterfaces'
import { ModelCustomerAddressDefault } from '@/containers/models/base/defaultModels'

export interface IModelCustomerAddress extends IModelCustomerAddressDefault {
  getFullAddressText: () => string
}

export default class ModelCustomerAddress extends ModelCustomerAddressDefault {
  getFullAddressText = () => {
    const { postNumber, basicAddress, detailedAddress } = this
    return `[${postNumber}] ${basicAddress} ${detailedAddress || ''}`
  }
}
