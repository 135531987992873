export enum PlatformDetailEnum {
  AndroidApp = 'android_app',
  IosApp = 'ios_app',
  AndroidWeb = 'android_web',
  IosWeb = 'ios_web',
  PcWeb = 'pc_web',
  Etc = 'etc',
  RnIos = 'fitpetApp_ios',
  RnAndroid = 'fitpetApp_android',
}
