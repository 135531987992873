import { PlatformDetailEnum } from '@/constants/platform-detail.enum'
import { FitpetMallEventEnum } from '@/utils/event-tracker/events'
import { Logger } from '@/utils/event-tracker/handlers/logger'

export interface EventHandler {
  initialize: () => void
  setUserId: (userID: string) => void
  /**
   * @deprecated: 구 버전의 triggerCustomEvent에 사용되는 이벤트 트래커 지정 방식입니다.
   */
  sendCustomEvent: (event: FitpetMallEventEnum, properties: any, platform: PlatformDetailEnum) => void
  sendEvent: (event: FitpetMallEventEnum, platform: PlatformDetailEnum, properties: Record<string, any>) => void
  sendPageviewEvent: (url: string) => void
  setUserProperties: (properties: any) => void
}

export class BaseEventHandler {
  loggerName: string = 'LOG_'
  logger: Logger = new Logger(this.loggerName)
  userProperties: any = {}
  constructor() {
    if (new.target === BaseEventHandler) {
      throw new TypeError('Cannot construct Abstract instances directly')
    }
    if (this.initialize === undefined) {
      throw new TypeError('Must override initialize')
    }
    if (this.setUserId === undefined) {
      throw new TypeError('Must override setUserId')
    }
    if (this.setUserProperties === undefined) {
      throw new TypeError('Must override setUserProperties')
    }
    if (this.sendPageviewEvent === undefined) {
      throw new TypeError('Must override sendPageviewEvent')
    }
  }
  // eslint-disable-next-line class-methods-use-this
  initialize = () => {}
  // eslint-disable-next-line class-methods-use-this
  setUserId = (userId: string) => {}
  setUserProperties = (properties: any) => {
    this.userProperties = { ...this.userProperties, ...properties }
  }
  // eslint-disable-next-line class-methods-use-this
  sendPageviewEvent = (url: string) => {}
  // 트래커 기본값 설정
  // eslint-disable-next-line class-methods-use-this
  setInitProperties = (properties: Record<string, any>) => {
    const findCategories = (properties: Record<string, any>) => {
      if (properties?.categories) return properties.categories
      if (properties?.product_options?.categories) return properties.product_options.categories
      if (properties?.products && properties?.products.length > 0 && properties?.products[0].categories) {
        // TODO typescript 오류 수정 필요
        // @ts-ignore
        return properties.products.reduce((p, c) => {
          p.push(...c.categories)
          return p
        }, [])
      }
      return []
    }
    const findProductOptions = (properties: Record<string, any>) => {
      if (properties?.product_options?.product_options) return properties?.product_options?.product_options
      if (properties?.product_options) return properties?.product_options
      if (properties?.products && properties?.products.length > 0 && properties?.products[0].product_options) {
        // TODO typescript 오류 수정 필요
        // @ts-ignore
        return properties.products.reduce((p, c) => {
          p.push(...c.product_options)
          return p
        }, [])
      }
      return []
    }
    const findProducts = (properties: Record<string, any>) => properties?.products || []
    const findCoupons = (properties: Record<string, any>) => properties?.coupons || []
    // TODO typescript 오류 수정 필요
    // @ts-ignore
    const setProperties = (target: Record<string, any>, targetArr, keyObject) => {
      // TODO typescript 오류 수정 필요
      // @ts-ignore
      targetArr.forEach((obj) => {
        Object.keys(keyObject).forEach((key) => {
          if (target[key]) target[key].push(obj[keyObject[key]])
          else target[key] = [obj[keyObject[key]]]
        })
      })
    }
    setProperties(properties, findCategories(properties), {
      category_ids: 'category_id',
      category_names: 'category_name',
    })
    setProperties(properties, findProductOptions(properties), {
      product_option_ids: 'product_option_id',
      product_option_names: 'product_option_name',
    })
    setProperties(properties, findProducts(properties), { product_ids: 'product_id', product_names: 'product_name' })
    setProperties(properties, findCoupons(properties), { coupon_ids: 'coupon_id', coupon_names: 'coupon_name' })
    return properties
  }
}
