import qs from 'qs'
import { match, P } from 'ts-pattern'
import { isServerSide } from '@/utils/utilCommon'
import { sessionReferrerTracker } from '@/utils/utilSessionStorage'

const searchEngines = {
  google: {
    keyword: 'google',
    name: 'Google',
  },
  naver: {
    keyword: 'naver',
    name: 'Naver',
  },
  daum: {
    keyword: 'daum',
    name: 'Daum',
  },
}

const fitpetKeyword = 'fitpet'

const ReferrerUtil = {
  getReferrer: () => {
    if (isServerSide()) {
      return ''
    }

    const referrer = document.referrer || ''
    if (sessionReferrerTracker.load() === referrer) {
      return ''
    }
    sessionReferrerTracker.save(referrer)
    return referrer
  },
  /**
   * @description: referrer 를 기반으로 검색 엔진을 판별합니다.
   * @return: 검색 엔진 이름 (Google, Naver, Daum) 그 외의 경우 domain, referrer 가 없는 경우 빈 문자열 반환
   */
  getReferrerDomain: (referrer: string): string => {
    const lowerCaseReferrer = referrer.toLowerCase()
    return match(lowerCaseReferrer)
      .with(P.string.includes(searchEngines.google.keyword), () => searchEngines.google.name)
      .with(P.string.includes(searchEngines.naver.keyword), () => searchEngines.naver.name)
      .with(P.string.includes(searchEngines.daum.keyword), () => searchEngines.daum.name)
      .otherwise(() => {
        try {
          const url = new URL(lowerCaseReferrer)
          // domain 에 "fitpet" 이 포함될 경우 빈 문자열 반환
          if (url.origin.includes(fitpetKeyword)) {
            return ''
          }
          return url.origin
        } catch (error) {
          return ''
        }
      })
  },
  getNaverSearchQuery: (referrer: string): string => {
    if (!referrer) {
      return ''
    }
    /**
     * referrer = 'https://m.search.naver.com/search.naver?nso=&page=2&query=%ED%95%8F%ED%8E%AB+%ED%95%8F%ED%8E%AB+%EC%96%B4%ED%97%A4%EB%93%9C+%EB%B2%A0%EC%9D%B4%EC%A7%81+%ED%82%A4%ED%8A%B8&sm=tab_pge&start=1&where=m_web',
     * queryObject = {
     *   https://m.search.naver.com/search.naver?nso:  ""
     *   page: "2"
     *   query:  "핏펫 핏펫 어헤드 베이직 키트"
     *   sm: "tab_pge"
     *   start: "1"
     *   where: "m_web"
     * }
     */
    const queryObject = qs.parse(referrer, { ignoreQueryPrefix: true })
    return (queryObject?.query as string) || ''
  },
  getDaumSearchQuery: (referrer: string): string => {
    if (!referrer) {
      return ''
    }
    const queryObject = qs.parse(referrer, { ignoreQueryPrefix: true })
    return (queryObject?.q as string) || ''
  },
  getSearchQuery: (referrer: string): string => {
    const referrerDomain = ReferrerUtil.getReferrerDomain(referrer)
    return match(referrerDomain)
      .with(searchEngines.naver.name, () => ReferrerUtil.getNaverSearchQuery(referrer))
      .with(searchEngines.daum.name, () => ReferrerUtil.getDaumSearchQuery(referrer))
      .otherwise(() => '')
  },
}

export { ReferrerUtil }
