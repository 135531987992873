import { FC, PropsWithChildren, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { FIXED_HEIGHT } from '@/constants/legacy/constLayout'
import useCustomRouter from '@/containers/hooks/useCustomRouter'
import { doSetHeaderTitle } from '@/stores/reduxUI'
import { isInApp } from '@/utils/utilCommon'

const LayoutBody: FC<PropsWithChildren<{}>> = ({ children }) => {
  const { ableToShowBottomTabNavigator, pathname } = useCustomRouter()
  const dispatch = useDispatch()
  const [paddingBottom, setPaddingBottom] = useState<number>(FIXED_HEIGHT.BOTTOM_TAB_NAVIGATOR)

  useEffect(() => {
    return () => {
      dispatch(doSetHeaderTitle(''))
    }
  }, [pathname])

  useEffect(() => {
    if (isInApp() || !ableToShowBottomTabNavigator()) {
      setPaddingBottom(0)
    } else {
      setPaddingBottom(FIXED_HEIGHT.BOTTOM_TAB_NAVIGATOR)
    }
  }, [pathname])

  return (
    <StyledContent paddingbottom={paddingBottom} id="main">
      {children}
    </StyledContent>
  )
}

const StyledContent = styled.div<{ paddingbottom: number }>`
  background-color: ${(props) => props.theme.color.grayWhite};
  width: 100%;
  min-height: 100vh;
  padding-bottom: ${({ paddingbottom }) => `${paddingbottom}px`};
`

export default LayoutBody
