// Todo : 실제로 사용되지 않는 이벤트들이 많아 마케팅팀과 협력하여 사용하지 않는 이벤트들은 삭제해야 합니다.
export enum FitpetMallEventEnum {
  SearchEngineInflow = 'searchengine_inflow', //서치엔진 검색을 통해 유입했을때 이벤트 발생 (방문 url 무관)
  Garbage = 'garbage',
  FirstCompletePurchase = 'first_complete_purchase',
  CompletePurchase = 'complete_purchase',
  CompletePurchaseError = 'complete_purchase_error',
  ClickGnbPetToggle = 'click_gnb_pet_toggle',
  ClickGnbSearchIcon = 'click_gnb_search_icon',
  ClickGnbCartIcon = 'click_gnb_cart_icon',
  ClickGnbHomeIcon = 'click_gnb_home_icon',
  ClickBottomNavigationbar = 'click_bottom_navigationbar',
  ViewCategory = 'view_category', // 카테고리 페이지 진입 시 (3차 카테고리 중 ’전체’ 포함)
  ViewCategorySimilarProductSection = 'view_category_similarproduct_section',
  ClickCategoryListTab = 'click_category_list_tab',
  ClickCategoryListItem = 'click_category_list_item',
  ClickHomeCategoryTab = 'click_home_category_tab',
  ClickCategoryTab = 'click_category_tab',
  ClickHomeCategoryItem = 'click_home_category_item',
  ClickCategoryItem = 'click_category_item',
  ClickCategoryListProduct = 'click_category_list_product',
  // 홈관련
  ViewHome = 'view_home',
  ClickHomeTopmenuTab = 'click_home_topmenu_tab',
  ViewHomeBrandShopBrand = 'view_home_brandshop_brand',
  ClickHomeBrandshopItem = 'click_home_brandshop_item',
  ClickHomeBanner = 'click_home_banner',
  ViewHomeBanner = 'view_home_banner',
  ViewHomeTextBanner = 'view_home_textbanner',
  ViewHomeBannerMain = 'view_home_banner_main',
  ViewHomeSection = 'view_home_section',
  ClickHomeTextBanner = 'click_home_textbanner',
  ClickHomeDisplayProduct = 'click_home_display_product',
  ClickHomeSpecialsaleProduct = 'click_home_specialsale_product',
  ClickHomeNewProduct = 'click_home_new_product',
  ClickHomeWeeklybestProduct = 'click_home_weeklybest_product',
  ClickHomeBestReviewProduct = 'click_home_best_review_product',
  ClickHomeMagazineBackgroundImageProduct = 'click_home_magazine_background_image_product',
  ClickHomeMagazineImageProduct = 'click_home_magazine_image_product',
  ViewProductdetail = 'view_productdetail',
  ClickProductdetailStartpurchaseButton = 'click_productdetail_startpurchase_button',
  ClickProductdetailGotocartButton = 'click_productdetail_gotocart_button',
  ClickProductdetailBrandButton = 'click_productdetail_brand_button',
  ClickProductdetailShareButton = 'click_productdetail_share_button',
  ClickProductdetailSharetypeButton = 'click_productdetail_sharetype_button',
  ClickProductdetailMorereviewButton = 'click_productdetail_morereview_button',
  ClickProductdetailMoreinfo = 'click_productdetail_moreinfo',
  ClickProductdetailRecommendedProduct = 'click_productdetail_recommend_product',
  ClickProductdetailMiddleProduct = 'click_productdetail_middle_product',
  ViewProductdetailMiddleProduct = 'view_productdetail_middle_product',
  ClickProductdetailBottomProduct = 'click_productdetail_bottom_product',
  ClickProductdetailBundleShippingButton = 'click_productdetail_bundleshipping_button',
  ViewProductdetailBottomProductFirst = 'view_productdetail_bottom_product_first',
  ViewProductdetailBottomProductSecond = 'view_productdetail_bottom_product_second',
  ViewBrandshop = 'view_brandshop',
  ClickHomeSectionProduct = 'click_home_section_product',
  ClickHomeSectionMore = 'click_home_section_more',
  ClickHomeNudgeMessage = 'click_home_nudgemessage', // 홈 하단 넛지 버튼 클릭
  ViewHomeRecommendSection = 'view_home_recommend_section', // 홈 추천 섹션 조회
  ClickHomeRecommendSectionMore = 'click_home_recommend_section_more', // 홈 추천 섹션 더보기 버튼 클릭
  ClickHomeRecommendProduct = 'click_home_recommend_product', // 홈 추천 섹션 상품 클릭
  ClickSectionListProduct = 'click_section_list_product', // 섹션 리스트 상품 클릭
  ClickHomeFloatingButton = 'click_home_floating_button', // 홈 플로팅 버튼 클릭

  // 상품상세 페이지
  ViewProductdetailTextbanner = 'view_productdetail_textbanner',
  ClickProductdetailTextbanner = 'click_productdetail_textbanner',
  ViewProductdetailBanner = 'view_productdetail_banner',
  ClickProductdetailBanner = 'click_productdetail_banner',
  ClickProductDetailAddToCartButton = 'click_productdetail_addtocart_button',
  ClickProductDetailSinglePurchaseButton = 'click_productdetail_singlepurchase_button',
  ClickProductDetailTab = 'click_productdetail_tab',
  ClickProductDetailTag = 'click_productdetail_tag',
  ViewProductDetailMiddleProductBrand = 'view_productdetail_middle_product_brand',
  ViewProductDetailBrand = 'view_productdetail_brand',

  // 브랜드 페이지
  ViewBrandList = 'view_brand_list',
  ClickBrandListProduct = 'click_brandlist_product',

  // 이벤트 상세페이지
  ClickEventsaleDetailSection = 'click_eventsale_detail_section',
  ClickEventsaleProduct = 'click_eventsale_product',
  ClickEventsaleDetailImage = 'click_eventsale_detail_image',
  ClickEventsaleFilter = 'click_eventsale_filter',
  ClickEventsaleCouponButton = 'click_eventsale_detail_couponbutton',
  ClickEventsaleCouponDownload = 'click_eventsale_detail_coupondownload',

  // 장바구니 페이지
  ViewCart = 'view_cart',
  ViewCartBanner = 'view_cart_banner',
  ClickCartBanner = 'click_cart_banner',
  ClickCartChangecntButton = 'click_cart_changecnt_button',
  ClickCartChangeoptButton = 'click_cart_changeopt_button',
  ClickCartRecommendProduct = 'click_cart_recommend_product',
  ClickCartPaymentButton = 'click_cart_payment_button',
  ClickEventsaleListItem = 'click_eventsale_list_item',
  ClickEventsalepageListItem = 'click_eventsalepage_list_item',
  ViewEventsaleDetail = 'view_eventsale_detail',
  ClickEventsaleDetailSelectbox = 'click_eventsale_detail_selectbox',
  ClickEventsaleShareButton = 'click_eventsale_share_button',
  ClickEventsaleSharetypeButton = 'click_eventsale_sharetype_button',
  ClickHomeFloatingbannerTop = 'click_home_floatingbanner_top',
  ViewHomeFloatingbannerTop = 'view_home_floatingbanner_top',
  View100Wondeal = 'view_100wondeal',
  Click100WondealSignup = 'click_100wondeal_signup',
  Click100WondealProduct = 'click_100wondeal_product',
  SubmitSearchKeyword = 'submit_search_keyword',
  ClickSearchListProduct = 'click_search_list_product',
  ViewFrequentlyList = 'view_frequently_list',
  ViewReviewlist = 'view_reviewlist',
  ClickReviewWriteButton = 'click_review_write_button',
  ClickHomeBannerReviewrequest = 'click_home_banner_reviewrequest',
  ClickReviewwriteCompleted = 'click_reviewwrite_completed',
  ViewSectionListItems = 'view_section_list_items',
  ClickCartBottomProduct = 'click_cart_bottom_product', // 장바구니 하단 위젯 상품 클릭
  ClickCartDeleteSelected = 'click_cart_delete_selected', // 장바구니 선택 상품 삭제
  ClickCartDeleteIcon = 'click_cart_delete_icon', // 장바구니 상품 삭제 아이콘 클릭
  ViewCartLikeTab = 'view_cart_like_tab', // 장바구니 페이지내 찜한 상품 탭
  ViewCartFrequentTab = 'view_cart_freqeunt_tab', // 장바구니 페이지내 자주 산 상품 탭
  ClickCartNudgeMessage = 'click_cart_nudgemessage', // 장바구니 페이지 하단 쿠폰 넛지 메시지에서 링크 클릭
  ViewCartNudgeMessage = 'view_cart_nudgemessage', // 장바구니 페이지 하단 쿠폰 넛지 메시지 조회, 메시지가 변경될 때마다 이벤트 트래커 함께 호출
  ClickProductCouponApply = 'click_product_coupon_apply',

  // 바텀시트
  ClickAddCartBottomUpProduct = 'click_addcart_bottomup_product',
  ClickListSinglePurchaseButton = 'click_list_singlepurchase_button',
  ClickListBottomSheetToCart = 'click_list_bottomsheet_tocart',
  ClickListAddToCartButton = 'click_list_addtocart_button',
  ViewAddCartBottomUpProduct = 'view_addcart_bottomup_product',
  ViewAddCartBottomUpBottomSheet = 'view_addcart_bottomup_bottomsheet', // 상품의 장바구니 담기 완료시 나타나는 바텀시트 노출 시

  ClickAddCartBottomUpSectionMore = 'click_addcart_bottomup_section_more', //상품의 장바구니 담기 완료시 나타나는 바텀시트 > 추천 섹션 > ‘더보기’ 클릭 시

  ClickAddCartBottomUpCart = 'click_addcart_bottomup_cart', // 상품의 장바구니 담기 완료시 나타나는 바텀시트 > ‘보러가기’ 클릭 시

  // 상품 리스트에서 담기 버튼 클릭
  ClickListStartPurchaseButton = 'click_list_startpurchase_button',

  // 회원가입
  ViewSignupInfo = 'view_signup_info', // 회원가입 정보 입력 화면을 조회했을때
  ViewSignup = 'view_signup', // done
  ClickSignupKakao = 'click_signup_kakao',
  ClickSignupApple = 'click_signup_apple',
  ClickSignupNaver = 'click_signup_naver',
  ClickSignupEmail = 'click_signup_email',
  ClickSignupLogin = 'click_signup_login',
  CompleteSignup = 'complete_signup',
  SubmitSignupInfoId = 'submit_signup_info_id', // 회원가입 정보 입력 화면에서 아이디 기입 완료 시
  SubmitSignupInfoPassword = 'submit_signup_info_password', // 회원가입 정보 입력 화면에서 비밀번호 기입 완료시
  SubmitSignupInfoPasswordConfirm = 'submit_signup_info_password_confirm', //회원가입 정보 입력 화면에서 비밀번호 확인 기입 완료시
  SubmitSignupInfoName = 'submit_signup_info_name', // 회원가입 정보 입력 화면에서 이름 기입 완료시
  ClickSignupInfoGender = 'click_signup_info_gender', // 회원가입 정보 입력 화면에서 성별 선택시
  ClickSignupInfoCodeSend = 'click_signup_info_codesend', //회원가입 정보 입력 화면에서 인증번호 전송을 클릭했을때
  ClickSignupInfoCodeCheck = 'click_signup_info_codecheck', //회원가입 정보 입력 화면에서 인증번호 확인을 클릭했을때
  SubmitSignupInfoBirthday = 'submit_signup_info_birthday', //회원가입 정보 입력 화면에서 생일 기입 완료시
  SubmitSignupInfoInviteCode = 'submit_signup_info_invitecode', // 회원가입 정보 입력 화면에서 초대코드 기입 완료시
  ViewSignupInfoPopUp = 'view_signup_info_popup', //번호인증 후 안내 팝업 조회 시
  ClickSignupInfoPopUp = 'click_signup_info_popup', //번호인증 후 안내 팝업버튼 클릭 시
  ClickSignupInfoAllTerms = 'click_signup_info_allterms', //회원가입 정보 입력 화면에서 이용약관 전체 동의를 클릭했을 때
  ClickSignupInfoDetailTerms = 'click_signup_info_detailterms', //회원가입 정보 입력 화면에서 세부 이용약관 동의를 클릭했을 때

  //로그인
  ViewLogin = 'view_login',
  ViewSignupLogin = 'view_signuplogin', // 로그인/회원가입 통합 페이지를 조회했을때
  ClickSignupLoginKakao = 'click_signuplogin_kakao', // 회원가입 / 로그인 통합 페이지 에서 카카오 아이콘 클릭
  ClickSignupLoginNaver = 'click_signuplogin_naver', // 회원가입 / 로그인 통합 페이지 에서 네이버 아이콘 클릭
  ClickSignupLoginEmail = 'click_signuplogin_email', // 회원가입 / 로그인 통합 페이지 에서 이메일 아이콘 클릭
  ClickSignupLoginApple = 'click_signuplogin_apple', // 회원가입 / 로그인 통합 페이지 에서 애플 아이콘 클릭
  ClickLoginEmailSignup = 'click_loginemail_signup', // 이메일 로그인 페이지 에서 이메일 아이콘 클릭
  ClickListLikeButton = 'click_list_like_button',
  ClickProductdetailLikeButton = 'click_productdetail_like_button',
  ClickMypageLikeproduct = 'click_mypage_likeproduct',
  ViewLikeproduct = 'view_likeproduct',
  ClickLikeProduct = 'click_likeproduct_product',
  ClickUnlikeButton = 'click_unlike_button',
  ClickMainRecentproductButton = 'click_main_recentproduct_button',
  ClickMainLikeproductButton = 'click_main_likeproduct_button',
  ClickMypageRecentproduct = 'click_mypage_recentproduct',
  ViewRecentproduct = 'view_recentproduct',
  ClickRecentlyProduct = 'click_recentproduct_product',
  ClickLoginEmailLogin = 'click_loginemail_login',
  ClickLoginKakao = 'click_login_kakao',
  ClickLoginNaver = 'click_login_naver',
  ClickLoginApple = 'click_login_apple',
  ClickLoginSearchId = 'click_login_search_id',
  ClickLoginSearchPassword = 'click_login_search_password',
  ClickLoginSignup = 'click_login_signup',
  CompleteLogin = 'complete_login',
  ClickLoginSearchIdCodeSend = 'click_login_searchid_codesend', //아이디 찾기에서 인증번호 전송을 클릭했을 때
  ClickLoginSearchIdCodeCheck = 'click_login_searchid_codecheck', //아이디 찾기에서 인증번호 확인을 클릭했을 때
  ClickLoginSearchPwCodeSend = 'click_login_searchpw_codesend', //비밀번호 찾기에서 인증번호 전송을 클릭했을 때
  ClickLoginSearchPwCodeCheck = 'click_login_searchpw_codecheck', //비밀번호 찾기에서 인증번호 확인을 클릭했을 때
  SubmitLoginEmailInfoId = 'submit_loginemail_info_id', //로그인 화면에서 아이디 기입 완료 시
  SubmitLoginEmailInfoPassword = 'submit_loginemail_info_password', //로그인 화면에서 비밀번호 기입 완료 시
  ViewLoginEmailMessage = 'view_loginemail_message', //로그인 화면에서 안내 토스트 메시지 조회 시
  ClickLoginEmailPopup = 'click_loginemail_popup', //로그인 화면에서 (비밀번호 초기화 안내) 팝업 클릭 시

  // inapp에 동일 구현
  ViewMypage = 'view_mypage',
  ClickMypagePetAdd = 'click_mypage_pet_add',
  ClickMypagePet = 'click_mypage_pet',
  ClickMypageOrder = 'click_mypage_order',
  ClickMypageReview = 'click_mypage_review',
  ClickMypageCoupon = 'click_mypage_coupon',
  ClickMypageMileage = 'click_mypage_mileage',
  ClickMypageFitpetmallSection = 'click_mypage_fitpetmall_section',
  ClickMypageHospitalSection = 'click_mypage_hospital_section',
  ClickMypageContact = 'click_mypage_contact',
  ClickMypageNotice = 'click_mypage_notice',
  ClickMypageCustomercenter = 'click_mypage_customercenter',
  ClickMypageWithdrawal = 'click_mypage_withdrawal',
  ClickMypageWithdrawalComplete = 'click_mypage_withdrawal_complete',
  ClickMypageMarketingAgreement = 'click_mypage_marketing_agreement',
  ClickMypageMarketingNightAgreement = 'click_mypage_marketing_night_agreement',
  ClickMypageSnsconnectNaver = 'click_mypage_snsconnect_naver',
  ClickMypageSnsconnectKakao = 'click_mypage_snsconnect_kakao',
  ClickMypageSnsconnectApple = 'click_mypage_snsconnect_apple',
  ClickMypageBanner = 'click_mypage_banner',
  ViewOrder = 'view_order',
  ClickOrderPaymentButton = 'click_order_payment_button',
  ClickProductdetailRestockButton = 'click_productdetail_restock_button',
  ClickProductdetailRestockcancelButton = 'click_productdetail_restockcancel_button',
  ClickProductdetailOptionrestockButton = 'click_productdetail_optionrestock_button',
  ClickProductdetailOptionrestockcancelButton = 'click_productdetail_optionrestockcancel_button',
  ClickProductdetailGotorestockButton = 'click_productdetail_gotorestock_button',
  ViewRestockproduct = 'view_restockproduct',
  ClickRestockproductProduct = 'click_restockproduct_product',
  ViewSearch = 'view_search',
  ClickSearchRelatedKeyword = 'click_search_related_keyword',
  ClickSearchRecentKeyword = 'click_search_recent_keyword',
  ClickSearchAutoKeyword = 'click_search_auto_keyword',
  ClickSearchBrand = 'click_search_brand',
  ClickSearchPopularKeyword = 'click_search_popular_keyword',
  ClickSearchRecentProduct = 'click_search_recent_product',
  ClickSearchRecommendKeyword = 'click_search_recommend_keyword',
  ViewSearchResult = 'view_search_result',
  ViewSearchResultSimilarProductSection = 'view_search_result_similarproduct_section',

  // 친구추천 관련
  ViewFriendevent = 'view_friendevent', // 친구추천 이벤트 페이지를 조회했을때
  ClickFriendeventCodecopy = 'click_friendevent_codecopy', // 친구추천 이벤트 페이지에서 코드 복사를 클릭했을때
  ClickFriendeventLinkshare = 'click_friendevent_linkshare', // 친구추천 이벤트 페이지에서 링크 공유를 클릭했을때
  ClickFriendeventKakaoinvite = 'click_friendevent_kakaoinvite', // 친구추천 이벤트 페이지에서 카카오톡 초대를 클릭했을때
  ViewInvitedFriendevent = 'view_invited_friendevent',
  ClickFriendeventSignupButton = 'click_friendevent_signup_button',
  ViewOrdercompleteBanner = 'view_ordercomplete_banner',
  ClickOrdercompleteBanner = 'click_ordercomplete_banner',

  // 주문내역
  ViewOrderlist = 'view_orderlist',
  ClickOrderlistConfirmButton = 'click_orderlist_confirm_button',
  ClickOrderlistReviewButton = 'click_orderlist_review_button',
  ClickOrderlistAddtocartButton = 'click_orderlist_addtocart_button',
  ClickOrderListShippingInfoButton = 'click_orderlist_shippinginfo_button', // 주문내역 배송조회 버튼 클릭

  // 결제 페이지
  ViewOrderCoupon = 'view_order_coupon',
  ClickOrderCouponApply = 'click_order_coupon_apply',
  CompletePurchaseProductOption = 'complete_purchase_productoption',

  // 멤버십
  ClickHomeMembership = 'click_home_membership',
  ClickHomeBannerMembership = 'click_home_banner_membership',
  ViewHomeBannerMembership = 'view_home_banner_membership',
  ClickMembershipIntroJoin = 'click_membership_intro_join',
  ViewMembershipIntro = 'view_membership_intro',
  ViewMembershipJoinFinish = 'view_membership_join_finish',
  CompleteMembershipJoin = 'complete_membership_join',
  CompleteMembershipFirstJoin = 'complete_membership_first_join',
  CompleteMembershipRegisterError = 'complete_membership_register_error',
  ViewMembershipCancelRequest = 'view_membership_cancel_request',
  ViewMembershipCancelReason = 'view_membership_cancel_reason',
  ViewMembershipCancelFinish = 'view_membership_cancel_finish',
  ClickMembershipSettingCancel = 'click_membership_setting_cancel',
  ClickMembershipSettingCancelComplete = 'click_membership_setting_cancel_complete',
  CompleteMembershipCanceled = 'complete_membership_canceled',
  ViewMembershipFamily = 'view_membership_family',
  ClickMembershipFamilyInvite = 'click_membership_family_invite',
  ClickMembershipFamilyInviteShare = 'click_membership_family_invite_share',
  ClickMembershipFamilyInviteShareDetail = 'click_membership_family_invite_share_detail',
  ViewMembershipHome = 'view_membership_home',
  ViewMembershipRecommendList = 'view_membership_recommend_list',
  ClickMembershipHomeInvite = 'click_membership_home_invite',
  ClickMembershipHomeInviteShare = 'click_membership_home_invite_share',
  CompletedMembershipSettingCancel = 'completed_membership_setting_cancel',
  ClickMembershipJoinTypeSelect = 'click_membership_join_typeselect',
  ClickMembershipJoinStart = 'click_membership_join_start',

  // 쿠폰
  ViewCouponPage = 'view_couponpage', // 쿠폰함  화면 조회 (사용가능 탭 조회 기준)
  ClickCouponPageExpired = 'click_couponpage_expired', // 쿠폰함 화면에서 사용 완료불가 탭 클릭
  ClickCouponPageRegister = 'click_couponpage_register', // 쿠폰함  화면에서 쿠폰 등록하기 버튼 클릭
  ViewCouponPageBanner = 'view_couponpage_banner', // 쿠폰함  화면에서 멤버십 유도 배너영역 조회
  ClickCouponPageBanner = 'click_couponpage_banner', // 쿠폰함  화면에서 멤버십 유도 배너영역 클릭

  // 회원가입 완료 페이지(welcome)
  ClickSignUpCompleteMainbanner = 'click_signupcomplete_mainbanner', // 회원가입 완료페이지에서 메인 배너 클릭
  ClickSignUpCompleteBanner = 'click_signupcomplete_banner', // 회원가입 완료페이지에서 하단 배너 클릭

  // 파트너스 페이지
  ViewEventSalePartners = 'view_eventsale_partners', // 파트너스 이벤트 페이지 조회
  ClickEventSalePartnersRegister = 'click_eventsale_partners_register', // 파트너스 이벤트 페이지에서 신청하기 버튼 클릭
  ClickEventSalePartnersShare = 'click_eventsale_partners_share', // 파트너스 이벤트 페이지에서 공유하기 버튼 클릭
  ClickPartnersRegisterRegister = 'click_partnersregister_register', // 파트너스  신청 페이지에서 내용 기입 후 파트너스 신청하기 버튼 클릭
  ViewPartnersDetail = 'view_partnersdetail', // 파트너스  상세 페이지 조회
  ClickPartnersDetailMakeCoupon = 'click_partnersdetail_makecoupon', // 파트너스  상세 페이지에서 키워드 입력 후 만들기 버튼 클릭
  ViewPartnersDetailMyReward = 'view_partnersdetail_myreward', // 파트너스  나의 리워드 페이지 조회
  ClickMypagePartners = 'click_mypage_partners', // 마이페이지에서 핏펫 파트너스 클릭

  // 페퍼 저축 은행 이벤트 페이지
  ViewEventSalePepper = 'view_eventsale_pepper', // 페퍼 저축 은행 이벤트 페이지 조회
  ClickEventSalePepperCoupon = 'click_eventsale_pepper_coupon', // 페퍼 저축 은행 이벤트 펫적금 가입 쿠폰 받기 클릭
  ClickEventSalePepperPopupButton = 'click_eventsale_pepper_popup_button', // 페퍼저축은행 이벤트 페이지에서 쿠폰 받기 버튼 클릭 후 나오는 모달 팝업에서 버튼 클릭
  ClickEventSalePepperOpen = 'click_eventsale_pepper_open', // 페퍼저축은행 이벤트 페이지에서 펫적금 개설하기 버튼 클릭
  ClickEventSalePepperShare = 'click_eventsale_pepper_share', // 페퍼저축은행 이벤트 페이지에서 공유하기 아이콘 클릭

  // 액션 파워 관련 이벤트
  ClickOrderListOtherInfoButton = 'click_orderlist_otherinfo_button', // 주문 내역에서 액션 파워 버튼(사진 올리기, 사진 제작중, 사진 다운받기) 클릭 시

  // 베스트 관련
  ClickBestSellerTab = 'click_bestseller_tab', // 베스트 상품 진열페이지 내 탭 클릭
  ClickBestSellerProduct = 'click_bestseller_product', // 베스트 상품 진열페이지 내 상품 클릭
  ClickHomeWeeklyBestTab = 'click_home_weeklybest_tab', // 홈 페이지 내 주간 베스트 탭 클릭

  //건강
  ClickPlayAppDownload = 'click_play_appdownload', //건강 인트로 페이지 내 앱 다운로드 클릭

  // 검색 결과 페이지
  ClickSearchResultFrequentProduct = 'click_search_result_frequentproduct', // 검색 결과 페이지 내 최근 구매한 상품 위젯의 상품 클릭 시
  ViewSearchResultFrequentProduct = 'view_search_result_frequentproduct', // 검색 결과 페이지 내 최근 구매한 상품 위젯의 상품 조회 시
  ViewSearchResultBrandProduct = 'view_search_result_brand', // 검색 결과 페이지 내 브랜드 리스트 상품 위젯의 상품 조회 시
  ClickSearchResultBrandProduct = 'click_search_result_brandproduct', // 검색 결과 페이지 내 브랜드 리스트 상품 위젯의 상품 클릭 시

  // 쿠폰 바텀 시트
  ClickCouponDownloadButton = 'click_coupondownload_button', // 쿠폰 바텀 시트에서 쿠폰 다운로드 버튼 클릭 시
  ClickListCouponDownload = 'click_list_coupondownload', // 쿠폰 바텀 시트 오픈 버튼 클릭 시
  ViewProductCoupon = 'view_product_coupon', // 쿠폰 바텀시트 조회 시

  // 관심상품 - 자주 본 상품 + 찜한 상품
  ViewFavoritePage = 'view_favorite_page',

  // 마이페이지
  ClickMyPageHealth = 'click_mypage_health', // 마이페이지 건강 클릭
  ClickMyPageHospital = 'click_mypage_hospital', // 마이페이지 병원 클릭
  ClickMyPageInsurance = 'click_mypage_insurance', // 마이페이지 보험 클릭
}
