import { ElementType } from 'react'
import { IconNameEnum } from '@/constants/icon-name.enum'
import SvgImages from '@/constants/legacy/svgImages'
import { GlobalTheme } from '@/styles/globalTheme'

const { color } = GlobalTheme

type FlatIconListType = {
  [key in IconNameEnum]: {
    SvgComponent: ElementType
    defaultColor: typeof color[keyof typeof color]
  }
}

/**
 * @deprecated
 */
const FlatIconList: FlatIconListType = {
  [IconNameEnum.IcHeartOutlined]: {
    SvgComponent: SvgImages.IcHeartOutlined,
    defaultColor: color.gray700,
  },
  [IconNameEnum.IcHeartFilled]: {
    SvgComponent: SvgImages.IcHeartFilled,
    defaultColor: color.red500,
  },
  [IconNameEnum.IcHeartDimmedThumbnail]: {
    SvgComponent: SvgImages.IcHeartDimmedThumbnail,
    defaultColor: color.gray700,
  },
  [IconNameEnum.IcChange]: {
    SvgComponent: SvgImages.IcChange,
    defaultColor: color.gray700,
  },
  [IconNameEnum.IcSearch]: {
    SvgComponent: SvgImages.IcSearch,
    defaultColor: color.gray900,
  },
  [IconNameEnum.IcTabShoppingFilled]: {
    SvgComponent: SvgImages.IcTabShoppingFilled,
    defaultColor: color.gray800,
  },
  [IconNameEnum.IcTabShoppingOutlined]: {
    SvgComponent: SvgImages.IcTabShoppingOutlined,
    defaultColor: color.gray400,
  },
  [IconNameEnum.IcTabMyOutlined]: {
    SvgComponent: SvgImages.IcTabMyOutlined,
    defaultColor: color.gray400,
  },
  [IconNameEnum.IcTabMyFilled]: {
    SvgComponent: SvgImages.IcTabMyFilled,
    defaultColor: color.gray800,
  },
  [IconNameEnum.IcCart]: {
    SvgComponent: SvgImages.IcCart,
    defaultColor: color.gray900,
  },
  [IconNameEnum.IcAddSmall]: {
    SvgComponent: SvgImages.IcAddSmall,
    defaultColor: color.gray700,
  },
  [IconNameEnum.IcArrowRight]: {
    SvgComponent: SvgImages.IcArrowRight,
    defaultColor: color.gray700,
  },
  [IconNameEnum.IcArrowLeft]: {
    SvgComponent: SvgImages.IcArrowLeft,
    defaultColor: color.gray700,
  },
  [IconNameEnum.IcArrowRightThin]: {
    SvgComponent: SvgImages.IcArrowRightThin,
    defaultColor: color.gray500,
  },
  [IconNameEnum.IcArrowDown]: {
    SvgComponent: SvgImages.IcArrowDown,
    defaultColor: color.gray500,
  },
  [IconNameEnum.IcArrowDownRedGradation]: {
    SvgComponent: SvgImages.IcArrowDownRedGradation,
    defaultColor: color.gray500,
  },
  [IconNameEnum.IcArrowUp]: {
    SvgComponent: SvgImages.IcArrowUp,
    defaultColor: color.gray500,
  },
  [IconNameEnum.IcRecently]: {
    SvgComponent: SvgImages.IcRecently,
    defaultColor: color.gray700,
  },
  [IconNameEnum.IcClose]: {
    SvgComponent: SvgImages.IcClose,
    defaultColor: color.gray700,
  },
  [IconNameEnum.IcHome]: {
    SvgComponent: SvgImages.IcHome,
    defaultColor: color.gray900,
  },
  [IconNameEnum.IcBack]: {
    SvgComponent: SvgImages.IcBack,
    defaultColor: color.gray900,
  },
  [IconNameEnum.IcShare]: {
    SvgComponent: SvgImages.IcShare,
    defaultColor: color.gray900,
  },
  [IconNameEnum.IcStar]: {
    SvgComponent: SvgImages.IcStar,
    defaultColor: color.gray100,
  },
  [IconNameEnum.IcArrowUpSmall]: {
    SvgComponent: SvgImages.IcArrowUpSmall,
    defaultColor: color.gray400,
  },
  [IconNameEnum.IcArrowDownSmall]: {
    SvgComponent: SvgImages.IcArrowDownSmall,
    defaultColor: color.gray400,
  },
  [IconNameEnum.IcTip]: {
    SvgComponent: SvgImages.IcTip,
    defaultColor: color.gray900,
  },
  [IconNameEnum.IcInfo]: {
    SvgComponent: SvgImages.IcInfo,
    defaultColor: color.gray400,
  },
  [IconNameEnum.IcInfoFilled]: {
    SvgComponent: SvgImages.IcInfoFilled,
    defaultColor: color.gray400,
  },
  [IconNameEnum.IcTop]: {
    SvgComponent: SvgImages.IcTop,
    defaultColor: color.gray300,
  },
  [IconNameEnum.IcBottom]: {
    SvgComponent: SvgImages.IcBottom,
    defaultColor: color.gray500,
  },
  [IconNameEnum.IcSearchDelete]: {
    SvgComponent: SvgImages.IcSearchDelete,
    defaultColor: color.gray200,
  },
  [IconNameEnum.IcCheck]: {
    SvgComponent: SvgImages.IcCheck,
    defaultColor: color.gray900,
  },
  [IconNameEnum.IcSetting]: {
    SvgComponent: SvgImages.IcSetting,
    defaultColor: color.gray900,
  },
  [IconNameEnum.IcTabCareOutlined]: {
    SvgComponent: SvgImages.IcTabCareOutlined,
    defaultColor: color.gray400,
  },
  [IconNameEnum.IcTabCareFilled]: {
    SvgComponent: SvgImages.IcTabCareFilled,
    defaultColor: color.gray800,
  },
  [IconNameEnum.IcTabHospitalOutlined]: {
    SvgComponent: SvgImages.IcTabHospitalOutlined,
    defaultColor: color.gray400,
  },
  [IconNameEnum.IcTabHospitalFilled]: {
    SvgComponent: SvgImages.IcTabHospitalFilled,
    defaultColor: color.gray800,
  },
  [IconNameEnum.IcTabHealthOutlined]: {
    SvgComponent: SvgImages.IcTabHealthOutlined,
    defaultColor: color.gray800,
  },
  [IconNameEnum.IcTabHealthFilled]: {
    SvgComponent: SvgImages.IcTabHealthFilled,
    defaultColor: color.gray800,
  },

  [IconNameEnum.IcRankingNone]: {
    SvgComponent: SvgImages.IcRankingNone,
    defaultColor: color.gray600,
  },
  [IconNameEnum.IcRankingUp]: {
    SvgComponent: SvgImages.IcRankingUp,
    defaultColor: color.red500,
  },
  [IconNameEnum.IcRankingDown]: {
    SvgComponent: SvgImages.IcRankingDown,
    defaultColor: color.gray500,
  },
  [IconNameEnum.IcEventFlat]: {
    SvgComponent: SvgImages.IcEventFlat,
    defaultColor: color.gray400,
  },
  [IconNameEnum.IcChevronDown]: {
    SvgComponent: SvgImages.IcChevronDown,
    defaultColor: color.gray700,
  },
  [IconNameEnum.IcChevronRight]: {
    SvgComponent: SvgImages.IcChevronRight,
    defaultColor: color.gray700,
  },
  [IconNameEnum.IcChevronUp]: {
    SvgComponent: SvgImages.IcChevronUp,
    defaultColor: color.gray700,
  },
  [IconNameEnum.IcDownload]: {
    SvgComponent: SvgImages.IcDownload,
    defaultColor: color.gray700,
  },
  [IconNameEnum.IcDownloadRadius]: {
    SvgComponent: SvgImages.IcDownloadRadius,
    defaultColor: color.gray700,
  },
  [IconNameEnum.IcOrderCoupon]: {
    SvgComponent: SvgImages.IcOrderCoupon,
    defaultColor: color.gray700,
  },
  [IconNameEnum.IcOwner]: {
    SvgComponent: SvgImages.IcOwner,
    defaultColor: color.gray700,
  },
  [IconNameEnum.IcCoinFlat]: {
    SvgComponent: SvgImages.IcCoinFlat,
    defaultColor: color.gray700, // 이 svg 는 defaultColor 에 영향을 받지 않음
  },
  [IconNameEnum.IcCaution]: {
    SvgComponent: SvgImages.IcCaution,
    defaultColor: color.gray400,
  },
  [IconNameEnum.IcEmpty]: {
    SvgComponent: SvgImages.IcEmpty,
    defaultColor: color.gray700,
  },
}

export default FlatIconList
