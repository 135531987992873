export enum FieldKeysEnum {
  AccountHolderName = 'accountHolderName',
  AccountNumber = 'accountNumber',
  Rate = 'rate',
  Address = 'address',
  Agree = 'agree',
  AgreeMarketing = 'agreeMarketing',
  AgreeNightMarketing = 'agreeNightMarketing',
  AgreePrMarketing = 'agreePrMarketing',
  AgreePersonalInfoCollection = 'agreePersonalInfoCollection',
  Agreement = 'agreement',
  BankName = 'bankName',
  BankCode = 'bankCode',
  BasicAddress = 'basicAddress',
  Birthday = 'birthday',
  ConfirmNumber = 'confirmNumber',
  ConfirmPassword = 'confirmPassword',
  CouponNumber = 'couponNumber',
  Code = 'code',
  CustomerAddress = 'customerAddress',
  CustomerTemplateAddress = 'customerTemplateAddress',
  DetailAddress = 'detailedAddress',
  Email = 'email',
  Female = 'female',
  Gender = 'gender',
  Id = 'id',
  IdEmail = 'email',
  InvitationCode = 'invitationCode',
  IsDefault = 'isDefault',
  IsSecret = 'isSecret',
  LeftMessage = 'leftMessage',
  LeftReasons = 'leftReasons',
  Male = 'male',
  Message = 'message',
  MessageInput = 'messageInput',
  MessageSelect = 'messageSelect',
  Mileage = 'mileage',
  MobileNumber = 'mobileNumber',
  Name = 'name',
  NewPassword = 'newPassword',
  None = 'none',
  Password = 'password',
  PetType = 'petType',
  PostNumber = 'postNumber',
  Product = 'product',
  OrderItem = 'orderItem',
  ProductOption = 'productOption',
  QuestionBody = 'questionBody',
  Shippings = 'shippings',
  SocialService = 'socialService',
  UserSocialServices = 'userSocialServices',
  TemplateName = 'templateName',
  UserCertification = 'userCertification',
  VerificationNumber = 'verificationNumber',
  ReturnOrderItemImages = 'returnOrderItemImages',
  Coupon = 'coupon',
  ReviewBody = 'reviewBody',
  ReviewImages = 'reviewImages',
  Score = 'score',
  PayMethodType = 'payMethodType',
  IsUseAllMileage = 'isUseAllMileage',
}
