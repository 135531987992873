import { useRouter } from 'next/router'
import styled from 'styled-components'
import { LinkButton } from '@/components/common/buttons'
import FlatIcon from '@/components/common/icons/FlatIcon'
import { CartItemCountCircle } from '@/components/domains/etc'
import { SearchBarInputText } from '@/components/domains/layouts/header/index'
import { PetTypeFilter } from '@/components/domains/search'
import { IconNameEnum } from '@/constants/icon-name.enum'
import { IconSizeEnum } from '@/constants/icon-size.enum'
import ROUTES from '@/constants/legacy/constRoutes'
import { Bridge } from '@/utils/bridge/bridge'
import appBridgeProvider, { isInAppFlag } from '@/utils/utilBridge'

const SearchHeader = () => {
  const { pathname } = useRouter()
  return (
    <>
      <StyledSearchBarContainer>
        <LinkButton goBack>
          <FlatIcon type={IconNameEnum.IcBack} size={IconSizeEnum.Size24} />
        </LinkButton>
        <StyledSearchBarInputText>
          <SearchBarInputText />
        </StyledSearchBarInputText>
        <StyledIconContainer style={{ paddingRight: '0' }}>
          {pathname !== ROUTES.SEARCH && (
            <LinkButton
              url={isInAppFlag ? undefined : ROUTES.INDEX}
              onClick={() => {
                if (isInAppFlag) {
                  appBridgeProvider((bridge: Bridge) => bridge.goDeepLink('fitpetmall', '/main'))
                }
              }}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <FlatIcon type={IconNameEnum.IcHome} size={IconSizeEnum.Size24} />
            </LinkButton>
          )}
          <LinkButton rel="nosublink" url={ROUTES.CART} style={{ display: 'flex', alignItems: 'center' }}>
            <CartItemCountCircle />
            <FlatIcon type={IconNameEnum.IcCart} size={IconSizeEnum.Size24} />
          </LinkButton>
        </StyledIconContainer>
      </StyledSearchBarContainer>

      <PetTypeFilter />
    </>
  )
}

const StyledSearchBarContainer = styled.div`
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1.6rem;
  height: 5.8rem;
  flex-flow: nowrap;
`

const StyledIconContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
  max-width: 100%;
  min-height: 1px;
  padding-inline-end: 0;
`

const StyledSearchBarInputText = styled.div`
  flex: 1;
`

export default SearchHeader
