import { useDispatch } from 'react-redux'
import {
  doSetBottomSheetData,
  doSetCartConfirmationBottomSheetData,
  doSetProductChoiceBottomSheetData,
} from '@/stores/reduxData'

export const useCartBottomSheetRestReduxData = () => {
  const dispatch = useDispatch()
  const resetCartBottomSheetReduxData = () => {
    dispatch(
      doSetBottomSheetData({
        isCartConfirmationVisible: false,
      })
    )
    dispatch(
      doSetProductChoiceBottomSheetData({
        reduxProductInProductChoiceBottomSheet: undefined,
        reduxTrackerDataInProductChoiceBottomSheet: undefined,
      })
    )
    dispatch(
      doSetCartConfirmationBottomSheetData({
        reduxProductInCartConfirmationBottomSheet: undefined,
      })
    )
  }
  return {
    resetCartBottomSheetReduxData,
  }
}
