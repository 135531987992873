// CAUTION: transform-origin 에 사용하기 위해서 value 를 snake_case 로 적용
export enum TooltipArrowPositionEnum {
  LeftTop = 'left_top',
  LeftMiddle = 'left_middle',
  LeftBottom = 'left_bottom',
  TopLeft = 'top_left',
  TopMiddle = 'top_middle',
  TopRight = 'top_right',
  RightTop = 'right_top',
  RightMiddle = 'right_middle',
  RightBottom = 'right_bottom',
  BottomLeft = 'bottom_left',
  BottomMiddle = 'bottom_middle',
  BottomRight = 'bottom_right',
}
