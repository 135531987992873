import { useDispatch } from 'react-redux'
import MUTATION_VARIABLES from '@/containers/gqls/base/mutation_variables'
import { useApiMutation, useCustomRouter } from '@/containers/hooks'
import { UserContainer } from '@/containers/users/UserContainer'
import { AdPetType } from '@/graphql/generated/schemas'
import { doSetPetType } from '@/stores/reduxData'
import { AppStorageDataType, Bridge } from '@/utils/bridge/bridge'
import appBridgeProvider, { isAppStorageDisabled } from '@/utils/utilBridge'
import { localPetType } from '@/utils/utilLocalStorage'

type UsePetTypeType = {
  getPetType: () => AdPetType
  isPetType: (petType: string | AdPetType | undefined) => boolean
  setPetType: (petType: string | AdPetType | undefined, updateServer?: boolean, updateAppStorage?: boolean) => void
  syncAppPetType: () => void
}

const usePetType = (): UsePetTypeType => {
  const dispatch = useDispatch()
  const { me } = UserContainer.useContainer()
  const { query } = useCustomRouter()

  const isPetType = (petType: string | AdPetType | undefined) => {
    if (!petType) return false
    const convertPetType = petType.toUpperCase()
    return convertPetType === AdPetType.Dog || convertPetType === AdPetType.Cat
  }

  const getPetType = () => {
    return localPetType.load() || AdPetType.Dog
  }

  const setPetType = (
    petType: string | AdPetType | undefined,
    updateServer: boolean = true,
    updateAppStorage: boolean = true
  ) => {
    if (!petType || !isPetType(petType)) return
    const _petType = petType.toUpperCase() as AdPetType
    const petTypeInLocal = localPetType.load()
    // eslint-disable-next-line no-unused-expressions
    updateAppStorage &&
      !isAppStorageDisabled &&
      appBridgeProvider((bridge: Bridge) => bridge.storageSetItem('petType', _petType))
    dispatch(doSetPetType(_petType))

    if (_petType === petTypeInLocal) {
      return
    }

    localPetType.save(_petType)

    if (updateServer) {
      _postPetTypeToServer(_petType)
    }
  }

  const [updatePetTypeMutation] = useApiMutation('updateDisplayPetType')
  const _postPetTypeToServer = (petType: AdPetType) => {
    if (me?.id) {
      const params = {
        id: me.id,
        displayPetType: petType,
      }
      const variables = MUTATION_VARIABLES.UPDATE_DISPLAY_PET_TYPE(params)
      // @ts-ignore
      updatePetTypeMutation({ variables })
    }
  }

  const syncAppPetType = () => {
    if (!('petType' in query)) {
      appBridgeProvider((bridge: Bridge) =>
        bridge.storageGetItem('petType').then((data: AppStorageDataType<AdPetType>) => {
          if (!data.data) return
          setPetType(data.data, false, false)
        })
      )
    }
  }

  return {
    getPetType,
    setPetType,
    isPetType,
    syncAppPetType,
  }
}

export default usePetType
