import { gql } from '@apollo/client'
import DEFAULT_FRAGMENTS from '@/containers/gqls/base/defaultFragments'

const ADDRESS_MUTATION_GQLS = {
  CREATE_CUSTOMER_ADDRESS: gql`
    mutation createCustomerTemplateAddress($input: CreateCustomerTemplateAddressInput!) {
      createCustomerTemplateAddress(input: $input) {
        customerTemplateAddress {
          ...customerTemplateAddress
        }
      }
    }
    ${DEFAULT_FRAGMENTS.customerTemplateAddress}
  `,
  DELETE_CUSTOMER_ADDRESS: gql`
    mutation deleteCustomerTemplateAddress($id: ID!) {
      deleteCustomerTemplateAddress(id: $id) {
        customerTemplateAddress {
          ...customerTemplateAddress
        }
      }
    }
    ${DEFAULT_FRAGMENTS.customerTemplateAddress}
  `,
  UPDATE_CUSTOMER_ADDRESS: gql`
    mutation updateCustomerTemplateAddress($id: ID!, $input: UpdateCustomerTemplateAddressInput!) {
      updateCustomerTemplateAddress(id: $id, input: $input) {
        customerTemplateAddress {
          ...customerTemplateAddress
        }
      }
    }
    ${DEFAULT_FRAGMENTS.customerTemplateAddress}
  `,
}

export default ADDRESS_MUTATION_GQLS
