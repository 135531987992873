import { useEffect } from 'react'
import styled, { keyframes } from 'styled-components'
import { useRecentlyProductList } from '@/components/domains/mypage/favorites/hooks/use-recently-product-list'
import PopularBrands from '@/components/domains/search/PopularBrands'
import ProductRecentlySliderWithAddToCart from '@/components/domains/search/ProductRecentlySlider'
import useSearchedKeywords from '@/components/domains/search/hooks/useSearchedKeywords'
import { PopularKeywords, RecentSearchKeywords, RecommendKeywords } from '@/components/domains/search/index'
import { FitpetMallEventEnum } from '@/constants/fitpet-mall-event.enum'
import { PageSizeEnum } from '@/constants/page-size.enum'
import { QueryStringKeyEnum } from '@/constants/query-string-key.enum'
import { useTracker } from '@/containers/contexts/EventTrackingProvider'
import { useCustomRouter, useSearch } from '@/containers/hooks'

const SearchBody = () => {
  const { query } = useCustomRouter()
  const { [QueryStringKeyEnum.SearchPetType]: searchPetType } = query

  const tracker = useTracker()
  const { initSearchBodyMount } = useSearch()
  const { isShowSearchedKeywords } = useSearchedKeywords()
  const { recentlyProducts } = useRecentlyProductList({ length: PageSizeEnum.PageSize20 })

  useEffect(() => {
    initSearchBodyMount()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (searchPetType) {
      tracker.triggerCustomEvent(FitpetMallEventEnum.ViewSearch, {
        petTypeSearch: searchPetType,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchPetType])

  return (
    <StyledSearchBodyContainer hasKeywords={isShowSearchedKeywords}>
      <StyledDisplayDefaultContainer>
        <section>
          <RecentSearchKeywords />
        </section>
        <section>
          <PopularBrands />
        </section>
        {!!recentlyProducts?.length && (
          <section>
            <ProductRecentlySliderWithAddToCart data={recentlyProducts} />
          </section>
        )}
        <section>
          <RecommendKeywords />
        </section>
        <section>
          <PopularKeywords />
        </section>
      </StyledDisplayDefaultContainer>
    </StyledSearchBodyContainer>
  )
}

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const StyledSearchBodyContainer = styled.div<{ hasKeywords: boolean }>`
  display: ${({ hasKeywords }) => (hasKeywords ? 'none' : 'block')};
  animation: ${fadeIn} 0.5s ease-in-out;
`

const StyledDisplayDefaultContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 3.6rem;
  padding-top: 2rem;
`

export default SearchBody
