import { gql } from '@apollo/client'
import DEFAULT_FRAGMENTS from '@/containers/gqls/base/defaultFragments'

const MILEAGE_QUERY_GQLS = {
  MILEAGE_LIST: gql`
    query mileageList($pageInfo: PageInfoInputType, $filter: GenericScalar, $user: String, $orderBy: String) {
      mileageHistories(pageInfo: $pageInfo, filter: $filter, user: $user, orderBy: $orderBy) {
        edges {
          node {
            ...mileageHistory
            orderItem {
              id
              productName
            }
            review {
              id
              product {
                name
              }
              productOptionName
            }
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
    }
    ${DEFAULT_FRAGMENTS.pageInfo}
    ${DEFAULT_FRAGMENTS.mileageHistory}
  `,
}

export default MILEAGE_QUERY_GQLS
