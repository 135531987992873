/**
 * 신규 기획전 이벤트 트래커
 * @link https://www.notion.so/industrious-ethernet-850/9b234b7bd70a40548c0cc048a37f6bb1?v=c071ecb7b7ce456fb51eca95577a6c07&pvs=4
 */
import {
  ProductScheme,
  SearchDisplayProductInfoResponseDtoDisplayProductInfosItemProduct,
} from '@/apis/rest/generated/types'
import { FitpetMallEventEnum } from '@/constants/fitpet-mall-event.enum'
import { AdSlugPropertiesType } from '@/constants/legacy/constType'
import { LocationEnum } from '@/constants/location.enum'
import { TrackerOrderByEnum } from '@/constants/tracker-sort-by-value.enum'
import { AdType } from '@/graphql/generated/schemas'
import { ProductTypeDefinedByFrontend } from '@/types/product-type-defined-by.frontend'
import { Base64ConverterUtil } from '@/utils/base64-converter.util'
import { ReferrerUtil } from '@/utils/referrer.util'
import { decodeIdForEventTracker } from '@/utils/utilApi'
import { getPrevPageName } from '@/utils/utilURL'
import { useTracker } from '../contexts/EventTrackingProvider'
import { useCustomRouter } from '../hooks'

interface AdTypeParams {
  adId?: string
  adName?: string
  adSlug?: string
}
interface ClickSectionTabParams extends AdTypeParams {
  sectionIndex: number
  tabName: string
}
interface ClickProductParamsV4 extends AdTypeParams {
  sectionIndex: number
  sectionTabName: string
  product: ProductScheme
}

interface ClickProductParams extends AdTypeParams {
  sectionIndex: number
  sectionTabName: string
  product: ProductTypeDefinedByFrontend
}
interface ClickDetailImageUrlParams extends AdTypeParams {
  adUrl: string
}
interface ClickFilterParams extends AdTypeParams {
  sectionIndex: number
  sectionTabName: string
  sortBy?: TrackerOrderByEnum
}

interface ClickCouponButtonParams extends AdTypeParams {
  buttonText: string
  isLogin: boolean
}

interface ClickCouponDownloadParams extends AdTypeParams {
  couponName: string
  couponGroupId: string
}

interface ViewEventsaleDetailParams extends AdSlugPropertiesType {
  search_engine?: string
  search_query?: string
  is_product_included?: boolean
  eventsale_location?: string
}

const useEventDetailEventTracker = () => {
  const { triggerCustomEvent } = useTracker()
  const referrer = ReferrerUtil.getReferrer()
  const {
    query: { location, prev_path, eventsale_location },
  } = useCustomRouter()

  // 이벤트 상세 페이지 진입
  const trackViewEventsaleDetail = (ad: AdType) => {
    const referrerDomain = ReferrerUtil.getReferrerDomain(referrer)
    const searchQuery = ReferrerUtil.getSearchQuery(referrer)

    let properties: ViewEventsaleDetailParams = {
      eventsaleSlug: ad.slug || '',
      eventsaleId: decodeIdForEventTracker(ad.id),
      eventsaleName: ad.name,
      location: location as LocationEnum,
      is_product_included: !!ad?.adDisplayCollections?.totalCount,
      eventsale_location: eventsale_location as string,
    }

    if (referrerDomain) {
      properties['search_engine'] = referrerDomain
    }
    if (searchQuery) {
      properties['search_query'] = searchQuery
    }

    if (ad.slug === 'app-only') {
      properties = { ...properties, path: getPrevPageName(prev_path as string) }
    }

    triggerCustomEvent(FitpetMallEventEnum.ViewEventsaleDetail, properties)
    return properties
  }

  // 섹션의 탭메뉴 클릭
  const trackClickEventsaleDetailSection = ({ adId, adName, adSlug, sectionIndex, tabName }: ClickSectionTabParams) => {
    triggerCustomEvent(FitpetMallEventEnum.ClickEventsaleDetailSection, {
      eventsaleSlug: adSlug,
      eventsaleName: adName,
      eventsaleId: decodeIdForEventTracker(adId),
      eventsaleSectionIndex: sectionIndex,
      eventsaleSectionTabName: tabName,
    })
  }

  // 섹션내에 상품 클릭
  const trackClickEventsaleProductV4 = ({
    adId,
    adName,
    adSlug,
    sectionIndex,
    sectionTabName,
    product,
  }: ClickProductParamsV4) => {
    triggerCustomEvent(FitpetMallEventEnum.ClickEventsaleProduct, {
      eventsaleId: decodeIdForEventTracker(adId),
      eventsaleName: adName,
      eventsaleSlug: adSlug,
      eventsaleSectionIndex: sectionIndex,
      eventsaleSectionTabName: sectionTabName,
      productId: product?.id,
      productName: product?.name,
      productPrice: product?.price,
      productDiscountRate: product?.discountRate?.toFixed(2),
      productPurchasePrice: product?.salePrice,
      productReviewAvgscore: product?.review.score,
      productReviewTotalCount: product?.review.count,
      productIsInStock: product?.isRealSoldOut,
    })
  }

  // 섹션내에 상품 클릭
  const trackClickEventSaleProduct = ({
    adId,
    adName,
    adSlug,
    sectionIndex,
    sectionTabName,
    product,
  }: ClickProductParams) => {
    triggerCustomEvent(FitpetMallEventEnum.ClickEventsaleProduct, {
      eventsaleId: decodeIdForEventTracker(adId),
      eventsaleName: adName,
      eventsaleSlug: adSlug,
      eventsaleSectionIndex: sectionIndex,
      eventsaleSectionTabName: sectionTabName,
      productId: product?.id,
      productName: product?.name,
      productPrice: product?.price,
      productDiscountRate: product?.discountRate?.toFixed(2),
      productPurchasePrice: product?.salePrice,
      productReviewAvgscore: product?.reviewScore,
      productReviewTotalCount: product?.reviewCount,
      productIsInStock: product?.isRealSoldOut,
      is_shownprice_coupondiscounted: product?.hasApplicableProductCoupon,
    })
  }

  // 이벤트 상세이미지에서 URL이 있는 이미지 클릭
  const trackClickEventsaleDetailImage = ({ adUrl, adId, adSlug, adName }: ClickDetailImageUrlParams) => {
    triggerCustomEvent(FitpetMallEventEnum.ClickEventsaleDetailImage, {
      eventsaleClickurl: adUrl,
      eventsaleSlug: adSlug,
      eventsaleId: decodeIdForEventTracker(adId),
      eventsaleName: adName,
    })
  }

  /** 이벤트 섹션 내 정렬 필터 변경시(변경완료 시점 기준) */
  const trackClickEventsaleFilter = ({
    adId,
    adName,
    adSlug,
    sectionIndex,
    sectionTabName,
    sortBy,
  }: ClickFilterParams) => {
    triggerCustomEvent(FitpetMallEventEnum.ClickEventsaleFilter, {
      eventsaleId: decodeIdForEventTracker(adId),
      eventsaleName: adName,
      eventsaleSlug: adSlug,
      eventsaleSectionIndex: sectionIndex,
      eventsaleSectionTabName: sectionTabName,
      sortBy: sortBy,
    })
  }

  /** 쿠폰 다운받기 플로팅 버튼 */
  const trackerClickEventsaleCouponButton = ({
    adId,
    adName,
    adSlug,
    buttonText,
    isLogin,
  }: ClickCouponButtonParams) => {
    triggerCustomEvent(FitpetMallEventEnum.ClickEventsaleCouponButton, {
      eventsaleId: Base64ConverterUtil.convertBase64ToNumberId(adId || ''),
      eventsaleName: adName,
      eventsaleSlug: adSlug,
      buttonText,
      isLogin,
    })
  }

  /** 쿠폰 다운로드 모달에서 쿠폰 다운로드 */
  const trackerClickEventsaleCouponDownload = ({
    adId,
    adName,
    adSlug,
    couponGroupId,
    couponName,
  }: ClickCouponDownloadParams) => {
    triggerCustomEvent(FitpetMallEventEnum.ClickEventsaleCouponDownload, {
      eventsaleId: Base64ConverterUtil.convertBase64ToNumberId(adId || ''),
      eventsaleName: adName,
      eventsaleSlug: adSlug,
      couponGroupId: Base64ConverterUtil.convertBase64ToNumberId(couponGroupId),
      couponName,
    })
  }

  return {
    trackViewEventsaleDetail,
    trackClickEventsaleDetailSection,
    trackClickEventsaleProductV4,
    trackClickEventsaleDetailImage,
    trackClickEventsaleFilter,
    trackerClickEventsaleCouponButton,
    trackerClickEventsaleCouponDownload,
    trackClickEventSaleProduct,
  }
}

export default useEventDetailEventTracker
