export enum PaymentMethodKoreanEnum {
  CreditCard = '신용카드',
  NaverPay = '네이버페이',
  KakaoPay = '카카오페이',
  TossPay = '토스페이',
  Payco = '페이코',
  KbPay = 'KB페이',
  VirtualAccount = '가상계좌',
  DepositAccount = '계좌입금',
  Bank = '계좌이체',
  Mobile = '핸드폰결제',
  ZeroPrice = '적립금',
  // Todo : Point payment 메서드가 페이코에서만 사용하는지 확인
  Point = '포인트',
  Null = '',
}
