import { gql } from '@apollo/client'
import DEFAULT_FRAGMENTS from '@/containers/gqls/base/defaultFragments'
import PRODUCT_FRAGMENTS from '@/containers/gqls/products/fragments'

const DISPLAY_COLLECTION_QUERY_GQLS = {
  CACHED_DISPLAY_COLLECTION_PRODUCT_INFOS: gql`
    query cachedDisplayCollectionProductInfos($id: ID!, $pageInfo: PageInfoInputType) {
      cachedDisplayCollection(id: $id, pageInfo: $pageInfo)
    }
  `,

  DISPLAY_COLLECTION_PRODUCT_INFOS: gql`
    query displayCollectionProductInfos($id: ID!, $pageInfo: PageInfoInputType, $filter: GenericScalar) {
      displayCollection(id: $id) {
        id
        petType
        displayProductInfos(pageInfo: $pageInfo, filter: $filter) {
          pageInfo {
            ...pageInfo
          }
          totalCount
          edges {
            node {
              id
              product {
                ...productListDefaultItem
              }
              productpromotion {
                id
                productPromotionType
              }
            }
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.pageInfo}
    ${PRODUCT_FRAGMENTS.productListDefaultItem}
  `,
  DISPLAY_COLLECTIONS_BEST: gql`
    query displayCollectionsBest {
      displayCollections(filter: { collectionType: ["BEST"] }) {
        totalCount
        edges {
          node {
            id
            name
          }
        }
      }
    }
  `,
}

export default DISPLAY_COLLECTION_QUERY_GQLS
