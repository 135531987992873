import { FC } from 'react'
import styled from 'styled-components'
import { TextEllipsis } from '@/components/common'
import { LinkButton } from '@/components/common/buttons'
import { S3_HOST_URL } from '@/constants/legacy/constEnv'
import ROUTES from '@/constants/legacy/constRoutes'
import { useCustomRouter } from '@/containers/hooks'

type OrderItemProductInfoProps = {
  productNumber: number
  productName: string
  thumbnail: string
  productOptionName: string
  shouldRemoveHistoryStateOnRoutePush?: boolean
  productPromotionId?: string
}

const OrderItemProductInfo: FC<OrderItemProductInfoProps> = ({
  productNumber,
  productName,
  productOptionName,
  thumbnail,
  shouldRemoveHistoryStateOnRoutePush = false,
  productPromotionId,
}) => {
  const { back } = useCustomRouter()

  const productDetailLink =
    ROUTES.PRODUCTS.DETAIL.replace('[productId]', String(productNumber)) + `?promotion=${productPromotionId}`

  return (
    <LinkButton
      url={productDetailLink}
      afterRouteChange={() => {
        if (shouldRemoveHistoryStateOnRoutePush) {
          back()
        }
      }}
    >
      <ProductInfoContainer>
        <ImageWrapper>
          {thumbnail && (
            <img className="review-thumbnail" alt={`${productName}-thumbnail`} src={`${S3_HOST_URL}/${thumbnail}`} />
          )}
        </ImageWrapper>
        <InfoWrapper>
          <TextEllipsis size={{ xs: 13, md: 15 }} lineNumber={2}>
            {productName}
          </TextEllipsis>
          {productOptionName && (
            <ProductOption>
              <TextEllipsis color="gray400" size={{ xs: 13, md: 15 }} lineNumber={1}>
                {`${productOptionName}`}
              </TextEllipsis>
            </ProductOption>
          )}
        </InfoWrapper>
      </ProductInfoContainer>
    </LinkButton>
  )
}

const ProductInfoContainer = styled.div`
  display: flex;
  padding: 2rem;
  .review-thumbnail {
    width: 100%;
    height: 100%;
  }
`

const ImageWrapper = styled.div`
  width: 6rem;
  height: 6rem;
  border-radius: 2px;
  overflow: hidden;
  margin-right: 1rem; // 이미지와 텍스트 사이의 간격 조정
`

const InfoWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

const ProductOption = styled.div`
  padding: 0.3rem 0;
`

export default OrderItemProductInfo
