/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * Fitpet Mall
 * 핏펫몰 API 스펙

API 스펙 작성시 다음 가이드를 숙지하시기 바랍니다.

- [Open API 가이드 참고](https://swagger.io/docs/specification/about/)
- [Kotlin-Spring Open API Generator 설정 참고](https://openapi-generator.tech/docs/generators/kotlin-spring)
- REST API 디자인 가이드 참고
  - doc > guide > rest-api-design.md
- OpenAPI Spec 컨벤션 참고
  - doc > guid > openapi-spec-convention.md
- 설명(description) 작성시 마크다운([CommonMark](https://commonmark.org/help/)) 구문 참고

로컬에서 스웨거 에디터를 사용하는 방법은 다음과 같다.

```shell
docker pull swaggerapi/swagger-editor
docker run -d -p 8123:8080 swaggerapi/swagger-editor
open http://localhost:8123
```

Api 스펙을 하나의 파일로 합치려면 다음과 같이 실행한다.

```shell
# 설치
npm install -g swagger-cli

# 실행
swagger-cli bundle api-spec.yaml --outfile build/openapi.yaml --type yaml
```
 * OpenAPI spec version: 1.0.0
 */

/**
 * GNB 종류

- `PLACEHOLDER` : PLACEHOLDER
- `HOME` : Home
- `AD` : 개별 이벤트 ex) 신상백서, MD추천
- `AD_LIST` : 이벤트 리스트 ex) 현 메뉴의 이벤트
- `DISPLAY_COLLECTION` : 개별 DisplayCollection 예비로 만들어 놓음
- `CATEGORY` : 카테고리 중 한개 ex) 현 메뉴의 건강관리
- `BRAND` : 개별 Brand 예비로 만들어 놓음
- `BRAND_LIST` : Brand의 목록 예비로 만들어 놓음
- `PRODUCT` : 개별 Product 예비로 만들어 놓음
- `BEST` : 그냥 Best
- `DEAL_100` : 100원 딜
- `DEAL_990` : 990원 딜
 */
export type GnbTypeEnum = typeof GnbTypeEnum[keyof typeof GnbTypeEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GnbTypeEnum = {
  PLACEHOLDER: 'PLACEHOLDER',
  HOME: 'HOME',
  AD: 'AD',
  AD_LIST: 'AD_LIST',
  DISPLAY_COLLECTION: 'DISPLAY_COLLECTION',
  CATEGORY: 'CATEGORY',
  BRAND: 'BRAND',
  BRAND_LIST: 'BRAND_LIST',
  PRODUCT: 'PRODUCT',
  BEST: 'BEST',
  DEAL_100: 'DEAL_100',
  DEAL_990: 'DEAL_990',
} as const
