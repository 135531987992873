import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CartConfirmationBottomSheet } from '@/components/common/modals/CartConfirmationBottomSheet'
import { ProductChoiceBottomSheet } from '@/components/common/modals/ProductChoiceBottomSheet'
import { ProductCouponDownloadBottomSheet } from '@/components/common/modals/ProductCouponDownloadBottomSheet'
import { CartChangeOptionBottomSheet } from '@/components/domains/cart/CartChangeOptionBottomSheet'
import { CartProductCouponBottomSheet } from '@/components/domains/cart/CartProductCouponBottomSheet'
import { CartOrderCouponDownloadBottomSheet } from '@/components/domains/cart/cart-order-coupon-download-bottom-sheet'
import { useCustomRouter } from '@/containers/hooks'
import { doSetBottomSheetData } from '@/stores/reduxData'
import { RootState } from '@/stores/store'

export const BottomSheet = () => {
  const dispatch = useDispatch()
  const { asPath } = useCustomRouter()
  const bottomSheetData = useSelector((rootState: RootState) => rootState.reduxDataReducers.bottomSheetData)
  const {
    isProductChoiceVisible,
    isCartConfirmationVisible,
    isProductCouponDownloadVisible,
    isCartProductCouponVisible,
    isCartProductOptionChangeVisible,
    isCartOrderCouponDownloadVisible,
  } = bottomSheetData

  useEffect(() => {
    const isAnyVisible = Object.values(bottomSheetData).some((visible) => visible)
    const overflowStyle = isAnyVisible ? 'hidden' : 'unset'

    if (document.body.style.overflow !== overflowStyle) {
      document.body.style.overflow = overflowStyle
    }
  }, [bottomSheetData])

  useEffect(() => {
    // 라우터 변경 시 바텀시트를 닫습니다.
    dispatch(
      doSetBottomSheetData({
        isProductChoiceVisible: false,
        isCartConfirmationVisible: false,
        isProductCouponDownloadVisible: false,
      })
    )
  }, [asPath, dispatch])

  return (
    <>
      {<ProductChoiceBottomSheet visible={isProductChoiceVisible} />}
      {<CartConfirmationBottomSheet visible={isCartConfirmationVisible} />}
      {<ProductCouponDownloadBottomSheet visible={isProductCouponDownloadVisible} />}
      {<CartProductCouponBottomSheet visible={isCartProductCouponVisible} />}
      {<CartChangeOptionBottomSheet visible={isCartProductOptionChangeVisible} />}
      {<CartOrderCouponDownloadBottomSheet visible={isCartOrderCouponDownloadVisible} />}
    </>
  )
}
