/**
 * 아래 설정은 https://github.com/vercel/next.js/tree/master/examples/with-redux-wrapper 참고
 */
// https://qiita.com/maruware/items/89b3547fa75488980952
// https://github.com/kirill-konshin/next-redux-wrapper
// wrapperStore 참고: https://velog.io/@jjunn0/NEXTjs-Redux-styled-component-%EC%A0%81%EC%9A%A9%ED%95%98%EA%B8%B0
import { composeWithDevTools } from '@redux-devtools/extension'
import { createWrapper } from 'next-redux-wrapper'
import { createStore, applyMiddleware, combineReducers, compose } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { reduxUIReducers, reduxDataReducers } from '@/stores/index'

const bindMiddleware = (middleware: any) => {
  return applyMiddleware(...middleware)
}

const composeEnhancer = process.env.FITPETMALL_ENV !== 'production' ? composeWithDevTools({}) : compose()

export const rootReducer = combineReducers({
  reduxUIReducers,
  reduxDataReducers,
})

export type RootState = ReturnType<typeof rootReducer>

export const initStore = (preloadedState = {}) => {
  const store = createStore(rootReducer, preloadedState, composeEnhancer(bindMiddleware([thunkMiddleware])))
  return store
}

export const store = initStore()

const makeStore = () => {
  return store
}

// cypress에서 redux 접근을 위해 윈도우에 store를 등록한다.
if (typeof window !== 'undefined' && window.Cypress) {
  window.Cypress.store = store
}

// export const wrapperStore = createWrapper<RootState>(initStore)

// @ts-ignore
export const wrapperStore = createWrapper(makeStore)
