import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { LinkButton } from '@/components/common/buttons'
import FlatIcon from '@/components/common/icons/FlatIcon'
import { Image } from '@/components/common/images/Image'
import { IconNameEnum } from '@/constants/icon-name.enum'
import { IconSizeEnum } from '@/constants/icon-size.enum'
import { FITPET } from '@/constants/legacy/constData'
import { FITPETMALL_HOST, S3_FOLDERS_LEGACY, S3_HOST_URL } from '@/constants/legacy/constEnv'
import ROUTES from '@/constants/legacy/constRoutes'
import { getTodayDate, isBetweenDays } from '@/utils/utilDate'
import { sessionLayoutBackgroundImageIndex } from '@/utils/utilSessionStorage'

const fitpetLogo = `${FITPETMALL_HOST}/images/fitpet_logo.svg`
const bulbIcon = `${S3_HOST_URL}/${S3_FOLDERS_LEGACY.ASSET_IMAGES}/ic_blub.svg`
const qrCodeImage = `${S3_HOST_URL}/${S3_FOLDERS_LEGACY.ASSET_IMAGES}/fitpetmall_home_qr_code_image.png`

const originBanners = [
  {
    key: 'care',
    image: `${S3_HOST_URL}/${S3_FOLDERS_LEGACY.ASSET_IMAGES}/fitpetmall_pc_home_image_main.png`,
    url: '',
    startDate: '2024-06-25',
    endDate: '2099-01-01',
  },
  {
    key: 'appCoupon',
    image: `${S3_HOST_URL}/${S3_FOLDERS_LEGACY.ASSET_IMAGES}/fitpetmall_pc_home_image_app_coupon.png`,
    url: '',
    startDate: '2024-06-25',
    endDate: '2099-01-01',
  },
  {
    key: 'membership',
    image: `${S3_HOST_URL}/${S3_FOLDERS_LEGACY.ASSET_IMAGES}/fitpetmall_pc_home_image_membership.png`,
    url: ROUTES.MEMBERSHIP.INTRO,
    startDate: '2024-06-25',
    endDate: '2099-01-01',
  },
  {
    key: 'sale',
    image: `${S3_HOST_URL}/${S3_FOLDERS_LEGACY.ASSET_IMAGES}/fitpetmall_pc_home_image_240612.png`,
    url: '/events/T_favoriteitem2406-dog',
    startDate: '2024-06-12',
    endDate: '2024-06-24',
  },
  {
    key: 'cat-sand-1',
    image: `${S3_HOST_URL}/${S3_FOLDERS_LEGACY.ASSET_IMAGES}/fitpetmall_pc_home_image_240617-1.png`,
    url: '/events/T_catsand2406-cat?utm_source=BRANDTEAM&utm_medium=BRANDTEAM&utm_campaign=ALL_ALL_ALL_BRANDTEAM_BRANDTEAM_SAND&utm_content=ALL_NONE_NONE_NONE_NONE_SAND&utm_term=SAND',
    startDate: '2024-06-17',
    endDate: '2024-06-23',
  },
  {
    key: 'cat-sand-2',
    image: `${S3_HOST_URL}/${S3_FOLDERS_LEGACY.ASSET_IMAGES}/fitpetmall_pc_home_image_240617-2.png`,
    url: '/events/T_catsand2406-cat?utm_source=BRANDTEAM&utm_medium=BRANDTEAM&utm_campaign=ALL_ALL_ALL_BRANDTEAM_BRANDTEAM_SAND&utm_content=ALL_NONE_NONE_NONE_NONE_SAND&utm_term=SAND',
    startDate: '2024-06-17',
    endDate: '2024-06-23',
  },
]

const LayoutBackground = () => {
  const [randomBanner, setRandomBanner] = useState<{ key: string; image: string; url: string } | undefined>(undefined)

  useEffect(() => {
    // 우측 배너가 과도하게 깜빡이지 않도록 sessionStorage 에서 배너의 index 를 관리합니다.
    // sessionStorage 를 사용하지 않으면 랜더링 될 때마다 이미지가 변경됩니다.
    // sessionLayoutBackgroundImageIndex.load() 가 0 을 리턴할 수 있으므로 null 체크를 해줍니다.
    // 날짜 범위안에 들어온 배너 추출
    const activeBanners = originBanners.filter((banner) =>
      isBetweenDays({ targetDate: getTodayDate(), startDate: banner.startDate, endDate: banner.endDate })
    )
    const isSessionStorageEmpty = sessionLayoutBackgroundImageIndex.load() === null

    const randomBannerIndex = isSessionStorageEmpty
      ? Math.floor(Math.random() * activeBanners.length)
      : (sessionLayoutBackgroundImageIndex.load() as number)

    if (isSessionStorageEmpty) {
      sessionLayoutBackgroundImageIndex.save(randomBannerIndex)
    }

    setRandomBanner(activeBanners[randomBannerIndex])

    // LayoutBackground 가 정리될 때 sessionStorage 의  sessionLayoutBackgroundImageIndex 를 삭제합니다.
    // 이렇게 하지 않으면 이미지가 변경되지 않습니다.
    return () => {
      sessionLayoutBackgroundImageIndex.remove()
    }
  }, [])
  // 1rem = 10px 이 적용되지 않아 rem 을 사용하지 않고 px 로 직접 값을 지정했습니다.
  return (
    <StyledContainer>
      <StyledFixedBox>
        {randomBanner && (
          <StyledBackgroundContent>
            <StyledLogo url={ROUTES.INDEX}>
              <Image src={fitpetLogo} alt="fitpet-logo" aspectRatio="86 / 22" objectFit="cover" width={140} />
            </StyledLogo>
            <StyledPcLayoutImage>
              {randomBanner.url ? (
                <LinkButton url={randomBanner.url}>
                  <div style={{ display: 'flex' }}>
                    <Image
                      src={randomBanner.image}
                      alt="main-image"
                      aspectRatio="385 / 425"
                      className="image-with-link"
                      objectFit="cover"
                    />
                  </div>
                </LinkButton>
              ) : (
                <Image src={randomBanner.image} aspectRatio="385 / 425" objectFit="cover" alt="main-image" />
              )}
            </StyledPcLayoutImage>
            <StyledImageBottomButtons>
              <div className="ad-contact">
                <div>
                  <span className="ad-icon">AD</span>
                  <span>광고 문의</span>
                </div>
                <div className="ad-email">ads@fitpet.co.kr</div>
              </div>

              <div className="care-contact">
                <div>
                  <span>병원 예약 입점 문의</span>
                </div>
                <div className="care-email">care@fitpet.co.kr</div>
              </div>

              <LinkButton className="go-to-fitpet-button" url={FITPET.INDEX} openNewWindow>
                <div>
                  <Image src={bulbIcon} width={13} height={20} alt="icon" />
                  <span>핏펫이 궁금해요</span>
                </div>
                <FlatIcon type={IconNameEnum.IcArrowRight} size={IconSizeEnum.Size16} />
              </LinkButton>
            </StyledImageBottomButtons>
            <StyledQRCode>
              <Image src={qrCodeImage} alt="qr-code" aspectRatio="320 / 129" objectFit="cover" width={385} />
            </StyledQRCode>
          </StyledBackgroundContent>
        )}
      </StyledFixedBox>
    </StyledContainer>
  )
}

// 기존 코드를 최대한 재활용했습니다.
const StyledContainer = styled.div`
  position: relative;
  width: 385px;
  margin-right: 94px;
  @media only screen and (max-width: 1200px) {
    display: none;
  }
`

const StyledFixedBox = styled.div`
  position: fixed;
  width: inherit;
  top: 50%;
  transform: translateY(-50%);
`

const StyledBackgroundContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const StyledLogo = styled(LinkButton)`
  margin-bottom: 1.6rem;
  display: inline-block;
  position: relative;
`

const StyledPcLayoutImage = styled.div`
  width: 385px;
  display: flex;
  .image-with-link {
    cursor: pointer;
  }
`

const StyledImageBottomButtons = styled.div`
  z-index: 1;
  width: 100%;
  height: 60px;
  display: flex;
  gap: 1px;
  margin-top: 1px;

  & .ad-contact {
    width: 120px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 0 0 0 10px;

    & > div {
      display: flex;
      align-items: center;
    }
    & > div > .ad-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 22px;
      height: 17px;
      padding: 1px 4px;
      margin-right: 4px;
      border-radius: 4px;
      color: ${({ theme }) => theme.color.grayWhite};
      background-color: ${({ theme }) => theme.color.blue300};
      font-size: 9px;
      font-weight: ${(props) => props.theme.fontWeight.semiBold};
    }

    & > div > span {
      font-size: 13px;
      font-weight: ${(props) => props.theme.fontWeight.medium};
      letter-spacing: -0.02em;
      line-height: 18px;
      color: ${(props) => props.theme.color.gray900};
    }

    & > .ad-email {
      font-size: 12px;
      font-weight: ${(props) => props.theme.fontWeight.regular};
      letter-spacing: -0.02em;
      line-height: 15px;
      color: ${(props) => props.theme.color.gray500};
    }
  }

  & .care-contact {
    width: 128px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.7);

    & > div {
      display: flex;
      align-items: center;
    }

    & > div > span {
      font-size: 13px;
      font-weight: ${(props) => props.theme.fontWeight.medium};
      letter-spacing: -0.02em;
      line-height: 18px;
      color: ${(props) => props.theme.color.gray900};
    }

    & > .care-email {
      font-size: 12px;
      font-weight: ${(props) => props.theme.fontWeight.regular};
      letter-spacing: -0.02em;
      line-height: 15px;
      color: ${(props) => props.theme.color.gray500};
    }
  }

  & .go-to-fitpet-button {
    width: 135px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 0 0 10px 0;
    span {
      color: ${(props) => props.theme.color.gray900};
      font-size: 13px;
      font-weight: ${(props) => props.theme.fontWeight.medium};
      letter-spacing: -0.02em;
      line-height: 14px;
    }
    & > div {
      display: flex;
      align-items: center;
      gap: 4px;
    }
  }
`

const StyledQRCode = styled.div`
  z-index: 1;
  width: 100%;
  margin-top: 16px;
`

export default LayoutBackground
