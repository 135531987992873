import { useEffect } from 'react'
import { PageSizeEnum } from '@/constants/page-size.enum'
import useIntersectionObserver from '@/containers/hooks/useIntersectionObserver'
import usePageInfo from '@/containers/hooks/usePageInfo'
import { useRecommendedProductDetailProductsQuery } from '@/graphql/generated/hooks'
import { GraphQLProductType } from '@/utils/product/product-card.util'

const defaultPageSize = PageSizeEnum.PageSize20

const useRelatedProductContainerHookV2 = (productId?: string, pageSize: number = defaultPageSize) => {
  const { pageNumber, setPageNumber, getTablePageInfoVariables } = usePageInfo(defaultPageSize)

  const {
    data,
    loading,
    refetch,
    fetchMore: fetchMoreRelatedProducts,
  } = useRecommendedProductDetailProductsQuery({
    variables: {
      id: productId ?? '',
      pageInfo: {
        first: pageSize,
      },
    },
    skip: !productId,
  })

  const { elementRef } = useIntersectionObserver(() => {
    setPageNumber((prev) => prev + 1)
  })

  const recommendedProductDetailProducts: GraphQLProductType[] =
    data?.recommendedProductDetailProducts?.edges.map(({ node }) => node as unknown as GraphQLProductType) || []

  useEffect(() => {
    if (pageNumber === 1) {
      return
    }
    fetchMoreRelatedProducts({
      variables: {
        pageInfo: {
          first: defaultPageSize,
          after: getTablePageInfoVariables(pageNumber + 1, defaultPageSize).pageInfo.after,
        },
      },
      // updateQuery 가 호출이 안되었다고 lint-error 가 발생하여 @ts-ignore 처리
      // @ts-ignore
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return previousResult
        }
        return {
          ...fetchMoreResult,
          recommendedProductDetailProducts: {
            ...fetchMoreResult.recommendedProductDetailProducts,
            edges: [
              ...(previousResult.recommendedProductDetailProducts?.edges ?? []),
              ...(fetchMoreResult.recommendedProductDetailProducts?.edges ?? []),
            ],
          },
        }
      },
    })
  }, [pageNumber])

  useEffect(() => {
    if (productId) {
      refetch()
    }
  }, [productId])

  return {
    recommendedProductDetailProducts,
    loading,
    totalCount: data?.recommendedProductDetailProducts?.totalCount || 0,
    hasNextPage: data?.recommendedProductDetailProducts?.pageInfo.hasNextPage || false,
    elementRef,
  }
}

export default useRelatedProductContainerHookV2
