import { useDispatch, useSelector } from 'react-redux'
import { useDeleteCartItems, usePatchProductOptionIdOfCartItem, usePutCartItems } from '@/apis/rest/generated/hooks'
import { ProductOptionForCartScheme } from '@/apis/rest/generated/types'
import { ToastConst } from '@/constants/toast.const'
import useCartEventTracker from '@/containers/event-tracker/cart.event-tracker'
import { useGetCartData } from '@/containers/hooks/cart/useGetCartData'
import {
  doSetBottomSheetData,
  doSetCartChangeOptionBottomSheetData,
  doSetCartData,
  doSetCartProductCouponBottomSheetData,
} from '@/stores/reduxData'
import { doSetGlobalToastContent } from '@/stores/reduxUI'
import { RootState } from '@/stores/store'

export const useCartController = () => {
  const dispatch = useDispatch()
  const cartStorageData = useSelector((state: RootState) => state.reduxDataReducers.cartData)
  const { mutate: deleteCartItem } = useDeleteCartItems()
  const { mutate: updateCartOption } = usePatchProductOptionIdOfCartItem()
  const { mutate: updateCartItemCount } = usePutCartItems()
  const { getCartDataByChecked, cartDataParams, updateCartDataParamsWithNewIds } = useGetCartData()
  const { trackClickCartChangeCntV2ButtonEvent } = useCartEventTracker()

  const updateCartCount = ({
    cartItemId,
    cartProductOptionId,
    count,
    prevCount,
  }: {
    cartItemId: number
    cartProductOptionId: number
    count: number
    prevCount: number
  }) => {
    if (!cartStorageData) return

    updateCartItemCount(
      { data: { cartItems: [{ id: cartItemId, productOptionId: cartProductOptionId, quantity: count }] } },
      {
        onSuccess: () => {
          trackClickCartChangeCntV2ButtonEvent({ prevCount, count })
          getCartDataByChecked({ param: cartDataParams })
        },
        onError: (err) => {
          dispatch(
            doSetGlobalToastContent({
              content: err.response?.data?.errors[0].message,
              variant: ToastConst.ToastVariant.withCTA,
            })
          )
          getCartDataByChecked({ param: cartDataParams })
        },
      }
    )
  }

  const deleteEachProduct = (cartItemId: number) => {
    if (!cartStorageData) return

    deleteCartItem(
      { data: { cartItems: [{ id: cartItemId }] } },
      { onSuccess: () => getCartDataByChecked({ param: cartDataParams }) }
    )
  }

  const checkEachProduct = (cartProductOptionId: number) => {
    if (!cartStorageData) return

    const updatedCartData = {
      ...cartStorageData,
      shippings: cartStorageData.shippings.map((group) => ({
        ...group,
        orderItems: group.orderItems.map((product) =>
          product.productOptionId === cartProductOptionId ? { ...product, isChecked: !product.isChecked } : product
        ),
      })),
    }

    const param = updatedCartData.shippings
      .flatMap((group) => group.orderItems)
      .filter((item) => item.isChecked)
      .map((item) => ({
        productCouponId: item?.appliedCouponId,
        checkedCartItemId: item.cartItemId,
      }))

    getCartDataByChecked({ param })
  }

  const changeOption = (cartItemId: number, productOptionId: number, options: ProductOptionForCartScheme[]) => {
    dispatch(doSetBottomSheetData({ isCartProductOptionChangeVisible: true }))
    dispatch(doSetCartChangeOptionBottomSheetData({ cartItemId, productOptionId, options }))
  }

  const confirmChangeOption = ({ cartItemId, productOptionId }: { cartItemId: number; productOptionId: number }) => {
    updateCartOption(
      { id: cartItemId, data: { productOptionId } },
      {
        onSuccess: () => {
          getCartDataByChecked({
            param: cartDataParams,
            onSuccess: () => dispatch(doSetBottomSheetData({ isCartProductOptionChangeVisible: false })),
            onError: () =>
              dispatch(
                doSetGlobalToastContent({ content: '이미 장바구니에 등록된 상품 옵션입니다. 수량 조절만 가능합니다.' })
              ),
          })
        },
        onError: () => dispatch(doSetGlobalToastContent({ content: '이미 장바구니에 등록된 상품 옵션입니다.' })),
      }
    )
  }

  const clickProductCouponButton = (cartProductOptionId: number) => {
    if (!cartStorageData) return

    const targetProduct = cartStorageData.shippings
      .flatMap((group) => group.orderItems)
      .find((product) => product.productOptionId === cartProductOptionId)

    if (targetProduct?.productCoupons?.length) {
      const updatedCartData = {
        ...cartStorageData,
        shippings: cartStorageData.shippings.map((group) => ({
          ...group,
          orderItems: group.orderItems.map((product) => {
            if (product.productOptionId === cartProductOptionId) {
              getCartDataByChecked({
                param: updateCartDataParamsWithNewIds([product.cartItemId]),
              })
              return { ...product, isChecked: true }
            } else {
              return product
            }
          }),
        })),
      }

      dispatch(doSetCartData(updatedCartData))
      dispatch(
        doSetCartProductCouponBottomSheetData({
          optionId: cartProductOptionId,
          coupons: targetProduct.productCoupons,
        })
      )
      dispatch(doSetBottomSheetData({ isCartProductCouponVisible: true }))
    }
  }

  const applyCoupon = (cartProductOptionId: number, couponId: number) => {
    if (!cartStorageData) return

    const param = cartStorageData.shippings
      .flatMap((group) => group.orderItems)
      .filter((item) => item.isChecked)
      .map((item) => ({
        productCouponId: item.productOptionId === cartProductOptionId ? couponId : item?.appliedCouponId,
        checkedCartItemId: item.cartItemId,
      }))

    getCartDataByChecked({
      param,
      onSuccess: (res) => {
        if (!res.excludedCoupons.length) {
          dispatch(
            doSetGlobalToastContent({
              content: cartStorageData.shippings.some((group) =>
                group.orderItems.some((item) => item.productOptionId === cartProductOptionId && item.appliedCouponId)
              )
                ? '쿠폰이 변경되었어요'
                : '할인이 적용되었어요',
              variant: ToastConst.ToastVariant.withCTA,
            })
          )
          dispatch(doSetBottomSheetData({ isCartProductCouponVisible: false }))
        }
      },
      onError: () => {
        getCartDataByChecked({
          param: cartDataParams,
          onSuccess: () => dispatch(doSetBottomSheetData({ isCartProductCouponVisible: false })),
        })
        dispatch(
          doSetGlobalToastContent({
            content: '쿠폰 적용에 실패했어요',
            variant: ToastConst.ToastVariant.withCTA,
          })
        )
      },
    })
  }

  const cancelApplyCoupon = (cartProductOptionId: number) => {
    if (!cartStorageData) return

    const updatedCartData = {
      ...cartStorageData,
      shippings: cartStorageData.shippings.map((group) => ({
        ...group,
        orderItems: group.orderItems.map((product) =>
          product.productOptionId === cartProductOptionId ? { ...product, appliedCouponId: undefined } : product
        ),
      })),
    }

    const param = updatedCartData.shippings
      .flatMap((group) => group.orderItems)
      .filter((item) => item.isChecked)
      .map((item) => ({
        productCouponId: item?.appliedCouponId,
        checkedCartItemId: item.cartItemId,
      }))

    dispatch(doSetCartData(updatedCartData))
    getCartDataByChecked({
      param,
      onSuccess: () => {
        dispatch(
          doSetGlobalToastContent({
            content: '할인 적용이 취소되었어요',
            variant: ToastConst.ToastVariant.withCTA,
          })
        )
        dispatch(doSetBottomSheetData({ isCartProductCouponVisible: false }))
        dispatch(doSetCartProductCouponBottomSheetData(undefined))
      },
    })
  }

  return {
    updateCartCount,
    clickProductCouponButton,
    changeOption,
    deleteEachProduct,
    checkEachProduct,
    confirmChangeOption,
    applyCoupon,
    cancelApplyCoupon,
  }
}
