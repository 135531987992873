import { IModelShippingCompanyDefault } from '@/containers/models/base/defaultModelInterfaces'
import { ModelShippingCompanyDefault } from '@/containers/models/base/defaultModels'

export interface IModelShippingCompany extends IModelShippingCompanyDefault {
  sweetTrackerCode: string
}

export default class ModelShippingCompany extends ModelShippingCompanyDefault {
  constructor(data: IModelShippingCompany) {
    super(data)

    this.sweetTrackerCode = data.sweetTrackerCode
  }
}
