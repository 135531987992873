import { DealTypeEnum } from '@/constants/deal-type.enum'
import {
  FIXED_GID,
  getOrderCancelTypeByOrderStatus,
  isOrderStatusExchangeFinished,
  isOrderStatusOneOfCancel,
  PaymentMethodTypeKorean,
} from '@/constants/legacy/constData'
import { OrderPaymentSummaryType, OrderRefundInfoType, PaymentTrackingDataType } from '@/constants/legacy/constType'
import { OrderCancelTypeEnum } from '@/constants/order-cancel-type.enum'
import { OrderStatusTypeEnum } from '@/constants/order-status-type.enum'
import { PaymentMethodTypeEnum } from '@/constants/payment-method-type.enum'
import { ResponsibilityEnum } from '@/constants/responsibility.enum'
import { ModelProduct } from '@/containers/models'
import {
  IModelOrderDefault,
  IModelOrderItemDefault,
  IModelProductOptionDefault,
} from '@/containers/models/base/defaultModelInterfaces'
import { ModelOrderDefault } from '@/containers/models/base/defaultModels'
import { IModelCoupon } from '@/containers/models/modelCoupon'
import { IModelCustomerAddress } from '@/containers/models/modelCustomerAddress'
import { IModelOrderItem } from '@/containers/models/modelOrderItem'
import { IModelOrderRefundInfo } from '@/containers/models/modelOrderRefundInfo'
import { IModelProduct } from '@/containers/models/modelProduct'
import { IModelShipping } from '@/containers/models/modelShipping'
import ModelTransaction, { IModelTransaction } from '@/containers/models/modelTransaction'
import { IModelUser } from '@/containers/models/modelUser'
import { CartProductType, CartProductTypeUtil } from '@/containers/types/CartProduct'
import { CartProductOptionTypeUtil } from '@/containers/types/CartProductOption'
import { CartShippingGroupType } from '@/containers/types/CartShippingGroup'
import { find, flatten, isEmpty } from '@/utils/utilCommon'
import { deepcopy } from '@/utils/utilData'
import { formatDate } from '@/utils/utilDate'
import { numberWithCommas, tenThousandCurrencyText } from '@/utils/utilNumber'

export type ValidateOrderResultType = {
  isValid: boolean
  doRefreshCartProductOption?: boolean
  errorMessage?: string
  errorSubMessage?: string
  isDeal100?: boolean
  renderPutInCartModal?: boolean
  renderNoticeModal?: boolean
  doRestockAlarm?: boolean
  renderExcludeOrderModal?: boolean
}

export interface IModelOrder extends IModelOrderDefault {
  approvedTransaction: IModelTransaction
  isAllOrderItemsCancelled: boolean //취소, 반품 시 반품 배송비 계산을 위한 flag 값. True 일 경우는 반품비를 돌려줌.
  user: IModelUser
  shippings: IModelShipping[]
  orderOrderItems: IModelOrderItem[]
  orderTransactions: IModelTransaction[]
  availableCoupons: IModelCoupon[]
  customerAddress: IModelCustomerAddress
  orderRefundInfo: IModelOrderRefundInfo
  shippingFeePaidShippingIdsSet: Set<string>
  hasThirdPartyVendorOrderItems: () => boolean
  hasExchangeFinishedOrderItems: () => boolean
  hasReturnOrExchangeOrderItems: () => boolean
  hasCancelOrReturnOrderItems: () => boolean //취소, 교환, 반품 상태를 가지고 있는 orderItem 이 있는지 체크
  getAllProductIdAndName: () => { productId: string; productName: string }[]
  getAllOrderItemIds: () => string[]
  getOrderItemsCount: () => number
  getFormattedOrderDate: () => string
  getCancelTargetOrderItemsByType: (
    cancelType: OrderCancelTypeEnum,
    selectedOrderItemId: string
  ) => IModelOrderItem[] | undefined
  getCancelTargetOrderItemsByTypeVBank: (
    cancelType: OrderCancelTypeEnum,
    selectedOrderItemId: string
  ) => IModelOrderItem[] | undefined
  getOrderItemsByIds: (orderItemIds: string[]) => IModelOrderItem[] | undefined
  getRefundInfo: (
    cancelType: OrderCancelTypeEnum,
    responsibility?: ResponsibilityEnum,
    inRequest?: boolean
  ) => OrderRefundInfoType
  getPaymentSummary: () => OrderPaymentSummaryType
  setOrderItemsByStatus: (status?: OrderStatusTypeEnum) => void
  setOrderItemsByIds: (ids: string[]) => void
  setCancelProcessingOrderItems: () => void
  setCancelProcessedOrderItems: () => void
  hasSoldOutProductOptions: () => boolean
  hasDisabledProducts: () => boolean
  isPromotionMinOrderAmountSatisfied: (totalCustomerPrice: number) => {
    satisfied: boolean
    minOrderAmount: number
    productName?: string
  }
  isPromotionMaxOrderCountSatisfied: () => { satisfied: boolean; canOrderCount: number; productName?: string }
  get100DealOrderItemsCount: () => number
  get100DealProductNames: () => string
  hasExpiredPromotionProducts: () => boolean
  getTotalCouponDiscountAmount: () => number
  isVBankTransaction: () => boolean
  getPaymentTrackingData: (paymentMethod?: PaymentMethodTypeEnum) => PaymentTrackingDataType
  getPartialCancelStatus: () => {
    isDeal100: boolean
    isVBankBeforePaid: boolean
    isMobile: boolean
    productName?: string
  }
  isShippingFeePaid: (shipping?: IModelShipping) => boolean
  getLastTransaction: () => IModelTransaction
  getCartShippingGroups: () => CartShippingGroupType[]
  validateOrder: () => ValidateOrderResultType
}

export default class ModelOrder extends ModelOrderDefault {
  // 모든 주문 상품이 주문, 반품, 교환 중 하나의 상태인지 확인하는 flag
  isAllOrderItemsOneOfCancelStatus: boolean = false
  shippingFeePaidShippingIdsSet: Set<string> = new Set()
  originalOrderItems: IModelOrderItem[] | undefined

  constructor(data: IModelOrder) {
    // @ts-ignore
    super(data)
    this.isAllOrderItemsOneOfCancelStatus = this._getIsAllOrderItemsOneOfCancelStatus()
    this.orderOrderItems
      ?.filter((orderItem) => {
        if (orderItem.shipping.amount > 0) {
          return true
        }
        const returnInfo = orderItem.returnInfo
        if (!returnInfo) {
          return false
        }
        return returnInfo.responsibility === 'CUSTOMER' && returnInfo.acceptedAt
      })
      .map((orderItem) => {
        this.shippingFeePaidShippingIdsSet.add(orderItem.shipping.id)
      })
  }

  _getIsAllOrderItemsOneOfCancelStatus = (orderItems = this.orderOrderItems) => {
    if (!orderItems) {
      return false
    }
    const _orderItemsCnt = orderItems.length
    const _filteredOrderItemsCnt = this._getAllCancelStatusOrderItems(orderItems).length

    return _orderItemsCnt === _filteredOrderItemsCnt
  }

  _isLastItemToCancel = (orderItems = this.orderOrderItems) => {
    if (!orderItems) {
      return false
    }
    const _orderItemsCnt = orderItems.length
    const _filteredOrderItemsCnt = this._getAllCancelStatusOrderItems(orderItems).length

    return _orderItemsCnt - 1 === _filteredOrderItemsCnt
  }

  _isAllItemsSelectedToCancel = () => {
    if (!this.originalOrderItems) return false
    return this.orderOrderItems.length === this.originalOrderItems.length
  }

  _getFlatOrderItemsFromShippings = () => {
    return flatten(this.shippings.map((s) => s.shippingOrderItems))
  }

  getLastTransaction = () => {
    if (!this.orderTransactions || this.orderTransactions.length === 0) return null
    return this.orderTransactions[0]
  }

  isVBankTransaction = () => {
    if (!this.orderTransactions || this.orderTransactions.length === 0) return false
    return this.orderTransactions[0].payMethodType.toLowerCase() === PaymentMethodTypeEnum.VirtualAccount
  }

  getAllProductIdAndName = () => {
    return flatten(
      this.shippings.map((s) =>
        s.shippingOrderItems.map(({ productOption }) => {
          const { product } = productOption
          const { _id, name } = product
          return {
            productId: _id,
            productName: name,
          }
        })
      )
    )
  }

  getTotalCouponDiscountAmount = () => {
    return this.couponDiscountAmount + this.orderCouponDiscountAmount + this.shippingCouponDiscountAmount
  }

  isPromotionMinOrderAmountSatisfied = (totalCustomerPrice: number) => {
    const _orderItems = this._getFlatOrderItemsFromShippings()
    let satisfied = true
    let minOrderAmount = 0
    let productName

    _orderItems.forEach((orderItem) => {
      const { productPromotion } = orderItem
      const { minOrderAmount: _minOrderAmount } = productPromotion
      if (
        productPromotion &&
        _minOrderAmount &&
        totalCustomerPrice - orderItem.productPromotionCustomerAmount < _minOrderAmount
      ) {
        satisfied = false
        minOrderAmount = _minOrderAmount
        productName = `ㆍ${orderItem.productName}-${orderItem.productOption.name}`
      }
    })

    return { satisfied, minOrderAmount, productName }
  }

  isPromotionMaxOrderCountSatisfied = () => {
    const _orderItems = this._getFlatOrderItemsFromShippings()
    let satisfied = true
    let canOrderCount = 0
    let productName

    _orderItems.forEach((orderItem) => {
      const { quantity } = orderItem
      // const { productPromotion } = orderItem.productOption.product
      const { productPromotion } = orderItem
      const { maxOrderCount: _maxOrderCount, boughtCount: _boughtCount } = productPromotion
      if (_maxOrderCount > 0) {
        const _canOrderCount = _maxOrderCount - _boughtCount
        if (quantity > _canOrderCount) {
          satisfied = false
          canOrderCount = _canOrderCount
          productName = `ㆍ${orderItem.productName}-${orderItem.productOption.name}`
        }
      }
    })

    return { satisfied, canOrderCount, productName }
  }

  _get100DealOrderItems = () => {
    const _orderItems = this._getFlatOrderItemsFromShippings()
    return _orderItems.filter((soi) => soi.productPromotion.productPromotionType?.includes(DealTypeEnum.Deal100))
  }

  get100DealOrderItemsCount = () => {
    return this._get100DealOrderItems().length
  }

  get100DealProductNames = () => {
    return [...new Set(this._get100DealOrderItems().map((orderItem) => `ㆍ${orderItem.productName}`))].join('\n')
  }

  // 부분 취소, 반품 불가 상태 객체 반환
  getPartialCancelStatus = () => {
    const partialCancelStatus: {
      isDeal100: boolean
      isVBankBeforePaid: boolean
      isMobile: boolean
      productName?: string
    } = {
      isDeal100: false,
      isVBankBeforePaid: false,
      isMobile: false,
    }

    // 100원, 900원딜 여부 (부분취소, 반품 못함)
    const hundredDealOrderItems = this.orderOrderItems.filter((soi) => {
      return (
        soi.productPromotion.productPromotionType === DealTypeEnum.Deal100 ||
        soi.productPromotion.productPromotionType === DealTypeEnum.Deal990
      )
    })
    partialCancelStatus.isDeal100 = hundredDealOrderItems.length > 0
    if (hundredDealOrderItems.length > 0) {
      partialCancelStatus.productName = hundredDealOrderItems[0].productName
    }

    // 가상계좌인데 미입금 상태 여부 (부분취소, 반품 못함)
    if (this.orderTransactions && this.orderTransactions.length > 0) {
      if (this.orderTransactions[0].payMethodType.toLowerCase() === PaymentMethodTypeEnum.VirtualAccount) {
        if (this.orderTransactions[0].transactionStatus === 'VBANK_READY') {
          partialCancelStatus.isVBankBeforePaid = true
        }

        // 핸드폰 결제 여부 (부분취소, 반품 못함)
      } else if (this.orderTransactions[0].payMethodType.toLowerCase() === PaymentMethodTypeEnum.Mobile) {
        partialCancelStatus.isMobile = true
      }
    }
    return partialCancelStatus
  }

  hasSoldOutProductOptions = () => {
    const _orderItems = this._getFlatOrderItemsFromShippings()
    const _soldOutOrderItems = _orderItems.filter(({ productOption }) => {
      // TODO typescript 오류 수정 필요
      // @ts-ignore
      const _product = new ModelProduct(productOption.product)
      return productOption.stockQuantity === 0 || _product.isProductSoldOut
    })
    return _soldOutOrderItems.length > 0
  }

  hasExpiredPromotionProducts = () => {
    const _orderItems = this._getFlatOrderItemsFromShippings()
    const _expiredOrderItems = _orderItems.filter((soi) => {
      // TODO typescript 오류 수정 필요
      // @ts-ignore
      const _product = new ModelProduct(soi.productOption.product)
      return _product.isOngoingPromotionExpired()
    })

    return _expiredOrderItems.length > 0
  }

  hasDisabledProducts = () => {
    const _orderItems = this._getFlatOrderItemsFromShippings()
    const _disabledOrderItems = _orderItems.filter((soi) => {
      // TODO typescript 오류 수정 필요
      // @ts-ignore
      const _product = new ModelProduct(soi.productOption.product)
      return _product.isDisabledByAdmin() || soi.productOption.isDelete || !soi.productOption.isUse
    })

    return _disabledOrderItems.length > 0
  }

  getAllOrderItemIds = () => {
    return this.orderOrderItems.map((i) => i.id)
  }

  getOrderItemsCount = () => {
    let itemsCount = 0
    this.shippings.forEach((shipping) => {
      const { getShippingOrderItemCount } = shipping as unknown as IModelShipping
      itemsCount += getShippingOrderItemCount()
    })

    return itemsCount
  }

  getFormattedOrderDate = () => {
    return formatDate(this.createdAt)
  }

  getCancelTargetOrderItemsByType = (cancelType: OrderCancelTypeEnum, selectedOrderItemId: string) => {
    if (!this.orderOrderItems || this.orderOrderItems.length === 0) {
      return undefined
    }

    const selectedOrderItem = this.orderOrderItems.find((i) => i.id === selectedOrderItemId)
    if (!selectedOrderItem) {
      return undefined
    }

    let targetOrderItems = [selectedOrderItem]
    let otherOrderItems = []

    if (cancelType === 'cancel') {
      otherOrderItems = this.orderOrderItems.filter(
        (i) =>
          (i.orderStatus === OrderStatusTypeEnum.UnconfirmedPayment ||
            i.orderStatus === OrderStatusTypeEnum.NewOrder) &&
          i.shipping.id === selectedOrderItem?.shipping.id &&
          i.id !== selectedOrderItem.id
      )
    } else {
      // return, exchange case
      const targetShippingId = selectedOrderItem?.shipping.id
      otherOrderItems = this.orderOrderItems.filter(
        (i) =>
          (i.orderStatus === OrderStatusTypeEnum.Shipping || i.orderStatus === OrderStatusTypeEnum.Shipped) &&
          i.shipping.id === targetShippingId &&
          i.id !== selectedOrderItem.id
      )
    }

    return targetOrderItems.concat(otherOrderItems)
  }

  getCancelTargetOrderItemsByTypeVBank = (cancelType: OrderCancelTypeEnum, selectedOrderItemId: string) => {
    if (!this.orderOrderItems || this.orderOrderItems.length === 0) {
      return undefined
    }

    const selectedOrderItem = this.orderOrderItems.find((i) => i.id === selectedOrderItemId)
    if (!selectedOrderItem) {
      return undefined
    }

    let targetOrderItems = [selectedOrderItem]
    let otherOrderItems = []

    if (cancelType === 'cancel') {
      otherOrderItems = this.orderOrderItems.filter(
        (i) =>
          (i.orderStatus === OrderStatusTypeEnum.UnconfirmedPayment ||
            i.orderStatus === OrderStatusTypeEnum.NewOrder) &&
          i.id !== selectedOrderItem.id
      )
    } else {
      // return, exchange case
      const targetShippingId = selectedOrderItem?.shipping.id
      otherOrderItems = this.orderOrderItems.filter(
        (i) =>
          (i.orderStatus === OrderStatusTypeEnum.Shipping || i.orderStatus === OrderStatusTypeEnum.Shipped) &&
          i.shipping.id === targetShippingId &&
          i.id !== selectedOrderItem.id
      )
    }

    return targetOrderItems.concat(otherOrderItems)
  }

  getOrderItemsByIds = (orderItemIds: string[]) => {
    return this.orderOrderItems.filter((i) => orderItemIds.indexOf(i.id) > -1)
  }

  setOrderItemsByStatus = (status?: OrderStatusTypeEnum) => {
    if (!status) {
      return
    }

    const orderCancelType = getOrderCancelTypeByOrderStatus(status)
    if (!orderCancelType) {
      return
    }
    const _filteredOrderStatusItems = this.orderOrderItems.filter(
      (i) => getOrderCancelTypeByOrderStatus(i.orderStatus) === orderCancelType
    )
    this.isAllOrderItemsOneOfCancelStatus = this.orderOrderItems.length === _filteredOrderStatusItems.length

    this.originalOrderItems = deepcopy(this.orderOrderItems)
    this.orderOrderItems = _filteredOrderStatusItems
  }

  setOrderItemsByIds = (ids: string[]) => {
    if (!ids || ids.length === 0) {
      return
    }
    this.isAllOrderItemsOneOfCancelStatus = this.orderOrderItems.length === ids.length
    this.originalOrderItems = deepcopy(this.orderOrderItems)
    this.orderOrderItems = this.orderOrderItems.filter((i) => ids.includes(i.id))
  }

  hasThirdPartyVendorOrderItems = () => {
    const _idx = this.shippings.findIndex((s) => s.shippingPreset.seller.id !== FIXED_GID.SELLER.FITPET)

    return _idx > -1
  }

  hasExchangeFinishedOrderItems = () => {
    const _idx = this.orderOrderItems.findIndex((i) => isOrderStatusExchangeFinished(i.orderStatus))
    return _idx > -1
  }

  _getAllCancelStatusOrderItems = (orderItems = this.orderOrderItems): IModelOrderItem[] => {
    return orderItems.filter((i) => isOrderStatusOneOfCancel(i.orderStatus)) as unknown as IModelOrderItem[]
  }

  _isAllCancelOrderItemsSellerResponsibility = () => {
    const _allCancelStatusOrderItems = this._getAllCancelStatusOrderItems()
    const _orderItemsCnt = _allCancelStatusOrderItems.length
    const _filteredOrderItemsCnt = _allCancelStatusOrderItems.filter(
      (i) => !i.returnInfo.id || (i.returnInfo.id !== '' && i.returnInfo.responsibility === ResponsibilityEnum.Seller)
      // !i.returnInfo.id <- 주문상세에서 취소 아이템의 경우 반품의 판매자 책임과 동일하게 처리
    ).length

    return _orderItemsCnt === _filteredOrderItemsCnt
  }

  _isAllCancelOrderItemsNewOrder = () => {
    return this.originalOrderItems?.every((item) => item.orderStatus === OrderStatusTypeEnum.Canceled)
  }

  // 환불 배송비 계산
  _calculateShippingAmountToReturn = (
    shippingAmount: number,
    orderCancelType: OrderCancelTypeEnum,
    inRequest: boolean, //요청 중 계산 여부
    responsibility?: ResponsibilityEnum
  ): number => {
    if (shippingAmount === 0) return 0
    if (orderCancelType === 'exchange') return 0

    if (orderCancelType === 'cancel') {
      if (inRequest && this._isLastItemToCancel(this.originalOrderItems)) {
        //취소 요청 중 + 마지막 아이템 = 환불 배송비 존재
        return shippingAmount
      }
      if (inRequest && this._isAllItemsSelectedToCancel()) {
        //취소 요청 중 + 모든 아이템 = 환불 배송비 존재
        return shippingAmount
      }
      if (this._getIsAllOrderItemsOneOfCancelStatus(this.originalOrderItems)) {
        //취소 + 모든 아이템 = 환불 배송비 존재
        return shippingAmount
      }
      if (inRequest && this._isAllCancelOrderItemsNewOrder()) {
        return shippingAmount
      }

      return 0
    }

    if (!this._getIsAllOrderItemsOneOfCancelStatus()) return 0

    const isAllSellerResponsibility = inRequest
      ? responsibility === ResponsibilityEnum.Seller
      : this._isAllCancelOrderItemsSellerResponsibility()

    return isAllSellerResponsibility ? shippingAmount : 0
  }

  // 반품 배송비 계산
  _calculateReturnShippingFeeToDeduct = (
    shippingFee: number,
    isPaidShippingFee: boolean,
    returnShippingFee: number,
    shippingExtraFee: number,
    orderCancelType: OrderCancelTypeEnum,
    inRequest: boolean,
    responsibility?: ResponsibilityEnum
  ): number => {
    // 취소의 경우는 반품 배송비 없음
    if (orderCancelType === OrderCancelTypeEnum.Cancel) return 0

    const isAllSellerResponsibility = inRequest
      ? responsibility === ResponsibilityEnum.Seller
      : this._isAllCancelOrderItemsSellerResponsibility()

    // 판매자 책임일 경우 반품배송비 0원
    if (isAllSellerResponsibility) return 0

    // 교환의 경우는 무조건 반품비 * 2
    if (orderCancelType === OrderCancelTypeEnum.Exchange) {
      return returnShippingFee * 2 + shippingExtraFee * 2
    }

    let calculatedReturnShippingFee = returnShippingFee + shippingExtraFee
    // 무료 배송이고, 상품 반품을 할때 주문의 배송비를 지불하지 않은 경우 반품 수수료에 무료로 지원된 배송비를 청구
    if (!isPaidShippingFee) {
      calculatedReturnShippingFee += shippingFee
    }
    return calculatedReturnShippingFee
  }

  getRefundInfo = (
    orderCancelType: OrderCancelTypeEnum,
    responsibility?: ResponsibilityEnum,
    inRequest: boolean = false
  ) => {
    const refundInfo: OrderRefundInfoType = {
      paymentMethod: '',
      productAmount: 0,
      refundAmount: 0,
      couponDiscountAmount: 0,
      couponShippingDiscountAmount: 0,
      mileageDiscountAmount: 0,
      returnShippingFee: 0,
      returnShippingFeeToDeduct: 0,
      shippingExtraFee: 0,
      shippingFee: 0,
      shippingAmount: 0,
      shippingAmountToReturn: 0,
      isConfirmed: false,
      isShippingFeePaid: false,
    }

    let currentShippingId = ''
    let _orderOrderItems: IModelOrderItem[]
    if (orderCancelType === OrderCancelTypeEnum.All) {
      _orderOrderItems = this._getAllCancelStatusOrderItems()
    } else {
      _orderOrderItems = deepcopy(this.orderOrderItems) as unknown as IModelOrderItem[]
    }

    if (!_orderOrderItems || _orderOrderItems.length === 0) {
      return refundInfo
    }

    _orderOrderItems.map((orderItem, index) => {
      const {
        couponDiscountPrice,
        orderCouponDiscountPrice,
        mileageDiscountPrice,
        amount,
        shipping,
        isStatusCancelConfirmed,
      } = orderItem

      const {
        shippingPreset,
        extraFee: shippingExtraFee,
        amount: shippingAmount,
        couponDiscountPrice: shippingCouponDiscountPrice,
        id: shippingId,
      } = shipping

      const { shippingFee, returnFee: returnShippingFee } = shippingPreset

      // 처음 한번만 add. 환불 배송비(refund shipping fee)
      const _shippingAmountToReturn = this._calculateShippingAmountToReturn(
        shippingAmount,
        orderCancelType,
        inRequest,
        responsibility
      )
      const isShippingFeePaid = this.isShippingFeePaid(shipping)
      const _returnShippingFeeToDeduct = this._calculateReturnShippingFeeToDeduct(
        shippingFee,
        isShippingFeePaid,
        returnShippingFee,
        shippingExtraFee,
        orderCancelType,
        inRequest,
        responsibility
      )

      if (isShippingFeePaid) {
        refundInfo.isShippingFeePaid = isShippingFeePaid
      }

      refundInfo.productAmount += amount
      refundInfo.couponDiscountAmount += couponDiscountPrice + orderCouponDiscountPrice

      refundInfo.couponShippingDiscountAmount +=
        index === 0 && this.isAllOrderItemsOneOfCancelStatus ? shippingCouponDiscountPrice : 0

      refundInfo.mileageDiscountAmount += mileageDiscountPrice

      /*
      - 묶음 배송 상품의 경우 환불 배송비 sum 하지 말것
      - 개별 배송 상품의 경우 환불 배송비 sum
      * */
      if (currentShippingId !== shippingId) {
        currentShippingId = shippingId

        refundInfo.shippingAmountToReturn += _shippingAmountToReturn
      }
      refundInfo.shippingAmountToReturn = _shippingAmountToReturn
      refundInfo.shippingAmount = shippingAmount //sum 하지 않음
      refundInfo.returnShippingFeeToDeduct = _returnShippingFeeToDeduct //sum 하지 않음
      refundInfo.returnShippingFee = returnShippingFee //sum 하지 않음
      refundInfo.shippingExtraFee = shippingExtraFee //sum 하지 않음
      refundInfo.shippingFee = shippingFee //sum 하지 않음

      if (isStatusCancelConfirmed()) {
        refundInfo.isConfirmed = true
      }
      return refundInfo
    })

    let paymentMethod = '-'
    if (!isEmpty(this.orderTransactions)) {
      const _orderTransaction = this.orderTransactions[
        this.orderTransactions.length - 1
      ] as unknown as IModelTransaction
      // @ts-ignore
      const orderTransaction = new ModelTransaction(_orderTransaction)
      paymentMethod = orderTransaction.getRefundMethodText()
    }

    refundInfo.paymentMethod = paymentMethod
    refundInfo.mileageDiscountAmount += this.shippingMileageDiscountAmount
    refundInfo.refundAmount = refundInfo.productAmount + refundInfo.shippingAmountToReturn

    if (orderCancelType !== OrderCancelTypeEnum.Cancel) {
      refundInfo.refundAmount -= refundInfo.returnShippingFeeToDeduct
      // const _totalRefundShippingFee = refundInfo.returnShippingFee + refundInfo.shippingAmount
      // refundInfo.refundAmount -= _totalRefundShippingFee
    }

    return refundInfo
  }

  // eslint-disable-next-line class-methods-use-this
  _addCouponInfo = (coupons: any[], couponInfo?: string) => {
    if (!isEmpty(couponInfo)) {
      const _parsedCouponInfo = JSON.parse(couponInfo!)
      coupons.push({
        couponId: _parsedCouponInfo.id,
        couponName: _parsedCouponInfo.name,
      })
    }
  }

  getTotalProductAmount = () => {
    return this.originalAmount - this.productDiscountAmount
  }

  getPaymentTrackingData = (paymentMethod?: PaymentMethodTypeEnum): PaymentTrackingDataType => {
    let _paymentMethod: PaymentMethodTypeEnum
    if (!isEmpty(this.orderTransactions)) {
      const _orderTransaction = this.orderTransactions[
        this.orderTransactions.length - 1
      ] as unknown as IModelTransaction
      // @ts-ignore
      const orderTransaction = new ModelTransaction(_orderTransaction)
      _paymentMethod = orderTransaction.payMethodType.toLowerCase() as PaymentMethodTypeEnum
    } else if (!!paymentMethod) {
      _paymentMethod = paymentMethod
    } else {
      _paymentMethod = PaymentMethodTypeEnum.CreditCard
    }

    // @ts-ignore
    const products: any[] = []
    const coupons: any[] = []

    this._addCouponInfo(coupons, this.couponInfo)

    this.shippings.forEach((shipping) => {
      const { shippingOrderItems, couponInfo } = shipping
      this._addCouponInfo(coupons, couponInfo)

      shippingOrderItems.forEach((orderItem) => {
        const { productOption, couponInfo, quantity: productOptionQuantity } = orderItem
        this._addCouponInfo(coupons, couponInfo)

        const {
          product: _product,
          productPromotionCustomerPrice: price,
          _id: productOptionId,
          name: productOptionName,
        } = productOption
        const { _id, name, categories = [] } = _product

        let product = products.find((item) => item.productId === _id)
        if (!product) {
          product = {
            productId: _id,
            productName: name,
            categories: categories.map((x) => {
              return { categoryId: x._id, categoryName: x.name }
            }),
            productOptions: [],
          }
          products.push(product)
        }
        product.productOptions.push({
          price,
          productOptionQuantity,
          productOptionId,
          productOptionName,
        })
      })
    })
    const totalOrderDiscountAmount =
      this.couponDiscountAmount +
      this.orderCouponDiscountAmount +
      this.shippingCouponDiscountAmount +
      this.mileageDiscountAmount +
      this.shippingMileageDiscountAmount

    const trackingData: PaymentTrackingDataType = {
      orderId: this._id,
      products,
      coupons,
      totalOrderQuantity: this.totalItemQuantity,
      totalPaymentAmount: this.amount,
      totalProductAmount: this.getTotalProductAmount(),
      mileageDiscountAmount: this.mileageDiscountAmount,
      totalShipmentAmount: this.shippingAmount,
      couponDiscountAmount: this.getTotalCouponDiscountAmount(),
      paymentType: PaymentMethodTypeKorean[_paymentMethod],
      totalOrderDiscountAmount,
      maxSaveMileageAmount: this.mileage,
      totalProductDiscountAmount: this.productDiscountAmount,
      couponDiscountRate: totalOrderDiscountAmount / (totalOrderDiscountAmount + this.amount),
    }

    // tosspay, payco의 경우 pay_method_type이 카드, 무통장, 가상계좌 등으로 나뉘기 때문에
    // tracking 이벤트에 한해서 상위 개념으로 강제 지정
    if (this.approvedTransaction?.pgProviderType === 'TOSSPAY') {
      trackingData.paymentType = '토스 페이'
    }
    if (this.approvedTransaction?.pgProviderType === 'PAYCO') {
      trackingData.paymentType = '페이코'
    }

    return trackingData
  }

  getPaymentSummary = (): OrderPaymentSummaryType => {
    const _productDiscountAmount = this.productDiscountAmount > 0 ? this.productDiscountAmount : 0
    const _originalAmount =
      this.originalAmount + (this.productDiscountAmount < 0 ? Math.abs(this.productDiscountAmount) : 0)
    return {
      orderPaymentAmount: this.amount, // 주문 결제 금액
      productOriginalAmount: _originalAmount, // 정가
      productDiscountAmount: _productDiscountAmount + this.productPromotionDiscountAmount, // 할인금액
      productCustomerAmount: this.productPromotionCustomerAmount, // 판매가(할인금액 반영됨)
      productCouponDiscountAmount: this.couponDiscountAmount + this.orderCouponDiscountAmount, // 쿠폰 할인금액(상품 쿠폰 + 주문 쿠폰)
      couponDiscountAmount: this.couponDiscountAmount, // 상품 쿠폰 할인금액
      orderCouponDiscountAmount: this.orderCouponDiscountAmount, // 주문 쿠폰 할인금액
      mileageDiscountAmount: this.mileageDiscountAmount, // 상품 적립금 사용
      isFreeShipping: this.shippingFees + this.shippingExtraFees === 0, // 무료 배송비 여부
      shippingAmount: this.shippingAmount, // 최종 배송비 금액
      shippingSumFees: this.shippingFees + this.shippingExtraFees, // 배송비 + 지역별 추가 배송비
      shippingFees: this.shippingFees, // 배송비
      shippingExtraFees: this.shippingExtraFees, // 지역별 추가 배송비
      shippingDiscountAmount: this.shippingDiscountAmount, // 배송비 할인금액
      shippingCouponDiscountAmount: this.shippingCouponDiscountAmount, // 배송비 쿠폰 할인금액
      shippingMileageDiscountAmount: this.shippingMileageDiscountAmount, // 배송비 적립금 사용
      mileageExpected: {
        confirmMileage: 0,
        reviewMileage: 0,
        extraMileage: 0,
      },
    }
  }

  isShippingFeePaid = (shipping?: IModelShipping): boolean => {
    return !!shipping && this.shippingFeePaidShippingIdsSet.has(shipping.id)
  }

  getCartShippingGroups = (): CartShippingGroupType[] => {
    const cartShippingGroups: CartShippingGroupType[] = []
    this.shippings?.forEach((shipping) => {
      const { id: shippingId, shippingOrderItems, shippingPreset } = shipping
      const { shippingFee, freeShippingBaseAmount, seller, isPackage } = shippingPreset
      const { id: sellerId, shopName } = seller
      const cartProducts: CartProductType[] = []
      shippingOrderItems.forEach((orderItem) => {
        const cartProductOption = CartProductOptionTypeUtil.createCartProductOption(orderItem as IModelOrderItem)
        let cartProduct = CartProductTypeUtil.getCartProduct(
          cartProducts,
          cartProductOption.productId,
          cartProductOption.productPromotionType
        )
        if (!cartProduct) {
          cartProduct = CartProductTypeUtil.createCartProduct(orderItem)
          cartProducts.push(cartProduct)
        }
        cartProduct.cartProductOptions.push(cartProductOption)
      })
      cartShippingGroups.push({
        shippingId,
        seller: {
          id: sellerId,
          shopName,
          shippingFee,
          freeShippingBaseAmount,
          isPackage,
        },
        cartProducts,
      })
    })
    return cartShippingGroups
  }

  _getProductOptionsByCondition = (
    condition: (orderItem: IModelOrderItemDefault) => boolean
  ): IModelProductOptionDefault[] => {
    const productOptions: IModelProductOptionDefault[] = []
    this.shippings.forEach((shipping) => {
      shipping.shippingOrderItems.forEach((orderItem) => {
        if (condition(orderItem)) {
          productOptions.push(orderItem.productOption)
        }
      })
    })
    return productOptions
  }

  getProductOptionsSoldOut = () => {
    return this._getProductOptionsByCondition((orderItem: IModelOrderItemDefault): boolean => {
      return orderItem.productOption.stockQuantity === 0
    })
  }

  getProductOptionsDisabled = () => {
    return this._getProductOptionsByCondition((orderItem: IModelOrderItemDefault): boolean => {
      const product = new ModelProduct(orderItem.productOption.product as IModelProduct)
      const productOption = orderItem.productOption
      return product.isDisabledByAdmin() || productOption.isDelete || !productOption.isUse
    })
  }

  getProductOptions100Deal = () => {
    return this._getProductOptionsByCondition((orderItem: IModelOrderItemDefault): boolean => {
      return orderItem.productPromotion?.productPromotionType === DealTypeEnum.Deal100
    })
  }

  getProductOptionsPromotionApplied = () => {
    return this._getProductOptionsByCondition((orderItem: IModelOrderItemDefault): boolean => {
      return !!orderItem.productPromotion?.id
    })
  }

  // eslint-disable-next-line class-methods-use-this
  _isSatisfiedMinOrderAmount = (orderItem: IModelOrderItemDefault, productPromotionCustomerAmount: number): boolean => {
    if (!orderItem.productPromotion?.id || orderItem.productPromotion?.minOrderAmount === 0) {
      return true
    }
    return (
      orderItem.productPromotion.minOrderAmount <=
      productPromotionCustomerAmount - orderItem.productPromotionCustomerAmount
    )
  }

  // eslint-disable-next-line class-methods-use-this
  _isSatisfiedMaxOrderCount = (orderItem: IModelOrderItemDefault, currentPromotionOrderItemCount: number): boolean => {
    if (!orderItem.productPromotion?.id || !orderItem.productPromotion?.maxOrderCount) {
      return true
    }
    const canOrderCount = orderItem.productPromotion.maxOrderCount - orderItem.productPromotion.boughtCount
    return currentPromotionOrderItemCount <= canOrderCount
  }

  validateOrder = (type: 'buy-now' | 'cart'): ValidateOrderResultType => {
    let deal100OrderCount = 0

    for (const shipping of this.shippings) {
      let currentPromotionOrderItemCount = 0
      let promotionId = ''
      for (const orderItem of shipping.shippingOrderItems) {
        const product = new ModelProduct(orderItem.productOption.product as IModelProduct)
        const productOption = orderItem.productOption

        const isSoldOut = product.isRealSoldOut || product.isSoldOut || productOption.stockQuantity === 0

        if (promotionId === '') {
          promotionId = orderItem.productPromotion._id
          currentPromotionOrderItemCount += orderItem.quantity
        } else if (promotionId === orderItem.productPromotion._id) {
          currentPromotionOrderItemCount += orderItem.quantity
        } else {
          promotionId = orderItem.productPromotion._id
          currentPromotionOrderItemCount = orderItem.quantity
        }

        // 품절 상품일 경우
        if (isSoldOut) {
          return type === 'cart'
            ? {
                isValid: false,
                doRefreshCartProductOption: true,
                renderExcludeOrderModal: true,
                errorMessage: '품절된 상품이 포함되어 있습니다.\n해당 상품을 제외하고 결제하시겠습니까?',
                errorSubMessage: `ㆍ${orderItem.productName}-${orderItem.productOption.name}`,
              }
            : {
                isValid: false,
                doRefreshCartProductOption: true,
                doRestockAlarm: true,
                errorMessage: '일시 품절 상품이 있으므로 구매가 불가능합니다.',
                errorSubMessage: `ㆍ${orderItem.productName}-${orderItem.productOption.name}`,
              }
        }

        // 구매 불가 일 경우
        if (product.isDisabledByAdmin() || productOption.isDelete || !productOption.isUse) {
          return type === 'cart'
            ? {
                isValid: false,
                renderExcludeOrderModal: true,
                errorMessage: '구매불가 상품이 포함되어 있습니다.\n해당 상품을 제외하고 결제하시겠습니까?',
                errorSubMessage: `ㆍ${orderItem.productName}-${orderItem.productOption.name}`,
              }
            : {
                isValid: false,
                doRefreshCartProductOption: true,
                errorMessage: '구매 불가 상품이 있으므로 구매가 불가능합니다.',
                errorSubMessage: `ㆍ${orderItem.productName}-${orderItem.productOption.name}`,
              }
        }

        // 100원딜 이벤트 상품일 경우
        if (orderItem.productPromotion?.productPromotionType === DealTypeEnum.Deal100) {
          deal100OrderCount += orderItem.quantity
        }
        if (deal100OrderCount > 0) {
          if (!this.user.canBuyDeal100) {
            return type === 'cart'
              ? {
                  isValid: false,
                  errorMessage: '웰컴딜 상품은 첫 구매 시에만 구매 가능합니다',
                  errorSubMessage: this.get100DealProductNames(),
                }
              : {
                  isValid: false,
                  errorMessage: '웰컴딜 상품은 첫 구매 시에만 구매 가능합니다',
                  errorSubMessage: this.get100DealProductNames(),
                  renderNoticeModal: true,
                }
          }
          if (deal100OrderCount > 0 && type === 'buy-now') {
            return {
              isValid: false,
              errorMessage: `웰컴딜 상품 외 ${numberWithCommas(
                orderItem.productPromotion.minOrderAmount
              )}원 이상 주문 시 구매가 가능합니다`,
              errorSubMessage: this.get100DealProductNames(),
              renderPutInCartModal: true,
            }
          }
          if (deal100OrderCount > 1 && type === 'cart') {
            return {
              isValid: false,
              errorMessage: `웰컴딜 상품은\n1개만 구매 가능합니다.`,
              errorSubMessage: this.get100DealProductNames(),
            }
          }
        }

        // 최소 주문 금액이 있을 경우
        if (!this._isSatisfiedMinOrderAmount(orderItem, this.productPromotionCustomerAmount)) {
          return type === 'cart'
            ? {
                isValid: false,
                errorMessage: `이벤트 상품 외\n${tenThousandCurrencyText(
                  orderItem.productPromotion?.minOrderAmount
                )} 이상 주문 시 구매 가능합니다.`,
              }
            : {
                isValid: false,
                errorMessage: `이벤트 상품 외\n${tenThousandCurrencyText(
                  orderItem.productPromotion?.minOrderAmount
                )} 이상 주문 시 구매 가능합니다.\n장바구니에 담으시겠습니까?`,
                renderPutInCartModal: true,
              }
        }
        // 최대 구매 수량이 제한되어 있을 경우
        if (!this._isSatisfiedMaxOrderCount(orderItem, currentPromotionOrderItemCount)) {
          const canOrderCount = orderItem.productPromotion.maxOrderCount - orderItem.productPromotion.boughtCount
          return type === 'cart'
            ? {
                isValid: false,
                errorMessage:
                  canOrderCount > 0
                    ? `최대 구매 가능 수량은 ${canOrderCount}개 구매 가능합니다.`
                    : '이벤트 상품의 최대 구매 가능 수량을 초과하였습니다.',
                errorSubMessage: `ㆍ${orderItem.productName}-${orderItem.productOption.name}`,
              }
            : {
                isValid: false,
                errorMessage:
                  canOrderCount > 0
                    ? `최대 구매 가능 수량은 ${canOrderCount}개 구매 가능합니다.`
                    : '이벤트 상품의 최대 구매 가능 수량을 초과하였습니다.',
                errorSubMessage: `ㆍ${orderItem.productName}-${orderItem.productOption.name}`,
                renderNoticeModal: true,
              }
        }
      }
    }
    return {
      isValid: true,
    }
  }
}
