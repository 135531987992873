import { gql } from '@apollo/client'
import DEFAULT_FRAGMENTS from '@/containers/gqls/base/defaultFragments'

const PRODUCT_MUTATION_GQLS = {
  DELETE_CART_ITEMS: gql`
    mutation deleteCartItems($input: DeleteCartItemsInput!) {
      deleteCartItems(input: $input) {
        count
      }
    }
  `,
  CREATE_CART_ITEMS: gql`
    mutation createCartItems($input: CreateCartItemsInput!) {
      createCartItems(input: $input) {
        cartItems {
          id
          quantity
          productOption {
            id
            product {
              productPromotion {
                productPromotionType
              }
            }
          }
        }
      }
    }
  `,
  UPDATE_CART_ITEM: gql`
    mutation updateCartItem($id: ID!, $input: UpdateCartItemInput!) {
      updateCartItem(id: $id, input: $input) {
        cartItem {
          id
        }
      }
    }
  `,
  ASK_PRODUCT_QUESTION: gql`
    mutation askProductQuestion($input: AskProductQuestionInput!) {
      askProductQuestion(input: $input) {
        productQna {
          ...productQna
        }
      }
    }
    ${DEFAULT_FRAGMENTS.productQna}
  `,
  TOGGLE_PRODUCT_LIKED: gql`
    mutation toggleProductLiked($id: ID!, $input: ProductLikedPromotionInput) {
      toggleProductLiked(id: $id, input: $input) {
        product {
          id
          isLiked
          likedCount
          productPromotion {
            id
            productPromotionType
          }
        }
      }
    }
  `,
  TOGGLE_RESTOCK_PRODUCT_OPTION: gql`
    mutation toggleRestockProductOption($input: ToggleRestockProductOptionInput!) {
      toggleRestockProductOption(input: $input) {
        restockProductOptionUser {
          id
          requestedAt
          informedAt
          productOption {
            id
            name
            productId
          }
        }
      }
    }
  `,
}

export default PRODUCT_MUTATION_GQLS
