export enum OrderStatusTypeEnum {
  BeforePayment = 'BEFORE_PAYMENT',
  UnconfirmedPayment = 'UNCONFIRMED_PAYMENT',
  NewOrder = 'NEW_ORDER',
  ReadyForShipping = 'READY_FOR_SHIPPING',
  Shipping = 'SHIPPING',
  Shipped = 'SHIPPED',
  CancelRequested = 'CANCEL_REQUESTED',
  Canceled = 'CANCELED',
  Confirmed = 'CONFIRMED',
  ReturnRequested = 'RETURN_REQUESTED',
  ReturnUnderReturn = 'RETURN_UNDER_RETURN',
  ReturnReturned = 'RETURN_RETURNED',
  ReturnConfirmed = 'RETURN_CONFIRMED',
  ExchangeRequested = 'EXCHANGE_REQUESTED',
  ExchangeUnderReturn = 'EXCHANGE_UNDER_RETURN',
  ExchangeConfirmed = 'EXCHANGE_CONFIRMED',
}

export const cancelStatuses = [OrderStatusTypeEnum.Canceled, OrderStatusTypeEnum.CancelRequested]
export const exchangeStatuses = [
  OrderStatusTypeEnum.ExchangeUnderReturn,
  OrderStatusTypeEnum.ExchangeConfirmed,
  OrderStatusTypeEnum.ExchangeRequested,
]
export const returnStatuses = [
  OrderStatusTypeEnum.ReturnConfirmed,
  OrderStatusTypeEnum.ReturnRequested,
  OrderStatusTypeEnum.ReturnUnderReturn,
  OrderStatusTypeEnum.ReturnReturned,
]

export const finishStatuses = [
  OrderStatusTypeEnum.ReturnReturned,
  OrderStatusTypeEnum.Canceled,
  OrderStatusTypeEnum.ReturnConfirmed,
]
