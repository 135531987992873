import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { Typo, TypoVariant } from '@/components/common/typography'
import { GlobalColorEnum } from '@/constants/global-color.enum'
import { Z_INDEXES } from '@/constants/legacy/constLayout'
import { ToastConst } from '@/constants/toast.const'
import { useCustomRouter } from '@/containers/hooks'
import { doInitGlobalToastContent } from '@/stores/reduxUI'
import { RootState } from '@/stores/store'
import { ToastVariantKey, ToastVariantMargin } from '@/types/toast.type'

interface ToastAnimationTimeType {
  TIME_OUT: number
  FADE_IN_DELAY: number
  FADE_IN_DURATION: number
  FADE_OUT_DELAY: number
  FADE_OUT_DURATION: number
}

const GlobalToast: React.FC = () => {
  const dispatch = useDispatch()
  const globalToastContents = useSelector((state: RootState) => state.reduxUIReducers.globalToastContents)
  const { pathname } = useCustomRouter()
  const [toastRect, setToastRect] = useState<DOMRect | undefined>(undefined)

  const [toast] = globalToastContents
  const toastAnimationTime = {
    TIME_OUT: 3,
    FADE_IN_DELAY: 0.1,
    FADE_IN_DURATION: 0.2,
    FADE_OUT_DELAY: 3,
    FADE_OUT_DURATION: 0.3,
  }

  const getMainRect = () => {
    if (typeof document !== 'undefined') {
      return document.querySelector('#main')?.getBoundingClientRect()
    }
  }
  const clear = () => {
    dispatch(doInitGlobalToastContent([]))
  }

  useEffect(() => {
    setToastRect(getMainRect())
  }, [globalToastContents.length])

  // clear container
  useEffect(() => {
    const timeout = setTimeout(() => {
      clear()
    }, toastAnimationTime.TIME_OUT * ToastConst.ToastAnimationTimeout)
    return () => clearTimeout(timeout)
  }, [globalToastContents.length])

  useEffect(() => {
    clear()
  }, [pathname])

  return (
    <StyledToastContainer left={toastRect?.left} variant={toast?.variant}>
      <StyledToastInner>
        {globalToastContents.map((toast, index) => {
          if (index > 0 && toast.content === globalToastContents[index - 1].content) {
            return (
              <StyledOverlayedContent
                key={String(toast.content)}
                toastAnimationTime={toastAnimationTime}
                onClick={() => toast.onClick?.()}
              >
                <StyledText>
                  <Typo variant={TypoVariant.Body1Regular} color={GlobalColorEnum.GrayWhite}>
                    {toast.content}
                  </Typo>
                </StyledText>
              </StyledOverlayedContent>
            )
          }
          return (
            <StyledContent
              key={String(toast.content)}
              toastAnimationTime={toastAnimationTime}
              onClick={() => toast.onClick?.()}
            >
              <StyledText>
                <Typo variant={TypoVariant.Body1Regular} color={GlobalColorEnum.GrayWhite}>
                  {toast.content}
                </Typo>
              </StyledText>
            </StyledContent>
          )
        })}
      </StyledToastInner>
    </StyledToastContainer>
  )
}

export default GlobalToast

const StyledToastContainer = styled.div<{
  left?: number
  variant?: ToastVariantKey
}>`
  position: fixed;
  bottom: calc(
    env(safe-area-inset-bottom) +
      ${({ variant }) =>
        variant === ToastConst.ToastVariant.withCTA ? ToastVariantMargin.withCTA : ToastVariantMargin.default}rem
  );
  left: ${({ left }) => left}px;
  margin: 0 16px;
  width: calc(100% - 32px);
  max-width: calc(576px - 32px);
  z-index: ${Z_INDEXES.TOAST};
`

const StyledContent = styled.div<{ toastAnimationTime: ToastAnimationTimeType }>`
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;

  margin-top: 8px;
  min-height: 46px;
  border-radius: 8px;

  background-color: ${(props) => props.theme.color.gray900};
  color: ${(props) => props.theme.color.grayWhite};
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;

  @keyframes fadeIn {
    100% {
      opacity: 0.9;
    }
  }

  @keyframes fadeOut {
    100% {
      opacity: 0;
      visibility: hidden;
    }
  }

  @keyframes hide {
    100% {
      position: absolute;
      bottom: -2000px;
    }
  }
  animation: ${({
    toastAnimationTime,
  }) => `${toastAnimationTime?.FADE_IN_DURATION}s cubic-bezier(0.65, 0, 0.35, 1) ${toastAnimationTime?.FADE_IN_DELAY}s
      forwards fadeIn,
    ${toastAnimationTime?.FADE_OUT_DURATION}s cubic-bezier(0.65, 0, 0.35, 1) ${toastAnimationTime?.FADE_OUT_DELAY}s
      forwards fadeOut,
    0s step-end ${toastAnimationTime?.TIME_OUT}s forwards hide`};
`

const StyledOverlayedContent = styled(StyledContent)`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
`

const StyledText = styled.div`
  white-space: pre-line;
  padding: 1.2rem 1.6rem;
`
const StyledToastInner = styled.div`
  position: relative;
  width: 100%;
`
