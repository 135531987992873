import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FitpetMallEventEnum } from '@/constants/fitpet-mall-event.enum'
import ROUTES from '@/constants/legacy/constRoutes'
import { LocationEnum } from '@/constants/location.enum'
import { QueryStringKeyEnum } from '@/constants/query-string-key.enum'
import { SearchLocationEnum } from '@/constants/search-location.enum'
import { useTracker } from '@/containers/contexts/EventTrackingProvider'
import { useCustomRouter } from '@/containers/hooks'
import { useSearchedKeywordsWithBrandLazyQuery } from '@/graphql/generated/hooks'
import { AdPetType, BrandType } from '@/graphql/generated/schemas'
import { doSetIsShowSearchedKeywords } from '@/stores/reduxUI'
import { RootState } from '@/stores/store'

type SearchedBrandsType = {
  id: BrandType['id']
  name: BrandType['name']
  listImage: BrandType['listImage']
}

type UseSearchedKeywordsReturnType = {
  loading: boolean
  searchedKeywords: Array<string>
  searchedBrands: Array<SearchedBrandsType>
  isShowSearchedKeywords: boolean
  setShowSearchedKeywords: (flag: boolean) => void
  onClickKeyword: (searchedKeyword: string, index: number, brandId?: string) => void
}

const useSearchedKeywords = (searchText: string = '', searchPetType?: AdPetType): UseSearchedKeywordsReturnType => {
  const dispatch = useDispatch()
  const { triggerCustomEvent } = useTracker()
  const [searchedKeywords, setSearchedKeywords] = useState<Array<string>>([])
  const [searchedBrands, setSearchedBrands] = useState<Array<SearchedBrandsType>>([])
  const { push, replace, pathname, query } = useCustomRouter()
  const isShowSearchedKeywords = useSelector((state: RootState) => state.reduxUIReducers.isShowSearchedKeywords)
  const { q } = query

  // 자동완성 키워드 노출 분기 설정
  const setShowSearchedKeywords = (flag: boolean) => {
    dispatch(doSetIsShowSearchedKeywords(flag))
  }

  useEffect(() => {
    // 검색 결과 페이지 접근 시 자동완성 키워드 리스트 초기화
    if (pathname === ROUTES.SEARCH_RESULT) {
      setSearchedKeywords([])
    }
  }, [])

  useEffect(() => {
    if (searchText === q) return
    setShowSearchedKeywords(searchedKeywords.length > 0 || searchedBrands.length > 0)
  }, [searchedKeywords, searchedBrands])

  useEffect(() => {
    searchedKeywordsQuery({
      variables: {
        keyword: searchText,
        petType: searchPetType as AdPetType,
      },
    })
  }, [searchText, searchPetType])

  const [searchedKeywordsQuery, { loading }] = useSearchedKeywordsWithBrandLazyQuery({
    fetchPolicy: 'cache-and-network',

    onCompleted(data) {
      if (!data) {
        return
      }

      setSearchedKeywords(data.searchedKeywords || [])
      setSearchedBrands(data.searchedBrands as SearchedBrandsType[])
    },
  })

  const onClickKeyword = (searchedKeyword: string, index: number, brandId?: string) => {
    if (brandId) {
      // 브랜드 자동완성 클릭 시 브랜드 페이지로 이동
      push({
        pathname: ROUTES.BRAND_DETAIL,
        query: {
          brandId: brandId,
          location: LocationEnum.Search,
        },
      })
    } else {
      // 자동완성 키워드 클릭 시 검색 결과로 replace 처리
      replace({
        pathname: ROUTES.SEARCH_RESULT,
        query: {
          [QueryStringKeyEnum.SearchQuery]: searchedKeyword,
          [QueryStringKeyEnum.SearchPetType]: searchPetType,
          [QueryStringKeyEnum.SearchLocation]: SearchLocationEnum.Auto,
        },
      })
    }
    triggerCustomEvent(FitpetMallEventEnum.ClickSearchAutoKeyword, {
      searchKeyword: searchText,
      autoCompleteKeyword: searchedKeyword,
      listIndex: index,
      petTypeSearch: searchPetType,
      isBrand: !!brandId,
    })
    setShowSearchedKeywords(false)
  }

  return {
    loading,
    searchedKeywords,
    searchedBrands,
    isShowSearchedKeywords,
    setShowSearchedKeywords,
    onClickKeyword,
  }
}

export default useSearchedKeywords
