// if another service is added, add it here

export class Logger {
  private name: string

  constructor(name: string) {
    this.name = name
  }

  // eslint-disable-next-line class-methods-use-this
  info = (...args: any) => {
    if (process.env.FITPETMALL_ENV !== 'production') {
      // eslint-disable-next-line no-console
      // TODO: open when need to see tracking log
    }
  }
}
