import { FC } from 'react'
import { TextLegacy } from '@/components/common'
import { AccountLastLoginInfo } from '@/components/domains/etc/index'
import { UserInfoType } from '@/graphql/generated/schemas'

type FoundIDInformationProps = {
  userInfo: UserInfoType
}

const FoundIDInformation: FC<FoundIDInformationProps> = ({ userInfo }) => {
  return (
    <>
      <TextLegacy size={{ xs: 14, md: 16 }} weight="bold">
        가입된 계정
      </TextLegacy>
      <AccountLastLoginInfo userInfo={userInfo} />
    </>
  )
}

export default FoundIDInformation
