export enum PageSizeEnum {
  PageSize1 = 1,
  PageSize2 = 2,
  PageSize4 = 4,
  PageSize5 = 5,
  PageSize6 = 6,
  PageSize8 = 8,
  PageSize10 = 10,
  PageSize16 = 16,
  PageSize20 = 20,
  PageSize24 = 24,
  PageSize30 = 30,
  PageSize50 = 50,
}
