import { FC, useRef } from 'react'
import ScrollContainer from 'react-indiana-drag-scroll'
import styled from 'styled-components'
import HomeNavigationBarList from '@/components/domains/layouts/header/HomeNavigationBarList'
import { GlobalColorEnum } from '@/constants/global-color.enum'

const HomeNavigationBar: FC = () => {
  const listRef = useRef<HTMLElement | null | undefined>()

  return (
    <StyledContainer>
      <ScrollContainer
        className="scroll-container"
        innerRef={(ref) => {
          listRef.current = ref
        }}
      >
        <HomeNavigationBarList listRef={listRef} />
      </ScrollContainer>
    </StyledContainer>
  )
}

export default HomeNavigationBar

const StyledContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  max-width: 576px;
  border-bottom: 1.5px solid ${GlobalColorEnum.Gray70};
  background-color: ${(props) => props.theme.color.grayWhite};
  overflow: hidden;
`
