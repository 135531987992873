import { match, P } from 'ts-pattern'
import {
  ProductDetailResponseDto,
  ProductScheme,
  ProductsListItemResponseDto,
  PromotionTypeEnum,
  SupplyTypeEnum,
} from '@/apis/rest/generated/types'
import { CategoryTypeConnection, StatusValue } from '@/graphql/generated/schemas'
import { ProductTypeDefinedByFrontend } from '@/types/product-type-defined-by.frontend'
import { Base64ConverterUtil } from '@/utils/base64-converter.util'
import { getImageUrl } from '@/utils/utilURL'

export interface GraphQLProductType {
  id: string
  name: string
  productNumber: string
  mainImage: {
    thumbnail: string
  }
  brand: {
    id: string
    name: string
  }
  categories?: CategoryTypeConnection
  bestReview: string
  reviewCount: number
  price: number
  customerPrice: number
  discountRate: number
  isRealSoldOut: boolean
  pricePerSku: number
  maxProductOptionSkuCount: number
  productPromotion: {
    id: string
    productPromotionType: string
  }
  finalDiscountRate: number
  productPromotionCustomerPrice: number
  averageReviewScore: number
  boughtCount: number
  status: {
    value: StatusValue
  }
  supplyType: string
  purchasePrice: number
  isLiked: boolean
  sticker?: string
}

// 타입 가드 함수
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isProductScheme = (
  product: ProductScheme | GraphQLProductType | ProductDetailResponseDto
): product is ProductScheme => {
  return (
    typeof product?.id === 'number' && (product as ProductDetailResponseDto).hasUnreceivedProductCoupon === undefined
  )
}

const isGraphQLProductType = (
  product: ProductScheme | GraphQLProductType | ProductDetailResponseDto
): product is GraphQLProductType => {
  return typeof product?.id === 'string'
}

const isProductDetailResponseDto = (
  product: ProductScheme | GraphQLProductType | ProductDetailResponseDto
): product is ProductDetailResponseDto => {
  return (product as ProductDetailResponseDto)?.hasUnreceivedProductCoupon !== undefined
}

// GraphQL API 에서 받아온 상품 데이터를 ProductCardData 로 변환
interface ConvertGraphQLDataToCard {
  id: string
  name: string
  productNumber: string
  thumbnail: string
  reviewCount: number
  price: number
  customerPrice: number
  discountRate: number
  isRealSoldOut: boolean
  pricePerSku: number
  maxProductOptionSkuCount: number
  productPromotion: {
    id: string
    productPromotionType: string
  }
  finalDiscountRate: number
  productPromotionCustomerPrice: number
  averageReviewScore: number
  boughtCount: number
  status: {
    value: StatusValue
  }
  supplyType: string
}

const convertGraphQLDataToCard = (data: GraphQLProductType): ConvertGraphQLDataToCard => {
  return {
    id: data?.id ?? '',
    name: data?.name,
    productNumber: data?.productNumber?.toString(),
    thumbnail: data?.mainImage?.thumbnail,
    reviewCount: data?.reviewCount,
    price: data?.price,
    customerPrice: data?.customerPrice,
    discountRate: data?.discountRate,
    isRealSoldOut: data?.isRealSoldOut,
    pricePerSku: data?.pricePerSku,
    maxProductOptionSkuCount: data?.maxProductOptionSkuCount,
    productPromotion: {
      id: data?.productPromotion?.id,
      productPromotionType: data?.productPromotion?.productPromotionType,
    },
    finalDiscountRate: data?.finalDiscountRate,
    productPromotionCustomerPrice: data?.productPromotionCustomerPrice,
    averageReviewScore: data?.averageReviewScore,
    boughtCount: data?.boughtCount,
    status: data?.status,
    supplyType: data?.supplyType,
  }
}

// REST API 에서 받아온 상품 데이터를 ProductCardData 로 변환
// status 가 없음

interface ConvertProductSchemeToCardDataType {
  id: string
  name: string
  productNumber: string
  thumbnail?: string
  reviewCount: number
  price: number
  customerPrice: number
  discountRate?: number
  isRealSoldOut: boolean
  pricePerSku: number
  maxProductOptionSkuCount: number
  productPromotion: {
    id: string
    productPromotionType?: string
  }
  finalDiscountRate?: number
  productPromotionCustomerPrice: number
  averageReviewScore: number
  supplyType: string
  boughtCount?: number
  status?: {
    value: StatusValue
  }
}

const convertProductSchemeToCardData = (data: ProductScheme): ConvertProductSchemeToCardDataType => {
  return {
    id: data.id ? Base64ConverterUtil.convertNumberIdToBase64ByKey({ key: 'ProductType', id: data.id }) : '',
    name: data.name,
    productNumber: data.productNumber.toString(),
    thumbnail: data.mainImage?.path,
    reviewCount: data.review.count,
    price: data.price,
    customerPrice: data.defaultOption.salePrice,
    discountRate: data.discountRate,
    isRealSoldOut: data.isRealSoldOut,
    pricePerSku: data.pricePerSku,
    maxProductOptionSkuCount: data.maxOptionSkuCount,
    productPromotion: {
      id: data.promotion?.id
        ? Base64ConverterUtil.convertNumberIdToBase64ByKey({ key: 'ProductPromotionType', id: data.promotion.id })
        : '',
      productPromotionType: data.promotion?.type,
    },
    finalDiscountRate: data.discountRate,
    productPromotionCustomerPrice: data.salePrice,
    averageReviewScore: data.review.score,
    supplyType: data.supplyType,
  }
}

export type ConvertProductType = ConvertProductSchemeToCardDataType | ConvertGraphQLDataToCard

export type ReturnConvertProductSchemeType = ReturnType<typeof convertProductSchemeToCardData>

// 메인 함수
export const convertToProductCard = (
  data: ProductScheme | GraphQLProductType | ProductDetailResponseDto
): ConvertProductType => {
  if (isProductScheme(data)) {
    return convertProductSchemeToCardData(data)
  }
  return convertGraphQLDataToCard(data)
}

const processProductData = (data: ProductsListItemResponseDto): ProductTypeDefinedByFrontend => {
  const thumbnail = getImageUrl(data.mainImage?.path) || ''
  const sticker = getImageUrl(data.sticker)
  const animatedMainImageUrl = getImageUrl(data.animatedMainImage?.path)
  const isShowBundledMessage = !!data.maxOptionSkuCount && data.maxOptionSkuCount !== 1 && data.pricePerSku > 0
  const hasApplicableProductCoupon = !!data?.hasApplicableProductCoupon
  const hasDiscount = !!data.discountRate && data.discountRate > 0
  return {
    id: data.id,
    promotion: data.promotion,
    // webp 이미지가 있으면 webp 이미지를 사용하고 없으면 일반 이미지 사용
    thumbnail: animatedMainImageUrl || thumbnail,
    name: data.name,
    discountRate: data.discountRate || 0,
    price: data.price,
    salePrice: data.salePrice,
    reviewCount: data.review.count,
    reviewScore: data.review.score,
    maxOptionSkuCount: data.maxOptionSkuCount,
    pricePerSku: data.pricePerSku,
    isShowBundledMessage,
    // 쿠폰 적용 가능한 상품인지 확인, 나중에 백엔드 데이터에 맞게 변경
    hasApplicableProductCoupon,
    // 할인율이 0일때 안보여주기 위한 데이터
    // 할인율이 0이면 할인정보를 보여주지 않음(discountRate, price 가 안보여짐)
    hasDiscount,
    isLiked: data.isLiked,
    isRealSoldOut: data.isRealSoldOut,
    isNotAvailable: !!data.isSuspend,
    discountPrice: data.salePrice,
    supplyType: data.supplyType,
    thumbnailData: {
      // GraphQL, REST API 둘다 호환되게 하기 위해 추가 - 이벤트 트래커용
      price: data.price,
      discountRate: Number(data.discountRate),
      discountPrice: data.salePrice,
      reviewCount: data.review.count,
      reviewScore: data.review.score,
    },
    brand: {
      id: data.brand.id,
      name: data.brand.name,
    },
    categories: data.categories.map((category) => ({
      id: category.id,
      name: category.name,
    })),
    hasAnimatedMainImage: !!animatedMainImageUrl,
    expectedMileage: data.expectedMileage,
    salesQuantity: data.salesQuantity,
    sticker,
  }
}

const processProductDataByGraphQL = (data: GraphQLProductType): ProductTypeDefinedByFrontend => {
  const thumbnail = getImageUrl(data.mainImage?.thumbnail) || ''
  const sticker = getImageUrl(data.sticker)
  const isShowBundledMessage =
    !!data.maxProductOptionSkuCount && data.maxProductOptionSkuCount !== 1 && data.pricePerSku > 0
  const hasApplicableProductCoupon = false
  const hasDiscount = !!data.discountRate && data.discountRate > 0

  return {
    id: Base64ConverterUtil.convertBase64ToNumberId(data?.id),
    promotion: data.productPromotion && {
      id: Base64ConverterUtil.convertBase64ToNumberId(data.productPromotion?.id),
      type: data.productPromotion?.productPromotionType as PromotionTypeEnum,
    },
    thumbnail,
    name: data.name,
    discountRate: data?.finalDiscountRate || data.discountRate,
    price: data.price,
    salePrice: data?.productPromotion ? data?.productPromotionCustomerPrice : data.customerPrice,
    reviewCount: data.reviewCount,
    reviewScore: data.averageReviewScore,
    maxOptionSkuCount: data.maxProductOptionSkuCount,
    pricePerSku: data.pricePerSku,
    isShowBundledMessage,
    hasApplicableProductCoupon,
    // 할인율이 0일때 안보여주기 위한 데이터
    // 할인율이 0이면 할인정보를 보여주지 않음(discountRate, price 가 안보여짐)
    hasDiscount,
    isLiked: data.isLiked,
    isRealSoldOut: data.isRealSoldOut,
    isNotAvailable: data?.status?.value && data.status.value !== StatusValue.Approved,
    discountPrice: data.customerPrice,
    supplyType: data.supplyType as SupplyTypeEnum,
    thumbnailData: {
      // GraphQL, REST API 둘다 호환되게 하기 위해 추가 - 이벤트 트래커용
      price: data.price,
      discountRate: Number(data.discountRate),
      discountPrice: data.customerPrice,
      reviewCount: data.reviewCount,
      reviewScore: data.averageReviewScore,
    },
    brand: {
      id: Base64ConverterUtil.convertBase64ToNumberId(data?.brand?.id),
      name: data?.brand?.name,
    },
    categories: data?.categories?.edges?.map((category) => ({
      id: Base64ConverterUtil.convertBase64ToNumberId(category.node?.id || ''),
      name: category.node?.name || '',
    })),
    sticker,
  }
}

const processProductDataByPDP = (data: ProductDetailResponseDto): ProductTypeDefinedByFrontend => {
  const thumbnail = getImageUrl(data.mainImage?.path) || ''
  const sticker = getImageUrl(data.sticker)
  const isShowBundledMessage = !!data.maxOptionSkuCount && data.maxOptionSkuCount !== 1 && data.pricePerSku > 0
  const hasApplicableProductCoupon = !!data.hasApplicableProductCoupon
  const hasDiscount = !!data.discountRate && data.discountRate > 0
  return {
    id: data.id,
    promotion: data.promotion,
    thumbnail,
    name: data.name,
    discountRate: data.discountRate || 0,
    price: data.price,
    salePrice: data.salePrice,
    reviewCount: data.review.count,
    reviewScore: data.review.score,
    maxOptionSkuCount: data.maxOptionSkuCount,
    pricePerSku: data.pricePerSku,
    isShowBundledMessage,
    // 쿠폰 적용 가능한 상품인지 확인, 나중에 백엔드 데이터에 맞게 변경
    hasApplicableProductCoupon,
    // 할인율이 0일때 안보여주기 위한 데이터
    // 할인율이 0이면 할인정보를 보여주지 않음(discountRate, price 가 안보여짐)
    hasDiscount,
    salePriceAfterCoupon: data.salePriceAfterCoupon,
    isLiked: data.isLiked,
    isRealSoldOut: data.isRealSoldOut,
    isNotAvailable: false,
    discountPrice: data.salePrice,
    supplyType: data.supplyType,
    thumbnailData: {
      // GraphQL, REST API 둘다 호환되게 하기 위해 추가 - 이벤트 트래커용
      price: data.price,
      discountRate: Number(data.discountRate),
      discountPrice: data.salePrice,
      reviewCount: data.review.count,
      reviewScore: data.review.score,
    },
    brand: {
      id: data.brand.id,
      name: data.brand.name,
    },
    categories: data.categories.map((category) => ({
      id: category.id,
      name: category.name,
    })),
    sticker,
  }
}

export const transformProductTypeDefinedByFrontend = (
  product: ProductScheme | GraphQLProductType | ProductDetailResponseDto
): ProductTypeDefinedByFrontend => {
  return match(product)
    .with(P.when(isProductScheme), (product) => processProductData(product))
    .with(P.when(isGraphQLProductType), (product) => processProductDataByGraphQL(product))
    .with(P.when(isProductDetailResponseDto), (product) => processProductDataByPDP(product))
    .exhaustive()
}
