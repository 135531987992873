import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { Spinner } from '@/components/common/etc'
import { CartConfirmationContent } from '@/components/domains/cart/cart-confirmation/CartConfirmationContent'
import { CartConfirmationTop } from '@/components/domains/cart/cart-confirmation/CartConfirmationTop'
import { useRecentlyProductList } from '@/components/domains/mypage/favorites/hooks/use-recently-product-list'
import ROUTES from '@/constants/legacy/constRoutes'
import { PageSizeEnum } from '@/constants/page-size.enum'
import { useBottomSheetEventTracker } from '@/containers/event-tracker/bottom-sheet.event.tracker'
import { useCustomRouter } from '@/containers/hooks'
import { useCartBottomSheetRestReduxData } from '@/containers/hooks/cart/useCartBottomSheetRestReduxData'
import useRelatedProductContainerHookV2 from '@/containers/hooks/related-product.containerV2.hook'
import { useFrequentlyBoughtV2 } from '@/containers/hooks/use-frequently-bought-v2'
import { ABTestGroupCase, useAbGroup } from '@/containers/hooks/useAbGroup'
import { useRecommendBundleProductsByGen } from '@/containers/hooks/useRecommendBundleProductsByGen'
import { RootState } from '@/stores/store'
import { Base64ConverterUtil } from '@/utils/base64-converter.util'
import { getLocationByPathname } from '@/utils/getLocationByPathname'

const fallbackImage = `${process.env.HOST}/images/product_placeholder_76.svg`

export const CartConfirmation = () => {
  const { getAbByKey } = useAbGroup()

  const { abCode } = getAbByKey({
    abKey: ABTestGroupCase.BOTTOM_SHEET_RECOMMENDATION_SLOT_IMPROVEMENT_ON_ADD_TO_CART,
  })

  const isNewCartBottomSheet = abCode !== 'A'

  const { reduxProductInCartConfirmationBottomSheet } = useSelector(
    (state: RootState) => state.reduxDataReducers.cartConfirmationBottomSheetData
  )

  const { trackClickAddCartBottomUpCartEvent, trackViewAddCartBottomUpBottomSheetEvent } = useBottomSheetEventTracker()

  const { push, pathname } = useCustomRouter()
  const { resetCartBottomSheetReduxData } = useCartBottomSheetRestReduxData()
  const encodedProductId = Base64ConverterUtil.convertNumberIdToBase64ByKey({
    key: 'ProductType',
    id: reduxProductInCartConfirmationBottomSheet?.id,
  })

  /**
   * 자주 산 상품
   */
  const { data: frequentlyBoughtProducts, isLoading: isFrequentlyBoughtProductsLoading } = useFrequentlyBoughtV2({
    page: PageSizeEnum.PageSize1,
    size: PageSizeEnum.PageSize20,
    keySuffix: 'cart',
  })
  /**
   * 이 상품의 연관 상품
   */
  const { loading: isRecommendedProductDetailProductsLoading, recommendedProductDetailProducts } =
    useRelatedProductContainerHookV2(encodedProductId, PageSizeEnum.PageSize20)

  /**
   * 묶음 배송이 가능한 상품
   */
  const { recommendBundledProducts, isRecommendBundleProductsLoading } = useRecommendBundleProductsByGen({
    productId: encodedProductId,
  })

  /**
   * 최근 본 상품
   */
  const { recentlyProducts, isRecentlyProductsLoading } = useRecentlyProductList({
    length: PageSizeEnum.PageSize20,
    currentProductId: encodedProductId,
  })

  const loadDataState = {
    frequentlyBoughtProducts: {
      name: '자주 산 상품',
      isLoading: isFrequentlyBoughtProductsLoading,
      data: frequentlyBoughtProducts,
    },
    recommendedProductDetailProducts: {
      name: '이 상품의 연관 상품',
      isLoading: isRecommendedProductDetailProductsLoading,
      data: recommendedProductDetailProducts,
    },
    recommendBundledProducts: {
      name: '묶음 배송이 가능한 상품',
      isLoading: isRecommendBundleProductsLoading,
      data: recommendBundledProducts,
    },
    recentlyProducts: {
      name: '최근 본 상품',
      isLoading: isRecentlyProductsLoading,
      data: recentlyProducts || [],
    },
  }

  const isLoading = Object.values(loadDataState).some((state) => {
    return state.isLoading
  })
  const trueDataCount = !isLoading
    ? Object.values(loadDataState).reduce((count, item) => {
        return count + (item.data.length > 0 ? 1 : 0)
      }, 0)
    : 0
  const count = !isLoading ? trueDataCount : 0

  const handleClickLink = () => {
    if (reduxProductInCartConfirmationBottomSheet) {
      trackClickAddCartBottomUpCartEvent({
        originProductId: reduxProductInCartConfirmationBottomSheet?.id,
        originProductName: reduxProductInCartConfirmationBottomSheet?.name,
        supplyType: reduxProductInCartConfirmationBottomSheet?.supplyType,
        brandId: reduxProductInCartConfirmationBottomSheet?.brand.id,
        brandName: reduxProductInCartConfirmationBottomSheet?.brand.name,
        location: getLocationByPathname(pathname),
        addLocation: 'bottomsheet',
      })
    }
    resetCartBottomSheetReduxData()
    push({
      pathname: ROUTES.CART,
      query: { tab: 'cart' },
    })
  }

  useEffect(() => {
    if (abCode && reduxProductInCartConfirmationBottomSheet) {
      trackViewAddCartBottomUpBottomSheetEvent({
        originProductId: reduxProductInCartConfirmationBottomSheet?.id,
        originProductName: reduxProductInCartConfirmationBottomSheet?.name,
        supplyType: reduxProductInCartConfirmationBottomSheet?.supplyType,
        brandId: reduxProductInCartConfirmationBottomSheet?.brand.id,
        brandName: reduxProductInCartConfirmationBottomSheet?.brand.name,
        abTestGroup: `addcart_bottomsheet_${abCode.toLowerCase()}`,
      })
    }
  }, [abCode, reduxProductInCartConfirmationBottomSheet])

  return (
    <>
      <StyledCartConfirmationWrapper isNewCartBottomSheet={isNewCartBottomSheet}>
        {isLoading ? (
          <StyledSpinnerContainer>
            <Spinner />
          </StyledSpinnerContainer>
        ) : (
          <>
            <div className="cart-confirmation__top">
              <CartConfirmationTop
                imageUrl={reduxProductInCartConfirmationBottomSheet?.thumbnail || fallbackImage}
                onClickLink={handleClickLink}
              />
            </div>
            <div className="cart-confirmation__content">
              <CartConfirmationContent
                count={count}
                loadDataList={{
                  frequentlyBoughtProducts: loadDataState.frequentlyBoughtProducts.data,
                  recommendedProductDetailProducts: loadDataState.recommendedProductDetailProducts.data,
                  recommendBundledProducts: loadDataState.recommendBundledProducts.data,
                  recentlyProducts: loadDataState.recentlyProducts.data,
                }}
              />
            </div>
          </>
        )}
      </StyledCartConfirmationWrapper>
    </>
  )
}

const StyledCartConfirmationWrapper = styled.div<{ isNewCartBottomSheet: boolean }>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  .cart-confirmation {
    &__top {
      padding: ${({ isNewCartBottomSheet }) => (isNewCartBottomSheet ? '0 1.6rem 0.8rem' : '0 1.6rem 1.2rem')};
      border-bottom: 1px solid
        ${({
          theme: {
            color: { gray70 },
          },
        }) => gray70};
    }
    &__content {
    }
  }
`

const StyledSpinnerContainer = styled.div`
  height: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
`
