import { forwardRef } from 'react'
import styled from 'styled-components'

interface NewRowProps extends React.ComponentPropsWithoutRef<'div'> {
  align?: 'flex-start' | 'flex-end' | 'center' | 'baseline' | 'stretch'
  justify?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around' | 'space-evenly'
  wrap?: boolean
  gap?: string
  className?: string
  onClick?: () => void
  style?: React.CSSProperties
}

export const NewRow = forwardRef<HTMLDivElement, NewRowProps>(({ children, ...props }, ref) => {
  return (
    <StyledNewRow ref={ref} {...props}>
      {children}
    </StyledNewRow>
  )
})

NewRow.displayName = 'NewRow'

const StyledNewRow = styled.div<NewRowProps>`
  display: flex;
  flex-direction: row;
  flex-wrap: ${({ wrap }) => (wrap ? 'wrap' : 'nowrap')};
  justify-content: ${({ justify }) => justify};
  gap: ${({ gap }) => gap};
  align-items: ${({ align }) => align};
`
