import { css } from 'styled-components'

export const removeScrollbar = css`
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`

export const preventDragSelect = css`
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`

export const textEllipsis = (lineClamp: number) => css`
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  -webkit-line-clamp: ${lineClamp};
  -webkit-box-orient: vertical;
`

/**
 * 화면사이즈에 따라 값이 증가해야하는 경우 rem 대신에 사용
 * rem은 450px까지만 증가하도록 되어있음 (styles/globals.css 참고)
 */
export const calculateResponsiveSize = (pixel?: number) => {
  if (!pixel) return 'auto'
  return `calc(${pixel} * min(100vw, 576px) / 375)`
}
