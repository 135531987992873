import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useUserInfo } from '@/containers/hooks'
import { useCartLocal } from '@/containers/hooks/cart/useCartLocal'
import { useCartServer } from '@/containers/hooks/cart/useCartServer'
import {
  CartProductOptionKeyType,
  CartProductOptionType,
  CartProductOptionTypeUtil,
} from '@/containers/types/CartProductOption'
import { doSetCartItemCount, doSetGlobalNoticeModal, doSetGlobalToastContent } from '@/stores/reduxUI'
import { Bridge } from '@/utils/bridge/bridge'
import appBridgeProvider, { isAppNonLoginCart, isAppStorageDisabled } from '@/utils/utilBridge'

type UseCartNewType = {
  cartProductOptionsInCart: CartProductOptionType[]
  isCartLoaded: boolean
  refreshCartProductOptions: () => void
  refreshCartProductOptionsCount: () => void
  addOrUpdateCartProductOptions: (cartProductOptions: CartProductOptionType[]) => void
  removeCartProductOption: (cartProductOptionKey: CartProductOptionKeyType) => void
  removeCartProductOptions: (cartProductOptionKeys: CartProductOptionKeyType[]) => void
  updateCartProductOption: (
    cartProductOptionKey: CartProductOptionKeyType,
    cartProductOption: CartProductOptionType,
    availableMaxPurchaseQuantity?: number
  ) => void
  updateCartProductOptionPurchaseQuantity: (
    cartProductOptionKey: CartProductOptionKeyType,
    purchaseQuantity: number
  ) => void
  mergeLocalCartToServerCartIfAvailable: () => void
  renderCartValidateMessage: (
    expectedCartProductOption: CartProductOptionType,
    usageFrom: 'productDetail' | 'cart'
  ) => void
  isValidCartProductOption: (cartProductOption: CartProductOptionType) => boolean
}

const useCartNew = (): UseCartNewType => {
  const { hasSignedIn } = useUserInfo

  const isSignedIn = (): boolean => {
    return hasSignedIn()
  }
  const [isCartLoaded, setIsCartLoaded] = useState(false)
  const [cartProductOptionsInCart, setCartProductOptionsInCart] = useState<CartProductOptionType[]>([])

  const dispatch = useDispatch()

  const _handleCartProductOptionQuantityChange = (cartItemQuantity: number) => {
    setCartProductOptionsCountToLayoutHeader(cartItemQuantity)
  }
  const _handleCartProductOptionChange = (cartProductOptions: CartProductOptionType[]) => {
    setCartProductOptionsInCart(cartProductOptions)
    setCartProductOptionsCountToLayoutHeader(CartProductOptionTypeUtil.getCartProductOptionsCount(cartProductOptions))
    setIsCartLoaded(true)
  }

  const _cartLocal = useCartLocal({
    handleCartProductOptionQuantityChange: _handleCartProductOptionQuantityChange,
    handleCartProductOptionChange: _handleCartProductOptionChange,
  })
  const _cartServer = useCartServer({
    handleCartProductOptionQuantityChange: _handleCartProductOptionQuantityChange,
    handleCartProductOptionChange: _handleCartProductOptionChange,
  })

  const refreshCartProductOptionsCount = () => {
    if (isSignedIn()) {
      _cartServer.refreshCartProductOptionsCount()
    } else {
      _handleCartProductOptionQuantityChange(_cartLocal.getCartProductOptionsCount())
    }
  }

  const refreshCartProductOptions = () => {
    if (isSignedIn()) {
      _cartServer.refreshCartProductOptions()
    } else {
      _cartLocal.refreshCartProductOptions()
    }
  }

  const addOrUpdateCartProductOptions = async (cartProductOptions: CartProductOptionType[]) => {
    if (isSignedIn()) {
      await _cartServer.addOrUpdateCartProductOptions(cartProductOptions)
    } else {
      _cartLocal.addOrUpdateCartProductOptions(cartProductOptions)
    }
  }

  const removeCartProductOption = (cartProductOptionKey: CartProductOptionKeyType) => {
    if (isSignedIn()) {
      _cartServer.removeCartProductOption(cartProductOptionKey)
    } else {
      _cartLocal.removeCartProductOption(cartProductOptionKey)
    }
  }

  const removeCartProductOptions = (cartProductOptionKeys: CartProductOptionKeyType[]) => {
    if (isSignedIn()) {
      _cartServer.removeCartProductOptions(cartProductOptionKeys)
    } else {
      _cartLocal.removeCartProductOptions(cartProductOptionKeys)
    }
  }

  const updateCartProductOption = (
    cartProductOptionKey: CartProductOptionKeyType,
    cartProductOption: CartProductOptionType,
    availableMaxPurchaseQuantity?: number
  ) => {
    if (isSignedIn()) {
      _cartServer.updateCartProductOption(cartProductOptionKey, cartProductOption, availableMaxPurchaseQuantity)
    } else {
      _cartLocal.updateCartProductOption(cartProductOptionKey, cartProductOption, availableMaxPurchaseQuantity)
    }
  }

  const updateCartProductOptionPurchaseQuantity = (
    cartProductOptionKey: CartProductOptionKeyType,
    purchaseQuantity: number
  ) => {
    if (isSignedIn()) {
      _cartServer.updateCartProductOptionPurchaseQuantity(cartProductOptionKey, purchaseQuantity)
    } else {
      _cartLocal.updateCartProductOptionPurchaseQuantity(cartProductOptionKey, purchaseQuantity)
    }
  }

  const setCartProductOptionsCountToLayoutHeader = (cartProductOptionsCount: number) => {
    dispatch(doSetCartItemCount(cartProductOptionsCount))
    // eslint-disable-next-line no-unused-expressions
    !isAppNonLoginCart &&
      !isAppStorageDisabled &&
      appBridgeProvider((bridge: Bridge) => {
        bridge.storageSetItem('cartItemQuantity', String(cartProductOptionsCount))
      })
  }

  const mergeLocalCartToServerCartIfAvailable = async () => {
    if (isSignedIn()) {
      const localCartProductOptions = _cartLocal.getCartProductOptions()
      if (localCartProductOptions.length > 0) {
        _cartLocal.clear()
        await _cartServer.mergeCartProductOptionsWithApiQuery(localCartProductOptions)
      }
    }
  }

  const renderCartValidateMessage = (
    expectedCartProductOption: CartProductOptionType,
    usageFrom: 'productDetail' | 'cart' = 'productDetail'
  ) => {
    const errorInfo = CartProductOptionTypeUtil.getErrorInfo(expectedCartProductOption, usageFrom)
    if (!errorInfo) {
      return
    }
    const isRenderToastError =
      errorInfo.type === 'exceedStockQuantity' || errorInfo.type === 'notInCartMaxAblePurchaseQuantity'
    const isRenderModalError =
      errorInfo.type === 'alreadyInCartMaxAblePurchaseQuantity' ||
      errorInfo.type === 'alreadyInCartInValidStockQuantity'

    if (isRenderToastError) {
      dispatch(
        doSetGlobalToastContent({
          content: errorInfo.message,
        })
      )
    }

    if (isRenderModalError) {
      dispatch(
        doSetGlobalNoticeModal({
          text: errorInfo.message,
          subText: errorInfo.subMessage,
          buttonType: 'ACTION',
          okText: '확인',
          visible: true,
        })
      )
    }
  }

  const isValidCartProductOption = (cartProductOption: CartProductOptionType) => {
    return !CartProductOptionTypeUtil.getErrorInfo(cartProductOption, 'productDetail')
  }

  return {
    cartProductOptionsInCart,
    isCartLoaded,
    refreshCartProductOptions,
    refreshCartProductOptionsCount,
    addOrUpdateCartProductOptions,
    removeCartProductOption,
    removeCartProductOptions,
    updateCartProductOption,
    updateCartProductOptionPurchaseQuantity,
    mergeLocalCartToServerCartIfAvailable,
    renderCartValidateMessage,
    isValidCartProductOption,
  }
}

export default useCartNew
