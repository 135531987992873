import { FC, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { TextLegacy } from '@/components/common'
import { NewRow } from '@/components/common/layouts/NewRow'
import { GlobalColorEnum } from '@/constants/global-color.enum'
import { SCREEN_MEDIA_QUERY } from '@/constants/legacy/constLayout'

export interface InputTextAreaProps
  extends Omit<React.ComponentPropsWithoutRef<'textarea'>, 'minLength' | 'maxLength'> {
  row?: number
  minLength?: number
  defaultMinLength?: number
  maxLength?: number
  minLengthAddText?: boolean
  /** textarea의 최소, 최대 rows 설정 */
  autoSize?: { minRows: number; maxRows: number }
}

const ROWS_MIN_COUNT = 6
const ROWS_MAX_COUNT = 20

const InputTextArea: FC<InputTextAreaProps> = ({
  minLength,
  defaultMinLength,
  maxLength,
  minLengthAddText,
  autoSize = { minRows: ROWS_MIN_COUNT, maxRows: ROWS_MAX_COUNT },
  rows,
  ...props
}) => {
  const [charCount, setCharCount] = useState(defaultMinLength || 0)
  const [spaceCount, setSpaceCount] = useState(0)

  const isLastInputEnter = useRef(false)
  const textareaRef = useRef<HTMLTextAreaElement>(null)
  useMaxRows(textareaRef, autoSize.maxRows)

  return (
    <StyledContainer>
      <StyledInput
        ref={textareaRef}
        onChangeCapture={(e) => {
          const _val: string = e.currentTarget.value
          isLastInputEnter.current = endsWith(_val, '\n')
          const value: string = _val.replace(/(\s*)/g, '')
          setSpaceCount(Array.from(_val).length - Array.from(value).length)

          let _valLength = Array.from(value).length

          if (minLength || maxLength) {
            if (maxLength && _valLength > maxLength) {
              _valLength = maxLength
            }

            setCharCount(_valLength)
          }
        }}
        minLength={minLength}
        maxLength={(maxLength ?? 0) + spaceCount}
        rows={getRows(rows, autoSize.minRows, autoSize.maxRows)}
        {...props}
      />

      {(maxLength || minLength) && (
        <CountContainer>
          <NewRow justify="flex-end">
            <TextLegacy size={{ xs: 12, md: 14 }} color="gray400">
              {`글자수 `}
              <TextLegacy size={{ xs: 12, md: 14 }}>{charCount}</TextLegacy>
              {minLengthAddText
                ? getReviewLengthCount({ maxLength, minLength })
                : getLengthCount({ maxLength, minLength })}
            </TextLegacy>
          </NewRow>
        </CountContainer>
      )}
    </StyledContainer>
  )
}

const useMaxRows = (ref: React.RefObject<HTMLTextAreaElement>, maxRows: number) => {
  useEffect(() => {
    const textarea = ref.current

    if (textarea) {
      const handleUpdateTextAreaHeight = () => {
        textarea.style.height = 'auto'

        const scrollHeight = textarea.scrollHeight
        const lineHeight = parseInt(window.getComputedStyle(textarea).lineHeight || '20', 10)
        const maxHeight = maxRows * lineHeight

        textarea.style.height = `${Math.min(scrollHeight, maxHeight)}px`
      }

      handleUpdateTextAreaHeight()
      textarea.addEventListener('input', handleUpdateTextAreaHeight)

      return () => {
        textarea.removeEventListener('input', handleUpdateTextAreaHeight)
      }
    }
  }, [ref, maxRows])
}

const getRows = (rows?: number, minRows?: number, maxRows?: number) => {
  const MIN_ROWS = minRows ?? ROWS_MIN_COUNT
  const MAX_ROWS = maxRows ?? ROWS_MAX_COUNT
  if (!rows) return MIN_ROWS
  return Math.min(rows, MAX_ROWS)
}

const endsWith = (str: string, suffix: string): boolean => {
  return str.slice(-suffix.length) === suffix
}

const getLengthCount = ({ maxLength, minLength }: { maxLength?: number; minLength?: number }) => {
  // minLength 가 있을 경우 먼저 노출
  if (minLength) {
    return ` (최소 ${minLength}자)`
  }
  if (maxLength) {
    return ` (최대 ${maxLength}자)`
  }
  return null
}

const getReviewLengthCount = ({ maxLength, minLength }: { maxLength?: number; minLength?: number }) => {
  // minLength 가 있을 경우 먼저 노출
  if (minLength) {
    return ` (적립금 지급 최소 ${minLength}자)`
  }
  if (maxLength) {
    return ` (최대 ${maxLength}자)`
  }
  return null
}

const StyledContainer = styled.div`
  border: 1px solid ${GlobalColorEnum.Gray70};
  border-radius: 3px;
  background-color: ${(props) => props.theme.color.grayWhite};
`

const StyledInput = styled.textarea`
  width: 100%;
  max-width: 100%;
  font-size: 15px !important;
  line-height: 20px !important;
  padding: 10px;
  transition: all 0.3s, height 0s;
  vertical-align: bottom;

  position: relative;
  display: inline-block;
  width: 100%;
  background-color: #fff;
  outline: none;
  border: 1px solid transparent;
  background: transparent;
  border-radius: 2px;
  overflow: auto;
  resize: none;

  &::placeholder {
    opacity: 0.5;
  }

  &:focus {
    box-shadow: none;
    border: 1px solid #8fb8ff;
  }

  @media ${SCREEN_MEDIA_QUERY.xs} {
    font-size: 13px !important;
  }
`

const CountContainer = styled.div`
  padding: 0 10px 6px 0;
`

export default InputTextArea
