import { FC, ReactNode, useEffect, useState } from 'react'
import styled from 'styled-components'

type ScaledSVGProps = {
  component: ReactNode
}

type StyledScaledSVGProps = {
  scale: number
}

const ScaledSVG: FC<ScaledSVGProps> = ({ component }) => {
  const [scale, setScale] = useState(1)

  useEffect(() => {
    const getRect = () => {
      if (typeof document !== 'undefined') {
        return document.querySelector('#main')?.getBoundingClientRect()
      }
    }
    const _width = getRect()?.width ?? 0
    const _scale = Math.min(_width, 450) / 375
    setScale(_scale)
  }, [])

  return <StyledScaledSVG scale={scale}>{component}</StyledScaledSVG>
}

const StyledScaledSVG = styled.div<StyledScaledSVGProps>`
  line-height: 0;

  svg {
    scale: ${(props) => props.scale};
  }
`

export default ScaledSVG
